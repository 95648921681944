import {
    Booking as BookingModel,
    BookingStatus,
    useBookings,
    useBookingStatus,
} from '@sugg-gestion/suggpro-graph-module'
import actions from 'core/store/actions'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import BookingDataView from '../../components/views/booking/bookingDataView'
import { useApiErrors } from '../../core/services/suggPro/useApiErrors'
import withAuthenticatedGraphApi from '../hoc/withAuthenticatedGraphApi'

const BookingData: React.FC = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { data, refetch, loading } = useBookings()
    const { updateBookingStatus } = useBookingStatus()
    const { displayError } = useApiErrors()
    const { enqueueSnackbar } = useSnackbar()

    const [acceptBookingInProgress, setAcceptBookingInProgress] = useState<string>()
    const [rejectBookingInProgress, setRejectBookingInProgress] = useState<string>()

    let bookings: Array<BookingModel> = []
    if (data !== undefined) {
        bookings = data.bookings
    }

    const handleRefresh = () => {
        refetch()
    }

    const acceptBooking = (bookingId: string) => {
        setAcceptBookingInProgress(bookingId)
        return updateBookingStatus({
            variables: {
                id: bookingId,
                status: BookingStatus.Confirmed,
            },
        })
            .then(() => {
                enqueueSnackbar(t('views.booking.acceptBooking.success'), {
                    variant: 'success',
                })
            })
            .catch((e) => {
                displayError(e)
            })
            .finally(() => {
                setAcceptBookingInProgress(undefined)
            })
    }
    const rejectBooking = (bookingId: string) => {
        setRejectBookingInProgress(bookingId)
        return updateBookingStatus({
            variables: {
                id: bookingId,
                status: BookingStatus.Rejected,
            },
        })
            .then(() => {
                enqueueSnackbar(t('views.booking.rejectBooking.success'), {
                    variant: 'success',
                })
            })
            .catch((e) => {
                displayError(e)
            })
            .finally(() => {
                setRejectBookingInProgress(undefined)
            })
    }
    const handleAcceptBooking = (bookingId: string) => {
        dispatch(
            actions.alert({
                content: t('views.booking.verificationAccept'),
                buttons: [
                    {
                        color: 'primary',
                        children: t('common.no'),
                    },
                    {
                        color: 'primary',
                        children: t('common.yes'),
                        onClick: () => acceptBooking(bookingId),
                    },
                ],
            }),
        )
    }
    const handleRejectBooking = (bookingId: string) => {
        dispatch(
            actions.alert({
                content: t('views.booking.verificationReject'),
                buttons: [
                    {
                        color: 'primary',
                        children: t('common.no'),
                    },
                    {
                        color: 'primary',
                        children: t('common.yes'),
                        onClick: () => rejectBooking(bookingId),
                    },
                ],
            }),
        )
    }

    return (
        <BookingDataView
            bookings={bookings}
            loading={loading}
            acceptBookingInProgress={acceptBookingInProgress}
            onAcceptBooking={handleAcceptBooking}
            rejectBookingInProgress={rejectBookingInProgress}
            onRejectBooking={handleRejectBooking}
            onRefresh={handleRefresh}
        />
    )
}

export default withAuthenticatedGraphApi(BookingData)
