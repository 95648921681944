import {
    SpecialClosing,
    useDeleteSpecialClosing,
    useEstablishmentTypes,
} from '@sugg-gestion/ubidreams-react-suggpro'
import { DeleteSpecialClosingRequest } from '@sugg-gestion/ubidreams-react-suggpro/src/resources/establishment/types'
import EstablishmentEditView from 'components/views/establishment/establishmentEditView'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import actions from 'core/store/actions'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import withAuthenticateLayout, { WithAuthenticateLayout } from '../hoc/withAuthenticateLayout'
import EstablishmentAddSpecialClosingDialog from './dialogs/establishmentAddSpecialClosingDialog'
import EstablishmentEditDetailsDialog from './dialogs/establishmentEditDetailsDialog'
import EstablishmentEditFacebookGoogleDialog from './dialogs/establishmentEditFacebookGoogleDialog'
import EstablishmentEditFoodTypeDialog from './dialogs/establishmentEditFoodTypeDialog'
import EstablishmentEditHoursDialog from './dialogs/establishmentEditHoursDialog'
import EstablishmentEditLocationDialog from './dialogs/establishmentEditLocationDialog'
import EstablishmentEditPhotosDialog from './dialogs/establishmentEditPhotosDialog'
import EstablishmentEditSocialNetworksDialog from './dialogs/establishmentEditSocialNetworksDialog'

interface Props extends WithAuthenticateLayout {}

const EstablishmentEdit: React.FC<Props> = ({ establishment, ability }) => {
    const { t } = useTranslation()
    const { establishmentTypes } = useEstablishmentTypes()
    const { deleteSpecialClosing } = useDeleteSpecialClosing()
    const { displayError } = useApiErrors()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const [open, setOpen] = useState<number>(-1)

    const handleEditSocialNetworks = () => {
        setOpen(0)
    }
    const handleEditDetails = () => {
        setOpen(1)
    }
    const handleEditFoodType = () => {
        setOpen(2)
    }
    const handleEditPosition = () => {
        setOpen(3)
    }
    const handleEditHours = () => {
        setOpen(4)
    }
    const handleEditPhotos = () => {
        setOpen(5)
    }
    const handleEditFacebookGoogle = () => {
        setOpen(6)
    }
    const handleAddSpecialClosing = () => {
        setOpen(7)
    }
    const handleDeleteSpecialClosing = (specialClosing: SpecialClosing) => {
        const delSpecialClosing: DeleteSpecialClosingRequest = {
            id: specialClosing.id,
            establishment: specialClosing.establishment,
        }
        return deleteSpecialClosing(delSpecialClosing)
            .then(() => {
                enqueueSnackbar(t('views.establishment.specialClosing.delete'), {
                    variant: 'success',
                })
            })
            .catch((error) => displayError(error))
    }
    const handleDeleteSpecialClosingPrompt = (specialClosing: SpecialClosing) => {
        dispatch(
            actions.alert({
                content: t('views.establishment.specialClosing.warning'),
                buttons: [
                    {
                        color: 'primary',
                        children: t('common.cancel'),
                    },
                    {
                        color: 'primary',
                        children: t('common.ok'),
                        onClick: () => handleDeleteSpecialClosing(specialClosing),
                    },
                ],
            }),
        )
    }

    const handleClose = () => {
        setOpen(-1)
    }

    const showDialog = () => {
        switch (open) {
            case 0:
                return (
                    <EstablishmentEditSocialNetworksDialog
                        establishment={establishment}
                        open={true}
                        onClose={handleClose}
                    />
                )
            case 1:
                return (
                    <EstablishmentEditDetailsDialog
                        establishment={establishment}
                        open={true}
                        onClose={handleClose}
                    />
                )
            case 2:
                return (
                    <EstablishmentEditFoodTypeDialog
                        establishment={establishment}
                        open={true}
                        onClose={handleClose}
                    />
                )
            case 3:
                return (
                    <EstablishmentEditLocationDialog
                        establishment={establishment}
                        open={true}
                        onClose={handleClose}
                    />
                )
            case 4:
                return (
                    <EstablishmentEditHoursDialog
                        establishment={establishment}
                        open={true}
                        onClose={handleClose}
                    />
                )
            case 5:
                return (
                    <EstablishmentEditPhotosDialog
                        establishment={establishment}
                        open={true}
                        onClose={handleClose}
                    />
                )
            case 6:
                return (
                    <EstablishmentEditFacebookGoogleDialog
                        establishment={establishment}
                        open={true}
                        onClose={handleClose}
                        ability={ability}
                    />
                )
            case 7:
                return (
                    <EstablishmentAddSpecialClosingDialog
                        establishment={establishment}
                        open={true}
                        onClose={handleClose}
                    />
                )
        }
    }
    return (
        <>
            <EstablishmentEditView
                establishment={establishment}
                establishmentTypes={establishmentTypes}
                onEditSocialNetworks={handleEditSocialNetworks}
                onEditDetails={handleEditDetails}
                onEditFoodType={handleEditFoodType}
                onEditPosition={handleEditPosition}
                onEditHours={handleEditHours}
                onEditPhotos={handleEditPhotos}
                onEditFacebookGoogle={handleEditFacebookGoogle}
                ability={ability}
                onAddSpecialClosing={handleAddSpecialClosing}
                onDeleteSpecialClosing={handleDeleteSpecialClosingPrompt}
                withTwitter={false}
            />
            {showDialog()}
        </>
    )
}

export default withAuthenticateLayout(EstablishmentEdit)
