import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = (maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false, fullScreen: boolean) => {
    let buttonWidth = 130
    if (fullScreen || maxWidth === 'sm') {
        buttonWidth = 48
    }
    return makeStyles((theme: Theme) =>
        createStyles({
            container: {
                display: 'flex',
                [theme.breakpoints.down('xs')]: {
                    flexGrow: 1,
                    padding: 0,
                },
            },
            card: {
                width: '100%',
                marginTop: theme.spacing(4),
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: theme.palette.border.main,
                [theme.breakpoints.down('xs')]: {
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    marginTop: 0,
                    justifyContent: 'space-evenly',
                },
            },
            appBar: {
                paddingTop: theme.spacing(2),
            },
            logoContainer: {
                textAlign: 'center',
                flexGrow: 1,
            },
            logo: {
                height: 80,
                width: 300,
                maxWidth: '100%',
            },
            settings: {
                height: 48,
                width: 48,
            },
            menuButton: {
                minWidth: 130,
            },
            headerRight: {
                width: buttonWidth,
                marginRight: theme.spacing(2),
            },
            action: {
                position: 'absolute',
                right: theme.spacing(3),
                top: theme.spacing(3),
                bottom: theme.spacing(2),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                [theme.breakpoints.down('xs')]: {
                    position: 'static',
                },
            },
        }),
    )
}
