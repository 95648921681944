import React from 'react'
import { SocialNetworksFormState } from 'components/forms/establishment/socialNetworksForm'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import { Establishment, useEstablishmentUpdate } from '@sugg-gestion/ubidreams-react-suggpro'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import EstablishmentSocialNetworksDialogView from 'components/views/establishment/dialogs/establishmentSocialNetworksDialogView'

interface Props {
    open: boolean
    onClose: () => void
    establishment: Establishment
}

const EstablishmentEditSocialNetworksDialog: React.FC<Props> = ({ establishment, open, onClose }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const { displayError } = useApiErrors()
    const { updateEstablishment, inProgress } = useEstablishmentUpdate()

    const handleUpdateSocialNetworks = (values: SocialNetworksFormState) => {
        updateEstablishment({
            id: establishment.id,
            establishment: {
                ...values,
                website: values.website ?? null,
                facebookUrl: values.facebook ?? null,
                instagramUrl: values.instagram ?? null,
                booking: values.booking ?? null,
                delivery: values.delivery ?? null,
                mealandmeetUrl: values.mealAndMeet ?? null,
                interactiveGame: values.interactiveGame ?? null,
            },
        })
            .then(() => {
                enqueueSnackbar(t('views.establishment.socialNetworks.success'), {
                    variant: 'success',
                })
                onClose()
            })
            .catch((error) => displayError(error))
    }

    return (
        <EstablishmentSocialNetworksDialogView
            open={open}
            onClose={onClose}
            onSubmit={handleUpdateSocialNetworks}
            inProgress={inProgress}
            initialValues={{
                facebook: establishment.facebookUrl ?? undefined,
                website: establishment.website ?? undefined,
                instagram: establishment.instagramUrl ?? undefined,
                booking: establishment.booking ?? undefined,
                delivery: establishment.delivery ?? undefined,
                mealAndMeet: establishment.mealandmeetUrl ?? undefined,
                interactiveGame: establishment.interactiveGame ?? undefined,
            }}
        />
    )
}

export default EstablishmentEditSocialNetworksDialog
