import { useGetFacebookPages, useRenewFacebookSignIn } from '@sugg-gestion/ubidreams-react-suggpro'
import { FacebookPagesRequest } from '@sugg-gestion/ubidreams-react-suggpro/src/resources/restaurantOwner/types'
import actions from 'core/store/actions'
import { FacebookUserInfo } from 'core/store/app/types'
import { ApplicationState } from 'core/store/reducers'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useApiErrors } from '../suggPro/useApiErrors'

export const useFacebookPages = () => {
    const { getFacebookPages, inProgress: getFacebookPagesInProgress } = useGetFacebookPages()
    const { user, facebookPages } = useSelector(({ app, suggpro: { auth } }: ApplicationState) => ({
        user: auth.user,
        facebookPages: app.facebookPages,
    }))
    const { displayError } = useApiErrors()
    const dispatch = useDispatch()
    const { renewFacebookSignIn, inProgress: renewFacebookSignInInProgress } = useRenewFacebookSignIn()
    const [inProgress, setInProgress] = useState(false)

    useEffect(() => {
        if (user !== undefined) {
            if (user.isFacebookSigned && facebookPages === undefined && !inProgress) {
                setInProgress(true)
                getFacebookPages({})
                    .then((response) => {
                        dispatch(actions.setFacebookPages(response))
                        setInProgress(false)
                    })
                    .catch((error) => {
                        dispatch(actions.setFacebookPages([]))
                        displayError(error)
                        setInProgress(false)
                    })
            }
        }
    }, [user, facebookPages, dispatch, getFacebookPages, inProgress, displayError])

    const fetchFacebookPagesAction = (withInstagram: boolean = false, forcePrompt: boolean = false) => {
        const facebookRequiredPermissions = [
            'public_profile',
            'pages_manage_metadata',
            'pages_manage_posts',
            'pages_read_user_content',
            'pages_manage_engagement',
            'pages_manage_ads',
            'pages_read_engagement',
            'read_insights',
            'pages_messaging',
            'pages_manage_metadata',
            'business_management',
        ]
        if (withInstagram) {
            facebookRequiredPermissions.push(
                'instagram_basic',
                'instagram_content_publish',
                'instagram_manage_insights',
                'instagram_manage_messages',
                'instagram_manage_comments',
            )
        }
        return new Promise<FacebookPagesRequest>((resolve, reject) =>
            FB.login(
                (response: facebook.StatusResponse) => {
                    if (response.status === 'connected') {
                        if (user) {
                            const { accessToken } = response.authResponse
                            return renewFacebookSignIn({
                                facebookAccessToken: accessToken,
                            })
                                .then(() => {
                                    resolve({})
                                })
                                .catch((error) => {
                                    reject(error)
                                })
                        } else {
                            FB.api(
                                '/me?fields=id,first_name,last_name,email',
                                (userInfo: FacebookUserInfo) => {
                                    dispatch(
                                        actions.setFacebookUser({
                                            auth: response,
                                            info: userInfo,
                                        }),
                                    )
                                    const { accessToken } = response.authResponse
                                    return resolve({
                                        facebookAccessToken: accessToken,
                                    })
                                },
                            )
                        }
                    }
                },

                {
                    scope: facebookRequiredPermissions.join(','),
                    return_scopes: true,
                    auth_type: forcePrompt ? 'rerequest' : undefined,
                },
            ),
        )
            .then((request) => {
                return getFacebookPages(request).then((response) => {
                    dispatch(actions.setFacebookPages(response))
                    setInProgress(false)
                })
            })
            .catch((error) => {
                dispatch(actions.setFacebookPages([]))
                setInProgress(false)
                throw error
            })
    }

    return {
        fetchFacebookPagesAction,
        getFacebookPages,
        inProgress: getFacebookPagesInProgress || renewFacebookSignInInProgress || inProgress,
        facebookPages,
    }
}
