import React from 'react'
import { createStyles, FormHelperText, Theme } from '@material-ui/core'
import { FormSpy } from 'react-final-form'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField, { TextFieldProps } from './textField'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
        },
        helper: {
            position: 'absolute',
            bottom: 8,
            right: 0,
        },
        textField: {
            '& .MuiFormHelperText-root': {
                paddingRight: theme.spacing(5),
            },
        },
    }),
)

interface Props extends TextFieldProps {
    calculateFunction: (text: string) => number
    maxLength: number
}

const TextFieldLength: React.FC<Props> = ({
    name,
    calculateFunction,
    maxLength,
    helperText = ' ',
    ...textFieldProps
}) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <TextField
                name={name}
                helperText={helperText}
                {...textFieldProps}
                className={classes.textField}
            />
            <FormSpy subscription={{ values: true }}>
                {(props) => {
                    const length = calculateFunction(props.values[name])
                    return (
                        <FormHelperText
                            classes={{
                                root: classes.helper,
                            }}
                            error={length > maxLength}
                        >
                            {length}/{maxLength}
                        </FormHelperText>
                    )
                }}
            </FormSpy>
        </div>
    )
}

export default TextFieldLength
