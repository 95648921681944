import { CountryCode } from 'libphonenumber-js/types'
import { isSiretValid } from '../duns/siret'

export const inputLength = (country: CountryCode) => {
    switch (country) {
        case 'FR':
            return 14
        case 'AT':
            return 'ATU99999999'.length
        case 'BE':
            return 'BE0999999999'.length
        case 'BG':
            return 'BG9999999999'.length
        case 'CY':
            return 'CY99999999L'.length
        case 'CZ':
            return 'CZ9999999999'.length
        case 'DE':
            return 'DE999999999'.length
        case 'DK':
            return 'DK99 99 99 99'.length
        case 'EE':
            return 'EE999999999'.length
        case 'GR':
            return 'EL999999999'.length
        case 'ES':
            return 'ESX9999999X'.length
        case 'FI':
            return 'FI99999999'.length
        case 'HR':
            return 'HR99999999999'.length
        case 'HU':
            return 'HU99999999'.length
        case 'IE':
            return 'IE9999999WI'.length
        case 'IT':
            return 'IT99999999999'.length
        case 'LT':
            return 'LT999999999999'.length
        case 'LU':
            return 'LU99999999'.length
        case 'LV':
            return 'LV99999999999'.length
        case 'MT':
            return 'MT99999999'.length
        case 'NL':
            return 'NLSSSSSSSSSSSS'.length
        case 'PL':
            return 'PL9999999999'.length
        case 'PT':
            return 'PT999999999'.length
        case 'RO':
            return 'RO999999999'.length
        case 'SE':
            return 'SE999999999999'.length
        case 'SI':
            return 'SI99999999'.length
        case 'SK':
            return 'SK9999999999'.length
    }
    return 40
}

const regexList = {
    FR: new RegExp('^[0-9]{14}$'),
    // ATU99999999
    AT: new RegExp('^ATU[0-9]{8}$'),
    // BE0999999999 or BE1999999999
    BE: new RegExp('^BE[01][0-9]{9}$'),
    // BG999999999 or BG9999999999
    BG: new RegExp('^BG[0-9]{9,10}$'),
    // CY99999999L
    CY: new RegExp('^CY[0-9]{8}[a-zA-Z0-9]$'),
    // CZ99999999 or CZ999999999 or CZ9999999999
    CZ: new RegExp('^CZ[0-9]{8,10}$'),
    // DE999999999
    DE: new RegExp('^DE[0-9]{9}$'),
    // DK99 99 99 99
    DK: new RegExp('^DK([0-9]{2}[ ]?){4}$'),
    // EE999999999
    EE: new RegExp('^EE[0-9]{9}$'),
    // EL999999999
    GR: new RegExp('^EL[0-9]{9}$'),
    // ESX9999999X
    ES: new RegExp('^ES([0-9][0-9]{7}[a-zA-Z])|([a-zA-Z][0-9]{7}[0-9])$'),
    // FI99999999
    FI: new RegExp('^FI[0-9]{8}$'),
    // HR99999999999
    HR: new RegExp('^HR[0-9]{11}$'),
    // HU99999999
    HU: new RegExp('^HU[0-9]{8}$'),
    // IE9S99999L or IE9999999WI
    IE: new RegExp('^IE([0-9][a-zA-Z0-9+*][0-9]{5}[a-zA-Z])|([0-9]{7}WI)$'),
    // IT99999999999
    IT: new RegExp('^IT[0-9]{11}$'),
    // LT999999999 or LT999999999999
    LT: new RegExp('^LT([0-9]{9})$|([0-9]{12})$'),
    // LU99999999
    LU: new RegExp('^LU[0-9]{8}$'),
    // LV99999999999
    LV: new RegExp('^LV[0-9]{11}$'),
    // MT99999999
    MT: new RegExp('^MT[0-9]{8}$'),
    // NLSSSSSSSSSSSS
    NL: new RegExp('^NL[a-zA-Z0-9+*]{12}$'),
    // PL9999999999
    PL: new RegExp('^PL[0-9]{10}$'),
    // PT999999999
    PT: new RegExp('^PT[0-9]{9}$'),
    // RO999999999
    RO: new RegExp('^RO[0-9]{2,10}$'),
    // SE999999999999
    SE: new RegExp('^SE[0-9]{12}$'),
    // SI99999999
    SI: new RegExp('^SI[0-9]{8}$'),
    // SK9999999999
    SK: new RegExp('^SK[0-9]{10}$'),
}

export const isDunsValid = (country: CountryCode, duns: string) => {
    switch (country) {
        case 'FR':
            return regexList.FR.test(duns) && isSiretValid(duns)
        default:
            // @ts-ignore
            if (regexList[country]) {
                // @ts-ignore
                return regexList[country].test(duns)
            }
    }
    return false
}
