import * as appActions from './app/actions'
import * as shareActions from './share/actions'
import * as tutoActions from './tuto/actions'

const actions = {
    ...appActions,
    ...shareActions,
    ...tutoActions,
}

export default actions
