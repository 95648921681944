export const pricingQuery = `
query {
  promotionCodeCollection {
    items {
      code
      priceId
      couponCode
    }
  }
  pricingCollection(order: sortOrder_ASC) {
    items {
      title
      type
      priceId
      priceByMonth
      initialPriceByMonth
      trialPeriod
      couponCode
      subtitle {
        json
      }
      advantages {
        json
      }
      backgroundColor
      lottieAnimation {
        animationData
        loop
      }
      image {
          url
      }
    }
  }
}
`
