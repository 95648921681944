import appSagas from './app/sagas'
import { suggProSaga } from '@sugg-gestion/ubidreams-react-suggpro'
import { all, call } from 'redux-saga/effects'
import shareSagas from './share/sagas'

function* mainSaga() {
    yield all([call(appSagas), call(suggProSaga), call(shareSagas)])
}

export default mainSaga
