import React from 'react'
import SubmitButton from '../../common/submitButton'
import { useFormsStyles } from '../forms.css'
import { Trans, useTranslation } from 'react-i18next'
import { validateAWSEmail } from 'appsync-scalar-utils'
import { Form } from 'react-final-form'
import EmailField from '../components/emailField'
import { InputAdornment } from '@material-ui/core'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: InlineEmailFormState) => void
    inProgress: boolean
    disabled: boolean
    initialValues: InlineEmailFormState
    label: string
}

export interface InlineEmailFormState {
    email: string
}

interface InlineEmailFormError {
    email?: string
}

const InlineEmailForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    label,
    onSubmit,
    inProgress,
    initialValues,
    disabled,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()

    const validate = (values: InlineEmailFormState) => {
        const errors: InlineEmailFormError = {}

        if (values.email && !validateAWSEmail(values.email)) {
            errors.email = t('form-validation.emailValid')
        }
        return errors
    }
    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <EmailField
                        name="email"
                        label={label}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" style={{ marginBottom: 16 }}>
                                    <SubmitButton
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        loading={submitting || inProgress}
                                        disabled={disabled}
                                        className={formsClasses.submit}
                                    >
                                        <Trans i18nKey="actions.validate" />
                                    </SubmitButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </form>
            )}
        </Form>
    )
}

export default InlineEmailForm
