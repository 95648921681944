import { FormControl, PropTypes } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'
import { Field } from 'react-final-form'

interface Props {
    name: string
    label?: string | React.ReactNode
    value: any
    disabled?: boolean
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
    classes?: {
        formControlLabel?: string
        checkbox?: string
        checked?: string
    }
    margin?: PropTypes.Margin
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            '& > .MuiFormControlLabel-label': {
                fontWeight: theme.fontsWeight.semiBold,
                flexGrow: 1,
            },
        },
    }),
)

const CheckboxField: React.FC<Props> = ({
    name,
    label,
    value,
    classes,
    disabled = false,
    margin = 'normal',
    labelPlacement = 'start',
}) => {
    const internalClasses = useStyles()
    return (
        <FormControl component="fieldset" fullWidth margin={margin}>
            <FormControlLabel
                classes={{
                    root: clsx(internalClasses.label, classes?.formControlLabel),
                }}
                control={
                    <Field name={name} type="checkbox" value={value}>
                        {({ input: { name, value, onChange, checked } }) => {
                            return (
                                <Checkbox
                                    disabled={disabled}
                                    checked={disabled ? false : checked}
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    classes={{
                                        root: classes?.checkbox,
                                        checked: classes?.checked,
                                    }}
                                />
                            )
                        }}
                    </Field>
                }
                label={label}
                labelPlacement={labelPlacement}
            />
        </FormControl>
    )
}

export default CheckboxField
