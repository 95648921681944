import React from 'react'
import { useFormsStyles } from '../forms.css'
import { Form } from 'react-final-form'
import { SubmitButtonProps } from 'components/common/submitButton'
import { Trans, useTranslation } from 'react-i18next'
import EmailField from '../components/emailField'
import { validateAWSEmail } from 'appsync-scalar-utils'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: EmailFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: EmailFormState
}

export interface EmailFormState {
    email: string
}

interface EmailFormError {
    email?: string
}

const EmailForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()

    const validate = (values: EmailFormState) => {
        const errors: EmailFormError = {}

        if (!values.email) {
            errors.email = t('form-validation.email')
        } else if (!validateAWSEmail(values.email)) {
            errors.email = t('form-validation.emailValid')
        }
        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <div>
                            <EmailField name="email" label={t('form.email')} required />
                        </div>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                className: formsClasses.submit,
                                children: <Trans i18nKey="actions.continue" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}
export default EmailForm
