import { setGoogleAuth, setGoogleInitialized } from 'core/store/app/actions'
import { ApplicationState } from 'core/store/reducers'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SocialWindow } from 'types/window'

declare let window: SocialWindow

const useGoogleAuth = () => {
    const { REACT_APP_GOOGLE_CLIENT_ID } = process.env
    if (!REACT_APP_GOOGLE_CLIENT_ID) {
        throw Error()
    }
    const dispatch = useDispatch()
    const { googleInitialized } = useSelector(({ app }: ApplicationState) => ({
        googleInitialized: app.googleInitialized,
    }))
    const [hasRun, setHasRun] = useState(googleInitialized)

    useEffect(() => {
        if (!hasRun && !googleInitialized) {
            setHasRun(true)
            const params: gapi.auth2.ClientConfig = {
                client_id: REACT_APP_GOOGLE_CLIENT_ID,
                cookie_policy: 'single_host_origin',
                scope:
                    'https://www.googleapis.com/auth/userinfo.email ' +
                    'https://www.googleapis.com/auth/userinfo.profile ' +
                    'https://www.googleapis.com/auth/business.manage',
            }
            window.googleAsyncInit = () => {
                gapi.load('auth2', () => {
                    gapi.auth2.init(params).then(
                        (googleAuth) => {
                            dispatch(setGoogleAuth(googleAuth))
                            dispatch(setGoogleInitialized(true))
                        },
                        (err) => {
                            dispatch(setGoogleInitialized(true))
                            console.error('err: ', err)
                        },
                    )
                })
            }
            ;(function (d, s, id) {
                let js,
                    fjs = d.getElementsByTagName(s)[0]
                if (d.getElementById(id)) return
                js = d.createElement(s) as HTMLScriptElement
                js.id = id
                js.src = 'https://apis.google.com/js/platform.js?onload=googleAsyncInit'
                js.onerror = () => {
                    dispatch(setGoogleInitialized(true))
                }
                js.addEventListener('load', function () {
                    setTimeout(() => {
                        if (!googleInitialized) {
                            window.googleAsyncInit()
                        }
                    }, 400)
                })
                const a = fjs.parentNode
                if (a) {
                    a.insertBefore(js, fjs)
                }
            })(document, 'script', 'google-jssdk')
        }
    }, [googleInitialized, REACT_APP_GOOGLE_CLIENT_ID, dispatch, hasRun])

    return googleInitialized
}

export default useGoogleAuth
