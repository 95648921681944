import React from 'react'
import Typography from '@material-ui/core/Typography'
import useStyles from './version.css'
import { useVersion } from 'core/services/version/useVersion'

const Version: React.FC = () => {
    const classes = useStyles()
    const version = useVersion()
    return <Typography className={classes.version}>Version {version}</Typography>
}

export default Version
