import { BusinessHour, BusinessHours, DayOfWeek, Establishment } from '@sugg-gestion/ubidreams-react-suggpro'
import { Trans } from 'react-i18next'
import React from 'react'
import { createStyles, Divider, Grid, makeStyles, Theme, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            transform: 'rotate(45deg)',
            backgroundColor: theme.palette.text.primary,
        },
        hour: {
            fontWeight: theme.fontsWeight.semiBold,
            padding: theme.spacing(0, 1),
            lineHeight: 1.7,
        },
        dividerCenter: {
            display: 'flex',
            justifyContent: 'center',
        },
        textCenter: {
            textAlign: 'center',
        },
    }),
)

export const useBusinessHours = (establishment: Establishment) => {
    const classes = useStyles()

    const theme = useTheme()
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
    const smUp = useMediaQuery(theme.breakpoints.up('sm'))

    const lgUpDividerCondition = (index: number, hours: Array<BusinessHour>) => {
        return lgUp && index < hours.length - 1 && index % 3 !== 2
    }
    const smUpDividerCondition = (index: number, hours: Array<BusinessHour>) => {
        return smUp && !lgUp && index < hours.length - 1 && index % 2 !== 1
    }
    const getHoursDisplay = (hours: Array<BusinessHour>) => {
        if (hours.length === 0) {
            return null
        }
        const hoursDisplay = []
        for (let i = 0; i < hours.length; i++) {
            const hour = hours[i]
            const display = (
                <Trans
                    i18nKey="views.establishment.edit.hours"
                    values={{
                        ...hour,
                    }}
                    components={[<span className={classes.hour} />]}
                />
            )
            hoursDisplay.push(display)
        }
        return (
            <Grid container>
                {hoursDisplay.map((display, index) => (
                    <React.Fragment key={index}>
                        <Grid item lg={3} sm={5} xs={12}>
                            <Typography className={classes.textCenter}>{display}</Typography>
                        </Grid>
                        <Grid item sm={1} className={classes.dividerCenter}>
                            {lgUpDividerCondition(index, hours) && (
                                <Divider orientation="vertical" className={classes.divider} />
                            )}
                            {smUpDividerCondition(index, hours) && (
                                <Divider orientation="vertical" className={classes.divider} />
                            )}
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        )
    }

    const getDayString = (day: DayOfWeek) => {
        switch (day) {
            case DayOfWeek.Monday:
                return 'monday'
            case DayOfWeek.Tuesday:
                return 'tuesday'
            case DayOfWeek.Wednesday:
                return 'wednesday'
            case DayOfWeek.Thursday:
                return 'thursday'
            case DayOfWeek.Friday:
                return 'friday'
            case DayOfWeek.Saturday:
                return 'saturday'
            case DayOfWeek.Sunday:
                return 'sunday'
        }
    }
    const hoursByDay: BusinessHours = {
        [DayOfWeek.Monday]: [],
        [DayOfWeek.Tuesday]: [],
        [DayOfWeek.Wednesday]: [],
        [DayOfWeek.Thursday]: [],
        [DayOfWeek.Friday]: [],
        [DayOfWeek.Saturday]: [],
        [DayOfWeek.Sunday]: [],
        ...establishment.businessHours,
    }

    return {
        hoursByDay,
        getHoursDisplay,
        getDayString,
    }
}
