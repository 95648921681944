import { ActionType, getType } from 'typesafe-actions'
import actions from '../../actions'
import { call, put, select, take } from 'redux-saga/effects'
import {
    actions as suggProActions,
    GooglePageNotLinked,
    GooglePagesResponse,
    GoogleTokenExpired,
    GoogleTokenRequired,
    Slate,
    suggProSagas,
} from '@sugg-gestion/ubidreams-react-suggpro'
import { NotificationActionTypes } from '../../app/types'
import { ApplicationState } from '../../reducers'
import { IgnoredError } from 'core/services/helper/errors'

export function* reauthenticateGoogle() {
    try {
        const token: string = yield call(getGoogleAccessTokenDecorator, true)
        return token
    } catch (ignore) {}
}

export function* shareSlateOnGoogleResolver(action: ActionType<typeof actions.shareSlateOnGoogleResolver>) {
    const resolve = action.payload
    if (resolve !== undefined) {
        yield take([getType(actions.setIsSharingSlateOnGoogle)])
        resolve()
    }
}

const loginOnGoogle = (
    googleAuth: gapi.auth2.GoogleAuth,
    resolve: (value: PromiseLike<GoogleAccessToken> | GoogleAccessToken) => void,
    reject: (reason?: any) => void,
) => {
    googleAuth
        .signIn()
        .then((googleUser) => {
            resolve({
                googleAccessToken: googleUser.getAuthResponse().access_token,
                googleId: googleUser.getId(),
            })
        })
        .catch(() => {
            reject()
        })
}

interface GoogleAccessToken {
    googleAccessToken: string
    googleId: string
}

const getGoogleAccessToken = (googleAuth: gapi.auth2.GoogleAuth, forceLogin: boolean = false) => {
    return new Promise<GoogleAccessToken>((resolve, reject) => {
        if (googleAuth === undefined) {
            reject()
        }
        if (forceLogin) {
            loginOnGoogle(googleAuth, resolve, reject)
        } else {
            if (googleAuth.isSignedIn.get()) {
                const googleUser = googleAuth.currentUser.get()
                const accessToken = googleUser.getAuthResponse(true).access_token
                resolve({
                    googleAccessToken: accessToken,
                    googleId: googleUser.getId(),
                })
            } else {
                loginOnGoogle(googleAuth, resolve, reject)
            }
        }
    })
}

function* getGoogleAccessTokenDecorator(forceLogin: boolean = false) {
    const { googleAuth, user } = yield select(({ app, suggpro: { auth } }: ApplicationState) => ({
        googleAuth: app.googleAuth,
        user: auth.user,
    }))
    if (user.isGoogleSigned && !user.isGoogleSignedExpired) {
        return { googleAccessToken: '' }
    }
    try {
        const token: string = yield call(getGoogleAccessToken, googleAuth, forceLogin)
        return token
    } catch (error) {
        if (error !== undefined && error === 'WrongPermissions') {
            yield put(
                actions.enqueueSnackbar({
                    message: 'sharing.google.wrongPermissions',
                    options: {
                        variant: 'error',
                    },
                }),
            )
        }
        throw error
    }
}

function* shareSlateOnGoogle(slate: Slate, forceLogin: boolean = false): any {
    try {
        const { googleAccessToken }: GoogleAccessToken = yield call(getGoogleAccessTokenDecorator, forceLogin)
        try {
            yield call(
                suggProSagas.shareSlate,
                suggProActions.shareSlate({
                    id: slate.id,
                    isGoogleShared: true,
                    googleAccessToken,
                }),
            )
            yield put(actions.setIsSharingSlateOnGoogle(false))
        } catch (error) {
            if (error instanceof GoogleTokenExpired || error instanceof GoogleTokenRequired) {
                yield put(
                    actions.enqueueSnackbar({
                        message: 'sharing.google.wrongPermissionsWrongPage',
                        options: {
                            variant: 'error',
                            persist: true,
                        },
                        actionType: NotificationActionTypes.GOOGLE_REAUTH,
                    }),
                )
            } else if (error instanceof GooglePageNotLinked) {
                yield call(getGooglePagesAndShareSlate, slate)
                // noinspection ExceptionCaughtLocallyJS
                throw IgnoredError
            } else {
                yield put(actions.enqueueError({ error }))
            }
            yield put(actions.setIsSharingSlateOnGoogle(false))
            // noinspection ExceptionCaughtLocallyJS
            throw IgnoredError
        }
        yield put(
            actions.enqueueSnackbar({
                message: 'sharing.google.success',
                options: {
                    variant: 'success',
                },
            }),
        )
        yield put(actions.setIsSharingSlateOnGoogle(false))
    } catch (error) {
        if (!forceLogin && (error instanceof GoogleTokenExpired || error instanceof GoogleTokenRequired)) {
            yield call(shareSlateOnGoogle, slate, true)
            return
        }
        if (
            error !== undefined &&
            !(error instanceof IgnoredError) &&
            error !== 'Cancel' &&
            error !== 'WrongPermissions'
        ) {
            yield put(actions.enqueueError({ error }))
            yield put(actions.setIsSharingSlateOnGoogle(false))
        }
    }
}

export function* connectAndShareSlateOnGoogle(action: ActionType<typeof actions.shareSlateOnGoogle>) {
    const { slate, establishment } = action.payload
    try {
        if (establishment.isGoogleLinked) {
            yield call(shareSlateOnGoogle, slate)
        } else {
            yield call(getGooglePagesAndShareSlate, slate)
        }
    } catch (error) {
        yield put(actions.setIsSharingSlateOnGoogle(false))
    }
}

function* getGooglePagesAndShareSlate(slate: Slate) {
    const {
        googleAccessToken,
        googlePages,
    }: { googleAccessToken: string; googlePages: GooglePagesResponse } = yield call(getGooglePages, slate)
    if (googlePages.length > 1) {
        yield put(
            actions.setGooglePagesData({
                slate,
                googleAccessToken,
                pages: googlePages,
                updateEstablishment: false,
            }),
        )
    } else {
        yield call(
            suggProSagas.connectToGooglePage,
            suggProActions.connectToGooglePage({
                id: slate.establishment.id,
                googleAccessToken,
                idGoogleLocation: googlePages[0].id,
                updateEstablishment: false,
                googleSync: true,
            }),
        )
        yield call(shareSlateOnGoogle, slate)
        yield put(actions.setIsSharingSlateOnGoogle(false))
    }
}

function* getGooglePages(slate: Slate, forceLogin: boolean = false): any {
    try {
        const { googleAccessToken, googleId }: GoogleAccessToken = yield call(
            getGoogleAccessTokenDecorator,
            forceLogin,
        )
        const googlePages: GooglePagesResponse = yield call(
            suggProSagas.getGooglePages,
            suggProActions.getGooglePages({
                googleAccessToken,
                googleId,
            }),
        )
        return { googleAccessToken, googlePages }
    } catch (error) {
        if (!forceLogin && (error instanceof GoogleTokenExpired || error instanceof GoogleTokenRequired)) {
            return yield call(getGooglePages, slate, true)
        }
        throw error
    }
}

export function* selectGooglePageData(action: ActionType<typeof actions.selectGooglePageData>) {
    if (action.payload === undefined) {
        yield put(actions.setGooglePagesData(undefined))
        yield put(actions.setIsSharingSlateOnGoogle(false))
        return
    }
    const { slate, googleAccessToken, page, updateEstablishment } = action.payload
    yield put(actions.setGooglePagesData(undefined))
    try {
        yield call(
            suggProSagas.connectToGooglePage,
            suggProActions.connectToGooglePage({
                id: slate.establishment.id,
                googleAccessToken,
                idGoogleLocation: page.id,
                updateEstablishment,
                googleSync: true,
            }),
        )
        yield call(shareSlateOnGoogle, slate)
    } catch (error) {
        yield put(actions.enqueueError({ error }))
        yield put(actions.setIsSharingSlateOnGoogle(false))
    }
}
