import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import clsx from 'clsx'
import { AlertContent } from 'core/store/app/types'
import React, { MouseEvent, useState } from 'react'
import SubmitButton from 'components/common/submitButton'
import { useStyles } from './alertDialog.css'

interface Props extends AlertContent {
    onClose: () => void
}

const AlertDialog = ({ title, onClose, content, buttons, actionsClassName }: Props) => {
    const classes = useStyles()
    const [inProgress, setInProgress] = useState(-1)
    const handleClick = <T,>(
        event: MouseEvent<T>,
        index: number,
        onClick?: (event: MouseEvent<T>) => Promise<any>,
    ) => {
        if (onClick) {
            setInProgress(index)
            onClick(event)
                .then(() => {
                    onClose()
                })
                .finally(() => {
                    setInProgress(-1)
                })
        } else {
            onClose()
        }
    }
    return (
        <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
            {content && (
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                </DialogContent>
            )}
            <DialogActions className={clsx(classes.actions, actionsClassName)}>
                {buttons?.map((buttonProp, index) => (
                    <SubmitButton
                        key={index}
                        {...buttonProp}
                        onClick={(event) => handleClick(event, index, buttonProp.onClick)}
                        loading={inProgress === index}
                        disabled={inProgress !== -1}
                    />
                ))}
            </DialogActions>
        </Dialog>
    )
}

export default AlertDialog
