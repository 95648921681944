import React from 'react'
import AccountDialogView from 'components/views/account/dialogs/accountDialogView'
import { User, useUpdateUser } from '@sugg-gestion/ubidreams-react-suggpro'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useApiErrors } from '../../core/services/suggPro/useApiErrors'
import { AccountFormState } from '../../components/forms/account/accountForm'
import { CountryCode } from 'libphonenumber-js/types'

interface Props {
    open: boolean
    onClose: () => void
    restaurantOwner: User
}

const AccountDialog: React.FC<Props> = ({ open, onClose, restaurantOwner }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const { displayError } = useApiErrors()
    const { updateUser, inProgress } = useUpdateUser()

    const handleUpdateAccount = (values: AccountFormState) => {
        updateUser({
            ...values,
        })
            .then(() => {
                enqueueSnackbar(t('views.establishment.hours.success'), {
                    variant: 'success',
                })
                onClose()
            })
            .catch((error) => displayError(error))
    }

    return (
        <AccountDialogView
            open={open}
            onClose={onClose}
            onSubmit={handleUpdateAccount}
            inProgress={inProgress}
            initialValues={{
                email: restaurantOwner.email,
                firstName: restaurantOwner.firstname,
                lastName: restaurantOwner.lastname,
                country: restaurantOwner.country as CountryCode,
                phoneNumber: restaurantOwner.phoneNumber,
                password: undefined,
                passwordConfirmation: undefined,
            }}
        />
    )
}

export default AccountDialog
