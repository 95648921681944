import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import clsx from 'clsx'
import ImageCard from 'components/common/imageCard'
import React from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import useStyles from './draggableSelectedImage.css'

interface Props {
    url: string
    index: number
    moveEntry: (from: number, to: number) => void
    deleteEntry: () => void
    children: (imageCard: React.ReactNode) => React.ReactNode
}

export const ItemTypes = {
    ESTABLISHMENT_PICTURE: 'establishmentPicture',
}

const DraggableSelectedImage: React.FC<Props> = ({ url, index, moveEntry, deleteEntry, children }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [{ isDragging }, drag] = useDrag<any, any, { isDragging: boolean }>({
        type: ItemTypes.ESTABLISHMENT_PICTURE,
        item: { index: index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const [{ isOver }, drop] = useDrop({
        accept: ItemTypes.ESTABLISHMENT_PICTURE,
        // @ts-ignore
        drop: (item) => moveEntry(item.index, index),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    })
    return (
        <Grid
            item
            lg={3}
            sm={4}
            xs={6}
            ref={drop}
            className={classNames({
                [classes.over]: isOver,
            })}
        >
            {children(
                <ImageCard
                    ref={drag}
                    index={0}
                    url={url}
                    title={t('form.entryN', { id: index + 1 })}
                    deleteEntry={deleteEntry}
                    className={clsx({
                        [classes.dragged]: isDragging,
                    })}
                    imageWidth={400}
                />,
            )}
        </Grid>
    )
}

export default DraggableSelectedImage
