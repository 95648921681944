import { AnyAbility } from '@casl/ability'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import { Establishment, User, useSignOut } from '@sugg-gestion/ubidreams-react-suggpro'
import AuthenticateLayout from 'components/layouts/authenticateLayout'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import { useSnackbar } from 'notistack'
import React, { ComponentType, PropsWithChildren } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import withAuthentication from './withAuthentication'
import withEstablishment from './withEstablishment'
import { WithGraphApiProps } from './withGraphApi'

interface WithAuthenticateLayoutDefault extends WithGraphApiProps {
    restaurantOwner: User
    establishments: Establishment[]
    ability: AnyAbility
}

type RequiredEstablishment = {
    establishment: Establishment
}

type OptionalEstablishment = {
    establishment?: Establishment
}

interface WithRequiredEstablishment extends WithAuthenticateLayoutDefault, RequiredEstablishment {}

interface WithOptionalEstablishment extends WithAuthenticateLayoutDefault, OptionalEstablishment {}

export type WithAuthenticateLayout<T extends boolean = true> = PropsWithChildren<
    T extends true ? WithRequiredEstablishment : WithOptionalEstablishment
>

interface InputProps extends WithGraphApiProps {
    restaurantOwner: User
    establishment?: Establishment
    establishments: Establishment[]
    ability: AnyAbility
}

const withAuthenticateLayout = <T extends boolean = true>(
    ComposedComponent: ComponentType<WithAuthenticateLayout<T>>,
    withRequiredEstablishment?: T,
) => {
    const WithAuthenticateLayout: React.FC<InputProps> = ({
        restaurantOwner,
        establishment,
        establishments,
        ability,
        ...props
    }) => {
        const { t } = useTranslation()
        const { enqueueSnackbar } = useSnackbar()
        const classes = useStyles()

        const { displayError } = useApiErrors()
        const { signOut } = useSignOut()

        const handleSignOut = () => {
            signOut()
                .then(() => {
                    enqueueSnackbar(t('views.signOut.success'), {
                        variant: 'success',
                    })
                })
                .catch((error) => displayError(error))
        }

        return (
            <AuthenticateLayout
                signOut={handleSignOut}
                restaurantOwner={restaurantOwner}
                establishments={establishments}
                ability={ability}
            >
                {establishment === undefined &&
                (withRequiredEstablishment === undefined || withRequiredEstablishment) ? (
                    <Alert severity="error" className={classes.alert}>
                        <Trans i18nKey="common.wrongEstablishment" />
                    </Alert>
                ) : (
                    <>
                        {/* @ts-ignore */}
                        <ComposedComponent
                            {...props}
                            establishment={establishment}
                            establishments={establishments}
                            restaurantOwner={restaurantOwner}
                            ability={ability}
                        />
                    </>
                )}
            </AuthenticateLayout>
        )
    }
    return withAuthentication(withEstablishment(WithAuthenticateLayout))
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        alert: {
            margin: theme.spacing(1, 0),
        },
    }),
)

export default withAuthenticateLayout
