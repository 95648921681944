import {
    actions as suggProActions,
    FacebookPageNotLinked,
    FacebookPagesResponse,
    FacebookSessionExpired,
    FacebookTokenExpired,
    FacebookTokenRequired,
    FacebookWrongPermissions,
    Slate,
    suggProSagas,
} from '@sugg-gestion/ubidreams-react-suggpro'
import { IgnoredError } from 'core/services/helper/errors'
import { difference } from 'lodash'
import { call, put, select, take } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'
import actions from '../../actions'
import { NotificationActionTypes } from '../../app/types'
import { ApplicationState } from '../../reducers'

export function* reauthenticateFacebook() {
    try {
        yield call(getFacebookAccessTokenDecorator, true)
    } catch (error) {
        if (error !== undefined && error === 'WrongPermissions') {
            yield put(
                actions.enqueueSnackbar({
                    message: 'sharing.facebook.wrongPermissions',
                    options: {
                        variant: 'error',
                    },
                }),
            )
        }
    }
}

const loginOnFacebook = (
    resolve: (value: string | PromiseLike<string>) => void,
    reject: (reason?: any) => void,
) => {
    const facebookRequiredPermissions = [
        'public_profile',
        'pages_manage_metadata',
        'pages_manage_posts',
        'pages_read_user_content',
        'pages_manage_engagement',
        'pages_manage_ads',
        'pages_read_engagement',
        'read_insights',
        'instagram_basic',
        'instagram_content_publish',
        'instagram_manage_insights',
    ]
    FB.login(
        (response: facebook.StatusResponse) => {
            switch (response.status) {
                case 'connected':
                    const { accessToken, grantedScopes } = response.authResponse
                    const hasRightPermissions =
                        difference(facebookRequiredPermissions, grantedScopes!!.split(',')).length === 0
                    if (hasRightPermissions) {
                        resolve(accessToken)
                    } else {
                        reject('WrongPermissions')
                    }
                    break
                default:
                    reject('Cancel')
            }
        },
        {
            scope: facebookRequiredPermissions.join(','),
            return_scopes: true,
        },
    )
}

const getFacebookAccessToken = (forceLogin: boolean = false): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        if (forceLogin) {
            loginOnFacebook(resolve, reject)
        } else {
            FB.getLoginStatus((response) => {
                switch (response.status) {
                    case 'connected':
                        const { accessToken } = response.authResponse
                        resolve(accessToken)
                        break
                    default:
                        loginOnFacebook(resolve, reject)
                }
            })
        }
    })
}

function* getFacebookAccessTokenDecorator(forceLogin: boolean = false) {
    const { user } = yield select(({ suggpro: { auth } }: ApplicationState) => ({
        user: auth.user,
    }))
    if (user.isFacebookSigned && !user.isFacebookSignedExpired) {
        return ''
    }
    try {
        const token: string = yield call(getFacebookAccessToken, forceLogin)
        return token
    } catch (error) {
        if (error !== undefined && error === 'WrongPermissions') {
            yield put(
                actions.enqueueSnackbar({
                    message: 'sharing.facebook.wrongPermissions',
                    options: {
                        variant: 'error',
                    },
                }),
            )
        }
        throw error
    }
}

function* shareSlateOnFacebook(slate: Slate, forceLogin: boolean = false): any {
    try {
        const facebookAccessToken = yield call(getFacebookAccessTokenDecorator, forceLogin)
        try {
            yield call(
                suggProSagas.shareSlate,
                suggProActions.shareSlate({
                    id: slate.id,
                    isFacebookShared: true,
                    facebookAccessToken,
                }),
            )
            yield put(actions.setIsSharingSlateOnFacebook(false))
        } catch (error) {
            if (
                error instanceof FacebookWrongPermissions ||
                error instanceof FacebookTokenExpired ||
                error instanceof FacebookTokenRequired ||
                error instanceof FacebookSessionExpired
            ) {
                yield put(
                    actions.enqueueSnackbar({
                        message: 'sharing.facebook.wrongPermissionsWrongPage',
                        options: {
                            variant: 'error',
                            persist: true,
                        },
                        actionType: NotificationActionTypes.FACEBOOK_REAUTH,
                    }),
                )
            } else if (error instanceof FacebookPageNotLinked) {
                yield call(getFacebookPagesAndShareSlate, slate)
                // noinspection ExceptionCaughtLocallyJS
                throw new IgnoredError()
            } else {
                yield put(actions.enqueueError({ error }))
            }
            yield put(actions.setIsSharingSlateOnFacebook(false))
            // noinspection ExceptionCaughtLocallyJS
            throw new IgnoredError()
        }
        yield put(
            actions.enqueueSnackbar({
                message: 'sharing.facebook.success',
                options: {
                    variant: 'success',
                },
            }),
        )
        yield put(actions.setIsSharingSlateOnFacebook(false))
    } catch (error) {
        if (
            !forceLogin &&
            (error instanceof FacebookWrongPermissions ||
                error instanceof FacebookTokenExpired ||
                error instanceof FacebookTokenRequired ||
                error instanceof FacebookSessionExpired)
        ) {
            yield call(shareSlateOnFacebook, slate, true)
            return
        }
        if (
            error !== undefined &&
            !(error instanceof IgnoredError) &&
            error !== 'Cancel' &&
            error !== 'WrongPermissions'
        ) {
            yield put(actions.enqueueError({ error }))
            yield put(actions.setIsSharingSlateOnFacebook(false))
        }
    }
}

function* getFacebookPages(slate: Slate, forceLogin: boolean = false): any {
    try {
        const facebookAccessToken = yield call(getFacebookAccessTokenDecorator, forceLogin)
        const facebookPages: FacebookPagesResponse = yield call(
            suggProSagas.getFacebookPages,
            suggProActions.getFacebookPages({ facebookAccessToken }),
        )
        return { facebookAccessToken, facebookPages }
    } catch (error) {
        if (
            !forceLogin &&
            (error instanceof FacebookWrongPermissions ||
                error instanceof FacebookTokenExpired ||
                error instanceof FacebookTokenRequired ||
                error instanceof FacebookSessionExpired)
        ) {
            return yield call(getFacebookPages, slate, true)
        }
        throw error
    }
}

function* getFacebookPagesAndShareSlate(slate: Slate) {
    const {
        facebookAccessToken,
        facebookPages,
    }: { facebookAccessToken: string; facebookPages: FacebookPagesResponse } = yield call(
        getFacebookPages,
        slate,
    )
    if (facebookPages.length > 1) {
        yield put(
            actions.setFacebookPagesData({
                slate,
                facebookAccessToken,
                pages: facebookPages,
                updateEstablishment: false,
            }),
        )
    } else {
        yield call(
            suggProSagas.connectToFacebookPage,
            suggProActions.connectToFacebookPage({
                id: slate.establishment.id,
                facebookAccessToken,
                pageId: facebookPages[0].id,
                updateEstablishment: false,
                facebookSync: true,
            }),
        )
        yield call(shareSlateOnFacebook, slate)
        yield put(actions.setIsSharingSlateOnFacebook(false))
    }
}

export function* connectAndShareSlateOnFacebook(action: ActionType<typeof actions.shareSlateOnFacebook>) {
    const { slate, establishment } = action.payload
    try {
        if (establishment.isFacebookLinked) {
            yield call(shareSlateOnFacebook, slate)
        } else {
            yield call(getFacebookPagesAndShareSlate, slate)
        }
    } catch (error) {
        yield put(actions.setIsSharingSlateOnFacebook(false))
    }
}

export function* selectFacebookPageData(action: ActionType<typeof actions.selectFacebookPageData>) {
    if (action.payload === undefined) {
        yield put(actions.setFacebookPagesData(undefined))
        yield put(actions.setIsSharingSlateOnFacebook(false))
        return
    }
    const { slate, facebookAccessToken, page, updateEstablishment } = action.payload
    yield put(actions.setFacebookPagesData(undefined))
    try {
        yield call(
            suggProSagas.connectToFacebookPage,
            suggProActions.connectToFacebookPage({
                id: slate.establishment.id,
                facebookAccessToken,
                pageId: page.id,
                updateEstablishment,
                facebookSync: true,
            }),
        )
        yield call(shareSlateOnFacebook, slate)
    } catch (error) {
        yield put(actions.enqueueError({ error }))
        yield put(actions.setIsSharingSlateOnFacebook(false))
    }
}

export function* shareSlateOnFacebookResolver(
    action: ActionType<typeof actions.shareSlateOnFacebookResolver>,
) {
    const resolve = action.payload
    if (resolve !== undefined) {
        yield take([getType(actions.setIsSharingSlateOnFacebook)])
        resolve()
    }
}
