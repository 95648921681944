import React, { ComponentType, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import { useHistory } from 'react-router-dom'
import Loader from 'components/common/loader'
import { User } from '@sugg-gestion/ubidreams-react-suggpro'
import { AnyAbility } from '@casl/ability'
import { WithGraphApiProps } from './withGraphApi'

export interface WithAuthenticationProps extends WithGraphApiProps {
    restaurantOwner: User
    ability: AnyAbility
}

const withAuthentication = (ComposedComponent: ComponentType<WithAuthenticationProps>) => {
    const WithAuthentication: React.FC<WithGraphApiProps> = (props) => {
        const history = useHistory()
        const { restaurantOwner, ability, signInInProgress } = useSelector(
            ({ suggpro: { auth }, app }: ApplicationState) => ({
                restaurantOwner: auth.user,
                ability: app.ability,
                signInInProgress: auth.signInInProgress,
            }),
        )
        useEffect(() => {
            if (!signInInProgress && !restaurantOwner) {
                history.push('/')
            }
        }, [restaurantOwner, history, signInInProgress])
        if (restaurantOwner) {
            return <ComposedComponent restaurantOwner={restaurantOwner} ability={ability} {...props} />
        } else {
            return <Loader />
        }
    }
    return WithAuthentication
}

export default withAuthentication
