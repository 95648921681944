import { Button, Grid, Typography } from '@material-ui/core'
import { Establishment, NewsModel } from '@sugg-gestion/ubidreams-react-suggpro'
import AuthenticateContent from 'components/layouts/components/authenticateContent'
import React from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import AuthenticateActions from '../../layouts/components/authenticateActions'
import EventView from './components/eventView'
import NewsView from './components/newsView'
import { useStyles } from './styles.css'

interface Props {
    newsList?: Array<NewsModel>
    establishment: Establishment
    shareOnGoogle: (eventId: NewsModel['id']) => void
    shareOnFacebook: (eventId: NewsModel['id']) => void
    shareOnInstagram: (eventId: NewsModel['id']) => void
    shareOnTwitter: (eventId: NewsModel['id']) => void
    onDelete: (eventId: NewsModel['id']) => void
    eventInProgress?: number
    deleteInProgress: boolean
    isSharingSlateOnFacebook: boolean
    isSharingSlateOnGoogle: boolean
    isSharingSlateOnInstagram: boolean
    isSharingSlateOnTwitter: boolean
    isGoogleSignedExpired: boolean
    isFacebookSignedExpired: boolean
    isTwitterSignedExpired: boolean
}

const NewsListView: React.FC<Props> = ({ newsList, establishment, ...viewProps }) => {
    const classes = useStyles()

    const establishmentBasePath = '/establishment/:id'.replace(':id', establishment.id.toString())

    return (
        <>
            <AuthenticateContent>
                <Typography variant="h1" color="textSecondary" className={classes.title}>
                    <Trans i18nKey="views.news.title" />
                </Typography>
                <Typography className={classes.newMessage}>
                    <Trans i18nKey="views.news.new" components={[<span className={classes.primary} />]} />
                </Typography>
                {(newsList ?? []).length === 0 && (
                    <div className={classes.emptyContainer}>
                        <Typography color="textSecondary" className={classes.empty}>
                            <Trans i18nKey="views.news.noData" />
                        </Typography>
                        <Grid container spacing={4} justifyContent="center">
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    to={establishmentBasePath + '/news/addNews'}
                                    component={Link}
                                >
                                    <Trans i18nKey="actions.addNews" />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    to={establishmentBasePath + '/news/addEvent'}
                                    component={Link}
                                >
                                    <Trans i18nKey="actions.addEvent" />
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                )}
                <Grid container spacing={6}>
                    {(newsList ?? []).map((news) => {
                        if (news.event !== null && news.images !== null) {
                            return (
                                <EventView
                                    key={news.id}
                                    // @ts-ignore
                                    event={news}
                                    establishment={establishment}
                                    showTwitter={false}
                                    {...viewProps}
                                />
                            )
                        }
                        return (
                            <NewsView
                                key={news.id}
                                news={news}
                                establishment={establishment}
                                showTwitter={false}
                                {...viewProps}
                            />
                        )
                    })}
                </Grid>
            </AuthenticateContent>
            <AuthenticateActions>
                <Button
                    variant="contained"
                    color="primary"
                    to={establishmentBasePath + '/news/addNews'}
                    component={Link}
                >
                    <Trans i18nKey="actions.addNews" />
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    to={establishmentBasePath + '/news/addEvent'}
                    component={Link}
                >
                    <Trans i18nKey="actions.addEvent" />
                </Button>
            </AuthenticateActions>
        </>
    )
}

export default NewsListView
