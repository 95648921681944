import { IconButton, Typography } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionActions from '@material-ui/core/AccordionActions'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import { GridRowData } from '@material-ui/data-grid'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import classNames from 'classnames'
import clsx from 'clsx'
import React from 'react'
import { Trans } from 'react-i18next'
import { ResponsibleDataGridProps } from './index'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flex: 1,
            color: '#6c6c6c',
            border: '1px solid rgba(224, 224, 224, 1)',
            display: 'flex',
            outline: 'none',
            position: 'relative',
            borderRadius: 4,
            flexDirection: 'column',
        },
        footerContainer: {
            display: 'flex',
            minHeight: 52,
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        spacer: {
            flex: '1 1 100%',
        },
        caption: {
            flexShrink: 0,
        },
        gridActions: {
            flexShrink: 0,
            marginLeft: 20,
        },
        pagination: {
            'color': '#6c6c6c',
            'overflow': 'auto',
            'fontSize': '0.875rem',
            '&:last-child': {
                padding: 0,
            },
        },
        heading: {
            color: theme.palette.text.secondary,
        },
        headingWithSecondary: {
            // flexBasis: '33.33%',
            flexShrink: 0,
            marginRight: theme.spacing(),
        },
        secondaryHeading: {
            color: theme.palette.text.primary,
        },
        summaryContent: {
            flexWrap: 'wrap',
            display: 'inline-flex',
            justifyContent: 'space-between',
        },
        actions: {
            flexWrap: 'wrap',
        },
        detail: {
            flexDirection: 'column',
        },
        data: {
            display: 'inline-flex',
            justifyContent: 'flex-end',
        },
        row: {
            '& > div': {
                margin: theme.spacing(1, 0),
            },
        },
    }),
)

interface Props extends ResponsibleDataGridProps {}

const DataList: React.FC<Props> = ({ className, rows, sortModel, pageSize, ...gridProps }) => {
    const classes = useStyles()

    const [expanded, setExpanded] = React.useState<string | false>(false)
    const [page, setPage] = React.useState(0)

    const handleChange = (panel: string) => (isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <div className={clsx(className, classes.root)}>
            {[...rows]
                .sort((row1, row2) => {
                    if (sortModel) {
                        for (let i = 0; i < sortModel.length; i++) {
                            const sortModelItem = sortModel[i]
                            if (sortModelItem.sort === 'desc') {
                                return row1[sortModelItem.field] > row2[sortModelItem.field] ? -1 : 1
                            }
                            return row1[sortModelItem.field] < row2[sortModelItem.field] ? -1 : 1
                        }
                    }
                    return 0
                })
                .slice(page * (pageSize || 10), page * (pageSize || 10) + (pageSize || 10))
                .map((row, index) => (
                    <DataListRow
                        {...gridProps}
                        row={row}
                        expanded={expanded === row.id}
                        handleChange={handleChange(row.id)}
                        key={index}
                    />
                ))}
            <div>
                <div className={classes.footerContainer}>
                    <div></div>
                    <div className={classes.pagination}>
                        <Toolbar>
                            <div className={classes.spacer} />
                            <Typography color="inherit" className={classes.caption}>
                                <Trans
                                    i18nKey="common.grid.pagination"
                                    values={{
                                        from: page * (pageSize || 10) + 1,
                                        to:
                                            page * (pageSize || 10) + (pageSize || 10) > rows.length
                                                ? rows.length
                                                : page * (pageSize || 10) + (pageSize || 10),
                                        length: rows.length,
                                    }}
                                />
                            </Typography>
                            <div className={classes.gridActions}>
                                <IconButton
                                    color="inherit"
                                    disabled={page === 0}
                                    onClick={() => {
                                        setPage(page - 1)
                                        setExpanded(false)
                                    }}
                                >
                                    <NavigateBeforeIcon />
                                </IconButton>
                                <IconButton
                                    color="inherit"
                                    disabled={page >= Math.floor(rows.length / (pageSize || 10))}
                                    onClick={() => {
                                        setPage(page + 1)
                                        setExpanded(false)
                                    }}
                                >
                                    <NavigateNextIcon />
                                </IconButton>
                            </div>
                        </Toolbar>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface DataListRowProps extends Omit<ResponsibleDataGridProps, 'rows'> {
    expanded: boolean
    handleChange: (isExpanded: boolean) => void
    row: GridRowData
}

const DataListRow: React.FC<DataListRowProps> = ({
    columns,
    row,
    primaryKey,
    secondaryKey,
    expanded,
    handleChange,
    getRowClassName,
    getPrimaryHeaderClassName,
    getSecondaryHeaderClassName,
}) => {
    const classes = useStyles()
    const getSummary = () => {
        const columnPrimaryData = columns.find((column) => column.field === primaryKey)
        const primary = (
            <Typography
                color="inherit"
                className={classNames(
                    getPrimaryHeaderClassName
                        ? getPrimaryHeaderClassName(
                              // @ts-ignore
                              { row },
                          )
                        : classes.heading,
                    {
                        [classes.headingWithSecondary]: secondaryKey !== undefined,
                    },
                )}
            >
                {columnPrimaryData && columnPrimaryData.renderCell ? (
                    columnPrimaryData.renderCell(
                        // @ts-ignore
                        { row: row, value: row[primaryKey] },
                    )
                ) : typeof row[primaryKey] === 'string' ? (
                    <Typography>{row[primaryKey]}</Typography>
                ) : (
                    row[primaryKey]
                )}
            </Typography>
        )

        const columnSecondaryData = columns.find((column) => column.field === secondaryKey)
        const secondary = secondaryKey ? (
            <Typography
                className={clsx(
                    getSecondaryHeaderClassName
                        ? getSecondaryHeaderClassName(
                              // @ts-ignore
                              { row },
                          )
                        : classes.secondaryHeading,
                )}
            >
                {columnSecondaryData && columnSecondaryData.renderCell ? (
                    columnSecondaryData.renderCell(
                        // @ts-ignore
                        { row: row, value: row[secondaryKey] },
                    )
                ) : typeof row[secondaryKey] === 'string' ? (
                    <Typography>{row[secondaryKey]}</Typography>
                ) : (
                    row[secondaryKey]
                )}
            </Typography>
        ) : undefined

        return { primary, secondary }
    }
    const summary = getSummary()
    return (
        <Accordion expanded={expanded} onChange={(_, expanded) => handleChange(expanded)}>
            <AccordionSummary
                classes={{
                    root: getRowClassName
                        ? getRowClassName(
                              // @ts-ignore
                              { row },
                          )
                        : '',
                    content: classes.summaryContent,
                }}
                expandIcon={<ExpandMoreIcon />}
            >
                {summary.primary}
                {summary.secondary}
            </AccordionSummary>
            <AccordionDetails
                classes={{
                    root: classes.detail,
                }}
            >
                {columns
                    .filter((column) => column.field !== 'actions')
                    .map((column, columnIndex) => {
                        const rowData = row[column.field]
                        return (
                            <div key={column.field} className={classes.row}>
                                {columnIndex > 0 && <Divider />}
                                <Grid container>
                                    <Grid item sm={6} xs={12}>
                                        <Typography color="textSecondary">{column.headerName}</Typography>
                                    </Grid>
                                    <Grid item sm={6} xs={12} className={classes.data}>
                                        {column.renderCell ? (
                                            column.renderCell(
                                                // @ts-ignore
                                                { row: row, value: rowData },
                                            )
                                        ) : typeof rowData === 'string' ? (
                                            <Typography>{rowData}</Typography>
                                        ) : (
                                            rowData
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        )
                    })}
            </AccordionDetails>
            {columns
                .filter((column) => column.field === 'actions')
                .map((column) => {
                    const rowData = row[column.field]
                    return (
                        <React.Fragment key={column.field}>
                            <Divider />
                            <AccordionActions className={classes.actions}>
                                {column.renderCell ? (
                                    column.renderCell(
                                        // @ts-ignore
                                        { row: row, value: rowData },
                                    )
                                ) : typeof rowData === 'string' ? (
                                    <Typography>{rowData}</Typography>
                                ) : (
                                    rowData
                                )}
                            </AccordionActions>
                        </React.Fragment>
                    )
                })}
        </Accordion>
    )
}

export default DataList
