import React, { useState } from 'react'
import { useFormsStyles } from '../forms.css'
import { Form, FormSpy, FormSpyRenderProps } from 'react-final-form'
import SubmitButton, { SubmitButtonProps } from 'components/common/submitButton'
import { Trans, useTranslation } from 'react-i18next'
import { Location } from '@sugg-gestion/ubidreams-react-suggpro'
import { useNominatim } from 'core/services/location/useNominatim'
import { useBrowserLocalization } from 'core/services/location/useBrowserLocalization'
import { FormHelperText, Grid, Typography } from '@material-ui/core'
import TextField from '../components/textField'
import { useSnackbar } from 'notistack'
import LocationField from '../components/locationField'
import CountryField from '../components/countryField'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: LocationFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: LocationFormState
}

export interface LocationFormState {
    position?: Location
    address_street_1: string
    address_street_2?: string
    address_zip_code: string
    address_city: string
    address_country: string
}

interface LocationFormError {
    position?: string
    address_street_1?: string
    address_street_2?: string
    address_zip_code?: string
    address_city?: string
    address_country?: string
}

const LocationForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const [localizeMyselfInProgress, setLocalizeMyselfInProgress] = useState<boolean>(false)
    const [localizeMyAddressInProgress, setLocalizeMyAddressInProgress] = useState<boolean>(false)
    const { geocode, reverseGeocode } = useNominatim()
    const { isBrowserLocalizationAvailable, localize } = useBrowserLocalization()

    const validate = (values: LocationFormState) => {
        const errors: LocationFormError = {}

        if (!values.address_street_1) {
            errors.address_street_1 = t('form-validation.companyAddressStreet1')
        }

        if (!values.address_zip_code) {
            errors.address_zip_code = t('form-validation.companyAddressZipCode')
        }

        if (!values.address_city) {
            errors.address_city = t('form-validation.companyAddressCity')
        }

        if (!values.address_country) {
            errors.address_country = t('form-validation.companyAddressCountry')
        }

        if (!values.position) {
            errors.position = t('form-validation.position')
        }

        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            mutators={{
                setPosition: (args, state, tools) => {
                    const position = args[0] as Location
                    tools.changeValue(state, 'position', () => position)
                },
                setAddress: (args, state, tools) => {
                    const data = args[0]
                    for (let type in data.address) {
                        if (data.address.hasOwnProperty(type)) {
                            switch (type) {
                                case 'building': {
                                    if (!data.address[type].startsWith('@')) {
                                        tools.changeValue(state, 'address_street_1', () => data.address[type])
                                    }
                                    break
                                }
                                case 'road': {
                                    if (data.address['house_number']) {
                                        tools.changeValue(state, 'address_street_1', () => {
                                            return data.address['house_number'] + ' ' + data.address[type]
                                        })
                                    } else if (
                                        data.address['building'] &&
                                        data.address['building'].startsWith('@')
                                    ) {
                                        tools.changeValue(state, 'address_street_1', () => {
                                            const number = data.address['building'].substring(1)
                                            return number + ' ' + data.address[type]
                                        })
                                    } else {
                                        tools.changeValue(state, 'address_street_1', () => data.address[type])
                                    }
                                    break
                                }
                                case 'village': {
                                    tools.changeValue(state, 'address_city', () => data.address[type])
                                    break
                                }
                                case 'city': {
                                    tools.changeValue(state, 'address_city', () => data.address[type])
                                    break
                                }
                                case 'postcode': {
                                    tools.changeValue(state, 'address_zip_code', () => data.address[type])
                                    break
                                }
                            }
                        }
                    }
                },
            }}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting, form: { mutators } }) => {
                const localizeAddress = (props: FormSpyRenderProps) => {
                    setLocalizeMyAddressInProgress(true)
                    let street = undefined
                    let city = undefined
                    let country = undefined
                    if (props.values.address_street_1 !== undefined && props.values.address_street_1 !== '') {
                        street = props.values.address_street_1
                    }
                    if (
                        street &&
                        props.values.address_street_2 !== undefined &&
                        props.values.address_street_2 !== ''
                    ) {
                        street = props.values.address_street_2
                    }
                    if (props.values.address_city !== undefined && props.values.address_city !== '') {
                        city = props.values.address_city
                    }
                    if (props.values.address_country !== undefined && props.values.address_country !== '') {
                        country = t('countries.' + props.values.address_country)
                    }
                    if (!(street && city)) {
                        setLocalizeMyAddressInProgress(false)
                        enqueueSnackbar(t('form.localizationFailed'), {
                            variant: 'info',
                        })
                        return new Promise<void>((resolve) => resolve())
                    }
                    return geocode(street, city, country)
                        .then((result) => {
                            setLocalizeMyAddressInProgress(false)
                            if (result[0]) {
                                const position: Location = {
                                    lng: parseFloat(result[0].lon),
                                    lat: parseFloat(result[0].lat),
                                }
                                mutators.setPosition(position)
                            } else {
                                enqueueSnackbar(t('form.localizationFailed'), {
                                    variant: 'info',
                                })
                            }
                        })
                        .catch((_) => {
                            enqueueSnackbar(t('form.localizationFailed'), {
                                variant: 'info',
                            })
                            setLocalizeMyAddressInProgress(false)
                        })
                }

                const localizeMySelf = () => {
                    setLocalizeMyselfInProgress(true)
                    localize()
                        .then((position) => {
                            mutators.setPosition(position)
                            if (position.lat && position.lng) {
                                reverseGeocode(position.lat, position.lng)
                                    .then((result) => {
                                        mutators.setAddress(result)
                                        setLocalizeMyselfInProgress(false)
                                    })
                                    .catch((_) => {
                                        setLocalizeMyselfInProgress(false)
                                    })
                            }
                        })
                        .catch(() => {
                            setLocalizeMyselfInProgress(false)
                            enqueueSnackbar(t('error.locationFailed'), {
                                variant: 'error',
                            })
                        })
                }
                return (
                    <form onSubmit={handleSubmit} noValidate className={formClassName}>
                        {children(
                            <>
                                <Typography variant="h4">
                                    <Trans i18nKey="form.checkAddress" />
                                </Typography>
                                <Grid container spacing={8}>
                                    <Grid item xs={12} md={6}>
                                        <div className={formsClasses.localizeButton} />
                                        <LocationField name="position" update={0} />
                                        <FormHelperText className={formsClasses.helper}>
                                            <Trans i18nKey="form.localizeAddressHelper" />
                                        </FormHelperText>
                                        {isBrowserLocalizationAvailable && (
                                            <SubmitButton
                                                variant="outlined"
                                                color="primary"
                                                loading={submitting || localizeMyselfInProgress}
                                                onClick={localizeMySelf}
                                                className={formsClasses.localizeButton}
                                            >
                                                <Trans i18nKey="form.localizeMyself" />
                                            </SubmitButton>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6} className={formsClasses.addressColumn}>
                                        <TextField
                                            label={t('form.companyAddressStreet1')}
                                            name="address_street_1"
                                            autoComplete="billing address-line1"
                                            required
                                        />
                                        <TextField
                                            label={t('form.companyAddressStreet2')}
                                            name="address_street_2"
                                            autoComplete="billing address-line2"
                                        />
                                        <TextField
                                            label={t('form.companyAddressZipCode')}
                                            name="address_zip_code"
                                            autoComplete="billing postal-code"
                                            required
                                        />
                                        <TextField
                                            label={t('form.companyAddressCity')}
                                            name="address_city"
                                            autoComplete="billing address-level2"
                                            required
                                        />
                                        <CountryField name="address_country" />
                                        <div className={formsClasses.localizeAddress}>
                                            <FormSpy
                                                subscription={{
                                                    values: true,
                                                }}
                                            >
                                                {(props) => (
                                                    <SubmitButton
                                                        variant="outlined"
                                                        color="primary"
                                                        loading={localizeMyAddressInProgress}
                                                        disabled={
                                                            !(
                                                                props.values.address_street_1 &&
                                                                props.values.address_zip_code &&
                                                                props.values.address_city
                                                            ) ||
                                                            submitting ||
                                                            inProgress
                                                        }
                                                        onClick={() => localizeAddress(props)}
                                                        className={formsClasses.completeButton}
                                                    >
                                                        <Trans i18nKey="form.localizeAddress" />
                                                    </SubmitButton>
                                                )}
                                            </FormSpy>
                                        </div>
                                    </Grid>
                                </Grid>
                            </>,
                            [
                                {
                                    type: 'submit',
                                    loading: submitting || inProgress,
                                    className: formsClasses.submit,
                                    children: <Trans i18nKey="actions.next" />,
                                },
                            ],
                        )}
                    </form>
                )
            }}
        </Form>
    )
}

export default LocationForm
