import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

const useClipboard = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const [inProgress, setInProgress] = useState<boolean>(false)

    const copyToClipboard = (text: string) => {
        setInProgress(true)
        if (navigator.clipboard) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    enqueueSnackbar(t('copy.success'), {
                        variant: 'success',
                    })
                    setInProgress(false)
                })
                .catch(() => {
                    enqueueSnackbar(t('copy.error'), {
                        variant: 'error',
                    })
                    setInProgress(false)
                })
        } else {
            enqueueSnackbar(t('copy.error'), {
                variant: 'error',
            })
            setInProgress(false)
        }
    }
    return {
        copyToClipboard,
        inProgress,
    }
}

export default useClipboard
