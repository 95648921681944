import { Avatar, ButtonBase, Grid, Typography } from '@material-ui/core'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import {
    Establishment,
    MessagingConversation,
    MessagingMessage,
    PostDetails,
    SlateImage,
    useDeleteConversationMessage,
    useHideConversationMessage,
} from '@sugg-gestion/ubidreams-react-suggpro'
import ReplyPostForm from 'components/forms/messaging/replyPostForm'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useApiErrors } from '../../../../core/services/suggPro/useApiErrors'
import actions from '../../../../core/store/actions'
import { TextLookBetter } from '../messagingUtils'
import { useStyles } from '../styles.css'
import CommentContainer from './commentContainer'
import PostMetricsBar from './postMetricsBar'
import ZoomDialog from 'components/dialogs/zoomDialog'
import clsx from 'clsx'
import { useFlippable } from 'components/helpers/useFlippable'
import moment from 'moment'

interface Props {
    establishment: Establishment
    conversation: MessagingConversation
    postDetails: PostDetails
    comments: MessagingMessage[]
}

const PostContainer: React.FC<Props> = ({ establishment, conversation, postDetails, comments }) => {
    const classes = useStyles()
    const { displayError } = useApiErrors()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const { classes: flippableClasses } = useFlippable()
    const [zoom, setZoom] = useState<SlateImage | undefined>(undefined)

    const handleZoom = (image: SlateImage) => {
        setZoom(image)
    }
    const handleClose = () => {
        setZoom(undefined)
    }
    const { deleteConversationMessage } = useDeleteConversationMessage()
    const { hideConversationMessage } = useHideConversationMessage()

    const handleDeleteMessage = (block: boolean, messageId: string) => {
        return deleteConversationMessage({
            establishment: establishment.id,
            id: conversation.id,
            block,
            messageId,
        })
    }

    const handleHideMessage = (block: boolean, messageId: string) => {
        return hideConversationMessage({
            establishment: establishment.id,
            id: conversation.id,
            block,
            messageId,
        })
    }

    const onDeleteMessage = (messageId: string) => {
        dispatch(
            actions.alert({
                content: t('views.messaging.deleteMessage.confirmation'),
                buttons: [
                    {
                        variant: 'contained',
                        children: t('common.no'),
                    },
                    {
                        color: 'primary',
                        variant: 'contained',
                        children: t('common.yes'),
                        onClick: () =>
                            handleDeleteMessage(false, messageId)
                                .then(() => {
                                    enqueueSnackbar(t('views.messaging.deleteMessage.success'), {
                                        variant: 'success',
                                    })
                                })
                                .catch((error) => displayError(error)),
                    },
                ],
            }),
        )
    }

    const onBlockUser = (messageId: string) => {
        dispatch(
            actions.alert({
                content: t('views.messaging.deleteMessage.confirmation'),
                buttons: [
                    {
                        variant: 'contained',
                        children: t('common.no'),
                    },
                    {
                        color: 'primary',
                        variant: 'contained',
                        children: t('common.yes'),
                        onClick: () =>
                            handleDeleteMessage(false, messageId)
                                .then(() => {
                                    enqueueSnackbar(t('views.messaging.deleteMessage.success'), {
                                        variant: 'success',
                                    })
                                })
                                .catch((error) => displayError(error)),
                    },
                ],
            }),
        )
    }

    const onHideMessage = (messageId: string) => {
        dispatch(
            actions.alert({
                content: t('views.messaging.hideMessage.confirmation'),
                buttons: [
                    {
                        variant: 'contained',
                        children: t('common.no'),
                    },
                    {
                        color: 'primary',
                        variant: 'contained',
                        children: t('common.yes'),
                        onClick: () =>
                            handleHideMessage(false, messageId)
                                .then(() => {
                                    enqueueSnackbar(t('views.messaging.hideMessage.success'), {
                                        variant: 'success',
                                    })
                                })
                                .catch((error) => displayError(error)),
                    },
                ],
            }),
        )
    }

    return (
        <>
            <Grid container>
                {/* Post area */}
                <Grid item sm={2} xs={false} />
                <Grid item sm={8} xs={12}>
                    <div className={classes.discussPost}>
                        <div className={classes.discussPostHat}>
                            <Avatar
                                src={postDetails.author.img ?? undefined}
                                className={classes.discussPostImg}
                            />
                            <div className={classes.discussPostInfo}>
                                <div className={classes.discussPostInfoAuthorName}>
                                    <a
                                        className={classes.userProfileLink}
                                        href={postDetails.author.link || ''}
                                        target={'_blank'}
                                        rel={'noreferrer'}
                                        title={t('views.messaging.lookupProfile')}
                                    >
                                        {postDetails.author.name ?? t('views.messaging.unknownUser')}
                                    </a>
                                    {postDetails.author.username && (
                                        <Typography className={classes.username}>
                                            {conversation.typePlatform === 'twitter' && '@'}
                                            {postDetails.author.username}
                                        </Typography>
                                    )}
                                </div>
                                <div className={classes.discussPostInfoDate}>
                                    {moment(postDetails.createdAt).format('DD/MM/YYYY HH:mm')}
                                </div>
                            </div>
                        </div>
                        <div className={classes.discussPostBody}>
                            {/* Star Rating */}
                            {postDetails.star && (
                                <div
                                    className={classes.discussPostBodyRating}
                                    title={t('views.messaging.postMetrics.starCount', {
                                        value: postDetails.star,
                                    })}
                                >
                                    <Typography>{t('views.messaging.ratting')} : </Typography>
                                    {[0, 1, 2, 3, 4].map((i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                {i < postDetails.star ? (
                                                    <StarIcon color={'primary'} key={i} />
                                                ) : (
                                                    <StarBorderIcon color={'primary'} key={i} />
                                                )}
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            )}
                            {postDetails.text && (
                                <Typography className={classes.discussPostBodyText} noWrap={false}>
                                    <TextLookBetter text={postDetails.text} />
                                </Typography>
                            )}
                            {postDetails.medias && postDetails.medias.length > 0 && (
                                <Grid container spacing={1} className={classes.discussPostBodyMedia}>
                                    {/* Browse media */}
                                    {postDetails.medias.map((media, mediaIndex) => {
                                        let image: SlateImage = {
                                            comment: '',
                                            facebookPhotoId: '',
                                            googlePhotoId: '',
                                            id: 0,
                                            instagramPermalink: '',
                                            instagramPostId: '',
                                            isFacebookShared: false,
                                            isGoogleShared: false,
                                            isInstagramShared: false,
                                            isTwitterShared: false,
                                            picture: media.url,
                                        }
                                        return (
                                            <Grid
                                                item
                                                sm={postDetails.medias.length > 1 ? 6 : 12}
                                                xs={postDetails.medias.length > 1 ? 6 : 12}
                                                key={mediaIndex}
                                            >
                                                {media.type === 'video' ? (
                                                    <video
                                                        controls
                                                        style={{ width: '100%' }}
                                                        src={media.url}
                                                    />
                                                ) : (
                                                    <ButtonBase
                                                        onClick={() => handleZoom(image)}
                                                        itemProp="photo"
                                                        className={clsx(
                                                            classes.photoButton,
                                                            flippableClasses.flippable,
                                                            flippableClasses.flipFront,
                                                        )}
                                                    >
                                                        <img
                                                            src={media.url}
                                                            className={classes.postMediaImage}
                                                            alt={'post media ' + mediaIndex}
                                                        />
                                                    </ButtonBase>
                                                )}
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            )}
                        </div>
                        <PostMetricsBar postDetails={postDetails} />
                        {/* Reply zone */}
                        <div className={classes.replyBox}>
                            <div className={classes.replyBoxContainer}>
                                <ReplyPostForm conversation={conversation} messageCount={comments.length} />
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item sm={2} xs={false} />
                {/* Comment container */}
                <Grid item sm={2} xs={false} />
                <Grid item sm={8} xs={12}>
                    <CommentContainer
                        establishment={establishment}
                        conversation={conversation}
                        comments={comments}
                        onDeleteMessage={onDeleteMessage}
                        onHideMessage={onHideMessage}
                        onBlockUser={onBlockUser}
                    />
                </Grid>
                <Grid item sm={2} xs={false} />
            </Grid>
            {zoom && <ZoomDialog open={true} image={zoom} extImage={true} onClose={handleClose} />}
        </>
    )
}

export default PostContainer
