import axios from 'axios'

export const client = axios.create({
    baseURL:
        'https://graphql.contentful.com/content/v1/spaces/' +
        process.env.REACT_APP_CONTENTFUL_SPACE_ID +
        '/environments/' +
        process.env.REACT_APP_CONTENTFUL_STAGE,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    },
})
