import React from 'react'
import AuthenticateContent from 'components/layouts/components/authenticateContent'
import { Grid, Typography } from '@material-ui/core'
import { useStyles } from './styles.css'
import { Trans } from 'react-i18next'
import HubspotForm from 'components/common/hubspotForm'
import LottieAnimation from 'components/common/lottieAnimation'
import animation from 'assets/animations/8253-fresh-new-job.json'

interface Props {
    onFormReady?: (form: HTMLFormElement) => void
}

const RecruitmentView: React.FC<Props> = ({ onFormReady }) => {
    const classes = useStyles()

    return (
        <AuthenticateContent withAction={false} className={classes.container}>
            <Typography variant="h1" color="textSecondary" className={classes.title}>
                <Trans i18nKey="views.recruitment.title" />
            </Typography>
            <LottieAnimation
                className={classes.animation}
                options={{
                    animationData: animation,
                }}
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography className={classes.message}>
                        <Trans i18nKey="views.recruitment.message" />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <HubspotForm formId="0b2bdc35-d4d1-4510-8375-6c17cfd83af6" onFormReady={onFormReady} />
                </Grid>
            </Grid>
        </AuthenticateContent>
    )
}

export default RecruitmentView
