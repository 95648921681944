export interface ImgixParams {
    [key: string]: string | number
}

export const useImgix = () => {
    const getImage = (url: string, params?: ImgixParams) => {
        if (url.startsWith('data:image')) {
            return url
        }
        let queryString = url + '?rot=0'
        if (params) {
            for (let param in params) {
                if (params.hasOwnProperty(param)) {
                    queryString += '&' + param + '=' + params[param]
                }
            }
        }
        return queryString
    }

    return {
        getImage,
    }
}
