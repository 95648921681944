import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'relative',
        },
        title: {
            paddingBottom: theme.spacing(4),
        },
        message: {
            paddingRight: 136,
            [theme.breakpoints.down('xs')]: {
                paddingRight: 'inherit',
            },
        },
        animation: {
            width: 120,
            height: 120,
            position: 'absolute',
            top: 0,
            right: 0,
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
    }),
)
