import { Chip, Container, Divider, Typography } from '@material-ui/core'
import moment from 'moment'
import React, { PropsWithChildren } from 'react'
import { Trans } from 'react-i18next'
import BookingDayForm from '../../forms/establishment/bookingDaysForm'
import AuthenticateContent from '../../layouts/components/authenticateContent'
import { useStyles } from './styles.css'

interface Props {
    fullDays: Array<string>
    onFullDayUpdate: (days: Array<string>) => void
    bookingInProgress: boolean
}

const BookingView: React.FC<PropsWithChildren<Props>> = ({
    fullDays,
    onFullDayUpdate,
    bookingInProgress,
    children,
}) => {
    const classes = useStyles()

    return (
        <AuthenticateContent withAction={false}>
            <Typography variant="h1" className={classes.title}>
                <Trans i18nKey="views.booking.fullDay.title" />
            </Typography>
            {fullDays.length === 0 ? (
                <Typography>
                    <Trans i18nKey="views.booking.fullDay.none" />
                </Typography>
            ) : (
                <ul className={classes.root}>
                    {fullDays.map((day, index) => (
                        <li key={index}>
                            <Chip
                                color="secondary"
                                disabled={bookingInProgress}
                                className={classes.chip}
                                label={moment(day).format('DD/MM/YYYY')}
                                onDelete={() => {
                                    const newDays = fullDays.filter(
                                        (currentDay) => !moment(currentDay).isSame(day, 'day'),
                                    )
                                    onFullDayUpdate(newDays)
                                }}
                            />
                        </li>
                    ))}
                </ul>
            )}
            <Container maxWidth="sm" className={classes.container}>
                <Divider variant="middle" className={classes.divider} />
                <Typography>
                    <Trans i18nKey="views.booking.fullDay.add" />
                </Typography>
                <BookingDayForm
                    initialValues={{
                        day: moment(),
                    }}
                    inProgress={bookingInProgress}
                    onSubmit={(values) => {
                        const newDays = fullDays.filter((day) => !moment(day).isSame(values.day, 'day'))
                        newDays.push(values.day.format())
                        onFullDayUpdate(newDays)
                    }}
                />
            </Container>
            <Divider variant="middle" className={classes.divider} />
            <div className={classes.root}>{children}</div>
        </AuthenticateContent>
    )
}

export default BookingView
