import React from 'react'
import { Button, MobileStepper } from '@material-ui/core'
import { Trans } from 'react-i18next'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import { useStyles } from './videoStepper.css'

interface Props {
    numberOfPage: number
    page: number
    handleNextPage: () => void
    handlePreviousPage: () => void
}

const VideoStepper: React.FC<Props> = ({ numberOfPage, page, handleNextPage, handlePreviousPage }) => {
    const classes = useStyles()
    return (
        <MobileStepper
            steps={numberOfPage}
            position="static"
            variant="text"
            activeStep={page}
            nextButton={
                <Button color="primary" disabled={page === numberOfPage - 1} onClick={handleNextPage}>
                    <Trans i18nKey="views.tuto.actions.next" />
                    <KeyboardArrowRight />
                </Button>
            }
            backButton={
                <Button color="primary" disabled={page === 0} onClick={handlePreviousPage}>
                    <KeyboardArrowLeft />
                    <Trans i18nKey="views.tuto.actions.previous" />
                </Button>
            }
            className={classes.root}
        />
    )
}

export default VideoStepper
