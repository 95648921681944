import React, { ComponentType, useEffect, useState } from 'react'
import mainSaga from 'core/store/sagas'
import { SagaMiddleware } from '@redux-saga/core'

interface InputProps {
    sagaMiddleware: SagaMiddleware
    suggProInit: boolean
}
interface OutputProps {
    sagaMiddleware: SagaMiddleware
}

const withSaga = (ComposedComponent: ComponentType<OutputProps>) => {
    const WithSaga: React.FC<InputProps> = ({ sagaMiddleware, suggProInit, ...props }) => {
        const [sagaInit, setSagaInit] = useState(false)
        useEffect(() => {
            if (suggProInit && !sagaInit) {
                setSagaInit(true)
                sagaMiddleware.run(mainSaga)
            }
        }, [sagaInit, sagaMiddleware, suggProInit])
        return <ComposedComponent sagaMiddleware={sagaMiddleware} {...props} />
    }
    return WithSaga
}

export default withSaga
