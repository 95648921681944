import React from 'react'
import withAuthenticateLayout, { WithAuthenticateLayout } from 'containers/hoc/withAuthenticateLayout'
import MessagingView from 'components/views/messaging/messagingView'
import Loader from 'components/common/loader'
import withMuiPickersUtilsProvider from '../hoc/withMuiPickersUtilsProvider'
import MessagingPermissions from './permissions'

interface Props extends WithAuthenticateLayout {}

const Messaging: React.FC<Props> = ({ establishment, restaurantOwner, ability }) => {
    const shouldAskPermissions = () => {
        // Facebook
        if (
            restaurantOwner.isFacebookSigned &&
            (restaurantOwner.facebookScopes.indexOf('pages_messaging') === -1 ||
                restaurantOwner.facebookScopes.indexOf('pages_manage_metadata') === -1)
        ) {
            return true
        }
        // Instagram
        else if (
            establishment.instagramPageId &&
            (restaurantOwner.facebookScopes.indexOf('instagram_manage_messages') === -1 ||
                restaurantOwner.facebookScopes.indexOf('instagram_manage_comments') === -1)
        ) {
            return true
        }
        return false
    }

    // statistics && newsStatistics
    // no true work perfectly
    // TODO: mettre une condition
    if (true) {
        return (
            <>
                {shouldAskPermissions() && (
                    <MessagingPermissions restaurantOwner={restaurantOwner} establishment={establishment} />
                )}
                <MessagingView establishment={establishment} />
            </>
        )
    } else {
        return <Loader withLogo={false} />
    }
}

export default withMuiPickersUtilsProvider(withAuthenticateLayout(Messaging))
