import {
    SlateImage,
    useEstablishmentSlate,
    useShareSlate,
    useUpdateSlate,
} from '@sugg-gestion/ubidreams-react-suggpro'
import { ShareImagesFormState } from 'components/forms/slate/shareImagesForm'
import AuthenticatedLoading from 'components/layouts/components/authenticatedLoading'
import EmptySlateView from 'components/views/slate/emptyView'
import SlateView from 'components/views/slate/slateView'
import withAuthenticateLayout, { WithAuthenticateLayout } from 'containers/hoc/withAuthenticateLayout'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExtraShared from '../../components/views/slate/components/extraShared'
import ShareImagesDialog from '../../components/views/slate/slate/shareImagesDialog'
import useRestaurantsApi from '../../core/services/restaurant/useRestaurantsApi'
import EstablishmentEditFacebookGoogleDialog from '../establishment/dialogs/establishmentEditFacebookGoogleDialog'
import withFacebookAndGoogle from '../hoc/withFacebookAndGoogle'
import withSwiper from '../hoc/withSwiper'

interface Props extends WithAuthenticateLayout {}

export enum SocialNetwork {
    Facebook = 'Facebook',
    Instagram = 'Instagram',
    Google = 'Google',
    Twitter = 'Twitter',
}

const Slate: React.FC<Props> = ({ establishment, restaurantOwner, ability }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const { displayError } = useApiErrors()
    const { isReady, establishmentSlate, error } = useEstablishmentSlate(establishment.id)
    const { shareSlate: shareOnGoogle, inProgress: shareOnGoogleInProgress } = useShareSlate()
    const { shareSlate: shareOnFacebook, inProgress: shareOnFacebookInProgress } = useShareSlate()
    const { shareSlate: shareOnInstagram, inProgress: shareOnInstagramInProgress } = useShareSlate()
    const { shareSlate: shareOnTwitter, inProgress: shareOnTwitterInProgress } = useShareSlate()
    const { updateSlate: updateImages, inProgress: updateImagesInProgress } = useUpdateSlate()

    const { menuLink } = useRestaurantsApi(restaurantOwner, establishment)

    const [openFacebookGoogle, setOpenFacebookGoogle] = useState<boolean>(false)
    const [shareImages, setShareImages] = useState<SocialNetwork | undefined>(undefined)

    useEffect(() => {
        if (error !== undefined) {
            displayError(error)
        }
    }, [error, displayError])

    if (!isReady) {
        return <AuthenticatedLoading title={t('views.slate.title')} loadingMessage={t('common.loading')} />
    }
    if (establishmentSlate) {
        const handleShareOnGoogle = () => {
            if (establishment.googleId) {
                return shareOnGoogle({
                    id: establishmentSlate.id,
                    isGoogleShared: true,
                    googleAccessToken: '',
                })
                    .then(() => {
                        enqueueSnackbar(t('views.slate.add.successGoogle'), {
                            variant: 'success',
                        })
                    })
                    .catch((error) => displayError(error))
            } else {
                setOpenFacebookGoogle(true)
            }
        }

        const handleShareOnFacebook = () => {
            if (establishment.facebookPageId) {
                return shareOnFacebook({
                    id: establishmentSlate.id,
                    isFacebookShared: true,
                    facebookAccessToken: '',
                })
                    .then(() => {
                        enqueueSnackbar(t('views.slate.add.successFacebook'), {
                            variant: 'success',
                        })
                    })
                    .catch((error) => displayError(error))
            } else {
                setOpenFacebookGoogle(true)
            }
        }

        const handleShareOnInstagram = () => {
            if (establishment.instagramPageId) {
                return shareOnInstagram({
                    id: establishmentSlate.id,
                    isInstagramShared: true,
                })
                    .then(() => {
                        enqueueSnackbar(t('views.slate.add.successInstagram'), {
                            variant: 'success',
                        })
                    })
                    .catch((error) => displayError(error))
            } else {
                setOpenFacebookGoogle(true)
            }
        }

        const handleShareOnTwitter = () => {
            if (establishment.twitterID && !establishment.isTwitterSignedExpired) {
                return shareOnTwitter({
                    id: establishmentSlate.id,
                    isTwitterShared: true,
                })
                    .then(() => {
                        enqueueSnackbar(t('views.slate.add.successTwitter'), {
                            variant: 'success',
                        })
                    })
                    .catch((error) => displayError(error))
            } else {
                setOpenFacebookGoogle(true)
            }
        }

        const handleClose = () => {
            setOpenFacebookGoogle(false)
        }

        let images: Array<SlateImage> = []
        if (establishmentSlate.images && establishmentSlate.images.length > 0) {
            images.push(...establishmentSlate.images)
        } else if (establishmentSlate.picture) {
            images.push({
                id: -1,
                picture: establishmentSlate.picture,
                isFacebookShared: true,
                isInstagramShared: true,
                isGoogleShared: true,
                isTwitterShared: true,
                comment: null,
                instagramPostId: null,
                instagramPermalink: null,
                facebookPhotoId: null,
                googlePhotoId: null,
            })
        }

        const handleUpdateImages = (values: ShareImagesFormState) => {
            if (!values.photos) {
                return Promise.reject()
            }
            return updateImages({
                id: establishmentSlate.id,
                images: values.photos,
            })
        }

        const shareOn = (socialNetwork: SocialNetwork) => {
            switch (socialNetwork) {
                case SocialNetwork.Facebook:
                    return handleShareOnFacebook()
                case SocialNetwork.Google:
                    return handleShareOnGoogle()
                case SocialNetwork.Instagram:
                    return handleShareOnInstagram()
                case SocialNetwork.Twitter:
                    return handleShareOnTwitter()
            }
        }
        const handleShareOn = (socialNetwork: SocialNetwork) => {
            if (images.length > 1) {
                setShareImages(socialNetwork)
            } else {
                return shareOn(socialNetwork)
            }
        }

        const handleSaveShare = (socialNetwork: SocialNetwork, values: ShareImagesFormState) => {
            return handleUpdateImages(values).then(() => {
                return shareOn(socialNetwork)?.then(() => {
                    handleShareClose()
                })
            })
        }

        const handleShareClose = () => {
            setShareImages(undefined)
        }

        const getShareInProgress = (socialNetwork: SocialNetwork) => {
            switch (socialNetwork) {
                case SocialNetwork.Facebook:
                    return shareOnFacebookInProgress
                case SocialNetwork.Instagram:
                    return shareOnFacebookInProgress
                case SocialNetwork.Google:
                    return shareOnGoogleInProgress
                case SocialNetwork.Twitter:
                    return shareOnTwitterInProgress
            }
        }

        return (
            <>
                <SlateView
                    establishment={establishment}
                    slate={establishmentSlate}
                    images={images}
                    shareOn={handleShareOn}
                    isSharingSlateOnGoogle={shareOnGoogleInProgress}
                    isGoogleSignedExpired={restaurantOwner.isGoogleSignedExpired}
                    isSharingSlateOnFacebook={shareOnFacebookInProgress}
                    isFacebookSignedExpired={restaurantOwner.isFacebookSignedExpired}
                    isSharingSlateOnInstagram={shareOnInstagramInProgress}
                    isSharingSlateOnTwitter={shareOnTwitterInProgress}
                    isTwitterSignedExpired={establishment.isTwitterSignedExpired ?? false}
                    ability={ability}
                    showTwitter={false}
                    extraSharing={<ExtraShared menuLink={menuLink()} />}
                />
                <EstablishmentEditFacebookGoogleDialog
                    establishment={establishment}
                    open={openFacebookGoogle}
                    onClose={handleClose}
                    ability={ability}
                />
                {shareImages && (
                    <ShareImagesDialog
                        open
                        onClose={handleShareClose}
                        initialValues={{
                            photos: images,
                        }}
                        onSubmit={(values: ShareImagesFormState) => handleSaveShare(shareImages, values)}
                        inProgress={updateImagesInProgress || getShareInProgress(shareImages)}
                        showTwitter={shareImages === SocialNetwork.Twitter}
                        showInstagram={shareImages === SocialNetwork.Instagram}
                        showFacebook={shareImages === SocialNetwork.Facebook}
                        showGoogle={shareImages === SocialNetwork.Google}
                    />
                )}
            </>
        )
    }
    return <EmptySlateView establishment={establishment} />
}

const Loader: React.FC = () => {
    const { t } = useTranslation()
    return <AuthenticatedLoading title={t('views.slate.title')} loadingMessage={t('common.loading')} />
}

export default withAuthenticateLayout(withSwiper(withFacebookAndGoogle(Slate, <Loader />)))
