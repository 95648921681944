import React from 'react'
import { Button, ListItem, ListItemIcon, useMediaQuery } from '@material-ui/core'
import SmartphoneIcon from '@material-ui/icons/Smartphone'
import ListItemText from '@material-ui/core/ListItemText'
import logoSugg1144 from 'assets/logo/sugg1144.png'
import LanguageIcon from '@material-ui/icons/Language'
import { GroupFacebookModel } from '@sugg-gestion/ubidreams-react-suggpro'
import { Trans, useTranslation } from 'react-i18next'
import { useStyles } from '../styles.css'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { useTheme } from '@material-ui/core/styles'

interface Props {
    menuLink: string
    establishmentsGroups?: Array<GroupFacebookModel>
}

const ExtraShared: React.FC<Props> = ({ menuLink, establishmentsGroups }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true })
    return (
        <>
            <ListItem divider>
                <ListItemIcon>
                    <SmartphoneIcon />
                </ListItemIcon>
                <ListItemText>
                    <Trans i18nKey="views.slate.share.app" />
                </ListItemText>
                <Button
                    variant="outlined"
                    href="https://www.sugg1144.com/installer-maintenant/"
                    target="_blank"
                    endIcon={<OpenInNewIcon />}
                >
                    {!isMobileScreen && <Trans i18nKey="actions.checkOnApp" />}
                </Button>
            </ListItem>
            {establishmentsGroups?.map((group) => (
                <ListItem divider key={group.facebookGroupId}>
                    <ListItemIcon className={classes.imgIcon}>
                        <img
                            src={logoSugg1144}
                            alt={t('views.slate.share.group', { name: group.facebookGroup.name })}
                        />
                    </ListItemIcon>
                    <ListItemText>
                        <Trans
                            i18nKey="views.slate.share.group"
                            values={{ name: group.facebookGroup.name }}
                        />
                    </ListItemText>
                    <Button
                        variant="outlined"
                        href={'https://www.facebook.com/' + group.facebookGroupId}
                        target="_blank"
                        endIcon={<OpenInNewIcon />}
                    >
                        {!isMobileScreen && <Trans i18nKey="actions.checkPublication" />}
                    </Button>
                </ListItem>
            ))}
            <ListItem divider>
                <ListItemIcon>
                    <LanguageIcon />
                </ListItemIcon>
                <ListItemText>
                    <Trans i18nKey="views.slate.share.website" />
                </ListItemText>
                <Button variant="outlined" href={menuLink} target="_blank" endIcon={<OpenInNewIcon />}>
                    {!isMobileScreen && <Trans i18nKey="actions.checkPublication" />}
                </Button>
            </ListItem>
        </>
    )
}

export default ExtraShared
