import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(4),
        },
        subtitle: {
            marginTop: theme.spacing(-4),
        },
        description: {
            paddingBottom: theme.spacing(2),
        },
        action: {
            '&.MuiButtonBase-root': {
                marginLeft: 'auto',
            },
        },
        rowAction: {
            '& > .MuiButtonBase-root': {
                marginTop: theme.spacing(3.5),
            },
        },
        couponContainer: {
            marginTop: theme.spacing(4),
        },
        invoiceTitleContainer: {
            margin: 0,
        },
        invoiceTitle: {
            display: 'inline-flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        editAction: {
            width: 160,
            minHeight: 0,
            maxWidth: '100%',
            [theme.breakpoints.down('xs')]: {
                width: 120,
            },
        },
        couponCard: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(2),
        },
        plansContainer: {
            padding: 0,
            [theme.breakpoints.up('lg')]: {
                '& .MuiGrid-grid-lg-3': {
                    maxWidth: '20%',
                    flexBasis: '20%',
                },
            },
        },
        interfacePlv: {
            height: 100,
        },
        interfacePlvContainer: {
            display: 'inline',
            textAlign: 'right',
            paddingRight: theme.spacing(),
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
                paddingRight: 0,
            },
        },
        loadingView: {
            minHeight: 300,
            position: 'relative',
        },
        submit: {
            width: 160,
            maxWidth: '100%',
            minWidth: 'fit-content',
        },
        annualSwitchContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: theme.spacing(2),
        },
        annualSwitchLabel: {
            fontSize: '1.2rem',
        },
        monthlySwitchLabel: {
            fontSize: '1.2rem',
            marginRight: theme.spacing(2),
            fontWeight: theme.fontsWeight.semiBold,
        },
        semiBold: {
            fontWeight: theme.fontsWeight.semiBold,
            fontSize: 'inherit',
        },
        selected: {
            color: theme.palette.primary.main,
        },
        alert: {
            margin: theme.spacing(1, 0),
        },
        code: {
            padding: theme.spacing(2, 0),
        },
    }),
)
