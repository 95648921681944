import { isNumber } from 'lodash'

export const isSiretValid = (siret: string) => {
    let isValid
    if (siret.length !== 14 || isNumber(siret)) {
        isValid = false
    } else {
        // Donc le SIRET est un numérique à 14 chiffres
        // Les 9 premiers chiffres sont ceux du SIREN (ou RCS), les 4 suivants
        // correspondent au numéro d'établissement
        // et enfin le dernier chiffre est une clef de LUHN.
        let sum = 0
        for (let cpt = 0; cpt < siret.length; cpt++) {
            let tmp = 0
            if (cpt % 2 === 0) {
                // Les positions impaires : 1er, 3è, 5è, etc...
                tmp = parseInt(siret.charAt(cpt)) * 2 // On le multiplie par 2
                if (tmp > 9) tmp -= 9 // Si le résultat est supérieur à 9, on lui soustrait 9
            } else {
                tmp = parseInt(siret.charAt(cpt))
            }
            sum += tmp
        }
        isValid = sum % 10 === 0
    }
    return isValid
}
