import React from 'react'
import useStyles from './flatCard.css'
import { Card } from '@material-ui/core'
import { CardProps } from '@material-ui/core/Card/Card'
import clsx from 'clsx'

interface Props extends CardProps {}

const FlatCard = React.forwardRef<typeof Card, Props>(({ className, elevation = 0, ...props }, ref) => {
    const classes = useStyles()
    return <Card ref={ref} elevation={elevation} className={clsx(classes.card, className)} {...props} />
})

export default FlatCard
