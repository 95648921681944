import React from 'react'
import { Typography } from '@material-ui/core'
import EmailValidationForm, { EmailValidationFormState } from 'components/forms/account/emailValidationForm'
import clsx from 'clsx'
import { Trans } from 'react-i18next'
import { useStyles } from './emailValidationView.css'
import SubmitButton from 'components/common/submitButton'
import SignUpContent from '../../layouts/components/signUpContent'
import SignUpActions from 'components/layouts/components/signUpActions'

interface Props {
    onSubmit: (values: EmailValidationFormState) => void
    inProgress: boolean
    resendInProgress: boolean
    signOutInProgress: boolean
    resendDisabled: boolean
    initialValues: EmailValidationFormState
    email: string
    onCancel: () => void
    onResend: () => void
}

const EmailValidationView: React.FC<Props> = ({
    signOutInProgress,
    email,
    onResend,
    resendInProgress,
    inProgress,
    onCancel,
    resendDisabled,
    ...formData
}) => {
    const classes = useStyles()
    return (
        <EmailValidationForm
            {...formData}
            inProgress={inProgress}
            resendButton={() => (
                <div className={classes.resend}>
                    <Typography>
                        <Trans i18nKey="views.emailValidation.resend" />
                    </Typography>

                    <SubmitButton
                        variant="outlined"
                        color="primary"
                        onClick={onResend}
                        loading={resendInProgress}
                        disabled={inProgress || resendDisabled}
                    >
                        <Trans i18nKey="actions.resendCode" />
                    </SubmitButton>
                </div>
            )}
        >
            {(content, actions) => (
                <>
                    <SignUpContent>
                        <Typography variant="h1" className={classes.title}>
                            <Trans i18nKey="views.emailValidation.title" />
                        </Typography>
                        <Typography>
                            <Trans i18nKey="views.emailValidation.message" values={{ email }} />
                        </Typography>
                        {content}
                    </SignUpContent>
                    <SignUpActions>
                        <SubmitButton variant="contained" onClick={onCancel} loading={signOutInProgress}>
                            <Trans i18nKey="actions.cancel" />
                        </SubmitButton>
                        {actions.map((action, index) => (
                            <SubmitButton
                                key={index}
                                variant="contained"
                                color="primary"
                                {...action}
                                className={clsx(action.className)}
                            />
                        ))}
                    </SignUpActions>
                </>
            )}
        </EmailValidationForm>
    )
}

export default EmailValidationView
