import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionsContainer: {
            [theme.breakpoints.down('xs')]: {
                'backgroundColor': theme.palette.background.default,
                '@media (orientation: portrait)': {
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                },
            },
        },
        actions: {
            justifyContent: 'flex-end',
            [theme.breakpoints.down('xs')]: {
                'padding': theme.spacing(1, 2),
                '@media (orientation: portrait)': {
                    padding: theme.spacing(2, 2),
                },
            },
        },
    }),
)
