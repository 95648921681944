import { Avatar, Button, Grid, IconButton, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { ArrowBack } from '@material-ui/icons'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { Establishment, MessagingConversation, MessagingMessage } from '@sugg-gestion/ubidreams-react-suggpro'
import Loader from 'components/common/loader'
import PrivateMessageForm, { MessageFormState } from 'components/forms/messaging/privateMessageForm'
import { FormApi } from 'final-form'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { messagingLink } from '../../messagingUtils'
import { useStyles } from '../../styles.css'
import MessageBoxPrivateMessage from '../messageBoxPrivateMessage'
import MenuPane from './menu'

interface Props {
    establishment: Establishment
    conversation: MessagingConversation
    handleToggleDrawer?: any
    messages: Array<MessagingMessage>
    inProgress: boolean
    inProgressSendMessage: boolean
    handleSendMessage: (
        values: MessageFormState,
        form: FormApi<MessageFormState, Partial<MessageFormState>>,
    ) => void
    onDeleteConversation: () => void
    onDeleteAndBlockConversation: () => void
    onDeleteMessage: (messageId: string) => void
    onHideMessage: (messageId: string) => void
}

const PrivateMessagePane: React.FC<Props> = ({
    establishment,
    conversation,
    handleToggleDrawer,
    messages,
    inProgress,
    inProgressSendMessage,
    handleSendMessage,
    onDeleteConversation,
    onDeleteAndBlockConversation,
    onDeleteMessage,
    onHideMessage,
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
    const { t } = useTranslation()

    return (
        <>
            <Grid container>
                {/* Discuss Info head */}
                <Grid item xs={12}>
                    <Grid container alignItems={'center'} className={classes.discussHat}>
                        {isMobile && (
                            <Grid item xs={1}>
                                <IconButton color={'primary'} onClick={handleToggleDrawer(false)}>
                                    <ArrowBack />
                                </IconButton>
                            </Grid>
                        )}
                        <Grid item sm={1} xs={2}>
                            <Avatar
                                style={{ margin: 'auto' }}
                                src={(conversation.receiver && conversation.receiver.img) ?? undefined}
                                className={classes.userProfilePicture}
                            />
                        </Grid>
                        <Grid item sm={6} xs={6}>
                            <a
                                className={classes.userProfileLink}
                                href={
                                    (conversation.receiver && conversation.receiver.link) ||
                                    conversation.permalink ||
                                    ''
                                }
                                target={'_blank'}
                                title={t('views.messaging.lookupProfile')}
                                rel="noreferrer"
                            >
                                {conversation.receiver && conversation.receiver.name}
                            </a>
                        </Grid>
                        <Grid item sm={5} xs={4} className={classes.discussHatOptions}>
                            <IconButton
                                className={classes.discussHatOpenInNew}
                                color={'primary'}
                                href={
                                    conversation.permalink || messagingLink(conversation, establishment) || ''
                                }
                                target={'_blank'}
                                title={t('views.messaging.openConversationInNewTab')}
                            >
                                <Typography>{t('views.messaging.openConversationInNewTab')}</Typography>
                                <OpenInNewIcon />
                            </IconButton>
                            <MenuPane
                                onDeleteConversation={onDeleteConversation}
                                onBlockUser={
                                    conversation.typePlatform === 'facebook' ||
                                    conversation.typePlatform === 'twitter'
                                        ? onDeleteAndBlockConversation
                                        : undefined
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.discussThreadContainer}>
                {/* Discuss */}
                <Grid item xs={12}>
                    <div className={classes.discussThreadReverse}>
                        {inProgress ? (
                            <>
                                <div className={classes.loaderContainer}>
                                    <Loader classes={{ main: classes.loaderConversation }} withLogo={false} />
                                    <div className={classes.loaderIndicator}>
                                        {t('views.messaging.loader')}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                {messages &&
                                    Array.from(messages)
                                        .reverse()
                                        .map((message, index) => {
                                            return (
                                                <MessageBoxPrivateMessage
                                                    establishment={establishment}
                                                    conversation={conversation}
                                                    message={message}
                                                    key={index}
                                                    onDeleteMessage={onDeleteMessage}
                                                    onHideMessage={onHideMessage}
                                                />
                                            )
                                        })}
                            </>
                        )}
                    </div>
                </Grid>
                {/* Typing Box */}
                <Grid item xs={12} style={{ alignSelf: 'flex-end!important' }}>
                    <div className={classes.discussTypingBox}>
                        {conversation.typePlatform === 'twitter' ? (
                            <div className={classes.conversationOpenInNew}>
                                <div>{t('views.messaging.openConversationInNewTabText')}</div>
                                <Button
                                    variant="contained"
                                    color={'primary'}
                                    endIcon={<OpenInNewIcon />}
                                    href={conversation.permalink || ''}
                                    target={'_blank'}
                                    title={t('views.messaging.openConversationInNewTabTitle')}
                                >
                                    {t('views.messaging.openConversationInNewTab')}
                                </Button>
                            </div>
                        ) : (
                            <PrivateMessageForm
                                onSubmit={handleSendMessage}
                                inProgress={inProgressSendMessage}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default PrivateMessagePane
