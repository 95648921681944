import React from 'react'
import CloseButton from 'components/dialogs/closeButton'
import { useTheme } from '@material-ui/core/styles'
import { Button, Dialog, DialogActions, DialogContent, useMediaQuery } from '@material-ui/core'
import { useStyles } from './shareImagesDialog.css'
import ShareImagesForm, { ShareImagesFormState } from 'components/forms/slate/shareImagesForm'
import { Trans } from 'react-i18next'
import SubmitButton from 'components/common/submitButton'
import clsx from 'clsx'

interface Props {
    open: boolean
    showInstagram: boolean
    showTwitter: boolean
    showGoogle: boolean
    showFacebook: boolean
    onClose: () => void
    onSubmit: (values: ShareImagesFormState) => void
    inProgress: boolean
    initialValues: ShareImagesFormState
}

const ShareImagesDialog: React.FC<Props> = ({ open, onClose, ...formData }) => {
    const classes = useStyles()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true })

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xl"
            fullScreen={fullScreen}
            scroll="paper"
            classes={{
                paper: classes.paper,
            }}
        >
            <ShareImagesForm formClassName={classes.form} {...formData}>
                {(content, actions) => (
                    <>
                        <DialogContent dividers>
                            <CloseButton onClose={onClose} />
                            {content}
                        </DialogContent>
                        <DialogActions className={classes.actions}>
                            <Button variant="contained" onClick={onClose}>
                                <Trans i18nKey="actions.cancel" />
                            </Button>
                            {actions.map((action, index) => (
                                <SubmitButton
                                    key={index}
                                    variant="contained"
                                    color="primary"
                                    {...action}
                                    className={clsx(action.className)}
                                >
                                    <Trans i18nKey="actions.validate" />
                                </SubmitButton>
                            ))}
                        </DialogActions>
                    </>
                )}
            </ShareImagesForm>
        </Dialog>
    )
}

export default ShareImagesDialog
