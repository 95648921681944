import React from 'react'
import AuthenticateContent from 'components/layouts/components/authenticateContent'
import { Button, ButtonGroup, TableBody, TableCell, TableRow, Typography } from '@material-ui/core'
import { Establishment, ReminderNotebook } from '@sugg-gestion/ubidreams-react-suggpro'
import { Trans } from 'react-i18next'
import { useStyles } from './styles.css'
import ReminderNotebookTable from './components/dataTable'
import SubmitButton from '../../common/submitButton'

interface Props {
    reminderNotebooks: Array<ReminderNotebook>
    dateSelector: React.ReactNode
    establishment: Establishment
    onSetReminderNotebook: (reminderNotebook: null | 'STANDARD' | 'ENFORCE') => void
    updateInProgress: boolean
    onDownload: () => void
    onRefreshReminderNotebook: () => void
    inProgress: boolean
}

const ReminderNotebookView: React.FC<Props> = ({
    reminderNotebooks,
    dateSelector,
    establishment,
    onSetReminderNotebook,
    updateInProgress,
    onDownload,
    onRefreshReminderNotebook,
    inProgress,
}) => {
    const classes = useStyles()

    return (
        <AuthenticateContent withAction={false}>
            <Typography variant="h1" color="textSecondary" className={classes.title}>
                <Trans i18nKey="views.reminderNotebook.title" />
            </Typography>
            <div className={classes.actionContainer}>
                <Typography
                    classes={{
                        root: classes.reminderNotebook,
                    }}
                >
                    <Trans i18nKey="views.reminderNotebook.reminderNotebook.label" />
                </Typography>
                <ButtonGroup color="primary">
                    <Button
                        variant={!establishment.reminderNotebook ? 'contained' : 'outlined'}
                        onClick={() => onSetReminderNotebook(null)}
                        disabled={updateInProgress}
                    >
                        <Trans i18nKey="views.reminderNotebook.reminderNotebook.inactive" />
                    </Button>
                    <Button
                        variant={establishment.reminderNotebook === 'STANDARD' ? 'contained' : 'outlined'}
                        onClick={() => onSetReminderNotebook('STANDARD')}
                        disabled={updateInProgress}
                    >
                        <Trans i18nKey="views.reminderNotebook.reminderNotebook.standard" />
                    </Button>
                    <Button
                        variant={establishment.reminderNotebook === 'ENFORCE' ? 'contained' : 'outlined'}
                        onClick={() => onSetReminderNotebook('ENFORCE')}
                        disabled={updateInProgress}
                    >
                        <Trans i18nKey="views.reminderNotebook.reminderNotebook.enforce" />
                    </Button>
                </ButtonGroup>
            </div>
            <div className={classes.actionContainer}>
                <Typography
                    classes={{
                        root: classes.reminderNotebook,
                    }}
                >
                    <Trans i18nKey="views.reminderNotebook.dataDate" />
                </Typography>
                {dateSelector}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onDownload}
                    classes={{
                        root: classes.action,
                    }}
                >
                    <Trans i18nKey="actions.downloadList" />
                </Button>
                <SubmitButton
                    variant="outlined"
                    color="primary"
                    onClick={onRefreshReminderNotebook}
                    loading={inProgress}
                >
                    <Trans i18nKey="views.reminderNotebook.refresh" />
                </SubmitButton>
            </div>
            <ReminderNotebookTable reminderNotebooks={reminderNotebooks}>
                {reminderNotebooks.length === 0 && (
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={4}>
                                <div className={classes.noData}>
                                    <Typography>
                                        <Trans i18nKey="views.reminderNotebook.noData" />
                                    </Typography>
                                    {dateSelector}
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </ReminderNotebookTable>
        </AuthenticateContent>
    )
}

export default ReminderNotebookView
