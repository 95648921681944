import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            width: '100%',
        },
        actions: {
            justifyContent: 'flex-end',
        },
        form: {
            display: 'contents',
        },
        alert: {
            margin: theme.spacing(1, 0),
        },
        formSubTitle: {
            paddingLeft: theme.spacing(2),
            fontSize: '0.8rem',
        },
    }),
)
