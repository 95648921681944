import React from 'react'
import EstablishmentCreateView from './establishmentCreateView'
import SignUpContent from 'components/layouts/components/signUpContent'
import SignUpActions from 'components/layouts/components/signUpActions'
import SubmitButton from 'components/common/submitButton'
import clsx from 'clsx'
import { useStyles } from './styles.css'
import { Typography } from '@material-ui/core'
import { Trans } from 'react-i18next'
import DetailsForm, { DetailsFormState } from '../../forms/establishment/detailsForm'
import { EstablishmentType } from '@sugg-gestion/ubidreams-react-suggpro'
import { CountryCode } from 'libphonenumber-js/types'

interface Props {
    step: number
    steps: number
    onSubmit: (values: DetailsFormState) => void
    inProgress: boolean
    initialValues: DetailsFormState
    establishmentTypes?: Array<EstablishmentType>
    typeMandatory: boolean
    onPrevious: () => void
    previousInProgress: boolean
    type: 'duns' | 'europeanVat'
    country: CountryCode
}

const EstablishmentDetailsView: React.FC<Props> = ({
    step,
    steps,
    onPrevious,
    previousInProgress,
    inProgress,
    ...formData
}) => {
    const classes = useStyles()

    return (
        <DetailsForm inProgress={inProgress} {...formData}>
            {(content, actions) => (
                <>
                    <SignUpContent>
                        <EstablishmentCreateView step={step} steps={steps}>
                            <Typography variant="h1" color="textSecondary" className={classes.title}>
                                <Trans i18nKey="views.establishment.details.title" />
                            </Typography>
                            <Typography variant="h2" color="textSecondary" className={classes.subtitle}>
                                <Trans i18nKey="views.establishment.details.subtitle" />
                            </Typography>
                            {content}
                        </EstablishmentCreateView>
                    </SignUpContent>
                    <SignUpActions>
                        <SubmitButton
                            variant="contained"
                            onClick={onPrevious}
                            loading={previousInProgress}
                            disabled={inProgress}
                        >
                            <Trans i18nKey="actions.previous" />
                        </SubmitButton>
                        {actions.map((action, index) => (
                            <SubmitButton
                                key={index}
                                variant="contained"
                                color="primary"
                                {...action}
                                className={clsx(action.className)}
                            />
                        ))}
                    </SignUpActions>
                </>
            )}
        </DetailsForm>
    )
}

export default EstablishmentDetailsView
