import React from 'react'
import { Button, CardActions, FormControl, Grid, Typography } from '@material-ui/core'
import SubmitButton from 'components/common/submitButton'
import DoneIcon from '@material-ui/icons/Done'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Trans } from 'react-i18next'
import { Field } from 'react-final-form'
import WarningIcon from '@material-ui/icons/Warning'
import EditIcon from '@material-ui/icons/Edit'
import _ from 'lodash'
import clsx from 'clsx'
import { TwitterInfoResponse } from '@sugg-gestion/ubidreams-react-suggpro'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: '1.3rem',
        },
        helper: {
            fontSize: '0.9rem',
            fontStyle: 'italic',
        },
        actionsContainer: {
            marginTop: 16,
        },
        actionContainer: {
            width: 'calc(100% - 64px)',
        },
        actionContainerWithoutIcon: {
            flexGrow: 1,
        },
        action: {
            height: 48,
        },
        iconButton: {
            backgroundColor: theme.palette.border.contrastText,
            padding: theme.spacing(0.5),
            minWidth: 0,
            height: 48,
            width: 48,
        },
        icon: {
            fontSize: '2.5rem',
        },
        iconSuccess: {
            color: theme.palette.success.main,
        },
        categoryRow: {
            display: 'block',
            marginBottom: theme.spacing(),
            marginTop: theme.spacing(0.5),
            fontStyle: 'italic',
        },
        linkedPage: {
            overflowWrap: 'break-word',
            borderColor: theme.palette.border.main,
            backgroundColor: theme.palette.border.contrastText,
            borderWidth: 2,
            borderStyle: 'solid',
            borderRadius: 6,
            padding: theme.spacing(),
            width: '100%',
            fontSize: '0.9rem',
            minHeight: 48,
            display: 'inline-flex',
            alignItems: 'center',
        },
        linkedPageContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
        linkedPageActions: {
            paddingRight: 0,
        },
        linkedPageNotActive: {
            width: '100%',
            marginTop: theme.spacing(),
        },
        pageTitle: {
            'display': 'flex',
            'alignItems': 'center',
            '& > svg': {
                marginLeft: theme.spacing(),
            },
        },
        facebook: {
            color: theme.socialColors.facebook,
        },
        instagram: {
            color: theme.socialColors.instagram,
        },
        deleteButton: {
            'padding': 4,
            'minWidth': 0,
            '& .MuiButton-label > span': {
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
        },
    }),
)

interface Props {
    name: string
    className?: string
    fetchTwitterData: () => Promise<TwitterInfoResponse>
    fetchTwitterDataInProgress: boolean
    unlinkTwitter: () => void
    hideWarning: boolean
    unlinkInProgress: boolean
}

const TwitterField: React.FC<Props> = ({
    name,
    className,
    fetchTwitterData,
    fetchTwitterDataInProgress,
    hideWarning,
    unlinkTwitter,
    unlinkInProgress,
}) => {
    const classes = useStyles()
    return (
        <Field name={name}>
            {({ input: { onChange, value }, meta }) => {
                const hasError =
                    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched

                const hideIcon = _.isEmpty(value) && hideWarning
                const handleFetchTwitterData = () => {
                    fetchTwitterData().then((info) => {
                        onChange(info)
                    })
                }
                const twitterInfo = value as TwitterInfoResponse
                return (
                    <FormControl margin="normal" error={hasError} className={className} fullWidth>
                        <Typography
                            color="textSecondary"
                            variant="h1"
                            component="p"
                            className={classes.title}
                        >
                            <Trans i18nKey={'form.twitterAccount'} />
                        </Typography>
                        <Typography className={classes.helper}>
                            <Trans i18nKey={'form.twitterHelper'} />
                        </Typography>
                        <Grid container spacing={2} className={classes.actionsContainer}>
                            <Grid
                                item
                                className={clsx({
                                    [classes.actionContainer]: !hideIcon,
                                    [classes.actionContainerWithoutIcon]: hideIcon,
                                })}
                            >
                                {_.isEmpty(value) && (
                                    <SubmitButton
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        fullWidth
                                        className={classes.action}
                                        onClick={handleFetchTwitterData}
                                        loading={fetchTwitterDataInProgress}
                                    >
                                        <Trans i18nKey={'actions.twitterAccount'} />
                                    </SubmitButton>
                                )}
                                {!_.isEmpty(value) && (
                                    <div className={classes.linkedPageContainer}>
                                        <Typography className={classes.linkedPage}>
                                            <Trans
                                                i18nKey="form.linkedAccount"
                                                values={{ account: twitterInfo.twitterScreenName }}
                                            />
                                        </Typography>
                                        <CardActions className={classes.linkedPageActions}>
                                            <Button
                                                href={
                                                    'https://www.twitter.com/' + twitterInfo.twitterScreenName
                                                }
                                                variant="outlined"
                                                target="_blank"
                                                startIcon={<OpenInNewIcon />}
                                            >
                                                <Trans i18nKey="actions.visitPage" />
                                            </Button>
                                            <SubmitButton
                                                variant="outlined"
                                                startIcon={<EditIcon />}
                                                onClick={handleFetchTwitterData}
                                                loading={fetchTwitterDataInProgress}
                                            >
                                                <Trans i18nKey="actions.change" />
                                            </SubmitButton>
                                            <SubmitButton
                                                variant="outlined"
                                                onClick={unlinkTwitter}
                                                loading={unlinkInProgress}
                                                className={classes.deleteButton}
                                            >
                                                <DeleteIcon />
                                            </SubmitButton>
                                        </CardActions>
                                    </div>
                                )}
                            </Grid>
                            {!hideIcon && (
                                <Grid item>
                                    <Button variant="contained" disabled className={classes.iconButton}>
                                        {_.isEmpty(value) ? (
                                            <WarningIcon color="primary" className={classes.icon} />
                                        ) : (
                                            <DoneIcon className={clsx(classes.icon, classes.iconSuccess)} />
                                        )}
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </FormControl>
                )
            }}
        </Field>
    )
}

export default TwitterField
