import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logoContainer: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(4),
        },
        title: {
            fontSize: '6em',
        },
        subTitle: {
            fontSize: '3em',
        },
        card: {
            'display': 'flex',
            'padding': theme.spacing(2),
            'justifyContent': 'center',
            'alignItems': 'center',
            'flexDirection': 'column',
            '& > .MuiTypography-root': {
                textAlign: 'center',
            },
        },
        action: {
            marginTop: theme.spacing(4),
        },
    }),
)
