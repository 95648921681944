import { Establishment, User } from '@sugg-gestion/ubidreams-react-suggpro'

const useRestaurantsApi = (user?: User, establishment?: Establishment) => {
    const { REACT_APP_RESTAURANTS_ENDPOINT } = process.env
    if (REACT_APP_RESTAURANTS_ENDPOINT === undefined) {
        throw new Error()
    }
    const restaurantsApi = REACT_APP_RESTAURANTS_ENDPOINT

    const qrCodeLink = () => {
        if (user !== undefined) {
            return restaurantsApi + '/qrcode/' + user.id + '?1'
        }
        return ''
    }

    const qrCodeDressedLink = () => {
        if (user !== undefined) {
            return restaurantsApi + '/qrcode-dressed/' + user.id
        }
        return ''
    }

    const slateLink = () => {
        if (user !== undefined) {
            return restaurantsApi + '/ardoise/' + user.id
        }
        return ''
    }

    const menuLink = () => {
        if (user !== undefined && establishment !== undefined) {
            return restaurantsApi + '/restaurant/' + establishment.uriKey + '.html'
        }
        return ''
    }

    return {
        restaurantsApi,
        qrCodeLink,
        slateLink,
        menuLink,
        qrCodeDressedLink,
    }
}

export default useRestaurantsApi
