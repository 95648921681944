import { Button, Typography } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import clsx from 'clsx'
import SubmitButton from 'components/common/submitButton'
import SignInForm, { SignInFormState } from 'components/forms/auth/signInForm'
import HomeActions from 'components/layouts/components/homeActions'
import HomeContent from 'components/layouts/components/homeContent'
import HomeLayout from 'components/layouts/homeLayout'
import React from 'react'
import { Trans } from 'react-i18next'
import { useStyles } from './oauth.css'

interface Props {
    onSubmit: (values: SignInFormState) => void
    onCancel: () => void
    inProgress: boolean
    initialValues: SignInFormState
}

const OAuthSignInView: React.FC<Props> = ({ onCancel, inProgress, ...formData }) => {
    const classes = useStyles()
    return (
        <HomeLayout>
            <SignInForm withEmailField={true} inProgress={inProgress} {...formData}>
                {(content, actions) => (
                    <>
                        <HomeContent>
                            <Typography variant="h1" className={classes.title}>
                                <Trans i18nKey="oauth.signIn.title" />
                            </Typography>
                            <Typography>
                                <Trans i18nKey="oauth.signIn.message" />
                            </Typography>
                            {content}
                        </HomeContent>
                        <HomeActions>
                            <Button variant="contained" onClick={onCancel}>
                                <Trans i18nKey="actions.cancel" />
                            </Button>
                            {actions.map((action, index) => (
                                <SubmitButton
                                    key={index}
                                    variant="contained"
                                    color="primary"
                                    endIcon={<ArrowForwardIosIcon />}
                                    {...action}
                                    className={clsx(action.className)}
                                />
                            ))}
                        </HomeActions>
                    </>
                )}
            </SignInForm>
        </HomeLayout>
    )
}

export default OAuthSignInView
