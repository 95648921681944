import { Reducer } from 'redux'
import { RegisterVideosAction, TutoState } from './types'
import { getType } from 'typesafe-actions'
import actions from '../actions'

const initialState: TutoState = {
    videos: [],
}

const reducer: Reducer<TutoState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.registerVideos): {
            const { videos: newVideos, nextToken, totalVideos }: RegisterVideosAction = action.payload
            const videos = state.videos
            return {
                ...state,
                videos: [...videos, ...newVideos],
                nextToken,
                totalVideos,
                pageFetched: (state.pageFetched ?? 0) + 1,
            }
        }

        default:
            return state
    }
}

export { reducer as tutoReducer }
