import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            width: 120,
            height: 20,
        },
        undefined: {
            fontSize: '0.8rem',
            fontStyle: 'italic',
        },
    }),
)
