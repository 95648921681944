import React, { ComponentType } from 'react'
import SwiperCore, { Keyboard, Zoom } from 'swiper'

// Import Swiper styles
import 'swiper/swiper.scss'
import 'swiper/components/zoom/zoom.scss'

// install Swiper components
SwiperCore.use([Zoom, Keyboard])

const withSwiper = <T,>(ComposedComponent: ComponentType<T>) => {
    const WithSwiper: React.FC<T> = (props) => {
        return <ComposedComponent {...props} />
    }
    return WithSwiper
}

export default withSwiper
