// import { Client } from '@sugg-gestion/suggpro-graph-module'
import { FacebookPagesResponse, GooglePagesResponse } from '@sugg-gestion/ubidreams-react-suggpro'
import { SnackbarKey } from 'notistack'
import { createAction } from 'typesafe-actions'
import {
    AlertContent,
    AppActionTypes,
    FacebookUser,
    GoogleUser,
    SnackbarErrorNotification,
    SnackbarNotificationAction,
} from './types'

export const setLocale = createAction(AppActionTypes.SET_LOCALE)<string>()
export const setTimezone = createAction(AppActionTypes.SET_TIMEZONE)<string>()

export const setGoogleInitialized = createAction(AppActionTypes.SET_GOOGLE_INITIALIZED)<boolean>()
export const setGoogleAuth = createAction(AppActionTypes.SET_GOOGLE_AUTH)<gapi.auth2.GoogleAuth>()
export const setGoogleUser = createAction(AppActionTypes.SET_GOOGLE_USER)<GoogleUser | undefined>()
export const setGooglePages = createAction(AppActionTypes.SET_GOOGLE_PAGES)<GooglePagesResponse | undefined>()

export const setFacebookInitialized = createAction(AppActionTypes.SET_FACEBOOK_INITIALIZED)<boolean>()
export const setFacebookUser = createAction(AppActionTypes.SET_FACEBOOK_USER)<FacebookUser | undefined>()
export const setFacebookPages = createAction(AppActionTypes.SET_FACEBOOK_PAGES)<
    FacebookPagesResponse | undefined
>()

export const enqueueSnackbar = createAction(AppActionTypes.ENQUEUE_SNACKBAR)<SnackbarNotificationAction>()
export const enqueueError = createAction(AppActionTypes.ENQUEUE_ERROR)<SnackbarErrorNotification>()
export const closeSnackbar = createAction(AppActionTypes.CLOSE_SNACKBAR)<SnackbarKey>()
export const removeSnackbar = createAction(AppActionTypes.REMOVE_SNACKBAR)<SnackbarKey>()

export const alert = createAction(AppActionTypes.ALERT)<AlertContent | undefined>()

export const redirectToEstablishment = createAction(AppActionTypes.REDIRECT_TO_ESTABLISHMENT)()

export const setHubSpotFormInitialized = createAction(AppActionTypes.SET_HUBSPOT_FORM_INITIALIZED)<boolean>()
export const setStatisticsStartDate = createAction(AppActionTypes.SET_STATISTICS_START_DATE)<Date>()
export const setStatisticsEndDate = createAction(AppActionTypes.SET_STATISTICS_END_DATE)<Date>()

export const setLostInternetConnexion = createAction(AppActionTypes.SET_LOST_INTERNET_CONNEXION)<boolean>()
// export const setGraphApiClient = createAction(AppActionTypes.SET_GRAPH_API_CLIENT)<Client>()
