import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: theme.palette.border.main,
        },
    }),
)
