import React from 'react'
import AuthenticateContent from 'components/layouts/components/authenticateContent'
import { Trans } from 'react-i18next'
import { Button, Typography } from '@material-ui/core'
import { useStyles } from './styles.css'
import { Link } from 'react-router-dom'
import { Establishment } from '@sugg-gestion/ubidreams-react-suggpro'
import SettingsIcon from '@material-ui/icons/Settings'

interface Props {
    establishment: Establishment
}

const EmptySlateView: React.FC<Props> = ({ establishment }) => {
    const classes = useStyles()
    const establishmentBasePath = '/establishment/:id'.replace(':id', establishment.id.toString() ?? '')

    return (
        <AuthenticateContent withAction={false}>
            <Typography variant="h1" color="textSecondary" className={classes.title}>
                <Trans i18nKey="views.slate.title" />
                <Button
                    className={classes.setting}
                    variant="outlined"
                    component={Link}
                    to={establishmentBasePath + '/slate/setting?withBack'}
                    startIcon={<SettingsIcon />}
                >
                    <Trans i18nKey="actions.setting" />
                </Button>
            </Typography>
            <Typography variant="h2" color="textSecondary" className={classes.subtitle}>
                <Trans i18nKey="views.slate.empty.subtitle" />
            </Typography>
            <div className={classes.emptyContainer}>
                <Typography color="textSecondary" className={classes.empty}>
                    <Trans i18nKey="views.slate.empty.message" />
                </Typography>
                <Button
                    variant="outlined"
                    color="primary"
                    to={establishmentBasePath + '/slate/add'}
                    component={Link}
                >
                    <Trans i18nKey="actions.addSlate" />
                </Button>
            </div>
        </AuthenticateContent>
    )
}

export default EmptySlateView
