import Loader from 'components/common/loader'
import useFacebookAuth from 'core/services/facebook/useFacebookAuth'
import useGoogleAuth from 'core/services/google/useGoogleAuth'
import React, { ComponentType } from 'react'

const withFacebookAndGoogle = (
    ComposedComponent: ComponentType<any>,
    loader: React.ReactNode = <Loader />,
) => {
    const WithFacebookAndGoogle: React.FC<any> = ({ ...props }) => {
        const isFacebookInitialized = useFacebookAuth()
        const isGoogleInitialized = useGoogleAuth()
        if (isFacebookInitialized && isGoogleInitialized) {
            return <ComposedComponent {...props} />
        } else {
            return <>{loader}</>
        }
    }
    return WithFacebookAndGoogle
}

export default withFacebookAndGoogle
