import { Card, Typography } from '@material-ui/core'
import React from 'react'
import { Trans } from 'react-i18next'
import { useStyles } from './color.css'

interface Props {
    color: string | undefined | null
}

export const Color: React.FC<Props> = ({ color }) => {
    const classes = useStyles()
    if (color) {
        return (
            <Card
                className={classes.card}
                style={{
                    backgroundColor: color,
                }}
            />
        )
    }
    return (
        <Typography className={classes.undefined}>
            <Trans i18nKey="views.externalLinks.customization.undefined" />
        </Typography>
    )
}
