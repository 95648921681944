import { validateAWSEmail } from 'appsync-scalar-utils'
import { SubmitButtonProps } from 'components/common/submitButton'
import React from 'react'
import { Form, FormSpy } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import EmailField from '../components/emailField'
import PasswordField from '../components/passwordField'
import { useFormsStyles } from '../forms.css'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: SignInFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    forgottenPasswordButton?: (email: string) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: SignInFormState
    withEmailField?: boolean
}

export interface SignInFormState {
    email: string
    password: string
}

interface SignInFormError {
    email?: string
    password?: string
}

const SignInForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
    forgottenPasswordButton,
    withEmailField = false,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()

    const validate = (values: SignInFormState) => {
        const errors: SignInFormError = {}

        if (!values.email) {
            errors.email = t('form-validation.email')
        } else if (!validateAWSEmail(values.email)) {
            errors.email = t('form-validation.emailValid')
        }
        if (!values.password) {
            errors.password = t('form-validation.password')
        }
        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <>
                            <EmailField
                                name="email"
                                label={t('form.email')}
                                required
                                disabled={!withEmailField}
                            />
                            <PasswordField
                                label={t('form.password')}
                                name="password"
                                autoComplete="current-password"
                                required
                            />

                            {forgottenPasswordButton && (
                                <FormSpy subscription={{ values: true }}>
                                    {(props) => forgottenPasswordButton(props.values.email)}
                                </FormSpy>
                            )}
                        </>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                className: formsClasses.submit,
                                children: <Trans i18nKey="actions.continue" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}
export default SignInForm
