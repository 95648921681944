import React from 'react'
import SignUpContent from 'components/layouts/components/signUpContent'
import { useStyles as useGlobalStyles } from './styles.css'
import { useStyles } from './establishmentPaymentCheckView.css'
import { Trans, useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import EstablishmentCreateView from './establishmentCreateView'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import SubmitButton from 'components/common/submitButton'
import SignUpLoading from './components/signUpLoading'

interface Props {
    step: number
    steps: number
    count: number
    onCheckAgain: () => void
    inProgress: boolean
}

const EstablishmentPaymentCheckView: React.FC<Props> = ({ step, steps, count, onCheckAgain, inProgress }) => {
    const { t } = useTranslation()

    const globalClasses = useGlobalStyles()
    const classes = useStyles()

    if (count < 5) {
        return (
            <SignUpLoading
                step={step}
                steps={steps}
                loadingMessage={t('views.paymentCheck.loading')}
                title={t('views.paymentCheck.title')}
            />
        )
    }
    return (
        <SignUpContent>
            <EstablishmentCreateView step={step} steps={steps}>
                <Typography variant="h1" color="textSecondary" className={globalClasses.title}>
                    <Trans i18nKey="views.paymentCheck.title" />
                </Typography>
                <div className={classes.root}>
                    <div>
                        <ErrorOutlineIcon color="primary" className={classes.icon} />
                    </div>
                    <div>
                        <Typography variant="h1" color="primary" className={classes.title}>
                            <Trans i18nKey="views.paymentCheck.failed.title" />
                        </Typography>
                        <Typography color="primary" className={classes.subtitle}>
                            <Trans i18nKey="views.paymentCheck.failed.subtitle" />
                        </Typography>
                    </div>
                    <div>
                        <SubmitButton
                            loading={inProgress}
                            color="primary"
                            variant="outlined"
                            className={classes.action}
                            onClick={onCheckAgain}
                        >
                            <Trans i18nKey="actions.checkAgain" />
                        </SubmitButton>
                    </div>
                </div>
            </EstablishmentCreateView>
        </SignUpContent>
    )
}

export default EstablishmentPaymentCheckView
