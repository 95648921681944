import React from 'react'
import { Field } from 'react-final-form'
import classNames from 'classnames'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import FormHelperText from '@material-ui/core/FormHelperText'
import { ParsableDate } from '@material-ui/pickers/constants/prop-types'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            color: theme.palette.error.main,
        },
        adornmentError: {
            '& > button': {
                color: theme.palette.error.main,
            },
        },
    }),
)

interface Props {
    name: string
    pastDate: boolean
    label?: string
    minDate?: ParsableDate
    maxDate?: ParsableDate
    onChange?: (date: MaterialUiPickersDate | null) => void
    required?: boolean
}

const KeyboardDateTimePickerField: React.FC<Props> = ({
    name,
    pastDate,
    label,
    minDate,
    maxDate,
    onChange: onChangeData,
    required,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Field name={name}>
            {({ input: { name, onChange, value, ...restInput }, meta, ...rest }) => {
                const showError =
                    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
                return (
                    <>
                        <KeyboardDateTimePicker
                            required={required}
                            ampm={false}
                            minDate={minDate}
                            maxDate={maxDate}
                            label={label}
                            value={value}
                            format="DD/MM/YYYY à HH:mm"
                            disablePast={!pastDate}
                            fullWidth
                            margin="normal"
                            error={showError}
                            minDateMessage={t('form.minDateError')}
                            onChange={(date) => {
                                onChange(date)
                                if (onChangeData) {
                                    onChangeData(date)
                                }
                            }}
                            InputAdornmentProps={{
                                className: classNames({
                                    [classes.adornmentError]: showError,
                                }),
                            }}
                            inputProps={{
                                className: classNames({
                                    [classes.error]: showError,
                                }),
                            }}
                            cancelLabel={t('actions.cancel')}
                        />
                        {showError && <FormHelperText className={classes.error}>{meta.error}</FormHelperText>}
                    </>
                )
            }}
        </Field>
    )
}

export default KeyboardDateTimePickerField
