import React from 'react'
import { useFormsStyles } from '../forms.css'
import { Form, FormSpy } from 'react-final-form'
import { SubmitButtonProps } from 'components/common/submitButton'
import { Trans, useTranslation } from 'react-i18next'
import EmailField from '../components/emailField'
import { validateAWSEmail } from 'appsync-scalar-utils'
import TextField from '../components/textField'
import { Typography } from '@material-ui/core'
import PasswordField from '../components/passwordField'
import parsePhoneNumberFromString from 'libphonenumber-js'
import PhoneField from '../components/phoneField'
import PasswordRules from '../components/passwordRules'
import CountryField from '../components/countryField'
import { CountryCode } from 'libphonenumber-js/types'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: AccountFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: AccountFormState
}

export interface AccountFormState {
    firstName?: string
    lastName?: string
    email?: string
    country: CountryCode
    phoneNumber?: string
    password?: string
    passwordConfirmation?: string
}

interface AccountFormError {
    firstName?: string
    lastName?: string
    email?: string
    country?: string
    password?: string
    phoneNumber?: string
    passwordConfirmation?: string
}

const AccountForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()

    const strongPasswordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\\W)(?=.{8,})')
    const validate = (values: AccountFormState) => {
        const errors: AccountFormError = {}

        if (!values.firstName) {
            errors.firstName = t('form-validation.firstName')
        } else if (values.firstName.length < 2) {
            errors.firstName = t('form-validation.firstNameLength')
        }

        if (!values.lastName) {
            errors.lastName = t('form-validation.lastName')
        } else if (values.lastName.length < 2) {
            errors.lastName = t('form-validation.lastNameLength')
        }

        if (!values.email) {
            errors.email = t('form-validation.email')
        } else if (!validateAWSEmail(values.email)) {
            errors.email = t('form-validation.emailValid')
        }

        if (!values.phoneNumber) {
            errors.phoneNumber = t('form-validation.phoneNumber')
        } else if (values.phoneNumber !== '') {
            const phone = parsePhoneNumberFromString(values.phoneNumber, 'FR')
            if (phone === undefined || !phone.isValid()) {
                errors.phoneNumber = t('form-validation.phone')
            }
        }

        if (values.password && !strongPasswordRegex.test(values.password)) {
            errors.password = t('form-validation.passwordStrength')
        }

        if (
            (values.passwordConfirmation || values.password) &&
            values.password !== values.passwordConfirmation
        ) {
            errors.passwordConfirmation = t('form-validation.passwordConfirmationNotMatch')
        }

        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <div>
                            <TextField
                                label={t('form.firstName')}
                                name="firstName"
                                autoComplete="given-name"
                                required
                            />
                            <TextField
                                label={t('form.lastName')}
                                name="lastName"
                                autoComplete="family-name"
                                required
                            />
                            <EmailField label={t('form.email')} name="email" required />
                            <CountryField name="country" />
                            <PhoneField label={t('form.phoneNumber')} name="phoneNumber" required />
                            <Typography className={formsClasses.info}>
                                <Trans i18nKey="form.editPassword" />
                            </Typography>
                            <PasswordField
                                label={t('form.new-password')}
                                name="password"
                                autoComplete="new-password"
                                margin="dense"
                            />
                            <FormSpy subscription={{ values: true }}>
                                {(props) => <PasswordRules value={props.values.password} />}
                            </FormSpy>
                            <PasswordField
                                label={t('form.passwordConfirmation')}
                                name="passwordConfirmation"
                                autoComplete="new-password"
                            />
                        </div>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                className: formsClasses.submit,
                                children: <Trans i18nKey="actions.continue" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}
export default AccountForm
