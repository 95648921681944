import { NewsProperty, MenuBoardProperty, Statistics } from '@sugg-gestion/ubidreams-react-suggpro'
import moment from 'moment'
import { useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

interface ChartData {
    unixDate: number
    facebook?: number
    instagram?: number
    googlemybusiness?: number
    twitter?: number
}

interface ChartDataPerPost {
    id: number
    unixDate: number
    facebook?: number
    instagram?: number
    googlemybusiness?: number
    twitter?: number
}

interface PieData {
    numberOfMenuBoard: number
    facebook?: number
    instagram?: number
    googlemybusiness?: number
    twitter?: number
}

interface PieDataPieData {
    name: string
    value: number
    socialMedia: SocialMedia
}

export enum SocialMedia {
    FACEBOOK = 'facebook',
    INSTAGRAM = 'instagram',
    GOOGLE_MY_BUSINESS = 'googlemybusiness',
    TWITTER = 'twitter',
}

export const useStatisticsData = (
    statistics: Array<Statistics<MenuBoardProperty | NewsProperty>>,
    type: 'views' | 'likes' | 'comments' | 'shares',
    per: 'perDay' | 'perPost',
    socialMedias: Array<SocialMedia>,
) => {
    const { t } = useTranslation()
    const theme = useTheme()

    const convertData = (statistics: Array<Statistics<MenuBoardProperty | NewsProperty>>) => {
        switch (per) {
            case 'perDay':
                return convertDataPerDay(statistics)
            case 'perPost':
                return convertDataPerPost(statistics)
        }
    }

    const convertDataPerPost = (statistics: Array<Statistics<MenuBoardProperty | NewsProperty>>) => {
        const chartDatas: Array<ChartDataPerPost> = []
        statistics.forEach((statistic) => {
            if (statistic.socialMedia === 'googlemybusiness-post') {
                statistic.socialMedia = SocialMedia.GOOGLE_MY_BUSINESS
            }
            let chartData: ChartDataPerPost | undefined
            if ('news' in statistic) {
                chartData = chartDatas.find((chartData) => chartData.id === statistic.news.id)
            } else {
                chartData = chartDatas.find((chartData) => chartData.id === statistic.menuboard.id)
            }
            if (!chartData) {
                if ('news' in statistic) {
                    chartData = {
                        id: statistic.news.id,
                        unixDate: moment(statistic.news.createdAt).valueOf(),
                    }
                } else {
                    chartData = {
                        id: statistic.menuboard.id,
                        unixDate: moment(statistic.menuboard.createdAt).valueOf(),
                    }
                }
                chartData[SocialMedia.FACEBOOK] = 0
                chartData[SocialMedia.INSTAGRAM] = 0
                chartData[SocialMedia.GOOGLE_MY_BUSINESS] = 0
                chartData[SocialMedia.TWITTER] = 0
                chartData[statistic.socialMedia] = statistic[type]
                chartDatas.push(chartData)
            } else {
                chartData[statistic.socialMedia] = (chartData[statistic.socialMedia] ?? 0) + statistic[type]
            }
        })
        return chartDatas.sort((a, b) => (a.unixDate > b.unixDate ? 1 : -1))
    }

    const convertDataPerDay = (statistics: Array<Statistics<MenuBoardProperty | NewsProperty>>) => {
        const chartDatas: Array<ChartData> = []
        statistics.forEach((statistic) => {
            if (statistic.socialMedia === 'googlemybusiness-post') {
                statistic.socialMedia = SocialMedia.GOOGLE_MY_BUSINESS
            }
            let chartData = chartDatas.find((chartData) =>
                moment(chartData.unixDate).isSame(
                    moment(statistic.date).hour(0).minute(0).second(0).millisecond(0),
                    'day',
                ),
            )
            if (!chartData) {
                chartData = {
                    unixDate: moment(statistic.date).hour(0).minute(0).second(0).millisecond(0).valueOf(),
                    [SocialMedia.FACEBOOK]: 0,
                    [SocialMedia.INSTAGRAM]: 0,
                    [SocialMedia.GOOGLE_MY_BUSINESS]: 0,
                    [SocialMedia.TWITTER]: 0,
                }
                chartData[statistic.socialMedia] = statistic[type]

                chartDatas.push(chartData)
            } else {
                chartData[statistic.socialMedia] = (chartData[statistic.socialMedia] ?? 0) + statistic[type]
            }
        })
        return chartDatas.sort((a, b) => (a.unixDate > b.unixDate ? 1 : -1))
    }

    const getStrokeColor = (socialMedia: SocialMedia) => {
        switch (socialMedia) {
            case SocialMedia.FACEBOOK:
                return theme.socialColors.facebookStats
            case SocialMedia.INSTAGRAM:
                return theme.socialColors.instagramStats
            case SocialMedia.GOOGLE_MY_BUSINESS:
                return theme.socialColors.googleMyBusiness
            case SocialMedia.TWITTER:
                return theme.socialColors.twitterStats
        }
    }
    const getFillColor = (socialMedia: SocialMedia) => {
        switch (socialMedia) {
            case SocialMedia.FACEBOOK:
                return theme.socialColors.facebookStatsLight
            case SocialMedia.INSTAGRAM:
                return theme.socialColors.instagramStatsLight
            case SocialMedia.GOOGLE_MY_BUSINESS:
                return theme.socialColors.googleMyBusinessLight
            case SocialMedia.TWITTER:
                return theme.socialColors.twitterStatsLight
        }
    }

    const { total, lastMenuBoardTotal } = (() => {
        const total: PieData = {
            numberOfMenuBoard: 0,
            [SocialMedia.FACEBOOK]: 0,
            [SocialMedia.INSTAGRAM]: 0,
            [SocialMedia.GOOGLE_MY_BUSINESS]: 0,
            [SocialMedia.TWITTER]: 0,
        }
        const datasPerPost = convertDataPerPost(statistics)
        const menuBoardIds: Array<number> = []
        datasPerPost.forEach((dataPerPost) => {
            if (menuBoardIds.indexOf(dataPerPost.id) === -1) {
                menuBoardIds.push(dataPerPost.id)
            }
            socialMedias.forEach((socialMedia) => {
                total[socialMedia] = (total[socialMedia] ?? 0) + (dataPerPost[socialMedia] ?? 0)
            })
        })
        total.numberOfMenuBoard = menuBoardIds.length
        const lastMenuBoard = Math.max(...menuBoardIds)
        const lastMenuBoardTotal: PieData = {
            numberOfMenuBoard: 1,
        }
        datasPerPost.forEach((dataPerPost) => {
            if (dataPerPost.id === lastMenuBoard) {
                socialMedias.forEach((socialMedia) => {
                    lastMenuBoardTotal[socialMedia] =
                        (lastMenuBoardTotal[socialMedia] ?? 0) + (dataPerPost[socialMedia] ?? 0)
                })
            }
        })
        return { total, lastMenuBoardTotal }
    })()

    const getAvailableSocialMedias = () => {
        switch (type) {
            case 'views':
                return [
                    SocialMedia.FACEBOOK,
                    SocialMedia.INSTAGRAM,
                    SocialMedia.GOOGLE_MY_BUSINESS,
                    SocialMedia.TWITTER,
                ]
            case 'likes':
                return [SocialMedia.FACEBOOK, SocialMedia.INSTAGRAM, SocialMedia.TWITTER]
            case 'comments':
                return [SocialMedia.FACEBOOK, SocialMedia.INSTAGRAM, SocialMedia.TWITTER]
            case 'shares':
                return [SocialMedia.FACEBOOK, SocialMedia.TWITTER]
        }
    }

    const pieDatas = (data: PieData) => {
        const pieDatas: Array<PieDataPieData> = []
        const availableSocialMedias = getAvailableSocialMedias()
        socialMedias
            .filter((socialMedia) => availableSocialMedias.indexOf(socialMedia) !== -1)
            .forEach((socialMedia) => {
                pieDatas.push({
                    name: t('form.' + socialMedia),
                    value: data[socialMedia] ?? 0,
                    socialMedia: socialMedia,
                })
            })

        return pieDatas
    }

    const totalPieDatas = pieDatas(total)
    const lastMenuBoardTotalPieDatas = pieDatas(lastMenuBoardTotal)

    return {
        getStrokeColor,
        getFillColor,
        total,
        totalPieDatas,
        lastMenuBoardTotal,
        lastMenuBoardTotalPieDatas,
        convertData,
    }
}
