import React, { useState } from 'react'
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core'
import { Video } from 'core/store/tuto/types'
import { useStyles } from './videoCard.css'
import VideoModal from 'components/common/videoModal'

interface Props {
    video: Video
}

const VideoCard: React.FC<Props> = ({ video }) => {
    const classes = useStyles()
    const [openVideo, setOpenVideo] = useState(false)
    return (
        <Card elevation={0} className={classes.card}>
            <CardActionArea onClick={() => setOpenVideo(true)}>
                <CardMedia
                    classes={{
                        root: classes.image,
                    }}
                    image={video.snippet.thumbnails.high.url}
                    title={video.snippet.title}
                />
                <CardContent>
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        classes={{
                            root: classes.title,
                        }}
                    >
                        {video.snippet.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {video.snippet.description}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <VideoModal
                open={openVideo}
                handleClose={() => setOpenVideo(false)}
                videoKey={video.snippet.resourceId.videoId}
                playlist={video.snippet.playlistId}
            />
        </Card>
    )
}

export default VideoCard
