import React from 'react'
import EstablishmentCreateView from './establishmentCreateView'
import SignUpContent from 'components/layouts/components/signUpContent'
import SignUpActions from 'components/layouts/components/signUpActions'
import SubmitButton from 'components/common/submitButton'
import clsx from 'clsx'
import { useStyles } from './styles.css'
import { Typography } from '@material-ui/core'
import { Trans } from 'react-i18next'
import PhotosForm, { PhotosFormState } from 'components/forms/establishment/photosForm'
import { Alert } from '@material-ui/lab'

interface Props {
    step: number
    steps: number
    onSubmit: (values: PhotosFormState) => void
    inProgress: boolean
    initialValues: Omit<PhotosFormState, 'flag'>
    onPrevious: () => void
    previousInProgress: boolean
}

const EstablishmentPhotosView: React.FC<Props> = ({
    step,
    steps,
    onPrevious,
    previousInProgress,
    inProgress,
    ...formData
}) => {
    const classes = useStyles()

    return (
        <PhotosForm inProgress={inProgress} {...formData}>
            {(content, actions) => (
                <>
                    <SignUpContent>
                        <EstablishmentCreateView step={step} steps={steps}>
                            <Typography variant="h1" color="textSecondary" className={classes.title}>
                                <Trans i18nKey="views.establishment.photos.title" />
                            </Typography>
                            <Typography variant="h2" color="textSecondary" className={classes.subtitle}>
                                <Trans i18nKey="views.establishment.photos.subtitle" />
                            </Typography>
                            <Alert severity="info" className={classes.alert}>
                                <Trans i18nKey="views.establishment.photos.info" components={[<strong />]} />
                            </Alert>
                            {content}
                        </EstablishmentCreateView>
                    </SignUpContent>
                    <SignUpActions>
                        <SubmitButton
                            variant="contained"
                            onClick={onPrevious}
                            loading={previousInProgress}
                            disabled={inProgress}
                        >
                            <Trans i18nKey="actions.previous" />
                        </SubmitButton>
                        {actions.map((action, index) => (
                            <SubmitButton
                                key={index}
                                variant="contained"
                                color="primary"
                                {...action}
                                className={clsx(action.className)}
                            />
                        ))}
                    </SignUpActions>
                </>
            )}
        </PhotosForm>
    )
}

export default EstablishmentPhotosView
