import Loader from 'components/common/loader'
import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'

const withInit = (ComposedComponent: ComponentType) => {
    const WithInit: React.FC = ({ ...props }) => {
        const { started } = useSelector(({ suggpro: { session } }: ApplicationState) => ({
            started: session.started,
        }))
        if (started) {
            return <ComposedComponent {...props} />
        } else {
            return <Loader />
        }
    }
    return WithInit
}

export default withInit
