import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        photoField: {
            width: '70%',
            margin: 'auto',
        },
        photoFieldCardMedia: {
            width: 120,
        },
        photoFieldAdd: {
            fontSize: '4rem',
        },
        photoEdit: {
            marginTop: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        photoDelete: {
            marginTop: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        sectionTitle: {
            fontSize: '1.2rem',
            padding: theme.spacing(2),
            textAlign: 'center',
        },
        sectionTitleContainer: {
            height: '6rem',
            backgroundColor: '#F0F0F0',
            padding: theme.spacing(2),
            borderBottomWidth: 1,
            borderBottomColor: theme.palette.border.main,
            borderBottomStyle: 'solid',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        disabled: {
            '& .MuiInput-input': {
                opacity: 0,
            },
        },
    }),
)
