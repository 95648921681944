import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        noData: {
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'center',
            'minHeight': theme.spacing(20),
            '& > p': {
                marginRight: theme.spacing(),
            },
        },
        title: {
            paddingBottom: theme.spacing(4),
        },
        actionContainer: {
            'marginBottom': theme.spacing(2),
            'display': 'flex',
            'alignItems': 'center',
            'flexWrap': 'wrap',
            '& > .MuiButton-root': {
                margin: theme.spacing(),
            },
        },
        reminderNotebook: {
            margin: theme.spacing(),
        },
        action: {
            '&.MuiButton-root': {
                marginLeft: 'auto',
            },
        },
    }),
)
