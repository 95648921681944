import React, { ComponentType } from 'react'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import { createRootReducer } from 'core/store/reducers'
import { createMiddleware } from 'core/store/middleware'
import { History } from 'history'
import createSagaMiddleware from 'redux-saga'
import { SagaMiddleware } from '@redux-saga/core'

interface InputProps {
    history: History<any>
}

interface OutputProps {
    history: History<any>
    sagaMiddleware: SagaMiddleware
}

const withStoreProvider = (ComposedComponent: ComponentType<OutputProps>) => {
    const WithStoreProvider: React.FC<InputProps> = ({ history, ...props }) => {
        const rootReducer = createRootReducer(history)
        const sagaMiddleware = createSagaMiddleware()
        const store = createStore(rootReducer, createMiddleware(history, sagaMiddleware))
        return (
            <Provider store={store}>
                <ComposedComponent history={history} sagaMiddleware={sagaMiddleware} {...props} />
            </Provider>
        )
    }
    return WithStoreProvider
}

export default withStoreProvider
