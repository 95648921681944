import { Grid, Typography } from '@material-ui/core'
import { GridSize } from '@material-ui/core/Grid/Grid'
import React, { PropsWithChildren } from 'react'
import { useStyles } from './line.css'

interface Props {
    title: string | React.ReactNode
    xs?: GridSize
}

export const Line: React.FC<PropsWithChildren<Props>> = ({ title, children, xs }) => {
    const classes = useStyles()
    return (
        <Grid item xs={12}>
            <Grid container spacing={1}>
                <Grid item xs={xs} className={classes.title}>
                    <Typography>{title}</Typography>
                </Grid>
                <Grid item xs={xs} className={classes.value}>
                    {children}
                </Grid>
            </Grid>
        </Grid>
    )
}
