import { AnyAbility } from '@casl/ability'
import { Typography } from '@material-ui/core'
import { APIError, Establishment } from '@sugg-gestion/ubidreams-react-suggpro'
import clsx from 'clsx'
import SubmitButton from 'components/common/submitButton'
import AddNewsForm, { AddNewsFormState } from 'components/forms/news/addNewsForm'
import AuthenticateActions from 'components/layouts/components/authenticateActions'
import AuthenticateContent from 'components/layouts/components/authenticateContent'
import React from 'react'
import { Trans } from 'react-i18next'
import { SocialNetwork } from '../../../containers/slate'
import DialogMultipleLoading, { LoadingState } from '../../dialogs/dialogMultipleLoading'
import { useStyles } from './styles.css'

interface Props {
    establishment: Establishment
    initialValues: AddNewsFormState
    onSubmit: (values: AddNewsFormState) => void
    inProgress: boolean
    createInProgress: boolean
    loadingNetworks?: Array<SocialNetwork>
    onBack: () => void
    closeLoadingDialog: () => void
    isFacebookLinked: boolean
    isInstagramLinked: boolean
    isFacebookSignedExpired: boolean
    isGoogleLinked: boolean
    isGoogleSignedExpired: boolean
    isTwitterLinked: boolean
    isTwitterSignedExpired: boolean
    shareOnFacebookInProgress: boolean
    shareOnInstagramInProgress: boolean
    shareOnTwitterInProgress: boolean
    shareOnGoogleInProgress: boolean
    ability: AnyAbility
    addError?: APIError
    facebookError?: APIError
    googleError?: APIError
    instagramError?: APIError
    twitterError?: APIError
}

const AddNewsView: React.FC<Props> = ({
    establishment,
    onBack,
    closeLoadingDialog,
    inProgress,
    createInProgress,
    loadingNetworks,
    shareOnFacebookInProgress,
    shareOnInstagramInProgress,
    shareOnGoogleInProgress,
    shareOnTwitterInProgress,
    ability,
    addError,
    facebookError,
    googleError,
    instagramError,
    twitterError,
    ...formData
}) => {
    const classes = useStyles()

    const loading = () => {
        const loadingStates: Array<LoadingState> = [
            {
                title: <Trans i18nKey={'views.slate.add.inProgress'} />,
                start: true,
                finish: !createInProgress,
                error: addError,
                hasError: addError !== undefined,
            },
        ]
        loadingNetworks?.forEach((network) => {
            switch (network) {
                case SocialNetwork.Facebook:
                    loadingStates.push({
                        title: <Trans i18nKey={'views.slate.add.shareOnFacebookInProgress'} />,
                        start: !createInProgress && shareOnFacebookInProgress,
                        finish: !createInProgress && !shareOnFacebookInProgress,
                        error: facebookError,
                        hasError: facebookError !== undefined || addError !== undefined,
                    })
                    break
                case SocialNetwork.Instagram:
                    loadingStates.push({
                        title: <Trans i18nKey={'views.slate.add.shareOnInstagramInProgress'} />,
                        start: !createInProgress && shareOnInstagramInProgress,
                        finish: !createInProgress && !shareOnInstagramInProgress,
                        error: instagramError,
                        hasError: instagramError !== undefined || addError !== undefined,
                    })
                    break
                case SocialNetwork.Google:
                    loadingStates.push({
                        title: <Trans i18nKey={'views.slate.add.shareOnGoogleInProgress'} />,
                        start: !createInProgress && shareOnGoogleInProgress,
                        finish: !createInProgress && !shareOnGoogleInProgress,
                        error: googleError,
                        hasError: googleError !== undefined || addError !== undefined,
                    })
                    break
                case SocialNetwork.Twitter:
                    loadingStates.push({
                        title: <Trans i18nKey={'views.slate.add.shareOnTwitterInProgress'} />,
                        start: !createInProgress && shareOnTwitterInProgress,
                        finish: !createInProgress && !shareOnTwitterInProgress,
                        error: twitterError,
                        hasError: twitterError !== undefined || addError !== undefined,
                    })
                    break
            }
        })
        return loadingStates
    }

    return (
        <AddNewsForm
            inProgress={inProgress}
            showInstagram
            showTwitter={false}
            canPublishMultiplePhoto={ability.can('api.multi_photo_access')}
            {...formData}
        >
            {(content, actions) => (
                <>
                    <AuthenticateContent>
                        {inProgress && (
                            <DialogMultipleLoading onClose={closeLoadingDialog} loading={loading()} />
                        )}
                        <Typography variant="h1" color="textSecondary" className={classes.title}>
                            <Trans i18nKey="views.news.addNews.title" />
                        </Typography>
                        {content}
                    </AuthenticateContent>
                    <AuthenticateActions>
                        <SubmitButton variant="contained" onClick={onBack}>
                            <Trans i18nKey="actions.back" />
                        </SubmitButton>
                        {actions.map((action, index) => (
                            <SubmitButton
                                key={index}
                                variant="contained"
                                color="primary"
                                {...action}
                                className={clsx(action.className)}
                            />
                        ))}
                    </AuthenticateActions>
                </>
            )}
        </AddNewsForm>
    )
}

export default AddNewsView
