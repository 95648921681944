import React from 'react'
import TextField, { TextFieldProps } from './textField'

interface Props extends TextFieldProps {}

const EmailField: React.FC<Props> = ({ name, label, ...rest }) => {
    return <TextField label={label} name={name} autoComplete="email" type="email" {...rest} />
}

export default EmailField
