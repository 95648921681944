import React, { useEffect, useState } from 'react'
import Modal from '@material-ui/core/Modal/Modal'
import { useMediaQuery } from '@material-ui/core'
import YoutubeVideo from './youtubeVideo'

interface Props {
    open: boolean
    handleClose: () => void
    videoKey: string
    playlist: string
}

const VideoModal: React.FC<Props> = ({ open, handleClose, videoKey, playlist }) => {
    const isLandscape = useMediaQuery('(orientation: landscape)')
    const calculWidth = () => {
        let maxWidth = typeof window !== `undefined` ? document.documentElement.clientWidth : 1920
        let maxHeight = typeof window !== `undefined` ? document.documentElement.clientHeight : 1080
        let width = 1080
        if (width > maxWidth) {
            width = maxWidth
        }
        const height = (width * 9) / 16
        if (height > maxHeight - 40) {
            width = (16 / 9) * maxHeight - 40
        }
        return width
    }
    const [width, setWidth] = useState(() => {
        return calculWidth()
    })

    useEffect(() => {
        const width = calculWidth()
        setWidth(width)
    }, [isLandscape])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{ outline: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <div style={{ outline: 0 }}>
                <YoutubeVideo videoKey={videoKey} width={width} playlist={playlist} />
            </div>
        </Modal>
    )
}

export default VideoModal
