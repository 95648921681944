import React from 'react'
import withAuthenticateLayout, { WithAuthenticateLayout } from '../hoc/withAuthenticateLayout'
import EstablishmentCompletedView from 'components/views/establishment/establishmentCompletedView'

interface Props extends WithAuthenticateLayout {
    step?: number
    steps?: number
}

const EstablishmentCompleted: React.FC<Props> = ({ establishment, step = 8, steps = 8 }) => {
    return <EstablishmentCompletedView step={step} steps={steps} establishment={establishment} />
}

export default withAuthenticateLayout(EstablishmentCompleted)
