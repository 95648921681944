import React from 'react'
import { SubmitButtonProps } from '../../common/submitButton'
import { useFormsStyles } from '../forms.css'
import { Form } from 'react-final-form'
import { Grid, Typography } from '@material-ui/core'
import { Trans } from 'react-i18next'
import { SortedImage } from '../helpers/useSortedImages'
import { moveArrayItemToNewIndex } from '../helpers/moveArrayItemToNewIndex'
import SortedImagesField from '../components/sortedImagesField'
import _ from 'lodash'
import DraggableImage from './components/draggableImage'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: MenuFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: MenuFormState
}

export interface MenuFormState {
    photos: Array<SortedImage>
}

const MenuForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
}) => {
    const formsClasses = useFormsStyles()

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            mutators={{
                moveEntry: (args, state, tools) => {
                    const from = args[0] as number
                    const to = args[1] as number
                    const {
                        formState: { values },
                    } = state
                    let { photos } = values as MenuFormState
                    // TODO: check
                    photos = moveArrayItemToNewIndex(photos, from, to)
                    const newPhotos: Array<SortedImage> = []
                    photos.forEach((photo, index) => {
                        newPhotos.push({
                            ...photo,
                            sortOrder: index,
                        })
                    })
                    tools.changeValue(state, 'photos', () => newPhotos)
                },
                deleteImage: (args, state, tools) => {
                    const image = args[0] as SortedImage
                    const {
                        formState: { values },
                    } = state
                    const { photos } = values as MenuFormState
                    const imageIndex = photos.findIndex((photo) => photo.id === image.id)
                    photos.splice(imageIndex, 1)
                    tools.changeValue(state, 'photos', () => photos)
                },
            }}
            onSubmit={onSubmit}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting, form: { mutators } }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <SortedImagesField
                            defaultValue={initialValues.photos}
                            name="photos"
                            errorName="photo"
                            defaultProperties={{
                                id: 0,
                                url: '',
                                sortOrder: 0,
                            }}
                        >
                            {(images, Uploader, hasError, error) => {
                                return (
                                    <Grid container spacing={2}>
                                        {_.sortBy(images, ['sortOrder']).map((image, index) => (
                                            <DraggableImage
                                                key={index}
                                                index={index}
                                                url={image.url}
                                                moveEntry={mutators.moveEntry}
                                                deleteEntry={() => mutators.deleteImage(image)}
                                                lg={3}
                                                sm={4}
                                                xs={6}
                                            />
                                        ))}
                                        <Grid item lg={3} sm={4} xs={6}>
                                            <Uploader className={formsClasses.uploader} />
                                        </Grid>
                                        {hasError && (
                                            <Grid item>
                                                <Typography>{error}</Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                )
                            }}
                        </SortedImagesField>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                className: formsClasses.submit,
                                children: <Trans i18nKey="actions.validate" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}

export default MenuForm
