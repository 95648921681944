import React, { ComponentType } from 'react'
import ReactPixel from 'react-facebook-pixel'

const withFacebookPixel = (ComposedComponent: ComponentType) => {
    const WithFacebookPixel: React.FC = ({ ...props }) => {
        return <ComposedComponent {...props} />
    }

    const { REACT_APP_FACEBOOK_PIXEL_ID } = process.env
    if (!REACT_APP_FACEBOOK_PIXEL_ID) {
        return WithFacebookPixel
    }
    const options = {
        autoConfig: true, // set pixel's autoConfig
        debug: false, // enable logs
    }
    ReactPixel.init(REACT_APP_FACEBOOK_PIXEL_ID, undefined, options)
    ReactPixel.pageView()
    return WithFacebookPixel
}

export default withFacebookPixel
