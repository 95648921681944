import { AnyAbility } from '@casl/ability'
import { Badge, Button, useMediaQuery } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import { useTheme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import { Alert } from '@material-ui/lab'
import { Establishment, User } from '@sugg-gestion/ubidreams-react-suggpro'
import Logo from 'components/common/logo'
import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { ApplicationState } from '../../core/store/reducers'
import { useStyles } from './authenticateLayout.css'
import AuthenticateDrawer from './components/authenticateDrawer'

interface Props {
    restaurantOwner: User
    signOut: () => void
    establishments: Establishment[]
    ability: AnyAbility
}

const AuthenticateLayout: React.FC<Props> = ({
    signOut,
    restaurantOwner,
    establishments,
    ability,
    children,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const classes = useStyles(isMobile)()
    const [mobileOpen, setMobileOpen] = React.useState(false)
    const history = useHistory()
    const { messagingStatus } = useSelector(({ suggpro: { messaging } }: ApplicationState) => ({
        messagingStatus: messaging.status,
    }))

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar} elevation={0}>
                <Toolbar className={classes.toolbarAside}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        {messagingStatus && messagingStatus.hasUnreadConversation ? (
                            <Badge overlap="rectangular" color={'primary'} variant={'dot'}>
                                <MenuIcon />
                            </Badge>
                        ) : (
                            <MenuIcon />
                        )}
                    </IconButton>
                    <Hidden mdUp implementation="js">
                        <Logo className={classes.logoContainer} spacing={2} />
                    </Hidden>
                    <div className={classes.headerRight} />
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer}>
                {establishments.map((establishment) => (
                    <React.Fragment key={'establishment' + establishment.id}>
                        <Hidden mdUp implementation="css">
                            <Drawer
                                variant="temporary"
                                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                            >
                                <AuthenticateDrawer
                                    signOut={signOut}
                                    restaurantOwner={restaurantOwner}
                                    establishment={establishment}
                                    ability={ability}
                                />
                            </Drawer>
                        </Hidden>
                        <Hidden smDown implementation="css">
                            <Drawer
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                variant="permanent"
                                elevation={0}
                                open
                            >
                                <AuthenticateDrawer
                                    signOut={signOut}
                                    restaurantOwner={restaurantOwner}
                                    establishment={establishment}
                                    ability={ability}
                                />
                            </Drawer>
                        </Hidden>
                    </React.Fragment>
                ))}
            </nav>
            <div className={classes.contentContainer}>
                <div className={classes.toolbarSpacing} />
                {(restaurantOwner.isGoogleSignedExpired || restaurantOwner.isFacebookSignedExpired) && (
                    <Alert
                        severity="error"
                        action={
                            history.location.pathname === '/account/sign' ? null : (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.alertButton}
                                    component={Link}
                                    to="/account/sign"
                                >
                                    <Trans i18nKey="actions.renew" />
                                </Button>
                            )
                        }
                    >
                        <Trans
                            i18nKey="drawer.expired"
                            components={[
                                restaurantOwner.isGoogleSignedExpired ? (
                                    <Trans i18nKey="error.expired.google" />
                                ) : null,
                                restaurantOwner.isFacebookSignedExpired ? (
                                    <Trans i18nKey="error.expired.facebook" />
                                ) : null,
                            ]}
                        />
                    </Alert>
                )}
                <main className={classes.content}>{children}</main>
            </div>
        </div>
    )
}

export default AuthenticateLayout
