import React, { useState } from 'react'
import {
    Button,
    CardContent,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Switch,
    Typography,
} from '@material-ui/core'
import { Trans } from 'react-i18next'
import { useStyles } from './styles.css'
import EstablishmentCreateView from './establishmentCreateView'
import { Establishment, User } from '@sugg-gestion/ubidreams-react-suggpro'
import SignUpContent from 'components/layouts/components/signUpContent'
import PaymentCodeForm, { PaymentCodeFormState } from 'components/forms/establishment/paymentCodeForm'
import SubmitButton from 'components/common/submitButton'
import clsx from 'clsx'
import { useAddresses } from 'components/forms/helpers/useAddresses'
import { Pricing } from 'core/services/contentful/ressources/types'
import PricingCard from './components/pricingCard'

interface Props {
    step: number
    steps: number
    pricingCollection: Array<Pricing>
    onChoosePricing: (pricing: Pricing) => void
    inProgress?: string
    onSubmitPaymentCode: (values: PaymentCodeFormState) => void
    paymentCodeInProgress: boolean
    establishment: Establishment
    restaurantOwner: User
    onEditInvoiceAddress: () => void
}

const EstablishmentPaymentView: React.FC<Props> = ({
    step,
    steps,
    pricingCollection,
    onChoosePricing,
    inProgress,
    establishment,
    onSubmitPaymentCode,
    paymentCodeInProgress,
    restaurantOwner,
    onEditInvoiceAddress,
}) => {
    const classes = useStyles()
    const { showInvoiceAddress } = useAddresses()

    const [annualSwitch, setAnnualSwitch] = useState(true)

    return (
        <SignUpContent>
            <EstablishmentCreateView step={step} steps={steps}>
                <Typography variant="h1" color="textSecondary" className={classes.title}>
                    <Trans i18nKey="views.payment.title" />
                </Typography>
                <FormControl margin="normal" fullWidth className={classes.annualSwitchContainer}>
                    <Typography
                        className={clsx(classes.monthlySwitchLabel, {
                            [classes.selected]: !annualSwitch,
                        })}
                    >
                        <Trans i18nKey="views.payment.monthlySwitch" />
                    </Typography>
                    <FormControlLabel
                        classes={{
                            label: clsx(classes.annualSwitchLabel, {
                                [classes.selected]: annualSwitch,
                            }),
                        }}
                        control={
                            <Switch
                                color="primary"
                                checked={annualSwitch}
                                onChange={() => setAnnualSwitch(!annualSwitch)}
                            />
                        }
                        label={
                            <Trans
                                i18nKey="views.payment.annualSwitch"
                                components={[<Typography component="strong" className={classes.semiBold} />]}
                            />
                        }
                    />
                </FormControl>
                <Grid container spacing={4} justifyContent="center" className={classes.plansContainer}>
                    {pricingCollection
                        .filter((pricing) => pricing.type === (annualSwitch ? 'year' : 'month'))
                        .map((pricing, index) => (
                            <Grid key={index} item lg={3} md={4} xs={12}>
                                <PricingCard
                                    pricing={pricing}
                                    establishment={establishment}
                                    onChoosePricing={onChoosePricing}
                                    inProgress={inProgress}
                                />
                            </Grid>
                        ))}
                </Grid>
                <div className={classes.couponContainer}>
                    <Divider />
                    <CardContent>
                        <Container disableGutters maxWidth="xs" className={classes.invoiceTitleContainer}>
                            <Typography variant="h4" color="textSecondary" className={classes.invoiceTitle}>
                                <Trans i18nKey="views.payment.invoice.title" />
                                <Button
                                    variant="outlined"
                                    className={classes.editAction}
                                    onClick={onEditInvoiceAddress}
                                >
                                    <Trans i18nKey="actions.edit" />
                                </Button>
                            </Typography>
                        </Container>
                        <Typography>{showInvoiceAddress(establishment, restaurantOwner)}</Typography>
                    </CardContent>
                </div>
                <Grid container spacing={8} justifyContent="center">
                    <Grid item sm={12}>
                        <Divider />
                        <Grid container alignItems="center" className={classes.plansContainer}>
                            <Grid item sm={6} xs={12} className={classes.code}>
                                <Typography>
                                    <Trans i18nKey="views.payment.code.info" />
                                </Typography>
                                <PaymentCodeForm
                                    initialValues={{
                                        code: '',
                                    }}
                                    onSubmit={onSubmitPaymentCode}
                                    inProgress={paymentCodeInProgress}
                                >
                                    {(content, actions) => (
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                {content}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                classes={{
                                                    root: classes.rowAction,
                                                }}
                                            >
                                                {actions.map((action, index) => (
                                                    <SubmitButton
                                                        key={index}
                                                        variant="outlined"
                                                        {...action}
                                                        className={clsx(action.className, classes.action)}
                                                    />
                                                ))}
                                            </Grid>
                                        </Grid>
                                    )}
                                </PaymentCodeForm>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </EstablishmentCreateView>
        </SignUpContent>
    )
}

export default EstablishmentPaymentView
