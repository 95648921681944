import { useRefreshUser } from '@sugg-gestion/ubidreams-react-suggpro'
import EstablishmentPaymentCheckView from 'components/views/establishment/establishmentPaymentCheckView'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import { redirectToEstablishment } from 'core/store/app/actions'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import withSignUpLayout, { WithSignUpLayout } from '../hoc/withSignUpLayout'

interface Props extends WithSignUpLayout {
    step?: number
    steps?: number
}

const EstablishmentPaymentCheck: React.FC<Props> = ({ establishment, step = 1, steps = 8 }) => {
    const dispatch = useDispatch()

    const { displayError } = useApiErrors()
    const { refreshUser, inProgress } = useRefreshUser()

    const [isReady, setIsReady] = useState<boolean>(false)
    const [count, setCount] = useState(0)
    const [currentCount, setCurrentCount] = useState(0)

    const countRef = useRef(count)
    countRef.current = count

    useEffect(() => {
        const timer = setInterval(() => {
            setCount(countRef.current + 1)
            if (countRef.current > 4) {
                clearTimeout(timer)
            }
        }, 2000)
        return () => clearTimeout(timer)
    }, [])

    useEffect(() => {
        if (!isReady) {
            if (count !== currentCount) {
                if (count < 5) {
                    setCurrentCount(count)
                    refreshUser()
                        .then(() => {
                            if (establishment?.subscribeState === 'STRIPE_OK') {
                                setIsReady(true)
                                dispatch(redirectToEstablishment())
                            }
                        })
                        .catch((error) => displayError(error))
                } else {
                    setIsReady(true)
                }
            }
        }
    }, [isReady, count, displayError, establishment?.subscribeState, refreshUser, currentCount, dispatch])

    const handleCheckAgain = () => {
        refreshUser()
            .then(() => {
                if (establishment?.subscribeState === 'STRIPE_OK') {
                    dispatch(redirectToEstablishment())
                }
            })
            .catch((error) => displayError(error))
    }
    return (
        <EstablishmentPaymentCheckView
            step={step}
            steps={steps}
            count={count}
            onCheckAgain={handleCheckAgain}
            inProgress={inProgress}
        />
    )
}

export default withSignUpLayout(EstablishmentPaymentCheck)
