import { AnyAbility } from '@casl/ability'
import { Typography } from '@material-ui/core'
import { TwitterInfoResponse } from '@sugg-gestion/ubidreams-react-suggpro'
import clsx from 'clsx'
import SubmitButton from 'components/common/submitButton'
import { Page } from 'components/forms/components/socialNetworkField'
import FacebookGoogleForm, {
    FacebookGoogleFormState,
} from 'components/forms/establishment/facebookGoogleForm'
import SignUpActions from 'components/layouts/components/signUpActions'
import SignUpContent from 'components/layouts/components/signUpContent'
import React from 'react'
import { Trans } from 'react-i18next'
import EstablishmentCreateView from './establishmentCreateView'
import { useStyles } from './styles.css'

interface Props {
    step: number
    steps: number
    onSubmit: (values: FacebookGoogleFormState) => void
    inProgress: boolean
    initialValues: FacebookGoogleFormState
    googlePages: Array<Page>
    retrieveGooglePages: (setOpen: (open: boolean) => void) => void
    retrieveGooglePagesInProgress: boolean
    facebookPages: Array<Page>
    retrieveFacebookPages: (withInstagram: boolean) => Promise<void>
    retrieveFacebookPagesInProgress: boolean
    hideWarning: boolean
    fetchTwitterData: () => Promise<TwitterInfoResponse>
    fetchTwitterDataInProgress: boolean
    ability: AnyAbility
    unlinkTwitter: () => void
}

const EstablishmentFacebookGoogleView: React.FC<Props> = ({
    ability,
    retrieveFacebookPages,
    step,
    steps,
    ...formData
}) => {
    const classes = useStyles()

    return (
        <FacebookGoogleForm
            retrieveFacebookPages={(setOpen, withInstagram) =>
                retrieveFacebookPages(withInstagram).then(() => setOpen(true))
            }
            withInstagram
            withTwitter={false}
            {...formData}
        >
            {(content, actions) => (
                <>
                    <SignUpContent>
                        <EstablishmentCreateView step={step} steps={steps}>
                            <Typography variant="h1" color="textSecondary" className={classes.title}>
                                <Trans i18nKey="views.establishment.facebookGoogle.title" />
                            </Typography>
                            {content}
                        </EstablishmentCreateView>
                    </SignUpContent>
                    <SignUpActions>
                        {actions.map((action, index) => (
                            <SubmitButton
                                key={index}
                                variant="contained"
                                color="primary"
                                {...action}
                                className={clsx(action.className)}
                            />
                        ))}
                    </SignUpActions>
                </>
            )}
        </FacebookGoogleForm>
    )
}

export default EstablishmentFacebookGoogleView
