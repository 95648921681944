import { Button, Card, CardContent, Container, Grid, Typography } from '@material-ui/core'
import { Establishment } from '@sugg-gestion/ubidreams-react-suggpro'
import FlatCard from 'components/common/flatCard'
import { WebsiteCustomizationFormState } from 'components/forms/establishment/websiteCustomizationForm'
import React from 'react'
import { Trans } from 'react-i18next'
import { useStyles } from './websiteCustomization.css'
import { Color } from './websiteCustomization/color'
import { EditModal } from './websiteCustomization/editModal'
import { Image } from './websiteCustomization/image'
import { Line } from './websiteCustomization/line'

interface Props {
    editModalOpen: boolean
    handleEditCustomization: () => void
    handleModalClose: () => void
    establishment: Establishment
    onSubmit: (values: WebsiteCustomizationFormState) => void
    inProgress: boolean
}

export const WebsiteCustomization: React.FC<Props> = ({
    establishment,
    editModalOpen,
    handleEditCustomization,
    handleModalClose,
    ...formData
}) => {
    const classes = useStyles()

    const sectionOrder = [
        'title',
        'slate',
        'menu',
        'news',
        'about',
        'links',
        'book',
        'order',
        'photos',
        'information',
        'reviews',
        'footer',
    ]
    const websiteSections = sectionOrder.map((sectionName) => {
        // Search for the section in existing sections
        let section = establishment.websiteSections?.find((section) => section.section === sectionName)

        // If the section doesn't exist, create a new one with null values for colors and background image
        if (!section) {
            section = {
                section: sectionName,
                textColor: null,
                backgroundColor: null,
                backgroundImage: null,
            }
        }

        return section
    })

    const getWebsiteCustomizationInitialValues = (): WebsiteCustomizationFormState => {
        let initialValues: WebsiteCustomizationFormState = {
            font: establishment.websiteFont ?? undefined,
            backgroundColorLight: establishment.websiteBackgroundColorLight ?? undefined,
            backgroundColorDark: establishment.websiteBackgroundColorDark ?? undefined,
            titleSectionTextColorEnabled: [],
            titleSectionBackgroundColorEnabled: [],
            slateSectionTextColorEnabled: [],
            slateSectionBackgroundColorEnabled: [],
            menuSectionTextColorEnabled: [],
            menuSectionBackgroundColorEnabled: [],
            newsSectionTextColorEnabled: [],
            newsSectionBackgroundColorEnabled: [],
            aboutSectionTextColorEnabled: [],
            aboutSectionBackgroundColorEnabled: [],
            linksSectionTextColorEnabled: [],
            linksSectionBackgroundColorEnabled: [],
            bookSectionTextColorEnabled: [],
            bookSectionBackgroundColorEnabled: [],
            orderSectionTextColorEnabled: [],
            orderSectionBackgroundColorEnabled: [],
            photosSectionTextColorEnabled: [],
            photosSectionBackgroundColorEnabled: [],
            informationSectionTextColorEnabled: [],
            informationSectionBackgroundColorEnabled: [],
            reviewsSectionTextColorEnabled: [],
            reviewsSectionBackgroundColorEnabled: [],
            footerSectionTextColorEnabled: [],
            footerSectionBackgroundColorEnabled: [],
        }

        establishment.websiteSections?.forEach((section) => {
            // @ts-ignore
            initialValues[section.section + 'SectionTextColor'] = section.textColor
            if (section.textColor) {
                // @ts-ignore
                initialValues[section.section + 'SectionTextColorEnabled'] = [true]
            }
            // @ts-ignore
            initialValues[section.section + 'SectionBackgroundColor'] = section.backgroundColor
            if (section.backgroundColor) {
                // @ts-ignore
                initialValues[section.section + 'SectionBackgroundColorEnabled'] = [true]
            }
            if (section.backgroundImage) {
                // @ts-ignore
                initialValues[section.section + 'SectionBackgroundImage'] = {
                    id: 0,
                    url: section.backgroundImage,
                }
            }
        })

        return initialValues
    }
    return (
        <FlatCard>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Container disableGutters maxWidth="sm" className={classes.root}>
                            <Typography className={classes.photosTitle}>
                                <Trans i18nKey="views.externalLinks.customization.title" />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.action}
                                    onClick={handleEditCustomization}
                                >
                                    <Trans i18nKey="actions.edit" />
                                </Button>
                            </Typography>
                        </Container>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Line title={<Trans i18nKey="views.externalLinks.customization.websiteFont" />}>
                                <Typography>{establishment.websiteFont ?? 'Open Sans'}</Typography>
                            </Line>
                            <Line title={<Trans i18nKey="views.externalLinks.customization.websiteColors" />}>
                                <Card
                                    style={{
                                        width: 120,
                                        height: 40,
                                        background:
                                            'linear-gradient(135deg, ' +
                                            (establishment.websiteBackgroundColorLight ?? '#f6f6f6') +
                                            ' 0%, ' +
                                            (establishment.websiteBackgroundColorLight ?? '#f6f6f6') +
                                            ' 50%, ' +
                                            (establishment.websiteBackgroundColorDark ?? '#000000') +
                                            ' 50%, ' +
                                            (establishment.websiteBackgroundColorDark ?? '#000000') +
                                            ' 100%)',
                                    }}
                                />
                            </Line>
                            {websiteSections.length > 0 && (
                                <>
                                    <Line
                                        title={<Trans i18nKey="views.externalLinks.customization.sections" />}
                                    />
                                    {websiteSections.map((section, index) => (
                                        <Grid key={index} item lg={4} sm={6} xs={12}>
                                            <Card>
                                                <div className={classes.sectionTitleContainer}>
                                                    <Typography className={classes.sectionTitle}>
                                                        <Trans
                                                            i18nKey={
                                                                'views.externalLinks.customization.section.' +
                                                                section.section
                                                            }
                                                            components={[<strong />]}
                                                        />
                                                    </Typography>
                                                </div>
                                                <CardContent>
                                                    <Grid container spacing={2}>
                                                        <Line
                                                            xs={6}
                                                            title={
                                                                <Trans i18nKey="views.externalLinks.customization.backgroundColor" />
                                                            }
                                                        >
                                                            <Color color={section.backgroundColor} />
                                                        </Line>
                                                        <Line
                                                            xs={6}
                                                            title={
                                                                <Trans i18nKey="views.externalLinks.customization.textColor" />
                                                            }
                                                        >
                                                            <Color color={section.textColor} />
                                                        </Line>
                                                        <Line
                                                            xs={6}
                                                            title={
                                                                <Trans i18nKey="views.externalLinks.customization.backgroundImage" />
                                                            }
                                                        >
                                                            <Image image={section.backgroundImage} />
                                                        </Line>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            <EditModal
                open={editModalOpen}
                onClose={handleModalClose}
                initialValues={getWebsiteCustomizationInitialValues()}
                {...formData}
            />
        </FlatCard>
    )
}
