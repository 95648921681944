import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { Establishment, MessagingConversation, MessagingMedia } from '@sugg-gestion/ubidreams-react-suggpro'
import FacebookSquareIcon from 'components/icons/facebookSquareIcon'
import GmbIcon from 'components/icons/gmbIcon'
import InstagramSquareIcon from 'components/icons/instagramSquareIcon'
import TwitterSquareIcon from 'components/icons/twitterSquareIcon'
import i18next from 'i18next'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hashtagText: {
            color: theme.palette.primary.main,
        },
        hashtagTextDark: {
            color: 'black',
        },
        conversationPlatformIcon: {
            zIndex: 10,
            position: 'absolute',
            bottom: -4,
            left: '56%',
            width: 25,
            height: 25,
            [theme.breakpoints.down('xs')]: {
                width: 25,
                height: 25,
            },
        },
        conversationPostPlatformIcon: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        mediaContentRemove: {
            fontSize: '15px',
            textAlign: 'center',
            color: theme.palette.grey['600'],
        },
    }),
)

export const messagingLink = (conversation: MessagingConversation, establishment: Establishment) => {
    switch (conversation.typePlatform) {
        case 'facebook':
            switch (conversation.typeConversation) {
                case 'private_message':
                    return 'https://business.facebook.com/latest/inbox/messenger'
                case 'post':
                    return conversation.permalink || 'https://business.facebook.com/latest/inbox/facebook'
                case 'review':
                    return 'https://www.facebook.com/' + establishment.facebookPageId + '/reviews'
            }
            break
        case 'instagram':
            switch (conversation.typeConversation) {
                case 'private_message':
                    return 'https://www.instagram.com/direct/inbox/'
                case 'post':
                case 'review':
                    return 'https://business.facebook.com/latest/inbox/instagram'
            }
            break
        case 'twitter':
            switch (conversation.typeConversation) {
                case 'private_message':
                    return 'https://twitter.com/messages/' + conversation.threadId
                case 'post':
                case 'review':
                    return conversation.permalink
            }
            break
        case 'google':
            switch (conversation.typeConversation) {
                case 'review':
                    return conversation.permalink
            }
    }
}

interface PropsTextLookBetter {
    text: string
    hashtagColorVariant?: boolean
}

/**
 * Function to add a font style on hastag words
 * @param txt String text
 */
export const TextLookBetter: React.FC<PropsTextLookBetter> = ({ text, hashtagColorVariant }) => {
    const classes = useStyles()

    let hashtagStyle: string
    if (hashtagColorVariant) {
        hashtagStyle = classes.hashtagTextDark
    } else {
        hashtagStyle = classes.hashtagText
    }

    let arr = text.replaceAll('\n', '\n ').split(' ')
    return (
        <>
            {arr.map((element, i) => {
                if (element.startsWith('#')) {
                    return (
                        <React.Fragment key={i}>
                            {' '}
                            <b className={hashtagStyle}>{element}</b>
                        </React.Fragment>
                    )
                } else if (element.startsWith('http') || element.startsWith('https')) {
                    return (
                        <React.Fragment key={i}>
                            {' '}
                            <a href={element} target={'_blank'} rel="noreferrer">
                                {element}
                            </a>
                        </React.Fragment>
                    )
                } else {
                    return <React.Fragment key={i}>{' ' + element} </React.Fragment>
                }
            })}
        </>
    )
}

export const getPostTitle = (conversation: MessagingConversation) => {
    if (conversation.typeConversation === 'post') {
        let dateStr =
            String(conversation.createdAt.getDate()).padStart(2, '0') +
            '/' +
            String(conversation.createdAt.getMonth() + 1).padStart(2, '0') +
            '/' +
            conversation.createdAt.getFullYear()
        let hourStr =
            String(conversation.createdAt.getHours()).padStart(2, '0') +
            ':' +
            String(conversation.createdAt.getMinutes()).padStart(2, '0')

        return i18next.t('views.messaging.postTitle.' + conversation.typePlatform, {
            date: dateStr,
            hour: hourStr,
        })
    }
}

export const GetPlatformIcon = (conversation: MessagingConversation) => {
    const classes = useStyles()

    let iconStyle
    if (conversation.typeConversation === 'post') {
        iconStyle = classes.conversationPostPlatformIcon
    } else {
        iconStyle = classes.conversationPlatformIcon
    }

    switch (conversation.typePlatform) {
        case 'facebook':
            return <FacebookSquareIcon className={iconStyle} />
        case 'instagram':
            return <InstagramSquareIcon className={iconStyle} />
        case 'google':
            return <GmbIcon className={iconStyle} />
        case 'twitter':
            return <TwitterSquareIcon className={iconStyle} />
    }
}

interface PropsMessageMedia {
    media: MessagingMedia
}
export const MessageMedia: React.FC<PropsMessageMedia> = ({ media }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [mediaLoadError, setMediaLoadError] = useState(false)
    const [isImage, setIsImage] = useState(false)

    if (mediaLoadError) {
        return (
            <Typography className={classes.mediaContentRemove}>
                {t('views.messaging.mediaContentRemoved')}
            </Typography>
        )
    } else {
        switch (media.type) {
            case 'image':
                return (
                    <img
                        src={media.url}
                        style={{ width: '100%' }}
                        alt={'message media'}
                        onError={() => setMediaLoadError(true)}
                    />
                )
            case 'video':
                return (
                    <video
                        src={media.url}
                        controls={true}
                        style={{ width: '100%' }}
                        onError={() => setMediaLoadError(true)}
                    />
                )
            case 'story':
                if (isImage) {
                    return (
                        <img
                            src={media.url}
                            style={{ width: '100%' }}
                            alt={'message media'}
                            onError={() => setMediaLoadError(true)}
                        />
                    )
                } else {
                    return (
                        <video
                            src={media.url}
                            controls={true}
                            style={{ width: '100%' }}
                            onError={() => setIsImage(true)}
                        />
                    )
                }
            case 'audio':
                return (
                    <audio controls={true} onError={() => setMediaLoadError(true)}>
                        <source src={media.url} />
                    </audio>
                )
            default:
                return <></>
        }
    }
}
