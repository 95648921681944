import React from 'react'
import { CardActions, Divider } from '@material-ui/core'
import HiddenCss from '@material-ui/core/Hidden/HiddenCss'
import { useStyles } from './homeActions.css'

const HomeActions: React.FC = ({ children }) => {
    const classes = useStyles()
    return (
        <div className={classes.actionsContainer}>
            <HiddenCss smUp>
                <Divider />
            </HiddenCss>
            <CardActions className={classes.actions}>{children}</CardActions>
        </div>
    )
}

export default HomeActions
