import React, { useState } from 'react'
import {
    GmbAccountSelect,
    MultipleGoogleMyBusinessAccounts,
    useGoogleMyBusinessPages,
} from 'core/services/google/useGoogleMyBusinessPages'
import { useFacebookPages } from 'core/services/facebook/useFacebookPages'
import { Page } from 'components/forms/components/socialNetworkField'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import { Establishment, useEstablishmentUpdate } from '@sugg-gestion/ubidreams-react-suggpro'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { FacebookGoogleFormState } from 'components/forms/establishment/facebookGoogleForm'
import EstablishmentFacebookGoogleDialogView from 'components/views/establishment/dialogs/establishmentFacebookGoogleDialogView'
import DialogLoading from 'components/dialogs/dialogLoading'
import withFacebookAndGoogle from 'containers/hoc/withFacebookAndGoogle'
import { AnyAbility } from '@casl/ability'
import { useFetchTwitterData } from 'core/services/twitter/useFetchTwitterData'
import GoogleMyBusinessAccountDialogView from '../../../components/views/account/dialogs/googleMyBusinessAccountDialogView'

interface Props {
    open: boolean
    onClose: () => void
    establishment: Establishment
    ability: AnyAbility
}

const EstablishmentEditFacebookGoogleDialog: React.FC<Props> = ({
    establishment,
    open,
    onClose,
    ability,
}) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const { displayError } = useApiErrors()
    const { updateEstablishment, inProgress } = useEstablishmentUpdate()
    const { fetchTwitterData, inProgress: fetchTwitterDataInProgress } = useFetchTwitterData()

    const [openGmbAccountSelect, setOpenGmbAccountSelect] = useState<GmbAccountSelect | null>(null)

    const {
        fetchFacebookPagesAction,
        inProgress: fetchFacebookPagesInProgress,
        facebookPages,
    } = useFacebookPages()
    const {
        fetchGoogleMyBusinessPagesAction,
        inProgress: fetchGooglePagesInProgress,
        googlePages,
        confirmMultipleGoogleMyBusinessAccountsSelection,
    } = useGoogleMyBusinessPages(establishment)

    const convertFacebookPages = () => {
        if (facebookPages === undefined) {
            return []
        }
        const pages: Array<Page> = []
        for (let i = 0; i < facebookPages.length; i++) {
            const facebookPage = facebookPages[i]
            pages.push({
                id: facebookPage.id.toString(),
                name: facebookPage.name,
                address: facebookPage.single_line_address,
                category: facebookPage.category,
                url: 'https://www.facebook.com/' + facebookPage.id,
                withInstagram: !!facebookPage.instagram_business_account?.id,
            })
        }
        return pages
    }

    const convertGooglePages = () => {
        if (googlePages === undefined) {
            return []
        }

        const pages: Array<Page> = []
        for (let i = 0; i < googlePages.length; i++) {
            const googlePage = googlePages[i]
            pages.push({
                ...googlePage,
                url: googlePage.url !== null ? googlePage.url : undefined,
            })
        }
        return pages
    }

    const handleUpdateSocialNetworks = (values: FacebookGoogleFormState) => {
        let instagramPageId = null
        if (values.facebookPageId && facebookPages) {
            const facebookPage = facebookPages.find(
                (facebookPage) => facebookPage.id.toString() === values.facebookPageId,
            )
            if (facebookPage?.instagram_business_account) {
                instagramPageId = facebookPage.instagram_business_account.id
            }
        }
        let twitterAccount = {}
        if (values.twitterAccount?.twitterToken && values.twitterAccount?.twitterTokenSecret) {
            twitterAccount = values.twitterAccount
        }
        updateEstablishment({
            id: establishment.id,
            establishment: {
                ...values,
                googleSync: values.googleSync.length > 0 ? values.googleSync[0] : false,
                facebookSync: values.facebookSync.length > 0 ? values.facebookSync[0] : false,
                googleId: values.googlePageId ?? null,
                facebookPageId: values.facebookPageId ?? null,
                instagramPageId: instagramPageId,
                ...twitterAccount,
            },
        })
            .then((establishment) => {
                if (!establishment.isInstagramBusiness) {
                    enqueueSnackbar(t('views.establishment.facebookGoogle.instagramBusiness'), {
                        variant: 'warning',
                        persist: true,
                    })
                }
                enqueueSnackbar(t('views.establishment.facebookGoogle.success'), {
                    variant: 'success',
                })
                onClose()
            })
            .catch((error) => displayError(error))
    }

    const handleUnlinkTwitter = () => {
        updateEstablishment({
            id: establishment.id,
            establishment: {
                twitterID: null,
                twitterScreenName: null,
            },
        })
            .then(() => {
                enqueueSnackbar(t('views.establishment.facebookGoogle.success'), {
                    variant: 'success',
                })
                onClose()
            })
            .catch((error) => displayError(error))
    }

    const handleRetrieveGooglePages = (setOpen: (open: boolean) => void) => {
        return fetchGoogleMyBusinessPagesAction('edit')
            .then(() => setOpen(true))
            .catch((error) => {
                if (error instanceof MultipleGoogleMyBusinessAccounts) {
                    setOpenGmbAccountSelect({
                        setOpen,
                        accounts: error.accounts,
                    })
                } else {
                    displayError(error)
                }
            })
    }

    const handleChooseAccount = (setOpen: (open: boolean) => void, id?: string) => {
        return confirmMultipleGoogleMyBusinessAccountsSelection(id)
            .then(() => {
                setOpen(true)
            })
            .catch((error) => displayError(error))
            .finally(() => {
                setOpenGmbAccountSelect(null)
            })
    }

    return (
        <>
            <EstablishmentFacebookGoogleDialogView
                open={open}
                onClose={onClose}
                onSubmit={handleUpdateSocialNetworks}
                inProgress={inProgress}
                initialValues={{
                    facebookPageId: establishment.facebookPageId ?? undefined,
                    facebookSync: establishment.facebookSync ? [true] : [],
                    googlePageId: establishment.googleId ?? undefined,
                    googleSync: establishment.googleSync ? [true] : [],
                    twitterAccount:
                        establishment.twitterID &&
                        establishment.twitterScreenName &&
                        !establishment.isTwitterSignedExpired
                            ? {
                                  twitterID: establishment.twitterID,
                                  twitterScreenName: establishment.twitterScreenName,
                                  twitterToken: '',
                                  twitterTokenSecret: '',
                              }
                            : undefined,
                }}
                retrieveFacebookPages={fetchFacebookPagesAction}
                retrieveFacebookPagesInProgress={fetchFacebookPagesInProgress}
                retrieveGooglePages={handleRetrieveGooglePages}
                retrieveGooglePagesInProgress={
                    fetchGooglePagesInProgress || (openGmbAccountSelect?.accounts.length ?? 0) > 0
                }
                facebookPages={convertFacebookPages()}
                googlePages={convertGooglePages()}
                hideWarning={false}
                ability={ability}
                fetchTwitterData={fetchTwitterData}
                fetchTwitterDataInProgress={fetchTwitterDataInProgress}
                unlinkTwitter={handleUnlinkTwitter}
            />
            {openGmbAccountSelect && (
                <GoogleMyBusinessAccountDialogView
                    open={openGmbAccountSelect.accounts.length > 0}
                    onClose={() => setOpenGmbAccountSelect(null)}
                    accounts={openGmbAccountSelect.accounts}
                    onChooseAccount={(id?: string) => handleChooseAccount(openGmbAccountSelect.setOpen, id)}
                />
            )}
        </>
    )
}

const Loader: React.FC = () => {
    const { t } = useTranslation()
    return (
        <DialogLoading
            maxWidth="md"
            title={t('views.establishment.facebookGoogle.title')}
            loadingMessage={t('common.loading')}
        />
    )
}

export default withFacebookAndGoogle(EstablishmentEditFacebookGoogleDialog, <Loader />)
