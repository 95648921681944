import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: 300,
            padding: theme.spacing(4),
        },
        shouldRetry: {
            'display': 'flex',
            'justifyContent': 'center',
            'alignItems': 'center',
            'flexDirection': 'column',
            '& .MuiTypography-root': {
                margin: theme.spacing(4, 0),
            },
        },
        action: {
            width: 300,
            maxWidth: '100%',
        },
        withAction: {
            paddingRight: 184,
        },
        categoryRow: {
            display: 'block',
            marginBottom: theme.spacing(),
            marginTop: theme.spacing(0.5),
            fontStyle: 'italic',
        },
        pageTitle: {
            'display': 'flex',
            'alignItems': 'center',
            '& > svg': {
                marginLeft: theme.spacing(),
            },
        },
        cancel: {
            '& > .MuiTypography-root': {
                textAlign: 'center',
            },
        },
    }),
)
