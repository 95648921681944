import { Reducer } from 'redux'
import { FacebookPagesData, GooglePagesData, ShareState } from './types'
import { getType } from 'typesafe-actions'
import actions from '../actions'

const initialState: ShareState = {
    isSharingSlateOnFacebook: false,
    isSharingSlateOnGoogle: false,
}

const reducer: Reducer<ShareState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.shareSlateOnFacebook): {
            return {
                ...state,
                isSharingSlateOnFacebook: true,
            }
        }
        case getType(actions.setIsSharingSlateOnFacebook): {
            const isSharingSlateOnFacebook: boolean = action.payload
            return {
                ...state,
                isSharingSlateOnFacebook,
            }
        }
        case getType(actions.setFacebookPagesData): {
            const facebookPagesData: FacebookPagesData | undefined = action.payload
            return {
                ...state,
                facebookPagesData,
            }
        }
        case getType(actions.shareSlateOnGoogle): {
            return {
                ...state,
                isSharingSlateOnGoogle: true,
            }
        }
        case getType(actions.setIsSharingSlateOnGoogle): {
            const isSharingSlateOnGoogle: boolean = action.payload
            return {
                ...state,
                isSharingSlateOnGoogle,
            }
        }
        case getType(actions.setGooglePagesData): {
            const googlePagesData: GooglePagesData | undefined = action.payload
            return {
                ...state,
                googlePagesData,
            }
        }
        default:
            return state
    }
}

export { reducer as shareReducer }
