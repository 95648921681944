import React from 'react'
import { useStyles } from './mainLayout.css'
import Version from 'components/common/version'
import Logo from '../common/logo'
import HiddenCss from '@material-ui/core/Hidden/HiddenCss'
import clsx from 'clsx'

interface Props {
    withLogo?: boolean
    className?: string
}

const MainLayout: React.FC<Props> = ({ children, className, withLogo = true }) => {
    const classes = useStyles()
    return (
        <div className={clsx(classes.mainLayout, className)}>
            {withLogo && <Logo className={classes.logo} />}
            {children}
            <HiddenCss xsDown>
                <Version />
            </HiddenCss>
        </div>
    )
}

export default MainLayout
