import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            paddingBottom: theme.spacing(4),
            display: 'inline-flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        text: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        container: {
            marginLeft: 0,
            padding: 0,
        },
        divider: {
            margin: theme.spacing(8, 0, 3),
        },
        chip: {
            margin: theme.spacing(0.5),
        },
        root: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            position: 'relative',
            minHeight: 200,
        },
        grid: {
            '& .MuiDataGrid-overlay': {
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                zIndex: 1000,
            },
        },
        tableContainer: {
            flexGrow: 1,
            minHeight: 400,
        },
        address: {
            whiteSpace: 'pre',
            fontSize: '0.7rem',
        },
        delete: {
            color: theme.palette.error.main,
        },
        restaurantOwner: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        },
        header: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
        },
        actions: {
            '& > button, & > a': {
                'margin': theme.spacing(0, 1),
                '&:first-child': {
                    marginLeft: 0,
                },
                '&:last-child': {
                    marginRight: 0,
                },
            },
        },
        confirmed: {
            backgroundColor: theme.palette.success.light,
            color: theme.palette.success.contrastText,
        },
        new: {
            // backgroundColor: theme.palette.warning.light,
            // color: theme.palette.warning.contrastText,
        },
        rejected: {
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.contrastText,
        },
        iconButton: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(0.5),
            minWidth: 'auto',
            minHeight: 'auto',
            marginRight: theme.spacing(),
        },
    }),
)
