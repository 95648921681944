import { AnyAbility } from '@casl/ability'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { TwitterInfoResponse } from '@sugg-gestion/ubidreams-react-suggpro'
import clsx from 'clsx'
import SubmitButton from 'components/common/submitButton'
import CloseButton from 'components/dialogs/closeButton'
import { Page } from 'components/forms/components/socialNetworkField'
import FacebookGoogleForm, {
    FacebookGoogleFormState,
} from 'components/forms/establishment/facebookGoogleForm'
import React from 'react'
import { Trans } from 'react-i18next'
import { useStyles } from './styles.css'

interface Props {
    open: boolean
    onClose: () => void
    onSubmit: (values: FacebookGoogleFormState) => void
    inProgress: boolean
    initialValues: FacebookGoogleFormState
    googlePages: Array<Page>
    retrieveGooglePages: (setOpen: (open: boolean) => void) => Promise<void>
    retrieveGooglePagesInProgress: boolean
    facebookPages: Array<Page>
    retrieveFacebookPages: (withInstagram: boolean) => Promise<void>
    retrieveFacebookPagesInProgress: boolean
    hideWarning: boolean
    ability: AnyAbility
    fetchTwitterData: () => Promise<TwitterInfoResponse>
    fetchTwitterDataInProgress: boolean
    unlinkTwitter: () => void
}

const EstablishmentFacebookGoogleDialogView: React.FC<Props> = ({
    open,
    onClose,
    ability,
    retrieveFacebookPages,
    ...formData
}) => {
    const classes = useStyles()

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={fullScreen}
            scroll="paper"
            classes={{
                paper: classes.paper,
            }}
            maxWidth="md"
        >
            <FacebookGoogleForm
                retrieveFacebookPages={(setOpen, withInstagram) =>
                    retrieveFacebookPages(withInstagram).then(() => setOpen(true))
                }
                withInstagram
                withTwitter={false}
                formClassName={classes.form}
                {...formData}
            >
                {(content, actions) => (
                    <>
                        <DialogTitle>
                            <Trans i18nKey="views.establishment.facebookGoogle.title" />
                        </DialogTitle>
                        <DialogContent dividers>
                            <CloseButton onClose={onClose} />
                            {content}
                        </DialogContent>
                        <DialogActions className={classes.actions}>
                            <Button variant="contained" onClick={onClose}>
                                <Trans i18nKey="actions.cancel" />
                            </Button>
                            {actions.map((action, index) => (
                                <SubmitButton
                                    key={index}
                                    variant="contained"
                                    color="primary"
                                    {...action}
                                    className={clsx(action.className)}
                                >
                                    <Trans i18nKey="actions.validate" />
                                </SubmitButton>
                            ))}
                        </DialogActions>
                    </>
                )}
            </FacebookGoogleForm>
        </Dialog>
    )
}

export default EstablishmentFacebookGoogleDialogView
