import { SvgIcon, SvgIconProps } from '@material-ui/core'
import React from 'react'
import { useStyles } from './websiteIcon.css'

interface Props {
    className?: string
}

const UpArrowCircleIcon: React.FC<SvgIconProps & Props> = (props) => {
    const classes = useStyles()
    return (
        <SvgIcon {...props} viewBox="0.000000 0.000000 1080.000000 1080.000000" classes={classes}>
            <g>
                <path d="M517.0 1.0 C930.8 -14.2 1210.2 418.8 1020.9 788.2 C819.3 1173.8 272.6 1178.7 65.2 795.9 C-121.9 444.9 120.9 21.6 517.0 1.0 M316.0 519.5 C327.5 544.2 352.3 559.3 379.9 555.1 C416.1 549.3 448.8 504.0 479.6 483.1 C481.7 569.4 477.8 656.1 481.0 742.4 C486.1 777.1 523.3 800.5 556.8 790.9 C585.4 784.3 604.3 755.8 602.9 727.0 C603.1 645.7 601.6 564.4 602.4 483.1 C622.8 500.1 642.7 518.0 663.3 534.9 C674.9 545.1 687.1 553.0 702.9 555.2 C745.2 562.1 782.3 517.8 769.7 477.5 C762.1 450.6 735.7 436.4 716.2 418.6 C696.6 401.4 676.6 384.8 656.6 368.1 C633.9 348.6 611.3 328.9 588.2 309.8 C573.5 296.5 555.4 286.8 534.8 289.8 C508.1 291.4 491.5 314.3 471.5 329.0 C454.0 344.1 436.3 359.1 418.6 373.9 C399.0 391.1 379.1 407.8 359.0 424.5 C328.8 446.7 296.1 479.0 316.0 519.5 " />
            </g>
        </SvgIcon>
    )
}

export default UpArrowCircleIcon
