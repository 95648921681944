import {
    Button,
    CardActions,
    CardContent,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import ShareIcon from '@material-ui/icons/Share'
import TwitterIcon from '@material-ui/icons/Twitter'
import { Establishment, NewsModel } from '@sugg-gestion/ubidreams-react-suggpro'
import { NewsEventModel } from '@sugg-gestion/ubidreams-react-suggpro/dist/models/newsModel'
import clsx from 'clsx'
import moment from 'moment'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { InlineShareButtons } from 'sharethis-reactjs'
import FlatCard from '../../../common/flatCard'
import ImageCard from '../../../common/imageCard'
import SubmitButton from '../../../common/submitButton'
import GoogleIcon from '../../../icons/googleIcon'
import { useStyles } from '../styles.css'

interface EventsProps extends NewsModel {
    picture: string
    event: NewsEventModel
}

interface Props {
    event: EventsProps
    establishment: Establishment
    shareOnGoogle: (eventId: NewsModel['id']) => void
    shareOnFacebook: (eventId: NewsModel['id']) => void
    shareOnInstagram: (eventId: NewsModel['id']) => void
    shareOnTwitter: (eventId: NewsModel['id']) => void
    onDelete: (eventId: NewsModel['id']) => void
    eventInProgress?: number
    deleteInProgress: boolean
    isSharingSlateOnFacebook: boolean
    isSharingSlateOnGoogle: boolean
    isSharingSlateOnInstagram: boolean
    isSharingSlateOnTwitter: boolean
    isGoogleSignedExpired: boolean
    isFacebookSignedExpired: boolean
    isTwitterSignedExpired: boolean
    showTwitter: boolean
}

const EventView: React.FC<Props> = ({
    event,
    establishment,
    shareOnGoogle,
    shareOnFacebook,
    shareOnInstagram,
    shareOnTwitter,
    onDelete,
    deleteInProgress,
    isSharingSlateOnFacebook,
    isSharingSlateOnInstagram,
    isSharingSlateOnGoogle,
    isSharingSlateOnTwitter,
    isGoogleSignedExpired,
    isTwitterSignedExpired,
    isFacebookSignedExpired,
    eventInProgress,
    showTwitter,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    let dateDisplay = 'views.news.differentDate'
    if (moment(event.event.startTime).isSame(moment(event.endTime), 'day')) {
        dateDisplay = 'views.news.date'
    }
    return (
        <Grid item xs={12} sm={6} key={event.id}>
            <FlatCard>
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography className={classes.formTitle}>{event.event.title}</Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.formTitle}>
                                <Trans i18nKey="form.eventPhoto" />
                            </Typography>
                            <Grid container spacing={1}>
                                {event.images &&
                                    event.images.map((image) => (
                                        <Grid item xs={6}>
                                            <ImageCard url={image.picture} />
                                        </Grid>
                                    ))}
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Typography className={classes.formTitle}>
                                        <Trans i18nKey="form.eventDate" />
                                    </Typography>
                                    <Typography color="textPrimary">
                                        <Trans
                                            i18nKey={dateDisplay}
                                            values={{
                                                startTime: moment(event.event.startTime).toDate(),
                                                endTime: moment(event.endTime).toDate(),
                                            }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.formTitle}>
                                        <Trans i18nKey="form.eventComment" />
                                    </Typography>
                                    <Typography color="textPrimary" className={classes.comment}>
                                        {event.comment === '' ? t('views.slate.noComment') : event.comment}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.formTitle}>
                                <Trans i18nKey="views.news.shares" />
                            </Typography>
                            <List>
                                <ListItem divider>
                                    <ListItemIcon>
                                        <FacebookIcon className={classes.facebookIcon} />
                                    </ListItemIcon>
                                    {event.isFacebookShared ? (
                                        <>
                                            <ListItemText primary={t('views.slate.share.facebook')} />
                                            {event.facebookPermalink && (
                                                <Button
                                                    variant="outlined"
                                                    href={event.facebookPermalink}
                                                    target="_blank"
                                                    endIcon={<OpenInNewIcon />}
                                                >
                                                    <Trans i18nKey="actions.checkPublication" />
                                                </Button>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <ListItemText primary={t('views.slate.share.notFacebook')} />
                                            <ListItemSecondaryAction className={clsx(classes.action)}>
                                                <SubmitButton
                                                    variant="outlined"
                                                    color="primary"
                                                    endIcon={<ShareIcon />}
                                                    onClick={() => shareOnFacebook(event.id)}
                                                    loading={
                                                        eventInProgress === event.id &&
                                                        isSharingSlateOnFacebook
                                                    }
                                                    disabled={eventInProgress !== undefined}
                                                >
                                                    {establishment.isFacebookLinked &&
                                                    !isFacebookSignedExpired ? (
                                                        <Trans i18nKey="actions.share" />
                                                    ) : isFacebookSignedExpired ? (
                                                        <Trans i18nKey="actions.renewSignInFacebook" />
                                                    ) : (
                                                        <Trans i18nKey="actions.link" />
                                                    )}
                                                </SubmitButton>
                                            </ListItemSecondaryAction>
                                        </>
                                    )}
                                </ListItem>
                                <ListItem divider>
                                    <ListItemIcon>
                                        <InstagramIcon className={classes.instagramIcon} />
                                    </ListItemIcon>
                                    {event.isInstagramShared ? (
                                        <>
                                            <ListItemText primary={t('views.slate.share.instagram')} />
                                            {event.instagramPermalink && (
                                                <Button
                                                    variant="outlined"
                                                    href={event.instagramPermalink}
                                                    target="_blank"
                                                    endIcon={<OpenInNewIcon />}
                                                >
                                                    <Trans i18nKey="actions.checkPublication" />
                                                </Button>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <ListItemText primary={t('views.slate.share.notInstagram')} />
                                            <ListItemSecondaryAction className={clsx(classes.action)}>
                                                <SubmitButton
                                                    variant="outlined"
                                                    color="primary"
                                                    endIcon={<ShareIcon />}
                                                    onClick={() => shareOnInstagram(event.id)}
                                                    loading={
                                                        eventInProgress === event.id &&
                                                        isSharingSlateOnInstagram
                                                    }
                                                    disabled={eventInProgress !== undefined}
                                                >
                                                    {establishment.instagramPageId &&
                                                    !isFacebookSignedExpired ? (
                                                        <Trans i18nKey="actions.share" />
                                                    ) : isFacebookSignedExpired ? (
                                                        <Trans i18nKey="actions.renewSignInFacebook" />
                                                    ) : (
                                                        <Trans i18nKey="actions.link" />
                                                    )}
                                                </SubmitButton>
                                            </ListItemSecondaryAction>
                                        </>
                                    )}
                                </ListItem>
                                <ListItem divider>
                                    <ListItemIcon>
                                        <GoogleIcon />
                                    </ListItemIcon>
                                    {event.isGoogleShared ? (
                                        <>
                                            <ListItemText primary={t('views.slate.share.google')} />
                                            {event.googleLocalPostPermalink ? (
                                                <Button
                                                    variant="outlined"
                                                    href={event.googleLocalPostPermalink}
                                                    target="_blank"
                                                    endIcon={<OpenInNewIcon />}
                                                >
                                                    <Trans i18nKey="actions.checkPublication" />
                                                </Button>
                                            ) : establishment.googleUrl ? (
                                                <Button
                                                    variant="outlined"
                                                    href={establishment.googleUrl}
                                                    target="_blank"
                                                    endIcon={<OpenInNewIcon />}
                                                >
                                                    <Trans i18nKey="actions.checkPublication" />
                                                </Button>
                                            ) : null}
                                        </>
                                    ) : (
                                        <>
                                            <ListItemText primary={t('views.slate.share.notGoogle')} />
                                            <ListItemSecondaryAction className={clsx(classes.action)}>
                                                <SubmitButton
                                                    variant="outlined"
                                                    color="primary"
                                                    endIcon={<ShareIcon />}
                                                    onClick={() => shareOnGoogle(event.id)}
                                                    loading={
                                                        eventInProgress === event.id && isSharingSlateOnGoogle
                                                    }
                                                    disabled={eventInProgress !== undefined}
                                                >
                                                    {establishment.isGoogleLinked &&
                                                    !isGoogleSignedExpired ? (
                                                        <Trans i18nKey="actions.share" />
                                                    ) : isGoogleSignedExpired ? (
                                                        <Trans i18nKey="actions.renewSignInGoogle" />
                                                    ) : (
                                                        <Trans i18nKey="actions.link" />
                                                    )}
                                                </SubmitButton>
                                            </ListItemSecondaryAction>
                                        </>
                                    )}
                                </ListItem>
                                {showTwitter && (
                                    <ListItem divider>
                                        <ListItemIcon>
                                            <TwitterIcon className={classes.twitterIcon} />
                                        </ListItemIcon>
                                        {event.isTwitterShared ? (
                                            <>
                                                <ListItemText primary={t('views.slate.share.twitter')} />
                                                {event.twitterPermalink && (
                                                    <Button
                                                        variant="outlined"
                                                        href={event.twitterPermalink}
                                                        target="_blank"
                                                        endIcon={<OpenInNewIcon />}
                                                    >
                                                        <Trans i18nKey="actions.checkPublication" />
                                                    </Button>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <ListItemText primary={t('views.slate.share.notTwitter')} />
                                                <ListItemSecondaryAction className={clsx(classes.action)}>
                                                    <SubmitButton
                                                        variant="outlined"
                                                        color="primary"
                                                        endIcon={<ShareIcon />}
                                                        onClick={() => shareOnTwitter(event.id)}
                                                        loading={
                                                            eventInProgress === event.id &&
                                                            isSharingSlateOnTwitter
                                                        }
                                                        disabled={eventInProgress !== undefined}
                                                    >
                                                        {establishment.twitterID &&
                                                        !isTwitterSignedExpired ? (
                                                            <Trans i18nKey="actions.share" />
                                                        ) : isTwitterSignedExpired ? (
                                                            <Trans i18nKey="actions.renewSignInTwitter" />
                                                        ) : (
                                                            <Trans i18nKey="actions.link" />
                                                        )}
                                                    </SubmitButton>
                                                </ListItemSecondaryAction>
                                            </>
                                        )}
                                    </ListItem>
                                )}
                                <ListItem>
                                    <ListItemIcon>
                                        <ShareIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t('views.slate.share.otherShare')} />
                                    <ListItemSecondaryAction
                                        className={clsx(classes.action, classes.shareButtons)}
                                    >
                                        <InlineShareButtons
                                            config={{
                                                alignment: 'center', // alignment of buttons (left, center, right)
                                                enabled: true, // show/hide buttons (true, false)
                                                language: 'fr', // which language to use (see LANGUAGES)
                                                padding: 8, // padding within buttons (INTEGER)
                                                size: 42, // the size of each button (INTEGER)
                                                networks: [
                                                    // which networks to include (see SHARING NETWORKS)
                                                    'twitter',
                                                    'email',
                                                    'pinterest',
                                                    'linkedin',
                                                    'whatsapp',
                                                ],
                                                // OPTIONAL PARAMETERS
                                                url: event.picture, // (defaults to current url)
                                                color: 'white',
                                                font_size: 12,
                                                radius: 6,
                                                show_total: false,
                                                labels: null,
                                            }}
                                        />
                                        <InlineShareButtons
                                            config={{
                                                alignment: 'center', // alignment of buttons (left, center, right)
                                                enabled: true, // show/hide buttons (true, false)
                                                language: 'fr', // which language to use (see LANGUAGES)
                                                padding: 8, // padding within buttons (INTEGER)
                                                size: 42, // the size of each button (INTEGER)
                                                networks: [
                                                    // which networks to include (see SHARING NETWORKS)
                                                    'whatsapp',
                                                ],
                                                // OPTIONAL PARAMETERS
                                                url: event.picture, // (defaults to current url)
                                                color: 'white',
                                                font_size: 12,
                                                radius: 6,
                                                show_total: false,
                                                labels: 'cta',
                                            }}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <SubmitButton
                        variant="outlined"
                        onClick={() => onDelete(event.id)}
                        disabled={eventInProgress !== undefined}
                        loading={eventInProgress === event.id && deleteInProgress}
                    >
                        <Trans i18nKey="actions.delete" />
                    </SubmitButton>
                </CardActions>
            </FlatCard>
        </Grid>
    )
}

export default EventView
