import React from 'react'
import SubmitButton from '../../common/submitButton'
import { Trans, useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import { Moment } from 'moment'
import KeyboardDatePickerField from '../components/keyboardDatePickerField'
import { useFormsStyles } from '../forms.css'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: BookingDayFormState) => void
    inProgress: boolean
    disabled?: boolean
    initialValues: BookingDayFormState
}

export interface BookingDayFormState {
    day: Moment
}

interface BookingDayFormError {
    day?: string
}

const BookingDayForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    inProgress,
    initialValues,
    disabled = false,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()

    const validate = (values: BookingDayFormState) => {
        const errors: BookingDayFormError = {}

        if (!values.day) {
            errors.day = t('form-validation.day')
        }
        return errors
    }
    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                        <KeyboardDatePickerField
                            name="day"
                            pastDate={false}
                            fullWidth={false}
                            className={formsClasses.extend}
                        />
                        <SubmitButton
                            color="primary"
                            variant="contained"
                            type="submit"
                            loading={submitting || inProgress}
                            className={formsClasses.submit}
                            size="small"
                            disabled={disabled}
                        >
                            <Trans i18nKey="actions.add" />
                        </SubmitButton>
                    </div>
                </form>
            )}
        </Form>
    )
}

export default BookingDayForm
