import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            paddingBottom: theme.spacing(4),
        },
        loadingView: {
            minHeight: 300,
            position: 'relative',
        },
    }),
)
