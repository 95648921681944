import { Card, CardActions, CardContent, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import SubmitButton from 'components/common/submitButton'
import HomeLayout from 'components/layouts/homeLayout'
import { useStyles } from 'components/views/oauth/oauth.css'
import React, { useState } from 'react'
import { Trans } from 'react-i18next'

interface Props {
    givenName: string
    familyName: string
    token: string
    redirectTo: string
    clientId: string
    onCancel: () => void
}

const OAuthAuthorize: React.FC<Props> = ({
    givenName,
    familyName,
    token,
    redirectTo,
    clientId,
    onCancel,
}) => {
    const classes = useStyles()
    const [submiting, setSubmiting] = useState(false)
    return (
        <HomeLayout>
            <div className={classes.root}>
                <Card elevation={0}>
                    <CardContent>
                        <Typography variant="h1">
                            <Trans i18nKey="oauth.authorize.title" values={{ givenName, familyName }} />
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <Typography>
                            <Trans i18nKey="oauth.authorize.welcome" />
                        </Typography>
                    </CardContent>
                    <form
                        method="post"
                        action={(process.env.REACT_APP_OAUTH_M_API_URI ?? '') + '/oauth/authorize'}
                        onSubmit={() => setSubmiting(true)}
                    >
                        <input type="hidden" name="access_token" value={token} />
                        <input type="hidden" name="redirect_uri" value={redirectTo} />
                        <input type="hidden" name="response_type" value="code" />
                        <input type="hidden" name="from" value="suggpro" />
                        <input type="hidden" name="client_id" value={clientId} />
                        <CardActions className={classes.action}>
                            <Button variant="outlined" onClick={onCancel}>
                                <Trans i18nKey="oauth.authorize.cancel" />
                            </Button>
                            <SubmitButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                loading={submiting}
                            >
                                <Trans i18nKey="oauth.authorize.action" values={{ givenName, familyName }} />
                            </SubmitButton>
                        </CardActions>
                    </form>
                </Card>
            </div>
        </HomeLayout>
    )
}

export default OAuthAuthorize
