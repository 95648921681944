import { useEffect } from 'react'
import ReactGA from 'react-ga4'

const useGoogleTagManager = () => {
    useEffect(() => {
        const { REACT_APP_USE_GOOGLE_TAG_MANAGER } = process.env
        if (REACT_APP_USE_GOOGLE_TAG_MANAGER) {
            ReactGA.initialize(process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID ?? '')
        }
    })
}

export default useGoogleTagManager
