import { useEmailVerification, useSignOut } from '@sugg-gestion/ubidreams-react-suggpro'
import { EmailValidationFormState } from 'components/forms/account/emailValidationForm'
import EmailValidationView from 'components/views/account/emailValidationView'
import { WithAuthenticationProps } from 'containers/hoc/withAuthentication'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { redirectToEstablishment } from '../../core/store/app/actions'
import withSignUpLayout from '../hoc/withSignUpLayout'

interface Props extends WithAuthenticationProps {}

const EmailValidation: React.FC<Props> = ({ restaurantOwner }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const { displayError } = useApiErrors()
    const { signOut, inProgress: signOutInProgress } = useSignOut()
    const {
        verifyEmail,
        inProgress: verifyEmailInProgress,
        resendVerifyEmail,
        resendInProgress,
    } = useEmailVerification()

    const [resendDisabled, setResendDisabled] = useState(false)

    const handleEmailValidation = (values: EmailValidationFormState) => {
        verifyEmail({
            email: restaurantOwner.email,
            ...values,
        })
            .then(() => {
                enqueueSnackbar(t('views.emailValidation.success'), {
                    variant: 'success',
                })
                dispatch(redirectToEstablishment())
            })
            .catch((error) => displayError(error))
    }

    const handleSignOut = () => {
        signOut()
            .then(() => {
                enqueueSnackbar(t('views.signOut.success'), {
                    variant: 'success',
                })
            })
            .catch((error) => displayError(error))
    }

    const handleResendCode = () => {
        resendVerifyEmail({ email: restaurantOwner.email })
            .then(() => {
                setResendDisabled(true)
                enqueueSnackbar(t('views.emailValidation.resendSuccess'), {
                    variant: 'success',
                })
            })
            .catch((error) => displayError(error))
    }

    return (
        <EmailValidationView
            initialValues={{ code: '' }}
            inProgress={verifyEmailInProgress}
            signOutInProgress={signOutInProgress}
            onSubmit={handleEmailValidation}
            onCancel={handleSignOut}
            email={restaurantOwner.email}
            onResend={handleResendCode}
            resendInProgress={resendInProgress}
            resendDisabled={resendDisabled}
        />
    )
}

export default withSignUpLayout(EmailValidation, { maxWidth: 'sm' }, false)
