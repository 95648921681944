import React from 'react'
import { useFormsStyles } from '../forms.css'
import { Form } from 'react-final-form'
import { SubmitButtonProps } from 'components/common/submitButton'
import { Trans, useTranslation } from 'react-i18next'
import TextField from '../components/textField'
import { CountryCode } from 'libphonenumber-js/types'
import { inputLength, isDunsValid } from 'core/services/vat/checkVat'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: DunsFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: DunsFormState
    type: 'duns' | 'europeanVat'
    country: CountryCode
}

export interface DunsFormState {
    duns: string
}

interface DunsFormError {
    duns?: string
}

const DunsForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
    type,
    country,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()

    const validate = (values: DunsFormState) => {
        const errors: DunsFormError = {}
        if (!isDunsValid(country, values.duns)) {
            errors.duns = t('form-validation.' + type)
        }
        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <div>
                            <TextField
                                name="duns"
                                label={t('form.' + type)}
                                type={type === 'duns' ? 'number' : 'text'}
                                InputProps={{
                                    inputProps: {
                                        maxLength: inputLength(country),
                                    },
                                }}
                                required
                            />
                        </div>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                className: formsClasses.submit,
                                children: <Trans i18nKey="actions.validate" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}

export default DunsForm
