import { Avatar, Grid, Typography } from '@material-ui/core'
import { Establishment, MessagingConversation, MessagingMessage } from '@sugg-gestion/ubidreams-react-suggpro'
import moment from 'moment'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ReplyPostCommentForm from '../../../forms/messaging/replyPostCommentForm'
import { TextLookBetter } from '../messagingUtils'
import { useStyles } from '../styles.css'
import MenuConversation from './conversation/menu'

interface Props {
    establishment: Establishment
    conversation: MessagingConversation
    comment: MessagingMessage
    onDeleteMessage: (messageId: string) => void
    onHideMessage: (messageId: string) => void
    onBlockUser: (messageId: string) => void
}

const CommentBox: React.FC<Props> = ({
    establishment,
    conversation,
    comment,
    onDeleteMessage,
    onHideMessage,
    onBlockUser,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const isAuthor = () => {
        if (
            conversation.typePlatform === 'facebook' &&
            comment.author.userId === establishment.facebookPageId
        ) {
            return true
        } else if (
            conversation.typePlatform === 'instagram' &&
            comment.author.userId === establishment.instagramPageId
        ) {
            return true
        } else if (
            conversation.typePlatform === 'twitter' &&
            comment.author.userId === establishment.twitterID
        ) {
            return true
        } else if (
            conversation.typePlatform === 'google' &&
            comment.author.userId === establishment.googleId
        ) {
            return true
        }
        return false
    }

    return (
        <>
            <Grid item sm={1} xs={2} style={{ textAlign: 'center' }}>
                <Avatar src={comment.author.img ?? undefined} className={classes.discussPostImg} />
                {!(
                    conversation.typePlatform === 'facebook' && conversation.typeConversation === 'review'
                ) && (
                    <MenuConversation
                        isTwitter={conversation.typePlatform === 'twitter'}
                        orientation="left"
                        onDeleteMessage={
                            conversation.typePlatform === 'twitter' && !isAuthor()
                                ? undefined
                                : () => onDeleteMessage(comment.messageId)
                        }
                        onHideMessage={
                            isAuthor() || conversation.typePlatform === 'twitter'
                                ? undefined
                                : comment.hidden
                                ? undefined
                                : () => onHideMessage(comment.messageId)
                        }
                        onBlockUser={isAuthor() ? undefined : () => onBlockUser(comment.messageId)}
                    />
                )}
            </Grid>
            <Grid item sm={11} xs={10}>
                <div className={classes.commentBox}>
                    <div className={classes.commentBoxInner}>
                        <div className={classes.commentAuthorName}>
                            <a
                                className={classes.userProfileLink}
                                href={comment.author.link || ''}
                                target={'_blank'}
                                rel={'noreferrer'}
                                title={t('views.messaging.lookupProfile')}
                            >
                                {comment.author.name}
                            </a>
                            {comment.author.username && (
                                <Typography className={classes.username}>
                                    <a
                                        className={classes.username}
                                        href={comment.author.link || ''}
                                        target={'_blank'}
                                        rel={'noreferrer'}
                                        title={t('views.messaging.lookupProfile')}
                                    >
                                        {conversation.typePlatform === 'twitter' && '@'}
                                        {comment.author.username}
                                    </a>
                                </Typography>
                            )}
                        </div>
                        {comment.statusCode ? (
                            <Typography className={classes.messageStatusIndication}>
                                <Trans i18nKey={'views.messaging.messageNotSupported'}>
                                    <span className={classes.capitalize}>
                                        {{ platform: conversation.typePlatform }}
                                    </span>
                                </Trans>
                            </Typography>
                        ) : (
                            <Typography noWrap={false}>
                                <TextLookBetter text={comment.messageText} />
                            </Typography>
                        )}
                        {comment.medias.length > 0 &&
                            comment.medias.map((media, index) => {
                                return (
                                    <div className={classes.commentMedia}>
                                        {media.type === 'video' ? (
                                            <video src={media.url} controls={true} style={{ width: '100%' }}>
                                                {t('views.messaging.privateContentMedia')}
                                            </video>
                                        ) : (
                                            <img
                                                src={media.url}
                                                alt={t('views.messaging.privateContentMedia')}
                                                style={{ width: '100%' }}
                                                key={index}
                                            />
                                        )}
                                    </div>
                                )
                            })}
                    </div>
                    <div className={classes.commentInfo}>
                        {moment(comment.createdAt).format('DD/MM/YYYY HH:mm')}
                    </div>
                    {conversation.typeConversation === 'review' && conversation.typePlatform === 'google' ? (
                        <></>
                    ) : (
                        <ReplyPostCommentForm conversation={conversation} comment={comment} />
                    )}
                </div>
            </Grid>
        </>
    )
}

export default CommentBox
