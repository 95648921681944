import { useSignInByEmail } from '@sugg-gestion/ubidreams-react-suggpro'
import { SignInFormState } from 'components/forms/auth/signInForm'
import OAuthSignInView from 'components/views/oauth/signIn'
import { PageType, useApiErrors } from 'core/services/suggPro/useApiErrors'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    redirectTo: string
    onSignIn: () => void
}

const OAuthSignIn: React.FC<Props> = ({ redirectTo, onSignIn }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const { displayError } = useApiErrors()
    const { signInByEmail, inProgress: signInInProgress } = useSignInByEmail()

    const handleSignIn = (values: SignInFormState) => {
        return signInByEmail(values)
            .then(() => {
                enqueueSnackbar(t('views.signIn.success'), {
                    variant: 'success',
                })
                onSignIn()
            })
            .catch((error) => displayError(error, PageType.SIGN_IN))
    }
    const handleCancel = () => {
        window.location.href = redirectTo
    }

    return (
        <OAuthSignInView
            onSubmit={handleSignIn}
            onCancel={handleCancel}
            inProgress={signInInProgress}
            initialValues={{ email: '', password: '' }}
        />
    )
}

export default OAuthSignIn
