import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import date from './date'

i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            fr: {
                translation: require('./languages/french.json'),
            },
        },
        lng: 'fr',
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false,
            format(value, format, locale) {
                if (format === 'currency') {
                    return new Intl.NumberFormat(locale, {
                        style: 'currency',
                        currency: 'EUR',
                        maximumFractionDigits: 2,
                    }).format(value)
                } else if (format === 'number') {
                    return new Intl.NumberFormat(locale, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    }).format(value)
                } else if (format === 'numberAnnual') {
                    return new Intl.NumberFormat(locale, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    }).format(value)
                } else if (value instanceof Date) {
                    return date.format(value, format)
                } else if (value === undefined) {
                    return format
                }
                return value
            },
        },
    })

date.init('fr')
