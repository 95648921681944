import clsx from 'clsx'
import React from 'react'
import { Establishment, MessagingConversation, MessagingMessage } from '@sugg-gestion/ubidreams-react-suggpro'
import { Avatar, Grid, Typography, useTheme } from '@material-ui/core'
import { useStyles } from '../styles.css'
import { MessageMedia, TextLookBetter } from '../messagingUtils'
import moment from 'moment'
import { Trans, useTranslation } from 'react-i18next'
import MenuConversation from './conversation/menu'

interface Props {
    establishment: Establishment
    conversation: MessagingConversation
    message: MessagingMessage
    onDeleteMessage: (messageId: string) => void
    onHideMessage: (messageId: string) => void
}

const MessageBoxPrivateMessage: React.FC<Props> = ({
    establishment,
    conversation,
    message,
    onDeleteMessage,
    onHideMessage,
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const { t } = useTranslation()

    // if the senderId is the current user (me)
    if (
        (conversation.typePlatform === 'facebook' &&
            message.author.userId === establishment.facebookPageId) ||
        (conversation.typePlatform === 'instagram' &&
            message.author.userId === establishment.instagramPageId) ||
        (conversation.typePlatform === 'twitter' && message.author.userId === establishment.twitterID)
    ) {
        // For story mention
        if (message.medias.length > 0 && message.medias[0].type === 'story' && conversation.receiver) {
            message.messageText = t('views.messaging.storyMentionOther', { name: conversation.receiver.name })
        }

        return (
            <div className={classes.messageSenderYou}>
                <div className={classes.messageBoxContainer}>
                    <div className={classes.messageBoxContainerSenderYou}>
                        <div className={classes.messageBoxInnerSenderYou}>
                            <div
                                className={
                                    message.medias.length > 0
                                        ? classes.messageBoxSenderYou + ' ' + classes.messageBoxWithMedia
                                        : classes.messageBoxSenderYou
                                }
                            >
                                {message.hidden ? (
                                    <Typography className={classes.messageStatusIndication}>
                                        <Trans i18nKey={'views.messaging.messageHidden'} />
                                    </Typography>
                                ) : message.statusCode ? (
                                    <Typography
                                        className={classes.messageStatusIndication}
                                        style={{ color: theme.palette.grey['800'] }}
                                    >
                                        <Trans i18nKey={'views.messaging.messageNotSupported'}>
                                            <span className={classes.capitalize}>
                                                {{ platform: conversation.typePlatform }}
                                            </span>
                                        </Trans>
                                    </Typography>
                                ) : (
                                    message.messageText && (
                                        <Typography noWrap={false}>
                                            <TextLookBetter
                                                text={message.messageText}
                                                hashtagColorVariant={true}
                                            />
                                        </Typography>
                                    )
                                )}
                                {!message.hidden && message.medias.length > 0 && (
                                    <Grid container className={classes.messageMediaContainer}>
                                        {message.medias.map((media, indexMedia) => {
                                            return (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={message.medias.length > 1 ? 6 : 12}
                                                    key={indexMedia}
                                                >
                                                    <MessageMedia media={media} />
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                )}
                            </div>
                        </div>
                        <div className={classes.messageInfo}>
                            <MenuConversation
                                orientation="right"
                                onDeleteMessage={() => onDeleteMessage(message.messageId)}
                                onHideMessage={
                                    !message.hidden ? () => onHideMessage(message.messageId) : undefined
                                }
                            />
                            {moment(message.createdAt).format('DD/MM/YYYY HH:mm')}
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        // For story mention
        if (message.medias.length > 0 && message.medias[0].type === 'story') {
            message.messageText = t('views.messaging.storyMention', { name: message.author.name })
        }

        return (
            <div className={classes.messageSenderOther}>
                <div className={classes.messageBoxContainer}>
                    <Avatar src={message.author.img ?? undefined} />
                    <div className={classes.messageBoxContainerSenderOther}>
                        <div className={classes.messageBoxInnerSenderOther}>
                            <div
                                className={clsx(classes.messageBoxSenderOther, {
                                    [classes.messageBoxWithMedia]: message.medias.length > 0,
                                })}
                            >
                                {message.hidden ? (
                                    <Typography className={classes.messageStatusIndication}>
                                        <Trans i18nKey={'views.messaging.messageHidden'} />
                                    </Typography>
                                ) : message.statusCode ? (
                                    <Typography className={classes.messageStatusIndication}>
                                        <Trans i18nKey={'views.messaging.messageNotSupported'}>
                                            <span className={classes.capitalize}>
                                                {{ platform: conversation.typePlatform }}
                                            </span>
                                        </Trans>
                                    </Typography>
                                ) : (
                                    message.messageText && (
                                        <Typography noWrap={false}>
                                            <TextLookBetter
                                                text={message.messageText}
                                                hashtagColorVariant={true}
                                            />
                                        </Typography>
                                    )
                                )}
                                {!message.hidden && message.medias.length > 0 && (
                                    <Grid container className={classes.messageMediaContainer} spacing={1}>
                                        {message.medias.map((media, indexMedia) => {
                                            return (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={message.medias.length > 1 ? 6 : 12}
                                                    key={indexMedia}
                                                >
                                                    <MessageMedia media={media} />
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                )}
                            </div>
                        </div>
                        <div className={classes.messageInfo}>
                            {moment(message.createdAt).format('DD/MM/YYYY HH:mm')}
                            <MenuConversation
                                orientation="left"
                                onDeleteMessage={() => onDeleteMessage(message.messageId)}
                                onHideMessage={
                                    !message.hidden ? () => onHideMessage(message.messageId) : undefined
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MessageBoxPrivateMessage
