import {
    Grid,
    List,
    ListItem,
    ListItemIcon,
    Switch,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@material-ui/core'
import ListItemText from '@material-ui/core/ListItemText'
import { useTheme } from '@material-ui/core/styles'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import TwitterIcon from '@material-ui/icons/Twitter'
import UpdateIcon from '@material-ui/icons/Update'
import { Establishment, SlateCategory } from '@sugg-gestion/ubidreams-react-suggpro'
import clsx from 'clsx'
import SubmitButton, { SubmitButtonProps } from 'components/common/submitButton'
import GoogleIcon from 'components/icons/googleIcon'
import DeleteFlagDialog from 'components/views/slate/setting/deleteFlagDialog'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { Field, Form, FormSpy } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import NoAccess from '../../common/noAccess'
import CategoriesField from '../components/categoriesField'
import CheckboxGroupField, { CheckboxValue } from '../components/checkboxGroupField'
import TextField from '../components/textField'
import ValidityField from '../components/validityField'
import { useFormsStyles } from '../forms.css'
import { moveArrayItemToNewIndex } from '../helpers/moveArrayItemToNewIndex'
import { Image } from '../helpers/useImages'
import { SortedImage } from '../helpers/useSortedImages'
import PhotoContainer from './components/photoContainer'
import { DefaultDeleteFlagFormState } from './defaultDeleteFlagForm'
import { countHashtag } from '../../views/slate/slateUtils'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: AddFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    updateEstablishmentInProgress: boolean
    initialValues: AddFormState
    categories?: Array<SlateCategory>
    isFacebookLinked: boolean
    isInstagramLinked: boolean
    isGoogleLinked: boolean
    isFacebookSignedExpired: boolean
    isGoogleSignedExpired: boolean
    showInstagram: boolean
    showTwitter: boolean
    isTwitterLinked: boolean
    isTwitterSignedExpired: boolean
    extraSharing?: React.ReactNode
    establishment: Establishment
    onUpdateEstablishment: (
        values: DefaultDeleteFlagFormState,
    ) => Promise<{ shouldClose: boolean; shouldDelete: boolean }>
    canPublishMultiplePhoto: boolean
    canAutomaticallyDelete: boolean
}

export interface AddFormImageState extends SortedImage {
    comment?: string
    isGoogleShared: boolean
    isTwitterShared: boolean
    isFacebookShared: boolean
    isInstagramShared: boolean
}

export interface AddFormState {
    photos?: Array<AddFormImageState>
    categories: Array<number>
    validityDate: Date
    isGoogleShared: boolean
    isTwitterShared: boolean
    isFacebookShared: boolean
    isInstagramShared: boolean
    comment: string
    facebookDeleteFlag: boolean
    twitterDeleteFlag: boolean
    googlePostDeleteFlag: boolean
    googlePhotoDeleteFlag: boolean
    shouldDelete: Array<boolean>
}

export interface AddFormError {
    photo?: string
    categories?: string
    validityDate?: string
    comment?: string
}

enum SocialNetwork {
    Facebook = 'Facebook',
    Instagram = 'Instagram',
    Google = 'Google',
    Twitter = 'Twitter',
}

const AddForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
        errors: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    updateEstablishmentInProgress,
    initialValues,
    categories,
    isFacebookLinked,
    isInstagramLinked,
    isGoogleLinked,
    isFacebookSignedExpired,
    isGoogleSignedExpired,
    showInstagram,
    showTwitter,
    isTwitterSignedExpired,
    isTwitterLinked,
    extraSharing,
    establishment,
    onUpdateEstablishment,
    canPublishMultiplePhoto,
    canAutomaticallyDelete,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const theme = useTheme()
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true })
    const [openDeleteFlag, setOpenDeleteFlag] = useState(false)

    const validate = (values: AddFormState) => {
        const errors: AddFormError = {}

        if (countHashtag(values.comment, values.categories.length) > 30) {
            errors.comment = t('form-validation.hashtagLimit')
            errors.categories = t('form-validation.hashtagLimit')
        }

        if ((values.photos?.length ?? 0) === 0) {
            errors.photo = t('form-validation.slatePhoto')
        } else if ((values.photos?.length ?? 0) > 10) {
            errors.photo = t('form-validation.slatePhotoMax')
        } else if (values.photos) {
            const twitterSharedImages = values.photos.filter((image) => {
                return image.isTwitterShared
            })
            if (twitterSharedImages.length > 4) {
                errors.photo = t('form-validation.slatePhotoTwitterMax')
            }
        }
        if (values.categories.length === 0) {
            errors.categories = t('form-validation.slateCategories')
        }
        return errors
    }

    const defaultValue: Array<CheckboxValue<boolean>> = [
        {
            label: (
                <Trans i18nKey="form.slateDelete" components={[<UpdateIcon style={{ marginRight: 8 }} />]} />
            ),
            value: true,
        },
    ]

    const deleteFlagsInitialValues = (shouldDelete: boolean) => {
        let initialValues: DefaultDeleteFlagFormState = {
            facebookDeleteFlag: establishment.facebookDeleteFlag,
            twitterDeleteFlag: establishment.twitterDeleteFlag,
            googlePostDeleteFlag: establishment.googlePostDeleteFlag,
            googlePhotoDeleteFlag: establishment.googlePhotoDeleteFlag,
        }
        if (
            shouldDelete &&
            !(
                establishment.facebookDeleteFlag ||
                establishment.twitterDeleteFlag ||
                establishment.googlePostDeleteFlag ||
                establishment.googlePhotoDeleteFlag
            )
        ) {
            initialValues = {
                facebookDeleteFlag: true,
                twitterDeleteFlag: true,
                googlePostDeleteFlag: false,
                googlePhotoDeleteFlag: true,
            }
        }
        return initialValues
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            mutators={{
                moveEntry: (args, state, tools) => {
                    const from = args[0] as number
                    const to = args[1] as number
                    const {
                        formState: { values },
                    } = state
                    let { photos } = values as AddFormState
                    photos = moveArrayItemToNewIndex(photos ?? [], from, to)
                    const newPhotos: Array<AddFormImageState> = []
                    photos.forEach((photo, index) => {
                        newPhotos.push({
                            ...photo,
                            sortOrder: index,
                        })
                    })
                    tools.changeValue(state, 'photos', () => newPhotos)
                },
                deleteImage: (args, state, tools) => {
                    const image = args[0] as Image
                    const {
                        formState: { values },
                    } = state
                    const { photos } = values as AddFormState

                    const imageIndex = photos?.findIndex((photo) => photo.id === image.id) ?? -1
                    photos?.splice(imageIndex, 1)
                    tools.changeValue(state, 'photos', () => photos)
                },
                setShouldDelete: (args, state, tools) => {
                    const shouldDelete = args[0] as boolean
                    tools.changeValue(state, 'shouldDelete', () => (shouldDelete ? [true] : []))
                },
                changeSocialNetwork: (args, state, tools) => {
                    const socialNetwork = args[0] as SocialNetwork
                    const isActive = args[1] as boolean
                    const {
                        formState: { values },
                    } = state
                    const { photos } = values as AddFormState
                    if (!isActive) {
                        const updatedPhotos = photos?.map((photo) => ({
                            ...photo,
                            ['is' + socialNetwork + 'Shared']: false,
                        }))
                        tools.changeValue(state, 'photos', () => updatedPhotos)
                    } else {
                        const updatedPhotos = photos?.map((photo, index) => {
                            let value = true
                            if (socialNetwork === SocialNetwork.Twitter && index > 3) {
                                value = false
                            }
                            return {
                                ...photo,
                                ['is' + socialNetwork + 'Shared']: value,
                            }
                        })
                        tools.changeValue(state, 'photos', () => updatedPhotos)
                    }
                },
            }}
            // debug={console.log}
            validate={validate}
            onSubmit={onSubmit}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting, errors, form: { mutators } }) => {
                const submit = (event: any) => {
                    handleSubmit(event)
                    if (errors && !_.isEmpty(errors)) {
                        const errorEntries = Object.entries(errors)
                        if (errorEntries.length === 1) {
                            for (const [, value] of errorEntries) {
                                enqueueSnackbar(value, {
                                    variant: 'error',
                                })
                            }
                        } else {
                            enqueueSnackbar(t('form-validation.common'), {
                                variant: 'error',
                            })
                        }
                    }
                }
                return (
                    <form onSubmit={submit} noValidate className={formClassName}>
                        {children(
                            <Grid container spacing={isMobileScreen ? 2 : 4}>
                                <Grid item xs={12}>
                                    {/* PHOTO */}
                                    <PhotoContainer
                                        canPublishMultiplePhoto={canPublishMultiplePhoto}
                                        showTwitter={showTwitter}
                                        showInstagram={showInstagram}
                                        mutators={mutators}
                                        xl={2}
                                        xs={6}
                                        lg={3}
                                        sm={4}
                                    />
                                </Grid>
                                <Grid item sm={5} xs={12}>
                                    {/* VALIDITY */}
                                    <Typography
                                        color="textSecondary"
                                        className={clsx(formsClasses.formTitle)}
                                    >
                                        <Trans i18nKey="form.slateValidity" />
                                    </Typography>
                                    <ValidityField name="validityDate" />

                                    {/* DELETE */}
                                    <NoAccess>
                                        {(onClick) => (
                                            <div onClick={onClick}>
                                                <CheckboxGroupField
                                                    name={'shouldDelete'}
                                                    values={defaultValue}
                                                    type={'checkbox'}
                                                    showCheckbox
                                                    xs={12}
                                                    disabled={!canAutomaticallyDelete}
                                                />
                                                <div className={formsClasses.deleteConfiguration}>
                                                    <SubmitButton
                                                        onClick={() => setOpenDeleteFlag(true)}
                                                        disabled={!canAutomaticallyDelete}
                                                    >
                                                        <Trans i18nKey="actions.configuration" />
                                                    </SubmitButton>
                                                    <FormSpy subscription={{ values: true }}>
                                                        {(props) => (
                                                            <DeleteFlagDialog
                                                                open={openDeleteFlag}
                                                                onClose={() => setOpenDeleteFlag(false)}
                                                                initialValues={deleteFlagsInitialValues(
                                                                    props.values.shouldDelete[0] ?? false,
                                                                )}
                                                                inProgress={updateEstablishmentInProgress}
                                                                onSubmit={(values) => {
                                                                    onUpdateEstablishment(values).then(
                                                                        ({ shouldClose, shouldDelete }) => {
                                                                            setOpenDeleteFlag(!shouldClose)
                                                                            if (shouldClose) {
                                                                                mutators.setShouldDelete(
                                                                                    shouldDelete,
                                                                                )
                                                                            }
                                                                        },
                                                                    )
                                                                }}
                                                            />
                                                        )}
                                                    </FormSpy>
                                                </div>
                                            </div>
                                        )}
                                    </NoAccess>

                                    {/*  COMMENT  */}
                                    <Typography color="textSecondary" className={formsClasses.formTitle}>
                                        <Trans i18nKey="form.slateComment" />
                                    </Typography>
                                    <TextField
                                        name="comment"
                                        multiline={true}
                                        maxRows={9}
                                        variant="outlined"
                                    />

                                    {/*  SHARING  */}
                                    <Typography color="textSecondary" className={formsClasses.formTitle}>
                                        <Trans i18nKey="form.slateShare" />
                                    </Typography>
                                    <List>
                                        <Field name="isFacebookShared">
                                            {({ input: { value, onChange } }) => (
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        !isFacebookLinked
                                                            ? (t('form.noFacebook') as string)
                                                            : isFacebookSignedExpired
                                                            ? (t('form.facebookExpired') as string)
                                                            : ''
                                                    }
                                                    placement="top-start"
                                                    classes={{
                                                        popper: formsClasses.shareMessageTop,
                                                    }}
                                                >
                                                    <div>
                                                        <ListItem
                                                            divider
                                                            button
                                                            onClick={() => {
                                                                const newValue = !value
                                                                onChange(newValue)
                                                                mutators.changeSocialNetwork(
                                                                    SocialNetwork.Facebook,
                                                                    newValue,
                                                                )
                                                            }}
                                                            disabled={
                                                                !isFacebookLinked || isFacebookSignedExpired
                                                            }
                                                        >
                                                            <ListItemIcon>
                                                                <Switch
                                                                    color="primary"
                                                                    edge="start"
                                                                    checked={value}
                                                                    disableRipple
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemIcon
                                                                classes={{
                                                                    root: formsClasses.shareIcon,
                                                                }}
                                                            >
                                                                <FacebookIcon
                                                                    className={formsClasses.facebookIcon}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={t('actions.shareFacebook')}
                                                            />
                                                        </ListItem>
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </Field>
                                        {showInstagram && (
                                            <Field name="isInstagramShared">
                                                {({ input: { value, onChange } }) => (
                                                    <Tooltip
                                                        arrow
                                                        title={
                                                            !isFacebookLinked
                                                                ? (t('form.noFacebook') as string)
                                                                : !isInstagramLinked
                                                                ? (t('form.noInstagram') as string)
                                                                : isFacebookSignedExpired
                                                                ? (t('form.facebookExpired') as string)
                                                                : ''
                                                        }
                                                        placement="top-start"
                                                        classes={{
                                                            popper: formsClasses.shareMessageTop,
                                                        }}
                                                    >
                                                        <div>
                                                            <ListItem
                                                                divider
                                                                button
                                                                onClick={() => {
                                                                    const newValue = !value
                                                                    onChange(newValue)
                                                                    mutators.changeSocialNetwork(
                                                                        SocialNetwork.Instagram,
                                                                        newValue,
                                                                    )
                                                                }}
                                                                disabled={
                                                                    !isInstagramLinked ||
                                                                    isFacebookSignedExpired
                                                                }
                                                            >
                                                                <ListItemIcon>
                                                                    <Switch
                                                                        color="primary"
                                                                        edge="start"
                                                                        checked={value}
                                                                        disableRipple
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemIcon
                                                                    classes={{
                                                                        root: formsClasses.shareIcon,
                                                                    }}
                                                                >
                                                                    <InstagramIcon
                                                                        className={formsClasses.instagramIcon}
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={t('actions.shareInstagram')}
                                                                />
                                                            </ListItem>
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            </Field>
                                        )}

                                        <Field name="isGoogleShared">
                                            {({ input: { value, onChange } }) => (
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        !isGoogleLinked
                                                            ? (t('form.noGoogle') as string)
                                                            : isGoogleSignedExpired
                                                            ? (t('form.googleExpired') as string)
                                                            : ''
                                                    }
                                                    placement="bottom-start"
                                                    classes={{
                                                        popper: formsClasses.shareMessageBottom,
                                                    }}
                                                >
                                                    <div>
                                                        <ListItem
                                                            divider
                                                            button
                                                            onClick={() => {
                                                                const newValue = !value
                                                                onChange(newValue)
                                                                mutators.changeSocialNetwork(
                                                                    SocialNetwork.Google,
                                                                    newValue,
                                                                )
                                                            }}
                                                            disabled={
                                                                !isGoogleLinked || isGoogleSignedExpired
                                                            }
                                                        >
                                                            <ListItemIcon>
                                                                <Switch
                                                                    color="primary"
                                                                    edge="start"
                                                                    checked={value}
                                                                    disableRipple
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemIcon
                                                                classes={{
                                                                    root: formsClasses.shareIcon,
                                                                }}
                                                            >
                                                                <GoogleIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={t('actions.shareGoogle')}
                                                            />
                                                        </ListItem>
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </Field>

                                        {showTwitter && (
                                            <Field name="isTwitterShared">
                                                {({ input: { value, onChange } }) => (
                                                    <Tooltip
                                                        arrow
                                                        title={
                                                            !isTwitterLinked
                                                                ? (t('form.noTwitter') as string)
                                                                : isTwitterSignedExpired
                                                                ? (t('form.twitterExpired') as string)
                                                                : ''
                                                        }
                                                        placement="top-start"
                                                        classes={{
                                                            popper: formsClasses.shareMessageTop,
                                                        }}
                                                    >
                                                        <div>
                                                            <ListItem
                                                                divider
                                                                button
                                                                onClick={() => {
                                                                    const newValue = !value
                                                                    onChange(newValue)
                                                                    mutators.changeSocialNetwork(
                                                                        SocialNetwork.Twitter,
                                                                        newValue,
                                                                    )
                                                                }}
                                                                disabled={
                                                                    !isTwitterLinked || isTwitterSignedExpired
                                                                }
                                                            >
                                                                <ListItemIcon>
                                                                    <Switch
                                                                        color="primary"
                                                                        edge="start"
                                                                        checked={value}
                                                                        disableRipple
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemIcon
                                                                    classes={{
                                                                        root: formsClasses.shareIcon,
                                                                    }}
                                                                >
                                                                    <TwitterIcon
                                                                        className={formsClasses.twitterIcon}
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={t('actions.shareTwitter')}
                                                                />
                                                            </ListItem>
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            </Field>
                                        )}
                                        {extraSharing}
                                    </List>
                                </Grid>
                                <Grid item sm={7} xs={12}>
                                    {/* CATEGORIES */}
                                    <Typography
                                        color="textSecondary"
                                        className={clsx(formsClasses.formTitle)}
                                    >
                                        <Trans i18nKey="form.slateCategories" />
                                    </Typography>
                                    <CategoriesField
                                        name="categories"
                                        categories={categories}
                                        xs={6}
                                        md={6}
                                        lg={4}
                                    />
                                </Grid>
                            </Grid>,
                            [
                                {
                                    type: 'submit',
                                    loading: submitting || inProgress,
                                    className: formsClasses.submit,
                                    children: <Trans i18nKey="actions.validate" />,
                                },
                            ],
                        )}
                    </form>
                )
            }}
        </Form>
    )
}

export default AddForm
