import React from 'react'
import MainLayout from './mainLayout'
import { Card, Container } from '@material-ui/core'
import { useStyles } from './homeLayout.css'
import Logo from '../common/logo'

interface Props {
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
    externalContent?: React.ReactNode
}

const HomeLayout: React.FC<Props> = ({ children, externalContent = null, maxWidth = 'sm' }) => {
    const classes = useStyles()
    return (
        <MainLayout withLogo={false}>
            <Container maxWidth={maxWidth} className={classes.container}>
                <Card className={classes.card} elevation={0}>
                    <div className={classes.logoContainer}>
                        <Logo className={classes.logo} />
                    </div>
                    {children}
                </Card>
                {externalContent}
            </Container>
        </MainLayout>
    )
}

export default HomeLayout
