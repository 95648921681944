import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontWeight: theme.fontsWeight.semiBold,
        },
        stepper: {
            'padding': theme.spacing(1, 0),
            '& .MuiLinearProgress-root': {
                height: 8,
                borderRadius: 8,
                backgroundColor: '#F0F0F0',
            },
            '& .MuiLinearProgress-bar': {
                borderRadius: 8,
            },
            '& .MuiMobileStepper-progress': {
                width: '100%',
            },
        },
    }),
)
