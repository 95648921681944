import React from 'react'
import { PostDetails } from '@sugg-gestion/ubidreams-react-suggpro'
import { useStyles } from '../styles.css'
import i18next from 'i18next'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ShareIcon from '@material-ui/icons/Share'

interface Props {
    postDetails: PostDetails
}

const PostMetricsBar: React.FC<Props> = ({ postDetails }) => {
    const classes = useStyles()

    return (
        <div className={classes.discussPostFoot}>
            {/* Like count */}
            {postDetails.like !== null && (
                <div
                    className={classes.discussPostMetrics}
                    title={i18next.t('views.messaging.postMetrics.likeCount', {
                        value: postDetails.like,
                    })}
                >
                    <ThumbUpIcon color={'primary'} />
                    <span className={classes.discussPostMetricsCount}>{postDetails.like}</span>
                </div>
            )}
            {/* Share count */}
            {postDetails.share !== null && (
                <div
                    className={classes.discussPostMetrics}
                    title={i18next.t('views.messaging.postMetrics.shareCount', {
                        value: postDetails.share,
                    })}
                >
                    <ShareIcon color={'primary'} />
                    <span className={classes.discussPostMetricsCount}>{postDetails.share}</span>
                </div>
            )}
        </div>
    )
}

export default PostMetricsBar
