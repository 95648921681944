import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cguLink: {
            padding: theme.spacing(1, 2),
            paddingBottom: theme.spacing(2),
            textAlign: 'center',
            fontSize: '0.8rem',
            [theme.breakpoints.down('xs')]: {
                width: 'auto',
            },
        },
        title: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(4),
        },
        action: {
            '&.MuiButtonBase-root': {
                marginLeft: 'auto',
            },
        },
        cardContent: {
            flexGrow: 1,
            [theme.breakpoints.down('xs')]: {
                'width': '100%',
                '@media (orientation: portrait)': {
                    paddingBottom: 51,
                },
            },
        },
        actionsContainer: {
            [theme.breakpoints.down('xs')]: {
                'backgroundColor': theme.palette.background.default,
                '@media (orientation: portrait)': {
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                },
            },
        },
        actions: {
            justifyContent: 'space-between',
            [theme.breakpoints.down('xs')]: {
                'padding': theme.spacing(1, 2),
                '@media (orientation: portrait)': {
                    padding: theme.spacing(2, 4),
                },
            },
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        forgottenPassword: {
            'display': 'flex',
            'justifyContent': 'flex-end',
            '& .MuiButton-root': {
                textTransform: 'none',
                fontSize: '1rem',
                textDecoration: 'underline',
                [theme.breakpoints.down('xs')]: {
                    fontSize: '0.8rem',
                },
            },
        },
        facebookGoogle: {
            fontSize: '0.9rem',
            [theme.breakpoints.down('xs')]: {
                fontSize: '0.8rem',
            },
        },
        facebookGoogleCard: {
            '&.MuiPaper-root': {
                'marginTop': theme.spacing(2),
                'backgroundColor': theme.palette.warning.light,
                'color': theme.palette.warning.contrastText,
                '& .MuiLink-root': {
                    color: theme.palette.warning.contrastText,
                    textDecoration: 'underline',
                },
            },
        },
    }),
)
