import { Hidden } from '@material-ui/core'
import { DataGrid, DataGridProps, GridRowParams } from '@material-ui/data-grid'
import React from 'react'
import DataList from './dataList'
import { useStyles } from './styles.css'

export interface ResponsibleDataGridProps extends DataGridProps {
    primaryKey: string
    secondaryKey?: string
    getPrimaryHeaderClassName?: (params: GridRowParams) => string | undefined
    getSecondaryHeaderClassName?: (params: GridRowParams) => string | undefined
}

const ResponsibleDataGrid: React.FC<ResponsibleDataGridProps> = ({ ...gridProps }) => {
    const classes = useStyles()
    return (
        <>
            <Hidden only={['xs', 'sm']}>
                <DataGrid {...gridProps} className={classes.grid} />
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
                <DataList {...gridProps} />
            </Hidden>
        </>
    )
}

export default ResponsibleDataGrid
