import React from 'react'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'

import {
    Establishment,
    EstablishmentPicture,
    useEstablishmentPicturesCreate,
    useEstablishmentPicturesDelete,
    useEstablishmentPicturesUpdate,
    useEstablishmentUpdate,
} from '@sugg-gestion/ubidreams-react-suggpro'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { PhotosFormState } from 'components/forms/establishment/photosForm'
import _ from 'lodash'
import EstablishmentPhotosDialogView from 'components/views/establishment/dialogs/establishmentPhotosDialogView'
import withDnD from '../../hoc/withDnD'
import { SortedImage } from '../../../components/forms/helpers/useSortedImages'

interface Props {
    open: boolean
    onClose: () => void
    establishment: Establishment
}

const EstablishmentEditPhotosDialog: React.FC<Props> = ({ establishment, open, onClose }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const { displayError } = useApiErrors()
    const { updateEstablishment, inProgress } = useEstablishmentUpdate()
    const { createEstablishmentPictures, inProgress: createEstablishmentPicturesInProgress } =
        useEstablishmentPicturesCreate()
    const { updateEstablishmentPictures, inProgress: updateEstablishmentPicturesInProgress } =
        useEstablishmentPicturesUpdate()
    const { deleteEstablishmentPicture, inProgress: deleteEstablishmentPictureInProgress } =
        useEstablishmentPicturesDelete()

    const sortImagesEstablishmentPicture = (establishmentPicture: Array<EstablishmentPicture>) => {
        const sortedImagesEstablishmentPicture: Array<SortedImage> = []
        establishmentPicture.forEach((establishmentPicture) => {
            sortedImagesEstablishmentPicture.push({
                id: establishmentPicture.id,
                url: establishmentPicture.url,
                sortOrder: establishmentPicture.sortOrder,
                isMainPicture: establishmentPicture.isMainPicture,
            })
        })
        return sortedImagesEstablishmentPicture
    }

    const updateEstablishmentPicturesAction = (
        sortOrders: Array<number>,
        mainPicture: number | undefined,
    ) => {
        return updateEstablishmentPictures({
            id: establishment.id,
            sortOrders,
            mainPicture,
        }).catch((error) => {
            displayError(error)
        })
    }

    const reorganizeEstablishmentPictureAction = (
        addedEstablishmentPicture: Array<EstablishmentPicture>,
        sortedImages: Array<SortedImage>,
    ) => {
        addedEstablishmentPicture = _.sortBy<EstablishmentPicture>(addedEstablishmentPicture, 'id')
        let newSortedImages: Array<SortedImage> = []
        let index = 0
        let mainPicture: number | undefined = undefined
        sortedImages.forEach((sortedImage) => {
            if (sortedImage.file) {
                const addedEstablishmentPictures = addedEstablishmentPicture[index]
                newSortedImages.push({
                    sortOrder: sortedImage.sortOrder,
                    id: addedEstablishmentPictures.id,
                    url: addedEstablishmentPictures.url,
                })
                if (sortedImage.isMainPicture === true) {
                    mainPicture = addedEstablishmentPictures.id
                }
                index++
            } else {
                newSortedImages.push(sortedImage)
                if (sortedImage.isMainPicture === true) {
                    mainPicture = sortedImage.id
                }
            }
        })
        newSortedImages = _.sortBy<SortedImage>(newSortedImages, 'sortOrder')
        const sortOrders = newSortedImages.map((sortedImage) => sortedImage.id)
        return updateEstablishmentPicturesAction(sortOrders, mainPicture)
    }

    const uploadPhotos = (establishment: Establishment, values: PhotosFormState) => {
        return new Promise<void>((resolve, reject) => {
            const photos: Array<File> = []
            values.photos?.forEach((photo) => {
                if (photo.file) {
                    photos.push(photo.file)
                }
            })
            if (photos.length > 0) {
                createEstablishmentPictures({
                    id: establishment.id,
                    files: photos,
                })
                    .then(() => {
                        resolve()
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } else {
                resolve()
            }
        })
    }

    const deletePhotos = (establishment: Establishment, photosToDelete: Array<EstablishmentPicture>) => {
        return new Promise<void>((resolve) => {
            const deletePhotosPromises = []
            for (let i = 0; i < photosToDelete.length; i++) {
                const photoToDelete = photosToDelete[i]
                deletePhotosPromises.push(
                    deleteEstablishmentPicture({
                        id: establishment.id,
                        pictureId: photoToDelete.id,
                    }),
                )
            }
            Promise.all(deletePhotosPromises)
                .then(() => {
                    resolve()
                })
                .catch((error) => {
                    displayError(error)
                    resolve()
                })
        })
    }

    const handleUpdatePhotos = (values: PhotosFormState) => {
        const establishmentPhotos = _.get(establishment, 'pictures', [])
        const sortedImages = values.photos.map((image) => ({
            ...image,
            isMainPicture: parseInt(values.flag) === image.id,
        }))
        const photosToDelete = _.differenceWith(
            establishmentPhotos,
            values.photos ?? [],
            (establishmentPhoto, dataPhoto) => {
                return establishmentPhoto.url === dataPhoto.url
            },
        )
        Promise.all([uploadPhotos(establishment, values), deletePhotos(establishment, photosToDelete)])
            .then(() => {
                const files: Array<File> = []
                sortedImages.forEach((sortedImage) => {
                    if (sortedImage.file) {
                        files.push(sortedImage.file)
                    }
                })
                if (files.length > 0) {
                    return updateEstablishment({
                        id: establishment.id,
                        establishment: {
                            ...establishment,
                            defaultMenuBoardCategories: undefined,
                        },
                    })
                        .then((response) => {
                            let addedEstablishmentPictures = _.differenceBy<
                                EstablishmentPicture,
                                EstablishmentPicture
                            >(response.pictures, establishmentPhotos, 'id')
                            return reorganizeEstablishmentPictureAction(
                                addedEstablishmentPictures,
                                sortedImages,
                            )
                        })
                        .catch((error) => {
                            console.error(error)
                            displayError(error)
                        })
                } else {
                    return reorganizeEstablishmentPictureAction([], sortedImages)
                }
            })
            .then(() => {
                enqueueSnackbar(t('views.establishment.photos.success'), {
                    variant: 'success',
                })
                onClose()
            })
    }

    return (
        <EstablishmentPhotosDialogView
            open={open}
            onClose={onClose}
            onSubmit={handleUpdatePhotos}
            inProgress={
                createEstablishmentPicturesInProgress ||
                updateEstablishmentPicturesInProgress ||
                deleteEstablishmentPictureInProgress ||
                inProgress
            }
            initialValues={{
                photos: sortImagesEstablishmentPicture(establishment.pictures),
            }}
        />
    )
}

export default withDnD(EstablishmentEditPhotosDialog)
