import { APIError, useNewsCreate, useNewsShare } from '@sugg-gestion/ubidreams-react-suggpro'
import { ShareNewsRequest } from '@sugg-gestion/ubidreams-react-suggpro/dist/resources/news/types'
import { AddNewsFormState } from 'components/forms/news/addNewsForm'
import AddNewsView from 'components/views/news/addNewsView'
import withAuthenticateLayout, { WithAuthenticateLayout } from 'containers/hoc/withAuthenticateLayout'
import withMuiPickersUtilsProvider from 'containers/hoc/withMuiPickersUtilsProvider'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import withDnD from '../hoc/withDnD'
import { SocialNetwork } from '../slate'

interface Props extends WithAuthenticateLayout {}

const AddNews: React.FC<Props> = ({ establishment, ability, restaurantOwner }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const { displayError } = useApiErrors()
    const history = useHistory()

    const establishmentBasePath = '/establishment/:id'.replace(':id', establishment.id.toString() ?? '')
    const handleGoBack = () => {
        history.push(establishmentBasePath + '/news')
    }

    const [openLoadingDialog, setOpenLoadingDialog] = useState<'no' | 'open' | 'force'>('no')
    const [loadingNetworks, setLoadingNetworks] = useState<Array<SocialNetwork>>()
    const [addError, setAddError] = useState<APIError>()
    const [facebookError, setFacebookError] = useState<APIError>()
    const [googleError, setGoogleError] = useState<APIError>()
    const [instagramError, setInstagramError] = useState<APIError>()
    const [twitterError, setTwitterError] = useState<APIError>()

    const handleCloseLoadingDialog = () => {
        setOpenLoadingDialog('no')
        if (addError === undefined) {
            handleGoBack()
        }
    }

    const handleFacebookError = (error: APIError) => {
        setOpenLoadingDialog('force')
        setFacebookError(error)
        displayError(error, undefined, false)
    }
    const handleGoogleError = (error: APIError) => {
        setOpenLoadingDialog('force')
        setGoogleError(error)
        displayError(error, undefined, false)
    }
    const handleInstagramError = (error: APIError) => {
        setOpenLoadingDialog('force')
        setInstagramError(error)
        displayError(error, undefined, false)
    }
    const handleTwitterError = (error: APIError) => {
        setOpenLoadingDialog('force')
        setTwitterError(error)
        displayError(error, undefined, false)
    }

    const { createNews, inProgress } = useNewsCreate()

    const {
        shareNews,
        inProgress: shareInProgress,
        facebookInProgress: shareOnFacebookInProgress,
        googleInProgress: shareOnGoogleInProgress,
        instagramInProgress: shareOnInstagramInProgress,
        twitterInProgress: shareOnTwitterInProgress,
    } = useNewsShare(handleFacebookError, handleGoogleError, handleInstagramError, handleTwitterError)

    const handleCreateEvent = (values: AddNewsFormState) => {
        const socialNetworks = []
        if (values.isFacebookShared) {
            socialNetworks.push(SocialNetwork.Facebook)
        }
        if (values.isInstagramShared) {
            const sharedPhotos = values.photos?.filter((photoData) => photoData.isInstagramShared) ?? []
            if (sharedPhotos.length > 0) {
                socialNetworks.push(SocialNetwork.Instagram)
            } else {
                values.isInstagramShared = false
            }
        }
        if (values.isTwitterShared) {
            socialNetworks.push(SocialNetwork.Twitter)
        }
        if (values.isGoogleShared) {
            socialNetworks.push(SocialNetwork.Google)
        }

        setLoadingNetworks(socialNetworks)
        setOpenLoadingDialog('open')

        // Create News
        createNews({
            ...values,
            establishment: establishment.id,
            pictures: values.photos,
        })
            .then((news) => {
                if (news) {
                    enqueueSnackbar(t('views.news.addNews.success'), {
                        variant: 'success',
                    })
                    let shouldShare = false
                    let parameters: ShareNewsRequest = {
                        id: news.id,
                        isFacebookShared: values.isFacebookShared,
                        isInstagramShared: values.isInstagramShared,
                        isTwitterShared: values.isTwitterShared,
                        isGoogleShared: values.isGoogleShared,
                    }
                    if (
                        values.isFacebookShared ||
                        values.isInstagramShared ||
                        values.isTwitterShared ||
                        values.isGoogleShared
                    ) {
                        shouldShare = true
                    }

                    if (shouldShare) {
                        return shareNews(parameters)
                            .then(() => {
                                if (openLoadingDialog !== 'force') {
                                    handleGoBack()
                                }
                            })
                            .catch(() => {})
                    } else {
                        handleCloseLoadingDialog()
                    }
                }
            })
            .catch((error) => {
                setOpenLoadingDialog('force')
                setAddError(error)
                displayError(error, undefined, false)
            })
    }

    const endTime = new Date()
    endTime.setHours(22)
    endTime.setMinutes(0)
    endTime.setSeconds(0)
    return (
        <AddNewsView
            establishment={establishment}
            onSubmit={handleCreateEvent}
            onBack={handleGoBack}
            closeLoadingDialog={handleCloseLoadingDialog}
            inProgress={
                inProgress ||
                shareInProgress ||
                shareOnFacebookInProgress ||
                shareOnInstagramInProgress ||
                shareOnTwitterInProgress ||
                shareOnGoogleInProgress
            }
            createInProgress={inProgress}
            loadingNetworks={loadingNetworks}
            shareOnFacebookInProgress={shareOnFacebookInProgress}
            shareOnInstagramInProgress={shareOnInstagramInProgress}
            shareOnTwitterInProgress={shareOnTwitterInProgress}
            shareOnGoogleInProgress={shareOnGoogleInProgress}
            initialValues={{
                endTime: endTime,
                isFacebookShared:
                    !_.isEmpty(establishment.facebookPageId) && !restaurantOwner.isFacebookSignedExpired,
                isInstagramShared:
                    !_.isEmpty(establishment.instagramPageId) && !restaurantOwner.isFacebookSignedExpired,
                isGoogleShared: !_.isEmpty(establishment.googleId) && !restaurantOwner.isGoogleSignedExpired,
                isTwitterShared:
                    false && !_.isEmpty(establishment.twitterID) && !establishment.isTwitterSignedExpired,
            }}
            isFacebookLinked={!_.isEmpty(establishment.facebookPageId)}
            isFacebookSignedExpired={restaurantOwner.isFacebookSignedExpired}
            isGoogleLinked={!_.isEmpty(establishment.googleId)}
            isGoogleSignedExpired={restaurantOwner.isGoogleSignedExpired}
            isInstagramLinked={!_.isEmpty(establishment.instagramPageId)}
            isTwitterLinked={!_.isEmpty(establishment.twitterID)}
            isTwitterSignedExpired={establishment.isTwitterSignedExpired ?? false}
            ability={ability}
            addError={addError}
            facebookError={facebookError}
            googleError={googleError}
            instagramError={instagramError}
            twitterError={twitterError}
        />
    )
}

export default withMuiPickersUtilsProvider(withDnD(withAuthenticateLayout(AddNews)))
