import React, { useState } from 'react'
import AuthenticateContent from 'components/layouts/components/authenticateContent'
import { useStyles } from './styles.css'
import { Button, IconButton, Typography } from '@material-ui/core'
import { Trans, useTranslation } from 'react-i18next'
import { Establishment, MenuEntry } from '@sugg-gestion/ubidreams-react-suggpro'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperClass from 'swiper/types/swiper-class'
import AuthenticateActions from 'components/layouts/components/authenticateActions'
import { Link } from 'react-router-dom'
import { useImgix } from 'core/services/imgix/useImgix'
import clsx from 'clsx'
import RightArrowIcon from 'components/icons/rightArrowIcon'
import LeftArrowIcon from 'components/icons/leftArrowIcon'

interface Props {
    menuEntries: Array<MenuEntry>
    establishment: Establishment
}

const MenuView: React.FC<Props> = ({ establishment, menuEntries }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const establishmentBasePath = '/establishment/:id'.replace(':id', establishment.id.toString() ?? '')

    const { getImage } = useImgix()
    const [swiper, setSwiper] = useState<SwiperClass>()
    const [activeIndex, setActiveIndex] = useState<number>(0)

    const next = () => {
        if (swiper) {
            swiper.slideNext()
        }
    }

    const previous = () => {
        if (swiper) {
            swiper.slidePrev()
        }
    }

    return (
        <>
            <AuthenticateContent>
                <Typography variant="h1" color="textSecondary" className={classes.title}>
                    <Trans i18nKey="views.menu.title" />
                </Typography>
                <Typography variant="h2" color="textSecondary" className={classes.subtitle}>
                    <Trans i18nKey="views.menu.subtitle" />
                </Typography>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    onSlideChange={(swiper) => {
                        const activeIndex: number = swiper.activeIndex
                        setActiveIndex(activeIndex)
                    }}
                    onSwiper={(swiper) => setSwiper(swiper)}
                    zoom
                    keyboard
                    className={classes.swiper}
                >
                    <div className={clsx(classes.swiperNavigation, classes.swiperNavigationLeft)}>
                        <IconButton onClick={previous} color="primary" disabled={activeIndex === 0}>
                            <LeftArrowIcon className={classes.chevron} />
                        </IconButton>
                    </div>
                    {menuEntries.map((menu, index) => (
                        <SwiperSlide zoom className={classes.slide} key={index}>
                            <img
                                src={getImage(menu.url, {
                                    h: window.innerHeight - 280 > 200 ? window.innerHeight - 280 : 200,
                                })}
                                alt={t('menu.menu-board')}
                            />
                        </SwiperSlide>
                    ))}
                    <div className={clsx(classes.swiperNavigation, classes.swiperNavigationRight)}>
                        <IconButton
                            onClick={next}
                            color="primary"
                            disabled={activeIndex === menuEntries.length - 1}
                        >
                            <RightArrowIcon className={classes.chevron} />
                        </IconButton>
                    </div>
                </Swiper>
            </AuthenticateContent>
            <AuthenticateActions className={classes.actions}>
                <Button
                    variant="contained"
                    color="primary"
                    to={establishmentBasePath + '/menu/edit'}
                    component={Link}
                    className={classes.submit}
                >
                    <Trans i18nKey="actions.edit" />
                </Button>
            </AuthenticateActions>
        </>
    )
}

export default MenuView
