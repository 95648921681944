import { FormControl, Grid, Link, Typography } from '@material-ui/core'
import HiddenJs from '@material-ui/core/Hidden/HiddenJs'
import { EstablishmentType } from '@sugg-gestion/ubidreams-react-suggpro'
import { validateAWSEmail } from 'appsync-scalar-utils'
import remplisVert from 'assets/logo/remplisVert.png'
import { SubmitButtonProps } from 'components/common/submitButton'
import { inputLength, isDunsValid } from 'core/services/vat/checkVat'
import parsePhoneNumberFromString from 'libphonenumber-js'
import { CountryCode } from 'libphonenumber-js/types'
import React from 'react'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import CheckboxField from '../components/checkboxField'
import EmailField from '../components/emailField'
import PhoneField from '../components/phoneField'
import RadioGroupField, { RadioValue } from '../components/radioGroupField'
import TextField from '../components/textField'
import TextFieldLength from '../components/textFieldLength'
import { useFormsStyles } from '../forms.css'
import { characterTextLength, utf8TextLength } from '../helpers/textLength'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: DetailsFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: DetailsFormState
    establishmentTypes?: Array<EstablishmentType>
    typeMandatory: boolean
    type: 'duns' | 'europeanVat'
    country: CountryCode
}

export interface DetailsFormState {
    name: string
    duns: string
    description: string
    shortDescription: string
    email?: string
    phone?: string
    type: string
    remplisVert: boolean
    remplisVertComment?: string
}

export interface DetailsFormStateInternal {
    name: string
    duns: string
    description: string
    shortDescription: string
    email?: string
    phone?: string
    type: string
    remplisVert: Array<boolean>
    remplisVertComment?: string
}

interface DetailsFormError {
    name?: string
    duns?: string
    description?: string
    shortDescription?: string
    email?: string
    phone?: string
    type?: string
}

const DetailsForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
    establishmentTypes,
    typeMandatory,
    type,
    country,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()

    const initialValuesInternal: DetailsFormStateInternal = {
        ...initialValues,
        remplisVert: initialValues.remplisVert ? [true] : [],
    }

    const establishmentTypesValues: Array<RadioValue> = []
    establishmentTypes?.forEach((type) => {
        establishmentTypesValues.push({
            label: type.name,
            value: type.id.toString(),
        })
    })

    const validate = (values: DetailsFormStateInternal) => {
        const errors: DetailsFormError = {}

        if (!values.name) {
            errors.name = t('form-validation.establishmentName')
        }

        if (!values.description) {
            errors.description = t('form-validation.description')
        }

        if (values.shortDescription && utf8TextLength(values.shortDescription) > 100) {
            errors.shortDescription = t('form-validation.shortDescriptionLength')
        }

        if (values.email && !validateAWSEmail(values.email)) {
            errors.email = t('form-validation.emailValid')
        }

        if (values.phone !== undefined && values.phone !== '') {
            const phone = parsePhoneNumberFromString(values.phone, 'FR')
            if (phone === undefined || !phone.isValid()) {
                errors.phone = t('form-validation.phone')
            }
        }

        if (typeMandatory && !values.type) {
            errors.type = t('form-validation.establishmentType')
        }
        if (!isDunsValid(country, values.duns)) {
            errors.duns = t('form-validation.' + type)
        }
        return errors
    }

    const handleSubmit = (values: DetailsFormStateInternal) => {
        return onSubmit({
            ...values,
            remplisVert: values.remplisVert && values.remplisVert.length > 0,
        })
    }

    return (
        <Form
            initialValues={initialValuesInternal}
            subscription={subscription}
            // debug={console.log}
            onSubmit={handleSubmit}
            validate={validate}
            keepDirtyOnReinitialize
            mutators={{
                changeRemplisVertComment: (args, state, tools) => {
                    const newRemplisVertComment = args[0] as string | undefined
                    if (state.lastFormState && newRemplisVertComment) {
                        const { remplisVertComment } = state.lastFormState.values
                        if (
                            remplisVertComment === undefined ||
                            newRemplisVertComment?.length > remplisVertComment?.length
                        ) {
                            tools.changeValue(state, 'remplisVert', () => [true])
                        }
                    }
                },
            }}
        >
            {({ handleSubmit, submitting, form: { mutators } }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <Grid container spacing={8}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label={t('form.establishmentName')}
                                    name="name"
                                    autoComplete="organization"
                                    required
                                    helperText={t('form.establishmentNameHelper')}
                                    className={formsClasses.important}
                                />
                                <TextField
                                    name="duns"
                                    label={t('form.' + type)}
                                    type={type === 'duns' ? 'number' : 'text'}
                                    InputProps={{
                                        inputProps: {
                                            maxLength: inputLength(country),
                                        },
                                    }}
                                    required
                                />
                                <EmailField label={t('form.establishmentEmail')} name="email" />
                                <PhoneField label={t('form.establishmentPhone')} name="phone" />
                                <TextFieldLength
                                    label={t('form.description')}
                                    name="description"
                                    required
                                    multiline
                                    maxRows={4}
                                    InputProps={{
                                        inputProps: {
                                            maxLength: 750,
                                        },
                                    }}
                                    calculateFunction={characterTextLength}
                                    maxLength={750}
                                    helperText={t('form.descriptionHelper')}
                                />
                                <TextFieldLength
                                    label={t('form.shortDescription')}
                                    name="shortDescription"
                                    multiline
                                    maxRows={2}
                                    InputProps={{
                                        inputProps: {
                                            maxLength: 100,
                                        },
                                    }}
                                    calculateFunction={utf8TextLength}
                                    maxLength={100}
                                    helperText={t('form.shortDescriptionHelper')}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RadioGroupField
                                    name="type"
                                    label={t('form.establishmentType')}
                                    values={establishmentTypesValues}
                                    column={2}
                                    required={typeMandatory}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item sm={8} xs={12} style={{ maxWidth: 700 }}>
                                        <CheckboxField
                                            name="remplisVert"
                                            value={true}
                                            labelPlacement="end"
                                            label={
                                                <Trans
                                                    i18nKey="form.remplisVert"
                                                    components={[
                                                        <span className={formsClasses.remplisVert} />,
                                                        <Link
                                                            className={formsClasses.remplisVertLink}
                                                            href="https://info.onvaauresto.fr/remplisvert/"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        />,
                                                    ]}
                                                />
                                            }
                                        />
                                        <FormControl fullWidth margin="none" style={{ paddingLeft: 31 }}>
                                            <Typography component="label" htmlFor="remplisVertComment">
                                                <Trans i18nKey="form.remplisVertComment" />
                                            </Typography>
                                            <TextField
                                                margin="dense"
                                                variant="outlined"
                                                name="remplisVertComment"
                                                multiline
                                                onChange={(e) => {
                                                    mutators.changeRemplisVertComment(e.currentTarget.value)
                                                }}
                                                minRows={3}
                                                maxRows={6}
                                                inputProps={{
                                                    id: 'remplisVertComment',
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <HiddenJs xsDown>
                                        <Grid
                                            item
                                            sm={4}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                                padding: 32,
                                            }}
                                        >
                                            <img
                                                src={remplisVert}
                                                alt="Remplis Vert"
                                                style={{ width: '100%', maxWidth: 220 }}
                                            />
                                        </Grid>
                                    </HiddenJs>
                                </Grid>
                            </Grid>
                        </Grid>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                className: formsClasses.submit,
                                children: <Trans i18nKey="actions.next" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}
export default DetailsForm
