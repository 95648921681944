import React, { useState } from 'react'
import EstablishmentSiretView from 'components/views/establishment/establishmentSiretView'
import { Address, Establishment, useEstablishmentUpdate } from '@sugg-gestion/ubidreams-react-suggpro'
import { PageType, useApiErrors } from 'core/services/suggPro/useApiErrors'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { DunsFormState } from 'components/forms/establishment/dunsForm'
import _ from 'lodash'
import { redirectToEstablishment } from 'core/store/app/actions'
import { useDispatch } from 'react-redux'
import EstablishmentDunsCompleteView from 'components/views/establishment/establishmentDunsCompleteView'
import { DunsCompleteFormState } from 'components/forms/establishment/dunsCompleteForm'
import withSignUpLayout, { WithSignUpLayout } from 'containers/hoc/withSignUpLayout'
import { useAlertExistingDuns } from '../../core/services/duns/useAlertExistingDuns'
import EstablishmentEuropeanVATView from '../../components/views/establishment/establishmentEuropeanVATView'
import EstablishmentEuropeanVATCompleteView from '../../components/views/establishment/establishmentEuropeanVATCompleteView'

interface Props extends WithSignUpLayout {
    step?: number
    steps?: number
}

const EstablishmentDuns: React.FC<Props> = ({ establishment, restaurantOwner, step = 0, steps = 8 }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const { displayError } = useApiErrors()
    const { updateEstablishment, inProgress } = useEstablishmentUpdate()
    const [duns, setDuns] = useState<string>()

    const { alertExistingDuns } = useAlertExistingDuns()

    const checkEstablishmentsWithDuns = (duns: string, establishment: Establishment) => {
        return new Promise<void>((resolve) => {
            setDuns(duns)
            if (_.isEmpty(establishment.completeAddress.street_1) || _.isEmpty(establishment.name)) {
                enqueueSnackbar(t('views.establishment.duns.failed'))
            } else {
                enqueueSnackbar(t('views.establishment.duns.success'), {
                    variant: 'success',
                })
            }
            resolve()
        })
    }

    const handleRegisterDuns = (values: DunsFormState) => {
        updateEstablishment({
            id: establishment.id,
            establishment: {
                DUNS: values.duns,
            },
            nextStatus: 'PAYMENT',
        })
            .then((establishment) => {
                alertExistingDuns(establishment, values.duns, checkEstablishmentsWithDuns)
            })
            .catch((error) => {
                if (_.get(error, 'status', 0) === 429) {
                    setDuns(values.duns)
                    enqueueSnackbar(t('views.establishment.duns.failed'))
                } else {
                    displayError(error, PageType.DUNS)
                }
            })
    }

    const handleRegisterDunsComplete = (values: DunsCompleteFormState) => {
        if (establishment) {
            const completeAddress: Address = {
                street_1: values.address_street_1 ?? null,
                street_2: values.address_street_2 ?? null,
                zip_code: values.address_zip_code ?? null,
                city: values.address_city ?? null,
                country: values.address_country ?? null,
            }
            updateEstablishment({
                id: establishment.id,
                establishment: {
                    DUNS: values.duns,
                    name: values.name,
                    completeAddress,
                },
            })
                .then(() => {
                    enqueueSnackbar(t('views.establishment.duns.successComplete'), {
                        variant: 'success',
                    })
                    dispatch(redirectToEstablishment())
                })
                .catch((error) => displayError(error))
        }
    }
    if (duns) {
        switch (restaurantOwner.country) {
            case 'FR':
                return (
                    <EstablishmentDunsCompleteView
                        step={step}
                        steps={steps}
                        onSubmit={handleRegisterDunsComplete}
                        inProgress={inProgress}
                        initialValues={{
                            duns: duns,
                            name: establishment?.name ?? '',
                            address_street_1: establishment?.completeAddress.street_1 ?? '',
                            address_street_2: establishment?.completeAddress.street_2 ?? '',
                            address_zip_code: establishment?.completeAddress.zip_code ?? '',
                            address_city: establishment?.completeAddress.city ?? '',
                            address_country: establishment?.completeAddress.country ?? 'FR',
                        }}
                    />
                )
            default:
                return (
                    <EstablishmentEuropeanVATCompleteView
                        step={step}
                        steps={steps}
                        onSubmit={handleRegisterDunsComplete}
                        inProgress={inProgress}
                        initialValues={{
                            duns: duns,
                            name: establishment?.name ?? '',
                            address_street_1: establishment?.completeAddress.street_1 ?? '',
                            address_street_2: establishment?.completeAddress.street_2 ?? '',
                            address_zip_code: establishment?.completeAddress.zip_code ?? '',
                            address_city: establishment?.completeAddress.city ?? '',
                            address_country: establishment?.completeAddress.country ?? 'FR',
                        }}
                    />
                )
        }
    } else {
        switch (restaurantOwner.country) {
            case 'FR':
                return (
                    <EstablishmentSiretView
                        step={step}
                        steps={steps}
                        onSubmit={handleRegisterDuns}
                        inProgress={inProgress}
                        initialValues={{
                            duns: establishment?.DUNS ?? '',
                        }}
                    />
                )
            default:
                return (
                    <EstablishmentEuropeanVATView
                        step={step}
                        steps={steps}
                        onSubmit={handleRegisterDuns}
                        inProgress={inProgress}
                        initialValues={{
                            duns: establishment?.DUNS ?? '',
                        }}
                        country={restaurantOwner.country}
                    />
                )
        }
    }
}

export default withSignUpLayout(EstablishmentDuns)
