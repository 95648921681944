import React from 'react'
import { FormHelperText, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import { Trans } from 'react-i18next'
import WarningIcon from '@material-ui/icons/Warning'
import DoneIcon from '@material-ui/icons/Done'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: 'inherit',
        },
        icons: {
            minWidth: 0,
            paddingRight: theme.spacing(2),
        },
        dense: {
            padding: 2,
        },
    }),
)

interface ItemProps {
    text: React.ReactNode
    valid: boolean
}

const Item: React.FC<ItemProps> = ({ text, valid }) => {
    const classes = useStyles()
    const theme = useTheme()
    return (
        <ListItem disableGutters className={classes.dense}>
            <ListItemIcon className={classes.icons}>
                {valid ? (
                    <DoneIcon htmlColor={theme.palette.success.main} fontSize="small" />
                ) : (
                    <WarningIcon htmlColor={theme.palette.warning.main} fontSize="small" />
                )}
            </ListItemIcon>
            <ListItemText primary={text} classes={{ primary: classes.title }} />
        </ListItem>
    )
}

interface Props {
    value: string | undefined
}

const lowercaseRegex = new RegExp('[a-z]')
const uppercaseRegex = new RegExp('[A-Z]')
const numberRegex = new RegExp('[0-9]')
const lengthRegex = new RegExp('.{8,}')
const specialRegex = new RegExp('\\W')
const PasswordRules: React.FC<Props> = ({ value }) => {
    const classes = useStyles()
    const hasLowercase = () => {
        return lowercaseRegex.test(value ?? '')
    }
    const hasUppercase = () => {
        return uppercaseRegex.test(value ?? '')
    }
    const hasNumber = () => {
        return numberRegex.test(value ?? '')
    }
    const hasRequiredLength = () => {
        return lengthRegex.test(value ?? '')
    }
    const hasSpecial = () => {
        return specialRegex.test(value ?? '')
    }

    return (
        <FormHelperText component="div">
            <Typography className={classes.title}>
                <Trans i18nKey="form.passwordRules.title" />
            </Typography>
            <List dense disablePadding>
                <Item text={<Trans i18nKey="form.passwordRules.lowercase" />} valid={hasLowercase()} />
                <Item text={<Trans i18nKey="form.passwordRules.uppercase" />} valid={hasUppercase()} />
                <Item text={<Trans i18nKey="form.passwordRules.number" />} valid={hasNumber()} />
                <Item text={<Trans i18nKey="form.passwordRules.length" />} valid={hasRequiredLength()} />
                <Item text={<Trans i18nKey="form.passwordRules.special" />} valid={hasSpecial()} />
            </List>
        </FormHelperText>
    )
}

export default PasswordRules
