import { TextEncoderLite } from 'text-encoder-lite'

export const utf8TextLength = (text: string) => {
    if (!text) {
        return 0
    }
    return new TextEncoderLite('utf-8').encode(text).length
}

export const characterTextLength = (text?: string) => {
    if (!text) {
        return 0
    }
    return text.length
}
