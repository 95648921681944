import { Button, Grid, IconButton, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { ArrowBack } from '@material-ui/icons'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import {
    Establishment,
    MessagingConversation,
    MessagingMessage,
    PostDetails,
} from '@sugg-gestion/ubidreams-react-suggpro'
import clsx from 'clsx'
import Loader from 'components/common/loader'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { messagingLink } from '../../messagingUtils'
import { useStyles } from '../../styles.css'
import PostContainer from '../postContainer'
import MenuPane from './menu'

interface Props {
    establishment: Establishment
    conversation: MessagingConversation
    handleToggleDrawer?: any
    messages: Array<MessagingMessage>
    inProgress: boolean
    postDetails?: PostDetails
    onDeletePost: () => void
    onHidePost: () => void
}

const PostPane: React.FC<Props> = ({
    establishment,
    conversation,
    handleToggleDrawer,
    messages,
    inProgress,
    postDetails,
    onDeletePost,
    onHidePost,
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
    const { t } = useTranslation()

    return (
        <Grid container>
            {inProgress ? (
                <>
                    <Grid item xs={12}>
                        <div className={classes.loaderContainer}>
                            <Loader classes={{ main: classes.loaderConversation }} withLogo={false} />
                            <div className={classes.loaderIndicator}>{t('views.messaging.loader')}</div>
                        </div>
                    </Grid>
                </>
            ) : (
                <>
                    {postDetails && (
                        <>
                            {/* Discuss Info head */}
                            <Grid item xs={12}>
                                <Grid container alignItems={'center'} className={classes.discussHat}>
                                    {isMobile && (
                                        <>
                                            <Grid item xs={1}>
                                                <IconButton
                                                    color={'primary'}
                                                    onClick={handleToggleDrawer(false)}
                                                >
                                                    <ArrowBack />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={1} />
                                        </>
                                    )}
                                    <Grid item sm={7} xs={7}>
                                        <a
                                            className={classes.userProfileLink}
                                            href={conversation.permalink || ''}
                                            target={'_blank'}
                                            title={t('views.messaging.lookupPost')}
                                            rel="noreferrer"
                                        >
                                            {conversation.title}
                                        </a>
                                    </Grid>
                                    <Grid item sm={5} xs={4} className={classes.discussHatOptions}>
                                        <IconButton
                                            className={classes.discussHatOpenInNew}
                                            color={'primary'}
                                            href={messagingLink(conversation, establishment) || ''}
                                            target={'_blank'}
                                            title={t('views.messaging.openConversationInNewTab')}
                                        >
                                            <Typography>
                                                {t('views.messaging.openConversationInNewTab')}
                                            </Typography>
                                            <OpenInNewIcon />
                                        </IconButton>
                                        <MenuPane
                                            onDeletePost={
                                                conversation.typePlatform !== 'instagram'
                                                    ? onDeletePost
                                                    : undefined
                                            }
                                            onHidePost={
                                                conversation.typePlatform === 'instagram'
                                                    ? onHidePost
                                                    : undefined
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Discuss */}
                            <Grid item xs={12}>
                                <div
                                    className={clsx(
                                        classes.discussThread,
                                        classes.discussThreadBGColorLightgrey,
                                    )}
                                >
                                    <PostContainer
                                        establishment={establishment}
                                        conversation={conversation}
                                        postDetails={postDetails}
                                        comments={messages}
                                    />
                                </div>
                            </Grid>
                            {conversation.typePlatform === 'twitter' && (
                                <div className={classes.conversationOpenInNew}>
                                    <div>{t('views.messaging.openConversationInNewTabText')}</div>
                                    <Button
                                        variant="contained"
                                        color={'primary'}
                                        endIcon={<OpenInNewIcon />}
                                        href={
                                            'https://www.twitter.com/' +
                                            establishment.twitterScreenName +
                                            '/status/' +
                                            conversation.threadId
                                        }
                                        target={'_blank'}
                                        title={t('views.messaging.openConversationInNewTabTitle')}
                                    >
                                        {t('views.messaging.openConversationInNewTab')}
                                    </Button>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </Grid>
    )
}

export default PostPane
