import React from 'react'
import withSignUpLayout, { WithSignUpLayout } from 'containers/hoc/withSignUpLayout'
import { useDispatch } from 'react-redux'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import { Address, useEstablishmentUpdate } from '@sugg-gestion/ubidreams-react-suggpro'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { redirectToEstablishment } from 'core/store/app/actions'
import EstablishmentLocationView from 'components/views/establishment/establishmentLocationView'
import { LocationFormState } from 'components/forms/establishment/locationForm'

interface Props extends WithSignUpLayout {
    step?: number
    steps?: number
}

const EstablishmentAddLocation: React.FC<Props> = ({ establishment, step = 5, steps = 8 }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const { displayError } = useApiErrors()
    const { updateEstablishment, inProgress } = useEstablishmentUpdate()
    const { updateEstablishment: updateToPreviousStatus, inProgress: updateToPreviousStatusInProgress } =
        useEstablishmentUpdate()

    const handleUpdateLocation = (values: LocationFormState) => {
        const completeAddress: Address = {
            street_1: values.address_street_1 ?? null,
            street_2: values.address_street_2 ?? null,
            zip_code: values.address_zip_code ?? null,
            city: values.address_city ?? null,
            country: values.address_country ?? null,
        }
        updateEstablishment({
            id: establishment.id,
            establishment: {
                location: values.position,
                completeAddress,
            },
            nextStatus: 'HOURS',
        })
            .then(() => {
                enqueueSnackbar(t('views.establishment.location.success'), {
                    variant: 'success',
                })
                dispatch(redirectToEstablishment())
            })
            .catch((error) => displayError(error))
    }

    const handlePrevious = () => {
        updateToPreviousStatus({
            id: establishment.id,
            establishment: {},
            nextStatus: 'ESTABLISHMENT',
        })
            .then(() => {
                dispatch(redirectToEstablishment())
            })
            .catch((error) => displayError(error))
    }

    return (
        <EstablishmentLocationView
            step={step}
            steps={steps}
            onSubmit={handleUpdateLocation}
            inProgress={inProgress}
            initialValues={{
                address_street_1: establishment.completeAddress.street_1 ?? '',
                address_street_2: establishment.completeAddress.street_2 ?? '',
                address_zip_code: establishment?.completeAddress.zip_code ?? '',
                address_city: establishment?.completeAddress.city ?? '',
                address_country: establishment?.completeAddress.country ?? 'FR',
                position: establishment?.location,
            }}
            previousInProgress={updateToPreviousStatusInProgress}
            onPrevious={handlePrevious}
        />
    )
}

export default withSignUpLayout(EstablishmentAddLocation)
