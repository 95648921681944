import React from 'react'
import DunsForm, { DunsFormState } from 'components/forms/establishment/dunsForm'
import SubmitButton from 'components/common/submitButton'
import clsx from 'clsx'
import EstablishmentCreateView from './establishmentCreateView'
import { Typography } from '@material-ui/core'
import { useStyles } from './styles.css'
import { Trans } from 'react-i18next'
import SignUpContent from 'components/layouts/components/signUpContent'
import SignUpActions from 'components/layouts/components/signUpActions'

interface Props {
    step: number
    steps: number
    onSubmit: (values: DunsFormState) => void
    inProgress: boolean
    initialValues: DunsFormState
}

const EstablishmentSiretView: React.FC<Props> = ({ children, step, steps, ...formData }) => {
    const classes = useStyles()
    return (
        <DunsForm {...formData} type="duns" country="FR">
            {(content, actions) => (
                <>
                    <SignUpContent>
                        <EstablishmentCreateView step={step} steps={steps}>
                            <Typography variant="h1" color="textSecondary" className={classes.title}>
                                <Trans i18nKey="views.establishment.duns.title" />
                            </Typography>
                            <Typography className={classes.description}>
                                <Trans i18nKey="views.establishment.duns.description" />
                            </Typography>
                            {content}
                        </EstablishmentCreateView>
                    </SignUpContent>
                    <SignUpActions>
                        {actions.map((action, index) => (
                            <SubmitButton
                                key={index}
                                variant="contained"
                                color="primary"
                                {...action}
                                className={clsx(action.className)}
                            />
                        ))}
                    </SignUpActions>
                </>
            )}
        </DunsForm>
    )
}

export default EstablishmentSiretView
