import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { Visibility } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import { ApplicationState } from 'core/store/reducers'
import i18next from 'i18next'
import { SnackbarKey, useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { MessagingMessage } from '@sugg-gestion/ubidreams-react-suggpro'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        snackbarBody: {
            maxWidth: '300px',
        },
        snackbarTitle: {
            fontSize: '1rem',
        },
        snackbarContent: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
    }),
)

const withMessage = (ComposedComponent: any) => {
    const WithMessage: React.FC = (props) => {
        const classes = useStyles()
        const { enqueueSnackbar, closeSnackbar } = useSnackbar()
        const { lastMessages, convPrivateMessages, convPosts, convReviews, establishments, ability } =
            useSelector(({ suggpro: { messaging, establishment }, app }: ApplicationState) => ({
                lastMessages: messaging.lastMessages,
                convPrivateMessages: messaging.convPrivateMessages,
                convPosts: messaging.convPosts,
                convReviews: messaging.convReviews,
                establishments: establishment.establishments,
                ability: app.ability,
            }))
        const allConversations = convPrivateMessages?.concat(convPosts || []).concat(convReviews || [])
        const history = useHistory()
        const [lastNotificationMessage, setLastNotificationMessage] = useState<undefined | MessagingMessage>(
            undefined,
        )

        useEffect(() => {
            if (!lastNotificationMessage || (lastMessages && lastNotificationMessage !== lastMessages[0])) {
                if (ability.can('api.conversation_access') && lastMessages && lastMessages.length > 0) {
                    const lastMessage = lastMessages[0]
                    const establishment = establishments?.find(
                        (esta) => esta.id === lastMessage.establishment,
                    )
                    const establishmentBasePath = '/establishment/:id'.replace(
                        ':id',
                        establishment?.id.toString() ?? '',
                    )

                    const routeChange = (snackBarKey: SnackbarKey) => {
                        closeSnackbar(snackBarKey)
                        history.push(establishmentBasePath + '/messages')
                    }

                    // Don't send notify when is our message
                    if (
                        establishment &&
                        ((lastMessage.typePlatform === 'facebook' &&
                            lastMessage.author.userId !== establishment.facebookPageId) ||
                            (lastMessage.typePlatform === 'instagram' &&
                                lastMessage.author.userId !== establishment.instagramPageId) ||
                            (lastMessage.typePlatform === 'twitter' &&
                                lastMessage.author.userId !== establishment.twitterID) ||
                            lastMessage.typePlatform === 'google')
                    ) {
                        setLastNotificationMessage(lastMessage)
                        const action = (key: SnackbarKey) => {
                            return (
                                <>
                                    <Button
                                        variant={'outlined'}
                                        color={'inherit'}
                                        startIcon={<Visibility />}
                                        onClick={() => routeChange(key)}
                                    >
                                        {i18next.t('views.messaging.openNewMessage')}
                                    </Button>
                                    <IconButton color={'inherit'} onClick={() => closeSnackbar(key)}>
                                        <CloseIcon />
                                    </IconButton>
                                </>
                            )
                        }

                        enqueueSnackbar(
                            <div className={classes.snackbarBody}>
                                <div className={classes.snackbarTitle}>
                                    {i18next.t('views.messaging.notifyNewMessage')}
                                </div>
                                <div className={classes.snackbarContent}>{lastMessage.messageText}</div>
                            </div>,
                            {
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                },
                                variant: 'info',
                                persist: true,
                                action,
                            },
                        )
                    }
                }
            }
        }, [
            ability,
            allConversations,
            classes.snackbarBody,
            classes.snackbarContent,
            classes.snackbarTitle,
            closeSnackbar,
            enqueueSnackbar,
            establishments,
            history,
            lastMessages,
            lastNotificationMessage,
        ])
        return (
            <>
                <ComposedComponent {...props} />
            </>
        )
    }
    return WithMessage
}

export default withMessage
