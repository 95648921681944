import React from 'react'
import { Trans } from 'react-i18next'
import { Alert } from '@material-ui/lab'
import { useStyles } from './styles.css'
import SubmitButton from 'components/common/submitButton'

interface Props {
    message: string
    action: string
    onClick: () => void
    loading: boolean
}

const PermissionAlert: React.FC<Props> = ({ message, action, onClick, loading }) => {
    const classes = useStyles()
    return (
        <Alert
            severity="warning"
            action={
                <SubmitButton loading={loading} variant="outlined" onClick={onClick}>
                    <Trans i18nKey={action} />
                </SubmitButton>
            }
            className={classes.alert}
        >
            <Trans i18nKey={message} />
        </Alert>
    )
}

export default PermissionAlert
