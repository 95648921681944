import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setHubSpotFormInitialized } from 'core/store/app/actions'

const useHubSpot = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        const { REACT_APP_USE_HUBSPOT } = process.env
        if (REACT_APP_USE_HUBSPOT) {
            ;(function (d, s, id) {
                let js,
                    fjs = d.getElementsByTagName(s)[0]
                if (d.getElementById(id)) return
                js = d.createElement(s) as HTMLScriptElement
                js.id = id
                js.src = 'https://js.hs-scripts.com/' + process.env.REACT_APP_HUBSPOT_PORTAL_ID + '.js'
                const a = fjs.parentNode
                if (a) {
                    a.insertBefore(js, fjs)
                }
            })(document, 'script', 'hubspot')
        }
        ;(function (d, s, id) {
            let js,
                fjs = d.getElementsByTagName(s)[0]
            if (d.getElementById(id)) return
            js = d.createElement(s) as HTMLScriptElement
            js.id = id
            js.src = '//js.hsforms.net/forms/shell.js'
            js.addEventListener('load', function () {
                dispatch(setHubSpotFormInitialized(true))
            })
            const a = fjs.parentNode
            if (a) {
                a.insertBefore(js, fjs)
            }
        })(document, 'script', 'hubspot-shell')
    }, [dispatch])
}

export default useHubSpot
