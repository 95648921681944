import React from 'react'
import withAuthenticateLayout, { WithAuthenticateLayout } from 'containers/hoc/withAuthenticateLayout'
import RecruitmentView from 'components/views/recruitment/recruitmentView'

interface Props extends WithAuthenticateLayout {}

const Recruitment: React.FC<Props> = ({ establishment, restaurantOwner }) => {
    const handleFormReady = (form: HTMLFormElement) => {
        const inputs = form.getElementsByTagName('input')
        for (let i = 0; i < inputs.length; i++) {
            const input = inputs[i]
            switch (input.name) {
                case 'company':
                    input.setAttribute('value', establishment.name ?? '')
                    input.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }))
                    break
                case 'email':
                    input.setAttribute('value', restaurantOwner.email ?? '')
                    input.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }))
                    break
                case 'firstname':
                    input.setAttribute('value', restaurantOwner.firstname ?? '')
                    input.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }))
                    break
                case 'lastname':
                    input.setAttribute('value', restaurantOwner.lastname ?? '')
                    input.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }))
                    break
                case 'address':
                    input.setAttribute(
                        'value',
                        (establishment.completeAddress.street_1 ?? '') +
                            (establishment.completeAddress.street_2
                                ? ' ' + establishment.completeAddress.street_2
                                : ''),
                    )
                    input.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }))
                    break
                case 'zip':
                    input.setAttribute('value', establishment.completeAddress.zip_code ?? '')
                    input.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }))
                    break
                case 'city':
                    input.setAttribute('value', establishment.completeAddress.city ?? '')
                    input.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }))
                    break
                case 'phone':
                    input.setAttribute(
                        'value',
                        establishment.phoneNumber ?? restaurantOwner.phoneNumber ?? '',
                    )
                    input.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }))
                    break
            }
        }
    }

    return <RecruitmentView onFormReady={handleFormReady} />
}

export default withAuthenticateLayout(Recruitment)
