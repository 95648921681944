import React from 'react'
import { Typography } from '@material-ui/core'
import { Trans } from 'react-i18next'
import { useStyles as useHomeStyles } from './homeView.css'
import { useStyles } from './styles.css'
import EmailForm, { EmailFormState } from 'components/forms/auth/emailForm'
import SubmitButton from 'components/common/submitButton'
import clsx from 'clsx'
import HomeLayout from 'components/layouts/homeLayout'
import HomeContent from 'components/layouts/components/homeContent'
import HomeActions from 'components/layouts/components/homeActions'

interface Props {
    onSubmit: (values: EmailFormState) => void
    inProgress: boolean
    initialValues: EmailFormState
}

const HomeView: React.FC<Props> = ({ onSubmit, inProgress, initialValues }) => {
    const classes = useStyles()
    const homeClasses = useHomeStyles()

    return (
        <HomeLayout>
            <EmailForm onSubmit={onSubmit} inProgress={inProgress} initialValues={initialValues}>
                {(content, actions) => (
                    <>
                        <HomeContent className={clsx(homeClasses.cardContent)}>
                            <Typography variant="h1" className={classes.title}>
                                <Trans i18nKey="views.home.title" />
                            </Typography>
                            <Typography>
                                <Trans i18nKey="views.home.message" />
                            </Typography>
                            {content}
                        </HomeContent>
                        <HomeActions>
                            <>
                                {actions.map((action, index) => (
                                    <SubmitButton
                                        key={index}
                                        variant="contained"
                                        color="primary"
                                        {...action}
                                        className={clsx(action.className)}
                                    />
                                ))}
                            </>
                        </HomeActions>
                    </>
                )}
            </EmailForm>
        </HomeLayout>
    )
}

export default HomeView
