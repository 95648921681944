import { AnyAbility } from '@casl/ability'
import {
    Button,
    ButtonBase,
    CardMedia,
    Chip,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Switch,
    Typography,
    useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import SettingsIcon from '@material-ui/icons/Settings'
import ShareIcon from '@material-ui/icons/Share'
import TwitterIcon from '@material-ui/icons/Twitter'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'
import { Establishment, Slate, SlateImage } from '@sugg-gestion/ubidreams-react-suggpro'
import clsx from 'clsx'
import FlatCard from 'components/common/flatCard'
import SubmitButton from 'components/common/submitButton'
import ZoomDialog from 'components/dialogs/zoomDialog'
import GoogleIcon from 'components/icons/googleIcon'
import AuthenticateActions from 'components/layouts/components/authenticateActions'
import AuthenticateContent from 'components/layouts/components/authenticateContent'
import { useImgix } from 'core/services/imgix/useImgix'
import moment from 'moment'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { InlineShareButtons } from 'sharethis-reactjs'
import { SocialNetwork } from '../../../containers/slate'
import { useFlippable } from '../../helpers/useFlippable'
import { useStyles } from './styles.css'

interface Props {
    establishment: Establishment
    slate: Slate
    images: Array<SlateImage>
    shareOn: (socialNetwork: SocialNetwork) => void
    isSharingSlateOnFacebook: boolean
    isSharingSlateOnGoogle: boolean
    isSharingSlateOnInstagram: boolean
    isSharingSlateOnTwitter: boolean
    isGoogleSignedExpired: boolean
    isFacebookSignedExpired: boolean
    isTwitterSignedExpired: boolean
    ability: AnyAbility
    extraSharing?: React.ReactNode
    showTwitter: boolean
}

const SlateView: React.FC<Props> = (props) => {
    const {
        establishment,
        slate,
        images,
        shareOn,
        isSharingSlateOnFacebook,
        isSharingSlateOnInstagram,
        isSharingSlateOnGoogle,
        isSharingSlateOnTwitter,
        isGoogleSignedExpired,
        isTwitterSignedExpired,
        isFacebookSignedExpired,
        extraSharing,
        showTwitter,
    } = props
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true })

    const { getImage } = useImgix()
    const establishmentBasePath = '/establishment/:id'.replace(':id', establishment.id.toString() ?? '')

    const [zoom, setZoom] = useState<SlateImage | undefined>(undefined)

    const handleZoom = (image: SlateImage) => {
        setZoom(image)
    }
    const handleClose = () => {
        setZoom(undefined)
    }

    const { classes: flippableClasses, shouldDisplayImagesOptions, onDisplayImagesOptions } = useFlippable()

    return (
        <>
            <AuthenticateContent>
                <Typography variant="h1" color="textSecondary" className={classes.title}>
                    <Trans i18nKey="views.slate.title" />
                    <Button
                        className={classes.setting}
                        variant="outlined"
                        component={Link}
                        to={establishmentBasePath + '/slate/setting?withBack'}
                        startIcon={<SettingsIcon />}
                    >
                        <Trans i18nKey="actions.setting" />
                    </Button>
                </Typography>
                <Grid container spacing={isMobileScreen ? 2 : 4}>
                    <Grid item xs={12}>
                        {/* PHOTO */}
                        <Typography
                            color="textSecondary"
                            className={clsx(classes.formTitle, classes.noPaddingTop)}
                        >
                            <Trans i18nKey="form.photosImage" values={{ count: images.length }} />
                        </Typography>
                        <FormControl margin="normal" fullWidth>
                            <Grid container spacing={isMobileScreen ? 2 : 4}>
                                {images.map((image, index) => (
                                    <Grid
                                        item
                                        xl={2}
                                        lg={3}
                                        sm={4}
                                        xs={6}
                                        key={index}
                                        className={classes.photoGrid}
                                    >
                                        <FlatCard className={classes.photoGrid}>
                                            {images.length > 1 && (
                                                <FormControlLabel
                                                    className={clsx(classes.options, classes.imageOptions)}
                                                    control={
                                                        <Switch
                                                            color="primary"
                                                            size={isMobileScreen ? 'small' : 'medium'}
                                                            checked={shouldDisplayImagesOptions(image.id)}
                                                            onChange={(event, checked) =>
                                                                onDisplayImagesOptions(image.id, checked)
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        isMobileScreen ? (
                                                            <Trans i18nKey="form.photosImageOptionsShort" />
                                                        ) : (
                                                            <Trans i18nKey="form.photosImageOptions" />
                                                        )
                                                    }
                                                />
                                            )}
                                            <div
                                                className={clsx(
                                                    classes.photoGrid,
                                                    flippableClasses.flippableContainer,
                                                    {
                                                        [flippableClasses.flip]: shouldDisplayImagesOptions(
                                                            image.id,
                                                        ),
                                                    },
                                                )}
                                            >
                                                <ButtonBase
                                                    onClick={() => handleZoom(image)}
                                                    itemProp="photo"
                                                    className={clsx(
                                                        classes.photoButton,
                                                        flippableClasses.flippable,
                                                        flippableClasses.flipFront,
                                                    )}
                                                >
                                                    <CardMedia
                                                        itemProp="contentUrl"
                                                        component={'img'}
                                                        src={getImage(image.picture, {
                                                            h: 400,
                                                            w: 400,
                                                            fit: 'crop',
                                                        })}
                                                    />
                                                    <IconButton
                                                        component={'div'}
                                                        onClick={() => handleZoom(image)}
                                                        className={classes.photoZoom}
                                                        color="primary"
                                                        size={isMobileScreen ? 'small' : 'medium'}
                                                    >
                                                        <ZoomOutMapIcon color="inherit" fontSize="inherit" />
                                                    </IconButton>
                                                </ButtonBase>
                                                {images.length > 1 && (
                                                    <div
                                                        className={clsx(
                                                            flippableClasses.flippable,
                                                            flippableClasses.flipBack,
                                                        )}
                                                    >
                                                        {/*  COMMENT  */}
                                                        <FormControl
                                                            margin="normal"
                                                            fullWidth
                                                            className={classes.imageComment}
                                                        >
                                                            <FormLabel>
                                                                <Trans i18nKey="form.imageComment" />
                                                            </FormLabel>
                                                            <Typography
                                                                color="textPrimary"
                                                                className={classes.comment}
                                                            >
                                                                {!image.comment
                                                                    ? t('views.slate.noComment')
                                                                    : image.comment}
                                                            </Typography>
                                                        </FormControl>
                                                        {(slate.isFacebookShared ||
                                                            slate.isGoogleShared ||
                                                            slate.isInstagramShared ||
                                                            slate.isTwitterShared) && (
                                                            <>
                                                                {/*  SHARE  */}
                                                                <FormControl
                                                                    margin="normal"
                                                                    fullWidth
                                                                    className={classes.share}
                                                                >
                                                                    <FormLabel>
                                                                        <Trans i18nKey="form.shared" />
                                                                    </FormLabel>
                                                                    <List
                                                                        dense
                                                                        className={classes.listShared}
                                                                    >
                                                                        <Grid container>
                                                                            {slate.isFacebookShared && (
                                                                                <Grid item xs={6}>
                                                                                    <ListItem
                                                                                        disableGutters={
                                                                                            isMobileScreen
                                                                                        }
                                                                                        divider={
                                                                                            slate.isGoogleShared ||
                                                                                            slate.isInstagramShared ||
                                                                                            slate.isTwitterShared
                                                                                        }
                                                                                        disabled={
                                                                                            !image.isFacebookShared
                                                                                        }
                                                                                    >
                                                                                        <ListItemIcon>
                                                                                            <FacebookIcon
                                                                                                className={
                                                                                                    classes.facebookIcon
                                                                                                }
                                                                                            />
                                                                                        </ListItemIcon>
                                                                                        <ListItemIcon
                                                                                            classes={{
                                                                                                root: classes.shareIconEnd,
                                                                                            }}
                                                                                        >
                                                                                            {image.isFacebookShared && (
                                                                                                <DoneIcon
                                                                                                    htmlColor={
                                                                                                        theme
                                                                                                            .palette
                                                                                                            .success
                                                                                                            .main
                                                                                                    }
                                                                                                    fontSize="small"
                                                                                                />
                                                                                            )}
                                                                                        </ListItemIcon>
                                                                                    </ListItem>
                                                                                </Grid>
                                                                            )}

                                                                            {slate.isInstagramShared && (
                                                                                <Grid item xs={6}>
                                                                                    <ListItem
                                                                                        disableGutters={
                                                                                            isMobileScreen
                                                                                        }
                                                                                        divider={
                                                                                            slate.isGoogleShared ||
                                                                                            slate.isTwitterShared
                                                                                        }
                                                                                        disabled={
                                                                                            !image.isInstagramShared
                                                                                        }
                                                                                    >
                                                                                        <ListItemIcon>
                                                                                            <InstagramIcon
                                                                                                className={
                                                                                                    classes.instagramIcon
                                                                                                }
                                                                                            />
                                                                                        </ListItemIcon>
                                                                                        <ListItemIcon
                                                                                            classes={{
                                                                                                root: classes.shareIconEnd,
                                                                                            }}
                                                                                        >
                                                                                            {image.isInstagramShared && (
                                                                                                <DoneIcon
                                                                                                    htmlColor={
                                                                                                        theme
                                                                                                            .palette
                                                                                                            .success
                                                                                                            .main
                                                                                                    }
                                                                                                    fontSize="small"
                                                                                                />
                                                                                            )}
                                                                                        </ListItemIcon>
                                                                                    </ListItem>
                                                                                </Grid>
                                                                            )}
                                                                            {slate.isGoogleShared && (
                                                                                <Grid item xs={6}>
                                                                                    <ListItem
                                                                                        disableGutters={
                                                                                            isMobileScreen
                                                                                        }
                                                                                        divider={
                                                                                            slate.isTwitterShared
                                                                                        }
                                                                                        disabled={
                                                                                            !image.isGoogleShared
                                                                                        }
                                                                                    >
                                                                                        <ListItemIcon>
                                                                                            <GoogleIcon />
                                                                                        </ListItemIcon>
                                                                                        <ListItemIcon
                                                                                            classes={{
                                                                                                root: classes.shareIconEnd,
                                                                                            }}
                                                                                        >
                                                                                            {image.isGoogleShared && (
                                                                                                <DoneIcon
                                                                                                    htmlColor={
                                                                                                        theme
                                                                                                            .palette
                                                                                                            .success
                                                                                                            .main
                                                                                                    }
                                                                                                    fontSize="small"
                                                                                                />
                                                                                            )}
                                                                                        </ListItemIcon>
                                                                                    </ListItem>
                                                                                </Grid>
                                                                            )}
                                                                            {slate.isTwitterShared && (
                                                                                <Grid item xs={6}>
                                                                                    <ListItem
                                                                                        disableGutters={
                                                                                            isMobileScreen
                                                                                        }
                                                                                        divider
                                                                                        disabled={
                                                                                            !image.isTwitterShared
                                                                                        }
                                                                                    >
                                                                                        <ListItemIcon>
                                                                                            <TwitterIcon
                                                                                                className={
                                                                                                    classes.twitterIcon
                                                                                                }
                                                                                            />
                                                                                        </ListItemIcon>
                                                                                        <ListItemIcon
                                                                                            classes={{
                                                                                                root: classes.shareIconEnd,
                                                                                            }}
                                                                                        >
                                                                                            {image.isTwitterShared && (
                                                                                                <DoneIcon
                                                                                                    htmlColor={
                                                                                                        theme
                                                                                                            .palette
                                                                                                            .success
                                                                                                            .main
                                                                                                    }
                                                                                                    fontSize="small"
                                                                                                />
                                                                                            )}
                                                                                        </ListItemIcon>
                                                                                    </ListItem>
                                                                                </Grid>
                                                                            )}
                                                                        </Grid>
                                                                    </List>
                                                                </FormControl>
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </FlatCard>
                                    </Grid>
                                ))}
                            </Grid>
                        </FormControl>
                    </Grid>
                    <Grid item sm={5} xs={12}>
                        {/* VALIDITY */}
                        <Typography
                            color="textSecondary"
                            className={clsx(classes.formTitle, classes.noPaddingTop)}
                        >
                            <Trans i18nKey="form.slateValidity" />
                        </Typography>
                        <FormControl margin="normal" fullWidth>
                            <Typography>
                                <Trans
                                    i18nKey="form.until"
                                    values={{
                                        date: moment(slate.validityDate).toDate(),
                                    }}
                                />
                            </Typography>
                        </FormControl>

                        {/*  COMMENT  */}
                        <Typography color="textSecondary" className={classes.formTitle}>
                            <Trans i18nKey="form.slateComment" />
                        </Typography>
                        <Typography color="textPrimary" className={classes.comment}>
                            {slate.comment === '' ? t('views.slate.noComment') : slate.comment}
                        </Typography>
                    </Grid>
                    <Grid item sm={7} xs={12}>
                        {/* CATEGORIES */}
                        <Typography
                            color="textSecondary"
                            className={clsx(classes.formTitle, classes.noPaddingTop)}
                        >
                            <Trans i18nKey="form.slateCategories" />
                        </Typography>
                        <FormControl margin="normal" fullWidth>
                            <Grid container spacing={2}>
                                {slate.categories.map((category, index) => (
                                    <Grid item key={index} xs={6} md={6} lg={4}>
                                        <Chip label={category.name} className={classes.categoryChip} />
                                    </Grid>
                                ))}
                            </Grid>
                        </FormControl>

                        {/*  SHARING  */}
                        <Typography color="textSecondary" className={classes.formTitle}>
                            <Trans i18nKey="form.slateShare" />
                        </Typography>

                        <List className={classes.listSharedButton}>
                            <ListItem divider>
                                <ListItemIcon>
                                    <FacebookIcon className={classes.facebookIcon} />
                                </ListItemIcon>
                                {slate.isFacebookShared ? (
                                    <>
                                        <ListItemText primary={t('views.slate.share.facebook')} />
                                        {slate.facebookPermalink && (
                                            <Button
                                                variant="outlined"
                                                href={slate.facebookPermalink}
                                                target="_blank"
                                                endIcon={<OpenInNewIcon />}
                                            >
                                                {!isMobileScreen && (
                                                    <Trans i18nKey="actions.checkPublication" />
                                                )}
                                            </Button>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <ListItemText primary={t('views.slate.share.notFacebook')} />
                                        <ListItemSecondaryAction className={clsx(classes.action)}>
                                            <SubmitButton
                                                variant="outlined"
                                                color="primary"
                                                endIcon={<ShareIcon />}
                                                onClick={() => shareOn(SocialNetwork.Facebook)}
                                                loading={isSharingSlateOnFacebook}
                                            >
                                                {establishment.isFacebookLinked &&
                                                !isFacebookSignedExpired ? (
                                                    <Trans i18nKey="actions.share" />
                                                ) : isFacebookSignedExpired ? (
                                                    <Trans i18nKey="actions.renewSignInFacebook" />
                                                ) : (
                                                    <Trans i18nKey="actions.link" />
                                                )}
                                            </SubmitButton>
                                        </ListItemSecondaryAction>
                                    </>
                                )}
                            </ListItem>
                            <ListItem divider>
                                <ListItemIcon>
                                    <InstagramIcon className={classes.instagramIcon} />
                                </ListItemIcon>
                                {slate.isInstagramShared ? (
                                    <>
                                        <ListItemText primary={t('views.slate.share.instagram')} />
                                        {slate.instagramPermalink && (
                                            <Button
                                                variant="outlined"
                                                href={slate.instagramPermalink}
                                                target="_blank"
                                                endIcon={<OpenInNewIcon />}
                                            >
                                                {!isMobileScreen && (
                                                    <Trans i18nKey="actions.checkPublication" />
                                                )}
                                            </Button>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <ListItemText primary={t('views.slate.share.notInstagram')} />
                                        <ListItemSecondaryAction className={clsx(classes.action)}>
                                            <SubmitButton
                                                variant="outlined"
                                                color="primary"
                                                endIcon={<ShareIcon />}
                                                onClick={() => shareOn(SocialNetwork.Instagram)}
                                                loading={isSharingSlateOnInstagram}
                                            >
                                                {establishment.instagramPageId && !isFacebookSignedExpired ? (
                                                    <Trans i18nKey="actions.share" />
                                                ) : isFacebookSignedExpired ? (
                                                    <Trans i18nKey="actions.renewSignInFacebook" />
                                                ) : (
                                                    <Trans i18nKey="actions.link" />
                                                )}
                                            </SubmitButton>
                                        </ListItemSecondaryAction>
                                    </>
                                )}
                            </ListItem>
                            <ListItem divider>
                                <ListItemIcon>
                                    <GoogleIcon />
                                </ListItemIcon>
                                {slate.isGoogleShared ? (
                                    <>
                                        <ListItemText primary={t('views.slate.share.google')} />
                                        {slate.googleLocalPostPermalink ? (
                                            <Button
                                                variant="outlined"
                                                href={slate.googleLocalPostPermalink}
                                                target="_blank"
                                                endIcon={<OpenInNewIcon />}
                                            >
                                                {!isMobileScreen && (
                                                    <Trans i18nKey="actions.checkPublication" />
                                                )}
                                            </Button>
                                        ) : establishment.googleUrl ? (
                                            <Button
                                                variant="outlined"
                                                href={establishment.googleUrl}
                                                target="_blank"
                                                endIcon={<OpenInNewIcon />}
                                            >
                                                {!isMobileScreen && (
                                                    <Trans i18nKey="actions.checkPublication" />
                                                )}
                                            </Button>
                                        ) : null}
                                    </>
                                ) : (
                                    <>
                                        <ListItemText primary={t('views.slate.share.notGoogle')} />
                                        <ListItemSecondaryAction className={clsx(classes.action)}>
                                            <SubmitButton
                                                variant="outlined"
                                                color="primary"
                                                endIcon={<ShareIcon />}
                                                onClick={() => shareOn(SocialNetwork.Google)}
                                                loading={isSharingSlateOnGoogle}
                                            >
                                                {establishment.isGoogleLinked && !isGoogleSignedExpired ? (
                                                    <Trans i18nKey="actions.share" />
                                                ) : isGoogleSignedExpired ? (
                                                    <Trans i18nKey="actions.renewSignInGoogle" />
                                                ) : (
                                                    <Trans i18nKey="actions.link" />
                                                )}
                                            </SubmitButton>
                                        </ListItemSecondaryAction>
                                    </>
                                )}
                            </ListItem>
                            {showTwitter && (
                                <ListItem divider>
                                    <ListItemIcon>
                                        <TwitterIcon className={classes.twitterIcon} />
                                    </ListItemIcon>
                                    {slate.isTwitterShared ? (
                                        <>
                                            <ListItemText primary={t('views.slate.share.twitter')} />
                                            {slate.twitterPermalink && (
                                                <Button
                                                    variant="outlined"
                                                    href={slate.twitterPermalink}
                                                    target="_blank"
                                                    endIcon={<OpenInNewIcon />}
                                                >
                                                    {!isMobileScreen && (
                                                        <Trans i18nKey="actions.checkPublication" />
                                                    )}
                                                </Button>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <ListItemText primary={t('views.slate.share.notTwitter')} />
                                            <ListItemSecondaryAction className={clsx(classes.action)}>
                                                <SubmitButton
                                                    variant="outlined"
                                                    color="primary"
                                                    endIcon={<ShareIcon />}
                                                    onClick={() => shareOn(SocialNetwork.Twitter)}
                                                    loading={isSharingSlateOnTwitter}
                                                >
                                                    {establishment.twitterID && !isTwitterSignedExpired ? (
                                                        <Trans i18nKey="actions.share" />
                                                    ) : isTwitterSignedExpired ? (
                                                        <Trans i18nKey="actions.renewSignInTwitter" />
                                                    ) : (
                                                        <Trans i18nKey="actions.link" />
                                                    )}
                                                </SubmitButton>
                                            </ListItemSecondaryAction>
                                        </>
                                    )}
                                </ListItem>
                            )}
                            {extraSharing}
                            <ListItem>
                                <ListItemIcon>
                                    <ShareIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('views.slate.share.otherShare')} />
                                <ListItemSecondaryAction
                                    className={clsx(classes.action, classes.shareButtons)}
                                >
                                    <InlineShareButtons
                                        config={{
                                            alignment: 'center', // alignment of buttons (left, center, right)
                                            enabled: true, // show/hide buttons (true, false)
                                            language: 'fr', // which language to use (see LANGUAGES)
                                            padding: 8, // padding within buttons (INTEGER)
                                            size: 42, // the size of each button (INTEGER)
                                            networks: [
                                                // which networks to include (see SHARING NETWORKS)
                                                'twitter',
                                                'email',
                                                'pinterest',
                                                'linkedin',
                                                'whatsapp',
                                            ],
                                            // OPTIONAL PARAMETERS
                                            url: slate.picture, // (defaults to current url)
                                            color: 'white',
                                            font_size: 12,
                                            radius: 6,
                                            show_total: false,
                                            labels: null,
                                        }}
                                    />
                                    <InlineShareButtons
                                        config={{
                                            alignment: 'center', // alignment of buttons (left, center, right)
                                            enabled: true, // show/hide buttons (true, false)
                                            language: 'fr', // which language to use (see LANGUAGES)
                                            padding: 8, // padding within buttons (INTEGER)
                                            size: 42, // the size of each button (INTEGER)
                                            networks: [
                                                // which networks to include (see SHARING NETWORKS)
                                                'whatsapp',
                                            ],
                                            // OPTIONAL PARAMETERS
                                            url: slate.picture, // (defaults to current url)
                                            color: 'white',
                                            font_size: 12,
                                            radius: 6,
                                            show_total: false,
                                            labels: 'cta',
                                        }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </AuthenticateContent>
            <AuthenticateActions>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.mainAction}
                    component={Link}
                    to={establishmentBasePath + '/slate/add'}
                >
                    <Trans i18nKey="actions.replace" />
                </Button>
            </AuthenticateActions>
            {zoom && <ZoomDialog open={true} image={zoom} onClose={handleClose} />}
        </>
    )
}

export default SlateView
