import { AnyAbility } from '@casl/ability'
import { Can } from '@casl/react'
import { Button, ListSubheader, Typography } from '@material-ui/core'
import Hidden from '@material-ui/core/Hidden'
import MuiLink from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AddIcon from '@material-ui/icons/Add'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import AssignmentIcon from '@material-ui/icons/Assignment'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import LanguageIcon from '@material-ui/icons/Language'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import MessageIcon from '@material-ui/icons/Message'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import StorefrontIcon from '@material-ui/icons/Storefront'
import { Establishment, User } from '@sugg-gestion/ubidreams-react-suggpro'
import FlatCard from 'components/common/flatCard'
import Logo from 'components/common/logo'
import NoAccess from 'components/common/noAccess'
import BookingIcon from 'components/icons/bookingIcon'
import StatisticsIcon from 'components/icons/statisticsIcon'
import React, { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import PadLockIcon from '../../icons/padLockIcon'
import UpArrowCircleIcon from '../../icons/upArrowCircleIcon'
import LensIcon from '@material-ui/icons/Lens'
import { useStyles } from './authenticateDrawer.css'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'

interface Props {
    restaurantOwner: User
    signOut: () => void
    establishment: Establishment
    ability: AnyAbility
}

const AuthenticateDrawer: React.FC<Props> = ({ signOut, restaurantOwner, establishment, ability }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const history = useHistory()
    const { messagingStatus } = useSelector(({ suggpro: { messaging } }: ApplicationState) => ({
        messagingStatus: messaging.status,
    }))
    const establishmentBasePath = '/establishment/:id'.replace(':id', establishment?.id.toString() ?? '')
    return (
        <div>
            <Hidden smDown implementation="css">
                <div className={classes.toolbar}>
                    <Logo className={classes.logoContainer} spacing={2} />
                </div>
            </Hidden>
            <div className={classes.content}>
                <div className={classes.titleContainer}>
                    <Typography variant="h1" color="textSecondary">
                        {establishment.name}
                    </Typography>
                    <Typography color="textSecondary">
                        <Trans
                            i18nKey="drawer.welcome"
                            values={{
                                name: restaurantOwner.firstname,
                            }}
                            components={[<Typography component="span" color="primary" />]}
                        />
                    </Typography>
                </div>
                <div className={classes.action}>
                    <Can I="add" a="slate" ability={ability}>
                        <Button
                            component={Link}
                            to={establishmentBasePath + '/slate'}
                            variant="contained"
                            color="primary"
                            endIcon={
                                history.location.pathname === establishmentBasePath + '/slate' ? (
                                    <NavigateNextIcon />
                                ) : (
                                    <AddIcon />
                                )
                            }
                            fullWidth
                        >
                            <Trans i18nKey="actions.addSlate" />
                        </Button>
                    </Can>
                </div>
                <FlatCard className={classes.favoritesCount}>
                    <Typography classes={{ body1: classes.favoritesCountMessage }}>
                        <Trans
                            i18nKey="common.favoritesCount"
                            components={[
                                <span className={classes.favoritesCountNumber} />,
                                <MuiLink
                                    href="https://www.sugg1144.com/"
                                    color="inherit"
                                    target="_blank"
                                    className={classes.favoritesCountSugg1144}
                                />,
                            ]}
                            values={{ count: establishment.favoritesCount ?? 0 }}
                        />
                    </Typography>
                    <Typography classes={{ body1: classes.favoritesCountLink }}>
                        <MuiLink href="https://www.suggpro.com/telechargements/" target="_blank">
                            <Trans i18nKey="actions.favoritesCount" />
                        </MuiLink>
                    </Typography>
                </FlatCard>
                <List
                    component="nav"
                    className={classes.list}
                    subheader={
                        <ListSubheader
                            component={Typography}
                            classes={{
                                root: classes.listTitle,
                            }}
                        >
                            <Trans i18nKey="drawer.manageEstablishment" />
                        </ListSubheader>
                    }
                >
                    <Can I="manage" a="establishment" ability={ability}>
                        <ListItem
                            button
                            to={establishmentBasePath + '/edit'}
                            component={Link}
                            className={classes.listItem}
                            selected={history.location.pathname === establishmentBasePath + '/edit'}
                        >
                            <ListItemIcon>
                                <LocationOnIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('drawer.pages.establishment')} />
                        </ListItem>
                    </Can>
                    <Can I="manage" a="slate" ability={ability}>
                        <ListItem
                            button
                            to={establishmentBasePath + '/slate'}
                            component={Link}
                            className={classes.listItem}
                            selected={history.location.pathname === establishmentBasePath + '/slate'}
                        >
                            <ListItemIcon>
                                <AssignmentIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('drawer.pages.slate')} />
                        </ListItem>
                    </Can>
                    <Can I="api.events_access" ability={ability}>
                        <Can I="manage" a="events" ability={ability}>
                            <ListItem
                                button
                                to={establishmentBasePath + '/news'}
                                component={Link}
                                className={classes.listItem}
                                selected={history.location.pathname === establishmentBasePath + '/news'}
                            >
                                <ListItemIcon>
                                    <AnnouncementIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('drawer.pages.news')} />
                            </ListItem>
                        </Can>
                    </Can>
                    <Can not I="api.events_access" ability={ability}>
                        <Can I="manage" a="events" ability={ability}>
                            <NoAccess placement="right">
                                {(onClick) => (
                                    <ListItem button onClick={onClick} className={classes.listItem}>
                                        <ListItemIcon>
                                            <AnnouncementIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={t('drawer.pages.news')} />
                                        <ListItemIcon>
                                            <PadLockIcon color="inherit" className={classes.lockIcon} />
                                            <UpArrowCircleIcon color="primary" />
                                        </ListItemIcon>
                                    </ListItem>
                                )}
                            </NoAccess>
                        </Can>
                    </Can>
                    <Can I="manage" a="menu" ability={ability}>
                        <ListItem
                            button
                            to={establishmentBasePath + '/menu'}
                            component={Link}
                            className={classes.listItem}
                            selected={history.location.pathname === establishmentBasePath + '/menu'}
                        >
                            <ListItemIcon>
                                <MenuBookIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('drawer.pages.menu')} />
                        </ListItem>
                    </Can>
                    <Can I="api.conversation_access" ability={ability}>
                        <ListItem
                            button
                            to={establishmentBasePath + '/messages'}
                            component={Link}
                            className={classes.listItem}
                            selected={history.location.pathname === establishmentBasePath + '/messages'}
                        >
                            <ListItemIcon>
                                <MessageIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('drawer.pages.messaging')} />
                            <ListItemIcon>
                                {messagingStatus?.hasUnreadConversation && (
                                    <LensIcon
                                        fontSize={'small'}
                                        color={'primary'}
                                        className={classes.blinkIcon}
                                    />
                                )}
                            </ListItemIcon>
                        </ListItem>
                    </Can>
                    <Can I="manage" a="establishment" ability={ability}>
                        <ListItem
                            button
                            to={establishmentBasePath + '/external-links'}
                            component={Link}
                            className={classes.listItem}
                            selected={history.location.pathname === establishmentBasePath + '/external-links'}
                        >
                            <ListItemIcon>
                                <LanguageIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('drawer.pages.externalLinks')} />
                        </ListItem>
                    </Can>
                    <Can I="api.booking_access" ability={ability}>
                        {establishment.bookingModule && (
                            <ListItem
                                button
                                to={establishmentBasePath + '/booking'}
                                component={Link}
                                className={classes.listItem}
                                selected={history.location.pathname === establishmentBasePath + '/booking'}
                            >
                                <ListItemIcon>
                                    <BookingIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('drawer.pages.booking')} />
                            </ListItem>
                        )}
                    </Can>
                    <Can not I="api.booking_access" ability={ability}>
                        <NoAccess placement="right">
                            {(onClick) => (
                                <ListItem button onClick={onClick} className={classes.listItem}>
                                    <ListItemIcon>
                                        <BookingIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t('drawer.pages.booking')} />
                                    <ListItemIcon>
                                        <PadLockIcon color="inherit" className={classes.lockIcon} />
                                        <UpArrowCircleIcon color="primary" />
                                    </ListItemIcon>
                                </ListItem>
                            )}
                        </NoAccess>
                    </Can>
                    <Can I="api.statistics_access" ability={ability}>
                        <Can I="manage" a="statistics" ability={ability}>
                            <ListItem
                                button
                                to={establishmentBasePath + '/statistics'}
                                component={Link}
                                className={classes.listItem}
                                selected={history.location.pathname === establishmentBasePath + '/statistics'}
                            >
                                <ListItemIcon>
                                    <StatisticsIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('drawer.pages.statistics')} />
                            </ListItem>
                        </Can>
                    </Can>
                    <Can not I="api.statistics_access" ability={ability}>
                        <Can I="manage" a="statistics" ability={ability}>
                            <NoAccess placement="right">
                                {(onClick) => (
                                    <ListItem button onClick={onClick} className={classes.listItem}>
                                        <ListItemIcon>
                                            <StatisticsIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={t('drawer.pages.statistics')} />
                                        <ListItemIcon>
                                            <PadLockIcon color="inherit" className={classes.lockIcon} />
                                            <UpArrowCircleIcon color="primary" />
                                        </ListItemIcon>
                                    </ListItem>
                                )}
                            </NoAccess>
                        </Can>
                    </Can>
                    {establishment.interactiveGameAdmin ? (
                        <ListItem
                            button
                            href={establishment.interactiveGameAdmin}
                            component={MuiLink}
                            className={classes.listItem}
                            target="_blank"
                        >
                            <ListItemIcon>
                                <CardGiftcardIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('drawer.pages.interactiveGameAdmin')} />
                        </ListItem>
                    ) : (
                        <ListItem
                            button
                            href="https://resto.suggpro.com/jeux-pour-restaurant"
                            component={MuiLink}
                            className={classes.listItem}
                            target="_blank"
                        >
                            <ListItemIcon>
                                <CardGiftcardIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('drawer.pages.interactiveGameAdmin')} />
                        </ListItem>
                    )}
                    <ListItem
                        button
                        to={'/tuto'}
                        component={Link}
                        className={classes.listItem}
                        selected={history.location.pathname === '/tuto'}
                    >
                        <ListItemIcon>
                            <HelpOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('drawer.pages.tuto')} />
                    </ListItem>
                    <ListItem
                        button
                        href="https://shop.spreadshirt.fr/onvaauresto/"
                        component={MuiLink}
                        className={classes.listItem}
                        target="_blank"
                    >
                        <ListItemIcon>
                            <StorefrontIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('drawer.pages.store')} />
                    </ListItem>
                </List>
                <List
                    component="nav"
                    subheader={
                        <ListSubheader
                            component={Typography}
                            classes={{
                                root: classes.listTitle,
                            }}
                        >
                            <Trans i18nKey="drawer.settings" />
                        </ListSubheader>
                    }
                >
                    <Can I="confirm" a="account" ability={ability}>
                        <ListItem
                            button
                            to="/account/confirm"
                            component={Link}
                            className={classes.listItem}
                            selected={history.location.pathname === '/account/confirm'}
                        >
                            <ListItemIcon>
                                <AlternateEmailIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('drawer.confirm')} />
                        </ListItem>
                    </Can>
                    <ListItem
                        button
                        to="/account"
                        component={Link}
                        className={classes.listItem}
                        selected={history.location.pathname === '/account'}
                    >
                        <ListItemIcon>
                            <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('drawer.account')} />
                    </ListItem>
                    <ListItem
                        button
                        to={establishmentBasePath + '/slate/setting'}
                        component={Link}
                        className={classes.listItem}
                        selected={history.location.pathname === establishmentBasePath + '/slate/setting'}
                    >
                        <ListItemIcon>
                            <AssignmentIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('drawer.pages.slate')} />
                    </ListItem>
                    <ListItem button className={classes.listItem} onClick={signOut}>
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('drawer.logOut')} />
                    </ListItem>
                </List>
            </div>
        </div>
    )
}

export default memo(AuthenticateDrawer)
