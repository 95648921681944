import React from 'react'
import { CardContent } from '@material-ui/core'
import { useStyles } from './homeContent.css'
import clsx from 'clsx'

interface Props {
    className?: string
}

const HomeContent: React.FC<Props> = ({ children, className }) => {
    const classes = useStyles()
    return <CardContent className={clsx(classes.cardContent, className)}>{children}</CardContent>
}

export default HomeContent
