import React from 'react'
import ReactDOM from 'react-dom'
import 'core-js/es/object'
import 'finally-polyfill'
import 'typeface-open-sans'
import App from './containers/main/app'
import reportWebVitals from './reportWebVitals'
import { compose } from 'redux'
import withBugsnag from './containers/hoc/withBugsnag'

const { REACT_APP_BUGSNAG_ID, REACT_APP_STAGE } = process.env
let enableBugsnag = true
if (!REACT_APP_BUGSNAG_ID || !REACT_APP_STAGE) {
    enableBugsnag = false
}

const WrappedApp = enableBugsnag ? compose(withBugsnag)(App) : App

ReactDOM.render(<WrappedApp />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
