import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            paddingBottom: theme.spacing(2),
        },
        new: {
            marginLeft: theme.spacing(1),
            position: 'relative',
            top: -8,
        },
        titleSoon: {
            marginLeft: theme.spacing(),
        },
        container: {
            position: 'relative',
        },
        socialContainer: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: theme.spacing(-1, 2),
            [theme.breakpoints.down('xs')]: {
                position: 'static',
                flexDirection: 'row',
                margin: theme.spacing(0, 0),
                marginTop: 48 + 8,
            },
        },
        socialItem: {
            margin: theme.spacing(1, 0),
            [theme.breakpoints.down('xs')]: {
                flexGrow: 1,
            },
        },
        actionButtonGroup: {
            flexWrap: 'wrap',
        },
        actionContainer: {
            'marginBottom': theme.spacing(2),
            'display': 'flex',
            'alignItems': 'center',
            'flexWrap': 'wrap',
            '& > .MuiButton-root': {
                margin: theme.spacing(),
            },
        },
        iconButton: {
            'padding': theme.spacing(1),
            'minWidth': 0,
            '&.Mui-disabled': {
                backgroundColor: theme.palette.action.hover,
            },
        },
        icon: {
            width: 48,
            height: 48,
            [theme.breakpoints.down('xs')]: {
                width: 36,
                height: 36,
            },
        },
        graphContainer: {
            position: 'absolute',
            inset: 0,
            paddingRight: theme.spacing(12),
            width: '100%',
            height: '100%',
            minHeight: 240,
            [theme.breakpoints.down('xs')]: {
                paddingRight: 0,
            },
        },
        graphParent: {
            position: 'relative',
            paddingTop: '46%',
            height: 0,
            [theme.breakpoints.down('xs')]: {
                paddingTop: '68%',
            },
        },
        perContainer: {
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
        },
        alert: {
            marginTop: theme.spacing(),
        },
        disclaimer: {
            marginTop: theme.spacing(2),
            [theme.breakpoints.down('xs')]: {
                marginTop: theme.spacing(15),
            },
        },
        soonContainer: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            overflow: 'hidden',
            [theme.breakpoints.down('xs')]: {
                marginBottom: 5,
            },
        },
        soon: {
            fontSize: '3rem',
            transform: 'rotate(-30deg)',
            transformOrigin: '90% 50%',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            marginLeft: -240,
            marginRight: -40,
            textAlign: 'center',
            [theme.breakpoints.down('xs')]: {
                fontSize: '1.4rem',
                marginLeft: -60,
                marginRight: -20,
            },
        },
        refresh: {
            'padding': theme.spacing(0.5, 1.5),
            'height': 'auto',
            '& .MuiChip-label': {
                whiteSpace: 'normal',
            },
            'marginBottom': theme.spacing(2),
        },
        pieChartContainer: {
            width: '100%',
            height: '100%',
            minHeight: 190,
        },
        totalTitle: {
            fontSize: '1.2rem',
            fontWeight: theme.fontsWeight.semiBold,
        },
        totalLabel: {
            textAlign: 'center',
        },
        totalValue: {
            textAlign: 'center',
            fontSize: '1.8rem',
            fontWeight: theme.fontsWeight.semiBold,
            padding: theme.spacing(0.5, 0),
        },
        totalHelper: {
            textAlign: 'center',
            fontSize: '0.8rem',
            fontWeight: theme.fontsWeight.light,
        },
        graphTitle: {
            paddingBottom: theme.spacing(2),
            fontSize: '1.2rem',
            fontWeight: theme.fontsWeight.semiBold,
        },
        alignHeight: {
            [theme.breakpoints.up('sm')]: {
                height: 'calc(100% + 16px)',
            },
        },
        tabs: {
            paddingBottom: theme.spacing(2),
        },
        indicator: {
            'display': 'flex',
            'justifyContent': 'center',
            'backgroundColor': 'transparent',
            '& > span': {
                maxWidth: 80,
                width: '100%',
                backgroundColor: theme.palette.primary.main,
            },
        },
        tab: {
            'flexGrow': 1,
            'textTransform': 'none',
            'fontWeight': theme.fontsWeight.semiBold,
            'fontSize': '1.4rem',
            'marginRight': theme.spacing(1),
            '&:focus': {
                opacity: 1,
            },
        },
        overlay: {
            backgroundColor: 'rgba(0,0,0, 0.7)',
        },
    }),
)
