import { IconButton, Menu, MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import React from 'react'
import { Trans } from 'react-i18next'

interface Props {
    onDeleteConversation?: () => void
    onBlockUser?: () => void
    onDeletePost?: () => void
    onHidePost?: () => void
    onHideReview?: () => void
}

const MenuPane: React.FC<Props> = ({
    onDeleteConversation,
    onBlockUser,
    onDeletePost,
    onHidePost,
    onHideReview,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const menuOpen = Boolean(anchorEl)

    const openMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closeMenu = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <IconButton onClick={openMenu}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorEl={anchorEl}
                keepMounted
                open={menuOpen}
                onClose={closeMenu}
            >
                {onDeleteConversation && (
                    <MenuItem
                        onClick={() => {
                            onDeleteConversation()
                            closeMenu()
                        }}
                    >
                        <Trans i18nKey="views.messaging.deleteConversation.action" />
                    </MenuItem>
                )}
                {onDeletePost && (
                    <MenuItem
                        onClick={() => {
                            onDeletePost()
                            closeMenu()
                        }}
                    >
                        <Trans i18nKey="views.messaging.deletePost.action" />
                    </MenuItem>
                )}
                {onHidePost && (
                    <MenuItem
                        onClick={() => {
                            onHidePost()
                            closeMenu()
                        }}
                    >
                        <Trans i18nKey="views.messaging.hidePost.action" />
                    </MenuItem>
                )}
                {onHideReview && (
                    <MenuItem
                        onClick={() => {
                            onHideReview()
                            closeMenu()
                        }}
                    >
                        <Trans i18nKey="views.messaging.hideReview.action" />
                    </MenuItem>
                )}
                {onBlockUser && (
                    <MenuItem
                        onClick={() => {
                            onBlockUser()
                            closeMenu()
                        }}
                    >
                        <Trans i18nKey="views.messaging.blockUser.action" />
                    </MenuItem>
                )}
            </Menu>
        </>
    )
}

export default MenuPane
