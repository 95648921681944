import AuthenticatedLoading from 'components/layouts/components/authenticatedLoading'
import GoogleMyBusinessAccountDialogView from 'components/views/account/dialogs/googleMyBusinessAccountDialogView'
import SignView from 'components/views/account/signView'
import withAuthenticateLayout, { WithAuthenticateLayout } from 'containers/hoc/withAuthenticateLayout'
import { useFacebookPages } from 'core/services/facebook/useFacebookPages'
import {
    GmbAccountSelect,
    GoogleMyBusinessPageExist,
    MultipleGoogleMyBusinessAccounts,
    useGoogleMyBusinessPages,
} from 'core/services/google/useGoogleMyBusinessPages'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import withFacebookAndGoogle from '../hoc/withFacebookAndGoogle'

interface Props extends WithAuthenticateLayout<false> {}

const Sign: React.FC<Props> = ({ restaurantOwner, establishments }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const { displayError } = useApiErrors()
    const { fetchFacebookPagesAction, inProgress: renewFacebookSignInInProgress } = useFacebookPages()
    const establishment = establishments.length > 0 ? establishments[0] : undefined
    const {
        fetchGoogleMyBusinessPagesAction,
        inProgress: renewGoogleSignInInProgress,
        confirmMultipleGoogleMyBusinessAccountsSelection,
    } = useGoogleMyBusinessPages(establishment)
    const [openGmbAccountSelect, setOpenGmbAccountSelect] = useState<GmbAccountSelect | null>(null)

    const handleRenewFacebookSignIn = () => {
        fetchFacebookPagesAction(true, true)
            .then(() => {
                enqueueSnackbar(t('views.sign.successFacebook'), {
                    variant: 'success',
                })
            })
            .catch((error) => displayError(error))
    }

    const handleRenewGoogleSignIn = () => {
        fetchGoogleMyBusinessPagesAction('sign', restaurantOwner.googleID)
            .then(() => {
                enqueueSnackbar(t('views.sign.successGoogle'), {
                    variant: 'success',
                })
            })
            .catch((error) => {
                if (error instanceof MultipleGoogleMyBusinessAccounts) {
                    setOpenGmbAccountSelect({
                        setOpen: () => {},
                        accounts: error.accounts,
                    })
                } else if (error instanceof GoogleMyBusinessPageExist) {
                    enqueueSnackbar(t('views.sign.successGoogle'), {
                        variant: 'success',
                    })
                } else {
                    displayError(error)
                }
            })
    }

    const handleChooseAccount = (setOpen: (open: boolean) => void, id?: string) => {
        return confirmMultipleGoogleMyBusinessAccountsSelection(id)
            .then(() => {
                setOpen(true)
            })
            .catch((error) => displayError(error))
            .finally(() => {
                setOpenGmbAccountSelect(null)
            })
    }

    return (
        <>
            <SignView
                isFacebookSigned={restaurantOwner.isFacebookSigned}
                isFacebookSignedExpired={restaurantOwner.isFacebookSignedExpired}
                isGoogleSigned={restaurantOwner.isGoogleSigned}
                isGoogleSignedExpired={restaurantOwner.isGoogleSignedExpired}
                onRenewFacebookSignIn={handleRenewFacebookSignIn}
                renewFacebookSignInInProgress={renewFacebookSignInInProgress}
                onRenewGoogleSignIn={handleRenewGoogleSignIn}
                renewGoogleSignInInProgress={renewGoogleSignInInProgress}
            />
            {openGmbAccountSelect && (
                <GoogleMyBusinessAccountDialogView
                    open={openGmbAccountSelect.accounts.length > 0}
                    onClose={() => setOpenGmbAccountSelect(null)}
                    accounts={openGmbAccountSelect.accounts}
                    onChooseAccount={(id?: string) => handleChooseAccount(openGmbAccountSelect.setOpen, id)}
                />
            )}
        </>
    )
}

const Loader: React.FC = () => {
    const { t } = useTranslation()
    return <AuthenticatedLoading title={t('views.sign.title')} loadingMessage={t('common.loading')} />
}

export default withAuthenticateLayout(withFacebookAndGoogle(Sign, <Loader />), false)
