import React, { createRef, useEffect, useState } from 'react'
import lottie, { AnimationConfigWithData, AnimationConfigWithPath, AnimationItem } from 'lottie-web'

interface Props {
    className?: string
    options: Omit<AnimationConfigWithPath, 'container'> | Omit<AnimationConfigWithData, 'container'>
}

const LottieAnimation: React.FC<Props> = ({ className, options }) => {
    const animationContainer = createRef<HTMLDivElement>()
    const [animation, setAnimation] = useState<AnimationItem>()

    useEffect(() => {
        if (animation === undefined && animationContainer.current !== null) {
            const newAnimation = lottie.loadAnimation({
                container: animationContainer.current, // the dom element that will contain the animation
                ...options,
            })
            setAnimation(newAnimation)
        }
    }, [options, animationContainer, animation])

    return <div className={className} ref={animationContainer} />
}

export default LottieAnimation
