import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import { useCallback, useEffect, useState } from 'react'
import { actions, StatisticsRequest, useStatistics } from '@sugg-gestion/ubidreams-react-suggpro'
import { useApiErrors } from '../suggPro/useApiErrors'
import moment from 'moment'

export const useFetchStatistics = (parameters: StatisticsRequest, lastStatisticsFetchAt: Date | null) => {
    const { statistics, newsStatistics, statisticsStartDate, statisticsEndDate } = useSelector(
        ({ suggpro: { establishment }, app }: ApplicationState) => ({
            statistics: establishment.statistics,
            newsStatistics: establishment.newsStatistics,
            statisticsStartDate: app.statisticsStartDate,
            statisticsEndDate: app.statisticsEndDate,
        }),
    )
    const dispatch = useDispatch()
    const { displayError } = useApiErrors()
    const [inProgress, setInProgress] = useState(false)
    const [startDate, setStartDate] = useState(statisticsStartDate)
    const [endDate, setEndDate] = useState(statisticsEndDate)
    const [refreshDate, setRefreshDate] = useState<Date>(
        moment(
            moment(lastStatisticsFetchAt).add(5, 'minutes').add(1, 'seconds').valueOf() - moment().valueOf(),
        ).toDate(),
    )

    const { getStatistics } = useStatistics()

    const updateStatistics = useCallback(() => {
        if (!inProgress) {
            setInProgress(true)
            getStatistics({ ...parameters, startDate: statisticsStartDate, endDate: statisticsEndDate })
                .then((response) => {
                    const newRefreshDate = moment(
                        moment(response.lastStatisticsFetchAt).add(5, 'minutes').add(1, 'seconds').valueOf() -
                            moment().valueOf(),
                    ).toDate()
                    setRefreshDate(newRefreshDate)
                })
                .catch((error) => {
                    dispatch(
                        actions.setStatistics({
                            id: parameters.id,
                            lastStatisticsFetchAt: new Date(),
                            statistics: [],
                            newsStatistics: [],
                        }),
                    )
                    const newRefreshDate = moment(
                        moment().add(20, 'seconds').valueOf() - moment().valueOf(),
                    ).toDate()
                    setRefreshDate(newRefreshDate)
                    displayError(error)
                })
                .finally(() => {
                    setStartDate(statisticsStartDate)
                    setEndDate(statisticsEndDate)
                    setInProgress(false)
                })
        }
    }, [
        dispatch,
        displayError,
        getStatistics,
        inProgress,
        parameters,
        statisticsEndDate,
        statisticsStartDate,
    ])

    useEffect(() => {
        if (
            (statistics === undefined ||
                startDate !== statisticsStartDate ||
                endDate !== statisticsEndDate) &&
            !inProgress
        ) {
            updateStatistics()
        }
    }, [
        inProgress,
        dispatch,
        displayError,
        statistics,
        getStatistics,
        parameters,
        statisticsStartDate,
        statisticsEndDate,
        startDate,
        endDate,
        updateStatistics,
    ])

    useEffect(() => {
        const interval = setInterval(() => {
            if (
                lastStatisticsFetchAt === null ||
                moment(lastStatisticsFetchAt).add(5, 'minutes') < moment()
            ) {
                setRefreshDate(moment(1000).toDate())
            } else {
                const newRefreshDate = moment(refreshDate).add(-1, 'seconds').valueOf()
                if (newRefreshDate > 0) {
                    setRefreshDate(moment(newRefreshDate).toDate())
                }
            }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [lastStatisticsFetchAt, refreshDate])

    useEffect(() => {
        if (refreshDate.valueOf() >= 1000 && refreshDate.valueOf() <= 2000) {
            updateStatistics()
        }
    }, [refreshDate, updateStatistics])

    return { statistics, newsStatistics: newsStatistics, inProgress, refreshDate }
}
