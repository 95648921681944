import React, { useEffect } from 'react'
import withAuthenticateLayout, { WithAuthenticateLayout } from '../hoc/withAuthenticateLayout'
import withDnD from '../hoc/withDnD'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import AuthenticatedLoading from 'components/layouts/components/authenticatedLoading'
import MenuEditView from '../../components/views/menu/editView'
import {
    MenuEntry,
    useCreateMenuEntries,
    useDeleteMenuEntry,
    useEstablishmentMenuEntries,
    useUpdateMenuEntries,
} from '@sugg-gestion/ubidreams-react-suggpro'
import { SortedImage } from 'components/forms/helpers/useSortedImages'
import { MenuFormState } from 'components/forms/menu/menuForm'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'

interface Props extends WithAuthenticateLayout {}

const MenuEdit: React.FC<Props> = ({ establishment }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const history = useHistory()

    const establishmentBasePath = '/establishment/:id'.replace(':id', establishment.id.toString() ?? '')

    const { displayError } = useApiErrors()
    const { isReady, establishmentMenuEntries, error } = useEstablishmentMenuEntries(establishment.id)
    const { updateMenuEntries, inProgress: updateMenuEntriesInProgress } = useUpdateMenuEntries()
    const { createMenuEntries, inProgress: createMenuEntriesInProgress } = useCreateMenuEntries()
    const { deleteMenuEntry, inProgress: deleteMenuEntryInProgress } = useDeleteMenuEntry()

    useEffect(() => {
        if (error !== undefined) {
            displayError(error)
        }
    }, [error, displayError])

    if (!isReady) {
        return <AuthenticatedLoading title={t('views.menu.title')} loadingMessage={t('common.loading')} />
    }

    const sortImagesMenuEntries = (establishmentMenuEntries: Array<MenuEntry>) => {
        const sortedImagesMenuEntries: Array<SortedImage> = []
        establishmentMenuEntries.forEach((menuEntry) => {
            sortedImagesMenuEntries.push({
                id: menuEntry.id,
                url: menuEntry.url,
                sortOrder: menuEntry.sort_order,
            })
        })
        return sortedImagesMenuEntries
    }

    const deleteMenuEntryAction = (entry: MenuEntry) => {
        return deleteMenuEntry({
            id: establishment.id,
            pictureId: entry.id,
        })
            .then(() => {})
            .catch((error) => {
                displayError(error)
            })
    }

    const updateMenuEntriesAction = (sortOrders: Array<number>) => {
        return updateMenuEntries({
            id: establishment.id,
            sortOrders,
        }).catch((error) => {
            displayError(error)
        })
    }

    const reorganizeMenuEntriesAction = (
        addedMenuEntries: Array<MenuEntry>,
        sortedImages: Array<SortedImage>,
    ) => {
        addedMenuEntries = _.sortBy<MenuEntry>(addedMenuEntries, 'id')
        let newSortedImages: Array<SortedImage> = []
        let index = 0
        sortedImages.forEach((sortedImage) => {
            if (sortedImage.file) {
                const addedMenuEntry = addedMenuEntries[index]
                newSortedImages.push({
                    sortOrder: sortedImage.sortOrder,
                    id: addedMenuEntry.id,
                    url: addedMenuEntry.url,
                })
                index++
            } else {
                newSortedImages.push(sortedImage)
            }
        })
        newSortedImages = _.sortBy<SortedImage>(newSortedImages, 'sortOrder')
        const sortOrders = newSortedImages.map((sortedImage) => sortedImage.id)
        return updateMenuEntriesAction(sortOrders)
    }

    const handleUpdateMenu = (values: MenuFormState) => {
        const menuEntries = establishmentMenuEntries
        const sortedImages = values.photos
        const sortedImagesMenuEntries = sortImagesMenuEntries(establishmentMenuEntries)
        const sortedImagesToDelete = _.differenceBy(sortedImagesMenuEntries, sortedImages, 'id')
        const deleteMenuEntries: Array<Promise<void>> = []
        sortedImagesToDelete.forEach((sortedImage) => {
            if (sortedImage.file === undefined) {
                const menuEntry = menuEntries.find((menuEntry) => menuEntry.id === sortedImage.id)
                if (menuEntry) {
                    deleteMenuEntries.push(deleteMenuEntryAction(menuEntry))
                }
            }
        })
        return Promise.all(deleteMenuEntries)
            .then(() => {
                const files: Array<File> = []
                sortedImages.forEach((sortedImage) => {
                    if (sortedImage.file) {
                        files.push(sortedImage.file)
                    }
                })
                if (files.length > 0) {
                    return createMenuEntries({
                        id: establishment.id,
                        files: files,
                    })
                        .then((response) => {
                            let addedMenuEntries = _.differenceBy<MenuEntry, MenuEntry>(
                                response.menuEntries,
                                establishmentMenuEntries,
                                'id',
                            )
                            return reorganizeMenuEntriesAction(addedMenuEntries, sortedImages)
                        })
                        .catch((error) => {
                            console.error(error)
                            displayError(error)
                        })
                } else {
                    return reorganizeMenuEntriesAction([], sortedImages)
                }
            })
            .then(() => {
                handleGoBack()
                enqueueSnackbar(t('views.menu.success'), {
                    variant: 'success',
                })
            })
    }

    const handleGoBack = () => {
        history.push(establishmentBasePath + '/menu')
    }

    return (
        <MenuEditView
            initialValues={{
                photos: sortImagesMenuEntries(establishmentMenuEntries),
            }}
            inProgress={
                updateMenuEntriesInProgress || createMenuEntriesInProgress || deleteMenuEntryInProgress
            }
            onBack={handleGoBack}
            onSubmit={handleUpdateMenu}
        />
    )
}

export default withAuthenticateLayout(withDnD(MenuEdit))
