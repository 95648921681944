import React from 'react'
import { SubmitButtonProps } from '../../common/submitButton'
import { useFormsStyles } from '../forms.css'
import { Form } from 'react-final-form'
import { FormControl, Grid, Typography } from '@material-ui/core'
import { Trans, useTranslation } from 'react-i18next'
import { SlateImage } from '@sugg-gestion/ubidreams-react-suggpro'
import clsx from 'clsx'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import ShareImage from './components/shareImage'
import ImageCard from '../../common/imageCard'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: ShareImagesFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: ShareImagesFormState
    showInstagram: boolean
    showTwitter: boolean
    showFacebook: boolean
    showGoogle: boolean
}

export interface ShareImagesFormState {
    photos?: Array<SlateImage>
}

export interface ShareImagesFormError {
    photo?: string
}

enum SocialNetwork {
    Facebook = 'Facebook',
    Instagram = 'Instagram',
    Google = 'Google',
    Twitter = 'Twitter',
}

const ShareImagesForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
        errors: true,
        values: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
    showInstagram,
    showTwitter,
    showFacebook,
    showGoogle,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const validate = (values: ShareImagesFormState) => {
        const errors: ShareImagesFormError = {}

        const countSharedImages = (socialNetwork: SocialNetwork) => {
            const sharedImages = values.photos?.filter((image) => {
                // @ts-ignore
                return image['is' + socialNetwork + 'Shared']
            })
            return sharedImages?.length ?? 0
        }

        if (values.photos) {
            let count = 0
            if (showFacebook) {
                count = countSharedImages(SocialNetwork.Facebook)
            }
            if (showInstagram) {
                count = countSharedImages(SocialNetwork.Instagram)
            }
            if (showGoogle) {
                count = countSharedImages(SocialNetwork.Google)
            }
            if (showTwitter) {
                count = countSharedImages(SocialNetwork.Twitter)
                if (count > 4) {
                    errors.photo = t('form-validation.slatePhotoTwitterMax')
                }
            }
            if (count === 0) {
                errors.photo = t('form-validation.slatePhotoShare')
            }
        }
        return errors
    }

    const internalInitialValues = (): ShareImagesFormState => {
        const images = initialValues.photos?.map((image) => {
            return {
                ...image,
                isFacebookShared: showFacebook ? true : image.isFacebookShared,
                isInstagramShared: showInstagram ? true : image.isInstagramShared,
                isGoogleShared: showGoogle ? true : image.isGoogleShared,
                isTwitterShared: showTwitter ? true : image.isTwitterShared,
            }
        })
        return {
            photos: images,
        }
    }

    return (
        <Form
            initialValues={internalInitialValues()}
            subscription={subscription}
            // debug={console.log}
            validate={validate}
            onSubmit={onSubmit}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting, errors, values, form: { mutators } }) => {
                const submit = (event: any) => {
                    handleSubmit(event)
                    if (errors && !_.isEmpty(errors)) {
                        const errorEntries = Object.entries(errors)
                        if (errorEntries.length === 1) {
                            for (const [, value] of errorEntries) {
                                enqueueSnackbar(value, {
                                    variant: 'error',
                                })
                            }
                        } else {
                            enqueueSnackbar(t('form-validation.common'), {
                                variant: 'error',
                            })
                        }
                    }
                }
                return (
                    <form onSubmit={submit} noValidate className={formClassName}>
                        {children(
                            <>
                                {/* PHOTO */}
                                <Typography
                                    color="textSecondary"
                                    className={clsx(formsClasses.formTitle, formsClasses.noPaddingTop, {
                                        [formsClasses.error]: false,
                                    })}
                                >
                                    <Trans
                                        i18nKey="form.photosImage"
                                        values={{ count: values.photos?.length ?? 0 }}
                                    />
                                </Typography>
                                <FormControl margin="normal" error={false} fullWidth>
                                    <Grid container spacing={2}>
                                        {values.photos?.map((image, index) => (
                                            <Grid item key={index} xl={2} lg={3} sm={4} xs={6}>
                                                <ImageCard index={0} url={image.picture} imageWidth={400}>
                                                    {/*  SHARING  */}
                                                    <ShareImage
                                                        xs={12}
                                                        showText
                                                        id={index}
                                                        showTwitter={showTwitter}
                                                        showInstagram={showInstagram}
                                                        showFacebook={showFacebook}
                                                        showGoogle={showGoogle}
                                                    />
                                                </ImageCard>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </FormControl>
                            </>,
                            [
                                {
                                    type: 'submit',
                                    loading: submitting || inProgress,
                                    className: formsClasses.submit,
                                    children: <Trans i18nKey="actions.validate" />,
                                },
                            ],
                        )}
                    </form>
                )
            }}
        </Form>
    )
}

export default ShareImagesForm
