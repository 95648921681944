import { applyMiddleware, compose, Middleware } from 'redux'
import loggerMiddleware from 'redux-logger'
import { routerMiddleware } from 'connected-react-router'
import { History } from 'history'

export const createMiddleware = (history: History, sagaMiddleware: Middleware) => {
    let middleware: Array<Middleware> = []
    middleware = [...middleware, routerMiddleware(history)]
    middleware = [...middleware, sagaMiddleware]
    if (process.env.REACT_APP_LOGGER === 'on') {
        middleware = [...middleware, loggerMiddleware]
    }
    return compose(applyMiddleware(...middleware))
}
