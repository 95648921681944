export const useNominatim = () => {
    const getReverseGeocodeUrl = (latitude: number, longitude: number) => {
        return `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
    }

    const reverseGeocode = (latitude: number, longitude: number) => {
        return fetch(getReverseGeocodeUrl(latitude, longitude)).then((response) => response.json())
    }

    const getGeocodeUrl = (street?: string, city?: string, country?: string) => {
        let url = `https://nominatim.openstreetmap.org/?format=jsonv2`
        if (street) {
            street = encodeURIComponent(street)
            url += '&street=' + street
        }
        if (city) {
            city = encodeURIComponent(city)
            url += '&city=' + city
        }
        if (country) {
            country = encodeURIComponent(country)
            url += '&country=' + country
        }

        return url
    }

    const geocode = (street?: string, city?: string, country?: string) => {
        console.log('geocode')
        return fetch(getGeocodeUrl(street, city, country)).then((response) => response.json())
    }

    return { geocode, reverseGeocode }
}
