import { AnyAbility } from '@casl/ability'
import { Button, Container, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import DoneIcon from '@material-ui/icons/Done'
import WarningIcon from '@material-ui/icons/Warning'
import { Alert } from '@material-ui/lab'
import { DayOfWeek, Establishment, SpecialClosing } from '@sugg-gestion/ubidreams-react-suggpro'
import { EstablishmentType } from '@sugg-gestion/ubidreams-react-suggpro/src/resources/establishment/types'
import clsx from 'clsx'
import { useAddresses } from 'components/forms/helpers/useAddresses'
import { useBusinessHours } from 'components/forms/helpers/useBusinessHours'
import parsePhoneNumberFromString from 'libphonenumber-js'
import moment from 'moment'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ImageCard from '../../common/imageCard'
import AuthenticateContent from '../../layouts/components/authenticateContent'
import { useStyles } from './establishmentEditView.css'

interface Props {
    establishment: Establishment
    establishmentTypes?: Array<EstablishmentType>
    onEditSocialNetworks: () => void
    onEditDetails: () => void
    onEditFoodType: () => void
    onEditPosition: () => void
    onEditHours: () => void
    onAddSpecialClosing: () => void
    onEditPhotos: () => void
    onEditFacebookGoogle: () => void
    onDeleteSpecialClosing: (specialClosing: SpecialClosing) => void
    ability: AnyAbility
    withTwitter: boolean
}

const EstablishmentEditView: React.FC<Props> = ({
    establishment,
    establishmentTypes,
    onEditSocialNetworks,
    onEditDetails,
    onEditFoodType,
    onEditPosition,
    onEditHours,
    onAddSpecialClosing,
    onEditPhotos,
    onEditFacebookGoogle,
    onDeleteSpecialClosing,
    withTwitter,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

    const { showAddress } = useAddresses()
    const { getDayString, getHoursDisplay, hoursByDay } = useBusinessHours(establishment)

    const displaySection = (
        title: string | React.ReactNode,
        value: React.ReactNode,
        withTypography: boolean = true,
    ) => {
        return (
            <Grid container className={classes.section}>
                <Grid item xl={5} sm={4} xs={12}>
                    <Typography className={classes.sectionTitle}>{title}</Typography>
                </Grid>
                <Grid item xl={7} sm={8} xs={12} className={classes.sectionContentContainer}>
                    {withTypography ? (
                        <Typography className={classes.sectionContent}>{value}</Typography>
                    ) : (
                        value
                    )}
                </Grid>
            </Grid>
        )
    }
    const phone = parsePhoneNumberFromString(establishment.phoneNumber ?? '', 'FR')

    let type = ''
    if (establishment.type) {
        if (typeof establishment.type !== 'number') {
            type = establishment.type.name
        } else {
            type = establishmentTypes?.find((type) => type.id === establishment.type)?.name ?? ''
        }
    }

    return (
        <AuthenticateContent withAction={false}>
            {/* Social networks */}
            <div className={classes.mainSection}>
                <Container disableGutters maxWidth="sm" className={classes.root}>
                    <Typography variant="h1" className={classes.title}>
                        <Trans i18nKey="views.establishment.facebookGoogle.title" />
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.action}
                            onClick={onEditFacebookGoogle}
                        >
                            <Trans i18nKey="actions.edit" />
                        </Button>
                    </Typography>
                </Container>
                <Grid container className={classes.section}>
                    <Grid item sm={12} xs={12}>
                        <Typography className={classes.sectionTitle}>
                            <Trans i18nKey="form.googlePage" />
                            <Button variant="contained" disabled className={classes.iconButton}>
                                {establishment.googleId ? (
                                    <DoneIcon className={clsx(classes.icon, classes.iconSuccess)} />
                                ) : (
                                    <WarningIcon color="primary" className={classes.icon} />
                                )}
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={clsx(classes.section)}>
                    <Grid item sm={12} xs={12}>
                        <Typography className={classes.sectionTitle}>
                            <Trans i18nKey="form.facebook" />
                            <Button variant="contained" disabled className={classes.iconButton}>
                                {establishment.facebookPageId ? (
                                    <DoneIcon className={clsx(classes.icon, classes.iconSuccess)} />
                                ) : (
                                    <WarningIcon color="primary" className={classes.icon} />
                                )}
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={clsx(classes.section)}>
                    <Grid item sm={12} xs={12}>
                        <Typography className={classes.sectionTitle}>
                            <Trans i18nKey="form.instagram" />
                            <Button variant="contained" disabled className={classes.iconButton}>
                                {establishment.instagramPageId ? (
                                    <DoneIcon className={clsx(classes.icon, classes.iconSuccess)} />
                                ) : (
                                    <WarningIcon color="primary" className={classes.icon} />
                                )}
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
                {withTwitter && (
                    <Grid container className={clsx(classes.section)}>
                        <Grid item sm={12} xs={12}>
                            <Typography className={classes.sectionTitle}>
                                <Trans i18nKey="form.twitter" />
                                <Button variant="contained" disabled className={classes.iconButton}>
                                    {establishment.twitterID ? (
                                        <DoneIcon className={clsx(classes.icon, classes.iconSuccess)} />
                                    ) : (
                                        <WarningIcon color="primary" className={classes.icon} />
                                    )}
                                </Button>
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </div>

            {/* Social networks */}
            <div className={classes.mainSection}>
                <Container disableGutters maxWidth="sm" className={classes.root}>
                    <Typography variant="h1" className={classes.title}>
                        <Trans i18nKey="views.establishment.socialNetworks.title" />
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.action}
                            onClick={onEditSocialNetworks}
                        >
                            <Trans i18nKey="actions.edit" />
                        </Button>
                    </Typography>
                </Container>
                {displaySection(t('form.website'), establishment.website)}
                {displaySection(t('form.facebook'), establishment.facebookUrl)}
                {displaySection(t('form.instagram'), establishment.instagramUrl)}
                {displaySection(t('form.mealAndMeet'), establishment.mealandmeetUrl)}
                {displaySection(t('form.booking'), establishment.booking)}
                {displaySection(t('form.delivery'), establishment.delivery)}
                {displaySection(t('form.interactiveGame'), establishment.interactiveGame)}
            </div>

            {/* Establishment */}
            <div className={classes.mainSection}>
                <Container disableGutters maxWidth="sm" className={classes.root}>
                    <Typography variant="h1" className={classes.title}>
                        <Trans i18nKey="views.establishment.details.title" />
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.action}
                            onClick={onEditDetails}
                        >
                            <Trans i18nKey="actions.edit" />
                        </Button>
                    </Typography>
                </Container>
                {displaySection(t('form.establishmentName'), establishment.name)}
                {displaySection(t('form.duns'), establishment.DUNS)}
                {displaySection(t('form.establishmentEmail'), establishment.email)}
                {displaySection(t('form.establishmentPhone'), phone?.formatNational() ?? null)}
                {displaySection(t('form.description'), establishment.description)}
                {displaySection(
                    t('form.shortDescription'),
                    establishment.shortDescription ? (
                        establishment.shortDescription
                    ) : (
                        <span className={classes.reuseDescription}>
                            <Trans i18nKey="views.establishment.details.reuseDescription" />
                        </span>
                    ),
                )}
                {displaySection(t('form.establishmentType'), type)}
                {displaySection(
                    <Trans
                        i18nKey="form.remplisVertShort"
                        components={[<span className={classes.remplisVert} />]}
                    />,
                    <>
                        {establishment.remplisVert ? (
                            <DoneIcon className={clsx(classes.icon, classes.iconSuccess)} />
                        ) : (
                            <CloseIcon color="primary" className={classes.icon} />
                        )}
                    </>,
                )}
                {establishment.remplisVertComment &&
                    displaySection(t('form.remplisVertCommentShort'), establishment.remplisVertComment)}
            </div>

            {/* Food Type */}
            <div className={classes.mainSection}>
                <Container disableGutters maxWidth="sm" className={classes.root}>
                    <Typography variant="h1" className={classes.title}>
                        <Trans i18nKey="views.establishment.foodType.subtitle" />
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.action}
                            onClick={onEditFoodType}
                        >
                            <Trans i18nKey="actions.edit" />
                        </Button>
                    </Typography>
                </Container>
                {displaySection(
                    t('form.foodTypes'),
                    establishment.foodType?.map((foodType) => foodType + '\n'),
                )}
            </div>

            {/* Location */}
            <div className={classes.mainSection}>
                <Container disableGutters maxWidth="sm" className={classes.root}>
                    <Typography variant="h1" className={classes.title}>
                        <Trans i18nKey="views.establishment.location.title" />
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.action}
                            onClick={onEditPosition}
                        >
                            <Trans i18nKey="actions.edit" />
                        </Button>
                    </Typography>
                </Container>
                {displaySection(t('form.establishmentAddress'), showAddress(establishment.completeAddress))}
            </div>

            {/* Hours */}
            <div className={classes.mainSection}>
                <Container disableGutters maxWidth="sm" className={classes.root}>
                    <Typography variant="h1" className={classes.title}>
                        <Trans i18nKey="views.establishment.hours.title" />
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.action}
                            onClick={onEditHours}
                        >
                            <Trans i18nKey="actions.edit" />
                        </Button>
                    </Typography>
                </Container>
                {displaySection(
                    t('form.' + getDayString(DayOfWeek.Monday)),
                    getHoursDisplay(hoursByDay[DayOfWeek.Monday]),
                    false,
                )}
                {displaySection(
                    t('form.' + getDayString(DayOfWeek.Tuesday)),
                    getHoursDisplay(hoursByDay[DayOfWeek.Tuesday]),
                    false,
                )}
                {displaySection(
                    t('form.' + getDayString(DayOfWeek.Wednesday)),
                    getHoursDisplay(hoursByDay[DayOfWeek.Wednesday]),
                    false,
                )}
                {displaySection(
                    t('form.' + getDayString(DayOfWeek.Thursday)),
                    getHoursDisplay(hoursByDay[DayOfWeek.Thursday]),
                    false,
                )}
                {displaySection(
                    t('form.' + getDayString(DayOfWeek.Friday)),
                    getHoursDisplay(hoursByDay[DayOfWeek.Friday]),
                    false,
                )}
                {displaySection(
                    t('form.' + getDayString(DayOfWeek.Saturday)),
                    getHoursDisplay(hoursByDay[DayOfWeek.Saturday]),
                    false,
                )}
                {displaySection(
                    t('form.' + getDayString(DayOfWeek.Sunday)),
                    getHoursDisplay(hoursByDay[DayOfWeek.Sunday]),
                    false,
                )}
            </div>
            {/* Special Closing */}
            <div className={classes.mainSection}>
                <Container disableGutters maxWidth="sm" className={classes.root}>
                    <Typography variant="h1" className={classes.title}>
                        <Trans i18nKey="views.establishment.specialClosing.title" />
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.action}
                            onClick={onAddSpecialClosing}
                        >
                            <Trans i18nKey="actions.add" />
                        </Button>
                    </Typography>
                </Container>
                {establishment.specialClosing?.map((closing, index) => (
                    <React.Fragment key={index}>
                        {displaySection(
                            t(closing.label),
                            <Grid container spacing={fullScreen ? 2 : 4} alignItems={'center'}>
                                <Grid item xs={6}>
                                    <Typography className={classes.textCenter}>
                                        <Trans
                                            i18nKey="views.establishment.edit.dates"
                                            values={{
                                                dateStart: moment(closing.dateStart).toDate(),
                                                dateEnd: moment(closing.dateEnd).toDate(),
                                            }}
                                            components={[<span className={classes.date} />]}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={() => onDeleteSpecialClosing(closing)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>,
                            false,
                        )}
                    </React.Fragment>
                ))}
                {(establishment.specialClosing === undefined ||
                    establishment.specialClosing.length === 0) && (
                    <Grid container spacing={fullScreen ? 2 : 4}>
                        <Grid item xs={12}>
                            <Typography>
                                <Trans i18nKey="views.establishment.specialClosing.empty" />
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </div>
            {/* Photos */}
            <div className={classes.mainSection}>
                <Container disableGutters maxWidth="sm" className={classes.root}>
                    <Typography variant="h1" className={classes.title}>
                        <Trans i18nKey="views.establishment.photos.title" />
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.action}
                            onClick={onEditPhotos}
                        >
                            <Trans i18nKey="actions.edit" />
                        </Button>
                    </Typography>
                </Container>
                <Grid container spacing={fullScreen ? 2 : 4}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Alert severity="info">
                                    <Trans
                                        i18nKey="views.establishment.photos.info"
                                        components={[<strong />]}
                                    />
                                </Alert>
                            </Grid>
                        </Grid>
                    </Grid>
                    {establishment.pictures?.map((photo, index) => (
                        <Grid item key={index} lg={2} sm={3} xs={4}>
                            <Grid
                                container
                                className={clsx(classes.mainPictureContainer, {
                                    [classes.mainPictureContainerVisible]: photo.isMainPicture,
                                })}
                            >
                                <Grid item xs={12}>
                                    <Typography
                                        className={clsx(classes.mainPicture, {
                                            [classes.mainPictureVisible]: photo.isMainPicture,
                                        })}
                                    >
                                        <Trans i18nKey="views.establishment.photos.main" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <ImageCard url={photo.url} index={index} />
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                    {(establishment.pictures === undefined || establishment.pictures.length === 0) && (
                        <Grid item xs={12}>
                            <Typography>
                                <Trans i18nKey="views.establishment.photos.empty" />
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </div>
        </AuthenticateContent>
    )
}

export default EstablishmentEditView
