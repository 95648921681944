import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        icon: {
            width: theme.spacing(12),
            height: theme.spacing(12),
        },
        title: {
            fontSize: '2rem',
            textAlign: 'center',
            margin: theme.spacing(2, 0),
        },
        subtitle: {
            margin: theme.spacing(2, 0),
            fontSize: '1.2rem',
            textAlign: 'center',
        },
        action: {
            margin: theme.spacing(2, 0),
        },
    }),
)
