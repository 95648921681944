import * as React from 'react'
import { useEffect, useRef } from 'react'
import useStyles from './hubspotForm.css'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../core/store/reducers'
import { SocialWindow } from '../../types/window'

interface Props {
    formId: string
    onFormReady?: (form: HTMLFormElement) => void
}

declare let window: SocialWindow

const HubspotForm: React.FC<Props> = ({ formId, onFormReady = () => {} }) => {
    const classes = useStyles()
    const { hubSpotFormInitialized } = useSelector(({ app }: ApplicationState) => ({
        hubSpotFormInitialized: app.hubSpotFormInitialized,
    }))
    const formRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (hubSpotFormInitialized) {
            const element = formRef.current
            if (element != null) {
                element.innerHTML = ''
                const tag = document.createElement('script')
                window.hubSpotCallback = onFormReady
                window.jQuery =
                    window.jQuery ||
                    function (nodeOrSelector: any) {
                        if (typeof nodeOrSelector == 'string') {
                            return document.querySelector(nodeOrSelector)
                        }
                        return nodeOrSelector
                    }
                tag.innerHTML =
                    'hbspt.forms.create({\n' +
                    '        portalId: "' +
                    process.env.REACT_APP_HUBSPOT_PORTAL_ID +
                    '",\n' +
                    '        formId: "' +
                    formId +
                    '",\n' +
                    '        onFormReady: window.hubSpotCallback' +
                    '\n' +
                    '    });'
                element.appendChild(tag)
            }
        }
    }, [formId, hubSpotFormInitialized, onFormReady])
    return <div ref={formRef} className={classes.root} />
}

export default HubspotForm
