import React from 'react'
import { useStyles } from './authenticateContent.css'
import clsx from 'clsx'

interface Props {
    className?: string
    withAction?: boolean
}

const AuthenticateContent: React.FC<Props> = ({ children, className, withAction = true }) => {
    const classes = useStyles()
    return (
        <div
            className={clsx(
                classes.cardContent,
                {
                    [classes.withAction]: withAction,
                },
                className,
            )}
        >
            {children}
        </div>
    )
}

export default AuthenticateContent
