import React from 'react'
import withSignUpLayout, { WithSignUpLayout } from 'containers/hoc/withSignUpLayout'
import { useDispatch } from 'react-redux'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import { useEstablishmentUpdate, useSlateCategories } from '@sugg-gestion/ubidreams-react-suggpro'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { redirectToEstablishment } from 'core/store/app/actions'
import _ from 'lodash'
import EstablishmentFoodTypeView from 'components/views/establishment/establishmentFoodTypeView'
import { FoodTypeFormState } from 'components/forms/establishment/foodTypeForm'

interface Props extends WithSignUpLayout {
    step?: number
    steps?: number
}

const EstablishmentAddFoodType: React.FC<Props> = ({ establishment, step = 4, steps = 8 }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const { displayError } = useApiErrors()
    const { updateEstablishment, inProgress } = useEstablishmentUpdate()
    const { updateEstablishment: updateToPreviousStatus, inProgress: updateToPreviousStatusInProgress } =
        useEstablishmentUpdate()
    const { categories } = useSlateCategories()

    const handleUpdateFoodType = (values: FoodTypeFormState) => {
        updateEstablishment({
            id: establishment.id,
            establishment: {
                ...values,
            },
            nextStatus: 'LOCATION',
        })
            .then(() => {
                enqueueSnackbar(t('views.establishment.foodType.success'), {
                    variant: 'success',
                })
                dispatch(redirectToEstablishment())
            })
            .catch((error) => displayError(error))
    }

    const handlePrevious = () => {
        updateToPreviousStatus({
            id: establishment.id,
            establishment: {},
            nextStatus: 'ESTABLISHMENT',
        })
            .then(() => {
                dispatch(redirectToEstablishment())
            })
            .catch((error) => displayError(error))
    }

    return (
        <EstablishmentFoodTypeView
            step={step}
            steps={steps}
            onSubmit={handleUpdateFoodType}
            inProgress={inProgress}
            initialValues={{
                foodType: _.defaultTo(establishment.foodType, []),
            }}
            categories={_.filter(
                categories,
                (category) =>
                    category.name !== 'Sur place' &&
                    category.name !== 'A emporter' &&
                    category.name !== 'Livraison',
            )}
            previousInProgress={updateToPreviousStatusInProgress}
            onPrevious={handlePrevious}
        />
    )
}

export default withSignUpLayout(EstablishmentAddFoodType)
