import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            width: 320,
        },
        value: {
            flexGrow: 1,
        },
    }),
)
