import React from 'react'
import { history } from 'core/config/history'

const withHistory = (ComposedComponent: any) => {
    const WithHistory: React.FC = (props) => {
        return <ComposedComponent history={history} {...props} />
    }
    return WithHistory
}

export default withHistory
