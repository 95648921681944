import { createStyles, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        videoContentContainer: {
            position: 'relative',
            outline: 0,
        },
    }),
)

export default useStyles
