import React from 'react'
import { useFormsStyles } from '../forms.css'
import { Form, FormSpy } from 'react-final-form'
import { SubmitButtonProps } from 'components/common/submitButton'
import { Trans, useTranslation } from 'react-i18next'
import CodeField from '../components/codeField'
import PasswordField from '../components/passwordField'
import PasswordRules from '../components/passwordRules'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: ForgottenPasswordCodeFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: ForgottenPasswordCodeFormState
}

export interface ForgottenPasswordCodeFormState {
    code: string
    password: string
    passwordConfirmation: string
}

interface ForgottenPasswordCodeFormErrors {
    code?: string
    password?: string
    passwordConfirmation?: string
}

const ForgottenPasswordCodeForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()

    const strongPasswordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\\W)(?=.{8,})')
    const validate = (values: ForgottenPasswordCodeFormState) => {
        const errors: ForgottenPasswordCodeFormErrors = {}

        if (!values.code) {
            errors.code = t('form-validation.code')
        } else if (values.code.length !== 6) {
            errors.code = t('form-validation.codeLength')
        }

        if (!values.password) {
            errors.password = t('form-validation.password')
        } else if (!strongPasswordRegex.test(values.password)) {
            errors.password = t('form-validation.passwordStrength')
        }

        if (!values.passwordConfirmation) {
            errors.passwordConfirmation = t('form-validation.passwordConfirmation')
        } else if (values.password !== values.passwordConfirmation) {
            errors.passwordConfirmation = t('form-validation.passwordConfirmationNotMatch')
        }

        return errors
    }
    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <div>
                            <CodeField name="code" />
                            <PasswordField
                                name="password"
                                label={t('form.password')}
                                autoComplete="new-password"
                            />
                            <FormSpy subscription={{ values: true }}>
                                {(props) => <PasswordRules value={props.values.password} />}
                            </FormSpy>
                            <PasswordField
                                name="passwordConfirmation"
                                label={t('form.passwordConfirmation')}
                                autoComplete="new-password"
                            />
                        </div>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                className: formsClasses.submit,
                                children: <Trans i18nKey="actions.continue" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}
export default ForgottenPasswordCodeForm
