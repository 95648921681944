import SignUpContent from 'components/layouts/components/signUpContent'
import React from 'react'
import EstablishmentCreateView from '../establishmentCreateView'
import Loader from '../../../common/loader'
import { Typography } from '@material-ui/core'
import { useStyles as useGlobalStyles } from '../styles.css'

interface Props {
    step: number
    steps: number
    loadingMessage: string
    title: string
}

const SignUpLoading: React.FC<Props> = ({ step, steps, loadingMessage, title }) => {
    const classes = useGlobalStyles()
    return (
        <SignUpContent>
            <EstablishmentCreateView step={step} steps={steps}>
                <Typography variant="h1" color="textSecondary" className={classes.title}>
                    {title}
                </Typography>
                <div className={classes.loadingView}>
                    <Loader withLogo={false} text={loadingMessage} />
                </div>
            </EstablishmentCreateView>
        </SignUpContent>
    )
}

export default SignUpLoading
