import React from 'react'
import AuthenticateContent from 'components/layouts/components/authenticateContent'
import { Button, Typography } from '@material-ui/core'
import EstablishmentCreateView from './establishmentCreateView'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Trans } from 'react-i18next'
import { useStyles } from './establishmentCompletedView.css'
import { Link } from 'react-router-dom'
import { Establishment } from '@sugg-gestion/ubidreams-react-suggpro'

interface Props {
    step: number
    steps: number
    establishment: Establishment
}

const EstablishmentCompletedView: React.FC<Props> = ({ step, steps, establishment }) => {
    const classes = useStyles()
    const establishmentBasePath = '/establishment/:id'.replace(':id', establishment.id.toString() ?? '')

    return (
        <EstablishmentCreateView step={step} steps={steps}>
            <AuthenticateContent className={classes.root} withAction={false}>
                <div>
                    <CheckCircleIcon color="primary" className={classes.icon} />
                </div>
                <div>
                    <Typography variant="h1" color="primary" className={classes.title}>
                        <Trans i18nKey="views.establishment.completed.title" />
                    </Typography>
                    <Typography color="primary" className={classes.subtitle}>
                        <Trans i18nKey="views.establishment.completed.subtitle" />
                    </Typography>
                </div>
                <div>
                    <Button
                        color={'primary'}
                        variant="outlined"
                        component={Link}
                        to={establishmentBasePath + '/slate'}
                        className={classes.button}
                    >
                        <Trans i18nKey="actions.addSlate" />
                    </Button>
                </div>
            </AuthenticateContent>
        </EstablishmentCreateView>
    )
}

export default EstablishmentCompletedView
