import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // backgroundColor: 'white',
            padding: 4,
        },
    }),
)
