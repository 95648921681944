import React from 'react'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useStyles } from './closeButton.css'

interface Props {
    onClose: () => void
}

const CloseButton: React.FC<Props> = ({ onClose }) => {
    const classes = useStyles()
    return (
        <IconButton className={classes.button} onClick={onClose}>
            <CloseIcon />
        </IconButton>
    )
}

export default CloseButton
