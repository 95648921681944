import React from 'react'
import withAuthenticateLayout, { WithAuthenticateLayout } from 'containers/hoc/withAuthenticateLayout'
import StatisticsView from 'components/views/statistics/statisticsView'
import { useFetchStatistics } from 'core/services/statistics/useFetchStatistics'
import Loader from 'components/common/loader'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import withMuiPickersUtilsProvider from '../hoc/withMuiPickersUtilsProvider'
import { setStatisticsEndDate, setStatisticsStartDate } from 'core/store/app/actions'
import StatisticsPermissions from './permissions'

interface Props extends WithAuthenticateLayout {}

const Statistics: React.FC<Props> = ({ establishment, restaurantOwner, ability }) => {
    const dispatch = useDispatch()
    const { statisticsStartDate, statisticsEndDate } = useSelector(({ app }: ApplicationState) => ({
        statisticsStartDate: app.statisticsStartDate,
        statisticsEndDate: app.statisticsEndDate,
    }))

    const { statistics, newsStatistics, inProgress, refreshDate } = useFetchStatistics(
        {
            id: establishment.id,
        },
        establishment.lastStatisticsFetchAt,
    )
    const handleUpdateStartDate = (date: Date) => {
        dispatch(setStatisticsStartDate(date))
    }
    const handleUpdateEndDate = (date: Date) => {
        dispatch(setStatisticsEndDate(date))
    }
    const shouldAskPermissions = () => {
        // Facebook
        if (
            restaurantOwner.isFacebookSigned &&
            restaurantOwner.facebookScopes.indexOf('read_insights') === -1
        ) {
            return true
        }
        // Instagram
        else if (
            establishment.instagramPageId &&
            restaurantOwner.facebookScopes.indexOf('instagram_manage_insights') === -1
        ) {
            return true
        }
        return false
    }
    if (statistics && newsStatistics) {
        return (
            <>
                {shouldAskPermissions() && (
                    <StatisticsPermissions restaurantOwner={restaurantOwner} establishment={establishment} />
                )}
                <StatisticsView
                    statistics={statistics}
                    newsStatistics={newsStatistics}
                    startDate={statisticsStartDate}
                    endDate={statisticsEndDate}
                    updateStartDate={handleUpdateStartDate}
                    updateEndDate={handleUpdateEndDate}
                    loading={inProgress}
                    isFacebookLinked={!!establishment.facebookPageId}
                    isInstagramLinked={!!establishment.instagramPageId}
                    isGoogleLinked={!!establishment.googleId}
                    isTwitterLinked={!!establishment.twitterID}
                    refreshDate={refreshDate}
                    ability={ability}
                />
            </>
        )
    } else {
        return <Loader withLogo={false} />
    }
}

export default withMuiPickersUtilsProvider(withAuthenticateLayout(Statistics))
