import { Card, CardContent, CardMedia, InputAdornment, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid/Grid'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import clsx from 'clsx'
import { SubmitButtonProps } from 'components/common/submitButton'
import React from 'react'
import { Form, FormSpy } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { useImgix } from '../../../core/services/imgix/useImgix'
import CheckboxField from '../components/checkboxField'
import ImageField from '../components/imageField'
import SelectField from '../components/selectField'
import TextField from '../components/textField'
import { useFormsStyles } from '../forms.css'
import { Image } from '../helpers/useImages'
import { useStyles } from './websiteCustomizationForm.css'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: WebsiteCustomizationFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: WebsiteCustomizationFormState
}

export interface WebsiteCustomizationFormState {
    backgroundColorLight?: string
    backgroundColorDark?: string
    font?: string

    titleSectionTextColor?: string
    titleSectionBackgroundColor?: string
    titleSectionTextColorEnabled: boolean[]
    titleSectionBackgroundColorEnabled: boolean[]
    titleSectionBackgroundImage?: Image

    slateSectionTextColor?: string
    slateSectionBackgroundColor?: string
    slateSectionTextColorEnabled: boolean[]
    slateSectionBackgroundColorEnabled: boolean[]
    slateSectionBackgroundImage?: Image

    menuSectionTextColor?: string
    menuSectionBackgroundColor?: string
    menuSectionTextColorEnabled: boolean[]
    menuSectionBackgroundColorEnabled: boolean[]
    menuSectionBackgroundImage?: Image

    newsSectionTextColor?: string
    newsSectionBackgroundColor?: string
    newsSectionTextColorEnabled: boolean[]
    newsSectionBackgroundColorEnabled: boolean[]
    newsSectionBackgroundImage?: Image

    aboutSectionTextColor?: string
    aboutSectionBackgroundColor?: string
    aboutSectionTextColorEnabled: boolean[]
    aboutSectionBackgroundColorEnabled: boolean[]
    aboutSectionBackgroundImage?: Image

    linksSectionTextColor?: string
    linksSectionBackgroundColor?: string
    linksSectionTextColorEnabled: boolean[]
    linksSectionBackgroundColorEnabled: boolean[]
    linksSectionBackgroundImage?: Image

    bookSectionTextColor?: string
    bookSectionBackgroundColor?: string
    bookSectionTextColorEnabled: boolean[]
    bookSectionBackgroundColorEnabled: boolean[]
    bookSectionBackgroundImage?: Image

    orderSectionTextColor?: string
    orderSectionBackgroundColor?: string
    orderSectionTextColorEnabled: boolean[]
    orderSectionBackgroundColorEnabled: boolean[]
    orderSectionBackgroundImage?: Image

    photosSectionTextColor?: string
    photosSectionBackgroundColor?: string
    photosSectionTextColorEnabled: boolean[]
    photosSectionBackgroundColorEnabled: boolean[]
    photosSectionBackgroundImage?: Image

    informationSectionTextColor?: string
    informationSectionBackgroundColor?: string
    informationSectionTextColorEnabled: boolean[]
    informationSectionBackgroundColorEnabled: boolean[]
    informationSectionBackgroundImage?: Image

    reviewsSectionTextColor?: string
    reviewsSectionBackgroundColor?: string
    reviewsSectionTextColorEnabled: boolean[]
    reviewsSectionBackgroundColorEnabled: boolean[]
    reviewsSectionBackgroundImage?: Image

    footerSectionTextColor?: string
    footerSectionBackgroundColor?: string
    footerSectionTextColorEnabled: boolean[]
    footerSectionBackgroundColorEnabled: boolean[]
    footerSectionBackgroundImage?: Image
}

interface SectionPartProps {
    initialValues: WebsiteCustomizationFormState
    section: string
}

const SectionPart: React.FC<SectionPartProps> = ({ initialValues, section }) => {
    const { t } = useTranslation()
    const formsClasses = useFormsStyles()
    const classes = useStyles()

    const { getImage } = useImgix()

    return (
        <FormSpy subscription={{ values: true }}>
            {(props) => (
                <Grid item md={4} sm={6} xs={12}>
                    <Card>
                        <div className={classes.sectionTitleContainer}>
                            <Typography className={classes.sectionTitle}>
                                <Trans
                                    i18nKey={'views.externalLinks.customization.section.' + section}
                                    components={[<strong />]}
                                />
                            </Typography>
                        </div>
                        <CardContent>
                            <TextField
                                label={t('views.externalLinks.customization.backgroundColor')}
                                name={section + 'SectionBackgroundColor'}
                                type="color"
                                className={
                                    props.values[section + 'SectionBackgroundColorEnabled'].length === 0
                                        ? classes.disabled
                                        : ''
                                }
                                disabled={
                                    props.values[section + 'SectionBackgroundColorEnabled'].length === 0
                                }
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CheckboxField
                                                value={true}
                                                label={t('views.externalLinks.customization.active')}
                                                name={section + 'SectionBackgroundColorEnabled'}
                                                labelPlacement="end"
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                label={t('views.externalLinks.customization.textColor')}
                                name={section + 'SectionTextColor'}
                                type="color"
                                className={
                                    props.values[section + 'SectionTextColorEnabled'].length === 0
                                        ? classes.disabled
                                        : ''
                                }
                                disabled={props.values[section + 'SectionTextColorEnabled'].length === 0}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CheckboxField
                                                value={true}
                                                label={t('views.externalLinks.customization.active')}
                                                name={section + 'SectionTextColorEnabled'}
                                                labelPlacement="end"
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <ImageField
                                name={section + 'SectionBackgroundImage'}
                                label={t('views.externalLinks.customization.backgroundImage')}
                                defaultValue={
                                    // @ts-ignore
                                    initialValues[section + 'SectionBackgroundImage']
                                }
                                imageOptions={{
                                    maxWidth: 120,
                                }}
                            >
                                {(Uploader, hasError, error, image, onDelete) => {
                                    return (
                                        <div className={classes.photoField}>
                                            <Uploader
                                                classes={{
                                                    root: clsx(formsClasses.photoField),
                                                    container: formsClasses.photoFieldContainer,
                                                }}
                                            >
                                                <Card
                                                    elevation={0}
                                                    classes={{
                                                        root: image
                                                            ? formsClasses.photoFieldCard
                                                            : formsClasses.photoFieldCardWithoutImage,
                                                    }}
                                                >
                                                    <CardMedia
                                                        image={
                                                            image?.url &&
                                                            getImage(image?.url, {
                                                                w: 120,
                                                                ar: '1:1',
                                                                fit: 'clip',
                                                            })
                                                        }
                                                        classes={{
                                                            root: clsx(formsClasses.photoFieldCardMedia),
                                                        }}
                                                    >
                                                        {!image && (
                                                            <div
                                                                className={
                                                                    formsClasses.photoFieldAddContainer
                                                                }
                                                            >
                                                                <AddCircleIcon
                                                                    className={clsx(
                                                                        formsClasses.photoFieldAdd,
                                                                        classes.photoFieldAdd,
                                                                    )}
                                                                    color="primary"
                                                                />
                                                            </div>
                                                        )}
                                                    </CardMedia>
                                                </Card>
                                                {image?.url && (
                                                    <div className={classes.photoEdit}>
                                                        <Button variant="contained" color="primary" fullWidth>
                                                            <Trans i18nKey="actions.edit" />
                                                        </Button>
                                                    </div>
                                                )}
                                            </Uploader>
                                            {image?.url && (
                                                <div className={classes.photoDelete}>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={onDelete}
                                                        fullWidth
                                                    >
                                                        <Trans i18nKey="actions.delete" />
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    )
                                }}
                            </ImageField>
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </FormSpy>
    )
}

const WebsiteCustomizationForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
}) => {
    const { t } = useTranslation()
    const formsClasses = useFormsStyles()
    const submit = (values: WebsiteCustomizationFormState) => {
        return onSubmit(values)
    }
    const sectionOrder = [
        'title',
        'slate',
        'menu',
        'news',
        'about',
        'links',
        'book',
        'order',
        'photos',
        'information',
        'reviews',
        'footer',
    ]

    const possibilities = [
        { label: 'Open Sans', value: 'Open Sans' },
        { label: 'Roboto', value: 'Roboto' },
        { label: 'Inclusive Sans', value: 'Inclusive Sans' },
        { label: 'Coiny', value: 'Coiny' },
        { label: 'Noto Serif', value: 'Noto Serif' },
        { label: 'Josefin Sans', value: 'Josefin Sans' },
        { label: 'Oregano', value: 'Oregano' },
        { label: 'Sumana', value: 'Sumana' },
        { label: 'Sigmar', value: 'Sigmar' },
        { label: 'Stick No Bills', value: 'Stick No Bills' },
        { label: 'Belanosima', value: 'Belanosima' },
        { label: 'MuseoModerno', value: 'MuseoModerno' },
    ]
    if (
        initialValues.font &&
        possibilities.find((possibility) => possibility.value === initialValues.font) === undefined
    ) {
        possibilities.push({
            label: initialValues.font,
            value: initialValues.font,
        })
    }
    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={submit}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <SelectField
                                        name="font"
                                        label={t('views.externalLinks.customization.websiteFont')}
                                        variant="standard"
                                        possibilities={possibilities}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label={t(
                                            'views.externalLinks.customization.websiteBackgroundColorLight',
                                        )}
                                        name={'backgroundColorLight'}
                                        type="color"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label={t(
                                            'views.externalLinks.customization.websiteBackgroundColorDark',
                                        )}
                                        name={'backgroundColorDark'}
                                        type="color"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={4}>
                                {sectionOrder.map((section) => (
                                    <SectionPart
                                        key={section}
                                        initialValues={initialValues}
                                        section={section}
                                    />
                                ))}
                            </Grid>
                        </div>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                className: formsClasses.submit,
                                children: <Trans i18nKey="actions.next" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}
export default WebsiteCustomizationForm
