import {
    Button,
    CardActions,
    CardContent,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    Typography,
} from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import ShareIcon from '@material-ui/icons/Share'
import TwitterIcon from '@material-ui/icons/Twitter'
import { Establishment, NewsModel } from '@sugg-gestion/ubidreams-react-suggpro'
import clsx from 'clsx'
import moment from 'moment'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { InlineShareButtons } from 'sharethis-reactjs'
import FlatCard from '../../../common/flatCard'
import ImageCard from '../../../common/imageCard'
import SubmitButton from '../../../common/submitButton'
import GoogleIcon from '../../../icons/googleIcon'
import { useStyles } from '../styles.css'

interface Props {
    news: NewsModel
    establishment: Establishment
    shareOnGoogle: (eventId: NewsModel['id']) => void
    shareOnFacebook: (eventId: NewsModel['id']) => void
    shareOnInstagram: (eventId: NewsModel['id']) => void
    shareOnTwitter: (eventId: NewsModel['id']) => void
    onDelete: (eventId: NewsModel['id']) => void
    eventInProgress?: number
    deleteInProgress: boolean
    isSharingSlateOnFacebook: boolean
    isSharingSlateOnGoogle: boolean
    isSharingSlateOnInstagram: boolean
    isSharingSlateOnTwitter: boolean
    isGoogleSignedExpired: boolean
    isFacebookSignedExpired: boolean
    isTwitterSignedExpired: boolean
    showTwitter: boolean
}

const NewsView: React.FC<Props> = ({
    news,
    establishment,
    shareOnGoogle,
    shareOnFacebook,
    shareOnInstagram,
    shareOnTwitter,
    onDelete,
    deleteInProgress,
    isSharingSlateOnFacebook,
    isSharingSlateOnInstagram,
    isSharingSlateOnGoogle,
    isSharingSlateOnTwitter,
    isGoogleSignedExpired,
    isTwitterSignedExpired,
    isFacebookSignedExpired,
    eventInProgress,
    showTwitter,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <Grid item xs={12} sm={6} key={news.id}>
            <FlatCard>
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography className={classes.formTitle}>
                                <Trans i18nKey="views.news.news" />
                            </Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <Typography className={classes.formTitle}>
                                        <Trans i18nKey="form.eventPhoto" />
                                    </Typography>
                                    <Grid container spacing={1}>
                                        {news.images &&
                                            news.images.map((image) => (
                                                <Grid item xs={6}>
                                                    <ImageCard url={image.picture} />
                                                </Grid>
                                            ))}
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12}>
                                            <Typography className={classes.formTitle}>
                                                <Trans i18nKey="form.newsDate" />
                                            </Typography>
                                            <Typography color="textPrimary">
                                                <Trans
                                                    i18nKey="views.news.until"
                                                    values={{
                                                        endTime: moment(news.endTime).toDate(),
                                                    }}
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className={classes.formTitle}>
                                                <Trans i18nKey="form.eventComment" />
                                            </Typography>
                                            <Typography color="textPrimary" className={classes.comment}>
                                                {news.comment === ''
                                                    ? t('views.slate.noComment')
                                                    : news.comment}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.formTitle}>
                                <Trans i18nKey="views.news.shares" />
                            </Typography>
                            <List>
                                <ListItem divider>
                                    <ListItemIcon>
                                        <FacebookIcon className={classes.facebookIcon} />
                                    </ListItemIcon>
                                    {news.isFacebookShared ? (
                                        <>
                                            <ListItemText primary={t('views.slate.share.facebook')} />
                                            {news.facebookPermalink && (
                                                <Button
                                                    variant="outlined"
                                                    href={news.facebookPermalink}
                                                    target="_blank"
                                                    endIcon={<OpenInNewIcon />}
                                                >
                                                    <Trans i18nKey="actions.checkPublication" />
                                                </Button>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <ListItemText primary={t('views.slate.share.notFacebook')} />
                                            <ListItemSecondaryAction className={clsx(classes.action)}>
                                                <SubmitButton
                                                    variant="outlined"
                                                    color="primary"
                                                    endIcon={<ShareIcon />}
                                                    onClick={() => shareOnFacebook(news.id)}
                                                    loading={
                                                        eventInProgress === news.id &&
                                                        isSharingSlateOnFacebook
                                                    }
                                                    disabled={eventInProgress !== undefined}
                                                >
                                                    {establishment.isFacebookLinked &&
                                                    !isFacebookSignedExpired ? (
                                                        <Trans i18nKey="actions.share" />
                                                    ) : isFacebookSignedExpired ? (
                                                        <Trans i18nKey="actions.renewSignInFacebook" />
                                                    ) : (
                                                        <Trans i18nKey="actions.link" />
                                                    )}
                                                </SubmitButton>
                                            </ListItemSecondaryAction>
                                        </>
                                    )}
                                </ListItem>
                                <ListItem divider>
                                    <ListItemIcon>
                                        <InstagramIcon className={classes.instagramIcon} />
                                    </ListItemIcon>
                                    {news.isInstagramShared ? (
                                        <>
                                            <ListItemText primary={t('views.slate.share.instagram')} />
                                            {news.instagramPermalink && (
                                                <Button
                                                    variant="outlined"
                                                    href={news.instagramPermalink}
                                                    target="_blank"
                                                    endIcon={<OpenInNewIcon />}
                                                >
                                                    <Trans i18nKey="actions.checkPublication" />
                                                </Button>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <ListItemText primary={t('views.slate.share.notInstagram')} />
                                            <ListItemSecondaryAction className={clsx(classes.action)}>
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        news.images === undefined || news.images === null
                                                            ? (t('views.news.noShareInstagram') as string)
                                                            : ''
                                                    }
                                                    placement="top-start"
                                                >
                                                    <div>
                                                        <SubmitButton
                                                            variant="outlined"
                                                            color="primary"
                                                            endIcon={<ShareIcon />}
                                                            onClick={() => shareOnInstagram(news.id)}
                                                            loading={
                                                                eventInProgress === news.id &&
                                                                isSharingSlateOnInstagram
                                                            }
                                                            disabled={
                                                                eventInProgress !== undefined ||
                                                                news.images === undefined ||
                                                                news.images === null
                                                            }
                                                        >
                                                            {establishment.instagramPageId &&
                                                            !isFacebookSignedExpired ? (
                                                                <Trans i18nKey="actions.share" />
                                                            ) : isFacebookSignedExpired ? (
                                                                <Trans i18nKey="actions.renewSignInFacebook" />
                                                            ) : (
                                                                <Trans i18nKey="actions.link" />
                                                            )}
                                                        </SubmitButton>
                                                    </div>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </>
                                    )}
                                </ListItem>
                                <ListItem divider>
                                    <ListItemIcon>
                                        <GoogleIcon />
                                    </ListItemIcon>
                                    {news.isGoogleShared ? (
                                        <>
                                            <ListItemText primary={t('views.slate.share.google')} />
                                            {news.googleLocalPostPermalink ? (
                                                <Button
                                                    variant="outlined"
                                                    href={news.googleLocalPostPermalink}
                                                    target="_blank"
                                                    endIcon={<OpenInNewIcon />}
                                                >
                                                    <Trans i18nKey="actions.checkPublication" />
                                                </Button>
                                            ) : establishment.googleUrl ? (
                                                <Button
                                                    variant="outlined"
                                                    href={establishment.googleUrl}
                                                    target="_blank"
                                                    endIcon={<OpenInNewIcon />}
                                                >
                                                    <Trans i18nKey="actions.checkPublication" />
                                                </Button>
                                            ) : null}
                                        </>
                                    ) : (
                                        <>
                                            <ListItemText primary={t('views.slate.share.notGoogle')} />
                                            <ListItemSecondaryAction className={clsx(classes.action)}>
                                                <SubmitButton
                                                    variant="outlined"
                                                    color="primary"
                                                    endIcon={<ShareIcon />}
                                                    onClick={() => shareOnGoogle(news.id)}
                                                    loading={
                                                        eventInProgress === news.id && isSharingSlateOnGoogle
                                                    }
                                                    disabled={eventInProgress !== undefined}
                                                >
                                                    {establishment.isGoogleLinked &&
                                                    !isGoogleSignedExpired ? (
                                                        <Trans i18nKey="actions.share" />
                                                    ) : isGoogleSignedExpired ? (
                                                        <Trans i18nKey="actions.renewSignInGoogle" />
                                                    ) : (
                                                        <Trans i18nKey="actions.link" />
                                                    )}
                                                </SubmitButton>
                                            </ListItemSecondaryAction>
                                        </>
                                    )}
                                </ListItem>
                                {showTwitter && (
                                    <ListItem divider>
                                        <ListItemIcon>
                                            <TwitterIcon className={classes.twitterIcon} />
                                        </ListItemIcon>
                                        {news.isTwitterShared ? (
                                            <>
                                                <ListItemText primary={t('views.slate.share.twitter')} />
                                                {news.twitterPermalink && (
                                                    <Button
                                                        variant="outlined"
                                                        href={news.twitterPermalink}
                                                        target="_blank"
                                                        endIcon={<OpenInNewIcon />}
                                                    >
                                                        <Trans i18nKey="actions.checkPublication" />
                                                    </Button>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <ListItemText primary={t('views.slate.share.notTwitter')} />
                                                <ListItemSecondaryAction className={clsx(classes.action)}>
                                                    <SubmitButton
                                                        variant="outlined"
                                                        color="primary"
                                                        endIcon={<ShareIcon />}
                                                        onClick={() => shareOnTwitter(news.id)}
                                                        loading={
                                                            eventInProgress === news.id &&
                                                            isSharingSlateOnTwitter
                                                        }
                                                        disabled={eventInProgress !== undefined}
                                                    >
                                                        {establishment.twitterID &&
                                                        !isTwitterSignedExpired ? (
                                                            <Trans i18nKey="actions.share" />
                                                        ) : isTwitterSignedExpired ? (
                                                            <Trans i18nKey="actions.renewSignInTwitter" />
                                                        ) : (
                                                            <Trans i18nKey="actions.link" />
                                                        )}
                                                    </SubmitButton>
                                                </ListItemSecondaryAction>
                                            </>
                                        )}
                                    </ListItem>
                                )}
                                <ListItem>
                                    <ListItemIcon>
                                        <ShareIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t('views.slate.share.otherShare')} />
                                    <ListItemSecondaryAction
                                        className={clsx(classes.action, classes.shareButtons)}
                                    >
                                        <InlineShareButtons
                                            config={{
                                                alignment: 'center', // alignment of buttons (left, center, right)
                                                enabled: true, // show/hide buttons (true, false)
                                                language: 'fr', // which language to use (see LANGUAGES)
                                                padding: 8, // padding within buttons (INTEGER)
                                                size: 42, // the size of each button (INTEGER)
                                                networks: [
                                                    // which networks to include (see SHARING NETWORKS)
                                                    'twitter',
                                                    'email',
                                                    'pinterest',
                                                    'linkedin',
                                                    'whatsapp',
                                                ],
                                                // OPTIONAL PARAMETERS
                                                url: news.images[0] ? news.images[0].picture : undefined, // (defaults to current url)
                                                color: 'white',
                                                font_size: 12,
                                                radius: 6,
                                                show_total: false,
                                                labels: null,
                                            }}
                                        />
                                        <InlineShareButtons
                                            config={{
                                                alignment: 'center', // alignment of buttons (left, center, right)
                                                enabled: true, // show/hide buttons (true, false)
                                                language: 'fr', // which language to use (see LANGUAGES)
                                                padding: 8, // padding within buttons (INTEGER)
                                                size: 42, // the size of each button (INTEGER)
                                                networks: [
                                                    // which networks to include (see SHARING NETWORKS)
                                                    'whatsapp',
                                                ],
                                                // OPTIONAL PARAMETERS
                                                url: news.images[0] ? news.images[0].picture : undefined, // (defaults to current url)
                                                color: 'white',
                                                font_size: 12,
                                                radius: 6,
                                                show_total: false,
                                                labels: 'cta',
                                            }}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <SubmitButton
                        variant="outlined"
                        onClick={() => onDelete(news.id)}
                        disabled={eventInProgress !== undefined}
                        loading={eventInProgress === news.id && deleteInProgress}
                    >
                        <Trans i18nKey="actions.delete" />
                    </SubmitButton>
                </CardActions>
            </FlatCard>
        </Grid>
    )
}

export default NewsView
