import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useFormsStyles = makeStyles((theme: Theme) =>
    createStyles({
        actions: {
            'display': 'flex',
            'justifyContent': 'flex-end',
            'alignItems': 'center',
            'margin': theme.spacing(6, -1, 0, -1),
            '& a, & button': {
                margin: theme.spacing(0, 1),
            },
        },
        submit: {
            width: 160,
            maxWidth: '100%',
            minWidth: 'fit-content !important',
        },
        completeButton: {
            marginLeft: 'auto',
        },
        localizeAddress: {
            textAlign: 'right',
        },
        localizeButton: {
            marginTop: theme.spacing(1),
        },
        addressColumn: {
            marginTop: theme.spacing(0),
        },
        or: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
        },
        orDivider: {
            flexGrow: 1,
            backgroundColor: theme.palette.text.primary,
        },
        photoField: {
            height: '100% !important',
        },
        photoFieldContainer: {
            width: '100%',
            position: 'relative',
            maxWidth: 300,
            height: '100%',
            margin: 'auto',
        },
        photo: {
            '&.MuiCardMedia-root': {
                backgroundSize: 'contain',
                backgroundPosition: 'top center',
            },
        },
        photoFieldCard: {
            border: '1px solid #EBE6EF',
        },
        photoFieldCardImage: {
            marginTop: theme.spacing(2),
        },
        photoFieldCardWithoutImage: {
            border: '1px solid #F38355',
        },
        photoFieldCardMedia: {
            height: 0,
            paddingTop: '133%',
            backgroundSize: 'contain',
            position: 'relative',
            width: '100%',
        },
        photoFieldAddContainer: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        photoFieldAdd: {
            fontSize: '6rem',
        },
        helper: {
            fontStyle: 'italic',
            fontSize: '1rem',
            fontWeight: theme.fontsWeight.light,
        },
        updatePassword: {
            marginTop: theme.spacing(3),
        },
        row: {
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                'display': 'inline-flex',
                'alignItems': 'center',
                'justifyContent': 'space-between',
                '& > div': {
                    margin: theme.spacing(0, 2),
                    flexGrow: 1,
                    flex: 1,
                },
                '& > div:first-child': {
                    marginLeft: 0,
                },
                '& > div:last-child': {
                    marginRight: 0,
                },
            },
        },
        emailRow: {
            'alignItems': 'flex-start',
            '& legend': {
                fontWeight: theme.fontsWeight.semiBold,
            },
        },
        firstName: {
            paddingRight: theme.spacing(1),
            [theme.breakpoints.down('xs')]: {
                paddingRight: 0,
            },
        },
        lastName: {
            paddingLeft: theme.spacing(1),
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 0,
            },
        },
        semiBold: {
            fontWeight: theme.fontsWeight.semiBold,
        },
        hide: {
            'height': 0,
            'width': 0,
            '& input': {
                padding: 0,
            },
        },
        marginTop: {
            marginTop: theme.spacing(4),
            [theme.breakpoints.down('xs')]: {
                marginTop: theme.spacing(2),
            },
        },
        uploader: {
            'height': 0,
            'paddingTop': '100%',
            'marginTop': 0,
            '& > div > div': {
                borderStyle: 'solid',
                borderRadius: 0,
            },
            '& .MuiSvgIcon-root': {
                fontSize: '8rem !important',
                [theme.breakpoints.down('xs')]: {
                    fontSize: '5rem !important',
                },
            },
        },
        uploaderMargin: {
            [theme.breakpoints.up('sm')]: {
                marginTop: 38,
            },
        },
        info: {
            marginTop: theme.spacing(4),
        },
        sectionTitle: {
            fontWeight: theme.fontsWeight.semiBold,
        },
        formTitle: {
            fontWeight: theme.fontsWeight.semiBold,
            textTransform: 'uppercase',
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(1),
        },
        noPaddingTop: {
            paddingTop: 0,
        },
        shareIcon: {
            'justifyContent': 'center',
            [theme.breakpoints.down('xs')]: {
                justifyContent: 'flex-start',
            },
            '& img': {
                width: 24,
            },
        },
        sharePhotoIcon: {
            justifyContent: 'flex-start',
        },
        shareMessageTop: {
            top: '16px !important',
        },
        shareMessageBottom: {
            top: '-16px !important',
        },
        facebookIcon: {
            color: theme.socialColors.facebook,
        },
        instagramIcon: {
            color: theme.socialColors.instagram,
        },
        twitterIcon: {
            color: theme.socialColors.twitter,
        },
        removeTopBottomPadding: {
            [theme.breakpoints.down('xs')]: {
                '& > .MuiGrid-item': {
                    paddingTop: theme.spacing(1),
                    paddingBottom: theme.spacing(1),
                },
            },
        },
        gridSpacingContainer8: {
            padding: theme.spacing(2, 0),
        },
        extend: {
            flexGrow: 1,
            paddingRight: theme.spacing(2),
        },
        error: {
            color: theme.palette.error.main,
        },
        share: {
            '&.MuiFormControl-root': {
                padding: theme.spacing(0, 2),
            },
        },
        options: {
            '&.MuiFormControlLabel-root': {
                marginLeft: theme.spacing(4),
                marginRight: 0,
            },
            '& .MuiFormControlLabel-label': {
                fontSize: '0.7rem',
                textTransform: 'none',
            },
        },
        imageOptions: {
            '&.MuiFormControlLabel-root': {
                marginLeft: 0,
                width: '100%',
            },
            '& .MuiFormControlLabel-label': {
                fontSize: 'inherit',
                textTransform: 'none',
            },
            'borderColor': theme.palette.border.main,
            'borderBottomWidth': 1,
            'borderBottomStyle': 'solid',
            'marginBottom': theme.spacing(),
        },
        flippableContainer: {
            position: 'relative',
            transition: 'transform 0.6s',
            transformStyle: 'preserve-3d',
        },
        flippable: {
            backfaceVisibility: 'hidden',
        },
        flipFront: {
            backgroundColor: theme.palette.common.white,
        },
        flip: {
            transform: 'rotateY(180deg)',
        },
        flipBack: {
            position: 'absolute',
            inset: 0,
            backfaceVisibility: 'hidden',
            transform: 'rotateY(180deg)',
            backgroundColor: theme.palette.common.white,
            overflow: 'auto',
        },
        show: {
            position: 'static',
            transform: 'none',
        },
        imageContainer: {
            width: '100%',
            height: '100%',
        },
        imageContainerHidden: {
            visibility: 'hidden',
        },
        deleteConfiguration: {
            'display': 'flex',
            'justifyContent': 'flex-end',
            '& .MuiButton-root': {
                marginTop: theme.spacing(-1),
                textTransform: 'none',
                fontSize: '0.8rem',
                textDecoration: 'underline',
                [theme.breakpoints.down('xs')]: {
                    fontSize: '0.7rem',
                },
            },
            '& .MuiButton-text': {
                paddingTop: 0,
            },
        },
        permissionsIcon: {
            fontSize: '1rem',
            marginLeft: theme.spacing(0.5),
        },
        lockIcon: {
            color: theme.palette.text.secondary,
        },
        multiplePhoto: {
            marginLeft: theme.spacing(10),
            textTransform: 'none',
            color: theme.palette.text.primary,
        },
        remplisVert: {
            color: '#218a36',
        },
        remplisVertLink: {
            color: 'inherit',
        },
        important: {
            '& .MuiFormHelperText-root': {
                fontWeight: theme.fontsWeight.semiBold,
                color: theme.palette.error.main,
            },
        },
    }),
)
