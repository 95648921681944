import NotFound from 'components/views/main/notFound'
import { ApplicationState } from 'core/store/reducers'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import OAuthAuthorize from './authorize'
import OAuthSignIn from './signIn'

const OAuth: React.FC = (props) => {
    const history = useHistory()
    const params = new URLSearchParams(history.location.search)
    const redirectTo = params.get('redirect-to')
    const clientId = params.get('client')
    const { access, user } = useSelector(({ suggpro: { auth } }: ApplicationState) => ({ ...auth }))
    const [forceSignIn, setForceSignIn] = useState(false)

    if (!redirectTo) {
        return <NotFound />
    }
    if (!clientId) {
        return <NotFound />
    }

    if (access && !forceSignIn) {
        return (
            <OAuthAuthorize
                token={access}
                redirectTo={redirectTo}
                clientId={clientId}
                givenName={user?.firstname ?? ''}
                familyName={user?.lastname ?? ''}
                onCancel={() => setForceSignIn(true)}
            />
        )
    }

    return <OAuthSignIn redirectTo={redirectTo} onSignIn={() => setForceSignIn(false)} />
}

export default OAuth
