import React from 'react'
import { PageType, useApiErrors } from 'core/services/suggPro/useApiErrors'
import { Establishment, useCreateSpecialClosing } from '@sugg-gestion/ubidreams-react-suggpro'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import withMuiPickersUtilsProvider from 'containers/hoc/withMuiPickersUtilsProvider'
import EstablishmentSpecialClosingDialogView from 'components/views/establishment/dialogs/establishmentSpecialClosingDialogView'
import { SpecialClosingFormState } from '../../../components/forms/establishment/specialClosingForm'
import { SpecialClosingRequest } from '@sugg-gestion/ubidreams-react-suggpro/src/resources/establishment/types'
import moment from 'moment'

interface Props {
    open: boolean
    onClose: () => void
    establishment: Establishment
}

const EstablishmentAddSpecialClosingDialog: React.FC<Props> = ({ establishment, open, onClose }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const { displayError } = useApiErrors()
    const { createSpecialClosing, inProgress } = useCreateSpecialClosing()

    const handleUpdateSpecialClosing = (values: SpecialClosingFormState) => {
        const newSpecialClosing: SpecialClosingRequest = {
            establishment: establishment.id,
            label: values.label,
            dateStart: moment(values.dateStart).format('YYYY-MM-DDT00:00:00+00:00'),
            dateEnd: moment(values.dateEnd).format('YYYY-MM-DDT00:00:00+00:00'),
        }

        createSpecialClosing(newSpecialClosing)
            .then(() => {
                enqueueSnackbar(t('views.establishment.specialClosing.success'), {
                    variant: 'success',
                })
                onClose()
            })
            .catch((error) => displayError(error, PageType.EDIT_ESTABLISHMENT))
    }

    return (
        <EstablishmentSpecialClosingDialogView
            open={open}
            onClose={onClose}
            onSubmit={handleUpdateSpecialClosing}
            inProgress={inProgress}
            initialValues={{
                label: t('views.establishment.specialClosing.placeholderLabel'),
                dateStart: new Date(),
                dateEnd: new Date(),
            }}
        />
    )
}

export default withMuiPickersUtilsProvider(EstablishmentAddSpecialClosingDialog)
