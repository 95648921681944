import React from 'react'
import { Button, Grid, Link, Typography } from '@material-ui/core'
import AuthenticateContent from '../../layouts/components/authenticateContent'
import { Trans } from 'react-i18next'
import { useStyles } from './styles.css'
import LottieAnimation from 'components/common/lottieAnimation'
import animation from 'assets/animations/43762-sales-strategy-sales-report-animation.json'
import FacebookSquareIcon from 'components/icons/facebookSquareIcon'
import InstagramSquareIcon from 'components/icons/instagramSquareIcon'
import GmbIcon from 'components/icons/gmbIcon'
import TwitterSquareIcon from 'components/icons/twitterSquareIcon'

const FakeStatisticsView: React.FC = () => {
    const classes = useStyles()

    return (
        <AuthenticateContent withAction={false}>
            <Typography variant="h1" color="textSecondary" className={classes.title}>
                <Trans i18nKey="views.statistics.title" />
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>
                        <Trans
                            i18nKey="views.statistics.message"
                            components={[<Link href="mailto:hello@suggpro.com" />]}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.container}>
                        <div className={classes.container}>
                            <LottieAnimation options={{ animationData: animation, loop: false }} />
                            <div className={classes.soonContainer}>
                                <Typography className={classes.soon}>
                                    <Trans i18nKey="views.statistics.soon" />
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.socialContainer}>
                            <div className={classes.socialItem}>
                                <Button variant="contained" className={classes.iconButton} disabled>
                                    <FacebookSquareIcon className={classes.icon} />
                                </Button>
                            </div>
                            <div className={classes.socialItem}>
                                <Button variant="contained" className={classes.iconButton} disabled>
                                    <InstagramSquareIcon className={classes.icon} />
                                </Button>
                            </div>
                            <div className={classes.socialItem}>
                                <Button variant="contained" className={classes.iconButton} disabled>
                                    <GmbIcon className={classes.icon} />
                                </Button>
                            </div>
                            <div className={classes.socialItem}>
                                <Button variant="contained" className={classes.iconButton} disabled>
                                    <TwitterSquareIcon className={classes.icon} />
                                </Button>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </AuthenticateContent>
    )
}

export default FakeStatisticsView
