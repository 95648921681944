import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { useVersion } from 'core/services/version/useVersion'

const withBugsnag = (ComposedComponent: any) => {
    const WithBugsnag: React.FC = (props) => {
        const version = useVersion()
        const { REACT_APP_BUGSNAG_ID, REACT_APP_STAGE } = process.env
        if (!REACT_APP_BUGSNAG_ID || !REACT_APP_STAGE) {
            throw Error()
        }

        Bugsnag.start({
            apiKey: REACT_APP_BUGSNAG_ID,
            releaseStage: REACT_APP_STAGE,
            plugins: [new BugsnagPluginReact()],
            appVersion: version,
        })

        // @ts-ignore
        const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

        return (
            <ErrorBoundary>
                <ComposedComponent {...props} />
            </ErrorBoundary>
        )
    }
    return WithBugsnag
}

export default withBugsnag
