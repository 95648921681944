import React from 'react'
import { MobileStepper } from '@material-ui/core'
import { useStyles } from './step.css'

interface Props {
    step: number
    steps: number
}

const Step: React.FC<Props> = ({ step, steps }) => {
    const classes = useStyles()

    return (
        <div>
            <MobileStepper
                variant="progress"
                steps={steps + 1}
                position="static"
                activeStep={step}
                nextButton={null}
                backButton={null}
                className={classes.stepper}
            />
        </div>
    )
}

export default Step
