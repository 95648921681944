import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 700,
            width: '100%',
            padding: theme.spacing(1, 1),
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        container: { height: '100%', flexWrap: 'wrap' },
        logoContainer: {
            'display': 'inline-flex',
            'justifyContent': 'center',
            'alignItems': 'center',
            'height': '100%',
            '& > img': {
                width: '100%',
                maxHeight: 'fit-content',
                maxWidth: 'inherit',
            },
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0.5, 0.5),
            },
        },
        logoLeft: {
            justifyContent: 'flex-end',
        },
        logoRight: {
            justifyContent: 'flex-start',
        },
    }),
)
