import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
        },
        action: {
            width: 160,
            minHeight: 0,
            maxWidth: '100%',
            [theme.breakpoints.down('xs')]: {
                width: 120,
            },
        },
        photosTitle: {
            display: 'inline-flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        sectionTitle: {
            fontSize: '1.2rem',
            padding: theme.spacing(2),
            textAlign: 'center',
        },
        sectionTitleContainer: {
            height: '6rem',
            backgroundColor: '#F0F0F0',
            padding: theme.spacing(2),
            borderBottomWidth: 1,
            borderBottomColor: theme.palette.border.main,
            borderBottomStyle: 'solid',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    }),
)
