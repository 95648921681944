import React from 'react'
import Loader from 'components/common/loader'
import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core'
import { useStyles } from './dialogLoading.css'

interface Props extends Partial<DialogProps> {
    loadingMessage: string | React.ReactNode
    title?: string
}

const DialogLoading: React.FC<Props> = ({ loadingMessage, title, ...dialogProps }) => {
    const classes = useStyles()
    return (
        <Dialog
            open={true}
            classes={{
                paper: classes.paper,
            }}
            {...dialogProps}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent className={classes.loadingView}>
                <Loader withLogo={false} text={loadingMessage} />
            </DialogContent>
        </Dialog>
    )
}

export default DialogLoading
