import { PatchMessagingConversation, usePatchConversation } from '@sugg-gestion/ubidreams-react-suggpro'
import { useCallback, useEffect, useState } from 'react'
import { useApiErrors } from '../suggPro/useApiErrors'

export const useUpdateConversation = (parameters: { establishment: number; id: number | undefined }) => {
    const { displayError } = useApiErrors()
    const [inProgress, setInProgress] = useState(false)
    const [lastConversation, setLastConversation] = useState<number | undefined>(undefined)

    const { patchConversation } = usePatchConversation()

    const updateConversations = useCallback(() => {
        setInProgress(true)
        patchConversation({ ...parameters } as PatchMessagingConversation)
            .catch((error) => {
                displayError(error)
            })
            .finally(() => {
                setInProgress(false)
            })
    }, [displayError, patchConversation, parameters])

    useEffect(() => {
        if (!inProgress && parameters.id) {
            if (lastConversation === undefined || lastConversation !== parameters.id) {
                updateConversations()
                setLastConversation(parameters.id)
            }
        }
    }, [parameters.id, updateConversations, inProgress, lastConversation])

    return { inProgress, updateConversations }
}
