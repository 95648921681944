import React from 'react'
import { Field, FormSpy } from 'react-final-form'
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    PropTypes,
    Switch,
} from '@material-ui/core'
import TextField from './textField'
import _ from 'lodash'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { GridSize } from '@material-ui/core/Grid/Grid'

interface Props<T> extends Partial<Record<Breakpoint, boolean | GridSize>> {
    name: string
    label?: string
    placeholder?: string
    values: Array<CheckboxValue<T>>
    includeOther?: boolean
    required?: boolean
    disabled?: boolean
    onUpdate?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
    margin?: PropTypes.Margin
    type?: 'checkbox' | 'switch'
    showCheckbox?: boolean
}

export interface CheckboxValue<T> {
    label: React.ReactNode
    value: T
    disabled?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            flexGrow: 1,
            fontWeight: theme.fontsWeight.semiBold,
            marginBottom: theme.spacing(2),
        },
        item: {
            display: 'flex',
            flexDirection: 'column',
            fontWeight: theme.fontsWeight.semiBold,
        },

        itemtxt: {
            color: '#000000',
            fontWeight: theme.fontsWeight.semiBold,
        },

        checkbox: {
            'width': '100%',
            'border': '1px solid #EBE6EF',
            'borderRadius': theme.spacing(0.5),
            'padding': theme.spacing(0.5),
            'margin': 0,
            'overflowWrap': 'anywhere',
            '& > .MuiFormControlLabel-label': {
                padding: theme.spacing(0, 0, 0, 2),
                fontWeight: theme.fontsWeight.semiBold,
                flexGrow: 1,
                display: 'inline-flex',
                alignItems: 'center',
            },
        },
        error: {
            'borderColor': theme.palette.error.main,
            '& > .MuiFormControlLabel-label': {
                color: theme.palette.error.main,
            },
        },
        checkboxSelected: {
            width: '100%',
            border: '1px solid #EBE6EF',
            borderRadius: theme.spacing(0.5),
            padding: theme.spacing(0.5),
            margin: 0,
            backgroundColor: '#F0F0F0',
        },
        hidden: {
            visibility: 'hidden',
        },
        carder: {
            'borderRadius': 5,
            'border': '1px solid #E2DFDF ',
            '&:is checked': {
                backgroundColor: '#E2DFDF',
            },
            'color': 'black',
            'display': 'flex',
            'justifyContent': 'left',
            'textAlign': 'left',
            'alignItems': 'Right',
        },
    }),
)

const CheckboxGroupField = <T,>({
    name,
    label,
    values,
    includeOther = false,
    placeholder,
    required = false,
    onUpdate,
    margin = 'normal',
    type = 'checkbox',
    showCheckbox = false,
    disabled,
    ...gridSize
}: Props<T>) => {
    const classes = useStyles()
    return (
        <FormSpy subscription={{ errors: true, touched: true, values: true }}>
            {(props) => {
                const hasError =
                    props.errors !== undefined &&
                    props.errors[name] &&
                    props.touched !== undefined &&
                    props.touched[name]
                return (
                    <FormControl
                        component="fieldset"
                        fullWidth
                        margin={margin}
                        required={required}
                        error={hasError}
                        disabled={disabled}
                    >
                        {label && (
                            <FormLabel component="legend" className={classes.label}>
                                {label}
                            </FormLabel>
                        )}
                        {values.length > 0 ? (
                            <FormGroup>
                                <Grid container spacing={2}>
                                    {values.map((checkboxValue, index) => (
                                        <Grid item className={classes.item} key={index} {...gridSize}>
                                            <Field<T> name={name} type="checkbox" value={checkboxValue.value}>
                                                {({ input: { name, onChange, checked } }) => (
                                                    <FormControlLabel
                                                        disabled={disabled}
                                                        className={clsx(classes.checkbox, {
                                                            [classes.checkboxSelected]: checked,
                                                            [classes.error]: hasError,
                                                        })}
                                                        control={
                                                            type === 'checkbox' ? (
                                                                <Checkbox
                                                                    name={name}
                                                                    value={JSON.stringify(
                                                                        checkboxValue.value,
                                                                    )}
                                                                    color="primary"
                                                                    checked={checked}
                                                                    className={
                                                                        checked
                                                                            ? ''
                                                                            : showCheckbox
                                                                            ? ''
                                                                            : classes.hidden
                                                                    }
                                                                    onChange={(event, checked) => {
                                                                        onChange(event)
                                                                        if (onUpdate) {
                                                                            onUpdate(event, checked)
                                                                        }
                                                                    }}
                                                                    disabled={
                                                                        disabled || checkboxValue.disabled
                                                                    }
                                                                />
                                                            ) : (
                                                                <Switch
                                                                    name={name}
                                                                    value={JSON.stringify(
                                                                        checkboxValue.value,
                                                                    )}
                                                                    color="primary"
                                                                    checked={checked}
                                                                    onChange={(event, checked) => {
                                                                        onChange(event)
                                                                        if (onUpdate) {
                                                                            onUpdate(event, checked)
                                                                        }
                                                                    }}
                                                                    disabled={
                                                                        disabled || checkboxValue.disabled
                                                                    }
                                                                />
                                                            )
                                                        }
                                                        label={checkboxValue.label}
                                                        labelPlacement={'start'}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                    ))}
                                    {includeOther && (
                                        <Grid item xs={5}>
                                            <FormControlLabel
                                                classes={{
                                                    label: classes.label,
                                                }}
                                                control={
                                                    <Field name={name} type="checkbox">
                                                        {({ input: { name, onChange } }) => (
                                                            <Checkbox
                                                                name={name}
                                                                value={props.values[name]}
                                                                color="primary"
                                                                checked={
                                                                    _.findIndex(values, {
                                                                        value: props.values[name],
                                                                    }) === -1
                                                                }
                                                                onChange={() =>
                                                                    onChange(props.values[name + 'Other'])
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                }
                                                label={
                                                    <Field name={name} type="checkbox">
                                                        {({ input: { name, onChange } }) => (
                                                            <TextField
                                                                name={name + 'Other'}
                                                                label={undefined}
                                                                onChange={onChange}
                                                                placeholder={placeholder}
                                                            />
                                                        )}
                                                    </Field>
                                                }
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                {hasError && props.errors && (
                                    <FormHelperText error={hasError}>{props.errors[name]}</FormHelperText>
                                )}
                            </FormGroup>
                        ) : (
                            <TextField name={name} label={placeholder} />
                        )}
                    </FormControl>
                )
            }}
        </FormSpy>
    )
}

export default CheckboxGroupField
