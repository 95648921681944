import React from 'react'
import { useFormsStyles } from '../forms.css'
import { Form } from 'react-final-form'
import { SubmitButtonProps } from 'components/common/submitButton'
import { Trans, useTranslation } from 'react-i18next'
import { BusinessHour, BusinessHours, DayOfWeek } from '@sugg-gestion/ubidreams-react-suggpro'
import RegularHoursField from '../components/regularHoursField'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: HoursFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: HoursFormState
}

export interface HoursFormState {
    regularHours?: BusinessHours
}

type RegularHoursErrors = {
    [day in DayOfWeek]: Array<string>
}

interface HoursFormError {
    regularHours?: RegularHoursErrors
}

const HoursForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()

    const appendRegularHoursError = (errors: HoursFormError, day: DayOfWeek, value: BusinessHours) => {
        if (value[day]) {
            const dayHours = value[day]
            if (errors.regularHours === undefined) {
                errors.regularHours = {
                    [DayOfWeek.Monday]: [],
                    [DayOfWeek.Tuesday]: [],
                    [DayOfWeek.Wednesday]: [],
                    [DayOfWeek.Thursday]: [],
                    [DayOfWeek.Friday]: [],
                    [DayOfWeek.Saturday]: [],
                    [DayOfWeek.Sunday]: [],
                }
            }
            for (let i = 0; i < dayHours.length; i++) {
                const regularHour = dayHours[i]
                let previousHour: BusinessHour | undefined = undefined
                if (i > 0) {
                    previousHour = dayHours[i - 1]
                }
                if (i > 1) {
                    errors.regularHours[day][i] = t('form-validation.hoursMaxSlot')
                    continue
                }
                if (regularHour.startTime === regularHour.endTime) {
                    errors.regularHours[day][i] = t('form-validation.hours')
                    continue
                }
                if (previousHour && regularHour.startTime < previousHour.endTime) {
                    errors.regularHours[day][i] = t('form-validation.hoursOverlap')
                    continue
                }
                if (
                    i === dayHours.length - 1 &&
                    regularHour.endTime < regularHour.startTime &&
                    regularHour.endTime > '05:59' &&
                    regularHour.startTime > '05:59'
                ) {
                    errors.regularHours[day][i] = t('form-validation.hoursMax')
                }
            }
        }
    }

    const validate = (values: HoursFormState) => {
        const errors: HoursFormError = {}

        if (values.regularHours) {
            appendRegularHoursError(errors, DayOfWeek.Monday, values.regularHours)
            appendRegularHoursError(errors, DayOfWeek.Tuesday, values.regularHours)
            appendRegularHoursError(errors, DayOfWeek.Wednesday, values.regularHours)
            appendRegularHoursError(errors, DayOfWeek.Thursday, values.regularHours)
            appendRegularHoursError(errors, DayOfWeek.Friday, values.regularHours)
            appendRegularHoursError(errors, DayOfWeek.Saturday, values.regularHours)
            appendRegularHoursError(errors, DayOfWeek.Sunday, values.regularHours)
        }

        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <>
                            <RegularHoursField name="regularHours" />
                        </>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                className: formsClasses.submit,
                                children: <Trans i18nKey="actions.next" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}
export default HoursForm
