import React from 'react'
import {
    Button,
    Dialog,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
} from '@material-ui/core'
import { useStyles } from 'components/views/auth/dialogs/forgottenPasswordDialog.css'
import { GoogleAccount } from '@sugg-gestion/ubidreams-react-suggpro'
import { Trans } from 'react-i18next'

interface Props {
    open: boolean
    onClose: () => void
    accounts: Array<GoogleAccount>
    onChooseAccount: (id?: string) => void
}

const GoogleMyBusinessAccountDialogView: React.FC<Props> = ({ open, onClose, accounts, onChooseAccount }) => {
    const classes = useStyles()

    return (
        <Dialog open={open} onClose={onClose} scroll="body">
            <DialogTitle className={classes.dialogTitle}>
                <Trans i18nKey={'form.googleAccountChoice'} />
            </DialogTitle>
            <List>
                {accounts.map((account, index) => (
                    <React.Fragment key={index}>
                        <Divider component="li" />
                        <ListItem
                            button
                            onClick={() => onChooseAccount(account.id)}
                            classes={{
                                secondaryAction: classes.withAction,
                            }}
                        >
                            <ListItemText
                                primary={account.name}
                                primaryTypographyProps={{
                                    color: 'textSecondary',
                                }}
                                secondary={<Trans i18nKey={'common.googleAccountType.' + account.type} />}
                            />
                            <ListItemSecondaryAction>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => onChooseAccount(account.id)}
                                >
                                    <Trans i18nKey="actions.chooseAccount" />
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </React.Fragment>
                ))}
                <Divider component="li" />
                <ListItem button onClick={() => onChooseAccount(undefined)}>
                    <ListItemText
                        classes={{
                            root: classes.cancel,
                        }}
                    >
                        <Button variant="outlined" color="primary" onClick={() => onChooseAccount(undefined)}>
                            <Trans i18nKey="actions.chooseAccountCancel" />
                        </Button>
                    </ListItemText>
                </ListItem>
            </List>
        </Dialog>
    )
}

export default GoogleMyBusinessAccountDialogView
