import { Grid, List, ListItem, ListItemIcon, Switch, Tooltip, Typography } from '@material-ui/core'
import ListItemText from '@material-ui/core/ListItemText'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import TwitterIcon from '@material-ui/icons/Twitter'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import clsx from 'clsx'
import GoogleIcon from 'components/icons/googleIcon'
import moment from 'moment'
import React from 'react'
import { Field, Form, FormSpy } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { SubmitButtonProps } from '../../common/submitButton'
import { countHashtag } from '../../views/slate/slateUtils'
import KeyboardDateTimePickerField from '../components/keyboardDateTimePickerField'
import TextField from '../components/textField'
import { useFormsStyles } from '../forms.css'
import { moveArrayItemToNewIndex } from '../helpers/moveArrayItemToNewIndex'
import { Image } from '../helpers/useImages'
import { AddFormImageState, AddFormState } from '../slate/addForm'
import PhotoContainer from '../slate/components/photoContainer'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: AddEventFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: AddEventFormState
    canPublishMultiplePhoto: boolean
    isFacebookLinked: boolean
    isInstagramLinked: boolean
    isGoogleLinked: boolean
    isFacebookSignedExpired: boolean
    isGoogleSignedExpired: boolean
    showInstagram: boolean
    showTwitter: boolean
    isTwitterLinked: boolean
    isTwitterSignedExpired: boolean
}

export interface AddEventFormState {
    photos?: Array<AddFormImageState>
    startTime: Date
    endTime: Date
    isGoogleShared: boolean
    isTwitterShared: boolean
    isFacebookShared: boolean
    isInstagramShared: boolean
    title: string
    comment: string
}

export interface AddEventFormError {
    photo?: string
    comment?: string
    title?: string
    startTime?: string
    endTime?: string
}

const AddEventForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
    isFacebookLinked,
    isInstagramLinked,
    isGoogleLinked,
    isFacebookSignedExpired,
    isGoogleSignedExpired,
    showInstagram,
    showTwitter,
    isTwitterSignedExpired,
    isTwitterLinked,
    canPublishMultiplePhoto,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()

    const validate = (values: AddEventFormState) => {
        const errors: AddEventFormError = {}

        if (
            values.comment &&
            values.title &&
            countHashtag(values.comment) + countHashtag(values.title) > 30
        ) {
            errors.comment = t('form-validation.hashtagLimit')
            errors.title = t('form-validation.hashtagLimit')
        }
        if (values.comment && countHashtag(values.comment) > 30) {
            errors.comment = t('form-validation.hashtagLimit')
        }
        if (!values.title) {
            errors.title = t('form-validation.title')
        } else if (countHashtag(values.title) > 30) {
            errors.title = t('form-validation.hashtagLimit')
        }
        if (!values.startTime) {
            errors.startTime = t('form-validation.startTime')
        }
        if (!values.endTime) {
            errors.endTime = t('form-validation.endTime')
        }
        if (values.startTime && values.endTime) {
            if (moment(values.startTime).isAfter(moment(values.endTime), 'minutes')) {
                errors.startTime = t('form-validation.startTimeBeforeEndTime')
                errors.endTime = t('form-validation.startTimeBeforeEndTime')
            }
        }
        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            mutators={{
                changeStartTime: (args, state, tools) => {
                    const date = args[0] as MaterialUiPickersDate | null
                    const {
                        formState: { values },
                    } = state
                    const { endTime } = values as AddEventFormState
                    if (date) {
                        if (moment(date).isAfter(moment(endTime), 'minutes')) {
                            const daysDiff = moment(date).diff(moment(endTime), 'day')
                            tools.changeValue(state, 'endTime', () =>
                                moment(endTime).add(daysDiff + 1, 'day'),
                            )
                        }
                    }
                },
                changeEndTime: (args, state, tools) => {
                    const date = args[0] as MaterialUiPickersDate | null
                    const {
                        formState: { values },
                    } = state
                    const { startTime } = values as AddEventFormState
                    if (date) {
                        if (moment(date).isBefore(moment(startTime), 'minutes')) {
                            const daysDiff = moment(date).diff(moment(startTime), 'day')
                            tools.changeValue(state, 'startTime', () =>
                                moment(startTime).add(daysDiff - 1, 'day'),
                            )
                        }
                    }
                },
                moveEntry: (args, state, tools) => {
                    const from = args[0] as number
                    const to = args[1] as number
                    const {
                        formState: { values },
                    } = state
                    let { photos } = values as AddFormState
                    photos = moveArrayItemToNewIndex(photos ?? [], from, to)
                    const newPhotos: Array<AddFormImageState> = []
                    photos.forEach((photo, index) => {
                        newPhotos.push({
                            ...photo,
                            sortOrder: index,
                        })
                    })
                    tools.changeValue(state, 'photos', () => newPhotos)
                },
                deleteImage: (args, state, tools) => {
                    const image = args[0] as Image
                    const {
                        formState: { values },
                    } = state
                    const { photos } = values as AddFormState

                    const imageIndex = photos?.findIndex((photo) => photo.id === image.id) ?? -1
                    photos?.splice(imageIndex, 1)
                    tools.changeValue(state, 'photos', () => photos)
                },
                setShouldDelete: (args, state, tools) => {
                    const shouldDelete = args[0] as boolean
                    tools.changeValue(state, 'shouldDelete', () => (shouldDelete ? [true] : []))
                },
            }}
            // debug={console.log}
            validate={validate}
            onSubmit={onSubmit}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting, form: { mutators } }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <Grid container spacing={4}>
                            {/* IMAGES */}
                            <Grid item sm={12} xs={12}>
                                <PhotoContainer
                                    canPublishMultiplePhoto={canPublishMultiplePhoto}
                                    showTwitter={showTwitter}
                                    showInstagram={showInstagram}
                                    mutators={mutators}
                                    xl={2}
                                    xs={6}
                                    lg={3}
                                    sm={4}
                                />
                            </Grid>
                            {/*  TITLE  */}
                            <Grid item sm={6} xs={12}>
                                <Typography
                                    color="textSecondary"
                                    className={clsx(formsClasses.formTitle, formsClasses.noPaddingTop)}
                                >
                                    <Trans i18nKey="form.eventTitle" />
                                </Typography>
                                <TextField name="title" />
                            </Grid>
                            {/*  COMMENT  */}
                            <Grid item sm={6} xs={12}>
                                <Typography
                                    color="textSecondary"
                                    className={clsx(formsClasses.formTitle, formsClasses.noPaddingTop)}
                                >
                                    <Trans i18nKey="form.slateComment" />
                                </Typography>
                                <TextField name="comment" multiline={true} maxRows={8} variant="outlined" />
                            </Grid>
                            {/* DATE */}
                            <Grid item sm={6} xs={12}>
                                <Typography
                                    color="textSecondary"
                                    className={clsx(formsClasses.formTitle, formsClasses.noPaddingTop)}
                                >
                                    <Trans i18nKey="form.eventDate" />
                                </Typography>

                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <KeyboardDateTimePickerField
                                            name="startTime"
                                            pastDate={false}
                                            label={t('form.startTime')}
                                            onChange={mutators.changeStartTime}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <KeyboardDateTimePickerField
                                            name="endTime"
                                            pastDate={false}
                                            label={t('form.endTime')}
                                            onChange={mutators.changeEndTime}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/*  SHARING  */}
                            <Grid item sm={6} xs={12}>
                                <Typography color="textSecondary" className={formsClasses.formTitle}>
                                    <Trans i18nKey="form.slateShare" />
                                </Typography>
                                <List>
                                    <Field name="isFacebookShared">
                                        {({ input: { value, onChange } }) => (
                                            <Tooltip
                                                arrow
                                                title={
                                                    !isFacebookLinked
                                                        ? (t('form.noFacebook') as string)
                                                        : isFacebookSignedExpired
                                                        ? (t('form.facebookExpired') as string)
                                                        : ''
                                                }
                                                placement="top-start"
                                                classes={{
                                                    popper: formsClasses.shareMessageTop,
                                                }}
                                            >
                                                <div>
                                                    <ListItem
                                                        divider
                                                        button
                                                        onClick={() => onChange(!value)}
                                                        disabled={
                                                            !isFacebookLinked || isFacebookSignedExpired
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            <Switch
                                                                color="primary"
                                                                edge="start"
                                                                checked={value}
                                                                disableRipple
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemIcon
                                                            classes={{
                                                                root: formsClasses.shareIcon,
                                                            }}
                                                        >
                                                            <FacebookIcon
                                                                className={formsClasses.facebookIcon}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('actions.shareFacebook')} />
                                                    </ListItem>
                                                </div>
                                            </Tooltip>
                                        )}
                                    </Field>
                                    {showInstagram && (
                                        <FormSpy subscription={{ values: true }}>
                                            {(props) => (
                                                <Field name="isInstagramShared">
                                                    {({ input: { value, onChange } }) => (
                                                        <Tooltip
                                                            arrow
                                                            title={
                                                                !isFacebookLinked
                                                                    ? (t('form.noFacebook') as string)
                                                                    : !isInstagramLinked
                                                                    ? (t('form.noInstagram') as string)
                                                                    : isFacebookSignedExpired
                                                                    ? (t('form.facebookExpired') as string)
                                                                    : ''
                                                            }
                                                            placement="top-start"
                                                            classes={{
                                                                popper: formsClasses.shareMessageTop,
                                                            }}
                                                        >
                                                            <div>
                                                                <ListItem
                                                                    divider
                                                                    button
                                                                    onClick={() => onChange(!value)}
                                                                    disabled={
                                                                        !isInstagramLinked ||
                                                                        isFacebookSignedExpired
                                                                    }
                                                                >
                                                                    <ListItemIcon>
                                                                        <Switch
                                                                            color="primary"
                                                                            edge="start"
                                                                            checked={
                                                                                props.values.photos &&
                                                                                props.values.photos.length > 0
                                                                                    ? value
                                                                                    : false
                                                                            }
                                                                            disabled={
                                                                                !props.values.photos ||
                                                                                (props.values.photos &&
                                                                                    props.values.photos
                                                                                        .length === 0)
                                                                            }
                                                                            disableRipple
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemIcon
                                                                        classes={{
                                                                            root: formsClasses.shareIcon,
                                                                        }}
                                                                    >
                                                                        <InstagramIcon
                                                                            className={
                                                                                formsClasses.instagramIcon
                                                                            }
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={t('actions.shareInstagram')}
                                                                    />
                                                                </ListItem>
                                                            </div>
                                                        </Tooltip>
                                                    )}
                                                </Field>
                                            )}
                                        </FormSpy>
                                    )}

                                    <Field name="isGoogleShared">
                                        {({ input: { value, onChange } }) => (
                                            <Tooltip
                                                arrow
                                                title={
                                                    !isGoogleLinked
                                                        ? (t('form.noGoogle') as string)
                                                        : isGoogleSignedExpired
                                                        ? (t('form.googleExpired') as string)
                                                        : ''
                                                }
                                                placement="bottom-start"
                                                classes={{
                                                    popper: formsClasses.shareMessageBottom,
                                                }}
                                            >
                                                <div>
                                                    <ListItem
                                                        divider={showTwitter}
                                                        button
                                                        onClick={() => onChange(!value)}
                                                        disabled={!isGoogleLinked || isGoogleSignedExpired}
                                                    >
                                                        <ListItemIcon>
                                                            <Switch
                                                                color="primary"
                                                                edge="start"
                                                                checked={value}
                                                                disableRipple
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemIcon
                                                            classes={{
                                                                root: formsClasses.shareIcon,
                                                            }}
                                                        >
                                                            <GoogleIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('actions.shareGoogle')} />
                                                    </ListItem>
                                                </div>
                                            </Tooltip>
                                        )}
                                    </Field>

                                    {showTwitter && (
                                        <Field name="isTwitterShared">
                                            {({ input: { value, onChange } }) => (
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        !isTwitterLinked
                                                            ? (t('form.noTwitter') as string)
                                                            : isTwitterSignedExpired
                                                            ? (t('form.twitterExpired') as string)
                                                            : ''
                                                    }
                                                    placement="top-start"
                                                    classes={{
                                                        popper: formsClasses.shareMessageTop,
                                                    }}
                                                >
                                                    <div>
                                                        <ListItem
                                                            button
                                                            onClick={() => onChange(!value)}
                                                            disabled={
                                                                !isTwitterLinked || isTwitterSignedExpired
                                                            }
                                                        >
                                                            <ListItemIcon>
                                                                <Switch
                                                                    color="primary"
                                                                    edge="start"
                                                                    checked={value}
                                                                    disableRipple
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemIcon
                                                                classes={{
                                                                    root: formsClasses.shareIcon,
                                                                }}
                                                            >
                                                                <TwitterIcon
                                                                    className={formsClasses.twitterIcon}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={t('actions.shareTwitter')}
                                                            />
                                                        </ListItem>
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </Field>
                                    )}
                                </List>
                            </Grid>
                        </Grid>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                className: formsClasses.submit,
                                children: <Trans i18nKey="actions.validate" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}

export default AddEventForm
