import React from 'react'
import { Field } from 'react-final-form'
import LocationMap from 'components/common/locationMap'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { FormControl, FormHelperText, PropTypes } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        map: {
            width: '100%',
            height: 286,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.background.default,
        },
        error: {
            borderColor: theme.palette.error.main,
        },
    }),
)

interface Props {
    name: string
    update: number
    margin?: PropTypes.Margin
    required?: boolean
    className?: string
}

const LocationField: React.FC<Props> = ({ name, update, margin = 'normal', required, className }) => {
    const classes = useStyles()
    return (
        <Field name={name}>
            {({ input: { onChange, value }, meta }) => {
                const hasError =
                    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
                let coordinate = undefined
                if (value !== undefined && value !== '' && !(value.lng === 0 && value.lat === 0)) {
                    coordinate = [value.lng, value.lat]
                }
                return (
                    <FormControl
                        component="fieldset"
                        fullWidth
                        margin={margin}
                        required={required}
                        error={hasError}
                        className={className}
                    >
                        <div
                            className={clsx(classes.map, {
                                [classes.error]: hasError,
                            })}
                        >
                            <LocationMap
                                update={update}
                                coordinate={coordinate}
                                onChange={(coordinate) => {
                                    const value = {
                                        lng: coordinate[0],
                                        lat: coordinate[1],
                                    }
                                    onChange(value)
                                }}
                            />
                        </div>
                        {hasError && <FormHelperText error={hasError}>{meta.error}</FormHelperText>}
                    </FormControl>
                )
            }}
        </Field>
    )
}

export default LocationField
