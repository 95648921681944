import React from 'react'
import { Field } from 'react-final-form'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { KeyboardTimePicker } from '@material-ui/pickers'
import moment, { Moment } from 'moment'
import { FormControl, Grid, IconButton, PropTypes, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import classNames from 'classnames'
import { BusinessHours, DayOfWeek } from '@sugg-gestion/ubidreams-react-suggpro'
import { Trans, useTranslation } from 'react-i18next'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        info: {
            color: theme.palette.primary.main,
        },
        error: {
            color: theme.palette.error.main,
        },
        adornmentError: {
            '& > button': {
                color: theme.palette.error.main,
            },
        },
        details: {
            marginLeft: theme.spacing(4),
            marginTop: theme.spacing(-1.5),
        },
        checkbox: {
            'width': 130,
            'margin': 0,
            'backgroundColor': theme.palette.border.contrastText,
            'borderRadius': 6,
            'borderWidth': 1,
            'borderColor': theme.palette.border.main,
            '& .MuiFormControlLabel-label': {
                width: '100%',
                paddingLeft: theme.spacing(2),
                fontWeight: theme.fontsWeight.semiBold,
            },
        },
        checkboxSelected: {
            '& .MuiIconButton-label': {
                color: 'transparent',
                backgroundColor: theme.palette.background.default,
            },
        },

        color: {
            'fontWeight': theme.fontsWeight.semiBold,

            '& .MuiCheckbox-colorSecondary': {
                Color: 'transparent',
            },
            '& .MuiCheckbox-root': {
                color: 'transparent',
            },

            '& .MuiCheckbox-colorSecondary.Mui-checked': {
                color: '#F38355',
            },
        },

        day: {
            marginTop: theme.spacing(1),
            fontWeight: theme.fontsWeight.semiBold,
        },
        space: {
            marginTop: theme.spacing(1),
        },
        left: {
            marginLeft: theme.spacing(-4),
        },

        leftbuton: {
            marginLeft: theme.spacing(-2),
        },

        hourDetails: {
            paddingLeft: theme.spacing(2),
        },
        timeAssert: {
            marginTop: theme.spacing(3),
            textAlign: 'right',
            paddingRight: theme.spacing(),
        },
        additionalTime: {
            textAlign: 'center',
            paddingTop: theme.spacing(),
        },
        space3: {
            [theme.breakpoints.up('md')]: {
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 350,
                marginLeft: theme.spacing(1),
            },
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 310,
                marginLeft: theme.spacing(1),
            },
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 242,
            },
        },

        ecart: {
            [theme.breakpoints.down('sm')]: { marginLeft: theme.spacing(1) },

            [theme.breakpoints.down('xs')]: {
                marginLeft: theme.spacing(1),
            },
        },

        deleteContainer: {
            width: 30,
            [theme.breakpoints.up('md')]: {
                marginLeft: theme.spacing(-5),
            },
            [theme.breakpoints.down('xs')]: {
                marginLeft: theme.spacing(-20),
            },
        },
    }),
)

interface Props {
    name: string
    margin?: PropTypes.Margin
}

type HoursByDay = {
    [day in DayOfWeek]: Array<{
        startTime: string
        endTime: string
        error?: string
    }>
}

const RegularHoursField: React.FC<Props> = ({ name, margin = 'normal' }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const getHoursByDay = (hours: BusinessHours, meta: any) => {
        let hoursByDay: HoursByDay = {
            [DayOfWeek.Monday]: [],
            [DayOfWeek.Tuesday]: [],
            [DayOfWeek.Wednesday]: [],
            [DayOfWeek.Thursday]: [],
            [DayOfWeek.Friday]: [],
            [DayOfWeek.Saturday]: [],
            [DayOfWeek.Sunday]: [],
        }
        appendDayHours(hours, hoursByDay, DayOfWeek.Monday, meta)
        appendDayHours(hours, hoursByDay, DayOfWeek.Tuesday, meta)
        appendDayHours(hours, hoursByDay, DayOfWeek.Wednesday, meta)
        appendDayHours(hours, hoursByDay, DayOfWeek.Thursday, meta)
        appendDayHours(hours, hoursByDay, DayOfWeek.Friday, meta)
        appendDayHours(hours, hoursByDay, DayOfWeek.Saturday, meta)
        appendDayHours(hours, hoursByDay, DayOfWeek.Sunday, meta)

        return hoursByDay
    }

    const appendDayHours = (hours: BusinessHours, hoursByDay: HoursByDay, day: DayOfWeek, meta: any) => {
        const hourList = hours[day]
        for (let i = 0; i < hourList.length; i++) {
            const hour = hourList[i]
            hoursByDay[day].push({
                startTime: hour.startTime,
                endTime: hour.endTime,
                error:
                    meta.error !== undefined && meta.error[day][i] !== undefined
                        ? meta.error[day][i]
                        : undefined,
            })
        }
    }

    const getBusinessHours = (hoursByDay: HoursByDay) => {
        const businessHours: BusinessHours = {
            // @ts-ignore
            [DayOfWeek.Monday]: [],
            // @ts-ignore
            [DayOfWeek.Tuesday]: [],
            // @ts-ignore
            [DayOfWeek.Wednesday]: [],
            // @ts-ignore
            [DayOfWeek.Thursday]: [],
            // @ts-ignore
            [DayOfWeek.Friday]: [],
            // @ts-ignore
            [DayOfWeek.Saturday]: [],
            // @ts-ignore
            [DayOfWeek.Sunday]: [],
            ...hoursByDay,
        }

        return businessHours
    }

    const getDayString = (day: DayOfWeek) => {
        switch (day) {
            case DayOfWeek.Monday:
                return 'monday'
            case DayOfWeek.Tuesday:
                return 'tuesday'
            case DayOfWeek.Wednesday:
                return 'wednesday'
            case DayOfWeek.Thursday:
                return 'thursday'
            case DayOfWeek.Friday:
                return 'friday'
            case DayOfWeek.Saturday:
                return 'saturday'
            case DayOfWeek.Sunday:
                return 'sunday'
        }
    }

    const getTime = (value: string) => {
        const startTimeDetail = value.split(':')
        const now = moment()
        return now.hour(parseInt(startTimeDetail[0])).minute(parseInt(startTimeDetail[1]))
    }

    const getDayField = (
        hours: BusinessHours,
        hoursByDay: HoursByDay,
        day: DayOfWeek,
        onChange: (event: any) => void,
    ) => {
        const hoursFromDay = hoursByDay[day]
        return (
            <FormControl component="fieldset" fullWidth margin={margin}>
                <Grid container spacing={2}>
                    <Grid item md={2} xs={12}>
                        <Typography className={classes.day}>
                            <Trans i18nKey={'form.' + getDayString(day)} />
                        </Typography>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControlLabel
                            className={clsx(classes.checkbox, {
                                [classes.checkboxSelected]: hoursFromDay.length > 0,
                            })}
                            control={
                                <Checkbox
                                    checked={hoursFromDay.length === 0}
                                    value={day}
                                    className={classes.color}
                                    onChange={() => {
                                        hours[day] = []
                                        if (hoursFromDay.length === 0) {
                                            const dayBefore = (((day % 7) + 6) % 7) as DayOfWeek
                                            if (hoursByDay[dayBefore].length > 0) {
                                                hoursByDay[dayBefore].forEach((mondayHours) => {
                                                    hours[day].push(mondayHours)
                                                })
                                            } else {
                                                hours[day].push({
                                                    startTime: '12:00',
                                                    endTime: '14:00',
                                                })
                                            }
                                            onChange(hours)
                                        } else {
                                            hours[day] = []
                                            onChange(hours)
                                        }
                                    }}
                                />
                            }
                            label={t('form.hoursClose')}
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Grid container className={classes.hourDetails} alignItems="center">
                            {hoursFromDay.map((hourFromDay, index) => {
                                const onChangeTime = (value: any, field: string) => {
                                    const moment = value as Moment
                                    if (moment !== null && moment.isValid()) {
                                        hourFromDay = {
                                            ...hourFromDay,
                                            [field]: moment.format('HH:mm'),
                                        }
                                        hoursFromDay[index] = hourFromDay
                                        hoursByDay[day] = hoursFromDay
                                        const hours = getBusinessHours(hoursByDay)
                                        onChange(hours)
                                    }
                                }

                                const onDeleteTime = () => {
                                    hoursFromDay.splice(index, 1)
                                    const hours = getBusinessHours(hoursByDay)
                                    onChange(hours)
                                }

                                return (
                                    <React.Fragment key={index}>
                                        <Grid item xs={5}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={3}>
                                                    <Typography className={classes.timeAssert}>
                                                        <Trans i18nKey="form.startTime" />
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <KeyboardTimePicker
                                                        error={hourFromDay.error !== undefined}
                                                        ampm={false}
                                                        label=""
                                                        value={getTime(hourFromDay.startTime)}
                                                        fullWidth
                                                        margin="normal"
                                                        onChange={(value) => onChangeTime(value, 'startTime')}
                                                        InputAdornmentProps={{
                                                            className: classNames({
                                                                [classes.adornmentError]:
                                                                    hourFromDay.error !== undefined,
                                                            }),
                                                        }}
                                                        inputProps={{
                                                            className: classNames({
                                                                [classes.error]:
                                                                    hourFromDay.error !== undefined,
                                                            }),
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Grid container key={index} spacing={0}>
                                                <Grid item xs={3} className={classes.timeAssert}>
                                                    {t('form.endTime')}
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <KeyboardTimePicker
                                                        error={hourFromDay.error !== undefined}
                                                        ampm={false}
                                                        label=""
                                                        value={getTime(hourFromDay.endTime)}
                                                        fullWidth
                                                        margin="normal"
                                                        onChange={(value) => onChangeTime(value, 'endTime')}
                                                        InputAdornmentProps={{
                                                            className: classNames({
                                                                [classes.adornmentError]:
                                                                    hourFromDay.error !== undefined,
                                                            }),
                                                        }}
                                                        inputProps={{
                                                            className: classNames({
                                                                [classes.error]:
                                                                    hourFromDay.error !== undefined,
                                                            }),
                                                        }}
                                                        cancelLabel={t('actions.cancel')}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}>
                                            {hoursFromDay.length > 1 && (
                                                <IconButton onClick={onDeleteTime}>
                                                    <DeleteOutlineIcon />
                                                </IconButton>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {hourFromDay.error !== undefined && (
                                                <div>
                                                    <FormHelperText className={classes.error}>
                                                        {hourFromDay.error}
                                                    </FormHelperText>
                                                </div>
                                            )}
                                        </Grid>
                                    </React.Fragment>
                                )
                            })}

                            {hoursFromDay.length > 0 && (
                                <Grid item xs={12} className={classes.additionalTime}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => {
                                            hours[day].push({
                                                startTime: '12:00',
                                                endTime: '14:00',
                                            })
                                            onChange(hours)
                                        }}
                                        startIcon={<AddIcon />}
                                    >
                                        <Trans i18nKey="actions.addHours" />
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </FormControl>
        )
    }

    return (
        <Field name={name}>
            {({ input: { onChange, value }, meta }) => {
                let hours: BusinessHours = {
                    [DayOfWeek.Monday]: [],
                    [DayOfWeek.Tuesday]: [],
                    [DayOfWeek.Wednesday]: [],
                    [DayOfWeek.Thursday]: [],
                    [DayOfWeek.Friday]: [],
                    [DayOfWeek.Saturday]: [],
                    [DayOfWeek.Sunday]: [],
                    ...value,
                }
                const hoursByDay = getHoursByDay(hours, meta)

                return (
                    <Grid>
                        {getDayField(hours, hoursByDay, DayOfWeek.Monday, onChange)}
                        {getDayField(hours, hoursByDay, DayOfWeek.Tuesday, onChange)}
                        {getDayField(hours, hoursByDay, DayOfWeek.Wednesday, onChange)}
                        {getDayField(hours, hoursByDay, DayOfWeek.Thursday, onChange)}
                        {getDayField(hours, hoursByDay, DayOfWeek.Friday, onChange)}
                        {getDayField(hours, hoursByDay, DayOfWeek.Saturday, onChange)}
                        {getDayField(hours, hoursByDay, DayOfWeek.Sunday, onChange)}
                    </Grid>
                )
            }}
        </Field>
    )
}

export default RegularHoursField
