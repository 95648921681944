import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            color: '#dde3f4',
            position: 'absolute',
            top: theme.spacing(),
            right: theme.spacing(),
        },
    }),
)
