import React from 'react'
import { Field } from 'react-final-form'
import classNames from 'classnames'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            color: theme.palette.error.main,
        },
        adornmentError: {
            '& > button': {
                color: theme.palette.error.main,
            },
        },
    }),
)

interface Props {
    name: string
    pastDate: boolean
    fullWidth?: boolean
    label?: string
    className?: string
}

const KeyboardDatePickerField: React.FC<Props> = ({ name, pastDate, label, fullWidth = true, className }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Field name={name}>
            {({ input: { name, onChange, value, ...restInput }, meta, ...rest }) => {
                const showError =
                    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
                return (
                    <>
                        <KeyboardDatePicker
                            label={label}
                            value={value}
                            format="DD/MM/YYYY"
                            disablePast={!pastDate}
                            fullWidth={fullWidth}
                            margin="normal"
                            error={showError}
                            minDateMessage={t('form.minDateError')}
                            onChange={onChange}
                            className={className}
                            InputAdornmentProps={{
                                className: classNames({
                                    [classes.adornmentError]: showError,
                                }),
                            }}
                            inputProps={{
                                className: classNames({
                                    [classes.error]: showError,
                                }),
                            }}
                            cancelLabel={t('actions.cancel')}
                        />
                        {showError && <FormHelperText className={classes.error}>{meta.error}</FormHelperText>}
                    </>
                )
            }}
        </Field>
    )
}

export default KeyboardDatePickerField
