import React from 'react'
import MenuForm, { MenuFormState } from 'components/forms/menu/menuForm'
import AuthenticateContent from 'components/layouts/components/authenticateContent'
import { Typography } from '@material-ui/core'
import { Trans } from 'react-i18next'
import AuthenticateActions from 'components/layouts/components/authenticateActions'
import SubmitButton from 'components/common/submitButton'
import clsx from 'clsx'
import { useStyles } from './styles.css'

interface Props {
    onSubmit: (values: MenuFormState) => void
    inProgress: boolean
    initialValues: MenuFormState
    onBack: () => void
}

const MenuEditView: React.FC<Props> = ({ onBack, ...formdata }) => {
    const classes = useStyles()
    return (
        <MenuForm {...formdata}>
            {(content, actions) => (
                <>
                    <AuthenticateContent>
                        <Typography variant="h1" color="textSecondary" className={classes.title}>
                            <Trans i18nKey="views.menu.title" />
                        </Typography>
                        <Typography variant="h2" color="textSecondary" className={classes.subtitle}>
                            <Trans i18nKey="views.menu.add.message" />
                        </Typography>
                        {content}
                    </AuthenticateContent>
                    <AuthenticateActions>
                        <SubmitButton variant="contained" onClick={onBack}>
                            <Trans i18nKey="actions.back" />
                        </SubmitButton>
                        {actions.map((action, index) => (
                            <SubmitButton
                                key={index}
                                variant="contained"
                                color="primary"
                                {...action}
                                className={clsx(action.className)}
                            />
                        ))}
                    </AuthenticateActions>
                </>
            )}
        </MenuForm>
    )
}

export default MenuEditView
