export enum TutoActionTypes {
    REGISTER_VIDEOS = '@@tuto/REGISTER_VIDEOS',
}

export interface TutoState {
    videos: Array<Video>
    totalVideos?: number
    pageFetched?: number
    nextToken?: string
}

export interface Video {
    id: string
    snippet: VideoSnippet
}

export interface VideoSnippet {
    publishedAt: string
    title: string
    description: string
    thumbnails: {
        default: VideoSnippetThumbnail
        medium: VideoSnippetThumbnail
        high: VideoSnippetThumbnail
        standard: VideoSnippetThumbnail
    }
    playlistId: string
    resourceId: VideoSnippetResourceId
}

export interface VideoSnippetThumbnail {
    url: string
    width: number
    height: number
}

export interface VideoSnippetResourceId {
    videoId: string
}

export interface RegisterVideosAction {
    videos: Array<Video>
    totalVideos: number
    nextToken?: string
}
