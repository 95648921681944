import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            maxWidth: 180,
            minWidth: 80,
        },
        undefined: {
            fontSize: '0.8rem',
            fontStyle: 'italic',
        },
    }),
)
