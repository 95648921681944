import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

interface Props {
    className?: string
}

const StatisticsIcon: React.FC<SvgIconProps & Props> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 512.004 512.004">
            <g>
                <g>
                    <polygon
                        points="396.94,0 396.94,31.424 453.285,31.424 321.207,163.502 187.967,30.262 9.32,208.909 31.54,231.128 187.967,74.7
			321.207,207.941 475.504,53.643 475.504,109.987 506.927,109.987 506.927,0"
                    />
                </g>
            </g>
            <g>
                <g>
                    <rect x="4.719" y="329.968" width="101.812" height="182.026" />
                </g>
            </g>
            <g>
                <g>
                    <rect x="137.954" y="168.44" width="101.812" height="343.564" />
                </g>
            </g>
            <g>
                <g>
                    <rect x="271.201" y="264.418" width="101.812" height="247.586" />
                </g>
            </g>
            <g>
                <g>
                    <rect x="405.473" y="168.44" width="101.812" height="343.564" />
                </g>
            </g>
        </SvgIcon>
    )
}

export default StatisticsIcon
