import React from 'react'
import { SubmitButtonProps } from 'components/common/submitButton'
import { useFormsStyles } from '../forms.css'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import CheckboxGroupField from '../components/checkboxGroupField'
import CategoriesField from '../components/categoriesField'
import { SlateCategory } from '@sugg-gestion/ubidreams-react-suggpro'
import { Divider, Typography } from '@material-ui/core'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: DefaultCategoriesFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: DefaultCategoriesFormState
    categories?: Array<SlateCategory>
}

export interface DefaultCategoriesFormState {
    defaultMenuBoardCategories: Array<number>
    defaultMenuBoardCategoriesUseLast: boolean
}

export interface DefaultCategoriesFormStateInternal {
    defaultMenuBoardCategories: Array<number>
    useDefault: Array<boolean>
}

const DefaultCategoriesForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
        values: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
    categories,
}) => {
    const { t } = useTranslation()
    const formsClasses = useFormsStyles()

    const handleSubmit = (values: DefaultCategoriesFormStateInternal) => {
        return onSubmit({
            defaultMenuBoardCategories: values.defaultMenuBoardCategories,
            defaultMenuBoardCategoriesUseLast: values.useDefault && values.useDefault.length > 0,
        })
    }

    const defaultValues = [
        {
            label: t('form.useDefaultMenuBoardCategories'),
            value: true,
        },
    ]
    const internalInitialValues: DefaultCategoriesFormStateInternal = {
        defaultMenuBoardCategories: initialValues.defaultMenuBoardCategories,
        useDefault: initialValues.defaultMenuBoardCategoriesUseLast ? [true] : [],
    }
    return (
        <Form
            initialValues={internalInitialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={handleSubmit}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting, values }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <>
                            <CheckboxGroupField
                                name="useDefault"
                                values={defaultValues}
                                xs={12}
                                type="switch"
                            />
                            {/*  CATEGORIES  */}
                            <div className={formsClasses.or}>
                                <Divider className={formsClasses.orDivider} variant="middle" />
                                <Typography color="textPrimary">
                                    <Trans i18nKey="common.or" />
                                </Typography>
                                <Divider className={formsClasses.orDivider} variant="middle" />
                            </div>
                            <CategoriesField
                                name="defaultMenuBoardCategories"
                                categories={categories}
                                xs={6}
                                md={6}
                                lg={4}
                                disabled={values.useDefault && values.useDefault.length > 0}
                            />
                        </>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                className: formsClasses.submit,
                                children: <Trans i18nKey="actions.validate" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}

export default DefaultCategoriesForm
