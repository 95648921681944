import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { useStyles } from './styles.css'
import SignUpForm, { SignUpFormState } from 'components/forms/auth/signUpForm'
import { Trans } from 'react-i18next'
import SubmitButton from 'components/common/submitButton'
import clsx from 'clsx'
import HomeLayout from 'components/layouts/homeLayout'
import HomeContent from 'components/layouts/components/homeContent'
import HomeActions from 'components/layouts/components/homeActions'

interface Props {
    onSubmit: (values: SignUpFormState) => void
    onCancel: () => void
    inProgress: boolean
    initialValues: SignUpFormState
}

const SignUpView: React.FC<Props> = ({ onSubmit, onCancel, inProgress, initialValues }) => {
    const classes = useStyles()

    return (
        <HomeLayout>
            <SignUpForm onSubmit={onSubmit} inProgress={inProgress} initialValues={initialValues}>
                {(content, actions) => (
                    <>
                        <HomeContent>
                            <Typography variant="h1" className={classes.title}>
                                <Trans i18nKey="views.signUp.title" />
                            </Typography>
                            <Typography>
                                <Trans i18nKey="views.signUp.message" />
                            </Typography>
                            {content}
                        </HomeContent>
                        <HomeActions>
                            <Button variant="outlined" onClick={onCancel}>
                                <Trans i18nKey="actions.cancel" />
                            </Button>
                            {actions.map((action, index) => (
                                <SubmitButton
                                    key={index}
                                    variant="contained"
                                    color="primary"
                                    {...action}
                                    className={clsx(action.className)}
                                />
                            ))}
                        </HomeActions>
                    </>
                )}
            </SignUpForm>
        </HomeLayout>
    )
}

export default SignUpView
