import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../store/reducers'
import { useApiErrors } from '../suggPro/useApiErrors'
import { useEffect, useState } from 'react'
import { actions, useNewsGetList } from '@sugg-gestion/ubidreams-react-suggpro'

export const useNewsList = () => {
    const { newsList } = useSelector(({ suggpro: { news } }: ApplicationState) => ({
        newsList: news.newsList,
    }))
    const dispatch = useDispatch()
    const { displayError } = useApiErrors()

    const { getNewsList } = useNewsGetList()

    const [inProgress, setInProgress] = useState(false)

    useEffect(() => {
        if (newsList === undefined && !inProgress) {
            setInProgress(true)
            getNewsList()
                .catch((error) => {
                    displayError(error)
                    dispatch(actions.setNewsList([]))
                })
                .finally(() => {
                    setInProgress(false)
                })
        }
    }, [inProgress, dispatch, displayError, newsList, getNewsList])

    return {
        newsList,
    }
}
