import { AnyAbility } from '@casl/ability'
// import { Client } from '@sugg-gestion/suggpro-graph-module'
import { APIError, FacebookPagesResponse, GooglePagesResponse } from '@sugg-gestion/ubidreams-react-suggpro'
import { SubmitButtonProps } from 'components/common/submitButton'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'
import React, { MouseEvent } from 'react'

export enum AppActionTypes {
    SET_LOCALE = '@@app/SET_LOCALE',
    SET_TIMEZONE = '@@app/SET_TIMEZONE',

    SIGN_OUT_FROM_FACEBOOK = '@@app/SIGN_OUT_FROM_FACEBOOK',
    SIGN_OUT_FROM_GOOGLE = '@@app/SIGN_OUT_FROM_GOOGLE',

    SET_GOOGLE_INITIALIZED = '@@app/SET_GOOGLE_INITIALIZED',
    SET_GOOGLE_AUTH = '@@app/SET_GOOGLE_AUTH',
    SET_GOOGLE_USER = '@@app/SET_GOOGLE_USER',
    SET_GOOGLE_PAGES = '@@app/SET_GOOGLE_PAGES',

    SET_FACEBOOK_INITIALIZED = '@@app/SET_FACEBOOK_INITIALIZED',
    SET_FACEBOOK_USER = '@@app/SET_FACEBOOK_USER',
    SET_FACEBOOK_PAGES = '@@app/SET_FACEBOOK_PAGES',

    ENQUEUE_SNACKBAR = '@@app/ENQUEUE_SNACKBAR',
    ENQUEUE_ERROR = '@@app/ENQUEUE_ERROR',
    CLOSE_SNACKBAR = '@@app/CLOSE_SNACKBAR',
    REMOVE_SNACKBAR = '@@app/REMOVE_SNACKBAR',

    ALERT = '@@app/ALERT',

    SET_STRIPE = '@@app/SET_STRIPE',
    REDIRECT_TO_ESTABLISHMENT = '@@app/REDIRECT_TO_ESTABLISHMENT',

    SET_HUBSPOT_FORM_INITIALIZED = '@@app/SET_HUBSPOT_FORM_INITIALIZED',

    SET_STATISTICS_START_DATE = '@@app/SET_STATISTICS_START_DATE',
    SET_STATISTICS_END_DATE = '@@app/SET_STATISTICS_END_DATE',

    SET_LOST_INTERNET_CONNEXION = '@@app/SET_LOST_INTERNET_CONNEXION',
    SET_GRAPH_API_CLIENT = '@@app/SET_GRAPH_API_CLIENT',
}

export interface AppState {
    locale: string
    timezone: string

    googleInitialized: boolean
    googleAuth?: gapi.auth2.GoogleAuth
    googleUser?: GoogleUser
    googlePages?: GooglePagesResponse

    facebookInitialized: boolean
    facebookUser?: FacebookUser
    facebookPages?: FacebookPagesResponse

    notifications: Array<SnackbarNotification>

    alert?: AlertContent

    ability: AnyAbility

    hubSpotFormInitialized: boolean
    statisticsStartDate: Date
    statisticsEndDate: Date

    lostInternetConnexion: boolean
    // graphApiClient?: Client
}

export interface SnackbarNotification {
    key: SnackbarKey
    message: SnackbarMessage
    options?: OptionsObject
    dismissed?: boolean
    actionType?: NotificationActionTypes
}

export interface SnackbarNotificationAction {
    message: SnackbarMessage
    options?: OptionsObject
    actionType?: NotificationActionTypes
}

export interface SnackbarErrorNotification {
    error: APIError | unknown
    errorType?: NotificationErrorTypes
    actionType?: NotificationActionTypes
}

export enum NotificationErrorTypes {
    SIGN_IN,
    OTHER,
}

export enum NotificationActionTypes {
    FACEBOOK_REAUTH,
    GOOGLE_REAUTH,
}

export interface GoogleUser {
    code: string
    user: gapi.auth2.GoogleUser
}

export interface FacebookUser {
    auth: facebook.StatusResponse
    info: FacebookUserInfo
}

export interface FacebookUserInfo {
    id: string
    first_name: string
    last_name: string
    email: string
}

export interface AlertContent {
    title?: string
    content?: string | React.ReactNode
    buttons?: Array<AlertButtonProps>
    actionsClassName?: string
}

export interface AlertButtonProps extends SubmitButtonProps {
    onClick?: (event: MouseEvent<HTMLButtonElement>) => Promise<any>
}

export interface SocialLink {
    network: SocialNetworks
    url: string
}

export enum SocialNetworks {
    Website,
    Facebook,
    Instagram,
    MealAndMeet,
    InteractiveGame,
    Delivery,
    Booking,
}
