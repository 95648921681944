import StandardLayout from 'components/layouts/standardLayout'
import NotFound from 'components/views/main/notFound'
import withAlert from 'containers/hoc/withAlert'
import withConnectedRouter from 'containers/hoc/withConnectedRouter'
import withHistory from 'containers/hoc/withHistory'
import withInit from 'containers/hoc/withInit'
import withMuiTheme from 'containers/hoc/withMuiTheme'
import withNotistack from 'containers/hoc/withNotistack'
import withSaga from 'containers/hoc/withSaga'
import withStoreProvider from 'containers/hoc/withStoreProvider'
import withTranslation from 'containers/hoc/withTranslation'
import { InternalRoute, routes } from 'core/config/routes'
import { ApplicationState } from 'core/store/reducers'
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { compose } from 'redux'
import withBugsnagUser from '../hoc/withBugsnagUser'
import withFacebookPixel from '../hoc/withFacebookPixel'
import withGoogleTagManager from '../hoc/withGoogleTagManager'
import withGraphApi, { WithGraphApiProps } from '../hoc/withGraphApi'
import withHubSpot from '../hoc/withHubSpot'
import withMessage from '../hoc/withMessage'
import withSuggPro from '../hoc/withSuggPro'

const App: React.FC<WithGraphApiProps> = (props) => {
    const { ability } = useSelector(({ app }: ApplicationState) => ({
        ability: app.ability,
    }))
    const path = (routes: Array<InternalRoute>) => {
        let path = '/'
        for (let i = 0; i < routes.length; i++) {
            const route = routes[i]
            path = path + route.path + '/'
        }
        path = path.substring(0, path.length - 1)
        return path
    }

    const displayRoutes = (route: InternalRoute, parents: Array<InternalRoute>): React.ReactNode => {
        const routes = []
        if (route.component !== undefined) {
            const Page = route.component
            routes.push(
                <Route path={path([...parents, route])} key={path([...parents, route])} exact>
                    <Page {...props} />
                </Route>,
            )
        }
        if (route.children !== undefined && route.children.length > 0) {
            for (let i = 0; i < route.children.length; i++) {
                const childRoute = route.children[i]
                routes.push(displayRoutes(childRoute, [...parents, route]))
            }
        }
        return routes
    }

    return (
        <StandardLayout>
            <Switch>
                {routes(ability).map((mainRoute) => {
                    return displayRoutes(mainRoute, [])
                })}
                <Route component={NotFound} />
            </Switch>
        </StandardLayout>
    )
}

export default compose<React.FC>(
    withHistory,
    withTranslation,
    withStoreProvider,
    withBugsnagUser,
    withSuggPro,
    withSaga,
    withGraphApi,
    withConnectedRouter,
    withMuiTheme,
    withInit,
    withNotistack,
    withAlert,
    withHubSpot,
    withGoogleTagManager,
    withFacebookPixel,
    withMessage,
)(App)
