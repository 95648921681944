import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SnackbarKey, useSnackbar } from 'notistack'
import { ApplicationState } from 'core/store/reducers'
import actions from 'core/store/actions'
import { Trans } from 'react-i18next'

let displayed: Array<SnackbarKey> = []
const SnackbarNotifier = () => {
    const dispatch = useDispatch()
    const notifications = useSelector(({ app }: ApplicationState) => app.notifications || [])
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    React.useEffect(() => {
        const storeDisplayed = (id: SnackbarKey) => {
            displayed = [...displayed, id]
        }

        const removeDisplayed = (id: SnackbarKey) => {
            displayed = [...displayed.filter((key) => id !== key)]
        }

        notifications.forEach(({ key, message, options = {}, dismissed = false, actionType }) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key)
                return
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return

            if (typeof message === 'string') {
                message = (
                    <span>
                        <Trans i18nKey={message} />
                    </span>
                )
            }
            // switch (actionType) {
            //     case NotificationActionTypes.FACEBOOK_REAUTH:
            //         options.action = (key) => (
            //             <Button
            //                 onClick={() => {
            //                     dispatch(actions.reauthenticateFacebook())
            //                     closeSnackbar(key)
            //                 }}
            //                 color="inherit"
            //                 variant="outlined"
            //             >
            //                 <Trans i18nKey="sharing.facebook.reauth" />
            //             </Button>
            //         )
            //         break
            // }
            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                ...options,
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey)
                    }
                },
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(actions.removeSnackbar(myKey))
                    removeDisplayed(myKey)
                },
            })

            // keep track of snackbars that we've displayed
            storeDisplayed(key)
        })
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

    return null
}

export default SnackbarNotifier
