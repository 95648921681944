import React from 'react'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import {
    Establishment,
    useEstablishmentUpdate,
    useSlateCategories,
} from '@sugg-gestion/ubidreams-react-suggpro'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { FoodTypeFormState } from 'components/forms/establishment/foodTypeForm'
import EstablishmentFoodTypeDialogView from 'components/views/establishment/dialogs/establishmentFoodTypeDialogView'

interface Props {
    open: boolean
    onClose: () => void
    establishment: Establishment
}

const EstablishmentEditFoodTypeDialog: React.FC<Props> = ({ establishment, open, onClose }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const { displayError } = useApiErrors()
    const { updateEstablishment, inProgress } = useEstablishmentUpdate()
    const { categories } = useSlateCategories()

    const handleUpdateFoodType = (values: FoodTypeFormState) => {
        updateEstablishment({
            id: establishment.id,
            establishment: {
                ...values,
            },
        })
            .then(() => {
                enqueueSnackbar(t('views.establishment.foodType.success'), {
                    variant: 'success',
                })
                onClose()
            })
            .catch((error) => displayError(error))
    }

    return (
        <EstablishmentFoodTypeDialogView
            open={open}
            onClose={onClose}
            onSubmit={handleUpdateFoodType}
            inProgress={inProgress}
            initialValues={{
                foodType: _.defaultTo(establishment.foodType, []),
            }}
            categories={_.filter(
                categories,
                (category) =>
                    category.name !== 'Sur place' &&
                    category.name !== 'A emporter' &&
                    category.name !== 'Livraison',
            )}
        />
    )
}

export default EstablishmentEditFoodTypeDialog
