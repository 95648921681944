/**
 * Count number of hashtag in post text and checked categories
 */
export const countHashtag = (postText: string, categoriesLength: number = 0) => {
    const regexHashtag = '(\\#[^\\s]+)'
    const regexExpHashtag = new RegExp(regexHashtag, 'g')
    if (postText !== undefined) {
        return categoriesLength + (postText.match(regexExpHashtag) || []).length
    } else {
        return categoriesLength
    }
}
