import React, { ComponentType } from 'react'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

const withMuiPickersUtilsProvider = <T,>(ComposedComponent: ComponentType<T>) => {
    const WithMuiPickersUtilsProvider: React.FC<T> = (props) => {
        return (
            <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                <ComposedComponent {...props} />
            </MuiPickersUtilsProvider>
        )
    }
    return WithMuiPickersUtilsProvider
}

export default withMuiPickersUtilsProvider
