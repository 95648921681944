import {
    useCustomerPortal,
    useDissociateFacebook,
    useDissociateGoogle,
} from '@sugg-gestion/ubidreams-react-suggpro'
import AccountView from 'components/views/account/accountView'
import withAuthenticateLayout, { WithAuthenticateLayout } from 'containers/hoc/withAuthenticateLayout'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import actions from 'core/store/actions'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface Props extends WithAuthenticateLayout<false> {}

const Account: React.FC<Props> = ({ restaurantOwner }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { displayError } = useApiErrors()
    const { getCustomerPortal, inProgress } = useCustomerPortal()
    const { dissociateFacebook, inProgress: dissociateFacebookInProgress } = useDissociateFacebook()
    const { dissociateGoogle, inProgress: dissociateGoogleInProgress } = useDissociateGoogle()

    const handleGoToPortal = () => {
        getCustomerPortal()
            .then((url) => {
                document.location.href = url
            })
            .catch((error) => displayError(error))
    }

    const handleDissociateFacebook = () => {
        dispatch(
            actions.alert({
                content: t('views.account.warning.facebook'),
                buttons: [
                    {
                        color: 'primary',
                        children: t('common.cancel'),
                    },
                    {
                        color: 'primary',
                        children: t('common.ok'),
                        onClick: dissociateFacebook,
                    },
                ],
            }),
        )
    }

    const handleDissociateGoogle = () => {
        dispatch(
            actions.alert({
                content: t('views.account.warning.google'),
                buttons: [
                    {
                        color: 'primary',
                        children: t('common.cancel'),
                    },
                    {
                        color: 'primary',
                        children: t('common.ok'),
                        onClick: dissociateGoogle,
                    },
                ],
            }),
        )
    }

    return (
        <AccountView
            user={restaurantOwner}
            goToPortal={handleGoToPortal}
            inProgress={inProgress}
            dissociateFacebook={handleDissociateFacebook}
            dissociateFacebookInProgress={dissociateFacebookInProgress}
            dissociateGoogle={handleDissociateGoogle}
            dissociateGoogleInProgress={dissociateGoogleInProgress}
        />
    )
}

export default withAuthenticateLayout(Account, false)
