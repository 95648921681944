import React from 'react'
import { SubmitButtonProps } from '../../common/submitButton'
import { useFormsStyles } from '../forms.css'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import CheckboxGroupField, { CheckboxValue } from '../components/checkboxGroupField'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: DefaultDeleteFlagFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: DefaultDeleteFlagFormState
}

export interface DefaultDeleteFlagFormState {
    facebookDeleteFlag: boolean
    twitterDeleteFlag: boolean
    googlePostDeleteFlag: boolean
    googlePhotoDeleteFlag: boolean
}

export interface DefaultDeleteFlagFormStateInternal {
    flags: Array<string>
}

const DefaultDeleteFlagForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
        values: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
}) => {
    const { t } = useTranslation()
    const formsClasses = useFormsStyles()

    const handleSubmit = (values: DefaultDeleteFlagFormStateInternal) => {
        const finalValues: DefaultDeleteFlagFormState = {
            facebookDeleteFlag: false,
            googlePhotoDeleteFlag: false,
            googlePostDeleteFlag: false,
            twitterDeleteFlag: false,
        }
        values.flags.forEach((id) => {
            switch (id) {
                case 'facebookDeleteFlag':
                    finalValues.facebookDeleteFlag = true
                    break
                case 'googlePhotoDeleteFlag':
                    finalValues.googlePhotoDeleteFlag = true
                    break
                case 'googlePostDeleteFlag':
                    finalValues.googlePostDeleteFlag = true
                    break
                case 'twitterDeleteFlag':
                    finalValues.twitterDeleteFlag = true
                    break
            }
        })
        return onSubmit(finalValues)
    }

    const values: Array<CheckboxValue<string>> = [
        {
            label: t('form.facebook'),
            value: 'facebookDeleteFlag',
        },
        {
            label: t('form.twitter'),
            value: 'twitterDeleteFlag',
        },
        {
            label: t('form.googlePost'),
            value: 'googlePostDeleteFlag',
        },
        {
            label: t('form.googlePhoto'),
            value: 'googlePhotoDeleteFlag',
        },
    ]
    const internalInitialValues: DefaultDeleteFlagFormStateInternal = {
        flags: [],
    }
    if (initialValues.facebookDeleteFlag) {
        internalInitialValues.flags.push('facebookDeleteFlag')
    }
    if (initialValues.twitterDeleteFlag) {
        internalInitialValues.flags.push('twitterDeleteFlag')
    }
    if (initialValues.googlePostDeleteFlag) {
        internalInitialValues.flags.push('googlePostDeleteFlag')
    }
    if (initialValues.googlePhotoDeleteFlag) {
        internalInitialValues.flags.push('googlePhotoDeleteFlag')
    }

    return (
        <Form
            initialValues={internalInitialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={handleSubmit}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <>
                            <CheckboxGroupField name="flags" values={values} xs={6} type="switch" />
                        </>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                className: formsClasses.submit,
                                children: <Trans i18nKey="actions.validate" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}

export default DefaultDeleteFlagForm
