import React from 'react'
import { MessagingConversation } from '@sugg-gestion/ubidreams-react-suggpro'
import ConversationBoxPrivateMessage from './conversationBoxPrivateMessage'
import { useStyles } from '../styles.css'
import ConversationBoxPost from './conversationBoxPost'
import ConversationBoxReview from './conversationBoxReview'

interface Props {
    listConversation: MessagingConversation[]
    conversationSelected?: MessagingConversation
    onSelect: (conversation: MessagingConversation) => void
}

const ListConversation: React.FC<Props> = ({ listConversation, conversationSelected, onSelect }) => {
    const classes = useStyles()
    let boxStyle

    return (
        <>
            {listConversation
                .filter((conversation) => !conversation.hidden)
                .map((conversation, index) => {
                    if (conversationSelected && conversation.id === conversationSelected.id) {
                        boxStyle = [classes.conversationBox, classes.isSelected].join(' ')
                    } else {
                        boxStyle = classes.conversationBox
                    }
                    switch (conversation.typeConversation) {
                        case 'private_message':
                            return (
                                <div className={boxStyle} onClick={() => onSelect(conversation)} key={index}>
                                    <ConversationBoxPrivateMessage conversation={conversation} />
                                </div>
                            )
                        case 'post':
                            return (
                                <div className={boxStyle} onClick={() => onSelect(conversation)} key={index}>
                                    <ConversationBoxPost conversation={conversation} />
                                </div>
                            )
                        case 'review':
                            return (
                                <div className={boxStyle} onClick={() => onSelect(conversation)} key={index}>
                                    <ConversationBoxReview conversation={conversation} />
                                </div>
                            )
                        default:
                            return <></>
                    }
                })}
        </>
    )
}

export default ListConversation
