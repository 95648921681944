export const useVersion = () => {
    const { REACT_APP_VERSION, REACT_APP_SHA } = process.env

    if (REACT_APP_VERSION === '0.0.0' || REACT_APP_VERSION === '' || REACT_APP_VERSION === undefined) {
        if (REACT_APP_SHA) {
            return REACT_APP_SHA
        } else {
            return 'DEV'
        }
    }
    return REACT_APP_VERSION
}
