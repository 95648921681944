import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            width: '100%',
        },
        actions: {
            justifyContent: 'flex-end',
        },
        form: {
            display: 'contents',
        },
        dialogTitle: {
            color: theme.palette.primary.main,
        },
        cancel: {
            '& > .MuiTypography-root': {
                textAlign: 'center',
            },
        },
        withAction: {
            paddingRight: 184,
        },
    }),
)
