import React from 'react'
import { Button, Typography } from '@material-ui/core'
import HomeLayout from 'components/layouts/homeLayout'
import SignInForm, { SignInFormState } from 'components/forms/auth/signInForm'
import clsx from 'clsx'
import { Trans } from 'react-i18next'
import SubmitButton from 'components/common/submitButton'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { useStyles } from './styles.css'
import HomeContent from 'components/layouts/components/homeContent'
import HomeActions from 'components/layouts/components/homeActions'

interface Props {
    onSubmit: (values: SignInFormState) => void
    onCancel: () => void
    inProgress: boolean
    forgottenPasswordInProgress: boolean
    initialValues: SignInFormState
    onForgotPassword: (email: string) => void
}

const SignInView: React.FC<Props> = ({
    onCancel,
    onForgotPassword,
    inProgress,
    forgottenPasswordInProgress,
    ...formData
}) => {
    const classes = useStyles()
    return (
        <HomeLayout>
            <SignInForm
                inProgress={inProgress}
                {...formData}
                forgottenPasswordButton={(email: string) => (
                    <div className={classes.forgottenPassword}>
                        <SubmitButton
                            loading={forgottenPasswordInProgress}
                            onClick={() => onForgotPassword(email)}
                            disabled={inProgress}
                        >
                            <Trans i18nKey="actions.forgottenPassword" />
                        </SubmitButton>
                    </div>
                )}
            >
                {(content, actions) => (
                    <>
                        <HomeContent>
                            <Typography variant="h1" className={classes.title}>
                                <Trans i18nKey="views.signIn.title" />
                            </Typography>
                            <Typography>
                                <Trans i18nKey="views.signIn.message" />
                            </Typography>
                            {content}
                        </HomeContent>
                        <HomeActions>
                            <Button variant="contained" onClick={onCancel}>
                                <Trans i18nKey="actions.cancel" />
                            </Button>
                            {actions.map((action, index) => (
                                <SubmitButton
                                    key={index}
                                    variant="contained"
                                    color="primary"
                                    endIcon={<ArrowForwardIosIcon />}
                                    {...action}
                                    className={clsx(action.className)}
                                />
                            ))}
                        </HomeActions>
                    </>
                )}
            </SignInForm>
        </HomeLayout>
    )
}

export default SignInView
