import { FormControl, FormHelperText, InputLabel, MenuItem, PropTypes } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import React from 'react'
import { Field } from 'react-final-form'

export interface SelectValue {
    label: React.ReactNode
    value: string | number | undefined
}

interface Props {
    name: string
    label: string | React.ReactNode
    possibilities: Array<SelectValue>
    required?: boolean
    autoComplete?: string
    variant?: 'standard' | 'outlined' | 'filled'
    fullWidth?: boolean
    margin?: PropTypes.Margin
}

const SelectField: React.FC<Props> = ({
    name,
    label,
    possibilities,
    required,
    autoComplete,
    variant = 'outlined',
    fullWidth,
    margin = 'normal',
}) => {
    return (
        <Field name={name} type="select">
            {({ input: { name, onChange, value }, meta }) => {
                const hasError =
                    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
                return (
                    <FormControl
                        component="fieldset"
                        fullWidth
                        margin={margin}
                        required={required}
                        error={hasError}
                    >
                        <InputLabel shrink>{label}</InputLabel>
                        <Select
                            id={name}
                            name={name}
                            value={value}
                            variant={variant}
                            onChange={onChange}
                            required={required}
                            fullWidth={fullWidth}
                            autoComplete={autoComplete}
                        >
                            {possibilities.map((possibility, index) => (
                                <MenuItem value={possibility.value} key={index}>
                                    {possibility.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {hasError && <FormHelperText error={hasError}>{meta.error}</FormHelperText>}
                    </FormControl>
                )
            }}
        </Field>
    )
}

export default SelectField
