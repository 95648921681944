import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
        },
        title: {
            paddingBottom: theme.spacing(4),
        },
        section: {
            padding: theme.spacing(2, 0),
        },
        message: {
            fontStyle: 'italic',
        },
        subtitle: {
            fontSize: '1.3rem',
        },
        actionsContainer: {
            marginTop: 16,
        },
        actionContainer: {
            // flexGrow: 1,
            width: 'calc(100% - 64px)',
        },
        iconButton: {
            backgroundColor: theme.palette.border.contrastText,
            padding: theme.spacing(0.5),
            minWidth: 0,
            height: 48,
            width: 48,
        },
        icon: {
            fontSize: '2.5rem',
        },
        iconSuccess: {
            color: theme.palette.success.main,
        },
        action: {
            height: 48,
        },
    }),
)
