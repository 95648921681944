import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
        },
        title: {
            paddingBottom: theme.spacing(4),
        },
        secondaryTitle: {
            paddingBottom: theme.spacing(2),
            fontWeight: theme.fontsWeight.semiBold,
            fontStyle: 'unset',
            fontSize: '1.2rem',
        },
        subtitle: {
            marginTop: theme.spacing(-2),
        },
        empty: {
            fontWeight: theme.fontsWeight.semiBold,
            fontSize: '2rem',
            textAlign: 'center',
            padding: theme.spacing(2, 0),
            marginBottom: theme.spacing(2),
            width: 460,
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                fontSize: '1.4rem',
            },
        },
        emptyContainer: {
            minHeight: 400,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('xs')]: {
                minHeight: 0,
                height: '100%',
            },
        },
        formTitle: {
            fontWeight: theme.fontsWeight.semiBold,
            textTransform: 'uppercase',
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(1),
        },
        formSubTitle: {
            paddingTop: theme.spacing(-1),
            paddingBottom: theme.spacing(2),
            fontSize: '0.8rem',
        },
        noPaddingTop: {
            paddingTop: 0,
        },
        shareIcon: {
            justifyContent: 'center',
        },
        shareIconEnd: {
            justifyContent: 'flex-end',
        },
        shareMessageTop: {
            top: '16px !important',
        },
        shareMessageBottom: {
            top: '-16px !important',
        },
        facebookIcon: {
            color: theme.socialColors.facebook,
        },
        instagramIcon: {
            color: theme.socialColors.instagram,
        },
        twitterIcon: {
            color: theme.socialColors.twitter,
        },
        mainAction: {
            width: 160,
            maxWidth: '100%',
            minWidth: 'fit-content !important',
        },
        categoryChip: {
            '&.MuiChip-root': {
                width: '100%',
                borderRadius: theme.spacing(0.5),
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: theme.spacing(0.5),
                height: 52,
                backgroundColor: theme.palette.border.contrastText,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: theme.palette.border.main,
            },
            '& .MuiChip-label': {
                fontWeight: theme.fontsWeight.semiBold,
                fontSize: '1rem',
                color: '#605B63',
                paddingLeft: theme.spacing(2),
            },
        },
        imageContainer: {
            'height': 400,
            'maxHeight': 'calc(100vh - 200px)',
            'minHeight': 300,
            'display': 'inline-flex',
            'justifyContent': 'flex-start',
            'alignItems': 'center',
            '& > img': {
                maxHeight: '100%',
                maxWidth: '100%',
                margin: theme.spacing(),
            },
        },
        comment: {
            padding: theme.spacing(2),
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.border.main,
            borderRadius: theme.spacing(0.5),
            fontWeight: theme.fontsWeight.semiBold,
            fontSize: '0.9rem',
            overflow: 'hidden',
            overflowWrap: 'break-word',
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(1),
                fontSize: '0.75rem',
            },
        },
        shareButtons: {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: theme.spacing(0.5),
        },
        action: {
            [theme.breakpoints.down('xs')]: {
                position: 'static',
                top: 'inherit',
                right: 'inherit',
                transform: 'inherit',
            },
        },
        updateAction: {
            justifyContent: 'flex-end',
        },
        setting: {
            marginLeft: theme.spacing(2),
        },
        backAction: {
            justifyContent: 'flex-start',
        },
        photoGrid: {
            position: 'relative',
            display: 'inline-flex',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        photoButton: {
            width: '100%',
            height: '100%',
        },
        photoZoom: {
            position: 'absolute',
            bottom: 8,
            right: 8,
            backgroundColor: theme.palette.common.black,
            borderColor: theme.palette.common.white,
            borderWidth: 1,
            borderStyle: 'solid',
            color: theme.palette.common.white,
        },
        imageComment: {
            'padding': theme.spacing(0, 2),
            '& > .MuiFormLabel-root': {
                marginBottom: theme.spacing(2),
            },
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0, 1),
            },
        },
        share: {
            '& > .MuiFormLabel-root': {
                padding: theme.spacing(0, 2),
                [theme.breakpoints.down('xs')]: {
                    padding: theme.spacing(0, 1),
                },
            },
        },
        listShared: {
            '& .MuiListItemText-root': {
                [theme.breakpoints.down('xs')]: {
                    display: 'none',
                },
            },
            '& .MuiListItemIcon-root': {
                [theme.breakpoints.down('xs')]: {
                    'minWidth': 32,
                    '& $shareIconEnd': {
                        minWidth: 'inherit',
                    },
                },
            },
            '& $shareIconEnd': {
                justifyContent: 'flex-start',
            },
        },
        listSharedButton: {
            [theme.breakpoints.down('xs')]: {
                '& .MuiButton-root': {
                    minWidth: 'auto',
                },
                '& .MuiButton-endIcon': {
                    margin: 0,
                },
            },
        },
        options: {
            '&.MuiFormControlLabel-root': {
                marginLeft: theme.spacing(4),
                marginRight: 0,
            },
            '& .MuiFormControlLabel-label': {
                fontSize: '0.7rem',
                textTransform: 'none',
            },
        },
        imageOptions: {
            '&.MuiFormControlLabel-root': {
                marginLeft: 0,
                width: '100%',
            },
            '& .MuiFormControlLabel-label': {
                fontSize: 'inherit',
                textTransform: 'none',
            },
            'borderColor': theme.palette.border.main,
            'borderBottomWidth': 1,
            'borderBottomStyle': 'solid',
        },
        imgIcon: {
            '& img': {
                margin: theme.spacing(0.25),
                width: 20,
            },
        },
    }),
)
