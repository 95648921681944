import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContent: {
            flexGrow: 1,
            padding: theme.spacing(2, 3),
            [theme.breakpoints.down('xs')]: {
                'width': '100%',
                'padding': theme.spacing(2, 2),
                '@media (orientation: portrait)': {
                    paddingBottom: 82,
                },
            },
        },
    }),
)
