import React from 'react'
import withSignUpLayout, { WithSignUpLayout } from 'containers/hoc/withSignUpLayout'
import EstablishmentSocialNetworksView from 'components/views/establishment/establishmentSocialNetworksView'
import { SocialNetworksFormState } from 'components/forms/establishment/socialNetworksForm'
import { useDispatch } from 'react-redux'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import { useEstablishmentUpdate } from '@sugg-gestion/ubidreams-react-suggpro'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { redirectToEstablishment } from 'core/store/app/actions'

interface Props extends WithSignUpLayout {
    step?: number
    steps?: number
}

const EstablishmentAddSocialNetworks: React.FC<Props> = ({ establishment, step = 2, steps = 8 }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const { displayError } = useApiErrors()
    const { updateEstablishment, inProgress } = useEstablishmentUpdate()
    const { updateEstablishment: updateToPreviousStatus, inProgress: updateToPreviousStatusInProgress } =
        useEstablishmentUpdate()

    const handleUpdateSocialNetworks = (values: SocialNetworksFormState) => {
        updateEstablishment({
            id: establishment.id,
            establishment: {
                ...values,
                website: values.website ?? null,
                facebookUrl: values.facebook ?? null,
                instagramUrl: values.instagram ?? null,
                booking: values.booking ?? null,
                delivery: values.delivery ?? null,
                mealandmeetUrl: values.mealAndMeet ?? null,
                interactiveGame: values.interactiveGame ?? null,
            },
            nextStatus: 'ESTABLISHMENT',
        })
            .then(() => {
                enqueueSnackbar(t('views.establishment.socialNetworks.success'), {
                    variant: 'success',
                })
                dispatch(redirectToEstablishment())
            })
            .catch((error) => displayError(error))
    }

    const handlePrevious = () => {
        updateToPreviousStatus({
            id: establishment.id,
            establishment: {},
            nextStatus: 'PAID',
        })
            .then(() => {
                dispatch(redirectToEstablishment())
            })
            .catch((error) => displayError(error))
    }

    return (
        <EstablishmentSocialNetworksView
            step={step}
            steps={steps}
            onSubmit={handleUpdateSocialNetworks}
            inProgress={inProgress}
            initialValues={{
                facebook: establishment.facebookUrl ?? undefined,
                website: establishment.website ?? undefined,
                instagram: establishment.instagramUrl ?? undefined,
                booking: establishment.booking ?? undefined,
                delivery: establishment.delivery ?? undefined,
                mealAndMeet: establishment.mealandmeetUrl ?? undefined,
                interactiveGame: establishment.interactiveGame ?? undefined,
            }}
            previousInProgress={updateToPreviousStatusInProgress}
            onPrevious={handlePrevious}
        />
    )
}

export default withSignUpLayout(EstablishmentAddSocialNetworks)
