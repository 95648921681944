import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { useStyles } from './styles.css'
import { Trans } from 'react-i18next'
import CloseButton from 'components/dialogs/closeButton'
import SubmitButton from 'components/common/submitButton'
import clsx from 'clsx'
import DefaultCommentForm, { DefaultCommentFormState } from 'components/forms/slate/defaultCommentForm'

interface Props {
    open: boolean
    onClose: () => void
    initialValues: DefaultCommentFormState
    onSubmit: (values: DefaultCommentFormState) => void
    inProgress: boolean
}

const CommentEditDialog: React.FC<Props> = ({ open, onClose, inProgress, ...formData }) => {
    const classes = useStyles()

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={fullScreen}
            scroll="paper"
            classes={{
                paper: classes.paper,
            }}
            maxWidth="md"
        >
            <DefaultCommentForm formClassName={classes.form} inProgress={inProgress} {...formData}>
                {(content, actions) => (
                    <>
                        <DialogTitle>
                            <Trans i18nKey="views.slateSetting.defaultComment" />
                            <Typography component="span" className={classes.formSubTitle}>
                                <Trans i18nKey="views.slateSetting.defaultCommentMessage" />
                            </Typography>
                        </DialogTitle>
                        <DialogContent dividers>
                            <CloseButton onClose={onClose} />
                            {content}
                        </DialogContent>
                        <DialogActions className={classes.actions}>
                            <Button variant="contained" onClick={onClose}>
                                <Trans i18nKey="actions.cancel" />
                            </Button>
                            {actions.map((action, index) => (
                                <SubmitButton
                                    key={index}
                                    variant="contained"
                                    color="primary"
                                    {...action}
                                    className={clsx(action.className)}
                                >
                                    <Trans i18nKey="actions.validate" />
                                </SubmitButton>
                            ))}
                        </DialogActions>
                    </>
                )}
            </DefaultCommentForm>
        </Dialog>
    )
}

export default CommentEditDialog
