import {
    Establishment,
    GoogleAccount,
    GooglePagesRequest,
    useGetGoogleAccounts,
    useGetGooglePages,
    useRenewGoogleSignIn,
    useUpdateUser,
} from '@sugg-gestion/ubidreams-react-suggpro'
import actions from 'core/store/actions'
import { ApplicationState } from 'core/store/reducers'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useApiErrors } from '../suggPro/useApiErrors'
import { googleRedirectUri } from './googleRedirectUri'

export class MultipleGoogleMyBusinessAccounts extends Error {
    accounts: Array<GoogleAccount>

    constructor(accounts: Array<GoogleAccount>) {
        super()
        this.accounts = accounts
    }
}

export class GoogleMyBusinessPageExist extends Error {}

export interface GmbAccountSelect {
    accounts: Array<GoogleAccount>
    setOpen: (open: boolean) => void
}

export interface GmbState {
    establishment?: number
    accountId?: string
    pageId?: string
    previousAction: 'sign-up' | 'edit' | 'sign'
}

export const useGoogleMyBusinessPages = (establishment?: Establishment) => {
    const { REACT_APP_GOOGLE_CLIENT_ID } = process.env
    const { googleAuth, user, googlePages } = useSelector(({ app, suggpro: { auth } }: ApplicationState) => ({
        googleAuth: app.googleAuth,
        user: auth.user,
        googlePages: app.googlePages,
    }))
    const { getGooglePages, inProgress: getGooglePagesInProgress } = useGetGooglePages()
    const { getGoogleAccounts, inProgress: getGoogleAccountsInProgress } = useGetGoogleAccounts()
    const { displayError } = useApiErrors()
    const dispatch = useDispatch()
    const { renewGoogleSignIn, inProgress: renewGoogleSignInInProgress } = useRenewGoogleSignIn()
    const { updateUser } = useUpdateUser()

    const [inProgress, setInProgress] = useState(false)

    useEffect(() => {
        if (user !== undefined) {
            if (user.isGoogleSigned && googlePages === undefined && !inProgress) {
                setInProgress(true)
                getGooglePages({})
                    .then((response) => {
                        dispatch(actions.setGooglePages(response))
                        setInProgress(false)
                    })
                    .catch((error) => {
                        dispatch(actions.setGooglePages([]))
                        setInProgress(false)
                        displayError(error)
                    })
            }
        }
    }, [user, googlePages, dispatch, getGooglePages, inProgress, displayError])

    const fetchGoogleMyBusinessPagesAction = (
        previousAction: 'sign-up' | 'edit' | 'sign',
        gmbAccountId?: string,
    ) => {
        if (googleAuth === undefined) {
            const state: GmbState = {
                accountId: gmbAccountId,
                pageId: establishment?.googleId ?? undefined,
                previousAction: previousAction,
            }
            if (establishment) {
                state.establishment = establishment.id
            }
            const url = new URL('https://accounts.google.com/o/oauth2/v2/auth')
            url.searchParams.append('client_id', REACT_APP_GOOGLE_CLIENT_ID ?? '')
            url.searchParams.append('redirect_uri', googleRedirectUri())
            url.searchParams.append('response_type', 'code')
            url.searchParams.append('access_type', 'offline')
            url.searchParams.append('state', JSON.stringify(state))
            url.searchParams.append('prompt', 'consent')
            url.searchParams.append(
                'scope',
                'https://www.googleapis.com/auth/userinfo.email ' +
                    'https://www.googleapis.com/auth/userinfo.profile ' +
                    'https://www.googleapis.com/auth/business.manage',
            )
            window.location.href = url.href
            return Promise.resolve()
        }

        return googleAuth
            .grantOfflineAccess({
                prompt: 'consent',
            })
            .then(({ code }) => {
                setInProgress(true)
                return new Promise<GooglePagesRequest>((resolve, reject) => {
                    if (user) {
                        return renewGoogleSignIn({
                            googleOfflineCode: code,
                        })
                            .then(() => resolve({}))
                            .catch((error) => {
                                reject(error)
                            })
                    } else {
                        const googleUser = googleAuth.currentUser.get()
                        return googleUser.reloadAuthResponse().then((response) => {
                            dispatch(
                                actions.setGoogleUser({
                                    code,
                                    user: googleUser,
                                }),
                            )
                            return resolve({
                                googleAccessToken: response.access_token,
                                googleId: googleUser.getId(),
                            })
                        })
                    }
                })
            })
            .then((request) => {
                return getGoogleAccounts().then((accounts) => {
                    if (gmbAccountId) {
                        const accountExist = accounts.find((account) => account.id === gmbAccountId)
                        if (accountExist) {
                            return confirmMultipleGoogleMyBusinessAccountsSelectionInternal(gmbAccountId)
                                .then(() => getGooglePages({}))
                                .then((response) => {
                                    dispatch(actions.setGooglePages(response))
                                    const pageExist = response.find(
                                        (page) => page.id === establishment?.googleId,
                                    )
                                    if (pageExist) {
                                        throw new GoogleMyBusinessPageExist()
                                    }
                                })
                        }
                    }
                    if (accounts.length > 1) {
                        throw new MultipleGoogleMyBusinessAccounts(accounts)
                    } else {
                        return getGooglePages(request).then((response) => {
                            dispatch(actions.setGooglePages(response))
                            setInProgress(false)
                        })
                    }
                })
            })
            .catch((error) => {
                dispatch(actions.setGooglePages([]))
                setInProgress(false)
                throw error
            })
    }

    const confirmMultipleGoogleMyBusinessAccountsSelectionInternal = (id: string) => {
        return updateUser({
            googleID: id,
        })
            .then(() => getGooglePages({}))
            .then((response) => {
                dispatch(actions.setGooglePages(response))
                return response
            })
            .catch((error) => {
                dispatch(actions.setGooglePages([]))
                throw error
            })
    }

    const confirmMultipleGoogleMyBusinessAccountsSelection = (id?: string) => {
        if (id === undefined) {
            setInProgress(false)
            return Promise.reject()
        } else {
            return confirmMultipleGoogleMyBusinessAccountsSelectionInternal(id).finally(() => {
                setInProgress(false)
            })
        }
    }

    const googleCallback = (code: string, state: GmbState) => {
        setInProgress(true)
        const { accountId: gmbAccountId, pageId } = state
        return renewGoogleSignIn({
            googleOfflineCode: code,
            mode: 'callback',
        })
            .then(() => {
                return getGoogleAccounts().then((accounts) => {
                    if (gmbAccountId) {
                        const accountExist = accounts.find((account) => account.id === gmbAccountId)
                        if (accountExist) {
                            return confirmMultipleGoogleMyBusinessAccountsSelectionInternal(gmbAccountId)
                                .then(() => getGooglePages({}))
                                .then((response) => {
                                    dispatch(actions.setGooglePages(response))
                                    const pageExist = response.find((page) => page.id === pageId)
                                    if (pageExist) {
                                        throw new GoogleMyBusinessPageExist()
                                    }
                                })
                        }
                    }
                    if (accounts.length > 1) {
                        throw new MultipleGoogleMyBusinessAccounts(accounts)
                    } else {
                        return getGooglePages({}).then((response) => {
                            dispatch(actions.setGooglePages(response))
                        })
                    }
                })
            })
            .catch((error) => {
                dispatch(actions.setGooglePages([]))
                throw error
            })
            .finally(() => {
                setInProgress(false)
            })
    }

    return {
        fetchGoogleMyBusinessPagesAction,
        confirmMultipleGoogleMyBusinessAccountsSelection,
        getGooglePages,
        googleCallback,
        inProgress:
            getGooglePagesInProgress ||
            renewGoogleSignInInProgress ||
            inProgress ||
            getGoogleAccountsInProgress,
        googlePages,
    }
}
