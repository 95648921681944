import { Button, Hidden, Tooltip, Typography } from '@material-ui/core'
import { GridColDef, GridRowParams, GridSortModel, GridValueFormatterParams } from '@material-ui/data-grid'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Booking as BookingModel, BookingStatus } from '@sugg-gestion/suggpro-graph-module'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { usePhoneNumber } from '../../../core/services/phone/usePhoneNumber'
import ResponsibleDataGrid from '../../common/responsibleDataGrid'
import SubmitButton from '../../common/submitButton'
import { useStyles } from './styles.css'

interface Props {
    bookings: Array<BookingModel>
    loading: boolean
    acceptBookingInProgress?: string
    rejectBookingInProgress?: string
    onAcceptBooking: (bookingId: string) => void
    onRejectBooking: (bookingId: string) => void
    onRefresh: () => void
}

const BookingView: React.FC<Props> = ({
    bookings,
    acceptBookingInProgress,
    onAcceptBooking,
    rejectBookingInProgress,
    onRejectBooking,
    loading,
    onRefresh,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { validPhone, getPhoneNumber, displayPhone } = usePhoneNumber()
    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'createdAt',
            sort: 'desc',
        },
    ])

    const handleUpdateSortModel = (model: GridSortModel) => {
        if (!_.isEqual(model, sortModel)) {
            setSortModel(model)
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'createdAt',
            headerName: t('views.booking.createdAt'),
            width: 150,
            type: 'dateTime',
            renderCell: ({ value }) => {
                const date = moment(value as string)
                return date.format('DD/MM/yy à HH:mm')
            },
        },
        {
            field: 'name',
            headerName: t('views.booking.name'),
            width: 150,
            renderCell: (params: GridValueFormatterParams) => {
                const booking = params.row as BookingModel
                return booking.givenName + ' ' + booking.familyName
            },
        },
        {
            field: 'email',
            headerName: t('views.booking.email'),
            width: 250,
            renderCell: ({ value }) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Button variant="outlined" className={classes.iconButton} href={'mailto:' + value}>
                            <EmailIcon fontSize="small" />
                        </Button>
                        <span title={value as string}>{value}</span>
                    </div>
                )
            },
        },
        {
            field: 'phone',
            headerName: t('views.booking.phone'),
            width: 150,
            renderCell: ({ value }) => {
                if (validPhone(value as string | undefined)) {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Button
                                variant="outlined"
                                className={classes.iconButton}
                                href={getPhoneNumber(value as string | undefined) ?? ''}
                            >
                                <PhoneIcon fontSize="small" />
                            </Button>
                            <span title={displayPhone(value as string | undefined)}>
                                {displayPhone(value as string | undefined)}
                            </span>
                        </div>
                    )
                }
                return value
            },
        },
        { field: 'guestsNumber', headerName: t('views.booking.guests'), width: 150 },
        {
            field: 'bookingDate',
            headerName: t('views.booking.date'),
            width: 150,
            type: 'dateTime',
            renderCell: ({ value }) => {
                const date = moment(value as string)
                return date.format('DD/MM/yy à HH:mm')
            },
        },
        {
            field: 'comment',
            sortable: false,
            headerName: t('views.booking.comment'),
            width: 330,
            renderCell: ({ value }) => (
                <>
                    <Hidden only={['xs', 'sm']}>
                        <Tooltip title={<Typography>{value}</Typography>}>
                            <span className={classes.text}>{value}</span>
                        </Tooltip>
                    </Hidden>
                    <Hidden only={['md', 'lg', 'xl']}>
                        <Typography>{value}</Typography>
                    </Hidden>
                </>
            ),
        },
        {
            field: 'actions',
            filterable: false,
            sortable: false,
            headerName: t('views.booking.validation'),
            cellClassName: classes.actions,
            width: 160,
            renderCell: (params: GridValueFormatterParams) => {
                const booking = params.row as BookingModel
                const acceptButton = (
                    <SubmitButton
                        loading={acceptBookingInProgress === booking.id}
                        onClick={() => onAcceptBooking(booking.id)}
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={loading || rejectBookingInProgress === booking.id}
                    >
                        <Trans i18nKey="actions.accept" />
                    </SubmitButton>
                )
                const rejectButton = (
                    <SubmitButton
                        loading={rejectBookingInProgress === booking.id}
                        onClick={() => onRejectBooking(booking.id)}
                        variant="contained"
                        color="secondary"
                        size="small"
                        disabled={loading || acceptBookingInProgress === booking.id}
                    >
                        <Trans i18nKey="actions.reject" />
                    </SubmitButton>
                )
                switch (booking.status) {
                    case BookingStatus.New:
                        return (
                            <>
                                {acceptButton}
                                {rejectButton}
                            </>
                        )
                    case BookingStatus.Confirmed:
                        return <>{rejectButton}</>
                    case BookingStatus.Rejected:
                        return <>{acceptButton}</>
                }
            },
        },
    ]

    return (
        <>
            <div className={clsx(classes.header, classes.actions)}>
                <Typography variant="h1" className={classes.title}>
                    <Trans i18nKey="views.booking.bookings" />
                </Typography>
                <SubmitButton
                    loading={loading}
                    onClick={onRefresh}
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                >
                    <Trans i18nKey="actions.refresh" />
                </SubmitButton>
            </div>
            <div className={classes.root}>
                <div className={classes.tableContainer}>
                    <ResponsibleDataGrid
                        primaryKey="name"
                        secondaryKey="bookingDate"
                        className={classes.grid}
                        columns={columns}
                        rows={bookings}
                        pageSize={25}
                        rowsPerPageOptions={[25]}
                        loading={loading}
                        disableSelectionOnClick
                        sortModel={sortModel}
                        onSortModelChange={handleUpdateSortModel}
                        getRowClassName={(params: GridRowParams) => {
                            return params.row.status === BookingStatus.Confirmed
                                ? classes.confirmed
                                : params.row.status === BookingStatus.Rejected
                                ? classes.rejected
                                : classes.new
                        }}
                        getPrimaryHeaderClassName={(params: GridRowParams) => {
                            return params.row.status === BookingStatus.Confirmed
                                ? classes.confirmed
                                : params.row.status === BookingStatus.Rejected
                                ? classes.rejected
                                : classes.new
                        }}
                        getSecondaryHeaderClassName={(params: GridRowParams) => {
                            return params.row.status === BookingStatus.Confirmed
                                ? classes.confirmed
                                : params.row.status === BookingStatus.Rejected
                                ? classes.rejected
                                : classes.new
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default BookingView
