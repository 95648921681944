import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
        },
        mainSection: {
            marginBottom: theme.spacing(6),
        },
        title: {
            paddingBottom: theme.spacing(4),
            display: 'inline-flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        section: {
            padding: theme.spacing(1, 0),
        },
        sectionTitle: {
            fontWeight: theme.fontsWeight.semiBold,
            position: 'relative',
            fontSize: '1rem',
        },
        sectionSpacing: {
            marginBottom: theme.spacing(),
        },
        sectionContent: {
            overflowWrap: 'anywhere',
            [theme.breakpoints.down('xs')]: {
                fontSize: '0.9rem',
            },
        },
        sectionContentContainer: {
            paddingLeft: theme.spacing(),
        },
        action: {
            width: 160,
            minHeight: 0,
            maxWidth: '100%',
            [theme.breakpoints.down('xs')]: {
                width: 120,
            },
        },
        iconButton: {
            position: 'absolute',
            backgroundColor: theme.palette.border.contrastText,
            padding: theme.spacing(0.5),
            minWidth: 0,
            minHeight: 0,
            height: 28,
            width: 28,
            marginLeft: theme.spacing(2),
        },
        icon: {
            fontSize: '1.4rem',
        },
        iconSuccess: {
            color: theme.palette.success.main,
        },
        date: {
            fontWeight: theme.fontsWeight.semiBold,
            padding: theme.spacing(0, 1),
            lineHeight: 1.7,
        },
        textCenter: {
            textAlign: 'center',
        },
        reuseDescription: {
            fontStyle: 'italic',
            fontSize: '0.9rem',
        },
        mainPicture: {
            visibility: 'hidden',
            textAlign: 'center',
            alignContent: 'center',
            color: theme.palette.primary.contrastText,
            fontSize: '0.9rem',
            fontWeight: theme.fontsWeight.semiBold,
            backgroundColor: theme.palette.primary.main,
            padding: theme.spacing(0.5, 0),
        },
        mainPictureVisible: {
            visibility: 'visible',
        },
        mainPictureContainer: {
            borderWidth: 3,
            borderStyle: 'solid',
            borderColor: 'transparent',
        },
        mainPictureContainerVisible: {
            borderWidth: 3,
            borderStyle: 'solid',
            borderColor: theme.palette.primary.main,
            borderRadius: 4,
        },
        remplisVert: {
            color: '#218a36',
        },
    }),
)
