import { createAction } from 'typesafe-actions'
import {
    FacebookPageData,
    FacebookPagesData,
    GooglePageData,
    GooglePagesData,
    ShareActionTypes,
} from './types'
import { Establishment, Slate } from '@sugg-gestion/ubidreams-react-suggpro'

// Facebook
export const reauthenticateFacebook = createAction(ShareActionTypes.REAUTHENTICATE_FACEBOOK)()
export const shareSlateOnFacebookResolver = createAction(ShareActionTypes.SHARE_SLATE_ON_FACEBOOK_RESOLVER)<
    (value?: PromiseLike<undefined> | undefined) => void
>()
export const shareSlateOnFacebook = createAction(ShareActionTypes.SHARE_SLATE_ON_FACEBOOK)<{
    slate: Slate
    establishment: Establishment
}>()
export const setIsSharingSlateOnFacebook = createAction(
    ShareActionTypes.SET_IS_SHARING_SLATE_ON_FACEBOOK,
)<boolean>()

export const setFacebookPagesData = createAction(ShareActionTypes.SET_FACEBOOK_PAGES_DATA)<
    FacebookPagesData | undefined
>()
export const selectFacebookPageData = createAction(ShareActionTypes.SELECT_FACEBOOK_PAGE_DATA)<
    FacebookPageData | undefined
>()

// Google
export const reauthenticateGoogle = createAction(ShareActionTypes.REAUTHENTICATE_GOOGLE)()
export const shareSlateOnGoogleResolver = createAction(ShareActionTypes.SHARE_SLATE_ON_GOOGLE_RESOLVER)<
    (value?: PromiseLike<undefined> | undefined) => void
>()
export const shareSlateOnGoogle = createAction(ShareActionTypes.SHARE_SLATE_ON_GOOGLE)<{
    slate: Slate
    establishment: Establishment
}>()
export const setIsSharingSlateOnGoogle = createAction(
    ShareActionTypes.SET_IS_SHARING_SLATE_ON_GOOGLE,
)<boolean>()

export const setGooglePagesData = createAction(ShareActionTypes.SET_GOOGLE_PAGES_DATA)<
    GooglePagesData | undefined
>()
export const selectGooglePageData = createAction(ShareActionTypes.SELECT_GOOGLE_PAGE_DATA)<
    GooglePageData | undefined
>()
