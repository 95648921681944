import React from 'react'
import { Button, CardActions, Container, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { Trans } from 'react-i18next'
import AuthenticateContent from 'components/layouts/components/authenticateContent'
import { useStyles } from './accountView.css'
import { User } from '@sugg-gestion/ubidreams-react-suggpro'
import WarningIcon from '@material-ui/icons/Warning'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import clsx from 'clsx'
import AuthenticateActions from 'components/layouts/components/authenticateActions'
import parsePhoneNumberFromString from 'libphonenumber-js'
import { Link } from 'react-router-dom'
import SubmitButton from 'components/common/submitButton'
import GoogleIcon from '../../icons/googleIcon'
import FacebookIcon from '@material-ui/icons/Facebook'

interface Props {
    user: User
    goToPortal: () => void
    inProgress: boolean
    dissociateFacebook: () => void
    dissociateFacebookInProgress: boolean
    dissociateGoogle: () => void
    dissociateGoogleInProgress: boolean
}

const AccountView: React.FC<Props> = ({
    user,
    goToPortal,
    inProgress,
    dissociateFacebook,
    dissociateFacebookInProgress,
    dissociateGoogle,
    dissociateGoogleInProgress,
}) => {
    const classes = useStyles()

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

    const phone = parsePhoneNumberFromString(user.phoneNumber ?? '', 'FR')

    const actions = [
        <Button
            key={0}
            variant="contained"
            color="primary"
            className={classes.action}
            component={Link}
            to={'/account/edit'}
        >
            <Trans i18nKey="actions.editAccount" />
        </Button>,
    ]
    if (user.stripeCustomerId) {
        actions.push(
            <SubmitButton key={1} variant="outlined" onClick={goToPortal} loading={inProgress}>
                <Trans i18nKey="actions.updateSubscription" />
            </SubmitButton>,
        )
    }

    return (
        <>
            <Container disableGutters maxWidth="md" className={classes.root}>
                <AuthenticateContent withAction={fullScreen}>
                    <Typography variant="h1" className={classes.title}>
                        <Trans i18nKey="views.account.title" />
                        {!fullScreen && <CardActions>{actions}</CardActions>}
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <div className={classes.section}>
                                <Typography className={classes.sectionTitle}>
                                    <Trans i18nKey="views.account.email" />
                                </Typography>
                                <Typography className={classes.sectionContent}>
                                    {user?.email}

                                    {user?.hasEmailValidated ? (
                                        <CheckBoxIcon className={classes.success} />
                                    ) : (
                                        <WarningIcon className={classes.error} />
                                    )}
                                </Typography>
                            </div>
                            <div className={clsx(classes.section, classes.helper)}>
                                {user?.hasEmailValidated ? (
                                    <Typography className={classes.success}>
                                        <Trans i18nKey="views.account.isValidated" />
                                    </Typography>
                                ) : (
                                    <Typography className={classes.error}>
                                        <Trans i18nKey="views.account.isNotValidated" />
                                    </Typography>
                                )}
                            </div>
                            <div className={classes.section}>
                                <Typography className={classes.sectionTitle}>
                                    <Trans i18nKey="views.account.given_name" />
                                </Typography>
                                <Typography className={classes.sectionContent}>{user?.firstname}</Typography>
                            </div>
                            <div className={classes.section}>
                                <Typography className={classes.sectionTitle}>
                                    <Trans i18nKey="views.account.family_name" />
                                </Typography>
                                <Typography className={classes.sectionContent}>{user?.lastname}</Typography>
                            </div>
                            <div className={classes.section}>
                                <Typography className={classes.sectionTitle}>
                                    <Trans i18nKey="views.account.phone" />
                                </Typography>
                                <Typography className={classes.sectionContent}>
                                    {phone?.formatNational()}
                                </Typography>
                            </div>
                            {user.isFacebookSigned && (
                                <div className={clsx(classes.section, classes.sectionSocial)}>
                                    <Typography className={classes.sectionTitle}>
                                        <Trans i18nKey="views.account.facebook" />
                                    </Typography>
                                    <Typography className={classes.sectionContent}>
                                        <SubmitButton
                                            variant="outlined"
                                            onClick={dissociateFacebook}
                                            loading={dissociateFacebookInProgress}
                                            className={classes.buttonDanger}
                                            startIcon={<FacebookIcon />}
                                        >
                                            <Trans i18nKey="actions.dissociateFacebook" />
                                        </SubmitButton>
                                    </Typography>
                                </div>
                            )}
                            {user.isGoogleSigned && (
                                <div
                                    className={clsx(classes.section, {
                                        [classes.sectionSocial]: !user.isFacebookSigned,
                                    })}
                                >
                                    <Typography className={classes.sectionTitle}>
                                        <Trans i18nKey="views.account.google" />
                                    </Typography>
                                    <Typography className={classes.sectionContent}>
                                        <SubmitButton
                                            variant="outlined"
                                            onClick={dissociateGoogle}
                                            loading={dissociateGoogleInProgress}
                                            className={classes.buttonDanger}
                                            startIcon={<GoogleIcon />}
                                        >
                                            <Trans i18nKey="actions.dissociateGoogle" />
                                        </SubmitButton>
                                    </Typography>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </AuthenticateContent>
                {fullScreen && <AuthenticateActions>{actions.reverse()}</AuthenticateActions>}
            </Container>
        </>
    )
}

export default AccountView
