import React from 'react'
import PropTypes from 'prop-types'
import { Box, Tab, Tabs, Typography } from '@material-ui/core'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../styles.css'
import ListConversation from './listConversation'
import { Establishment, MessagingConversation } from '@sugg-gestion/ubidreams-react-suggpro'
import { useFetchConversations } from '../../../../core/services/messaging/useFetchConversations'
import Loader from '../../../common/loader'

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box style={{ padding: '3px' }}>{children}</Box>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index: number) {
    return {
        'id': `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

interface Props {
    establishment: Establishment
    conversationSelected?: MessagingConversation
    onSelect: (conversation: MessagingConversation) => void
}

const TabTypeConversation: React.FC<Props> = ({ establishment, conversationSelected, onSelect }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [value, setValue] = React.useState(0)
    const type: ('private_message' | 'post' | 'review')[] = ['private_message', 'post', 'review']

    // Handle change tab
    const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
        setValue(newValue)
    }

    // Get conversation by type
    const { privateMessages, posts, reviews, inProgress } = useFetchConversations(establishment)

    // Function to display list of conversations or error msg
    function generateListConversation(
        inProgress: boolean,
        conversations: MessagingConversation[] | undefined,
    ) {
        if (!inProgress && conversations && conversations.length > 0) {
            return (
                <ListConversation
                    listConversation={conversations}
                    conversationSelected={conversationSelected}
                    onSelect={onSelect}
                />
            )
        } else if (!inProgress && conversations && conversations.length === 0) {
            return (
                <div className={classes.loaderContainer}>
                    <Typography>{t('views.messaging.empty')}</Typography>
                </div>
            )
        } else {
            return (
                <div className={classes.loaderContainer}>
                    <Loader
                        classes={{ main: classes.loaderConversation }}
                        withLogo={false}
                        text={t('views.messaging.loader')}
                    />
                </div>
            )
        }
    }

    return (
        <Box style={{ width: '100%' }}>
            <Box style={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    variant="scrollable"
                    scrollButtons={'auto'}
                    indicatorColor={'primary'}
                    onChange={handleChange}
                    aria-label="tab type conversation"
                >
                    {type.map((typeConv) => {
                        return (
                            <Tab
                                key={typeConv}
                                className={classes.tab}
                                label={i18next.t('views.messaging.tab.' + typeConv)}
                                title={i18next.t('views.messaging.tab.' + typeConv)}
                                {...a11yProps(0)}
                            />
                        )
                    })}
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Box className={classes.conversationScrollBox}>
                    {generateListConversation(inProgress, privateMessages)}
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box className={classes.conversationScrollBox}>
                    {generateListConversation(inProgress, posts)}
                </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Box className={classes.conversationScrollBox}>
                    {generateListConversation(inProgress, reviews)}
                </Box>
            </TabPanel>
        </Box>
    )
}
export default TabTypeConversation
