import React from 'react'
import Loader from 'components/common/loader'
import AuthenticateContent from 'components/layouts/components/authenticateContent'
import { Typography } from '@material-ui/core'
import { useStyles } from './authenticatedLoading.css'

interface Props {
    loadingMessage: string
    title: string
}

const AuthenticatedLoading: React.FC<Props> = ({ loadingMessage, title }) => {
    const classes = useStyles()
    return (
        <AuthenticateContent withAction={false}>
            <Typography variant="h1" color="textSecondary" className={classes.title}>
                {title}
            </Typography>
            <div className={classes.loadingView}>
                <Loader withLogo={false} text={loadingMessage} />
            </div>
        </AuthenticateContent>
    )
}

export default AuthenticatedLoading
