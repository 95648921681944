// import { Client } from '@sugg-gestion/suggpro-graph-module'
import {
    actions as SuggProActions,
    Establishment,
    FacebookSessionExpired,
    FacebookTokenExpired,
    FacebookTokenRequired,
    FacebookWrongPermissions,
    User,
} from '@sugg-gestion/ubidreams-react-suggpro'
import { history } from 'core/config/history'
import { get } from 'lodash'
import ReactPixel from 'react-facebook-pixel'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'
import actions from '../actions'
import { ApplicationState } from '../reducers'
import { NotificationErrorTypes } from './types'

function* appSagas() {
    // Facebook
    yield takeLatest(getType(SuggProActions.signOut), signOutFromFacebook)

    // Google
    yield takeLatest(getType(SuggProActions.signOut), signOutFromGoogle)

    // User
    yield takeLatest(getType(SuggProActions.start), start)
    yield takeLatest(getType(SuggProActions.setUser), start)
    yield takeLatest(getType(SuggProActions.signOut), end)
    yield takeLatest(getType(SuggProActions.setEstablishment), updateUser)
    // yield takeLatest(getType(SuggProActions.signIn), initializeGraphApi)

    // Notistack
    yield takeLatest(getType(actions.enqueueError), enqueueError)

    // Router
    yield takeLatest('@@router/LOCATION_CHANGE', redirect)
    yield takeLatest('@@router/LOCATION_CHANGE', scrollToTop)
    yield takeLatest('@@router/LOCATION_CHANGE', trackFacebook)
    yield takeLatest(getType(actions.redirectToEstablishment), redirectToEstablishmentAction)
}

function* signOutFromFacebook() {
    if (typeof FB !== 'undefined') {
        yield call([FB, 'getLoginStatus'], (response) => {
            if (response.status === 'connected') {
                FB.logout()
            }
        })
    }
    yield put(actions.setFacebookUser(undefined))
    yield put(actions.setFacebookPages(undefined))
}

function* signOutFromGoogle() {
    const { googleAuth } = yield select(({ app }: ApplicationState) => ({
        googleAuth: app.googleAuth,
    }))
    if (googleAuth && googleAuth.isSignedIn.get()) {
        googleAuth.signOut()
    }
    yield put(actions.setGoogleUser(undefined))
    yield put(actions.setGooglePages(undefined))
}

function* enqueueError(action: ActionType<typeof actions.enqueueError>) {
    const { error, errorType, actionType } = action.payload
    let message = 'error.unexpected'

    if (
        error instanceof FacebookWrongPermissions ||
        error instanceof FacebookTokenExpired ||
        error instanceof FacebookTokenRequired ||
        error instanceof FacebookSessionExpired
    ) {
    }
    switch (errorType) {
        case NotificationErrorTypes.SIGN_IN:
            if (get(error, 'data.non_field_errors[0]', '') === 'User account is disabled.') {
                message = 'sign-in.error.disabled'
            } else if (
                get(error, 'data.non_field_errors[0]', '') === 'Unable to login with provided credentials.'
            ) {
                message = 'sign-in.error.wrongPassword'
            } else if (get(error, 'data', '') === 'Connection error') {
                message = 'sign-in.error.invalidFacebook'
            } else if (get(error, 'data', '') === 'User does not exist') {
                message = 'sign-in.error.invalidGoogle'
            }
    }
    yield put(
        actions.enqueueSnackbar({
            message,
            options: {
                variant: 'error',
            },
            actionType,
        }),
    )
}

function* redirect() {
    if (history.location.pathname === '/') {
        const { user, establishments } = yield select(
            ({ suggpro: { auth, establishment } }: ApplicationState) => ({
                user: auth.user,
                establishments: establishment.establishments,
            }),
        )
        if (!user) {
            console.log(history.location.pathname)
            yield call([history, 'push'], '/auth/sign-in')
        } else {
            if (!user.hasEmailValidated) {
                history.push('/account/email-validation')
            } else if (!user.fullname) {
                yield call([history, 'push'], '/account/edit')
            } else if (user.facebookTokenExpired) {
                yield call([history, 'push'], '/account/sign')
            } else if (user.googleTokenExpired) {
                yield call([history, 'push'], '/account/sign')
            } else {
                yield redirectToEstablishment(user, establishments)
            }
        }
    }
}

function* scrollToTop() {
    yield call([window, 'scrollTo'], 0, 0)
}

function* trackFacebook() {
    yield call([ReactPixel, 'pageView'])
}

function* start(action: ActionType<typeof SuggProActions.start>) {
    const started = action.payload
    if (started) {
        const { user, establishments } = yield select(
            ({ suggpro: { auth, establishment } }: ApplicationState) => ({
                user: auth.user,
                establishments: establishment.establishments,
            }),
        )
        if (!user) {
            if (
                !history.location.pathname.startsWith('/auth') &&
                !history.location.pathname.startsWith('/oauth')
            ) {
                yield call([history, 'push'], '/auth/sign-in')
            }
        } else {
            if (!user.hasEmailValidated) {
                yield call([history, 'push'], '/account/email-validation')
            } else if (!user.fullname) {
                yield call([history, 'push'], '/account/edit')
                yield put(
                    actions.enqueueSnackbar({
                        message: 'edit-account.mandatoryName',
                        options: {
                            variant: 'info',
                        },
                        actionType: undefined,
                    }),
                )
            } else if (user.facebookTokenExpired) {
                yield call([history, 'push'], '/account/sign')
            } else if (user.googleTokenExpired) {
                yield call([history, 'push'], '/account/sign')
            } else {
                if (history.location.pathname === '/' || history.location.pathname.startsWith('/auth')) {
                    yield redirectToEstablishment(user, establishments)
                }
                // else if (history.location.pathname === '/account/sign') {
                //     if (
                //         user.isFacebookSigned &&
                //         !user.isFacebookSignedExpired &&
                //         user.isGoogleSigned &&
                //         !user.isGoogleSignedExpired
                //     ) {
                //         yield redirectToEstablishment(user, establishments)
                //     } else if (
                //         user.isFacebookSigned &&
                //         !user.isFacebookSignedExpired &&
                //         !user.isGoogleSigned
                //     ) {
                //         yield redirectToEstablishment(user, establishments)
                //     } else if (!user.isFacebookSigned && user.isGoogleSigned && !user.isGoogleSignedExpired) {
                //         yield redirectToEstablishment(user, establishments)
                //     }
                // }
            }
        }
    }
}

function* updateUser() {
    const { user } = yield select(({ suggpro: { auth } }: ApplicationState) => ({
        user: auth.user,
    }))
    yield put(SuggProActions.setUser(user))
}

function* redirectToEstablishmentAction() {
    const { user, establishments } = yield select(
        ({ suggpro: { auth, establishment } }: ApplicationState) => ({
            user: auth.user,
            establishments: establishment.establishments,
        }),
    )
    yield redirectToEstablishment(user, establishments)
}

function* redirectToEstablishment(user: User, establishments: Array<Establishment>) {
    if (establishments !== undefined) {
        // TODO: manage multiple establishments
        for (let i = 0; i < establishments.length; i++) {
            const establishment: Establishment = establishments[i]
            if (establishment.status !== null) {
                switch (establishment.status.name) {
                    case 'DONE':
                        yield call(
                            [history, 'push'],
                            '/establishment/:id/slate'.replace(':id', establishment.id.toString()),
                        )
                        break
                    case 'SIRET':
                        yield call(
                            [history, 'push'],
                            '/establishment/:id/duns'.replace(':id', establishment.id.toString()),
                        )
                        break
                    case 'PAYMENT':
                        yield call(
                            [history, 'push'],
                            '/establishment/:id/payment'.replace(':id', establishment.id.toString()),
                        )
                        break
                    case 'PAID':
                        yield call(
                            [history, 'push'],
                            '/establishment/:id/add/facebook-google'.replace(
                                ':id',
                                establishment.id.toString(),
                            ),
                        )
                        break
                    case 'SOCIAL_NETWORK':
                        yield call(
                            [history, 'push'],
                            '/establishment/:id/add/social-networks'.replace(
                                ':id',
                                establishment.id.toString(),
                            ),
                        )
                        break
                    case 'ESTABLISHMENT':
                        yield call(
                            [history, 'push'],
                            '/establishment/:id/add/details'.replace(':id', establishment.id.toString()),
                        )
                        break
                    case 'FOOD':
                        yield call(
                            [history, 'push'],
                            '/establishment/:id/add/food-type'.replace(':id', establishment.id.toString()),
                        )
                        break
                    case 'LOCATION':
                        yield call(
                            [history, 'push'],
                            '/establishment/:id/add/location'.replace(':id', establishment.id.toString()),
                        )
                        break
                    case 'HOURS':
                        yield call(
                            [history, 'push'],
                            '/establishment/:id/add/hours'.replace(':id', establishment.id.toString()),
                        )
                        break
                    case 'PHOTOS':
                        yield call(
                            [history, 'push'],
                            '/establishment/:id/add/photos'.replace(':id', establishment.id.toString()),
                        )
                        break
                    default:
                        yield call(
                            [history, 'push'],
                            '/establishment/:id/duns'.replace(':id', establishment.id.toString()),
                        )
                        break
                }
            } else {
                yield call(
                    [history, 'push'],
                    '/establishment/:id/duns'.replace(':id', establishment.id.toString()),
                )
            }
            break
        }
    } else {
        yield call([history, 'push'], '/establishments/undefined')
    }
}

function* end() {
    yield call([history, 'push'], '/auth/sign-in')
}

// function* initializeGraphApi(action: ActionType<typeof SuggProActions.signIn>) {
//     const { graphApiClient } = yield select(({ app }: ApplicationState) => ({
//         graphApiClient: app.graphApiClient,
//     }))
//     if (!graphApiClient) {
//         const {
//             REACT_APP_GRAPH_API_URI,
//             REACT_APP_GRAPH_API_KEY,
//             REACT_APP_OAUTH_API_URI,
//             REACT_APP_OAUTH_CLIENT_ID,
//             REACT_APP_OAUTH_CLIENT_SECRET,
//         } = process.env
//         const graphApiClient = new Client(
//             REACT_APP_GRAPH_API_URI ?? '',
//             REACT_APP_GRAPH_API_KEY ?? '',
//             REACT_APP_OAUTH_API_URI ?? '',
//         )
//         try {
//             yield call(
//                 [graphApiClient, 'authenticateByToken'],
//                 action.payload.access,
//                 REACT_APP_OAUTH_CLIENT_ID ?? '',
//                 REACT_APP_OAUTH_CLIENT_SECRET ?? '',
//             )
//             yield put(actions.setGraphApiClient(graphApiClient))
//         } catch (e) {}
//     }
// }

export default appSagas
