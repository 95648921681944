import { useEstablishmentUpdate } from '@sugg-gestion/ubidreams-react-suggpro'
import BookingView from 'components/views/booking/bookingView'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import moment from 'moment'
import React from 'react'
import withAuthenticateLayout, { WithAuthenticateLayout } from '../hoc/withAuthenticateLayout'
import { WithGraphApiProps } from '../hoc/withGraphApi'
import withMuiPickersUtilsProvider from '../hoc/withMuiPickersUtilsProvider'
import BookingData from './bookingData'

interface Props extends WithAuthenticateLayout, WithGraphApiProps {}

const Booking: React.FC<Props> = ({ establishment, isGraphApiAuthenticated }) => {
    const { updateEstablishment, inProgress: bookingInProgress } = useEstablishmentUpdate()
    const { displayError } = useApiErrors()

    // const { graphApiClient } = useSelector(({ app }: ApplicationState) => ({
    //     graphApiClient: app.graphApiClient,
    // }))

    // const [bookings, setBookings] = useState<Array<BookingModel> | undefined>(undefined)

    const handleFullDayUpdate = (days: Array<string>) => {
        updateEstablishment({
            id: establishment.id,
            establishment: {
                bookingFullDays: days,
            },
        }).catch((error) => displayError(error))
    }

    // useEffect(() => {
    //     if (bookings === undefined && graphApiClient !== undefined) {
    //         getBookings(establishment.id.toString())
    //             .then((response) => {
    //                 setBookings(response.bookings)
    //             })
    //             .catch((e) => {
    //                 displayError(e)
    //                 setBookings([])
    //             })
    //         // graphApiClient
    //         //     .getBookings(establishment.id.toString())
    //         //     .then((response) => {
    //         //         setBookings(response.bookings)
    //         //     })
    //         //     .catch((e) => {
    //         //         displayError(e)
    //         //         setBookings([])
    //         //     })
    //     }
    // }, [bookings, displayError, establishment.id, getBookings, graphApiClient])

    return (
        <>
            <BookingView
                fullDays={
                    establishment.bookingFullDays
                        ?.filter((day) => moment(day).isSameOrAfter(moment(), 'day'))
                        .sort((d1, d2) => (moment(d1).isBefore(moment(d2)) ? -1 : 1)) ?? []
                }
                onFullDayUpdate={handleFullDayUpdate}
                bookingInProgress={bookingInProgress}
            >
                <BookingData isGraphApiAuthenticated={isGraphApiAuthenticated} />
            </BookingView>
        </>
    )
}

export default withMuiPickersUtilsProvider(withAuthenticateLayout(Booking))
