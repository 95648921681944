import loadImage from 'blueimp-load-image'

export interface Image {
    id: number
    url: string
    file?: File
}

export interface ImageOptions {
    maxWidth?: number
}

export const useImages = (options: ImageOptions = { maxWidth: 400 }) => {
    const updateImages = (files?: Array<string | File | undefined>, defaultValues?: Array<Image>) => {
        if (files === undefined) {
            return new Promise<Array<Image>>((resolve) => resolve(defaultValues || []))
        }
        const promises: Array<Promise<Image>> = []
        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            if (file !== undefined && file !== '') {
                if (typeof file == 'string') {
                    promises.push(
                        new Promise<Image>((resolve) => {
                            resolve({
                                id: i,
                                url: file,
                            })
                        }),
                    )
                } else {
                    promises.push(
                        new Promise<Image>((resolve, reject) => {
                            loadImage(
                                file,
                                (canvas: HTMLCanvasElement) => {
                                    // @ts-ignore
                                    if (canvas.type === 'error') {
                                        reject(canvas)
                                    } else {
                                        resolve({
                                            id: i,
                                            url: canvas.toDataURL(),
                                            file,
                                        })
                                    }
                                },
                                {
                                    orientation: true,
                                    meta: true,
                                    maxWidth: options.maxWidth,
                                    canvas: true,
                                },
                            )
                        }),
                    )
                }
            }
        }
        return Promise.all(promises).then((values) => {
            return [...(defaultValues || []), ...values]
        })
    }

    return {
        updateImages,
    }
}
