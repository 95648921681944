import { AnyAbility } from '@casl/ability'
import { Establishment, User } from '@sugg-gestion/ubidreams-react-suggpro'
import Loader from 'components/common/loader'
import { ApplicationState } from 'core/store/reducers'
import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { WithGraphApiProps } from './withGraphApi'

interface InputProps extends WithGraphApiProps {
    restaurantOwner: User
    ability: AnyAbility
}

interface OutputProps extends WithGraphApiProps {
    restaurantOwner: User
    establishment?: Establishment
    establishments: Establishment[]
    ability: AnyAbility
}

interface RouteParam {
    id?: string
}

const withEstablishment = (ComposedComponent: ComponentType<OutputProps>) => {
    const WithEstablishment: React.FC<InputProps> = ({ restaurantOwner, ...props }) => {
        const { establishments } = useSelector(({ suggpro: { establishment } }: ApplicationState) => ({
            establishments: establishment.establishments,
        }))
        let { id } = useParams<RouteParam>()
        const establishment =
            establishments && establishments.length > 0 && id !== undefined
                ? establishments.find((establishment) => establishment.id === (id ? parseInt(id) : 0))
                : undefined
        if (establishments && establishments.length > 0) {
            return (
                <ComposedComponent
                    restaurantOwner={restaurantOwner}
                    establishment={establishment}
                    establishments={establishments}
                    {...props}
                />
            )
        } else {
            return <Loader />
        }
    }
    return WithEstablishment
}

export default withEstablishment
