import { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flippableContainer: {
            position: 'relative',
            transition: 'transform 0.6s',
            transformStyle: 'preserve-3d',
        },
        flippable: {
            backfaceVisibility: 'hidden',
        },
        flipFront: {
            backgroundColor: theme.palette.common.white,
        },
        flip: {
            transform: 'rotateY(180deg)',
        },
        flipBack: {
            position: 'absolute',
            inset: 0,
            backfaceVisibility: 'hidden',
            transform: 'rotateY(180deg)',
            backgroundColor: theme.palette.common.white,
            overflow: 'auto',
        },
        show: {
            position: 'static',
            transform: 'none',
        },
    }),
)

export const useFlippable = () => {
    const classes = useStyles()
    const [displayImagesOptions, setDisplayImagesOptions] = useState<Array<number>>([])

    const onDisplayImagesOptions = (id: number, checked: boolean) => {
        const newDisplayImagesOptions = [...displayImagesOptions]
        if (checked) {
            newDisplayImagesOptions.push(id)
            setDisplayImagesOptions(newDisplayImagesOptions)
        } else {
            setDisplayImagesOptions(newDisplayImagesOptions.filter((val) => val !== id))
        }
    }
    const shouldDisplayImagesOptions = (id: number) => {
        return displayImagesOptions.includes(id)
    }

    return {
        classes,
        onDisplayImagesOptions,
        shouldDisplayImagesOptions,
    }
}
