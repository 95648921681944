import React from 'react'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import {
    Establishment,
    useEstablishmentTypes,
    useEstablishmentUpdate,
} from '@sugg-gestion/ubidreams-react-suggpro'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { DetailsFormState } from 'components/forms/establishment/detailsForm'
import _ from 'lodash'
import EstablishmentDetailsDialogView from 'components/views/establishment/dialogs/establishmentDetailsDialogView'
import { CountryCode } from 'libphonenumber-js/types'

interface Props {
    open: boolean
    onClose: () => void
    establishment: Establishment
}

const EstablishmentEditDetailsDialog: React.FC<Props> = ({ establishment, open, onClose }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const { displayError } = useApiErrors()
    const { updateEstablishment, inProgress } = useEstablishmentUpdate()
    const { establishmentTypes } = useEstablishmentTypes()

    const handleUpdateDetails = (values: DetailsFormState) => {
        updateEstablishment({
            id: establishment.id,
            establishment: {
                ...values,
                remplisVertComment: values.remplisVert ? values.remplisVertComment : '',
                shortDescription: values.shortDescription ?? null,
                phoneNumber: values.phone ?? null,
                email: values.email ?? null,
                DUNS: values.duns ?? null,
                type: values.type ? parseInt(values.type) : null,
            },
        })
            .then(() => {
                enqueueSnackbar(t('views.establishment.socialNetworks.success'), {
                    variant: 'success',
                })
                onClose()
            })
            .catch((error) => displayError(error))
    }

    const typeMandatory =
        _.defaultTo(establishment.facebookPageId, undefined) === undefined &&
        _.defaultTo(establishment.googleId, undefined) === undefined

    let type = ''
    if (establishment.type) {
        if (typeof establishment.type === 'number') {
            type = establishment.type.toString()
        } else {
            type = establishment.type.id.toString()
        }
    }
    return (
        <EstablishmentDetailsDialogView
            open={open}
            onClose={onClose}
            onSubmit={handleUpdateDetails}
            inProgress={inProgress}
            initialValues={{
                name: establishment.name ?? '',
                duns: establishment.DUNS ?? '',
                email: establishment.email ?? undefined,
                phone: establishment.phoneNumber ?? undefined,
                type: type,
                shortDescription: establishment.shortDescription ?? '',
                description: establishment.description ?? '',
                remplisVert: establishment.remplisVert,
                remplisVertComment: establishment.remplisVertComment ?? undefined,
            }}
            establishmentTypes={establishmentTypes}
            typeMandatory={typeMandatory}
            country={establishment.completeAddress.country as CountryCode}
            type={establishment.completeAddress.country === 'FR' ? 'duns' : 'europeanVat'}
        />
    )
}

export default EstablishmentEditDetailsDialog
