import useGoogleTagManager from 'core/services/tagManager/useGoogleTagManager'
import React, { ComponentType } from 'react'

const withGoogleTagManager = (ComposedComponent: ComponentType) => {
    const WithGoogleTagManager: React.FC = ({ ...props }) => {
        useGoogleTagManager()
        return <ComposedComponent {...props} />
    }
    return WithGoogleTagManager
}

export default withGoogleTagManager
