import React from 'react'
import { useFormsStyles } from '../forms.css'
import { Form } from 'react-final-form'
import { SubmitButtonProps } from 'components/common/submitButton'
import { Trans, useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'
import TextField from '../components/textField'
import { validateAWSEmail } from 'appsync-scalar-utils'
import EmailField from '../components/emailField'
import CountryField from '../components/countryField'
import { inputLength, isDunsValid } from 'core/services/vat/checkVat'
import { CountryCode } from 'libphonenumber-js/types'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: InvoiceAddressFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: InvoiceAddressFormState
}

export interface InvoiceAddressFormState {
    firstName: string
    lastName: string
    email: string
    name: string
    duns: string
    address_street_1: string
    address_street_2?: string
    address_zip_code: string
    address_city: string
    address_country: string
}

interface InvoiceAddressFormError {
    firstName?: string
    lastName?: string
    email?: string
    name?: string
    duns?: string
    address_street_1?: string
    address_street_2?: string
    address_zip_code?: string
    address_city?: string
    address_country?: string
}

const InvoiceAddressForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
        values: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()

    const validate = (values: InvoiceAddressFormState) => {
        const errors: InvoiceAddressFormError = {}

        if (!values.firstName) {
            errors.firstName = t('form-validation.firstName')
        } else if (values.firstName.length < 2) {
            errors.firstName = t('form-validation.firstNameLength')
        }

        if (!values.lastName) {
            errors.lastName = t('form-validation.lastName')
        } else if (values.lastName.length < 2) {
            errors.lastName = t('form-validation.lastNameLength')
        }

        if (!values.email) {
            errors.email = t('form-validation.email')
        } else if (!validateAWSEmail(values.email)) {
            errors.email = t('form-validation.emailValid')
        }

        if (!values.name) {
            errors.name = t('form-validation.establishmentName')
        }

        if (!isDunsValid((values.address_country as CountryCode) ?? 'FR', values.duns)) {
            const type: 'duns' | 'europeanVat' = values.address_country !== 'FR' ? 'europeanVat' : 'duns'
            errors.duns = t('form-validation.' + type)
        }
        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting, values }) => {
                const type: 'duns' | 'europeanVat' = values.address_country !== 'FR' ? 'europeanVat' : 'duns'
                return (
                    <form onSubmit={handleSubmit} noValidate className={formClassName}>
                        {children(
                            <Grid container spacing={4}>
                                <Grid item sm={6} xs={12}>
                                    <Typography className={formsClasses.sectionTitle}>
                                        <Trans i18nKey="views.account.title" />
                                    </Typography>
                                    <TextField
                                        label={t('form.firstName')}
                                        name="firstName"
                                        autoComplete="given-name"
                                        required
                                    />
                                    <TextField
                                        label={t('form.lastName')}
                                        name="lastName"
                                        autoComplete="family-name"
                                        required
                                    />
                                    <EmailField label={t('form.email')} name="email" required />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Typography className={formsClasses.sectionTitle}>
                                        <Trans i18nKey="views.establishment.details.title" />
                                    </Typography>
                                    <TextField
                                        label={t('form.establishmentName')}
                                        name="name"
                                        autoComplete="organization"
                                        required
                                    />
                                    <TextField
                                        name="duns"
                                        label={t('form.' + type)}
                                        type={type === 'duns' ? 'number' : 'text'}
                                        InputProps={{
                                            inputProps: {
                                                maxLength: inputLength(
                                                    (values.address_country as CountryCode) ?? 'FR',
                                                ),
                                            },
                                        }}
                                        required
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Typography className={formsClasses.sectionTitle}>
                                        <Trans i18nKey="views.establishment.location.title" />
                                    </Typography>
                                    <TextField
                                        label={t('form.companyAddressStreet1')}
                                        name="address_street_1"
                                        autoComplete="billing address-line1"
                                        required
                                    />
                                    <TextField
                                        label={t('form.companyAddressStreet2')}
                                        name="address_street_2"
                                        autoComplete="billing address-line2"
                                    />
                                    <TextField
                                        label={t('form.companyAddressZipCode')}
                                        name="address_zip_code"
                                        autoComplete="billing postal-code"
                                        required
                                    />
                                    <TextField
                                        label={t('form.companyAddressCity')}
                                        name="address_city"
                                        autoComplete="billing address-level2"
                                        required
                                    />
                                    <CountryField name="address_country" />
                                </Grid>
                            </Grid>,
                            [
                                {
                                    type: 'submit',
                                    loading: submitting || inProgress,
                                    className: formsClasses.submit,
                                    children: <Trans i18nKey="actions.next" />,
                                },
                            ],
                        )}
                    </form>
                )
            }}
        </Form>
    )
}
export default InvoiceAddressForm
