import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typeTitle: {
            fontSize: '1.2rem',
            fontWeight: theme.fontsWeight.semiBold,
            paddingBottom: theme.spacing(2),
        },
        formTitle: {
            fontWeight: theme.fontsWeight.semiBold,
            paddingBottom: theme.spacing(2),
        },
        noData: {
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'center',
            'minHeight': theme.spacing(20),
            '& > p': {
                marginRight: theme.spacing(),
            },
        },
        empty: {
            fontWeight: theme.fontsWeight.semiBold,
            fontSize: '2rem',
            textAlign: 'center',
            padding: theme.spacing(2, 0),
            marginBottom: theme.spacing(2),
            width: 460,
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                fontSize: '1.4rem',
            },
        },
        emptyContainer: {
            minHeight: 400,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('xs')]: {
                minHeight: 0,
                height: '100%',
            },
        },
        title: {
            paddingBottom: theme.spacing(2),
        },
        new: {
            marginLeft: theme.spacing(1),
            position: 'relative',
            top: -8,
        },
        primary: {
            color: theme.palette.primary.main,
        },
        newMessage: {
            paddingBottom: theme.spacing(3),
            fontSize: '0.9rem',
        },
        comment: {
            padding: theme.spacing(2),
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.border.main,
            borderRadius: theme.spacing(0.5),
            fontWeight: theme.fontsWeight.semiBold,
            fontSize: '0.9rem',
        },
        validityDate: {
            fontWeight: theme.fontsWeight.semiBold,
        },
        facebookIcon: {
            color: theme.socialColors.facebook,
        },
        instagramIcon: {
            color: theme.socialColors.instagram,
        },
        twitterIcon: {
            color: theme.socialColors.twitter,
        },
        action: {
            [theme.breakpoints.down('xs')]: {
                position: 'static',
                top: 'inherit',
                right: 'inherit',
                transform: 'inherit',
            },
        },
        shareButtons: {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: theme.spacing(0.5),
        },
    }),
)
