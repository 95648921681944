import {
    Button,
    DialogActions,
    Divider,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from '@material-ui/core'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { GooglePagesResponse } from '@sugg-gestion/ubidreams-react-suggpro'
import clsx from 'clsx'
import Loader from 'components/common/loader'
import SubmitButton from 'components/common/submitButton'
import { GmbAccountSelect } from 'core/services/google/useGoogleMyBusinessPages'
import React from 'react'
import { Trans } from 'react-i18next'
import { useStyles } from './googleCallbackView.css'

interface Props {
    inProgress: boolean
    updateEstablishmentInProgress: boolean
    shouldRetry: boolean
    handleRetrievePage: () => void
    gmbAccounts: GmbAccountSelect | null
    googlePages?: GooglePagesResponse
    onChooseAccount: (account: string) => void
    onChoosePage: (id: string) => void
    currentPage?: string
    onCancel: () => void
    onSwitchAccount: () => void
}

const GoogleCallbackView: React.FC<Props> = ({
    inProgress,
    shouldRetry,
    handleRetrievePage,
    googlePages,
    gmbAccounts,
    onChooseAccount,
    onChoosePage,
    currentPage,
    updateEstablishmentInProgress,
    onCancel,
    onSwitchAccount,
}) => {
    const classes = useStyles()
    if (inProgress) {
        return (
            <div className={classes.container}>
                <Loader withLogo={false} text={<Trans i18nKey="views.googleCallback.loading.message" />} />
            </div>
        )
    }
    if (shouldRetry) {
        return (
            <div className={clsx(classes.container, classes.shouldRetry)}>
                <Typography>
                    <Trans i18nKey="views.googleCallback.shouldRetry.message" />
                </Typography>
                <SubmitButton
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={handleRetrievePage}
                    className={classes.action}
                >
                    <Trans i18nKey="actions.googlePage" />
                </SubmitButton>
            </div>
        )
    }

    if (gmbAccounts && (googlePages === undefined || googlePages.length === 0)) {
        return (
            <>
                <List>
                    {gmbAccounts.accounts.map((account, index) => (
                        <React.Fragment key={index}>
                            <Divider component="li" />
                            <ListItem
                                button
                                onClick={() => onChooseAccount(account.id)}
                                classes={{
                                    secondaryAction: classes.withAction,
                                }}
                            >
                                <ListItemText
                                    primary={account.name}
                                    primaryTypographyProps={{
                                        color: 'textSecondary',
                                    }}
                                    secondary={<Trans i18nKey={'common.googleAccountType.' + account.type} />}
                                />
                                <ListItemSecondaryAction>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => onChooseAccount(account.id)}
                                    >
                                        <Trans i18nKey="actions.chooseAccount" />
                                    </Button>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </React.Fragment>
                    ))}
                    <Divider component="li" />
                    <ListItem button onClick={onCancel}>
                        <ListItemText
                            classes={{
                                root: classes.cancel,
                            }}
                        >
                            <Button variant="outlined" color="primary" onClick={onCancel}>
                                <Trans i18nKey="actions.choosePageCancel" />
                            </Button>
                        </ListItemText>
                    </ListItem>
                </List>
            </>
        )
    }

    return (
        <div className={classes.container}>
            <List>
                {googlePages?.map((page, index) => (
                    <React.Fragment key={index}>
                        <Divider component="li" />
                        <ListItem
                            button
                            onClick={() => onChoosePage(page.id)}
                            selected={currentPage === page.id}
                            classes={{
                                secondaryAction: classes.withAction,
                            }}
                            disabled={updateEstablishmentInProgress}
                        >
                            <ListItemText
                                primary={page.name}
                                primaryTypographyProps={{
                                    color: 'textSecondary',
                                }}
                                secondary={
                                    <>
                                        <Typography
                                            className={classes.categoryRow}
                                            component="span"
                                            variant="body2"
                                            color="textPrimary"
                                        >
                                            {page.category}
                                        </Typography>
                                        <Typography component="span" variant="body2" color="textPrimary">
                                            {page.address}
                                        </Typography>
                                    </>
                                }
                                classes={{
                                    primary: classes.pageTitle,
                                }}
                            />
                            <ListItemSecondaryAction>
                                <SubmitButton
                                    loading={updateEstablishmentInProgress}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => onChoosePage(page.id)}
                                >
                                    <Trans i18nKey="actions.choosePage" />
                                </SubmitButton>
                                {page.url && (
                                    <IconButton component={Link} edge="end" href={page.url} target="_blank">
                                        <OpenInNewIcon color="secondary" />
                                    </IconButton>
                                )}
                            </ListItemSecondaryAction>
                        </ListItem>
                    </React.Fragment>
                ))}
                <Divider component="li" />
                <ListItem button onClick={onCancel} disabled={updateEstablishmentInProgress}>
                    <ListItemText
                        classes={{
                            root: classes.cancel,
                        }}
                    >
                        <SubmitButton
                            disabled={updateEstablishmentInProgress}
                            variant="outlined"
                            color="primary"
                            onClick={onCancel}
                        >
                            <Trans i18nKey="actions.choosePageCancel" />
                        </SubmitButton>
                    </ListItemText>
                </ListItem>
                <Divider component="li" />
            </List>
            <DialogActions>
                <SubmitButton
                    variant="outlined"
                    size="large"
                    className={classes.action}
                    onClick={onSwitchAccount}
                >
                    <Trans i18nKey="actions.switchAccount" />
                </SubmitButton>
            </DialogActions>
        </div>
    )
}

export default GoogleCallbackView
