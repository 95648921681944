import React, { ReactNode } from 'react'
import CardContent from '@material-ui/core/CardContent'
import { CardMedia, Divider, IconButton, Typography } from '@material-ui/core'
import useStyles from './imageCard.css'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { useImgix } from 'core/services/imgix/useImgix'
import FlatCard from './flatCard'
import clsx from 'clsx'

export interface ImageContainerProps {
    className?: string
}

interface Props {
    url?: string
    index?: number
    deleteEntry?: () => void
    imageWidth?: number
    title?: string
    ratio?: string
    ar?: string
    className?: string
    containerClassName?: string
    imageContainerClassName?: string
    cardClassName?: string
    children?: React.ReactNode
    beforeImage?: ReactNode
    ImageContainer?: React.FC<ImageContainerProps>
}

const ImageCard = React.forwardRef<any, Props>(
    (
        {
            url,
            ratio = '100%',
            ar = '1:1',
            deleteEntry,
            imageWidth = 200,
            title,
            className,
            containerClassName,
            imageContainerClassName,
            children,
            cardClassName,
            beforeImage,
            ImageContainer = (props) => <div {...props} />,
        },
        ref,
    ) => {
        const { getImage } = useImgix()
        const classes = useStyles()

        return (
            <FlatCard ref={ref} className={cardClassName}>
                {beforeImage}
                <div className={containerClassName}>
                    <ImageContainer className={imageContainerClassName}>
                        {url ? (
                            <CardMedia
                                className={clsx(classes.media, className)}
                                style={{ paddingTop: ratio }}
                                image={getImage(url, { w: imageWidth, ar, fit: 'crop' })}
                                title={title}
                            />
                        ) : (
                            <div className={clsx(classes.media, className)} style={{ paddingTop: ratio }} />
                        )}
                    </ImageContainer>
                    {(title || deleteEntry) && (
                        <>
                            <Divider />
                            <CardContent className={classes.detail}>
                                <Typography className={classes.title}>{title}</Typography>
                                {deleteEntry && (
                                    <IconButton onClick={deleteEntry}>
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                )}
                            </CardContent>
                        </>
                    )}
                    {children}
                </div>
            </FlatCard>
        )
    },
)

export default ImageCard
