import React from 'react'
import { useFormsStyles } from '../forms.css'
import { Form } from 'react-final-form'
import { SubmitButtonProps } from 'components/common/submitButton'
import { Trans, useTranslation } from 'react-i18next'
import TextField from '../components/textField'
import KeyboardDatePickerField from '../components/keyboardDatePickerField'
import Grid from '@material-ui/core/Grid/Grid'
import { Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import moment from 'moment'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        timeAssert: {
            marginTop: theme.spacing(3),
            textAlign: 'right',
            paddingRight: theme.spacing(),
        },
    }),
)

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: SpecialClosingFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: SpecialClosingFormState
}

export interface SpecialClosingFormState {
    label: string
    dateStart: Date
    dateEnd: Date
}

type SpecialClosingFormErrors = {
    label?: string
    dateStart?: string
    dateEnd?: string
}

const SpecialClosingForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
}) => {
    const formsClasses = useFormsStyles()
    const classes = useStyles()
    const { t } = useTranslation()

    const validate = (values: SpecialClosingFormState) => {
        const errors: SpecialClosingFormErrors = {}
        if (!values.label) {
            errors.label = t('form.specialClosingLabelError')
        }
        if (moment(values.dateEnd).isBefore(values.dateStart, 'day')) {
            errors.dateEnd = t('form.specialClosingDateEndError')
        }
        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <>
                            <Grid container spacing={4}>
                                <Grid item md={4} xs={12}>
                                    <TextField name={'label'} />
                                </Grid>
                                <Grid item md={3} xs={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Typography className={classes.timeAssert}>
                                                <Trans i18nKey="form.startDate" />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <KeyboardDatePickerField name="dateStart" pastDate={false} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={3} xs={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Typography className={classes.timeAssert}>
                                                <Trans i18nKey="form.endDate" />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <KeyboardDatePickerField name="dateEnd" pastDate={false} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                className: formsClasses.submit,
                                children: <Trans i18nKey="actions.next" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}
export default SpecialClosingForm
