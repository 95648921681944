import React from 'react'
import { useStyles } from '../styles.css'
import { Box, Grid, Typography } from '@material-ui/core'
import { MessagingConversation } from '@sugg-gestion/ubidreams-react-suggpro'
import { GetPlatformIcon, getPostTitle } from '../messagingUtils'

interface Props {
    conversation: MessagingConversation
}

const ConversationBoxPost: React.FC<Props> = ({ conversation }) => {
    const classes = useStyles()
    conversation.title = String(getPostTitle(conversation) || '')
    let iconPlatform = GetPlatformIcon(conversation)

    let styleNewMessage = {}
    if (conversation.unread) {
        styleNewMessage = { fontWeight: 800 }
    }

    return (
        <Box component={'span'} title={conversation.title}>
            <Grid container alignItems="center" className={classes.conversationGrid}>
                <Grid item sm={10} xs={11}>
                    <Typography className={classes.conversationPostTitle} style={styleNewMessage}>
                        {conversation.title}
                    </Typography>
                </Grid>
                <Grid item sm={2} xs={1}>
                    <div>{iconPlatform}</div>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ConversationBoxPost
