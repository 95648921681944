import { IconButton, Menu, MenuItem } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import React from 'react'
import { Trans } from 'react-i18next'

interface Props {
    orientation: 'left' | 'right'
    onDeleteMessage?: () => void
    onBlockUser?: () => void
    onHideMessage?: () => void
    isTwitter?: boolean
}

const MenuConversation: React.FC<Props> = ({
    orientation,
    onDeleteMessage,
    onBlockUser,
    onHideMessage,
    isTwitter = false,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const menuOpen = Boolean(anchorEl)

    const openMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closeMenu = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <IconButton onClick={openMenu}>
                <MoreHorizIcon />
            </IconButton>
            <Menu
                getContentAnchorEl={null}
                anchorOrigin={
                    orientation === 'left'
                        ? {
                              vertical: 'top',
                              horizontal: 'right',
                          }
                        : {
                              vertical: 'top',
                              horizontal: 'left',
                          }
                }
                transformOrigin={
                    orientation === 'left'
                        ? {
                              vertical: 'top',
                              horizontal: 'left',
                          }
                        : {
                              vertical: 'top',
                              horizontal: 'right',
                          }
                }
                anchorEl={anchorEl}
                keepMounted
                open={menuOpen}
                onClose={closeMenu}
            >
                {onDeleteMessage && (
                    <MenuItem
                        onClick={() => {
                            onDeleteMessage()
                            closeMenu()
                        }}
                    >
                        <Trans i18nKey="views.messaging.deleteMessage.action" />
                    </MenuItem>
                )}
                {onHideMessage && (
                    <MenuItem
                        onClick={() => {
                            onHideMessage()
                            closeMenu()
                        }}
                    >
                        <Trans i18nKey="views.messaging.hideMessage.action" />
                    </MenuItem>
                )}
                {onBlockUser && (
                    <MenuItem
                        onClick={() => {
                            onBlockUser()
                            closeMenu()
                        }}
                    >
                        {isTwitter ? (
                            <Trans i18nKey="views.messaging.blockUser.actionTwitter" />
                        ) : (
                            <Trans i18nKey="views.messaging.blockUser.action" />
                        )}
                    </MenuItem>
                )}
            </Menu>
        </>
    )
}

export default MenuConversation
