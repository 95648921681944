import React from 'react'
import { Address, Establishment, User } from '@sugg-gestion/ubidreams-react-suggpro'
import { isEmpty, isNull } from 'lodash'
import { Trans } from 'react-i18next'

export const useAddresses = () => {
    const showInvoiceAddress = (establishment: Establishment, user: User) => {
        const address = establishment.completeAddress
        return (
            <>
                <Trans i18nKey="views.payment.invoice.name" values={{ ...user }} />
                <br />
                {address.country === 'FR' ? (
                    <Trans i18nKey="views.payment.invoice.company" values={{ ...establishment }} />
                ) : (
                    <Trans i18nKey="views.payment.invoice.company_europe" values={{ ...establishment }} />
                )}
                <br />
                {!isEmpty(address.street_1) && !isNull(address.street_1) && (
                    <>
                        {address.street_1}
                        <br />
                    </>
                )}
                {!isEmpty(address.zip_code) && !isNull(address.zip_code) && address.zip_code}{' '}
                {!isEmpty(address.city) && !isNull(address.zip_code) && address.city}
                <br />
                {!isEmpty(address.country) && !isNull(address.country) && (
                    <Trans i18nKey={'countries.' + address.country} />
                )}
                <br />
                {user.email}
            </>
        )
    }
    const showAddress = (address: Address, className: string | undefined = undefined) => {
        return (
            <>
                {!isEmpty(address.street_1) && !isNull(address.street_1) && (
                    <>
                        {address.street_1}
                        <br />
                    </>
                )}
                {!isEmpty(address.street_2) && !isNull(address.street_2) && (
                    <>
                        {address.street_2}
                        <br />
                    </>
                )}
                {!isEmpty(address.zip_code) && !isNull(address.zip_code) && address.zip_code}{' '}
                {!isEmpty(address.city) && !isNull(address.zip_code) && address.city}
                <br />
                {!isEmpty(address.country) && !isNull(address.country) && (
                    <Trans i18nKey={'countries.' + address.country} />
                )}
            </>
        )
    }

    return {
        showAddress,
        showInvoiceAddress,
    }
}
