import React from 'react'
import { useFormsStyles } from '../forms.css'
import { Field, Form } from 'react-final-form'
import { SubmitButtonProps } from 'components/common/submitButton'
import { Trans } from 'react-i18next'
import { Image } from '../helpers/useImages'
import { FormControl, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core'
import SortedImagesField from '../components/sortedImagesField'
import _ from 'lodash'
import { moveArrayItemToNewIndex } from '../helpers/moveArrayItemToNewIndex'
import { SortedImage } from '../helpers/useSortedImages'
import DraggableSelectedImage from './components/draggableSelectedImage'
import clsx from 'clsx'
import { useStyles } from './photosForm.css'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: PhotosFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: Omit<PhotosFormState, 'flag'>
}

export interface PhotosFormState {
    flag: string
    photos: Array<SortedImage>
}

const PhotosForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
}) => {
    const formsClasses = useFormsStyles()
    const classes = useStyles()
    const submit = (values: PhotosFormState) => {
        values.photos = values.photos.map((sortedImage) => {
            sortedImage.isMainPicture = parseInt(values.flag) === sortedImage.id
            return sortedImage
        })
        return onSubmit(values)
    }

    let initialFlag = '1'
    initialValues.photos.forEach((sortedImage) => {
        if (sortedImage.isMainPicture) {
            initialFlag = sortedImage.id.toString()
        }
    })
    const computedInitialValues: PhotosFormState = { ...initialValues, flag: initialFlag }

    return (
        <Form
            initialValues={computedInitialValues}
            subscription={subscription}
            mutators={{
                moveEntry: (args, state, tools) => {
                    const from = args[0] as number
                    const to = args[1] as number
                    const {
                        formState: { values },
                    } = state
                    let { photos } = values as PhotosFormState
                    photos = moveArrayItemToNewIndex(photos, from, to)
                    const newPhotos: Array<SortedImage> = []
                    photos.forEach((photo, index) => {
                        newPhotos.push({
                            ...photo,
                            sortOrder: index,
                        })
                    })
                    tools.changeValue(state, 'photos', () => newPhotos)
                },
                deleteImage: (args, state, tools) => {
                    const image = args[0] as Image
                    const {
                        formState: { values },
                    } = state
                    const { photos } = values as PhotosFormState

                    const imageIndex = photos.findIndex((photo) => photo.id === image.id)
                    photos.splice(imageIndex, 1)
                    tools.changeValue(state, 'photos', () => photos)
                },
            }}
            // debug={console.log}
            onSubmit={submit}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting, form: { mutators } }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <SortedImagesField
                            defaultValue={initialValues.photos}
                            name="photos"
                            errorName="photo"
                            defaultProperties={{
                                id: 0,
                                url: '',
                                sortOrder: 0,
                            }}
                        >
                            {(images, Uploader, hasError, error) => {
                                return (
                                    <Grid container spacing={2}>
                                        {_.sortBy(images, ['sortOrder']).map((image, index) => (
                                            <DraggableSelectedImage
                                                key={index}
                                                index={index}
                                                url={image.url}
                                                moveEntry={mutators.moveEntry}
                                                deleteEntry={() => mutators.deleteImage(image)}
                                            >
                                                {(imageCard) => (
                                                    <Field
                                                        name="flag"
                                                        type="radio"
                                                        value={image.id.toString()}
                                                    >
                                                        {({ input: { name, onChange, value, checked } }) => (
                                                            <Grid
                                                                container
                                                                className={clsx(
                                                                    classes.mainPictureContainer,
                                                                    {
                                                                        [classes.mainPictureContainerVisible]:
                                                                            checked,
                                                                    },
                                                                )}
                                                            >
                                                                <Grid item xs={12}>
                                                                    <FormControl fullWidth>
                                                                        <FormControlLabel
                                                                            labelPlacement="start"
                                                                            control={
                                                                                <Switch
                                                                                    name={name}
                                                                                    value={value}
                                                                                    color="default"
                                                                                    checked={checked}
                                                                                    onChange={(
                                                                                        event,
                                                                                        checked,
                                                                                    ) => {
                                                                                        onChange(
                                                                                            checked
                                                                                                ? value
                                                                                                : images[0].id.toString(),
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Trans i18nKey="views.establishment.photos.main" />
                                                                            }
                                                                            classes={{
                                                                                root: classes.controlLabel,
                                                                                label: clsx(
                                                                                    classes.controlLabelText,
                                                                                    {
                                                                                        [classes.controlLabelTextActive]:
                                                                                            checked,
                                                                                    },
                                                                                ),
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {imageCard}
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    </Field>
                                                )}
                                            </DraggableSelectedImage>
                                        ))}
                                        <Grid item lg={3} sm={4} xs={6}>
                                            <Uploader className={formsClasses.uploader} />
                                        </Grid>
                                        {hasError && (
                                            <Grid item>
                                                <Typography>{error}</Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                )
                            }}
                        </SortedImagesField>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                className: formsClasses.submit,
                                children: <Trans i18nKey="actions.next" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}
export default PhotosForm
