import React from 'react'
import 'core/services/translation'

const withTranslation = (ComposedComponent: any) => {
    const WithTranslation: React.FC = (props) => {
        return <ComposedComponent {...props} />
    }
    return WithTranslation
}

export default withTranslation
