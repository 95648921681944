import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('xs')]: {
                flexGrow: 1,
                padding: 0,
            },
        },
        card: {
            width: '100%',
            marginTop: theme.spacing(4),
            flexGrow: 1,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: theme.palette.border.main,
            padding: theme.spacing(2),
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                marginTop: 0,
                justifyContent: 'space-evenly',
            },
        },
        logo: {
            maxWidth: 600,
            width: '100%',
        },
        logoContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    }),
)
