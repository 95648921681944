import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContent: {
            flexGrow: 1,
            padding: theme.spacing(2, 0),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        withAction: {
            [theme.breakpoints.down('xs')]: {
                '@media (orientation: portrait)': {
                    paddingBottom: 66,
                },
            },
        },
    }),
)
