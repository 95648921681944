import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
    createStyles({
        detail: {
            'height': 100,
            'display': 'flex',
            'alignItems': 'center',
            '& > p': {
                flexGrow: 1,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            },
        },
        media: {
            height: 240,
        },
        dragged: {
            opacity: 0.5,
        },
        error: {
            borderColor: theme.palette.error.main,
        },
        over: {
            backgroundColor: theme.palette.text.primary,
        },
    }),
)
