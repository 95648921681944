import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            paddingBottom: theme.spacing(4),
        },
        subtitle: {
            marginTop: theme.spacing(-2),
        },
        swiper: {
            marginTop: theme.spacing(2),
            width: '100%',
            height: 'calc(100vh - 296px)',
            minHeight: 200,
            position: 'relative',
            [theme.breakpoints.down('xs')]: {
                left: theme.spacing(-2),
                width: '100vw',
            },
        },
        swiperNavigation: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            zIndex: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        swiperNavigationLeft: {
            left: 0,
        },
        swiperNavigationRight: {
            right: 0,
        },
        chevron: {
            fontSize: '4rem',
            [theme.breakpoints.down('md')]: {
                fontSize: '3rem',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '2rem',
            },
        },
        slide: {
            width: '100%',
        },
        empty: {
            fontWeight: theme.fontsWeight.semiBold,
            fontSize: '2rem',
            textAlign: 'center',
            padding: theme.spacing(2, 0),
            marginBottom: theme.spacing(2),
            width: 460,
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                fontSize: '1.4rem',
            },
        },
        emptyContainer: {
            minHeight: 400,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('xs')]: {
                minHeight: 0,
                height: '100%',
            },
        },
        buttonGrid: {
            width: 'fit-content',
            [theme.breakpoints.down('xs')]: {
                '& .MuiButton-label': {
                    fontSize: '0.7rem',
                },
            },
        },
        permanentLinkInfoContainer: {
            maxWidth: 600,
            padding: theme.spacing(1, 2),
            textAlign: 'justify',
            backgroundColor: '#fff2cc',
            border: '1px solid white',
            zIndex: 1500,
        },
        permanentLinkInfoDialog: {
            '& .MuiDialogContent-root': {
                paddingTop: theme.spacing(8),
            },
        },
        popper: {
            zIndex: 1500,
        },
        qrCodeInfoContainer: {
            maxWidth: 600,
            padding: theme.spacing(1, 2),
            backgroundColor: '#fff2cc',
            border: '1px solid white',
        },
        qrCodeInfoDialog: {
            '& .MuiDialogContent-root': {
                paddingTop: theme.spacing(8),
            },
        },
        submit: {
            width: 160,
            maxWidth: '100%',
            minWidth: 'fit-content !important',
        },
        actions: {
            justifyContent: 'center',
        },
        additionalActionsMargin: {
            marginTop: theme.spacing(),
        },
        additionalActions: {
            justifyContent: 'center',
        },
    }),
)
