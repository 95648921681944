import TutoView from 'components/views/tuto/tutoView'
import { useTuto } from 'core/services/tuto/useTuto'
import React, { useState } from 'react'
import withAuthenticateLayout from '../hoc/withAuthenticateLayout'

const Tuto: React.FC = () => {
    const [page, setPage] = useState(0)
    const { getVideos, numberOfPage, fetchVideosNextPage, inProgress } = useTuto(8)

    const handleNextPage = () => {
        fetchVideosNextPage(page + 1)
        setPage(page + 1)
    }

    const handlePreviousPage = () => {
        setPage(page - 1)
    }

    return (
        <TutoView
            page={page}
            numberOfPage={numberOfPage}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            inProgress={inProgress}
            getVideos={getVideos}
        />
    )
}

export default withAuthenticateLayout<false>(Tuto, false)
