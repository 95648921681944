import {
    UpdateEstablishmentWebsiteSectionRequest,
    useEstablishmentUpdate,
    useEstablishmentWebsiteSectionUpdate,
} from '@sugg-gestion/ubidreams-react-suggpro'
import { InlineEmailFormState } from 'components/forms/establishment/inlineEmailForm'
import { WebsiteCustomizationFormState } from 'components/forms/establishment/websiteCustomizationForm'
import ExternalLinksView from 'components/views/externalLinks/externalLinksView'
import EstablishmentEditPhotosDialog from 'containers/establishment/dialogs/establishmentEditPhotosDialog'
import withAuthenticateLayout, { WithAuthenticateLayout } from 'containers/hoc/withAuthenticateLayout'
import useClipboard from 'core/services/clipboard/useClipboard'
import useRestaurantsApi from 'core/services/restaurant/useRestaurantsApi'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props extends WithAuthenticateLayout {}

const ExternalLinks: React.FC<Props> = ({ establishment, restaurantOwner, ability }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const { displayError } = useApiErrors()
    const { updateEstablishment: bookingModuleChange, inProgress: bookingModuleChangeInProgress } =
        useEstablishmentUpdate()
    const { updateEstablishment: bookingEmailChange, inProgress: bookingEmailChangeInProgress } =
        useEstablishmentUpdate()
    const { updateEstablishment: orderModuleChange, inProgress: orderModuleChangeInProgress } =
        useEstablishmentUpdate()
    const { updateEstablishment: orderEmailChange, inProgress: orderEmailChangeInProgress } =
        useEstablishmentUpdate()
    const { updateEstablishment: websiteChange, inProgress: websiteChangeInProgress } =
        useEstablishmentUpdate()
    const { updateEstablishmentWebsiteSection, inProgress: websiteSectionChangeInProgress } =
        useEstablishmentWebsiteSectionUpdate()
    const { menuLink, qrCodeLink, qrCodeDressedLink, slateLink } = useRestaurantsApi(
        restaurantOwner,
        establishment,
    )
    const { copyToClipboard } = useClipboard()
    const [photoEditOpen, setPhotoEditOpen] = useState(false)

    const handleCopyLink = (link: string) => {
        copyToClipboard(link)
    }

    const handleChangeBookingModule = (bookingModule: boolean) => {
        bookingModuleChange({
            id: establishment.id,
            establishment: {
                bookingModule,
            },
        })
            .then(() => {
                enqueueSnackbar(
                    t('views.externalLinks.bookingModule.' + (bookingModule ? 'enable' : 'disable')),
                    {
                        variant: 'success',
                    },
                )
            })
            .catch((error) => displayError(error))
    }

    const handleChangeBookingEmail = (values: InlineEmailFormState) => {
        bookingEmailChange({
            id: establishment.id,
            establishment: {
                bookingEmail: values.email,
            },
        })
            .then(() => {
                enqueueSnackbar(t('views.externalLinks.bookingEmail.change'), {
                    variant: 'success',
                })
            })
            .catch((error) => displayError(error))
    }

    const handleChangeOrderEmail = (values: InlineEmailFormState) => {
        orderEmailChange({
            id: establishment.id,
            establishment: {
                orderEmail: values.email,
            },
        })
            .then(() => {
                enqueueSnackbar(t('views.externalLinks.orderEmail.change'), {
                    variant: 'success',
                })
            })
            .catch((error) => displayError(error))
    }

    const handleChangeOrderModule = (orderModule: boolean) => {
        orderModuleChange({
            id: establishment.id,
            establishment: {
                orderModule,
            },
        })
            .then(() => {
                enqueueSnackbar(
                    t('views.externalLinks.orderModule.' + (orderModule ? 'enable' : 'disable')),
                    {
                        variant: 'success',
                    },
                )
            })
            .catch((error) => displayError(error))
    }

    const handleEditPhotos = () => {
        setPhotoEditOpen(true)
    }

    const handleClose = () => {
        setPhotoEditOpen(false)
    }

    const [editCustomizationModalOpen, setEditCustomizationModalOpen] = useState(false)
    const handleEditCustomization = () => {
        setEditCustomizationModalOpen(true)
    }

    const handleModalCustomizationClose = () => {
        setEditCustomizationModalOpen(false)
    }

    const handleUpdateWebsiteCustomization = (values: WebsiteCustomizationFormState) => {
        const websiteSectionsPromise: Promise<any>[] = [
            websiteChange({
                id: establishment.id,
                establishment: {
                    websiteFont: values.font,
                    websiteBackgroundColorLight: values.backgroundColorLight,
                    websiteBackgroundColorDark: values.backgroundColorDark,
                },
            }),
        ]

        const sectionOrder = [
            'title',
            'slate',
            'menu',
            'news',
            'about',
            'links',
            'book',
            'order',
            'photos',
            'information',
            'reviews',
            'footer',
        ]
        sectionOrder.forEach((section) => {
            const currentWebsiteSection = establishment.websiteSections?.find(
                (websiteSection) => websiteSection.section === section,
            )
            if (
                currentWebsiteSection ||
                // @ts-ignore
                values[section + 'SectionBackgroundColorEnabled'].length > 0 ||
                // @ts-ignore
                values[section + 'SectionTextColorEnabled'].length > 0 ||
                // @ts-ignore
                values[section + 'SectionBackgroundImage']?.file
            ) {
                let titleSection: UpdateEstablishmentWebsiteSectionRequest = {
                    section: section,
                    backgroundColor: null,
                    textColor: null,
                    backgroundImage: undefined,
                    establishment: establishment.id,
                }
                if (currentWebsiteSection) {
                    titleSection.id = currentWebsiteSection.id
                }
                if (
                    // @ts-ignore
                    values[section + 'SectionBackgroundColorEnabled'].length > 0 &&
                    // @ts-ignore
                    values[section + 'SectionBackgroundColor']
                ) {
                    // @ts-ignore
                    titleSection.backgroundColor = values[section + 'SectionBackgroundColor']
                }
                if (
                    // @ts-ignore
                    values[section + 'SectionTextColorEnabled'].length > 0 &&
                    // @ts-ignore
                    values[section + 'SectionTextColor']
                ) {
                    // @ts-ignore
                    titleSection.textColor = values[section + 'SectionTextColor']
                }
                // @ts-ignore
                if (values[section + 'SectionBackgroundImage']?.file) {
                    // @ts-ignore
                    titleSection.backgroundImage = values[section + 'SectionBackgroundImage'].file
                } else if (
                    currentWebsiteSection &&
                    // @ts-ignore
                    values[section + 'SectionBackgroundImage']?.url === undefined
                ) {
                    titleSection.backgroundImage = null
                }
                websiteSectionsPromise.push(updateEstablishmentWebsiteSection(titleSection))
            }
        })

        Promise.all(websiteSectionsPromise)
            .then(() => {
                enqueueSnackbar(t('views.externalLinks.customization.change'), {
                    variant: 'success',
                })
                handleModalCustomizationClose()
            })
            .catch((error) => displayError(error))
    }

    return (
        <>
            <ExternalLinksView
                establishment={establishment}
                slateLink={slateLink()}
                menuLink={menuLink()}
                copyLink={handleCopyLink}
                qrCodeLink={qrCodeLink()}
                qrCodeDressedLink={qrCodeDressedLink()}
                bookingModuleAccess={ability.can('api.booking_access')}
                bookingModule={establishment.bookingModule}
                bookingModuleChangeInProgress={bookingModuleChangeInProgress}
                bookingEmail={establishment.bookingEmail ?? establishment.email ?? ''}
                bookingEmailChangeInProgress={bookingEmailChangeInProgress}
                onChangeBookingEmail={handleChangeBookingEmail}
                onChangeBookingModule={handleChangeBookingModule}
                orderModuleAccess={ability.can('api.order_access')}
                orderModule={establishment.orderModule}
                orderModuleChangeInProgress={orderModuleChangeInProgress}
                onChangeOrderModule={handleChangeOrderModule}
                enableOrderModule
                orderEmail={establishment.orderEmail ?? establishment.email ?? ''}
                orderEmailChangeInProgress={orderEmailChangeInProgress}
                onChangeOrderEmail={handleChangeOrderEmail}
                onEditPhotos={handleEditPhotos}
                editCustomizationModalOpen={editCustomizationModalOpen}
                onEditCustomization={handleEditCustomization}
                onCloseCustomization={handleModalCustomizationClose}
                onUpdateWebsiteCustomization={handleUpdateWebsiteCustomization}
                updateWebsiteCustomizationInProgress={
                    websiteChangeInProgress || websiteSectionChangeInProgress
                }
            />

            <EstablishmentEditPhotosDialog
                establishment={establishment}
                open={photoEditOpen}
                onClose={handleClose}
            />
        </>
    )
}

export default withAuthenticateLayout(ExternalLinks)
