import React, { ComponentType } from 'react'
import { SnackbarKey, SnackbarProvider } from 'notistack'
import SnackbarNotifier from 'components/common/snackbarNotifier'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { Trans } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('xs')]: {
                paddingBottom: theme.spacing(7),
            },
        },
    }),
)

const withNotistack = (ComposedComponent: ComponentType) => {
    const WithNotistack: React.FC = ({ ...props }) => {
        const classes = useStyles()
        const notistackRef = React.createRef<SnackbarProvider>()
        const onClickDismiss = (key: SnackbarKey) => () => {
            notistackRef.current?.closeSnackbar(key)
        }
        return (
            <SnackbarProvider
                ref={notistackRef}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{
                    anchorOriginBottomCenter: classes.root,
                }}
                action={(key) => (
                    <Button variant="outlined" color="inherit" onClick={onClickDismiss(key)}>
                        <Trans i18nKey="actions.close" />
                    </Button>
                )}
            >
                <SnackbarNotifier />
                <ComposedComponent {...props} />
            </SnackbarProvider>
        )
    }
    return WithNotistack
}

export default withNotistack
