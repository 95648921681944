import { Establishment, useSignOut } from '@sugg-gestion/ubidreams-react-suggpro'
import actions from '../../store/actions'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useApiErrors } from '../suggPro/useApiErrors'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            'textDecoration': 'underline',
            'textAlign': 'right',
            'color': '#2696E0',
            'width': '100%',
            '& .MuiButton-label': {
                justifyContent: 'flex-end',
            },
            '&.MuiButton-root:hover': {
                backgroundColor: 'inherit',
            },
        },
    }),
)

export const useAlertExistingDuns = () => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const { displayError } = useApiErrors()
    const { signOut } = useSignOut()

    const alertExistingDuns = (
        establishment: Establishment,
        duns: string,
        callback: (values: string, establishment: Establishment) => Promise<void>,
    ) => {
        if (establishment.existingDuns) {
            dispatch(
                actions.alert({
                    content: t('views.establishment.duns.existingDuns', {
                        existingDuns: establishment.existingDuns,
                    }),
                    buttons: [
                        {
                            color: 'primary',
                            children: t('actions.continue'),
                            onClick: () => callback(duns, establishment),
                        },
                        {
                            color: 'primary',
                            children: t('actions.corrected'),
                        },
                        {
                            children: t('actions.redirectAccountExistingDuns'),
                            onClick: () =>
                                signOut()
                                    .then(() => {
                                        enqueueSnackbar(t('views.signOut.success'), {
                                            variant: 'success',
                                        })
                                    })
                                    .catch((error) => displayError(error)),
                            className: classes.button,
                        },
                    ],
                }),
            )
        } else {
            callback(duns, establishment)
        }
    }

    return {
        alertExistingDuns,
    }
}
