import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { Divider, List, Typography } from '@material-ui/core'
import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem/ListItem'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bold: {
            fontWeight: theme.fontsWeight.bold,
        },
        italic: {
            fontStyle: 'italic',
        },
        underline: {
            textDecoration: 'underline',
        },
    }),
)

export const useRichContentful = (type: 'rich' | 'plain' = 'rich') => {
    const classes = useStyles()
    if (type === 'rich') {
        const options: Options = {
            renderMark: {
                [MARKS.BOLD]: (text) => (
                    <Typography component="span" className={classes.bold}>
                        {text}
                    </Typography>
                ),
                [MARKS.ITALIC]: (text) => (
                    <Typography component="span" className={classes.italic}>
                        {text}
                    </Typography>
                ),
                [MARKS.UNDERLINE]: (text) => (
                    <Typography component="span" className={classes.underline}>
                        {text}
                    </Typography>
                ),
            },
            renderNode: {
                [BLOCKS.HEADING_1]: (node, children) => <Typography variant="h1">{children}</Typography>,
                [BLOCKS.HEADING_2]: (node, children) => <Typography variant="h2">{children}</Typography>,
                [BLOCKS.HEADING_3]: (node, children) => <Typography variant="h3">{children}</Typography>,
                [BLOCKS.HEADING_4]: (node, children) => <Typography variant="h4">{children}</Typography>,
                [BLOCKS.HEADING_5]: (node, children) => <Typography variant="h5">{children}</Typography>,
                [BLOCKS.PARAGRAPH]: (node, children) => <Typography>{children}</Typography>,
                [BLOCKS.HR]: (node, children) => <Divider />,
                [BLOCKS.OL_LIST]: (node, children) => <List>{children}</List>,
                [BLOCKS.UL_LIST]: (node, children) => <List>{children}</List>,
                [BLOCKS.LIST_ITEM]: (node, children) => (
                    <ListItem disableGutters>
                        <ListItemText primary={children} />
                    </ListItem>
                ),
            },
        }
        return options
    }

    const options: Options = {
        renderMark: {
            [MARKS.BOLD]: (text) => (
                <Typography component="span" className={classes.bold}>
                    {text}
                </Typography>
            ),
            [MARKS.ITALIC]: (text) => (
                <Typography component="span" className={classes.italic}>
                    {text}
                </Typography>
            ),
            [MARKS.UNDERLINE]: (text) => (
                <Typography component="span" className={classes.underline}>
                    {text}
                </Typography>
            ),
        },
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => <Typography component="span">{children}</Typography>,
            [BLOCKS.HEADING_2]: (node, children) => <Typography component="span">{children}</Typography>,
            [BLOCKS.HEADING_3]: (node, children) => <Typography component="span">{children}</Typography>,
            [BLOCKS.HEADING_4]: (node, children) => <Typography component="span">{children}</Typography>,
            [BLOCKS.HEADING_5]: (node, children) => <Typography component="span">{children}</Typography>,
            [BLOCKS.PARAGRAPH]: (node, children) => <Typography component="span">{children}</Typography>,
            [BLOCKS.HR]: (node, children) => <Divider />,
            [BLOCKS.OL_LIST]: (node, children) => <List component="span">{children}</List>,
            [BLOCKS.UL_LIST]: (node, children) => <List component="span">{children}</List>,
            [BLOCKS.LIST_ITEM]: (node, children) => (
                <ListItem disableGutters>
                    <ListItemText primary={children} />
                </ListItem>
            ),
        },
    }
    return options
}
