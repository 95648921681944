import { useEffect, useState } from 'react'
import { client } from './ressources/client'
import { pricingQuery } from './ressources/pricing'
import { AxiosResponse } from 'axios'
import { GraphQLData, PricingCollection, PricingQuery, PromotionCodeCollection } from './ressources/types'

export const usePricing = () => {
    const [pricingCollection, setPricingCollection] = useState<PricingCollection | null>(null)
    const [promotionCodeCollection, setPromotionCodeCollection] = useState<PromotionCodeCollection | null>(
        null,
    )

    useEffect(() => {
        client
            .post('', { query: pricingQuery })
            .then((response: AxiosResponse<GraphQLData<PricingQuery>>) => {
                const queryResponse = response.data.data
                setPricingCollection(queryResponse.pricingCollection)
                setPromotionCodeCollection(queryResponse.promotionCodeCollection)
            })
    }, [])

    return { pricingCollection, promotionCodeCollection }
}
