import React from 'react'
import { Field } from 'react-final-form'
import PhotoUpload from 'components/common/photoUpload'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { SortedImage, useSortedImages } from 'components/forms/helpers/useSortedImages'
import { FormControl, PropTypes } from '@material-ui/core'

interface UploaderProps {
    className?: string
}

interface Props<T extends SortedImage> {
    name: string
    errorName: string
    defaultValue?: Array<T>
    children: (
        images: Array<T>,
        Uploader: React.FC<UploaderProps>,
        hasError: boolean,
        error?: string,
    ) => React.ReactNode
    margin?: PropTypes.Margin
    maxFiles?: number
    defaultProperties: T
    onChange?: (images: Array<T>) => Array<T>
}

const SortedImagesField = <T extends SortedImage>({
    name,
    errorName,
    defaultValue,
    children,
    margin = 'normal',
    maxFiles,
    defaultProperties,
    onChange: onExternalChange,
}: Props<T>) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const { updateImages } = useSortedImages<T>(defaultProperties)

    return (
        <FormControl margin={margin} fullWidth>
            <Field name={errorName} defaultValue={defaultValue}>
                {({ meta }) => {
                    const hasGlobalError =
                        ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
                    const globalError = meta.error
                    return (
                        <Field name={name} defaultValue={defaultValue}>
                            {({ input: { onChange, value } }) => {
                                const images: Array<T> = value
                                const hasError = (maxFiles && images.length > maxFiles) || hasGlobalError
                                const error =
                                    maxFiles && images.length > maxFiles
                                        ? t('form-validation.slatePhotoMax', { count: maxFiles })
                                        : globalError
                                return children(
                                    images,
                                    ({ className }) => (
                                        <PhotoUpload
                                            classes={{
                                                root: className,
                                            }}
                                            multiple={maxFiles ? maxFiles > 1 : true}
                                            files={[]}
                                            accept={['image/jpeg', 'image/png']}
                                            onChange={(files) => {
                                                if (files !== undefined) {
                                                    updateImages(files, value)
                                                        .then((sortedImages) => {
                                                            if (onExternalChange) {
                                                                return onExternalChange(sortedImages)
                                                            }
                                                            return sortedImages
                                                        })
                                                        .then((sortedImages) => onChange(sortedImages))
                                                }
                                            }}
                                            // 5000000 = 5 MB
                                            maxSize={parseInt(
                                                process.env.REACT_APP_PHOTO_MAX_SIZE !== undefined
                                                    ? process.env.REACT_APP_PHOTO_MAX_SIZE
                                                    : '5000000',
                                            )}
                                            maxFiles={maxFiles}
                                            isInvalidFiles={(files) => {
                                                if (maxFiles && files.length > maxFiles) {
                                                    enqueueSnackbar(t('form-validation.slatePhotoMax'), {
                                                        variant: 'error',
                                                    })
                                                } else {
                                                    for (let i = 0; i < files.length; i++) {
                                                        enqueueSnackbar(
                                                            t('form-validation.photo').replace(
                                                                ':name',
                                                                files[i].file.name,
                                                            ),
                                                            {
                                                                variant: 'error',
                                                            },
                                                        )
                                                    }
                                                }
                                                return true
                                            }}
                                        />
                                    ),
                                    hasError || false,
                                    error,
                                )
                            }}
                        </Field>
                    )
                }}
            </Field>
        </FormControl>
    )
}

export default SortedImagesField
