import { useUpdateUser } from '@sugg-gestion/ubidreams-react-suggpro'
import { AccountFormState } from 'components/forms/account/accountForm'
import AccountEditView from 'components/views/account/accountEditView'
import withAuthenticateLayout, { WithAuthenticateLayout } from 'containers/hoc/withAuthenticateLayout'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import { CountryCode } from 'libphonenumber-js/types'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

interface Props extends WithAuthenticateLayout<false> {}

const AccountEdit: React.FC<Props> = ({ restaurantOwner }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const history = useHistory()

    const { displayError } = useApiErrors()
    const { updateUser, inProgress } = useUpdateUser()

    const handleUpdateAccount = (values: AccountFormState) => {
        updateUser({
            ...values,
        })
            .then(() => {
                enqueueSnackbar(t('views.establishment.hours.success'), {
                    variant: 'success',
                })
            })
            .catch((error) => displayError(error))
    }

    const handlePrevious = () => {
        history.push('/account')
    }

    return (
        <AccountEditView
            onSubmit={handleUpdateAccount}
            inProgress={inProgress}
            initialValues={{
                email: restaurantOwner.email,
                firstName: restaurantOwner.firstname,
                lastName: restaurantOwner.lastname,
                country: restaurantOwner.country as CountryCode,
                phoneNumber: restaurantOwner.phoneNumber,
                password: undefined,
                passwordConfirmation: undefined,
            }}
            onPrevious={handlePrevious}
        />
    )
}

export default withAuthenticateLayout(AccountEdit, false)
