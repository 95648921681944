import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        'content': {
            padding: theme.spacing(0, 6),
            paddingTop: theme.spacing(4),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(0, 2),
                paddingTop: theme.spacing(4),
            },
        },
        'titleContainer': {
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(2),
            },
            marginBottom: theme.spacing(2),
        },
        // necessary for content to be below app bar
        'toolbar': {
            ...theme.mixins.toolbar,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#FAFAFA',
            borderBottom: '1px solid #DCDCDC',
        },
        'toolbarSpacing': theme.mixins.toolbar,
        'logoContainer': {
            height: 64,
            [theme.breakpoints.down('xs')]: {
                'height': 56,
                'padding': theme.spacing(0.5),
                '@media (orientation: landscape)': {
                    height: 48,
                },
            },
        },
        'listItem': {
            'position': 'relative',
            'borderRadius': theme.spacing(3),
            'padding': theme.spacing(0.5, 2),
            'margin': theme.spacing(1, 0),
            'color': theme.palette.common.black,
            '&.Mui-selected': {
                'backgroundColor': theme.palette.background.default,
                '& .MuiListItemIcon-root': {
                    color: theme.palette.primary.main,
                },
            },
            '&:hover': {
                'borderRadius': theme.spacing(3),
                'backgroundColor': theme.palette.background.default,
                '& .MuiListItemIcon-root': {
                    color: theme.palette.primary.main,
                },
            },
            '& .MuiListItemIcon-root': {
                color: '#BDBDBD',
            },
            '& .MuiTypography-body1': {
                [theme.breakpoints.down('sm')]: {
                    fontSize: '0.9rem',
                },
            },
        },
        'new': {
            position: 'relative',
            marginRight: -12,
            fontSize: '0.6rem',
        },
        'list': {
            '& > $listItem:first-of-type': {
                marginTop: 0,
            },
        },
        'listTitle': {
            fontWeight: theme.fontsWeight.semiBold,
            fontSize: '1rem',
            lineHeight: '1rem',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(),
            paddingLeft: theme.spacing(2),
            backgroundColor: '#F0F0F0',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.9rem',
            },
        },
        'action': {
            margin: theme.spacing(3, 0),
        },
        'favoritesCountMessage': {
            '&.MuiTypography-root': {
                textAlign: 'center',
                fontSize: '0.9rem',
            },
        },
        'favoritesCountLink': {
            '&.MuiTypography-root': {
                marginTop: theme.spacing(0.5),
                textAlign: 'center',
                fontSize: '0.9rem',
            },
        },
        'favoritesCount': {
            padding: theme.spacing(1, 2),
        },
        'favoritesCountNumber': {
            fontWeight: theme.fontsWeight.semiBold,
        },
        'favoritesCountSugg1144': {
            textDecoration: 'underline',
        },
        'lockIcon': {
            marginLeft: theme.spacing(2),
            color: theme.palette.text.secondary,
        },
        'blinkIcon': {
            animationDuration: '0.6s',
            animationName: '$blink',
            animationIterationCount: 'infinite',
            [theme.breakpoints.down('sm')]: {
                marginLeft: '20px',
            },
        },
        '@keyframes blink': {
            '0%': {
                opacity: 1,
            },
            '40%': {
                opacity: 0,
            },
            '100%': {
                opacity: 1,
            },
        },
    }),
)
