import React, { useState } from 'react'
import NewsListView from 'components/views/news/newsListView'
import withAuthenticateLayout, { WithAuthenticateLayout } from 'containers/hoc/withAuthenticateLayout'
import { useNewsList } from 'core/services/events/useNewsList'
import { NewsModel, useNewsDelete, useNewsUpdate } from '@sugg-gestion/ubidreams-react-suggpro'
import EstablishmentEditFacebookGoogleDialog from '../establishment/dialogs/establishmentEditFacebookGoogleDialog'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import withFacebookAndGoogle from '../hoc/withFacebookAndGoogle'
import Loader from 'components/common/loader'
import actions from '../../core/store/actions'
import { useDispatch } from 'react-redux'

interface Props extends WithAuthenticateLayout {}

const NewsList: React.FC<Props> = ({ establishment, restaurantOwner, ability }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const { displayError } = useApiErrors()

    const { newsList } = useNewsList()
    const { deleteNews, inProgress: deleteInProgress } = useNewsDelete()
    const { shareNews: shareOnGoogle, inProgress: shareOnGoogleInProgress } = useNewsUpdate()
    const { shareNews: shareOnFacebook, inProgress: shareOnFacebookInProgress } = useNewsUpdate()
    const { shareNews: shareOnInstagram, inProgress: shareOnInstagramInProgress } = useNewsUpdate()
    const { shareNews: shareOnTwitter, inProgress: shareOnTwitterInProgress } = useNewsUpdate()

    const [openFacebookGoogle, setOpenFacebookGoogle] = useState<boolean>(false)
    const [eventInProgress, setEventInProgress] = useState<number>()

    const handleShareOnGoogle = (eventId: NewsModel['id']) => {
        if (establishment.googleId) {
            setEventInProgress(eventId)
            shareOnGoogle(eventId, { id: eventId, isGoogleShared: true })
                .then(() => {
                    enqueueSnackbar(t('views.news.add.successGoogle'), {
                        variant: 'success',
                    })
                })
                .catch((error) => displayError(error))
                .finally(() => {
                    setEventInProgress(undefined)
                })
        } else {
            setOpenFacebookGoogle(true)
        }
    }

    const handleShareOnFacebook = (eventId: NewsModel['id']) => {
        if (establishment.facebookPageId) {
            setEventInProgress(eventId)
            shareOnFacebook(eventId, { id: eventId, isFacebookShared: true })
                .then(() => {
                    enqueueSnackbar(t('views.news.add.successFacebook'), {
                        variant: 'success',
                    })
                })
                .catch((error) => displayError(error))
                .finally(() => {
                    setEventInProgress(undefined)
                })
        } else {
            setOpenFacebookGoogle(true)
        }
    }

    const handleShareOnInstagram = (eventId: NewsModel['id']) => {
        if (establishment.instagramPageId) {
            setEventInProgress(eventId)
            shareOnInstagram(eventId, { id: eventId, isInstagramShared: true })
                .then(() => {
                    enqueueSnackbar(t('views.news.add.successInstagram'), {
                        variant: 'success',
                    })
                })
                .catch((error) => displayError(error))
                .finally(() => {
                    setEventInProgress(undefined)
                })
        } else {
            setOpenFacebookGoogle(true)
        }
    }

    const handleShareOnTwitter = (eventId: NewsModel['id']) => {
        if (establishment.twitterID && !establishment.isTwitterSignedExpired) {
            setEventInProgress(eventId)
            shareOnTwitter(eventId, { id: eventId, isTwitterShared: true })
                .then(() => {
                    enqueueSnackbar(t('views.news.add.successTwitter'), {
                        variant: 'success',
                    })
                })
                .catch((error) => displayError(error))
                .finally(() => {
                    setEventInProgress(undefined)
                })
        } else {
            setOpenFacebookGoogle(true)
        }
    }

    const handleClose = () => {
        setOpenFacebookGoogle(false)
    }

    const handleDelete = (eventId: NewsModel['id']) => {
        setEventInProgress(eventId)
        return deleteNews(eventId)
            .then(() => {
                enqueueSnackbar(t('views.news.delete.success'), {
                    variant: 'success',
                })
            })
            .catch((error) => displayError(error))
            .finally(() => {
                setEventInProgress(undefined)
            })
    }

    const handleDeletePrompt = (eventId: NewsModel['id']) => {
        dispatch(
            actions.alert({
                content: t('views.news.delete.confirm'),
                buttons: [
                    {
                        color: 'primary',
                        children: t('common.cancel'),
                    },
                    {
                        color: 'primary',
                        children: t('common.ok'),
                        onClick: () => handleDelete(eventId),
                    },
                ],
            }),
        )
    }

    return (
        <>
            <NewsListView
                newsList={newsList}
                establishment={establishment}
                eventInProgress={eventInProgress}
                onDelete={handleDeletePrompt}
                deleteInProgress={deleteInProgress}
                shareOnGoogle={handleShareOnGoogle}
                isSharingSlateOnGoogle={shareOnGoogleInProgress}
                isGoogleSignedExpired={restaurantOwner.isGoogleSignedExpired}
                shareOnFacebook={handleShareOnFacebook}
                isSharingSlateOnFacebook={shareOnFacebookInProgress}
                isFacebookSignedExpired={restaurantOwner.isFacebookSignedExpired}
                shareOnInstagram={handleShareOnInstagram}
                isSharingSlateOnInstagram={shareOnInstagramInProgress}
                shareOnTwitter={handleShareOnTwitter}
                isSharingSlateOnTwitter={shareOnTwitterInProgress}
                isTwitterSignedExpired={establishment.isTwitterSignedExpired ?? false}
            />

            <EstablishmentEditFacebookGoogleDialog
                establishment={establishment}
                open={openFacebookGoogle}
                onClose={handleClose}
                ability={ability}
            />
        </>
    )
}

export default withAuthenticateLayout(withFacebookAndGoogle(NewsList, <Loader />))
