import React from 'react'
import { Form, Field } from 'react-final-form'
import { Trans } from 'react-i18next'
import Grid from '@material-ui/core/Grid/Grid'
import { Button, CircularProgress, useMediaQuery } from '@material-ui/core'
import { useStyles } from '../../views/messaging/styles.css'
import i18next from 'i18next'
import SendIcon from '@material-ui/icons/Send'
import { FormApi } from 'final-form'
import theme from 'core/config/theme'

export interface MessageFormState {
    text: string
    parentId?: string
}

interface Props {
    onSubmit: (values: MessageFormState, form: FormApi<MessageFormState, Partial<MessageFormState>>) => void
    inProgress: boolean
}

const PrivateMessageForm: React.FC<Props> = ({ onSubmit, inProgress }) => {
    const classes = useStyles()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

    // Handle for when the key enter is presse, the form is submit. Use shit + enter to make a new line
    const keyEnterEvent = (e: React.KeyboardEvent, submitHandle: any) => {
        const keyCode = e.which || e.keyCode
        // Key enter pressed
        if (keyCode === 13 && !e.shiftKey) {
            // Don't generate a new line
            e.preventDefault()
            submitHandle()
        }
    }

    return (
        <Form
            //debug={console.log}
            onSubmit={onSubmit}
        >
            {({ handleSubmit, form, submitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={1}>
                        <Grid item xs={10}>
                            <Field
                                name={'text'}
                                component={'textarea'}
                                autoFocus={true}
                                onKeyPress={(e: React.KeyboardEvent) => keyEnterEvent(e, handleSubmit)}
                                className={classes.discussTextarea}
                                placeholder={i18next.t('views.messaging.placeholderMessage')}
                                title={i18next.t('views.messaging.textareaHelper')}
                                disabled={inProgress}
                            />
                        </Grid>
                        <Grid item xs={2} className={classes.discussSendBtn}>
                            {isMobile ? (
                                <Button
                                    type={'submit'}
                                    color={'primary'}
                                    className={classes.sendButtonSquare}
                                    title={i18next.t('views.messaging.send')}
                                >
                                    {inProgress ? (
                                        <CircularProgress
                                            style={{ color: 'rgba(0, 0, 0, 0.26)' }}
                                            size={20}
                                        />
                                    ) : (
                                        <SendIcon />
                                    )}
                                </Button>
                            ) : (
                                <Button
                                    type={'submit'}
                                    variant="contained"
                                    color={'primary'}
                                    endIcon={
                                        inProgress ? (
                                            <CircularProgress
                                                style={{ color: 'rgba(0, 0, 0, 0.26)' }}
                                                size={20}
                                            />
                                        ) : (
                                            <SendIcon />
                                        )
                                    }
                                    disabled={inProgress}
                                >
                                    <Trans i18nKey="views.messaging.send" />
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </form>
            )}
        </Form>
    )
}
export default PrivateMessageForm
