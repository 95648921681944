import React from 'react'
import { MessagingConversation, usePostMessages } from '@sugg-gestion/ubidreams-react-suggpro'
import { useStyles } from '../../views/messaging/styles.css'
import Grid from '@material-ui/core/Grid/Grid'
import { Field, Form } from 'react-final-form'
import { Button, CircularProgress } from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'
import { MessageFormState } from './privateMessageForm'
import { FormApi } from 'final-form'
import { PageType, useApiErrors } from 'core/services/suggPro/useApiErrors'
import { useTranslation } from 'react-i18next'

interface Props {
    conversation: MessagingConversation
    messageCount?: number
}

const ReplyPostForm: React.FC<Props> = ({ conversation, messageCount }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { displayError } = useApiErrors()

    // Post message
    const { postMessage, inProgressSendMessage } = usePostMessages()

    // Handle for when the key enter is presse, the form is submitting. Use shift + enter to make a new line
    const keyEnterEvent = (e: React.KeyboardEvent, submitHandle: any) => {
        const keyCode = e.which || e.keyCode
        // Key enter pressed
        if (keyCode === 13 && !e.shiftKey) {
            // Don't generate a new line
            e.preventDefault()
            submitHandle()
        }
    }

    // Handle submit form
    const handleSendComment = (
        values: MessageFormState,
        form: FormApi<MessageFormState, Partial<MessageFormState>>,
    ) => {
        if (values.text) {
            if (!values.parentId && conversation.typePlatform === 'facebook') {
                values.parentId = conversation.threadId
            }
            // Send the message
            const sendMessageResponse = postMessage({
                establishment: conversation.establishment,
                conversation: conversation.id,
                parentId: values.parentId ?? null,
                text: values.text,
            })

            sendMessageResponse
                .then((response) => {
                    // Reset the form when no error
                    form.reset()
                })
                .catch((error) => {
                    displayError(error, PageType.MESSAGING)
                })
        }
    }

    return (
        <Form
            //debug={console.log}
            onSubmit={handleSendComment}
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={1}>
                        <Grid item xs={10}>
                            <Field
                                name={'text'}
                                component={'textarea'}
                                autoFocus={true}
                                onKeyPress={(e: React.KeyboardEvent) => keyEnterEvent(e, handleSubmit)}
                                className={classes.discussTextarea}
                                placeholder={
                                    conversation.typeConversation === 'review' &&
                                    conversation.typePlatform === 'google' &&
                                    messageCount !== undefined &&
                                    messageCount > 0
                                        ? t('views.messaging.placeholderReplaceComment')
                                        : t('views.messaging.placeholderComment')
                                }
                                title={t('views.messaging.textareaHelper')}
                                disabled={inProgressSendMessage}
                            />
                        </Grid>
                        <Grid item xs={2} className={classes.discussSendBtn}>
                            <Button
                                type={'submit'}
                                color={'primary'}
                                className={classes.sendButtonSquare}
                                title={t('views.messaging.send')}
                            >
                                {inProgressSendMessage ? (
                                    <CircularProgress style={{ color: 'rgba(0, 0, 0, 0.26)' }} size={20} />
                                ) : (
                                    <SendIcon />
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Form>
    )
}

export default ReplyPostForm
