export const promiseTimeout = <T>(ms: number, promise: Promise<T>) => {
    // Create a promise that rejects in <ms> milliseconds
    let timeout = new Promise<T>((resolve, reject) => {
        let id = setTimeout(() => {
            clearTimeout(id)
            reject('Timed out in ' + ms + 'ms.')
        }, ms)
    })

    // Returns a race between our timeout and the passed in promise
    return Promise.race([promise, timeout])
}
