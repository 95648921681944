import React, { ComponentType } from 'react'
import Loader from '../../components/common/loader'
import { WithGraphApiProps } from './withGraphApi'

const withAuthenticatedGraphApi = <T extends WithGraphApiProps>(ComposedComponent: ComponentType<T>) => {
    const WithAuthenticatedGraphApi: React.FC<T> = (props) => {
        if (!props.isGraphApiAuthenticated) {
            return <Loader withLogo={false} />
        }
        return <ComposedComponent {...props} />
    }
    return WithAuthenticatedGraphApi
}

export default withAuthenticatedGraphApi
