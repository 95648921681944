import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import actions from 'core/store/actions'
import AlertDialog from 'components/dialogs/alertDialog'

const withAlert = (ComposedComponent: any) => {
    const WithAlert: React.FC = (props) => {
        const { alert } = useSelector(({ app }: ApplicationState) => ({
            alert: app.alert,
        }))
        const dispatch = useDispatch()
        const handleClose = () => {
            dispatch(actions.alert(undefined))
        }
        return (
            <>
                <ComposedComponent {...props} />
                {alert && <AlertDialog onClose={handleClose} {...alert} />}
            </>
        )
    }
    return WithAlert
}

export default withAlert
