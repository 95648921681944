import React from 'react'
import { Field } from 'react-final-form'
import { default as DefaultTextField } from '@material-ui/core/TextField/TextField'
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input'
import { PropTypes } from '@material-ui/core'
import { InputLabelProps } from '@material-ui/core/InputLabel/InputLabel'
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase'

export interface TextFieldProps {
    name: string
    label?: string
    placeholder?: string
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    multiline?: boolean
    required?: boolean
    minRows?: number
    maxRows?: number
    autoComplete?: string
    type?: string
    helperText?: string
    className?: string
    InputProps?: Partial<StandardInputProps>
    inputProps?: InputBaseComponentProps
    margin?: PropTypes.Margin
    variant?: any
    disabled?: boolean
    InputLabelProps?: InputLabelProps
}

const TextField: React.FC<TextFieldProps> = ({
    name,
    label,
    onChange: onChangeParent,
    placeholder,
    multiline,
    minRows,
    maxRows,
    autoComplete,
    required,
    type,
    helperText,
    className,
    InputProps,
    inputProps,
    margin = 'normal',
    variant,
    disabled,
    InputLabelProps,
}) => {
    return (
        <Field name={name}>
            {({ input: { name, onChange, value }, meta, ...rest }) => {
                const showError =
                    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
                return (
                    <DefaultTextField
                        className={className}
                        label={label}
                        name={name}
                        value={value}
                        variant={variant}
                        margin={margin}
                        disabled={disabled}
                        fullWidth
                        placeholder={placeholder}
                        onChange={(event) => {
                            if (onChangeParent) {
                                onChangeParent(event)
                            }
                            onChange(event)
                        }}
                        helperText={showError ? meta.error || meta.submitError : helperText}
                        error={showError}
                        inputProps={{
                            ...inputProps,
                        }}
                        multiline={multiline}
                        minRows={minRows}
                        maxRows={maxRows}
                        autoComplete={autoComplete}
                        required={required}
                        type={type}
                        InputProps={InputProps}
                        InputLabelProps={InputLabelProps}
                        {...rest}
                    />
                )
            }}
        </Field>
    )
}

export default TextField
