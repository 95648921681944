import React from 'react'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import { Establishment, useEstablishmentUpdate } from '@sugg-gestion/ubidreams-react-suggpro'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { HoursFormState } from 'components/forms/establishment/hoursForm'
import withMuiPickersUtilsProvider from 'containers/hoc/withMuiPickersUtilsProvider'
import EstablishmentHoursDialogView from 'components/views/establishment/dialogs/establishmentHoursDialogView'

interface Props {
    open: boolean
    onClose: () => void
    establishment: Establishment
}

const EstablishmentEditHoursDialog: React.FC<Props> = ({ establishment, open, onClose }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const { displayError } = useApiErrors()
    const { updateEstablishment, inProgress } = useEstablishmentUpdate()

    const handleUpdateHours = (values: HoursFormState) => {
        updateEstablishment({
            id: establishment.id,
            establishment: {
                businessHours: values.regularHours,
            },
        })
            .then(() => {
                enqueueSnackbar(t('views.establishment.hours.success'), {
                    variant: 'success',
                })
                onClose()
            })
            .catch((error) => displayError(error))
    }

    return (
        <EstablishmentHoursDialogView
            open={open}
            onClose={onClose}
            onSubmit={handleUpdateHours}
            inProgress={inProgress}
            initialValues={{
                regularHours: establishment.businessHours ?? undefined,
            }}
        />
    )
}

export default withMuiPickersUtilsProvider(EstablishmentEditHoursDialog)
