import React from 'react'
import { useFormsStyles } from '../forms.css'
import { Form } from 'react-final-form'
import { SubmitButtonProps } from 'components/common/submitButton'
import { Trans, useTranslation } from 'react-i18next'
import { SocialNetworks } from 'core/store/app/types'
import TextField from '../components/textField'
import { validateAWSURL } from 'appsync-scalar-utils'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: SocialNetworksFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: SocialNetworksFormState
}

export interface SocialNetworksFormState {
    website?: string
    facebook?: string
    instagram?: string
    mealAndMeet?: string
    interactiveGame?: string
    delivery?: string
    booking?: string
}

interface SocialNetworksFormError {
    website?: string
    facebook?: string
    instagram?: string
    mealAndMeet?: string
    interactiveGame?: string
    delivery?: string
    booking?: string
}

const SocialNetworksForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()

    const validateUrl = (
        values: SocialNetworksFormState,
        errors: SocialNetworksFormError,
        key: keyof SocialNetworksFormState,
    ) => {
        const url = values[key] as string | undefined
        if (url && !validateAWSURL(url)) {
            errors[key] = t('form-validation.url')
        }
    }

    const validate = (values: SocialNetworksFormState) => {
        const errors: SocialNetworksFormError = {}

        validateUrl(values, errors, 'website')
        validateUrl(values, errors, 'facebook')
        validateUrl(values, errors, 'instagram')
        validateUrl(values, errors, 'mealAndMeet')
        validateUrl(values, errors, 'interactiveGame')
        validateUrl(values, errors, 'delivery')
        validateUrl(values, errors, 'booking')

        return errors
    }

    const displaySocialNetworkName = (socialNetworkName: SocialNetworks) => {
        switch (socialNetworkName) {
            case SocialNetworks.Website:
                return 'website'
            case SocialNetworks.Facebook:
                return 'facebook'
            case SocialNetworks.Instagram:
                return 'instagram'
            case SocialNetworks.MealAndMeet:
                return 'mealAndMeet'
            case SocialNetworks.InteractiveGame:
                return 'interactiveGame'
            case SocialNetworks.Booking:
                return 'booking'
            case SocialNetworks.Delivery:
                return 'delivery'
        }
    }

    const getSocialNetworkField = (network: SocialNetworks) => {
        return (
            <TextField
                InputLabelProps={{ shrink: true }}
                name={displaySocialNetworkName(network)}
                label={t('form.' + displaySocialNetworkName(network))}
                placeholder={t('placeholder.' + displaySocialNetworkName(network))}
            />
        )
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <>
                            {getSocialNetworkField(SocialNetworks.Website)}
                            {getSocialNetworkField(SocialNetworks.Facebook)}
                            {getSocialNetworkField(SocialNetworks.Instagram)}
                            {getSocialNetworkField(SocialNetworks.MealAndMeet)}
                            {getSocialNetworkField(SocialNetworks.Booking)}
                            {getSocialNetworkField(SocialNetworks.Delivery)}
                            {getSocialNetworkField(SocialNetworks.InteractiveGame)}
                        </>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                className: formsClasses.submit,
                                children: <Trans i18nKey="actions.next" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}
export default SocialNetworksForm
