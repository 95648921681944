import React, { useEffect, useState } from 'react'
import PlayerFactory from 'youtube-player'
import { CircularProgress } from '@material-ui/core'
import { YouTubePlayer } from 'youtube-player/dist/types'
import useStyles from './youtubeVideo.css'

interface Props {
    videoKey: string
    playlist: string
    width: number
}

const YoutubeVideo: React.FC<Props> = ({ videoKey, width, playlist }) => {
    const playerElement = React.createRef<HTMLDivElement>()
    const [player, setPlayer] = useState<YouTubePlayer>()
    const classes = useStyles()
    const [currentVideoKey, setCurrentVideoKey] = useState<string>()
    const [currentPlayerWidth, setCurrentPlayerWidth] = useState<number>()

    const onStopInternal = (player: YouTubePlayer) => {
        player.seekTo(0, true)
        player.stopVideo()
    }

    // const resume = () => {
    //     if (player !== undefined) {
    //         player.playVideo()
    //     }
    // }

    useEffect(() => {
        if (playerElement.current !== null && player === undefined) {
            const player = PlayerFactory(playerElement.current, {
                height: (width * 9) / 16,
                width: width,
                videoId: videoKey,
                playerVars: {
                    autoplay: 0,
                    enablejsapi: 1,
                    iv_load_policy: 3,
                    modestbranding: 1,
                    listType: 'playlist',
                    list: playlist,
                },
            })
            setCurrentVideoKey(videoKey)
            setCurrentPlayerWidth(width)

            player.playVideo()

            const onPlayerStateChange = (event: any) => {
                if (event.data === 0) {
                    onStopInternal(player)
                }
            }
            player.addEventListener('onStateChange', onPlayerStateChange)
            const onError = (event: any) => {
                console.error('onError', event)
            }
            player.addEventListener('onError', onError)
            setPlayer(player)
        } else if (player !== undefined) {
            if (videoKey !== currentVideoKey) {
                player.loadVideoById(videoKey)
                setCurrentVideoKey(videoKey)
            }
            if (width !== currentPlayerWidth) {
                player.setSize(width, (width * 9) / 16)
                setCurrentPlayerWidth(width)
            }
        }
    }, [videoKey, playlist, width, playerElement, player, currentVideoKey, currentPlayerWidth])

    return (
        <div className={classes.videoContentContainer}>
            <div ref={playerElement} style={{ backgroundColor: '#000' }}>
                <CircularProgress />
            </div>
        </div>
    )
}

export default YoutubeVideo
