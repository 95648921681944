import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainLayout: {
            display: 'flex',
            minHeight: '100%',
            minWidth: '100%',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'relative',
            backgroundColor: theme.palette.background.default,
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(12),
            [theme.breakpoints.down('xs')]: {
                paddingBottom: 0,
                paddingTop: 0,
            },
        },
        logo: {
            maxWidth: 300,
            width: '100%',
            [theme.breakpoints.down('xs')]: {
                '$mainLayout > &': {
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                    marginBottom: theme.spacing(4),
                },
            },
        },
    }),
)
