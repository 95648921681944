import { combineReducers } from 'redux'
import { AppState } from './app/types'
import { appReducer } from './app/reducer'
import { History } from 'history'
import { connectRouter, RouterState } from 'connected-react-router'
import { suggProReducer, SuggProState } from '@sugg-gestion/ubidreams-react-suggpro'
import { ShareState } from './share/types'
import { shareReducer } from './share/reducer'
import { TutoState } from './tuto/types'
import { tutoReducer } from './tuto/reducer'

export interface ApplicationState {
    app: AppState
    router: RouterState
    suggpro: SuggProState
    share: ShareState
    tuto: TutoState
}

export const createRootReducer = (history: History) =>
    combineReducers<ApplicationState>({
        app: appReducer,
        router: connectRouter(history),
        suggpro: suggProReducer,
        share: shareReducer,
        tuto: tutoReducer,
    })
