import Grid from '@material-ui/core/Grid'
import { GridSize } from '@material-ui/core/Grid/Grid'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import classNames from 'classnames'
import clsx from 'clsx'
import ImageCard, { ImageContainerProps } from 'components/common/imageCard'
import React, { ReactNode } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import useStyles from './draggableImage.css'

interface Props extends Partial<Record<Breakpoint, boolean | GridSize>> {
    url: string
    index: number
    moveEntry: (from: number, to: number) => void
    deleteEntry: () => void
    error?: boolean
    beforeImage?: ReactNode
    className?: string
    containerClassName?: string
    imageContainerClassName?: string
    title?: string
    ImageContainer?: React.FC<ImageContainerProps>
}

export const ItemTypes = {
    MENU_ENTRY: 'menuEntry',
}

const DraggableImage: React.FC<Props> = ({
    url,
    index,
    moveEntry,
    deleteEntry,
    error = false,
    children,
    beforeImage,
    className,
    containerClassName,
    imageContainerClassName,
    title,
    ImageContainer,
    ...gridSize
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [{ isDragging }, drag] = useDrag<any, any, { isDragging: boolean }>({
        type: ItemTypes.MENU_ENTRY,
        item: { index: index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const [{ isOver }, drop] = useDrop({
        accept: ItemTypes.MENU_ENTRY,
        // @ts-ignore
        drop: (item) => moveEntry(item.index, index),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    })

    return (
        <Grid
            item
            {...gridSize}
            ref={drop}
            className={classNames({
                [classes.over]: isOver,
            })}
        >
            <ImageCard
                ref={drag}
                index={0}
                url={url}
                title={title ?? t('form.entryN', { id: index + 1 })}
                deleteEntry={deleteEntry}
                className={clsx(className, {
                    [classes.dragged]: isDragging,
                })}
                cardClassName={clsx({
                    [classes.error]: error,
                })}
                containerClassName={containerClassName}
                imageContainerClassName={imageContainerClassName}
                imageWidth={400}
                beforeImage={beforeImage}
                ImageContainer={ImageContainer}
                children={children}
            />
        </Grid>
    )
}

export default DraggableImage
