import { Chip, Drawer, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Establishment, MessagingConversation } from '@sugg-gestion/ubidreams-react-suggpro'
import AuthenticateContent from 'components/layouts/components/authenticateContent'
import { useUpdateConversation } from 'core/services/messaging/useUpdateConversation'
import React, { memo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import DiscussPane from './components/discussPane'
import TabTypeConversation from './components/tabTypeConversation'
import { useStyles } from './styles.css'

interface Props {
    establishment: Establishment
}

const MessagingView: React.FC<Props> = ({ establishment }) => {
    const theme = useTheme()
    const classes = useStyles()
    const { t } = useTranslation()

    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

    const [conversationSelected, setConversationSelected] = useState<MessagingConversation>()
    // Handle onClick on a conversation
    const handleSelectedConversation = (conversation: MessagingConversation) => {
        if (!conversationSelected || (conversationSelected && conversation.id !== conversationSelected.id)) {
            setConversationSelected(conversation)
        }
        setStateDrawer(true)
    }

    // Function to update lastView when a conversation is selected
    useUpdateConversation({
        establishment: establishment.id,
        id: conversationSelected?.id,
    })

    // Drawer
    const [stateDrawer, setStateDrawer] = React.useState(false)
    const toggleDrawer = (open: boolean) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'tab' || event.key === 'shift')) {
            setConversationSelected(undefined)
            return
        }
        setStateDrawer(open)
    }

    return (
        <AuthenticateContent withAction={false} className={classes.root}>
            <Typography variant="h1" color="textSecondary" className={classes.title}>
                <Trans i18nKey="views.messaging.title" />
                <Chip size="small" label={t('common.new')} color="primary" className={classes.new} />
            </Typography>
            <Alert severity="info" className={classes.disclaimer}>
                <Trans i18nKey="views.messaging.disclaimer" />
            </Alert>
            {isMobile ? (
                <>
                    <Grid container spacing={2} className={classes.messagingContainer}>
                        <Grid item xs={12} sm={4} style={{ height: '100%' }}>
                            <TabTypeConversation
                                establishment={establishment}
                                conversationSelected={conversationSelected}
                                onSelect={handleSelectedConversation}
                            />
                        </Grid>
                    </Grid>
                    <Drawer
                        anchor={'right'}
                        variant={'persistent'}
                        open={stateDrawer}
                        onClose={toggleDrawer(false)}
                    >
                        <Grid container spacing={2} className={classes.messagingContainer}>
                            <Grid item xs={12} sm={8} className={classes.discussionPane}>
                                {conversationSelected && (
                                    <DiscussPane
                                        establishment={establishment}
                                        conversation={conversationSelected}
                                        handleToggleDrawer={toggleDrawer}
                                        disableConversation={() => setConversationSelected(undefined)}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Drawer>
                </>
            ) : (
                <Grid container spacing={2} className={classes.messagingContainer}>
                    <Grid item xs={12} sm={4} style={{ height: '100%' }}>
                        <TabTypeConversation
                            establishment={establishment}
                            conversationSelected={conversationSelected}
                            onSelect={handleSelectedConversation}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} className={classes.discussionPane}>
                        {conversationSelected && (
                            <DiscussPane
                                establishment={establishment}
                                conversation={conversationSelected}
                                disableConversation={() => setConversationSelected(undefined)}
                            />
                        )}
                    </Grid>
                </Grid>
            )}
        </AuthenticateContent>
    )
}

export default memo(MessagingView)
