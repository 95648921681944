import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import { useEffect, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import actions from 'core/store/actions'
import { RegisterVideosAction, Video } from 'core/store/tuto/types'

interface FetchVideosAction {
    items: Array<Video>
    nextPageToken?: string
    pageInfo: {
        totalResults: number
        resultsPerPage: number
    }
}

const fetchVideos = (itemsPerPage: number, nextToken?: string) => {
    return new Promise<RegisterVideosAction>((resolve, reject) => {
        const { REACT_APP_YOUTUBE_KEY, REACT_APP_YOUTUBE_PLAYLIST } = process.env
        if (!REACT_APP_YOUTUBE_KEY || !REACT_APP_YOUTUBE_PLAYLIST) {
            reject()
            return
        }
        const url = new URL('https://www.googleapis.com/youtube/v3/playlistItems')
        url.searchParams.append('part', 'snippet')
        url.searchParams.append('playlistId', REACT_APP_YOUTUBE_PLAYLIST)
        url.searchParams.append('key', REACT_APP_YOUTUBE_KEY)
        url.searchParams.append('maxResults', itemsPerPage.toString())
        if (nextToken) {
            url.searchParams.append('pageToken', nextToken)
        }
        axios
            .get(url.href)
            .then((response: AxiosResponse<FetchVideosAction>) => {
                if (response.data) {
                    const {
                        items,
                        nextPageToken,
                        pageInfo: { totalResults },
                    } = response.data
                    resolve({
                        videos: items,
                        nextToken: nextPageToken,
                        totalVideos: totalResults,
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                reject()
            })
    })
}

export const useTuto = (itemsPerPage: number) => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)
    const { videos, pageFetched, nextToken, totalVideos } = useSelector(({ tuto }: ApplicationState) => ({
        videos: tuto.videos,
        pageFetched: tuto.pageFetched,
        nextToken: tuto.nextToken,
        totalVideos: tuto.totalVideos,
    }))

    const fetchVideosNextPage = (page: number) => {
        if (pageFetched && page < pageFetched) {
            // Videos are in cache
            return
        } else {
            setInProgress(true)
            fetchVideos(itemsPerPage, nextToken)
                .then((result) => {
                    dispatch(actions.registerVideos(result))
                    setInProgress(false)
                })
                .catch(() => setInProgress(false))
        }
    }

    useEffect(() => {
        if (totalVideos === undefined) {
            fetchVideos(itemsPerPage)
                .then((result) => {
                    dispatch(actions.registerVideos(result))
                    setInProgress(false)
                })
                .catch(() => setInProgress(false))
        }
    }, [totalVideos, dispatch, itemsPerPage])

    const getVideos = (page: number) => {
        if (pageFetched && page < pageFetched) {
            // Videos are in cache
            return videos.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
        } else {
            return []
        }
    }

    return {
        getVideos,
        fetchVideosNextPage,
        numberOfPage: totalVideos ? Math.ceil(totalVideos / 8) : 0,
        inProgress,
    }
}
