import loadImage from 'blueimp-load-image'
import { maxBy } from 'lodash'

export interface SortedImage {
    id: number
    url: string
    file?: File
    sortOrder: number
    isMainPicture?: boolean
}

export interface ImageOptions {
    maxWidth?: number
    maxHeight?: number
}

export const useSortedImages = <T extends SortedImage>(
    defaultProperties: T,
    options: ImageOptions = { maxWidth: 400, maxHeight: 400 },
) => {
    const maxSortOrder = (defaultValue?: Array<T>) => {
        return (maxBy(defaultValue, 'sortOrder')?.sortOrder ?? -1) + 1
    }

    const updateImages = (files?: Array<string | File | undefined>, defaultValue?: Array<T>) => {
        if (files === undefined) {
            return new Promise<Array<T>>((resolve) => resolve(defaultValue || []))
        }
        const promises: Array<Promise<T>> = []
        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            if (file !== undefined) {
                if (typeof file == 'string') {
                    promises.push(
                        new Promise<T>((resolve) => {
                            resolve({
                                ...defaultProperties,
                                id: -(maxSortOrder(defaultValue) + i + 1),
                                url: file,
                                sortOrder: maxSortOrder(defaultValue) + i,
                                isMainPicture: false,
                            })
                        }),
                    )
                } else {
                    promises.push(
                        new Promise<T>((resolve, reject) => {
                            loadImage(
                                file,
                                (canvas: HTMLCanvasElement) => {
                                    // @ts-ignore
                                    if (canvas.type === 'error') {
                                        reject(canvas)
                                    } else {
                                        resolve({
                                            ...defaultProperties,
                                            id: -(maxSortOrder(defaultValue) + i + 1),
                                            url: canvas.toDataURL(),
                                            file,
                                            sortOrder: maxSortOrder(defaultValue) + i,
                                        })
                                    }
                                },
                                {
                                    orientation: true,
                                    meta: true,
                                    maxWidth: options.maxWidth,
                                    maxHeight: options.maxHeight,
                                    canvas: true,
                                    contain: true,
                                    crop: true,
                                },
                            )
                        }),
                    )
                }
            }
        }
        return Promise.all(promises).then((values) => {
            return [...(defaultValue || []), ...values]
        })
    }

    return {
        updateImages,
    }
}
