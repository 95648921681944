import { Location } from '@sugg-gestion/ubidreams-react-suggpro'
import { promiseTimeout } from '../helper/promise'

export const useBrowserLocalization = () => {
    const geo = navigator.geolocation
    const localize = (): Promise<Location> => {
        return promiseTimeout(
            10000,
            new Promise<Location>((resolve, reject) => {
                geo.getCurrentPosition(
                    ({ coords }) => {
                        const position: Location = {
                            lng: coords.longitude,
                            lat: coords.latitude,
                        }
                        resolve(position)
                    },
                    ({ message }) => {
                        reject(message)
                    },
                    {
                        enableHighAccuracy: true,
                        timeout: Infinity,
                        maximumAge: 0,
                    },
                )
            }),
        )
    }
    return {
        isBrowserLocalizationAvailable: geo !== undefined,
        localize,
    }
}
