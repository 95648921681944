import React, { ComponentType } from 'react'
import useHubSpot from 'core/services/hubspot/useHubSpot'

const withHubSpot = (ComposedComponent: ComponentType) => {
    const WithHubSpot: React.FC = ({ ...props }) => {
        useHubSpot()
        return <ComposedComponent {...props} />
    }
    return WithHubSpot
}

export default withHubSpot
