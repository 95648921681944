import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainPictureContainer: {
            borderWidth: 3,
            borderStyle: 'solid',
            borderColor: theme.palette.border.main,
        },
        mainPictureContainerVisible: {
            borderWidth: 3,
            borderStyle: 'solid',
            borderColor: theme.palette.primary.main,
            borderRadius: 4,
            backgroundColor: theme.palette.primary.main,
        },
        controlLabel: {
            marginLeft: 0,
            padding: theme.spacing(0.5, 1),
        },
        controlLabelText: {
            fontSize: '0.8rem',
            width: '100%',
            fontWeight: theme.fontsWeight.semiBold,
        },
        controlLabelTextActive: {
            color: theme.palette.primary.contrastText,
        },
    }),
)
