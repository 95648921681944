import React from 'react'
import { CardActions, Divider } from '@material-ui/core'
import HiddenCss from '@material-ui/core/Hidden/HiddenCss'
import { useStyles } from './authenticateActions.css'
import clsx from 'clsx'

interface Props {
    className?: string
}

const AuthenticateActions: React.FC<Props> = ({ children, className }) => {
    const classes = useStyles()
    return (
        <div className={classes.actionsContainer}>
            <HiddenCss smUp>
                <Divider />
            </HiddenCss>
            <CardActions className={clsx(classes.actions, className)}>{children}</CardActions>
        </div>
    )
}

export default AuthenticateActions
