export const download = (content: string, fileName: string, mimeType: string) => {
    const a = document.createElement('a')
    mimeType = mimeType || 'application/octet-stream'

    // @ts-ignore
    if (navigator.msSaveBlob) {
        // IE10
        // @ts-ignore
        navigator.msSaveBlob(
            new Blob([content], {
                type: mimeType,
            }),
            fileName,
        )
    } else if (URL && 'download' in a) {
        //html5 A[download]
        a.href = URL.createObjectURL(
            new Blob([content], {
                type: mimeType,
            }),
        )
        a.setAttribute('download', fileName)
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    } else {
        window.location.href = 'data:application/octet-stream,' + encodeURIComponent(content) // only this mime type is supported
    }
}
