import React, { ComponentType } from 'react'
import { useSelector, useStore } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import Bugsnag, { Event } from '@bugsnag/js'

const withBugsnagUser = (ComposedComponent: ComponentType) => {
    const WithBugsnagUser: React.FC = ({ ...props }) => {
        const { REACT_APP_BUGSNAG_ID, REACT_APP_STAGE } = process.env
        const { user } = useSelector(({ suggpro: { auth } }: ApplicationState) => ({
            user: auth.user,
        }))
        const store = useStore()

        if (!REACT_APP_BUGSNAG_ID || !REACT_APP_STAGE) {
            return <ComposedComponent {...props} />
        }
        const addReduxStore = (event: Event, state: any) => {
            const categories = Object.keys(state)
            categories.forEach((category) => {
                if (category === 'suggpro') {
                    addReduxStore(event, state[category])
                } else {
                    event.addMetadata('redux-' + category, state[category])
                }
            })
        }
        Bugsnag.addOnError((event) => {
            if (user) {
                event.setUser(user.id.toString(), user.email, user.fullname)
            }
            addReduxStore(event, store.getState())
        })
        return <ComposedComponent {...props} />
    }
    return WithBugsnagUser
}

export default withBugsnagUser
