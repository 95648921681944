import React, { useState } from 'react'
import ReminderNotebookView from 'components/views/reminderNotebook/reminderNotebookView'
import withAuthenticateLayout, { WithAuthenticateLayout } from 'containers/hoc/withAuthenticateLayout'
import moment, { Moment } from 'moment'
import { useEstablishmentUpdate, useReminderNotebooks } from '@sugg-gestion/ubidreams-react-suggpro'
import AuthenticatedLoading from 'components/layouts/components/authenticatedLoading'
import { useTranslation } from 'react-i18next'
import { useApiErrors } from '../../core/services/suggPro/useApiErrors'
import DateSelector from '../../components/views/reminderNotebook/components/dateSelector'
import { useSnackbar } from 'notistack'
import { download } from 'components/forms/helpers/download'

interface Props extends WithAuthenticateLayout {}

const ReminderNotebook: React.FC<Props> = ({ establishment }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const [date, setDate] = useState<Moment | undefined>(moment().startOf('day'))
    const dates: Array<Moment | undefined> = [undefined]
    for (let i = 0; i < 14; i++) {
        dates.push(moment().add(-i, 'days').startOf('day'))
    }

    const { displayError } = useApiErrors()
    const { isReady, reminderNotebooks, refreshReminderNotebooks, inProgress } = useReminderNotebooks(
        establishment.id,
        date?.format('YYYY-MM-DD'),
    )
    const { updateEstablishment, inProgress: updateInProgress } = useEstablishmentUpdate()

    if (!isReady) {
        return (
            <AuthenticatedLoading
                title={t('views.reminderNotebook.title')}
                loadingMessage={t('common.loading')}
            />
        )
    }

    const refreshReminderNotebookAction = (date: Moment | undefined) => {
        refreshReminderNotebooks({
            id: establishment.id,
            date: date?.format('YYYY-MM-DD'),
        }).catch((error) => displayError(error))
    }

    const handleChangeDate = (event: React.ChangeEvent<{ value: unknown }>) => {
        const value = event.target.value
        let date: Moment | undefined
        if (value === 'seeAll') {
            date = undefined
        } else {
            date = moment(value as string)
        }
        setDate(date)
        refreshReminderNotebookAction(date)
    }

    const handleSetReminderNotebook = (reminderNotebook: null | 'STANDARD' | 'ENFORCE') => {
        updateEstablishment({
            id: establishment.id,
            establishment: {
                reminderNotebook,
            },
        })
            .then((establishment) => {
                switch (establishment.reminderNotebook) {
                    case 'STANDARD':
                        enqueueSnackbar(t('views.reminderNotebook.success.standard'), {
                            variant: 'success',
                        })
                        break
                    case 'ENFORCE':
                        enqueueSnackbar(t('views.reminderNotebook.success.enforce'), {
                            variant: 'success',
                        })
                        break
                    default:
                        enqueueSnackbar(t('views.reminderNotebook.success.disable'), {
                            variant: 'success',
                        })
                        break
                }
            })
            .catch((error) => displayError(error))
    }

    const handleDownload = () => {
        const columns = [t('form.createdAt'), t('form.firstName'), t('form.lastName'), t('form.phoneNumber')]
        if (reminderNotebooks && reminderNotebooks.length > 0) {
            let csvContent = columns.join(';') + '\n'
            reminderNotebooks.forEach((reminderNotebook, index) => {
                const dataString = [
                    moment(reminderNotebook.createdAt).format(t('DD MMMM à LT')),
                    reminderNotebook.givenName,
                    reminderNotebook.familyName,
                    reminderNotebook.phone,
                ].join(';')
                csvContent += index < reminderNotebooks.length ? dataString + '\n' : dataString
            })
            download(csvContent, 'download.csv', 'text/csv;encoding:utf-8')
        } else {
            if (date) {
                enqueueSnackbar(
                    t('views.reminderNotebook.error.noDataDate', {
                        date: date.toDate(),
                    }),
                    {
                        variant: 'error',
                    },
                )
            } else {
                enqueueSnackbar(t('views.reminderNotebook.error.noData'), {
                    variant: 'error',
                })
            }
        }
    }

    return (
        <ReminderNotebookView
            reminderNotebooks={reminderNotebooks}
            dateSelector={
                <DateSelector
                    date={date}
                    dates={dates}
                    inProgress={inProgress}
                    onChangeDate={handleChangeDate}
                />
            }
            establishment={establishment}
            onSetReminderNotebook={handleSetReminderNotebook}
            updateInProgress={updateInProgress}
            onDownload={handleDownload}
            inProgress={inProgress}
            onRefreshReminderNotebook={() => refreshReminderNotebookAction(date)}
        />
    )
}

export default withAuthenticateLayout(ReminderNotebook)
