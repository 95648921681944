import React from 'react'
import AuthenticateContent from 'components/layouts/components/authenticateContent'
import { useStyles } from './styles.css'
import { Button, Typography } from '@material-ui/core'
import { Trans } from 'react-i18next'
import { Establishment } from '@sugg-gestion/ubidreams-react-suggpro'
import { Link } from 'react-router-dom'

interface Props {
    establishment: Establishment
}

const EmptyMenuView: React.FC<Props> = ({ establishment }) => {
    const classes = useStyles()
    const establishmentBasePath = '/establishment/:id'.replace(':id', establishment.id.toString() ?? '')

    return (
        <AuthenticateContent withAction={false}>
            <Typography variant="h1" color="textSecondary" className={classes.title}>
                <Trans i18nKey="views.menu.title" />
            </Typography>
            <Typography variant="h2" color="textSecondary" className={classes.subtitle}>
                <Trans i18nKey="views.menu.subtitle" />
            </Typography>
            <div className={classes.emptyContainer}>
                <Typography color="textSecondary" className={classes.empty}>
                    <Trans i18nKey="views.menu.empty.message" />
                </Typography>
                <Button
                    variant="outlined"
                    color="primary"
                    to={establishmentBasePath + '/menu/edit'}
                    component={Link}
                >
                    <Trans i18nKey="actions.addMenu" />
                </Button>
            </div>
        </AuthenticateContent>
    )
}

export default EmptyMenuView
