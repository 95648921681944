import { useCallback, useEffect, useState } from 'react'
import { MessagingConversation, useGetContent } from '@sugg-gestion/ubidreams-react-suggpro'
import { PageType, useApiErrors } from '../suggPro/useApiErrors'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store/reducers'

export const useFetchContent = (parameters: MessagingConversation) => {
    const { displayError } = useApiErrors()
    const [inProgress, setInProgress] = useState(false)
    const [hasError, setHasError] = useState<number | undefined>(undefined)
    const { listMessage, listPostDetails } = useSelector(({ suggpro: { messaging } }: ApplicationState) => ({
        listMessage: messaging.listMessages,
        listPostDetails: messaging.listPostDetails,
    }))

    const { getContent } = useGetContent()

    const getConversationContent = useCallback(() => {
        setInProgress(true)
        getContent({ ...parameters })
            .catch((error) => {
                displayError(error, PageType.MESSAGING)
                setHasError(parameters.id)
            })
            .finally(() => {
                setInProgress(false)
            })
    }, [displayError, getContent, parameters])

    useEffect(() => {
        if (
            hasError !== parameters.id &&
            !inProgress &&
            (listMessage === undefined ||
                !listMessage.find((element) => element.conversation === parameters.id)) &&
            (listPostDetails === undefined ||
                !listPostDetails.find((element) => element.conversation === parameters.id))
        ) {
            getConversationContent()
        }
    }, [
        displayError,
        getConversationContent,
        inProgress,
        listMessage,
        parameters.id,
        hasError,
        parameters.typeConversation,
        listPostDetails,
    ])

    return { listMessage, listPostDetails, inProgress, hasError, setHasError }
}
