import React from 'react'
import withSignUpLayout, { WithSignUpLayout } from 'containers/hoc/withSignUpLayout'
import { useDispatch } from 'react-redux'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import {
    Establishment,
    EstablishmentPicture,
    useEstablishmentPicturesCreate,
    useEstablishmentPicturesDelete,
    useEstablishmentPicturesUpdate,
    useEstablishmentUpdate,
} from '@sugg-gestion/ubidreams-react-suggpro'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { redirectToEstablishment } from 'core/store/app/actions'
import EstablishmentPhotosView from 'components/views/establishment/establishmentPhotosView'
import { PhotosFormState } from 'components/forms/establishment/photosForm'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import withDnD from '../hoc/withDnD'
import { SortedImage } from '../../components/forms/helpers/useSortedImages'

interface Props extends WithSignUpLayout {
    step?: number
    steps?: number
}

const EstablishmentAddPhotos: React.FC<Props> = ({ establishment, step = 7, steps = 8 }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const history = useHistory()

    const { displayError } = useApiErrors()
    const { updateEstablishment, inProgress } = useEstablishmentUpdate()
    const { createEstablishmentPictures, inProgress: createEstablishmentPicturesInProgress } =
        useEstablishmentPicturesCreate()
    const { updateEstablishmentPictures, inProgress: updateEstablishmentPicturesInProgress } =
        useEstablishmentPicturesUpdate()
    const { deleteEstablishmentPicture, inProgress: deleteEstablishmentPictureInProgress } =
        useEstablishmentPicturesDelete()
    const { updateEstablishment: updateToPreviousStatus, inProgress: updateToPreviousStatusInProgress } =
        useEstablishmentUpdate()

    const sortImagesEstablishmentPicture = (establishmentPicture: Array<EstablishmentPicture>) => {
        const sortedImagesEstablishmentPicture: Array<SortedImage> = []
        establishmentPicture.forEach((establishmentPicture) => {
            sortedImagesEstablishmentPicture.push({
                id: establishmentPicture.id,
                url: establishmentPicture.url,
                sortOrder: establishmentPicture.sortOrder,
                isMainPicture: establishmentPicture.isMainPicture,
            })
        })
        return sortedImagesEstablishmentPicture
    }

    const updateEstablishmentPicturesAction = (
        sortOrders: Array<number>,
        mainPicture: number | undefined,
    ) => {
        return updateEstablishmentPictures({
            id: establishment.id,
            sortOrders,
            mainPicture,
        }).catch((error) => {
            displayError(error)
        })
    }

    const reorganizeEstablishmentPictureAction = (
        addedEstablishmentPicture: Array<EstablishmentPicture>,
        sortedImages: Array<SortedImage>,
    ) => {
        addedEstablishmentPicture = _.sortBy<EstablishmentPicture>(addedEstablishmentPicture, 'id')
        let newSortedImages: Array<SortedImage> = []
        let index = 0
        let mainPicture: number | undefined = undefined
        sortedImages.forEach((sortedImage) => {
            if (sortedImage.file) {
                const addedEstablishmentPictures = addedEstablishmentPicture[index]
                newSortedImages.push({
                    sortOrder: sortedImage.sortOrder,
                    id: addedEstablishmentPictures.id,
                    url: addedEstablishmentPictures.url,
                })
                if (sortedImage.isMainPicture === true) {
                    mainPicture = addedEstablishmentPictures.id
                }
                index++
            } else {
                newSortedImages.push(sortedImage)
                if (sortedImage.isMainPicture === true) {
                    mainPicture = sortedImage.id
                }
            }
        })
        newSortedImages = _.sortBy<SortedImage>(newSortedImages, 'sortOrder')
        const sortOrders = newSortedImages.map((sortedImage) => sortedImage.id)
        return updateEstablishmentPicturesAction(sortOrders, mainPicture)
    }

    const uploadPhotos = (establishment: Establishment, values: PhotosFormState) => {
        return new Promise<void>((resolve, reject) => {
            const photos: Array<File> = []
            values.photos?.forEach((photo) => {
                if (photo.file) {
                    photos.push(photo.file)
                }
            })
            if (photos.length > 0) {
                createEstablishmentPictures({
                    id: establishment.id,
                    files: photos,
                })
                    .then(() => {
                        resolve()
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } else {
                resolve()
            }
        })
    }

    const deletePhotos = (establishment: Establishment, photosToDelete: Array<EstablishmentPicture>) => {
        return new Promise<void>((resolve) => {
            const deletePhotosPromises = []
            for (let i = 0; i < photosToDelete.length; i++) {
                const photoToDelete = photosToDelete[i]
                deletePhotosPromises.push(
                    deleteEstablishmentPicture({
                        id: establishment.id,
                        pictureId: photoToDelete.id,
                    }),
                )
            }
            Promise.all(deletePhotosPromises)
                .then(() => {
                    resolve()
                })
                .catch((error) => {
                    displayError(error)
                    resolve()
                })
        })
    }

    const handleUpdatePhotos = (values: PhotosFormState) => {
        const establishmentPhotos = _.get(establishment, 'pictures', [])
        const sortedImages = values.photos.map((image) => ({
            ...image,
            isMainPicture: parseInt(values.flag) === image.id,
        }))
        const photosToDelete = _.differenceWith(
            establishmentPhotos,
            values.photos ?? [],
            (establishmentPhoto, dataPhoto) => {
                return establishmentPhoto.url === dataPhoto.url
            },
        )
        Promise.all([uploadPhotos(establishment, values), deletePhotos(establishment, photosToDelete)])
            .then(() => {
                const files: Array<File> = []
                sortedImages.forEach((sortedImage) => {
                    if (sortedImage.file) {
                        if (values.flag === sortedImage.id.toString()) {
                            sortedImage.isMainPicture = true
                        }
                        files.push(sortedImage.file)
                    }
                })
                return updateEstablishment({
                    id: establishment.id,
                    establishment: {
                        ...establishment,
                        defaultMenuBoardCategories: undefined,
                    },
                    nextStatus: 'DONE',
                })
                    .then((response) => {
                        let addedEstablishmentPictures = _.differenceBy<
                            EstablishmentPicture,
                            EstablishmentPicture
                        >(response.pictures, establishmentPhotos, 'id')
                        return reorganizeEstablishmentPictureAction(addedEstablishmentPictures, sortedImages)
                    })
                    .catch((error) => {
                        console.error(error)
                        displayError(error)
                    })
            })
            .then(() => {
                enqueueSnackbar(t('views.establishment.photos.success'), {
                    variant: 'success',
                })
                history.push('/establishment/:id/add/completed'.replace(':id', establishment.id.toString()))
            })
            .catch((error) => displayError(error))
    }

    const handlePrevious = () => {
        updateToPreviousStatus({
            id: establishment.id,
            establishment: {},
            nextStatus: 'LOCATION',
        })
            .then(() => {
                dispatch(redirectToEstablishment())
            })
            .catch((error) => displayError(error))
    }

    return (
        <EstablishmentPhotosView
            step={step}
            steps={steps}
            onSubmit={handleUpdatePhotos}
            inProgress={
                createEstablishmentPicturesInProgress ||
                updateEstablishmentPicturesInProgress ||
                deleteEstablishmentPictureInProgress ||
                inProgress
            }
            initialValues={{
                photos: sortImagesEstablishmentPicture(establishment.pictures),
            }}
            previousInProgress={updateToPreviousStatusInProgress}
            onPrevious={handlePrevious}
        />
    )
}

export default withSignUpLayout(withDnD(EstablishmentAddPhotos))
