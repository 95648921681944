import React, { memo, useState } from 'react'
import AuthenticateContent from 'components/layouts/components/authenticateContent'
import {
    Button,
    ButtonGroup,
    Chip,
    Grid,
    Tab,
    Tabs,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { Trans, useTranslation } from 'react-i18next'
import { useStyles } from './styles.css'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import { MenuBoardProperty, NewsProperty, Statistics } from '@sugg-gestion/ubidreams-react-suggpro'
import { Alert } from '@material-ui/lab'
import { KeyboardDatePicker } from '@material-ui/pickers'
import StatisticView from './components/statisticView'
import { SocialMedia } from 'core/services/statistics/useStatisticsData'
import { Can } from '@casl/react'
import { AnyAbility } from '@casl/ability'
import Loader from 'components/common/loader'

interface Props {
    statistics: Array<Statistics<MenuBoardProperty>>
    newsStatistics: Array<Statistics<NewsProperty>>
    startDate: Date
    endDate: Date
    updateStartDate: (date: Date) => void
    updateEndDate: (date: Date) => void
    loading: boolean
    isFacebookLinked: boolean
    isInstagramLinked: boolean
    isGoogleLinked: boolean
    isTwitterLinked: boolean
    refreshDate: Date
    ability: AnyAbility
}

const StatisticsView: React.FC<Props> = ({
    statistics,
    newsStatistics,
    startDate,
    endDate,
    updateStartDate,
    updateEndDate,
    loading,
    isFacebookLinked,
    isInstagramLinked,
    isGoogleLinked,
    isTwitterLinked,
    refreshDate,
    ability,
}) => {
    const theme = useTheme()
    const classes = useStyles()
    const { t } = useTranslation()

    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

    const [type, setType] = useState<'views' | 'likes' | 'comments' | 'shares'>('views')
    const [per, setPer] = useState<'perDay' | 'perPost'>('perDay')
    const [socialMedias, setSocialMedias] = useState<Array<SocialMedia>>([
        SocialMedia.FACEBOOK,
        SocialMedia.INSTAGRAM,
        SocialMedia.TWITTER,
        SocialMedia.GOOGLE_MY_BUSINESS,
    ])
    const [view, setView] = useState<'menu' | 'news'>('menu')

    return (
        <AuthenticateContent withAction={false}>
            <Typography variant="h1" color="textSecondary" className={classes.title}>
                <Trans i18nKey="views.statistics.title" />
            </Typography>
            <div>
                <Chip
                    icon={<AutorenewIcon />}
                    className={classes.refresh}
                    variant="outlined"
                    label={
                        <Trans
                            i18nKey="views.statistics.refresh"
                            values={{
                                date: refreshDate,
                            }}
                        />
                    }
                />
            </div>
            <Grid container spacing={2}>
                <Can I="api.events_access" ability={ability}>
                    <Grid item xs={12}>
                        <Tabs
                            value={view}
                            onChange={(news, view) => setView(view)}
                            centered
                            TabIndicatorProps={{ children: <span /> }}
                            classes={{
                                root: classes.tabs,
                                indicator: classes.indicator,
                            }}
                        >
                            <Tab
                                value="menu"
                                label={t('views.statistics.tabs.menu')}
                                className={classes.tab}
                            />
                            <Tab
                                value="news"
                                label={t('views.statistics.tabs.news')}
                                className={classes.tab}
                            />
                        </Tabs>
                    </Grid>
                </Can>
                <Grid item xs={12}>
                    <div className={classes.actionContainer}>
                        <Grid
                            container
                            spacing={2}
                            alignItems={'center'}
                            justifyContent={isMobile ? 'center' : 'flex-start'}
                        >
                            <Grid item>
                                <ButtonGroup color="primary" className={classes.actionButtonGroup}>
                                    <Button
                                        variant={type === 'views' ? 'contained' : 'outlined'}
                                        onClick={() => setType('views')}
                                    >
                                        <Trans i18nKey="views.statistics.views" />
                                    </Button>
                                    <Button
                                        variant={type === 'likes' ? 'contained' : 'outlined'}
                                        onClick={() => setType('likes')}
                                    >
                                        <Trans i18nKey="views.statistics.likes" />
                                    </Button>
                                    <Button
                                        variant={type === 'comments' ? 'contained' : 'outlined'}
                                        onClick={() => setType('comments')}
                                    >
                                        <Trans i18nKey="views.statistics.comments" />
                                    </Button>
                                    <Button
                                        variant={type === 'shares' ? 'contained' : 'outlined'}
                                        onClick={() => setType('shares')}
                                    >
                                        <Trans i18nKey="views.statistics.shares" />
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                            <Grid item className={classes.perContainer}>
                                <ButtonGroup color="primary">
                                    <Button
                                        variant={per === 'perDay' ? 'contained' : 'outlined'}
                                        onClick={() => setPer('perDay')}
                                    >
                                        <Trans i18nKey="views.statistics.perDay" />
                                    </Button>
                                    <Button
                                        variant={per === 'perPost' ? 'contained' : 'outlined'}
                                        onClick={() => setPer('perPost')}
                                    >
                                        <Trans i18nKey="views.statistics.perPost" />
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2} justifyContent={'center'}>
                                    <Grid item>
                                        <KeyboardDatePicker
                                            label={t('form.startDate')}
                                            value={startDate}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            margin="normal"
                                            minDateMessage={t('form.minDateError')}
                                            cancelLabel={t('actions.cancel')}
                                            onChange={(date) => {
                                                if (date) {
                                                    updateStartDate(date.toDate())
                                                }
                                            }}
                                            maxDate={endDate}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <KeyboardDatePicker
                                            label={t('form.endDate')}
                                            value={endDate}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            margin="normal"
                                            minDateMessage={t('form.minDateError')}
                                            cancelLabel={t('actions.cancel')}
                                            onChange={(date) => {
                                                if (date) {
                                                    updateEndDate(date.toDate())
                                                }
                                            }}
                                            minDate={startDate}
                                            maxDate={new Date()}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} className={classes.container}>
                    {view === 'menu' && (
                        <StatisticView
                            statistics={statistics}
                            statisticType="menu"
                            type={type}
                            per={per}
                            socialMedias={socialMedias}
                            startDate={startDate}
                            endDate={endDate}
                            setSocialMedias={setSocialMedias}
                            isFacebookLinked={isFacebookLinked}
                            isInstagramLinked={isInstagramLinked}
                            isGoogleLinked={isGoogleLinked}
                            isTwitterLinked={isTwitterLinked}
                        />
                    )}
                    {view === 'news' && (
                        <StatisticView
                            statistics={newsStatistics}
                            statisticType="news"
                            type={type}
                            per={per}
                            socialMedias={socialMedias}
                            startDate={startDate}
                            endDate={endDate}
                            setSocialMedias={setSocialMedias}
                            isFacebookLinked={isFacebookLinked}
                            isInstagramLinked={isInstagramLinked}
                            isGoogleLinked={isGoogleLinked}
                            isTwitterLinked={isTwitterLinked}
                        />
                    )}
                    {loading && <Loader classes={{ main: classes.overlay }} withLogo={false} />}
                </Grid>
                <Grid item xs={12}>
                    <Alert severity="info" className={classes.disclaimer}>
                        <Trans i18nKey="views.statistics.disclaimer" />
                    </Alert>
                </Grid>
            </Grid>
        </AuthenticateContent>
    )
}

export default memo(StatisticsView)
