import React from 'react'
import { Establishment, MessagingConversation, MessagingMessage } from '@sugg-gestion/ubidreams-react-suggpro'
import i18next from 'i18next'
import { useStyles } from '../styles.css'
import CommentThread from './commentThread'

interface Props {
    establishment: Establishment
    conversation: MessagingConversation
    comments?: MessagingMessage[]
    onDeleteMessage: (messageId: string) => void
    onHideMessage: (messageId: string) => void
    onBlockUser: (messageId: string) => void
}

const CommentContainer: React.FC<Props> = ({
    establishment,
    conversation,
    comments,
    onDeleteMessage,
    onHideMessage,
    onBlockUser,
}) => {
    const classes = useStyles()

    return (
        <>
            <h2 className={classes.commentContainerTitle}>
                {i18next.t('views.messaging.commentContainerTitle')}
            </h2>
            <div className={classes.commentContainer}>
                {comments && comments.length > 0 ? (
                    <CommentThread
                        establishment={establishment}
                        conversation={conversation}
                        listComments={comments}
                        onDeleteMessage={onDeleteMessage}
                        onHideMessage={onHideMessage}
                        onBlockUser={onBlockUser}
                    />
                ) : (
                    <>
                        {conversation.typePlatform === 'twitter' ? (
                            <p className={classes.commentsEmpty}>
                                {i18next.t('views.messaging.noRecentComment')}
                            </p>
                        ) : (
                            <p className={classes.commentsEmpty}>
                                {i18next.t('views.messaging.commentsEmpty')}
                            </p>
                        )}
                    </>
                )}
            </div>
        </>
    )
}

export default CommentContainer
