import React from 'react'
import withSignUpLayout, { WithSignUpLayout } from 'containers/hoc/withSignUpLayout'
import { useDispatch } from 'react-redux'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import { useEstablishmentTypes, useEstablishmentUpdate } from '@sugg-gestion/ubidreams-react-suggpro'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { redirectToEstablishment } from 'core/store/app/actions'
import EstablishmentDetailsView from 'components/views/establishment/establishmentDetailsView'
import { DetailsFormState } from 'components/forms/establishment/detailsForm'
import _ from 'lodash'
import { CountryCode } from 'libphonenumber-js/types'

interface Props extends WithSignUpLayout {
    step?: number
    steps?: number
}

const EstablishmentAddDetails: React.FC<Props> = ({ establishment, step = 3, steps = 8 }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const { displayError } = useApiErrors()
    const { updateEstablishment, inProgress } = useEstablishmentUpdate()
    const { updateEstablishment: updateToPreviousStatus, inProgress: updateToPreviousStatusInProgress } =
        useEstablishmentUpdate()
    const { establishmentTypes } = useEstablishmentTypes()

    const handleUpdateSocialNetworks = (values: DetailsFormState) => {
        updateEstablishment({
            id: establishment.id,
            establishment: {
                ...values,
                remplisVertComment: values.remplisVert ? values.remplisVertComment : '',
                shortDescription: values.shortDescription ?? null,
                phoneNumber: values.phone ?? null,
                email: values.email ?? null,
                DUNS: values.duns ?? null,
                type: values.type ? parseInt(values.type) : null,
            },
            nextStatus: 'FOOD',
        })
            .then(() => {
                enqueueSnackbar(t('views.establishment.socialNetworks.success'), {
                    variant: 'success',
                })
                dispatch(redirectToEstablishment())
            })
            .catch((error) => displayError(error))
    }

    const handlePrevious = () => {
        updateToPreviousStatus({
            id: establishment.id,
            establishment: {},
            nextStatus: 'PAID',
        })
            .then(() => {
                dispatch(redirectToEstablishment())
            })
            .catch((error) => displayError(error))
    }

    const typeMandatory =
        _.defaultTo(establishment.facebookPageId, undefined) === undefined &&
        _.defaultTo(establishment.googleId, undefined) === undefined

    let type = ''
    if (establishment.type) {
        if (typeof establishment.type === 'number') {
            type = establishment.type.toString()
        } else {
            type = establishment.type.id.toString()
        }
    }
    return (
        <EstablishmentDetailsView
            step={step}
            steps={steps}
            onSubmit={handleUpdateSocialNetworks}
            inProgress={inProgress}
            initialValues={{
                name: '',
                duns: establishment.DUNS ?? '',
                email: establishment.email ?? undefined,
                phone: establishment.phoneNumber ?? undefined,
                type: type,
                shortDescription: establishment.shortDescription ?? '',
                description: establishment.description ?? '',
                remplisVert: establishment.remplisVert,
                remplisVertComment: establishment.remplisVertComment ?? undefined,
            }}
            establishmentTypes={establishmentTypes}
            typeMandatory={typeMandatory}
            previousInProgress={updateToPreviousStatusInProgress}
            onPrevious={handlePrevious}
            country={establishment.completeAddress.country as CountryCode}
            type={establishment.completeAddress.country === 'FR' ? 'duns' : 'europeanVat'}
        />
    )
}

export default withSignUpLayout(EstablishmentAddDetails)
