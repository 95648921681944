import React from 'react'
import Button, { ButtonClassKey, ButtonProps } from '@material-ui/core/Button/Button'
import { CircularProgress, StandardProps } from '@material-ui/core'

export interface SubmitButtonProps extends StandardProps<ButtonProps, ButtonClassKey> {
    loading?: boolean
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
    loading = false,
    disabled,
    variant,
    children,
    ...rest
}) => {
    return (
        <Button
            variant={variant}
            startIcon={
                loading ? <CircularProgress style={{ color: 'rgba(0, 0, 0, 0.26)' }} size={20} /> : undefined
            }
            disabled={disabled || loading}
            {...rest}
        >
            <span>{children}</span>
        </Button>
    )
}

export default SubmitButton
