import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            paddingBottom: theme.spacing(4),
        },
        other: {
            padding: theme.spacing(4, 0),
        },
        new: {
            marginLeft: theme.spacing(1),
            position: 'relative',
            top: -8,
        },
        underline: {
            textDecoration: 'underline',
        },
        strong: {
            fontWeight: theme.fontsWeight.semiBold,
        },
        link: {
            flexGrow: 1,
        },
        linkUrl: {
            width: '100%',
            padding: theme.spacing(1, 0),
        },
        qrCode: {
            width: 'calc( 100% - 220px )',
            [theme.breakpoints.down('xs')]: {
                width: 'initial',
            },
        },
        qrCodeDressed: {
            marginRight: theme.spacing(2),
        },
        size: {
            fontSize: '0.7rem',
        },
        linkMessage: {
            padding: theme.spacing(2, 0),
        },
        qrCodeImage: {
            width: 120,
            height: 120,
        },
        qrCodeContainer: {
            'display': 'inline-flex',
            'flexDirection': 'column',
            'justifyContent': 'center',
            'alignItems': 'center',
            '& > .MuiButton-root': {
                margin: theme.spacing(0, 0, 2, 0),
            },
        },
        qrCodeMessage: {
            padding: theme.spacing(2, 0),
        },
        buttonFloat: {
            float: 'right',
        },
        actions: {
            'margin': -theme.spacing(1),
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        optionLabel: {
            'display': 'inline-flex',
            'alignItems': 'center',
            '& > div': {
                marginLeft: theme.spacing(),
            },
        },
        access: {
            'display': 'inline-flex',
            '& > span': {
                marginLeft: theme.spacing(2),
            },
        },
        moduleRow: {
            display: 'flex',
            alignItems: 'center',
            minHeight: 88,
        },
        moduleForm: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-start',
        },
        moduleFormContainer: {
            marginLeft: 0,
            paddingLeft: 0,
            paddingRight: 0,
        },
        moduleLoader: {
            width: 24,
            marginLeft: 8,
        },
        mainPicture: {
            visibility: 'hidden',
            textAlign: 'center',
            alignContent: 'center',
            color: theme.palette.primary.contrastText,
            fontSize: '0.9rem',
            fontWeight: theme.fontsWeight.semiBold,
            backgroundColor: theme.palette.primary.main,
            padding: theme.spacing(0.5, 0),
        },
        mainPictureVisible: {
            visibility: 'visible',
        },
        mainPictureContainer: {
            borderWidth: 3,
            borderStyle: 'solid',
            borderColor: 'transparent',
        },
        mainPictureContainerVisible: {
            borderWidth: 3,
            borderStyle: 'solid',
            borderColor: theme.palette.primary.main,
            borderRadius: 4,
        },
        root: {
            margin: 0,
        },
        action: {
            width: 160,
            minHeight: 0,
            maxWidth: '100%',
            [theme.breakpoints.down('xs')]: {
                width: 120,
            },
        },
        photosTitle: {
            display: 'inline-flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        lockIcon: {
            color: theme.palette.text.secondary,
        },
    }),
)
