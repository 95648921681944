import React, { useEffect } from 'react'
import withAuthenticateLayout, { WithAuthenticateLayout } from '../hoc/withAuthenticateLayout'
import MenuView from 'components/views/menu/menuView'
import AuthenticatedLoading from 'components/layouts/components/authenticatedLoading'
import { useTranslation } from 'react-i18next'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import withSwiper from '../hoc/withSwiper'
import EmptyMenuView from 'components/views/menu/emptyView'
import { useEstablishmentMenuEntries } from '@sugg-gestion/ubidreams-react-suggpro'

interface Props extends WithAuthenticateLayout {}

const Menu: React.FC<Props> = ({ establishment }) => {
    const { t } = useTranslation()

    const { displayError } = useApiErrors()
    const { isReady, establishmentMenuEntries, error } = useEstablishmentMenuEntries(establishment.id)

    useEffect(() => {
        if (error !== undefined) {
            displayError(error)
        }
    }, [error, displayError])

    if (!isReady) {
        return <AuthenticatedLoading title={t('views.menu.title')} loadingMessage={t('common.loading')} />
    }

    if (establishmentMenuEntries.length > 0) {
        return <MenuView establishment={establishment} menuEntries={establishmentMenuEntries} />
    } else {
        return <EmptyMenuView establishment={establishment} />
    }
}

export default withAuthenticateLayout(withSwiper(Menu))
