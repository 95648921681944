import {
    Button,
    CardContent,
    CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    Link,
    Switch,
    Typography,
} from '@material-ui/core'
import HiddenJs from '@material-ui/core/Hidden/HiddenJs'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { Alert } from '@material-ui/lab'
import { Establishment } from '@sugg-gestion/ubidreams-react-suggpro'
import clsx from 'clsx'
import FlatCard from 'components/common/flatCard'
import ImageCard from 'components/common/imageCard'
import InlineEmailForm, { InlineEmailFormState } from 'components/forms/establishment/inlineEmailForm'
import AuthenticateContent from 'components/layouts/components/authenticateContent'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { WebsiteCustomizationFormState } from '../../forms/establishment/websiteCustomizationForm'
import PadLockIcon from '../../icons/padLockIcon'
import UpArrowCircleIcon from '../../icons/upArrowCircleIcon'
import { useStyles } from './styles.css'
import { WebsiteCustomization } from './websiteCustomization'

interface Props {
    establishment: Establishment
    menuLink: string
    slateLink: string
    qrCodeLink: string
    qrCodeDressedLink: string
    copyLink: (link: string) => void
    bookingModuleAccess: boolean
    bookingModule: boolean
    bookingEmail: string
    bookingEmailChangeInProgress: boolean
    bookingModuleChangeInProgress: boolean
    onChangeBookingModule: (bookingModule: boolean) => void
    onChangeBookingEmail: (values: InlineEmailFormState) => void
    orderModuleAccess: boolean
    orderModule: boolean
    orderEmail: string
    orderEmailChangeInProgress: boolean
    orderModuleChangeInProgress: boolean
    onChangeOrderModule: (orderModule: boolean) => void
    onChangeOrderEmail: (values: InlineEmailFormState) => void
    enableOrderModule: boolean
    onEditPhotos: () => void
    onUpdateWebsiteCustomization: (values: WebsiteCustomizationFormState) => void
    updateWebsiteCustomizationInProgress: boolean
    editCustomizationModalOpen: boolean
    onEditCustomization: () => void
    onCloseCustomization: () => void
}

const ExternalLinksView: React.FC<Props> = ({
    establishment,
    menuLink,
    slateLink,
    copyLink,
    qrCodeLink,
    qrCodeDressedLink,
    bookingModule,
    bookingModuleChangeInProgress,
    onChangeBookingModule,
    orderModule,
    orderModuleChangeInProgress,
    onChangeOrderModule,
    enableOrderModule,
    bookingModuleAccess,
    orderModuleAccess,
    bookingEmail,
    onChangeBookingEmail,
    orderEmail,
    onChangeOrderEmail,
    bookingEmailChangeInProgress,
    orderEmailChangeInProgress,
    onEditPhotos,
    onUpdateWebsiteCustomization,
    updateWebsiteCustomizationInProgress,
    editCustomizationModalOpen,
    onEditCustomization,
    onCloseCustomization,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <AuthenticateContent withAction={false}>
            <Typography variant="h1" color="textSecondary" className={classes.title}>
                <Trans i18nKey="views.externalLinks.title" />
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>
                        <Trans i18nKey="views.externalLinks.description" />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FlatCard>
                        <CardContent>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Typography>
                                        <Trans i18nKey="views.externalLinks.link.link" />
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.link}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item className={classes.link}>
                                            <Typography className={classes.linkUrl}>{menuLink}</Typography>
                                        </Grid>
                                        <Grid item className={classes.actions}>
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                onClick={() => copyLink(menuLink)}
                                            >
                                                <Trans i18nKey="actions.copyLink" />
                                            </Button>
                                            <HiddenJs smUp>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    href={menuLink}
                                                    target="_blank"
                                                    endIcon={<OpenInNewIcon />}
                                                >
                                                    <Trans i18nKey="actions.checkWebsite" />
                                                </Button>
                                            </HiddenJs>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Typography className={clsx(classes.linkMessage, classes.size)}>
                                <HiddenJs xsDown>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        href={menuLink}
                                        target="_blank"
                                        endIcon={<OpenInNewIcon />}
                                        className={classes.buttonFloat}
                                    >
                                        <Trans i18nKey="actions.checkWebsite" />
                                    </Button>
                                </HiddenJs>
                                <Trans
                                    i18nKey="views.externalLinks.link.message"
                                    components={[<span className={classes.strong} />]}
                                />
                            </Typography>
                            <Typography component="ul">
                                <Typography component="li" className={classes.size}>
                                    <Trans
                                        i18nKey="views.externalLinks.link.platforms.gmb"
                                        components={[
                                            <Link
                                                color="primary"
                                                href="https://youtu.be/j_KP3ij_COI"
                                                target="_blank"
                                            />,
                                        ]}
                                    />
                                </Typography>
                                <Typography component="li" className={classes.size}>
                                    <Trans
                                        i18nKey="views.externalLinks.link.platforms.tripAdvisor"
                                        components={[
                                            <Link
                                                color="primary"
                                                href="https://youtu.be/fuQuONpYmDE"
                                                target="_blank"
                                            />,
                                        ]}
                                    />
                                </Typography>
                                <Typography component="li" className={classes.size}>
                                    <Trans i18nKey="views.externalLinks.link.platforms.website" />
                                </Typography>
                                <Typography component="li" className={classes.size}>
                                    <Trans i18nKey="views.externalLinks.link.platforms.others" />
                                </Typography>
                            </Typography>
                        </CardContent>
                    </FlatCard>
                </Grid>
                <Grid item xs={12}>
                    <FlatCard>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Container disableGutters maxWidth="sm" className={classes.root}>
                                        <Typography className={classes.photosTitle}>
                                            <Trans i18nKey="views.establishment.photos.main" />
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.action}
                                                onClick={onEditPhotos}
                                            >
                                                <Trans i18nKey="actions.edit" />
                                            </Button>
                                        </Typography>
                                    </Container>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item>
                                            <Alert severity="info">
                                                <Trans
                                                    i18nKey="views.establishment.photos.info"
                                                    components={[<strong />]}
                                                />
                                            </Alert>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {establishment.pictures?.map((photo, index) => (
                                    <Grid item key={index} lg={2} sm={3} xs={4}>
                                        <Grid
                                            container
                                            className={clsx(classes.mainPictureContainer, {
                                                [classes.mainPictureContainerVisible]: photo.isMainPicture,
                                            })}
                                        >
                                            <Grid item xs={12}>
                                                <Typography
                                                    className={clsx(classes.mainPicture, {
                                                        [classes.mainPictureVisible]: photo.isMainPicture,
                                                    })}
                                                >
                                                    <Trans i18nKey="views.establishment.photos.main" />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ImageCard url={photo.url} index={index} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                                {(establishment.pictures === undefined ||
                                    establishment.pictures.length === 0) && (
                                    <Grid item xs={12}>
                                        <Typography>
                                            <Trans i18nKey="views.establishment.photos.empty" />
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </FlatCard>
                </Grid>
                <Grid item xs={12}>
                    <FlatCard>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography>
                                        <Trans i18nKey="views.externalLinks.options.message" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item className={classes.moduleRow}>
                                            <FormControl margin="normal">
                                                <FormControlLabel
                                                    classes={{
                                                        label: classes.optionLabel,
                                                    }}
                                                    disabled={
                                                        !bookingModuleAccess ||
                                                        bookingModuleChangeInProgress ||
                                                        orderModuleChangeInProgress ||
                                                        bookingEmailChangeInProgress ||
                                                        orderEmailChangeInProgress
                                                    }
                                                    control={
                                                        <Switch
                                                            checked={bookingModule}
                                                            onChange={(_, checked) =>
                                                                onChangeBookingModule(checked)
                                                            }
                                                            color="primary"
                                                        />
                                                    }
                                                    label={
                                                        <>
                                                            <Trans i18nKey="views.externalLinks.bookingModule.title" />
                                                            <span className={classes.moduleLoader}>
                                                                {bookingModuleChangeInProgress && (
                                                                    <CircularProgress
                                                                        style={{
                                                                            color: 'rgba(0, 0, 0, 0.26)',
                                                                        }}
                                                                        size={20}
                                                                    />
                                                                )}
                                                            </span>
                                                        </>
                                                    }
                                                />
                                            </FormControl>
                                            {!bookingModuleAccess && (
                                                <Typography
                                                    className={classes.access}
                                                    style={{
                                                        display: 'inline-flex',
                                                    }}
                                                >
                                                    <PadLockIcon
                                                        fontSize="small"
                                                        color="inherit"
                                                        className={clsx(classes.lockIcon)}
                                                    />
                                                    <UpArrowCircleIcon fontSize="small" color="primary" />
                                                    <span>
                                                        <Trans
                                                            i18nKey="views.externalLinks.bookingModule.noAccess"
                                                            components={[
                                                                <Link href="mailto:hello@suggpro.com" />,
                                                            ]}
                                                        />
                                                    </span>
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item className={classes.moduleForm}>
                                            {bookingModule && (
                                                <Container
                                                    className={classes.moduleFormContainer}
                                                    maxWidth="sm"
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <InlineEmailForm
                                                                initialValues={{ email: bookingEmail }}
                                                                inProgress={bookingEmailChangeInProgress}
                                                                disabled={
                                                                    bookingModuleChangeInProgress ||
                                                                    orderModuleChangeInProgress ||
                                                                    bookingEmailChangeInProgress ||
                                                                    orderEmailChangeInProgress
                                                                }
                                                                onSubmit={onChangeBookingEmail}
                                                                label={t('form.bookingEmail')}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Container>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {enableOrderModule && (
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item className={classes.moduleRow}>
                                                <FormControlLabel
                                                    classes={{
                                                        label: classes.optionLabel,
                                                    }}
                                                    disabled={
                                                        !orderModuleAccess ||
                                                        bookingModuleChangeInProgress ||
                                                        orderModuleChangeInProgress ||
                                                        bookingEmailChangeInProgress ||
                                                        orderEmailChangeInProgress
                                                    }
                                                    control={
                                                        <Switch
                                                            checked={orderModule}
                                                            onChange={(_, checked) =>
                                                                onChangeOrderModule(checked)
                                                            }
                                                            color="primary"
                                                        />
                                                    }
                                                    label={
                                                        <>
                                                            <Trans i18nKey="views.externalLinks.orderModule.title" />
                                                            <span className={classes.moduleLoader}>
                                                                {orderModuleChangeInProgress && (
                                                                    <CircularProgress
                                                                        style={{
                                                                            color: 'rgba(0, 0, 0, 0.26)',
                                                                        }}
                                                                        size={20}
                                                                    />
                                                                )}
                                                            </span>
                                                        </>
                                                    }
                                                />
                                                {!orderModuleAccess && (
                                                    <Typography
                                                        className={classes.access}
                                                        style={{
                                                            display: 'inline-flex',
                                                        }}
                                                    >
                                                        <PadLockIcon
                                                            fontSize="small"
                                                            color="inherit"
                                                            className={clsx(classes.lockIcon)}
                                                        />
                                                        <UpArrowCircleIcon fontSize="small" color="primary" />
                                                        <span>
                                                            <Trans
                                                                i18nKey="views.externalLinks.orderModule.noAccess"
                                                                components={[
                                                                    <Link href="mailto:hello@suggpro.com" />,
                                                                ]}
                                                            />
                                                        </span>
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item className={classes.moduleForm}>
                                                {orderModule && (
                                                    <Container
                                                        maxWidth="sm"
                                                        className={classes.moduleFormContainer}
                                                    >
                                                        <InlineEmailForm
                                                            initialValues={{ email: orderEmail }}
                                                            inProgress={orderEmailChangeInProgress}
                                                            disabled={
                                                                bookingModuleChangeInProgress ||
                                                                orderModuleChangeInProgress ||
                                                                bookingEmailChangeInProgress ||
                                                                orderEmailChangeInProgress
                                                            }
                                                            onSubmit={onChangeOrderEmail}
                                                            label={t('form.orderEmail')}
                                                        />
                                                    </Container>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </FlatCard>
                </Grid>
                <Grid item xs={12}>
                    <FlatCard>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item className={classes.qrCodeContainer}>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        href={qrCodeLink}
                                        target="_blank"
                                    >
                                        <Trans i18nKey="actions.qrCode" />
                                    </Button>
                                    <Link
                                        href={qrCodeLink}
                                        target="_blank"
                                        title={t('actions.qrCode')}
                                        className={classes.qrCodeImage}
                                    >
                                        <img
                                            src={qrCodeLink}
                                            alt={t('dialog.qrCode.title')}
                                            className={classes.qrCodeImage}
                                        />
                                    </Link>
                                </Grid>
                                <Grid item className={classes.qrCode}>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        href={qrCodeDressedLink}
                                        target="_blank"
                                        className={classes.qrCodeDressed}
                                    >
                                        <Trans i18nKey="dialog.qrCode.openDressed" />
                                    </Button>
                                    <Typography className={clsx(classes.qrCodeMessage, classes.size)}>
                                        <Trans
                                            i18nKey="views.externalLinks.qrCode.message"
                                            components={[<span className={classes.strong} />]}
                                        />
                                    </Typography>
                                    <Typography component="ul">
                                        <Typography component="li" className={classes.size}>
                                            <Trans i18nKey="views.externalLinks.qrCode.platforms.tables" />
                                        </Typography>
                                        <Typography component="li" className={classes.size}>
                                            <Trans i18nKey="views.externalLinks.qrCode.platforms.flyers" />
                                        </Typography>
                                        <Typography component="li" className={classes.size}>
                                            <Trans
                                                i18nKey="views.externalLinks.qrCode.platforms.pub"
                                                components={[
                                                    <Link
                                                        color="primary"
                                                        href="https://shop.spreadshirt.fr/onvaauresto/"
                                                        target="_blank"
                                                    />,
                                                ]}
                                            />
                                        </Typography>
                                        <Typography component="li" className={classes.size}>
                                            <Trans
                                                i18nKey="views.externalLinks.qrCode.platforms.teeShirt"
                                                components={[
                                                    <Link
                                                        color="primary"
                                                        href="https://shop.spreadshirt.fr/onvaauresto/"
                                                        target="_blank"
                                                    />,
                                                ]}
                                            />
                                        </Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </FlatCard>
                </Grid>
                <Grid item xs={12}>
                    <WebsiteCustomization
                        establishment={establishment}
                        onSubmit={onUpdateWebsiteCustomization}
                        inProgress={updateWebsiteCustomizationInProgress}
                        editModalOpen={editCustomizationModalOpen}
                        handleEditCustomization={onEditCustomization}
                        handleModalClose={onCloseCustomization}
                    />
                </Grid>
            </Grid>
            <Typography variant="h1" color="textSecondary" className={classes.other}>
                <Trans i18nKey="views.externalLinks.other" />
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FlatCard>
                        <CardContent>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Typography>
                                        <Trans i18nKey="views.externalLinks.slate.link" />
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.link}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item className={classes.link}>
                                            <Typography className={classes.linkUrl}>{slateLink}</Typography>
                                        </Grid>
                                        <Grid item className={classes.actions}>
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                onClick={() => copyLink(slateLink)}
                                            >
                                                <Trans i18nKey="actions.copyLink" />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Typography className={clsx(classes.linkMessage, classes.size)}>
                                <Trans i18nKey="views.externalLinks.slate.message" />
                            </Typography>
                        </CardContent>
                    </FlatCard>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        color="primary"
                        variant="outlined"
                        href="https://www.onvaauresto.fr/"
                        target="_blank"
                        endIcon={<OpenInNewIcon />}
                    >
                        <Trans i18nKey="actions.checkWebsiteOVAR" />
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        color="primary"
                        variant="outlined"
                        href="https://bit.ly/MonResto"
                        target="_blank"
                        endIcon={<OpenInNewIcon />}
                    >
                        <Trans i18nKey="actions.checkApp" />
                    </Button>
                </Grid>
            </Grid>
        </AuthenticateContent>
    )
}

export default ExternalLinksView
