import { GraphApiProvider } from '@sugg-gestion/suggpro-graph-module'
import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../core/store/reducers'

export interface WithGraphApiProps {
    isGraphApiAuthenticated: boolean
}

const withGraphApi = <T extends WithGraphApiProps>(ComposedComponent: ComponentType<T>) => {
    const WithGraphApi: React.FC<T> = ({ ...props }) => {
        const { access } = useSelector(({ suggpro: { auth } }: ApplicationState) => ({
            access: auth.access,
        }))

        return (
            <GraphApiProvider
                apiEndpoint={process.env.REACT_APP_GRAPH_API_URI ?? ''}
                apiKey={process.env.REACT_APP_GRAPH_API_KEY ?? ''}
                apiRegion={'eu-west-1'}
                authenticateEndpoint={process.env.REACT_APP_OAUTH_API_URI ?? ''}
                clientId={process.env.REACT_APP_OAUTH_CLIENT_ID ?? ''}
                clientSecret={process.env.REACT_APP_OAUTH_CLIENT_SECRET ?? ''}
                accessToken={access}
            >
                {(isAuthenticated) => (
                    <ComposedComponent {...props} isGraphApiAuthenticated={isAuthenticated} />
                )}
            </GraphApiProvider>
        )
    }
    return WithGraphApi
}

export default withGraphApi
