import { useEstablishmentRetrieveStripeCheckout, useRedeemCode } from '@sugg-gestion/ubidreams-react-suggpro'
import { PaymentCodeFormState } from 'components/forms/establishment/paymentCodeForm'
import SignUpLoading from 'components/views/establishment/components/signUpLoading'
import EstablishmentPaymentView from 'components/views/establishment/establishmentPaymentView'
import withSignUpLayout, { WithSignUpLayout } from 'containers/hoc/withSignUpLayout'
import { Pricing } from 'core/services/contentful/ressources/types'
import { usePricing } from 'core/services/contentful/usePricing'
import { PageType, useApiErrors } from 'core/services/suggPro/useApiErrors'
import { redirectToEstablishment } from 'core/store/app/actions'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import EstablishmentEditInvoiceAddressDialog from './dialogs/establishmentEditInvoiceAddressDialog'

interface Props extends WithSignUpLayout {
    step?: number
    steps?: number
}

const EstablishmentPayment: React.FC<Props> = ({ establishment, step = 1, steps = 8, restaurantOwner }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const { displayError } = useApiErrors()
    const { establishmentRetrieveStripeCheckout } = useEstablishmentRetrieveStripeCheckout()
    const { redeemCode } = useRedeemCode()

    const { pricingCollection, promotionCodeCollection } = usePricing()

    const [inProgress, setInProgress] = useState<string>()
    const [redeemCodeInProgress, setRedeemCodeInProgress] = useState(false)
    const [open, setOpen] = useState<boolean>(false)

    if (pricingCollection === null || establishment === undefined) {
        return (
            <SignUpLoading
                step={step}
                steps={steps}
                loadingMessage={t('common.loading')}
                title={t('views.payment.title')}
            />
        )
    }

    const handleChoosePricing = (pricing: Pricing) => {
        setInProgress(pricing.priceId)
        return establishmentRetrieveStripeCheckout({
            id: establishment.id,
            price: pricing.priceId,
            coupon: pricing.couponCode ?? undefined,
        })
            .then((url) => {
                document.location.href = url
            })
            .catch((error) => {
                displayError(error)
                setInProgress(undefined)
            })
    }

    const handleRedeemCode = (values: PaymentCodeFormState) => {
        setRedeemCodeInProgress(true)
        if (promotionCodeCollection) {
            for (let i = 0; i < (promotionCodeCollection.items.length ?? 0); i++) {
                const promotionCode = promotionCodeCollection.items[i]
                if (values.code.toLowerCase() === promotionCode.code.toLowerCase()) {
                    return handleChoosePricing({
                        type: 'year',
                        title: '',
                        priceByMonth: 0,
                        priceId: promotionCode.priceId,
                        couponCode: promotionCode.couponCode,
                        advantages: null,
                        backgroundColor: null,
                        initialPriceByMonth: null,
                        subtitle: null,
                        lottieAnimation: null,
                        trialPeriod: null,
                        image: null,
                    }).finally(() => {
                        setRedeemCodeInProgress(false)
                    })
                }
            }
        }
        redeemCode({
            id: establishment.id,
            code: values.code,
        })
            .then(() => {
                enqueueSnackbar(t('views.payment.code.success'), {
                    variant: 'success',
                })

                dispatch(redirectToEstablishment())
            })
            .catch((error) => {
                displayError(error, PageType.REDEEM_CODE)
            })
            .finally(() => {
                setRedeemCodeInProgress(false)
            })
    }

    const handleEditInvoiceAddress = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <EstablishmentPaymentView
                step={step}
                steps={steps}
                pricingCollection={pricingCollection.items}
                onChoosePricing={handleChoosePricing}
                establishment={establishment}
                inProgress={inProgress}
                onSubmitPaymentCode={handleRedeemCode}
                paymentCodeInProgress={redeemCodeInProgress}
                restaurantOwner={restaurantOwner}
                onEditInvoiceAddress={handleEditInvoiceAddress}
            />
            <EstablishmentEditInvoiceAddressDialog
                establishment={establishment}
                restaurantOwner={restaurantOwner}
                open={open}
                onClose={handleClose}
            />
        </>
    )
}

export default withSignUpLayout(EstablishmentPayment, { maxWidth: 'lg' })
