import { Typography } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Link from '@material-ui/core/Link'
import Tooltip from '@material-ui/core/Tooltip'
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip'
import React from 'react'
import { Trans } from 'react-i18next'

interface Props {
    placement?: TooltipProps['placement']
    children: (onClick: () => void) => React.ReactElement
}

const NoAccess: React.FC<Props> = ({ children, placement = 'bottom' }) => {
    const [open, setOpen] = React.useState(false)
    const [timeoutRunning, setTimeoutRunning] = React.useState<ReturnType<typeof setTimeout> | undefined>()

    const handleTooltipClose = () => {
        if (timeoutRunning) {
            clearTimeout(timeoutRunning)
            setTimeoutRunning(undefined)
        }
        setOpen(false)
    }

    const handleTooltipOpen = () => {
        if (timeoutRunning) {
            clearTimeout(timeoutRunning)
            setTimeoutRunning(undefined)
        }
        setOpen(true)
    }
    return (
        <>
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    arrow
                    interactive
                    placement={placement}
                    onPointerLeave={() => {
                        const timeout = setTimeout(handleTooltipClose, 2000)
                        setTimeoutRunning(timeout)
                    }}
                    title={
                        <Typography
                            onPointerEnter={() => {
                                if (timeoutRunning) {
                                    clearTimeout(timeoutRunning)
                                    setTimeoutRunning(undefined)
                                }
                            }}
                            onPointerLeave={() => {
                                const timeout = setTimeout(handleTooltipClose, 2000)
                                setTimeoutRunning(timeout)
                            }}
                        >
                            <Trans
                                i18nKey="common.noAccess"
                                components={[<Link href="mailto:hello@suggpro.com" />]}
                            />
                        </Typography>
                    }
                    leaveDelay={2000}
                    children={children(handleTooltipOpen)}
                />
            </ClickAwayListener>
        </>
    )
}

export default NoAccess
