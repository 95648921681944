import React, { useState } from 'react'
import AuthenticateContent from 'components/layouts/components/authenticateContent'
import { Button, CardActions, Chip, Container, FormControl, Grid, Typography } from '@material-ui/core'
import { useStyles } from './styles.css'
import { Trans } from 'react-i18next'
import { Establishment, SlateCategory } from '@sugg-gestion/ubidreams-react-suggpro'
import CommentEditDialog from './setting/commentEditDialog'
import { DefaultCommentFormState } from 'components/forms/slate/defaultCommentForm'
import CategoriesEditDialog from './setting/categoriesEditDialog'
import { DefaultCategoriesFormState } from 'components/forms/slate/defaultCategoriesForm'
import AuthenticateActions from '../../layouts/components/authenticateActions'
import DeleteFlagDialog from './setting/deleteFlagDialog'
import { DefaultDeleteFlagFormState } from '../../forms/slate/defaultDeleteFlagForm'
import { AnyAbility } from '@casl/ability'

interface Props {
    establishment: Establishment
    onUpdateEstablishment: (
        values: DefaultCommentFormState | DefaultCategoriesFormState | DefaultDeleteFlagFormState,
    ) => Promise<boolean>
    inProgress: boolean
    withBack: boolean
    categories?: Array<SlateCategory>
    onGoBack: () => void
    ability: AnyAbility
}

const SettingSlateView: React.FC<Props> = ({
    establishment,
    categories,
    onUpdateEstablishment,
    inProgress,
    withBack,
    onGoBack,
    ability,
}) => {
    const classes = useStyles()

    const [openEditComment, setOpenEditComment] = useState(false)
    const [openEditCategories, setOpenEditCategories] = useState(false)
    const [openDeleteFlag, setOpenDeleteFlag] = useState(false)

    return (
        <>
            <AuthenticateContent withAction={withBack}>
                <Container disableGutters maxWidth="md" className={classes.root}>
                    <Typography variant="h1" className={classes.title}>
                        <Trans i18nKey="views.slateSetting.title" />
                    </Typography>

                    {/*  COMMENT  */}
                    <Typography color="textSecondary" className={classes.formTitle}>
                        <Trans i18nKey="views.slateSetting.defaultComment" />
                    </Typography>
                    <Typography color="textPrimary" className={classes.comment}>
                        {establishment.defaultMenuBoardComment}
                    </Typography>
                    <CardActions className={classes.updateAction}>
                        <Button variant="contained" color="primary" onClick={() => setOpenEditComment(true)}>
                            <Trans i18nKey="actions.edit" />
                        </Button>
                    </CardActions>
                    <CommentEditDialog
                        open={openEditComment}
                        onClose={() => setOpenEditComment(false)}
                        initialValues={{
                            defaultMenuBoardComment: establishment.defaultMenuBoardComment,
                            useDefault: establishment.defaultMenuBoardCommentUseDefault ? [true] : [],
                        }}
                        inProgress={inProgress}
                        onSubmit={(values) => {
                            onUpdateEstablishment(values).then((shouldClose) =>
                                setOpenEditComment(!shouldClose),
                            )
                        }}
                    />

                    {/*  CATEGORIES  */}
                    <Typography color="textSecondary" className={classes.formTitle}>
                        <Trans i18nKey="views.slateSetting.defaultCategories" />
                    </Typography>
                    <Typography className={classes.formSubTitle}>
                        <Trans i18nKey="views.slateSetting.defaultCategoriesMessage" />
                    </Typography>
                    {establishment.defaultMenuBoardCategories ? (
                        <FormControl margin="normal" fullWidth>
                            <Grid container spacing={2}>
                                {establishment.defaultMenuBoardCategories.map((category, index) => (
                                    <Grid item key={index} xs={6} md={6} lg={4}>
                                        <Chip label={category.name} className={classes.categoryChip} />
                                    </Grid>
                                ))}
                            </Grid>
                        </FormControl>
                    ) : (
                        <Typography color="textPrimary" className={classes.comment}>
                            <Trans i18nKey="views.slateSetting.useLastSlate" />
                        </Typography>
                    )}
                    <CardActions className={classes.updateAction}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setOpenEditCategories(true)}
                        >
                            <Trans i18nKey="actions.edit" />
                        </Button>
                    </CardActions>
                    <CategoriesEditDialog
                        open={openEditCategories}
                        onClose={() => setOpenEditCategories(false)}
                        initialValues={{
                            defaultMenuBoardCategories:
                                establishment.defaultMenuBoardCategories?.map((category) => category.id) ??
                                [],
                            defaultMenuBoardCategoriesUseLast:
                                establishment.defaultMenuBoardCategoriesUseLast,
                        }}
                        inProgress={inProgress}
                        onSubmit={(values) => {
                            onUpdateEstablishment(values).then((shouldClose) =>
                                setOpenEditCategories(!shouldClose),
                            )
                        }}
                        categories={categories}
                    />
                    {/*  DELETE FLAG  */}
                    {ability.can('api.auto_delete_access') && (
                        <>
                            <Typography color="textSecondary" className={classes.formTitle}>
                                <Trans i18nKey="views.slateSetting.deleteFlag" />
                            </Typography>
                            <Typography className={classes.formSubTitle}>
                                <Trans i18nKey="views.slateSetting.deleteFlagMessage" />
                            </Typography>
                            <Typography color="textPrimary" className={classes.comment}>
                                <Trans i18nKey="views.slateSetting.selectSocialMedia" />
                            </Typography>

                            <CardActions className={classes.updateAction}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setOpenDeleteFlag(true)}
                                >
                                    <Trans i18nKey="actions.edit" />
                                </Button>
                            </CardActions>
                            <DeleteFlagDialog
                                open={openDeleteFlag}
                                onClose={() => setOpenDeleteFlag(false)}
                                initialValues={{
                                    facebookDeleteFlag: establishment.facebookDeleteFlag,
                                    twitterDeleteFlag: establishment.twitterDeleteFlag,
                                    googlePostDeleteFlag: establishment.googlePostDeleteFlag,
                                    googlePhotoDeleteFlag: establishment.googlePhotoDeleteFlag,
                                }}
                                inProgress={inProgress}
                                onSubmit={(values) => {
                                    onUpdateEstablishment(values).then((shouldClose) =>
                                        setOpenDeleteFlag(!shouldClose),
                                    )
                                }}
                            />
                        </>
                    )}
                </Container>
            </AuthenticateContent>
            {withBack && (
                <AuthenticateActions className={classes.backAction}>
                    <Button variant="contained" onClick={onGoBack}>
                        <Trans i18nKey="actions.previous" />
                    </Button>
                </AuthenticateActions>
            )}
        </>
    )
}

export default SettingSlateView
