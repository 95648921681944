import React from 'react'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import {
    Address,
    Establishment,
    useEstablishmentUpdate,
    User,
    useUpdateUser,
} from '@sugg-gestion/ubidreams-react-suggpro'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import EstablishmentInvoiceAddressDialogView from 'components/views/establishment/dialogs/establishmentInvoiceAddressDialogView'
import { InvoiceAddressFormState } from 'components/forms/establishment/invoiceAddressForm'

interface Props {
    open: boolean
    onClose: () => void
    establishment: Establishment
    restaurantOwner: User
}

const EstablishmentEditInvoiceAddressDialog: React.FC<Props> = ({
    establishment,
    open,
    onClose,
    restaurantOwner,
}) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const { displayError } = useApiErrors()
    const { updateEstablishment, inProgress: updateEstablishmentInProgress } = useEstablishmentUpdate()
    const { updateUser, inProgress: updateUserInProgress } = useUpdateUser()

    const handleUpdateInvoiceAddress = (values: InvoiceAddressFormState) => {
        handleUpdateEstablishment(values)
            .then(() => handleUpdateAccount(values))
            .then(() => {
                enqueueSnackbar(t('views.payment.invoice.success'), {
                    variant: 'success',
                })
                onClose()
            })
            .catch((error) => displayError(error))
    }

    const handleUpdateAccount = (values: InvoiceAddressFormState) => {
        return updateUser({
            ...values,
        })
    }

    const handleUpdateEstablishment = (values: InvoiceAddressFormState) => {
        const completeAddress: Address = {
            street_1: values.address_street_1 ?? null,
            street_2: values.address_street_2 ?? null,
            zip_code: values.address_zip_code ?? null,
            city: values.address_city ?? null,
            country: values.address_country ?? null,
        }
        return updateEstablishment({
            id: establishment.id,
            establishment: {
                ...values,
                name: values.name ?? null,
                DUNS: values.duns ?? null,
                completeAddress,
            },
        })
    }

    return (
        <EstablishmentInvoiceAddressDialogView
            open={open}
            onClose={onClose}
            onSubmit={handleUpdateInvoiceAddress}
            inProgress={updateEstablishmentInProgress || updateUserInProgress}
            initialValues={{
                firstName: restaurantOwner.firstname,
                lastName: restaurantOwner.lastname,
                email: restaurantOwner.email,
                name: establishment.name ?? '',
                duns: establishment.DUNS ?? '',
                address_street_1: establishment.completeAddress.street_1 ?? '',
                address_street_2: establishment.completeAddress.street_2 ?? '',
                address_zip_code: establishment?.completeAddress.zip_code ?? '',
                address_city: establishment?.completeAddress.city ?? '',
                address_country: establishment?.completeAddress.country ?? 'FR',
            }}
        />
    )
}

export default EstablishmentEditInvoiceAddressDialog
