import {
    InitializeConnectToTwitterResponse,
    TwitterInfoResponse,
    useConnectToTwitter,
} from '@sugg-gestion/ubidreams-react-suggpro'
import { SocialWindow } from '../../../types/window'
import { useApiErrors } from '../suggPro/useApiErrors'

declare let window: SocialWindow

export const useFetchTwitterData = () => {
    const { displayError } = useApiErrors()
    const {
        initializeConnectToTwitter,
        initializeConnectToTwitterInProgress,
        getTwitterInfo,
        getTwitterInfoInProgress,
    } = useConnectToTwitter()

    const popupCenter = ({ url, title, w, h }: { url: string; title: string; w: number; h: number }) => {
        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

        const width = window.innerWidth
            ? window.innerWidth
            : document.documentElement.clientWidth
            ? document.documentElement.clientWidth
            : window.screen.width
        const height = window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight
            ? document.documentElement.clientHeight
            : window.screen.height

        const systemZoom = width / window.screen.availWidth
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        const newWindow = window.open(
            url,
            title,
            `
              scrollbars=yes,
              width=${w / systemZoom}, 
              height=${h / systemZoom}, 
              top=${top}, 
              left=${left}
            `,
        )

        if (newWindow && newWindow.focus) {
            newWindow.focus()
        }
        return newWindow
    }

    const authenticateToTwitter = (value: InitializeConnectToTwitterResponse) => {
        return new Promise<string>((resolve, reject) => {
            const url = new URL('https://api.twitter.com/oauth/authenticate')
            url.searchParams.append('oauth_token', value.oauth_token)
            url.searchParams.append('oauth_token_secret', value.oauth_token_secret)
            const signWindow = popupCenter({
                url: url.href,
                title: 'Sign in with Twitter',
                w: 600,
                h: 800,
            })
            if (signWindow) {
                let timer = setInterval(function () {
                    if (signWindow.closed) {
                        clearInterval(timer)
                        reject()
                    }
                }, 1000)
                window.twitterCallback = (url: string) => {
                    clearInterval(timer)
                    resolve(url)
                }
            } else {
                reject()
            }
        }).then((url) => {
            const parseUrl = new URL(url)
            const oauth_token = parseUrl.searchParams.get('oauth_token')
            const oauth_verifier = parseUrl.searchParams.get('oauth_verifier')
            if (oauth_token && oauth_verifier) {
                return getTwitterInfo({
                    twitterOauthToken: oauth_token,
                    twitterOauthVerifier: oauth_verifier,
                }).catch((error) => {
                    displayError(error)
                    throw error
                })
            } else {
                throw Error('Cancel')
            }
        })
    }

    const fetchTwitterData = (): Promise<TwitterInfoResponse> => {
        return initializeConnectToTwitter()
            .catch((error) => {
                displayError(error)
                throw error
            })
            .then((value) => authenticateToTwitter(value))
    }

    return {
        fetchTwitterData,
        inProgress: initializeConnectToTwitterInProgress || getTwitterInfoInProgress,
    }
}
