import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {},
        title: {
            paddingBottom: theme.spacing(2),
        },
        new: {
            marginLeft: theme.spacing(1),
            position: 'relative',
            top: -8,
        },
        titleSoon: {
            marginLeft: theme.spacing(),
        },
        container: {
            position: 'relative',
        },
        soonContainer: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            overflow: 'hidden',
            [theme.breakpoints.down('xs')]: {
                marginBottom: 5,
            },
        },
        soon: {
            fontSize: '3rem',
            transform: 'rotate(-30deg)',
            transformOrigin: '90% 50%',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            marginLeft: -240,
            marginRight: -40,
            textAlign: 'center',
            [theme.breakpoints.down('xs')]: {
                fontSize: '1.4rem',
                marginLeft: -60,
                marginRight: -20,
            },
        },
        socialContainer: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: theme.spacing(-1, 2),
            [theme.breakpoints.down('xs')]: {
                position: 'static',
                flexDirection: 'row',
                margin: theme.spacing(0, 0),
                marginTop: 48 + 8,
            },
        },
        socialItem: {
            margin: theme.spacing(1, 0),
            [theme.breakpoints.down('xs')]: {
                flexGrow: 1,
            },
        },
        iconButton: {
            'padding': theme.spacing(1),
            'minWidth': 0,
            '&.Mui-disabled': {
                backgroundColor: theme.palette.action.hover,
            },
        },
        icon: {
            width: 48,
            height: 48,
            [theme.breakpoints.down('xs')]: {
                width: 36,
                height: 36,
            },
        },
        disclaimer: {
            marginTop: theme.spacing(2),
            [theme.breakpoints.down('xs')]: {
                marginTop: theme.spacing(1),
            },
        },
        alert: {
            marginTop: theme.spacing(),
        },
        messagingContainer: {
            height: '100%',
            marginTop: '8px',
            [theme.breakpoints.down('xs')]: {
                marginTop: 0,
            },
        },
        tab: {
            '&.MuiTab-root': {
                fontSize: '15px',
                minWidth: '141px',
                width: '100px',
            },
        },
        discussionPane: {
            borderLeft: '2px solid grey',
            paddingTop: '0px !important',
            paddingBottom: '0px !important',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        discussThreadContainer: {
            alignContent: 'flex-end',
            flexGrow: 1,
        },
        conversationScrollBox: {
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: '61vh',
            minHeight: '100%',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            paddingRight: 0,
            marginRight: '-11px',
        },
        conversationBox: {
            'width': '100%',
            'cursor': 'pointer',
            '&:hover': {
                background: 'lightgrey',
            },
        },
        isSelected: {
            'background': theme.palette.primary.main,
            '&:hover': {
                background: theme.palette.primary.main,
            },
        },
        isNewMessage: {
            fontWeight: 'bold',
        },
        conversationGrid: {
            borderBottom: '1px solid lightgrey',
            padding: '8px 6px',
        },
        conversationCard: {
            [theme.breakpoints.down('xs')]: {
                marginLeft: '10px',
            },
        },
        conversationPictureContainer: {
            position: 'relative',
        },
        conversationPicture: {
            width: '56px',
            height: '56px',
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
            [theme.breakpoints.down('xs')]: {
                width: '50px',
                height: '50px',
            },
        },
        conversationPlatformIcon: {
            zIndex: 10,
            position: 'absolute',
            bottom: -4,
            left: '56%',
            width: 25,
            height: 25,
            [theme.breakpoints.down('xs')]: {
                width: 25,
                height: 25,
            },
        },
        conversationPostPlatformIcon: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        conversationReceiverName: {
            fontWeight: 600,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        conversationLastMessage: {
            color: 'grey',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        conversationPostTitle: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        conversationNonRead: {
            fontWeight: 'bold',
        },
        conversationOpenInNew: {
            margin: 'auto',
            textAlign: 'center',
        },
        loaderContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 200,
        },
        loaderConversation: {
            position: 'relative',
            display: 'block',
        },
        loaderIndicator: {
            textAlign: 'center',
            fontSize: '18px',
        },
        discussHat: {
            backgroundColor: 'lightgrey',
            borderTopRightRadius: '6px',
            borderTopLeftRadius: '6px',
            padding: '7px',
            zIndex: 1000,
        },
        discussHatOptions: {
            textAlign: 'right',
        },
        discussHatOpenInNew: {
            padding: 0,
            paddingRight: '7px',
        },
        discussTypingBox: {
            backgroundColor: 'lightgrey',
            borderBottomRightRadius: '6px',
            borderBottomLeftRadius: '6px',
            padding: '7px',
        },
        userProfileLink: {
            'color': 'inherit',
            'textDecoration': 'none',
            'fontSize': '16px',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        userProfilePicture: {
            width: '30px',
            height: '30px',
        },
        username: {
            fontSize: 14,
            color: theme.palette.text.primary,
            marginTop: '-3px',
        },
        discussThread: {
            overflowY: 'auto',
            overflowX: 'hidden',
            overscrollBehavior: 'contain',
            scrollSnapAlign: 'end',
            minHeight: '100%',
            maxHeight: '65vh',
            display: 'flex',
            flexDirection: 'column',
            padding: '16px 8px',
            [theme.breakpoints.down('xs')]: {
                maxHeight: 'inherit',
            },
        },
        discussThreadReverse: {
            overflowY: 'auto',
            overflowX: 'hidden',
            overscrollBehavior: 'contain',
            scrollSnapAlign: 'end',
            maxHeight: '62vh',
            minHeight: '100%',
            display: 'flex',
            flexDirection: 'column-reverse',
            padding: '16px 8px',
            [theme.breakpoints.down('xs')]: {
                maxHeight: '85vh',
            },
        },
        discussThreadBGColorWhite: {
            backgroundColor: 'white',
        },
        discussThreadBGColorLightgrey: {
            backgroundColor: '#f0f0f0',
        },
        discussPost: {
            marginRight: 'auto',
            marginLeft: 'auto',
            marginBottom: '16px',
            padding: '12px',
            borderRadius: '6px',
            backgroundColor: 'white',
            boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
        },
        discussPostHat: {
            display: 'flex',
            alignItems: 'flex-start',
        },
        discussPostImg: {
            width: 50,
            height: 50,
            [theme.breakpoints.down('xs')]: {
                width: 35,
                height: 35,
            },
        },
        discussPostInfo: {
            marginLeft: '15px',
        },
        discussPostInfoAuthorName: {
            marginTop: '3px',
            fontSize: '15px',
            fontWeight: 'bold',
        },
        discussPostInfoDate: {
            fontSize: '13px',
            color: 'grey',
        },
        discussPostBody: {
            paddingTop: '8px',
            marginBottom: '8px',
        },
        discussPostBodyText: {
            fontSize: '15px',
        },
        discussPostBodyTextHashtag: {
            fontWeight: 'bold',
        },
        discussPostBodyMedia: {
            'margin': '6px 0',
            '& img': {
                width: '100%',
            },
        },
        postMediaImage: {
            aspectRatio: '1/1',
            objectFit: 'cover',
        },
        discussPostBodyRating: {
            display: 'flex',
            alignItems: 'center',
            margin: '10px 0',
            paddingRight: '15px',
        },
        discussPostFoot: {
            display: 'flex',
            alignItems: 'center',
            borderTop: '1px solid lightgrey',
            padding: '5px 0',
        },
        discussPostMetrics: {
            display: 'flex',
            alignItems: 'center',
            paddingRight: '15px',
        },
        discussPostMetricsCount: {
            paddingLeft: '6px',
        },
        discussTextarea: {
            resize: 'none',
            width: '100%',
            height: '100% !important',
            overflow: 'visible !important',
            fontFamily: 'inherit',
            fontSize: '15px',
            borderRadius: '10px',
            padding: '5px',
        },
        discussSendBtn: {
            marginTop: 'auto',
            marginBottom: 'auto',
            textAlign: 'center',
        },
        sendButtonSquare: {
            minHeight: 'auto',
            minWidth: 'auto',
        },
        replyBox: {
            borderTop: '1px solid lightgrey',
            padding: '6px',
        },
        replyBoxContainer: {
            backgroundColor: 'lightgrey',
            padding: '7px',
        },
        messageSenderYou: {
            marginLeft: '30px',
            marginBottom: '6px',
            textAlign: 'right',
            //border: '2px solid red',
        },
        messageBoxContainerSenderYou: {
            marginRight: '10px',
            marginLeft: 'auto',
        },
        messageBoxInnerSenderYou: {
            display: 'flex',
            flexDirection: 'row-reverse',
        },
        messageBoxSenderYou: {
            padding: '10px',
            backgroundColor: theme.palette.primary.main,
            borderBottomLeftRadius: '18px',
            borderTopLeftRadius: '18px',
            borderTopRightRadius: '18px',
        },
        messageSenderOther: {
            marginRight: '30px',
            marginBottom: '6px',
            textAlign: 'left',
        },
        messageBoxContainerSenderOther: {
            marginLeft: '10px',
            marginRight: 'auto',
        },
        messageBoxInnerSenderOther: {
            display: 'flex',
            flexDirection: 'row',
        },
        messageBoxSenderOther: {
            padding: '10px',
            backgroundColor: '#e4e6eb',
            borderBottomRightRadius: '18px',
            borderBottomLeftRadius: '18px',
            borderTopRightRadius: '18px',
        },
        messageBoxContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
        },
        messageBoxWithMedia: {
            maxWidth: '300px',
            minWidth: '100%',
        },
        messageMediaContainer: {
            width: '100%',
        },
        messageInfo: {
            padding: '4px 2px',
            color: 'grey',
        },
        commentContainer: {
            marginRight: 'auto',
            marginLeft: 'auto',
            marginBottom: '16px',
            padding: '12px',
            borderRadius: '6px',
            backgroundColor: 'white',
            boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
        },
        commentContainerTitle: {
            marginTop: '30px',
        },
        commentsEmpty: {
            textAlign: 'center',
            fontSize: '1.2em',
        },
        commentContainerBox: {
            rowGap: '20px',
            margin: '10px 0',
        },
        commentBox: {
            marginLeft: '10px',
        },
        commentBoxInner: {
            'padding': '10px',
            'backgroundColor': '#e4e6eb',
            'borderBottomRightRadius': '18px',
            'borderBottomLeftRadius': '18px',
            'borderTopRightRadius': '18px',
            '&> p.MuiTypography-root': {
                fontSize: '1em',
            },
        },
        commentInfo: {
            paddingLeft: '10px',
        },
        commentAuthorName: {
            margin: '0',
            fontSize: '1em',
            fontWeight: 'bold',
        },
        commentMedia: {
            maxWidth: '50%',
        },
        commentReplyTextarea: {
            resize: 'none',
            width: '100%',
            height: '100% !important',
            overflow: 'visible !important',
            fontFamily: 'inherit',
            fontSize: '15px',
            borderRadius: '10px',
            padding: '5px',
            backgroundColor: '#e4e6eb',
            border: 'none',
        },
        errorContainer: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        errorIcon: {
            width: theme.spacing(16),
            height: theme.spacing(16),
            [theme.breakpoints.down('xs')]: {
                width: theme.spacing(12),
                height: theme.spacing(12),
            },
        },
        errorMessage: {
            fontWeight: theme.fontsWeight.semiBold,
            margin: theme.spacing(2, 0),
        },
        photoZoom: {
            position: 'absolute',
            bottom: 8,
            right: 8,
            backgroundColor: theme.palette.common.black,
            borderColor: theme.palette.common.white,
            borderWidth: 1,
            borderStyle: 'solid',
            color: theme.palette.common.white,
        },
        photoButton: {
            width: '100%',
            height: '100%',
        },
        messageStatusIndication: {
            fontSize: '1.1rem',
            textAlign: 'center',
            color: theme.palette.grey['600'],
        },
        capitalize: {
            textTransform: 'capitalize',
        },
    })
})
