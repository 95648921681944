import React from 'react'
import { useFormsStyles } from '../forms.css'
import { Form } from 'react-final-form'
import { SubmitButtonProps } from 'components/common/submitButton'
import { Trans, useTranslation } from 'react-i18next'
import { isSiretValid } from 'core/services/duns/siret'
import TextField from '../components/textField'
import CountryField from '../components/countryField'

interface Props {
    subscription?: { [property: string]: boolean }
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    onSubmit: (values: DunsCompleteFormState) => void
    inProgress: boolean
    initialValues: DunsCompleteFormState
    type: 'duns' | 'europeanVat'
}

export interface DunsCompleteFormState {
    duns: string
    name: string
    address_street_1: string
    address_street_2?: string
    address_zip_code: string
    address_city: string
    address_country: string
}

interface DunsCompleteFormError {
    duns?: string
    name?: string
    address_street_1?: string
    address_street_2?: string
    address_zip_code?: string
    address_city?: string
    address_country?: string
}

const DunsCompleteForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
    type,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()

    const dunsRegex = new RegExp('^[0-9]{14}$')
    const validate = (values: DunsCompleteFormState) => {
        const errors: DunsCompleteFormError = {}
        if (type === 'duns') {
            if (!dunsRegex.test(values.duns) || !isSiretValid(values.duns)) {
                errors.duns = t('form-validation.duns')
            }
        }
        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <div>
                            <TextField
                                name="duns"
                                label={t('form.' + type)}
                                type={type === 'duns' ? 'number' : 'text'}
                                InputProps={{
                                    inputProps: {
                                        maxLength: 14,
                                    },
                                }}
                                required
                                disabled={type === 'europeanVat'}
                            />
                            <TextField
                                label={t('form.establishmentName')}
                                name="name"
                                autoComplete="organization"
                                required
                            />
                            <TextField
                                label={t('form.companyAddressStreet1')}
                                name="address_street_1"
                                autoComplete="billing address-line1"
                                required
                            />
                            <TextField
                                label={t('form.companyAddressStreet2')}
                                name="address_street_2"
                                autoComplete="billing address-line2"
                            />
                            <TextField
                                label={t('form.companyAddressZipCode')}
                                name="address_zip_code"
                                autoComplete="billing postal-code"
                                required
                            />
                            <TextField
                                label={t('form.companyAddressCity')}
                                name="address_city"
                                autoComplete="billing address-level2"
                                required
                            />
                            <CountryField name="address_country" />
                        </div>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                className: formsClasses.submit,
                                children: <Trans i18nKey="actions.continue" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}

export default DunsCompleteForm
