// External libs
import React from 'react'
// Theme
import CircularProgress from '@material-ui/core/CircularProgress'
// Style
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Logo from 'components/common/logo'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        logo: {
            padding: theme.spacing(6, 2),
            maxWidth: 'calc(100vw - 48px)',
        },
        indicator: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        text: {
            paddingTop: theme.spacing(2),
        },
    }),
)

interface Props {
    withLogo?: boolean
    text?: string | React.ReactNode
    classes?: { main?: string }
}

const Loader: React.FC<Props> = ({ withLogo = true, text, classes }) => {
    const internalClasses = useStyles()
    return (
        <div className={clsx(internalClasses.main, classes?.main)}>
            {withLogo && (
                <div className={internalClasses.logo}>
                    <Logo />
                </div>
            )}
            <div className={internalClasses.indicator}>
                <CircularProgress size="4rem" />
                {!!text && <Typography className={internalClasses.text}>{text}</Typography>}
            </div>
        </div>
    )
}

export default Loader
