import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
    createStyles({
        version: {
            padding: theme.spacing(2, 0),
            fontSize: '0.8rem',
            color: theme.palette.grey.A200,
            position: 'absolute',
            bottom: 0,
        },
    }),
)
