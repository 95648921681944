import { StorageInterface, SuggProProvider } from '@sugg-gestion/ubidreams-react-suggpro'
import { sha512 } from 'js-sha512'
import React, { ComponentType, useState } from 'react'
import { useStore } from 'react-redux'
import { setInterceptor } from '../../core/services/axios/interceptor'

class WebStorage implements StorageInterface {
    storage: Storage

    constructor(storage: Storage) {
        this.storage = storage
    }

    getItem(key: string): Promise<string | null> {
        return new Promise<string | null>((resolve) => {
            resolve(this.storage.getItem(key))
        })
    }

    setItem(key: string, value: string): Promise<void> {
        return new Promise<void>((resolve) => {
            this.storage.setItem(key, value)
            resolve()
        })
    }

    removeItem(key: string): Promise<void> {
        return new Promise<void>((resolve) => {
            this.storage.removeItem(key)
            resolve()
        })
    }
}

class FakeStorage implements Storage {
    items: { [key: string]: string } = {}
    readonly length: number

    constructor() {
        this.length = 0
    }

    getItem(key: string) {
        if (this.items[key]) {
            return this.items[key]
        }
        return null
    }

    // eslint-disable-next-line no-restricted-globals
    [name: string]: any

    setItem(key: string, value: string) {
        this.items[key] = value
    }

    removeItem(key: string) {
        delete this.items[key]
    }

    clear(): void {
        this.items = {}
    }

    key(): string | null {
        return null
    }
}

let sessionStorage: WebStorage
let persistentStorage: WebStorage
try {
    sessionStorage = new WebStorage(localStorage)
    persistentStorage = new WebStorage(localStorage)
} catch (_) {
    sessionStorage = new WebStorage(new FakeStorage())
    persistentStorage = new WebStorage(new FakeStorage())
}

const sha512Promise = (value: string) => {
    return new Promise<string>((resolve) => {
        resolve(sha512(value))
    })
}

interface OutputProps {
    suggProInit: boolean
}

const withSuggPro = (ComposedComponent: ComponentType<OutputProps>) => {
    const WithSuggPro: React.FC = ({ ...props }) => {
        const store = useStore()
        const [suggProInit, setSuggProInit] = useState(false)

        const { REACT_APP_SUGGPRO_ENDPOINT, REACT_APP_VERSION, REACT_APP_SHA, REACT_APP_WEBSOCKET_URI } =
            process.env
        if (REACT_APP_SUGGPRO_ENDPOINT === undefined) {
            throw new Error('REACT_APP_SUGGPRO_ENDPOINT undefined')
        }
        if (REACT_APP_WEBSOCKET_URI === undefined) {
            throw new Error('REACT_APP_WEBSOCKET_URI undefined')
        }

        return (
            <SuggProProvider
                store={store}
                options={{
                    apiEndpoint: REACT_APP_SUGGPRO_ENDPOINT,
                    store,
                    sessionStorage,
                    persistentStorage,
                    sha512: sha512Promise,
                    versionName: REACT_APP_VERSION ?? '',
                    versionNumber: REACT_APP_SHA ?? '',
                    appAgent: 'suggpro-webapp',
                    websocketUri: REACT_APP_WEBSOCKET_URI,
                }}
                onInit={(suggPro) => {
                    if (!suggProInit) {
                        setSuggProInit(true)
                        setInterceptor(store, suggPro.axiosInstance)
                    }
                }}
            >
                <ComposedComponent suggProInit={suggProInit} {...props} />
            </SuggProProvider>
        )
    }
    return WithSuggPro
}

export default withSuggPro
