import {
    APIError,
    CreateSlateImageRequest,
    Establishment,
    UpdateSlateRequest,
    useCreateSlate,
    useEstablishmentSlate,
    useEstablishmentUpdate,
    useShareSlate,
    useSlateCategories,
} from '@sugg-gestion/ubidreams-react-suggpro'
import { SlateCategory } from '@sugg-gestion/ubidreams-react-suggpro/src/resources/slate/types'
import { AddFormState } from 'components/forms/slate/addForm'
import { DefaultCategoriesFormState } from 'components/forms/slate/defaultCategoriesForm'
import { DefaultCommentFormState } from 'components/forms/slate/defaultCommentForm'
import { DefaultDeleteFlagFormState } from 'components/forms/slate/defaultDeleteFlagForm'
import AddSlateView from 'components/views/slate/addView'
import ExtraSharing from 'components/views/slate/components/extraSharing'
import useRestaurantsApi from 'core/services/restaurant/useRestaurantsApi'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import withAuthenticateLayout, { WithAuthenticateLayout } from '../hoc/withAuthenticateLayout'
import withDnD from '../hoc/withDnD'
import withMuiPickersUtilsProvider from '../hoc/withMuiPickersUtilsProvider'
import { SocialNetwork } from './index'

interface Props extends WithAuthenticateLayout {}

const SlateAdd: React.FC<Props> = ({ establishment, restaurantOwner, ability }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const history = useHistory()

    const [openLoadingDialog, setOpenLoadingDialog] = useState<'no' | 'open' | 'force'>('no')
    const [loadingNetworks, setLoadingNetworks] = useState<Array<SocialNetwork>>()
    const [addError, setAddError] = useState<APIError>()
    const [facebookError, setFacebookError] = useState<APIError>()
    const [googleError, setGoogleError] = useState<APIError>()
    const [instagramError, setInstagramError] = useState<APIError>()
    const [twitterError, setTwitterError] = useState<APIError>()

    const { displayError } = useApiErrors()
    const { createSlate, inProgress } = useCreateSlate()
    const { updateEstablishment, inProgress: updateEstablishmentInProgress } = useEstablishmentUpdate()
    const { categories } = useSlateCategories()
    const { establishmentLastSlate } = useEstablishmentSlate(establishment.id)

    const { menuLink } = useRestaurantsApi(restaurantOwner, establishment)

    const handleFacebookError = (error: APIError) => {
        setOpenLoadingDialog('force')
        setFacebookError(error)
        displayError(error, undefined, false)
    }
    const handleGoogleError = (error: APIError) => {
        setOpenLoadingDialog('force')
        setGoogleError(error)
        displayError(error, undefined, false)
    }
    const handleInstagramError = (error: APIError) => {
        setOpenLoadingDialog('force')
        setInstagramError(error)
        displayError(error, undefined, false)
    }
    const handleTwitterError = (error: APIError) => {
        setOpenLoadingDialog('force')
        setTwitterError(error)
        displayError(error, undefined, false)
    }
    const {
        shareSlate,
        inProgress: shareInProgress,
        facebookInProgress: shareOnFacebookInProgress,
        googleInProgress: shareOnGoogleInProgress,
        instagramInProgress: shareOnInstagramInProgress,
        twitterInProgress: shareOnTwitterInProgress,
    } = useShareSlate(handleFacebookError, handleGoogleError, handleInstagramError, handleTwitterError)

    const returnToSlate = () => {
        history.push('/establishment/:id/slate'.replace(':id', establishment.id.toString()))
    }

    const handleCloseLoadingDialog = () => {
        setOpenLoadingDialog('no')
        if (addError === undefined) {
            returnToSlate()
        }
    }

    const handleCreateSlate = (values: AddFormState) => {
        const slateCategories: Array<SlateCategory> = []
        values.categories.forEach((category) => {
            const slateCategory = categories?.find((slateCategory) => slateCategory.id === category)
            if (slateCategory) {
                slateCategories.push(slateCategory)
            }
        })
        const files: Array<CreateSlateImageRequest> =
            values.photos?.map((photoData) => {
                return {
                    file: photoData.file,
                    comment: photoData.comment,
                    isFacebookShared: photoData.isFacebookShared,
                    isGoogleShared: photoData.isGoogleShared,
                    isInstagramShared: photoData.isInstagramShared,
                    isTwitterShared: photoData.isTwitterShared,
                }
            }) ?? []
        const socialNetworks = []
        if (values.isFacebookShared) {
            const sharedPhotos = values.photos?.filter((photoData) => photoData.isFacebookShared) ?? []
            if (sharedPhotos.length > 0) {
                socialNetworks.push(SocialNetwork.Facebook)
            } else {
                values.isFacebookShared = false
            }
        }
        if (values.isInstagramShared) {
            const sharedPhotos = values.photos?.filter((photoData) => photoData.isInstagramShared) ?? []
            if (sharedPhotos.length > 0) {
                socialNetworks.push(SocialNetwork.Instagram)
            } else {
                values.isInstagramShared = false
            }
        }
        if (values.isTwitterShared) {
            const sharedPhotos = values.photos?.filter((photoData) => photoData.isTwitterShared) ?? []
            if (sharedPhotos.length > 0) {
                socialNetworks.push(SocialNetwork.Twitter)
            } else {
                values.isTwitterShared = false
            }
        }
        if (values.isGoogleShared) {
            const sharedPhotos = values.photos?.filter((photoData) => photoData.isGoogleShared) ?? []
            if (sharedPhotos.length > 0) {
                socialNetworks.push(SocialNetwork.Google)
            } else {
                values.isGoogleShared = false
            }
        }
        setLoadingNetworks(socialNetworks)
        setOpenLoadingDialog('open')
        createSlate({
            establishmentId: establishment.id,
            files: files,
            validityDate: values.validityDate,
            categories: slateCategories,
            comment: values.comment ?? '',
            shouldDelete: values.shouldDelete[0],
        })
            .then((response) => {
                if (response.slate) {
                    const slateId = response.slate.id
                    enqueueSnackbar(t('views.slate.add.success'), {
                        variant: 'success',
                    })
                    let shouldShare = false
                    let parameters: UpdateSlateRequest = {
                        id: slateId,
                    }
                    if (values.isFacebookShared) {
                        parameters = {
                            ...parameters,
                            ...{ isFacebookShared: true, facebookAccessToken: '' },
                        }
                        shouldShare = true
                    }
                    if (values.isInstagramShared) {
                        parameters = {
                            ...parameters,
                            ...{ isInstagramShared: true },
                        }
                        shouldShare = true
                    }
                    if (values.isTwitterShared) {
                        parameters = {
                            ...parameters,
                            ...{ isTwitterShared: true },
                        }
                        shouldShare = true
                    }
                    if (values.isGoogleShared) {
                        parameters = {
                            ...parameters,
                            ...{ isGoogleShared: true, googleAccessToken: '' },
                        }
                        shouldShare = true
                    }
                    if (shouldShare) {
                        return shareSlate(parameters)
                            .then(() => {
                                if (openLoadingDialog !== 'force') {
                                    returnToSlate()
                                }
                            })
                            .catch(() => {})
                    } else {
                        handleCloseLoadingDialog()
                    }
                }
            })
            .catch((error) => {
                setOpenLoadingDialog('force')
                setAddError(error)
                displayError(error, undefined, false)
            })
    }

    const handleGoBack = () => {
        const establishmentBasePath = '/establishment/:id'.replace(':id', establishment.id.toString() ?? '')
        history.push(establishmentBasePath + '/slate')
    }

    const deleteInitialValue = (establishment: Establishment) => {
        return !(
            !establishment.facebookDeleteFlag &&
            !establishment.twitterDeleteFlag &&
            !establishment.googlePostDeleteFlag &&
            !establishment.googlePhotoDeleteFlag
        )
    }

    const handleUpdateEstablishment = (
        values: DefaultCommentFormState | DefaultCategoriesFormState | DefaultDeleteFlagFormState,
    ) => {
        return updateEstablishment({
            id: establishment.id,
            establishment: {
                ...values,
            },
        })
            .then((updatedEstablishment) => {
                enqueueSnackbar(t('views.establishment.socialNetworks.success'), {
                    variant: 'success',
                })
                return { shouldClose: true, shouldDelete: deleteInitialValue(updatedEstablishment) }
            })
            .catch((error) => {
                displayError(error)
                return { shouldClose: false, shouldDelete: false }
            })
    }

    return (
        <AddSlateView
            extraSharing={<ExtraSharing menuLink={menuLink()} />}
            establishment={establishment}
            onSubmit={handleCreateSlate}
            inProgress={
                inProgress ||
                updateEstablishmentInProgress ||
                shareInProgress ||
                shareOnFacebookInProgress ||
                shareOnInstagramInProgress ||
                shareOnTwitterInProgress ||
                shareOnGoogleInProgress
            }
            updateEstablishmentInProgress={updateEstablishmentInProgress}
            createInProgress={inProgress}
            shareOnFacebookInProgress={shareOnFacebookInProgress}
            shareOnInstagramInProgress={shareOnInstagramInProgress}
            shareOnTwitterInProgress={shareOnTwitterInProgress}
            shareOnGoogleInProgress={shareOnGoogleInProgress}
            initialValues={{
                categories: establishment.defaultMenuBoardCategoriesUseLast
                    ? establishmentLastSlate?.categories.map((category) => category.id) ?? []
                    : establishment.defaultMenuBoardCategories?.map((category) => category.id) ?? [],
                validityDate: new Date(),
                isFacebookShared:
                    !_.isEmpty(establishment.facebookPageId) && !restaurantOwner.isFacebookSignedExpired,
                isInstagramShared:
                    !_.isEmpty(establishment.instagramPageId) && !restaurantOwner.isFacebookSignedExpired,
                isGoogleShared: !_.isEmpty(establishment.googleId) && !restaurantOwner.isGoogleSignedExpired,
                isTwitterShared:
                    false && !_.isEmpty(establishment.twitterID) && !establishment.isTwitterSignedExpired,
                comment: establishment.defaultMenuBoardComment ?? '',
                facebookDeleteFlag: establishment.facebookDeleteFlag,
                twitterDeleteFlag: establishment.twitterDeleteFlag,
                googlePostDeleteFlag: establishment.googlePostDeleteFlag,
                googlePhotoDeleteFlag: establishment.googlePhotoDeleteFlag,
                shouldDelete: deleteInitialValue(establishment) ? [true] : [],
            }}
            onBack={handleGoBack}
            categories={categories}
            isFacebookLinked={!_.isEmpty(establishment.facebookPageId)}
            isFacebookSignedExpired={restaurantOwner.isFacebookSignedExpired}
            isGoogleLinked={!_.isEmpty(establishment.googleId)}
            isGoogleSignedExpired={restaurantOwner.isGoogleSignedExpired}
            isInstagramLinked={!_.isEmpty(establishment.instagramPageId)}
            isTwitterLinked={!_.isEmpty(establishment.twitterID)}
            isTwitterSignedExpired={establishment.isTwitterSignedExpired ?? false}
            ability={ability}
            addError={addError}
            facebookError={facebookError}
            googleError={googleError}
            instagramError={instagramError}
            twitterError={twitterError}
            openLoadingDialog={openLoadingDialog !== 'no'}
            closeLoadingDialog={handleCloseLoadingDialog}
            loadingNetworks={loadingNetworks}
            onUpdateEstablishment={handleUpdateEstablishment}
        />
    )
}

export default withMuiPickersUtilsProvider(withAuthenticateLayout(withDnD(SlateAdd)))
