import { Button, CardActions, Typography } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import {
    Establishment,
    MessagingConversation,
    MessagingMessage,
    PostDetails,
    SetMessagingMessage,
    useDeleteConversation,
    useDeleteConversationMessage,
    useHideConversationMessage,
    usePostMessages,
} from '@sugg-gestion/ubidreams-react-suggpro'
import SubmitButton from 'components/common/submitButton'
import { MessageFormState } from 'components/forms/messaging/privateMessageForm'
import { useFetchContent } from 'core/services/messaging/useFetchContent'
import { PageType, useApiErrors } from 'core/services/suggPro/useApiErrors'
import actions from 'core/store/actions'
import { FormApi } from 'final-form'
import { useSnackbar } from 'notistack'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { messagingLink } from '../messagingUtils'
import { useStyles } from '../styles.css'
import PostPane from './pane/post'
import PrivateMessagePane from './pane/privateMessage'
import ReviewPane from './pane/review'

interface Props {
    establishment: Establishment
    conversation: MessagingConversation
    handleToggleDrawer?: any
    disableConversation: () => void
}

const DiscussPane: React.FC<Props> = ({
    establishment,
    conversation,
    handleToggleDrawer,
    disableConversation,
}) => {
    const classes = useStyles()
    const { displayError } = useApiErrors()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    // Post message
    const { postMessage, inProgressSendMessage } = usePostMessages()
    const { deleteConversation } = useDeleteConversation()
    const { deleteConversationMessage } = useDeleteConversationMessage()
    const { hideConversationMessage } = useHideConversationMessage()

    // handle OnSubmit form send Message
    const handleSendMessage = (
        values: MessageFormState,
        form: FormApi<MessageFormState, Partial<MessageFormState>>,
    ) => {
        if (values.text) {
            const sendMessageResponse = postMessage({
                establishment: conversation.establishment,
                conversation: conversation.id,
                parentId: values.parentId ?? null,
                text: values.text,
            })

            sendMessageResponse
                .then(() => {
                    // Reset the form when no error
                    form.reset()
                })
                .catch((error) => {
                    displayError(error, PageType.MESSAGING)
                })
        }
    }

    const handleDeleteConversation = (block: boolean) => {
        return deleteConversation({
            establishment: establishment.id,
            id: conversation.id,
            block,
        })
    }

    const handleDeleteMessage = (block: boolean, messageId: string) => {
        return deleteConversationMessage({
            establishment: establishment.id,
            id: conversation.id,
            block,
            messageId,
        })
    }

    const handleHideMessage = (block: boolean, messageId: string) => {
        return hideConversationMessage({
            establishment: establishment.id,
            id: conversation.id,
            block,
            messageId,
        })
    }

    const onDeleteConversation = () => {
        dispatch(
            actions.alert({
                content: t('views.messaging.deleteConversation.confirmation'),
                buttons: [
                    {
                        variant: 'contained',
                        children: t('common.no'),
                    },
                    {
                        color: 'primary',
                        variant: 'contained',
                        children: t('common.yes'),
                        onClick: () =>
                            handleDeleteConversation(false)
                                .then(() => {
                                    disableConversation()
                                    enqueueSnackbar(t('views.messaging.blockUser.success'), {
                                        variant: 'success',
                                    })
                                })
                                .catch((error) => displayError(error)),
                    },
                ],
            }),
        )
    }

    const onDeletePost = () => {
        dispatch(
            actions.alert({
                content: t('views.messaging.deletePost.confirmation'),
                buttons: [
                    {
                        variant: 'contained',
                        children: t('common.no'),
                    },
                    {
                        color: 'primary',
                        variant: 'contained',
                        children: t('common.yes'),
                        onClick: () =>
                            handleDeleteConversation(false)
                                .then(() => {
                                    disableConversation()
                                    enqueueSnackbar(t('views.messaging.deletePost.success'), {
                                        variant: 'success',
                                    })
                                })
                                .catch((error) => displayError(error)),
                    },
                ],
            }),
        )
    }

    const onHidePost = () => {
        dispatch(
            actions.alert({
                content: t('views.messaging.hidePost.confirmation'),
                buttons: [
                    {
                        variant: 'contained',
                        children: t('common.no'),
                    },
                    {
                        color: 'primary',
                        variant: 'contained',
                        children: t('common.yes'),
                        onClick: () =>
                            handleDeleteConversation(false)
                                .then(() => {
                                    disableConversation()
                                    enqueueSnackbar(t('views.messaging.hidePost.success'), {
                                        variant: 'success',
                                    })
                                })
                                .catch((error) => displayError(error)),
                    },
                ],
            }),
        )
    }

    const onHideReview = () => {
        dispatch(
            actions.alert({
                content: t('views.messaging.hideReview.confirmation'),
                buttons: [
                    {
                        variant: 'contained',
                        children: t('common.no'),
                    },
                    {
                        color: 'primary',
                        variant: 'contained',
                        children: t('common.yes'),
                        onClick: () =>
                            handleDeleteConversation(false)
                                .then(() => {
                                    disableConversation()
                                    enqueueSnackbar(t('views.messaging.hideReview.success'), {
                                        variant: 'success',
                                    })
                                })
                                .catch((error) => displayError(error)),
                    },
                ],
            }),
        )
    }

    const onDeleteAndBlockConversation = () => {
        dispatch(
            actions.alert({
                content: t('views.messaging.blockUser.confirmation'),
                buttons: [
                    {
                        variant: 'contained',
                        children: t('common.no'),
                    },
                    {
                        color: 'primary',
                        variant: 'contained',
                        children: t('common.yes'),
                        onClick: () =>
                            handleDeleteConversation(true)
                                .then(() => {
                                    disableConversation()
                                    enqueueSnackbar(t('views.messaging.blockUser.success'), {
                                        variant: 'success',
                                    })
                                })
                                .catch((error) => displayError(error)),
                    },
                ],
            }),
        )
    }

    const onDeleteMessage = (messageId: string) => {
        dispatch(
            actions.alert({
                content: t('views.messaging.deleteMessage.confirmation'),
                buttons: [
                    {
                        variant: 'contained',
                        children: t('common.no'),
                    },
                    {
                        color: 'primary',
                        variant: 'contained',
                        children: t('common.yes'),
                        onClick: () =>
                            handleDeleteMessage(false, messageId)
                                .then(() => {
                                    enqueueSnackbar(t('views.messaging.deleteMessage.success'), {
                                        variant: 'success',
                                    })
                                })
                                .catch((error) => displayError(error)),
                    },
                ],
            }),
        )
    }

    const onHideMessage = (messageId: string) => {
        dispatch(
            actions.alert({
                content: t('views.messaging.hideMessage.confirmation'),
                buttons: [
                    {
                        variant: 'contained',
                        children: t('common.no'),
                    },
                    {
                        color: 'primary',
                        variant: 'contained',
                        children: t('common.yes'),
                        onClick: () =>
                            handleHideMessage(false, messageId)
                                .then(() => {
                                    enqueueSnackbar(t('views.messaging.hideMessage.success'), {
                                        variant: 'success',
                                    })
                                })
                                .catch((error) => displayError(error)),
                    },
                ],
            }),
        )
    }

    // Get post details and messages
    const { listPostDetails, listMessage, inProgress, hasError, setHasError } = useFetchContent(conversation)
    const postDetails: PostDetails | undefined = listPostDetails?.find(
        (element) => element.conversation === conversation.id,
    )
    let messages: MessagingMessage[] = []
    if (listMessage) {
        const messagesFromConversation: SetMessagingMessage | undefined = listMessage.find(
            (element) => element.conversation === conversation.id,
        )
        if (messagesFromConversation) {
            messages = messagesFromConversation.messages.filter((message) => !message.hidden)
        }
    }

    if (hasError === conversation.id) {
        return (
            <div className={classes.errorContainer}>
                <div>
                    <ErrorOutlineIcon color="primary" className={classes.errorIcon} />
                </div>
                <Typography className={classes.errorMessage}>
                    <Trans i18nKey="views.messaging.error.fetchFailed" />
                </Typography>
                <CardActions>
                    <SubmitButton
                        color="primary"
                        variant="contained"
                        loading={inProgress}
                        onClick={() => setHasError(undefined)}
                    >
                        <Trans i18nKey="actions.retry" />
                    </SubmitButton>
                    <Button
                        color="primary"
                        variant="contained"
                        href={messagingLink(conversation, establishment) || ''}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Trans i18nKey="actions.accessOnlineVersion" />
                    </Button>
                </CardActions>
            </div>
        )
    }

    switch (conversation.typeConversation) {
        case 'private_message':
            return (
                <PrivateMessagePane
                    establishment={establishment}
                    conversation={conversation}
                    messages={messages}
                    inProgress={inProgress}
                    handleToggleDrawer={handleToggleDrawer}
                    inProgressSendMessage={inProgressSendMessage}
                    handleSendMessage={handleSendMessage}
                    onDeleteConversation={onDeleteConversation}
                    onDeleteAndBlockConversation={onDeleteAndBlockConversation}
                    onDeleteMessage={onDeleteMessage}
                    onHideMessage={onHideMessage}
                />
            )
        case 'post':
            return (
                <PostPane
                    establishment={establishment}
                    conversation={conversation}
                    postDetails={postDetails}
                    messages={messages}
                    inProgress={inProgress}
                    handleToggleDrawer={handleToggleDrawer}
                    onDeletePost={onDeletePost}
                    onHidePost={onHidePost}
                />
            )
        case 'review':
            return (
                <ReviewPane
                    establishment={establishment}
                    conversation={conversation}
                    postDetails={postDetails}
                    messages={messages}
                    inProgress={inProgress}
                    handleToggleDrawer={handleToggleDrawer}
                    onHideReview={onHideReview}
                />
            )
        default:
            return <></>
    }
}

export default DiscussPane
