import React from 'react'
import AuthenticateContent from 'components/layouts/components/authenticateContent'
import { Button, Container, Grid, Typography } from '@material-ui/core'
import { Trans } from 'react-i18next'
import { useStyles } from './signView.css'
import SubmitButton from '../../common/submitButton'
import WarningIcon from '@material-ui/icons/Warning'
import DoneIcon from '@material-ui/icons/Done'
import clsx from 'clsx'

interface Props {
    isFacebookSigned: boolean
    isFacebookSignedExpired: boolean
    onRenewFacebookSignIn: () => void
    renewFacebookSignInInProgress: boolean
    isGoogleSigned: boolean
    isGoogleSignedExpired: boolean
    onRenewGoogleSignIn: () => void
    renewGoogleSignInInProgress: boolean
}

const SignView: React.FC<Props> = ({
    isFacebookSigned,
    isFacebookSignedExpired,
    onRenewFacebookSignIn,
    renewFacebookSignInInProgress,
    isGoogleSigned,
    isGoogleSignedExpired,
    onRenewGoogleSignIn,
    renewGoogleSignInInProgress,
}) => {
    const classes = useStyles()
    return (
        <Container disableGutters maxWidth="sm" className={classes.root}>
            <AuthenticateContent withAction={false}>
                <Typography variant="h1" className={classes.title}>
                    <Trans i18nKey="views.sign.title" />
                </Typography>
                {isGoogleSigned && (
                    <div className={classes.section}>
                        <Typography color="textSecondary" variant="h1" className={classes.subtitle}>
                            <Trans i18nKey="form.googlePage" />
                        </Typography>
                        <div className={classes.message}>
                            {isGoogleSignedExpired ? (
                                <Typography>
                                    <Trans i18nKey="views.sign.googleExpired" />
                                </Typography>
                            ) : (
                                <Typography>
                                    <Trans i18nKey="views.sign.google" />
                                </Typography>
                            )}
                        </div>
                        <Grid container spacing={2} className={classes.actionsContainer}>
                            <Grid item className={classes.actionContainer}>
                                <SubmitButton
                                    loading={renewGoogleSignInInProgress}
                                    variant={isGoogleSignedExpired ? 'contained' : 'outlined'}
                                    color="primary"
                                    onClick={onRenewGoogleSignIn}
                                    className={classes.action}
                                    fullWidth
                                >
                                    <Trans i18nKey="actions.renewSignInGoogle" />
                                </SubmitButton>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" disabled className={classes.iconButton}>
                                    {isGoogleSignedExpired ? (
                                        <WarningIcon color="primary" className={classes.icon} />
                                    ) : (
                                        <DoneIcon
                                            color="primary"
                                            className={clsx(classes.icon, classes.iconSuccess)}
                                        />
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                )}
                {isFacebookSigned && (
                    <div className={classes.section}>
                        <Typography color="textSecondary" variant="h1" className={classes.subtitle}>
                            <Trans i18nKey="form.facebookPage" />
                        </Typography>
                        <div className={classes.message}>
                            {isFacebookSignedExpired ? (
                                <Typography>
                                    <Trans i18nKey="views.sign.facebookExpired" />
                                </Typography>
                            ) : (
                                <Typography>
                                    <Trans i18nKey="views.sign.facebook" />
                                </Typography>
                            )}
                        </div>
                        <Grid container spacing={2} className={classes.actionsContainer}>
                            <Grid item className={classes.actionContainer}>
                                <SubmitButton
                                    loading={renewFacebookSignInInProgress}
                                    variant={isFacebookSignedExpired ? 'contained' : 'outlined'}
                                    color="primary"
                                    onClick={onRenewFacebookSignIn}
                                    className={classes.action}
                                    fullWidth
                                >
                                    <Trans i18nKey="actions.renewSignInFacebook" />
                                </SubmitButton>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" disabled className={classes.iconButton}>
                                    {isFacebookSignedExpired ? (
                                        <WarningIcon color="primary" className={classes.icon} />
                                    ) : (
                                        <DoneIcon
                                            color="primary"
                                            className={clsx(classes.icon, classes.iconSuccess)}
                                        />
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </AuthenticateContent>
        </Container>
    )
}

export default SignView
