import React from 'react'
import { useStyles } from '../styles.css'
import { Avatar, Box, Grid, Typography } from '@material-ui/core'
import { MessagingConversation } from '@sugg-gestion/ubidreams-react-suggpro'
import { GetPlatformIcon } from '../messagingUtils'

interface Props {
    conversation: MessagingConversation
}

const ConversationBoxReview: React.FC<Props> = ({ conversation }) => {
    const classes = useStyles()
    let iconPlatform = GetPlatformIcon(conversation)

    let styleNewMessage = {}
    if (conversation.unread) {
        styleNewMessage = { fontWeight: 800 }
    }

    return (
        <Box component={'span'}>
            <Grid container alignItems="center" className={classes.conversationGrid}>
                <Grid item sm={3} xs={2}>
                    <div className={classes.conversationPictureContainer}>
                        <Avatar
                            src={(conversation.receiver && conversation.receiver.img) ?? undefined}
                            className={classes.conversationPicture}
                        />
                        <div>{iconPlatform}</div>
                    </div>
                </Grid>
                <Grid item sm={9} xs={10}>
                    <div className={classes.conversationCard}>
                        <Typography className={classes.conversationReceiverName} style={styleNewMessage}>
                            {conversation.receiver && conversation.receiver.name}
                        </Typography>
                        <Typography className={classes.conversationLastMessage} style={styleNewMessage}>
                            {conversation.title}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ConversationBoxReview
