import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
        },
        titleContainer: {
            margin: 0,
        },
        title: {
            paddingBottom: theme.spacing(4),
            display: 'inline-flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        action: {
            maxWidth: '100%',
        },
        section: {
            display: 'flex',
            padding: theme.spacing(1, 0),
            [theme.breakpoints.down('xs')]: {
                display: 'block',
            },
        },
        sectionSocial: {
            display: 'flex',
            padding: theme.spacing(4, 0, 1, 0),
            [theme.breakpoints.down('xs')]: {
                display: 'block',
            },
        },
        page: {
            padding: 0,
        },
        sectionTitle: {
            width: '40%',
            fontWeight: theme.fontsWeight.semiBold,
            display: 'inline-flex',
            alignItems: 'center',
            [theme.breakpoints.down('xs')]: {
                paddingBottom: theme.spacing(0.5),
                width: '100%',
            },
        },
        sectionContent: {
            'flexGrow': 1,
            'display': 'flex',
            'justifyContent': 'space-between',
            '& > svg': {
                marginLeft: 4,
            },
            [theme.breakpoints.down('xs')]: {
                textAlign: 'right',
                justifyContent: 'flex-end',
            },
        },
        success: {
            color: '#43a047',
        },
        warning: {
            color: '#ffa000',
        },
        error: {
            color: theme.palette.error.main,
        },
        helper: {
            'marginTop': theme.spacing(-1),
            'paddingTop': 0,
            'position': 'relative',
            '& p': {
                position: 'absolute',
                textAlign: 'right',
                width: '100%',
                fontSize: '0.9rem',
                [theme.breakpoints.down('xs')]: {
                    position: 'static',
                },
            },
        },
        buttonDanger: {
            // backgroundColor: theme.palette.error.main,
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
        },
    }),
)
