import React from 'react'
import { MenuItem, Select } from '@material-ui/core'
import { Trans } from 'react-i18next'
import { Moment } from 'moment'

interface Props {
    date: Moment | undefined
    onChangeDate: (event: React.ChangeEvent<{ value: unknown }>) => void
    inProgress: boolean
    dates: Array<Moment | undefined>
}

const DateSelector: React.FC<Props> = ({ date, onChangeDate, inProgress, dates }) => {
    return (
        <Select value={date ? date.toISOString() : 'seeAll'} onChange={onChangeDate} disabled={inProgress}>
            {dates.map((date, index) => {
                if (date) {
                    return (
                        <MenuItem key={index} value={date.toISOString()}>
                            {date.format('DD MMMM')}
                        </MenuItem>
                    )
                } else {
                    return (
                        <MenuItem key={index} value={'seeAll'}>
                            <Trans i18nKey="views.reminderNotebook.viewAll" />
                        </MenuItem>
                    )
                }
            })}
        </Select>
    )
}

export default DateSelector
