import { Typography } from '@material-ui/core'
import ImageCard from 'components/common/imageCard'
import React from 'react'
import { Trans } from 'react-i18next'
import { useStyles } from './image.css'

interface Props {
    image: string | undefined | null
}

export const Image: React.FC<Props> = ({ image }) => {
    const classes = useStyles()
    if (image) {
        return (
            <div className={classes.card}>
                <ImageCard url={image ?? undefined} index={0} />
            </div>
        )
    }
    return (
        <Typography className={classes.undefined}>
            <Trans i18nKey="views.externalLinks.customization.undefined" />
        </Typography>
    )
}
