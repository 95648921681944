import {
    FacebookPage,
    FacebookPagesResponse,
    Slate,
    GooglePagesResponse,
    GooglePage,
} from '@sugg-gestion/ubidreams-react-suggpro'

export enum ShareActionTypes {
    // Facebook
    REAUTHENTICATE_FACEBOOK = '@@share/REAUTHENTICATE_FACEBOOK',
    SHARE_SLATE_ON_FACEBOOK = '@@share/SHARE_SLATE_ON_FACEBOOK',
    SHARE_SLATE_ON_FACEBOOK_RESOLVER = '@@share/SHARE_SLATE_ON_FACEBOOK_RESOLVER',
    SET_IS_SHARING_SLATE_ON_FACEBOOK = '@@share/SET_IS_SHARING_SLATE_ON_FACEBOOK',

    SET_FACEBOOK_PAGES_DATA = '@@share/SET_FACEBOOK_PAGES_DATA',
    SELECT_FACEBOOK_PAGE_DATA = '@@share/SELECT_FACEBOOK_PAGE_DATA',

    // Google
    REAUTHENTICATE_GOOGLE = '@@share/REAUTHENTICATE_GOOGLE',
    SHARE_SLATE_ON_GOOGLE = '@@share/SHARE_SLATE_ON_GOOGLE',
    SHARE_SLATE_ON_GOOGLE_RESOLVER = '@@share/SHARE_SLATE_ON_GOOGLE_RESOLVER',
    SET_IS_SHARING_SLATE_ON_GOOGLE = '@@share/SET_IS_SHARING_SLATE_ON_GOOGLE',

    SET_GOOGLE_PAGES_DATA = '@@share/SET_GOOGLE_PAGES_DATA',
    SELECT_GOOGLE_PAGE_DATA = '@@share/SELECT_GOOGLE_PAGE_DATA',
}

export interface ShareState {
    isSharingSlateOnFacebook: boolean
    facebookPagesData?: FacebookPagesData
    isSharingSlateOnGoogle: boolean
    googlePagesData?: GooglePagesData
}

export interface FacebookPagesData {
    updateEstablishment: boolean
    facebookAccessToken: string
    slate: Slate
    pages: FacebookPagesResponse
}

export interface FacebookPageData {
    updateEstablishment: boolean
    facebookAccessToken: string
    slate: Slate
    page: FacebookPage
}

export interface GooglePagesData {
    updateEstablishment: boolean
    googleAccessToken: string
    slate: Slate
    pages: GooglePagesResponse
}

export interface GooglePageData {
    updateEstablishment: boolean
    googleAccessToken: string
    slate: Slate
    page: GooglePage
}
