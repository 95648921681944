import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContent: {
            [theme.breakpoints.down('xs')]: {
                '&.MuiCardContent-root': {
                    '@media (orientation: portrait)': {
                        paddingBottom: 114,
                    },
                },
            },
        },
    }),
)
