import React from 'react'
import { SubmitButtonProps } from '../../common/submitButton'
import { useFormsStyles } from '../forms.css'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import TextField from '../components/textField'
import CheckboxGroupField from '../components/checkboxGroupField'
import { Divider, Typography } from '@material-ui/core'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: DefaultCommentFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: DefaultCommentFormState
}

export interface DefaultCommentFormState {
    defaultMenuBoardComment: string | null
    useDefault: Array<boolean>
}

const DefaultCommentForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
        values: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
}) => {
    const { t } = useTranslation()
    const formsClasses = useFormsStyles()

    const handleSubmit = (values: DefaultCommentFormState) => {
        if (values.useDefault && values.useDefault.length > 0) {
            values.defaultMenuBoardComment = null
        } else {
            if (!values.defaultMenuBoardComment) {
                values.defaultMenuBoardComment = ''
            }
        }
        return onSubmit(values)
    }
    const defaultValues = [
        {
            label: t('form.useDefaultMenuBoardComment'),
            value: true,
        },
    ]
    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={handleSubmit}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting, values }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <>
                            <CheckboxGroupField
                                name="useDefault"
                                values={defaultValues}
                                xs={12}
                                type="switch"
                            />
                            <div className={formsClasses.or}>
                                <Divider className={formsClasses.orDivider} variant="middle" />
                                <Typography color="textPrimary">
                                    <Trans i18nKey="common.or" />
                                </Typography>
                                <Divider className={formsClasses.orDivider} variant="middle" />
                            </div>
                            {/*  COMMENT  */}
                            <TextField
                                name="defaultMenuBoardComment"
                                multiline={true}
                                maxRows={9}
                                variant="outlined"
                                disabled={values.useDefault && values.useDefault.length > 0}
                            />
                        </>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                className: formsClasses.submit,
                                children: <Trans i18nKey="actions.validate" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}

export default DefaultCommentForm
