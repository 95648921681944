import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: 300,
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        action: {
            justifyContent: 'center',
        },
        title: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(4),
        },
    }),
)
