import { AnyAbility } from '@casl/ability'
import Account from 'containers/account'
import AccountEdit from 'containers/account/accountEdit'
import EmailValidation from 'containers/account/emailValidation'
import GoogleCallback from 'containers/account/googleCallback'
import Sign from 'containers/account/sign'
import Home from 'containers/auth/home'
import OAuth from 'containers/auth/oauth'
import Booking from 'containers/booking/booking'
import EstablishmentAddDetails from 'containers/establishment/establishmentAddDetails'
import EstablishmentAddFacebookGoogle from 'containers/establishment/establishmentAddFacebookGoogle'
import EstablishmentAddFoodType from 'containers/establishment/establishmentAddFoodType'
import EstablishmentAddHours from 'containers/establishment/establishmentAddHours'
import EstablishmentAddLocation from 'containers/establishment/establishmentAddLocation'
import EstablishmentAddPhotos from 'containers/establishment/establishmentAddPhotos'
import EstablishmentAddSocialNetworks from 'containers/establishment/establishmentAddSocialNetworks'
import EstablishmentCompleted from 'containers/establishment/establishmentCompleted'
import EstablishmentDuns from 'containers/establishment/establishmentDuns'
import EstablishmentEdit from 'containers/establishment/establishmentEdit'
import EstablishmentPayment from 'containers/establishment/establishmentPayment'
import EstablishmentPaymentCheck from 'containers/establishment/establishmentPaymentCheck'
import NoEstablishment from 'containers/establishment/noEstablishment'
import ExternalLinks from 'containers/externalLinks'
import { WithGraphApiProps } from 'containers/hoc/withGraphApi'
import Menu from 'containers/menu'
import MenuEdit from 'containers/menu/edit'
import Messaging from 'containers/messaging'
import News from 'containers/news'
import AddEvent from 'containers/news/addEvent'
import AddNews from 'containers/news/addNews'
import Recruitment from 'containers/recruitment'
import ReminderNotebook from 'containers/reminderNotebook'
import Slate from 'containers/slate'
import SlateAdd from 'containers/slate/add'
import SlateSetting from 'containers/slate/setting'
import Statistics from 'containers/statistics'
import FakeStatistics from 'containers/statistics/fake'
import Tuto from 'containers/tuto'
import React from 'react'

export interface InternalRoute {
    path: string
    component?: React.FC<WithGraphApiProps>
    children?: Array<InternalRoute>
}

export const routes = (ability: AnyAbility): Array<InternalRoute> => [
    {
        path: 'auth',
        children: [
            {
                path: 'sign-in',
                component: Home,
            },
        ],
    },
    {
        path: 'oauth',
        component: OAuth,
    },
    {
        path: 'account',
        component: Account,
        children: [
            {
                path: 'edit',
                component: AccountEdit,
            },
            {
                path: 'email-validation',
                component: EmailValidation,
            },
            {
                path: 'sign',
                component: Sign,
            },
        ],
    },
    {
        path: 'establishment/:id',
        children: [
            {
                path: 'slate',
                component: Slate,
                children: [
                    {
                        path: 'add',
                        component: SlateAdd,
                    },
                    {
                        path: 'setting',
                        component: SlateSetting,
                    },
                ],
            },
            {
                path: 'reminder-notebook',
                component: ReminderNotebook,
            },
            {
                path: 'menu',
                component: Menu,
                children: [
                    {
                        path: 'edit',
                        component: MenuEdit,
                    },
                ],
            },
            {
                path: 'duns',
                component: EstablishmentDuns,
            },
            {
                path: 'payment',
                component: EstablishmentPayment,
                children: [
                    {
                        path: 'check',
                        component: EstablishmentPaymentCheck,
                    },
                ],
            },
            {
                path: 'add',
                children: [
                    {
                        path: 'facebook-google',
                        component: EstablishmentAddFacebookGoogle,
                    },
                    {
                        path: 'social-networks',
                        component: EstablishmentAddSocialNetworks,
                    },
                    {
                        path: 'details',
                        component: EstablishmentAddDetails,
                    },
                    {
                        path: 'food-type',
                        component: EstablishmentAddFoodType,
                    },
                    {
                        path: 'location',
                        component: EstablishmentAddLocation,
                    },
                    {
                        path: 'hours',
                        component: EstablishmentAddHours,
                    },
                    {
                        path: 'photos',
                        component: EstablishmentAddPhotos,
                    },
                    {
                        path: 'completed',
                        component: EstablishmentCompleted,
                    },
                ],
            },
            {
                path: 'edit',
                component: EstablishmentEdit,
            },
            {
                path: 'external-links',
                component: ExternalLinks,
            },
            {
                path: 'booking',
                component: Booking,
            },
            {
                path: 'statistics',
                component: ability.can('api.statistics_access') ? Statistics : FakeStatistics,
            },
            {
                path: 'news',
                component: News,
                children: [
                    {
                        path: 'addEvent',
                        component: AddEvent,
                    },
                    {
                        path: 'addNews',
                        component: AddNews,
                    },
                ],
            },
            {
                path: 'messages',
                // TODO: mettre la conditiion ability
                // component: ability.can('api.statistics_access') ? Messaging : FakeMessaging,
                component: Messaging,
            },
        ],
    },
    {
        path: 'establishments',
        children: [
            {
                path: 'undefined',
                component: NoEstablishment,
            },
        ],
    },
    {
        path: 'recruitment',
        component: Recruitment,
    },
    {
        path: 'tuto',
        component: Tuto,
    },
    {
        path: 'google',
        children: [
            {
                path: 'callback',
                component: GoogleCallback,
            },
        ],
    },
]
