import React from 'react'
import { useImgix } from 'core/services/imgix/useImgix'
import CloseButton from './closeButton'
import { useTheme } from '@material-ui/core/styles'
import { Dialog, useMediaQuery } from '@material-ui/core'
import { useStyles } from './dialogZoom.css'
import { SlateImage } from '@sugg-gestion/ubidreams-react-suggpro'

interface Props {
    open: boolean
    image: SlateImage
    extImage?: boolean
    onClose: () => void
}

const ZoomDialog: React.FC<Props> = ({ open, image, extImage = false, onClose }) => {
    const classes = useStyles()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true })
    const { getImage } = useImgix()
    const getImageSource = (picture: string, dpr?: number) => {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        let height =
            (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) * 0.9
        if (fullScreen) {
            height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
        }
        const targetWidth = Math.round(width)
        const targetHeight = Math.round(height)

        if (!extImage) {
            return {
                height: targetHeight,
                width: targetWidth,
                image: getImage(picture, { w: targetWidth, h: targetHeight, fit: 'clip', dpr: dpr ?? 1 }),
            }
        } else {
            return {
                height: targetHeight,
                width: targetWidth,
                image: picture,
            }
        }
    }
    const { image: baseImage, height, width } = getImageSource(image.picture)
    if (!extImage) {
        return (
            <Dialog open={open} onClose={onClose} maxWidth="xl" fullScreen={fullScreen}>
                <div className={classes.dialog}>
                    <CloseButton onClose={onClose} />
                    <div style={{ maxHeight: height, maxWidth: width }}>
                        <img
                            src={baseImage}
                            srcSet={
                                getImageSource(image.picture, 1).image +
                                ' 1x, ' +
                                getImageSource(image.picture, 2).image +
                                ' 2x, ' +
                                getImageSource(image.picture, 3).image +
                                ' 3x'
                            }
                            alt="zoom"
                        />
                    </div>
                </div>
            </Dialog>
        )
    } else {
        return (
            <Dialog open={open} onClose={onClose} maxWidth="xl" fullScreen={fullScreen}>
                <div className={classes.dialog}>
                    <CloseButton onClose={onClose} />
                    <img src={baseImage} alt={'preview'} />
                </div>
            </Dialog>
        )
    }
}

export default ZoomDialog
