import React from 'react'
import { Establishment, User } from '@sugg-gestion/ubidreams-react-suggpro'
import PermissionAlert from '../../components/views/statistics/permissionAlert'
import withFacebookAndGoogle from '../hoc/withFacebookAndGoogle'
import Loader from '../../components/common/loader'
import { useFacebookPages } from '../../core/services/facebook/useFacebookPages'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useApiErrors } from '../../core/services/suggPro/useApiErrors'

interface Props {
    restaurantOwner: User
    establishment: Establishment
}

const MessagingPermissions: React.FC<Props> = ({ restaurantOwner, establishment }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const { displayError } = useApiErrors()

    const { fetchFacebookPagesAction, inProgress: renewFacebookSignInInProgress } = useFacebookPages()

    const handleFacebookPermission = () => {
        fetchFacebookPagesAction(true, true)
            .then(() => {
                enqueueSnackbar(t('views.sign.successFacebook'), {
                    variant: 'success',
                })
            })
            .catch((error) => displayError(error))
    }
    return (
        <>
            {restaurantOwner.isFacebookSigned &&
                (restaurantOwner.facebookScopes.indexOf('pages_messaging') === -1 ||
                    restaurantOwner.facebookScopes.indexOf('pages_manage_metadata')) && (
                    <PermissionAlert
                        action="actions.renewSignInFacebook"
                        message="views.messaging.permissions.noFacebook"
                        onClick={handleFacebookPermission}
                        loading={renewFacebookSignInInProgress}
                    />
                )}
            {establishment.instagramPageId &&
                (restaurantOwner.facebookScopes.indexOf('instagram_manage_messages') === -1 ||
                    restaurantOwner.facebookScopes.indexOf('instagram_manage_comments')) && (
                    <PermissionAlert
                        action="actions.renewSignInFacebook"
                        message="views.messaging.permissions.noInstagram"
                        onClick={handleFacebookPermission}
                        loading={renewFacebookSignInInProgress}
                    />
                )}
        </>
    )
}

export default withFacebookAndGoogle(MessagingPermissions, <Loader withLogo={false} />)
