import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'
import { useStyles } from './websiteIcon.css'

interface Props {
    className?: string
}

const PadLockIcon: React.FC<SvgIconProps & Props> = (props) => {
    const classes = useStyles()
    return (
        <SvgIcon {...props} viewBox="0.000000 0.000000 836.812317 1078.363037" classes={classes}>
            <g>
                <path d="M836.8 930.1 C836.8 955.7 836.8 981.7 830.0 1006.4 C823.2 1031.1 808.7 1054.8 786.1 1066.9 C765.4 1078.1 740.7 1078.4 717.2 1078.3 C521.6 1078.2 326.0 1078.1 130.4 1078.0 C91.3 1078.0 47.0 1075.6 21.7 1045.8 C1.3 1021.7 0.3 987.2 0.3 955.6 C0.2 804.2 0.1 652.7 0.0 501.2 C0.0 476.8 0.2 451.5 10.3 429.3 C19.6 409.0 37.0 392.7 57.9 384.8 C77.0 377.6 97.9 377.5 118.4 377.5 C315.3 377.2 512.2 376.9 709.2 376.7 C739.1 376.6 771.1 377.2 795.5 394.6 C832.9 421.2 836.5 474.5 836.6 520.4 C836.6 657.0 836.7 793.6 836.8 930.1 M504.7 753.4 C544.8 718.3 560.0 657.5 541.0 607.7 C522.1 557.9 470.3 522.5 417.0 523.0 C363.8 523.4 312.6 559.7 294.5 609.8 C276.4 659.9 292.7 720.5 333.4 754.8 C336.1 757.2 336.3 761.3 336.3 764.9 C336.1 795.5 335.8 826.2 335.6 856.9 C335.4 878.3 335.3 900.3 343.4 920.1 C356.2 951.5 390.4 972.7 424.2 970.3 C458.0 967.8 488.9 941.9 497.1 909.0 C500.3 896.0 500.3 882.5 500.2 869.1 C500.1 836.2 499.9 803.3 499.8 770.4 C499.8 764.3 500.1 757.4 504.7 753.4 " />
                <path d="M399.8 877.7 C399.9 835.3 400.1 792.9 400.2 750.4 C400.2 741.3 400.1 731.6 395.2 723.9 C390.7 716.8 382.9 712.6 376.3 707.4 C354.4 690.0 345.3 658.3 354.5 632.0 C363.8 605.7 390.9 586.7 418.8 586.9 C446.7 587.1 473.4 606.6 482.3 633.1 C491.1 659.5 481.4 691.1 459.2 708.1 C453.2 712.6 446.3 716.3 442.0 722.5 C436.2 730.6 435.9 741.4 436.0 751.4 C436.0 794.0 436.1 836.6 436.1 879.2 C436.1 886.3 436.0 893.9 431.8 899.6 C425.8 907.8 412.0 908.4 405.3 900.8 C400.0 894.7 399.8 885.8 399.8 877.7 " />
                <path d="M314.3 0.1 C384.9 0.1 455.5 0.0 526.1 0.0 C539.7 0.0 553.4 0.0 566.7 2.7 C581.1 5.6 594.8 11.6 607.5 19.1 C641.3 39.2 668.8 70.8 680.8 108.2 C690.3 137.7 690.0 169.3 689.7 200.2 C689.3 237.7 688.9 275.2 688.5 312.7 C627.7 313.4 566.9 313.4 506.1 312.7 C506.1 272.0 506.0 231.3 506.0 190.5 C506.0 185.3 505.5 179.0 500.9 176.4 C498.6 175.1 495.9 175.1 493.3 175.1 C443.4 175.1 393.5 175.1 343.7 175.1 C340.2 175.1 336.4 175.2 333.9 177.6 C331.1 180.2 330.9 184.4 330.9 188.2 C331.0 227.7 331.0 267.1 331.1 306.6 C331.1 311.6 324.3 313.2 319.3 313.2 C264.2 313.2 209.2 313.1 154.2 313.1 C152.1 313.1 149.7 313.0 148.4 311.4 C147.4 310.1 147.3 308.4 147.3 306.8 C147.2 291.8 147.0 276.8 146.9 261.8 C146.7 238.0 146.5 214.3 146.5 190.5 C146.6 168.0 146.8 145.2 151.8 123.1 C161.5 80.2 189.6 41.7 227.6 19.4 C241.0 11.6 255.5 5.7 270.7 2.8 C285.1 0.1 299.7 0.1 314.3 0.1 " />
            </g>
        </SvgIcon>
    )
}

export default PadLockIcon
