import React from 'react'
import CheckboxGroupField, { CheckboxValue } from './checkboxGroupField'
import { SlateCategory } from '@sugg-gestion/ubidreams-react-suggpro'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { GridSize } from '@material-ui/core/Grid/Grid'

interface Props extends Partial<Record<Breakpoint, boolean | GridSize>> {
    name: string
    categories?: Array<SlateCategory>
    disabled?: boolean
}

const CategoriesField: React.FC<Props> = ({ name, categories, disabled, ...gridSize }) => {
    const categoriesValues: Array<CheckboxValue<number>> = []
    const newCategoriesValues: Array<CheckboxValue<number>> = []

    const sortedCategories = categories?.sort((a, b) => {
        if (a.name === 'Livraison') {
            return 1
        } else if (b.name === 'Livraison') {
            return -1
        }
        if (a.name === 'A emporter') {
            return 1
        } else if (b.name === 'A emporter') {
            return -1
        }
        if (a.name === 'Sur place') {
            return 1
        } else if (b.name === 'Sur place') {
            return -1
        }
        return a.id - b.id
    })

    sortedCategories?.forEach((category) => {
        if (
            category.name === 'Sur place' ||
            category.name === 'Livraison' ||
            category.name === 'A emporter'
        ) {
            newCategoriesValues.push({
                label: category.name,
                value: category.id,
            })
        } else {
            categoriesValues.push({
                label: category.name,
                value: category.id,
            })
        }
    })
    return (
        <>
            <CheckboxGroupField name={name} values={categoriesValues} disabled={disabled} {...gridSize} />
            <CheckboxGroupField name={name} values={newCategoriesValues} disabled={disabled} {...gridSize} />
        </>
    )
}

export default CategoriesField
