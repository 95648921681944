import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

const RightArrowIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 41.423 71.676">
            <g id="down-arrow" transform="translate(-6.336 71.676) rotate(-90)">
                <path
                    d="M68.31,23.166,42.021,45.494a9.565,9.565,0,0,1-12.363,0L3.369,23.166A9.55,9.55,0,1,1,15.734,8.609L35.839,25.682l20.1-17.073A9.551,9.551,0,1,1,68.31,23.166Z"
                    transform="translate(0 0)"
                />
            </g>
        </SvgIcon>
    )
}

export default RightArrowIcon
