import React from 'react'
import { Grid } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import VideoStepper from './components/videoStepper'
import VideoCard from './components/videoCard'
import { Video } from 'core/store/tuto/types'
import AuthenticateContent from 'components/layouts/components/authenticateContent'
import AuthenticateActions from 'components/layouts/components/authenticateActions'
import HiddenCss from '@material-ui/core/Hidden/HiddenCss'

interface Props {
    numberOfPage: number
    page: number
    handleNextPage: () => void
    handlePreviousPage: () => void
    inProgress: boolean
    getVideos: (page: number) => Array<Video>
}

const TutoView: React.FC<Props> = ({
    page,
    numberOfPage,
    handleNextPage,
    handlePreviousPage,
    inProgress,
    getVideos,
}) => {
    return (
        <>
            <HiddenCss xsDown>
                <VideoStepper
                    page={page}
                    numberOfPage={numberOfPage}
                    handleNextPage={handleNextPage}
                    handlePreviousPage={handlePreviousPage}
                />
            </HiddenCss>
            <AuthenticateContent>
                <Grid container spacing={4}>
                    {inProgress
                        ? (function () {
                              const skeleton = []
                              for (let i = 0; i < 8; i++) {
                                  skeleton.push(
                                      <Grid key={i} item xs={12} sm={6} md={3}>
                                          <Skeleton variant="rect" height={240} />
                                      </Grid>,
                                  )
                              }
                              return skeleton
                          })()
                        : getVideos(page).map((video) => (
                              <Grid key={video.id} item xs={12} sm={6} md={3}>
                                  <VideoCard video={video} />
                              </Grid>
                          ))}
                </Grid>
            </AuthenticateContent>
            <AuthenticateActions>
                <VideoStepper
                    page={page}
                    numberOfPage={numberOfPage}
                    handleNextPage={handleNextPage}
                    handlePreviousPage={handlePreviousPage}
                />
            </AuthenticateActions>
        </>
    )
}

export default TutoView
