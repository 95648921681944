import React from 'react'
import MainLayout from './mainLayout'
import { Button, Card, Container, Menu, MenuItem, useMediaQuery, useTheme } from '@material-ui/core'
import { useStyles } from './signUpLayout.css'
import Logo from '../common/logo'
import { Trans, useTranslation } from 'react-i18next'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ListItemText from '@material-ui/core/ListItemText'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import AppBar from '@material-ui/core/AppBar'
import SettingsIcon from '@material-ui/icons/Settings'

interface Props {
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
    onSignOut: () => void
    onOpenAccount: () => void
}

const SignUpLayout: React.FC<Props> = ({ children, maxWidth = 'md', onSignOut, onOpenAccount }) => {
    const { t } = useTranslation()

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const classes = useStyles(maxWidth, fullScreen)()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const isMenuOpen = Boolean(anchorEl)

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleMenuClose = () => {
        setAnchorEl(null)
    }
    const handleOpenAccount = () => {
        setAnchorEl(null)
        onOpenAccount()
    }
    return (
        <MainLayout withLogo={false}>
            <Container maxWidth={maxWidth} className={classes.container}>
                <Card className={classes.card} elevation={0}>
                    <AppBar position="static" color="transparent" className={classes.appBar} elevation={0}>
                        <Toolbar>
                            <div className={classes.headerRight} />
                            <div className={classes.logoContainer}>
                                <Logo className={classes.logo} spacing={2} />
                            </div>
                            {fullScreen || maxWidth === 'sm' ? (
                                <IconButton color="inherit" edge="start" onClick={handleProfileMenuOpen}>
                                    <SettingsIcon />
                                </IconButton>
                            ) : (
                                <Button
                                    variant="outlined"
                                    onClick={handleProfileMenuOpen}
                                    startIcon={<SettingsIcon />}
                                    className={classes.menuButton}
                                >
                                    <Trans i18nKey="drawer.settings" />
                                </Button>
                            )}
                            <Menu
                                anchorEl={anchorEl}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                keepMounted
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                open={isMenuOpen}
                                onClose={handleMenuClose}
                            >
                                <MenuItem button onClick={handleOpenAccount}>
                                    <ListItemIcon>
                                        <AccountCircleIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t('drawer.account')} />
                                </MenuItem>
                                <MenuItem button onClick={onSignOut}>
                                    <ListItemIcon>
                                        <ExitToAppIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t('drawer.logOut')} />
                                </MenuItem>
                            </Menu>
                        </Toolbar>
                    </AppBar>
                    {children}
                </Card>
            </Container>
        </MainLayout>
    )
}

export default SignUpLayout
