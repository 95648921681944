import React from 'react'
import withAuthenticateLayout, { WithAuthenticateLayout } from '../hoc/withAuthenticateLayout'
import SettingSlateView from 'components/views/slate/settingView'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import { useEstablishmentUpdate, useSlateCategories } from '@sugg-gestion/ubidreams-react-suggpro'
import { DefaultCommentFormState } from 'components/forms/slate/defaultCommentForm'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { DefaultCategoriesFormState } from 'components/forms/slate/defaultCategoriesForm'
import { useQuery } from 'core/services/router/useQuery'
import { useHistory } from 'react-router-dom'
import { DefaultDeleteFlagFormState } from '../../components/forms/slate/defaultDeleteFlagForm'

interface Props extends WithAuthenticateLayout {}

const SlateSetting: React.FC<Props> = ({ establishment, ability }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const query = useQuery()
    const history = useHistory()

    const { displayError } = useApiErrors()
    const { updateEstablishment, inProgress } = useEstablishmentUpdate()
    const { categories } = useSlateCategories()

    const handleUpdateEstablishment = (
        values: DefaultCommentFormState | DefaultCategoriesFormState | DefaultDeleteFlagFormState,
    ) => {
        return updateEstablishment({
            id: establishment.id,
            establishment: {
                ...values,
            },
        })
            .then(() => {
                enqueueSnackbar(t('views.establishment.socialNetworks.success'), {
                    variant: 'success',
                })
                return true
            })
            .catch((error) => {
                displayError(error)
                return false
            })
    }

    const handleGoBack = () => {
        history.goBack()
    }

    return (
        <SettingSlateView
            ability={ability}
            establishment={establishment}
            onUpdateEstablishment={handleUpdateEstablishment}
            inProgress={inProgress}
            categories={categories}
            withBack={query.has('withBack')}
            onGoBack={handleGoBack}
        />
    )
}

export default withAuthenticateLayout(SlateSetting)
