import React from 'react'
import { Field } from 'react-final-form'
import {
    FormControl,
    FormLabel,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Switch,
} from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import GoogleIcon from 'components/icons/googleIcon'
import TwitterIcon from '@material-ui/icons/Twitter'
import { useFormsStyles } from '../../forms.css'
import { Trans } from 'react-i18next'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { GridSize } from '@material-ui/core/Grid/Grid'

interface Props extends Partial<Record<Breakpoint, boolean | GridSize>> {
    id: number
    isFacebookLinked?: boolean
    isInstagramLinked?: boolean
    isGoogleLinked?: boolean
    isTwitterLinked?: boolean
    showInstagram: boolean
    showTwitter: boolean
    showFacebook: boolean
    showGoogle: boolean
    showText?: boolean
}

const ShareImage: React.FC<Props> = ({
    id,
    isFacebookLinked = true,
    isInstagramLinked = true,
    isGoogleLinked = true,
    isTwitterLinked = true,
    showInstagram,
    showTwitter,
    showFacebook,
    showGoogle,
    showText = false,
    ...gridSize
}) => {
    const formsClasses = useFormsStyles()

    return (
        <FormControl margin="normal" className={formsClasses.share} fullWidth>
            <FormLabel>
                <Trans i18nKey="form.sharePhoto" />
            </FormLabel>
            <List>
                <Grid container>
                    {showFacebook && (
                        <Grid item {...gridSize}>
                            <Field name={'photos[' + id + '].isFacebookShared'}>
                                {({ input: { value, onChange } }) => (
                                    <ListItem
                                        disableGutters
                                        divider
                                        button
                                        onClick={() => onChange(!(value ?? false))}
                                        disabled={!isFacebookLinked}
                                    >
                                        <ListItemIcon>
                                            <Switch
                                                color="primary"
                                                edge="start"
                                                checked={value ?? false}
                                                disableRipple
                                            />
                                        </ListItemIcon>
                                        <ListItemIcon
                                            classes={{
                                                root: formsClasses.sharePhotoIcon,
                                            }}
                                        >
                                            <FacebookIcon className={formsClasses.facebookIcon} />
                                        </ListItemIcon>
                                        {showText && (
                                            <ListItemText
                                                primary={<Trans i18nKey="actions.shareFacebook" />}
                                            />
                                        )}
                                    </ListItem>
                                )}
                            </Field>
                        </Grid>
                    )}

                    {showInstagram && (
                        <Grid item {...gridSize}>
                            <Field name={'photos[' + id + '].isInstagramShared'}>
                                {({ input: { value, onChange } }) => (
                                    <ListItem
                                        disableGutters
                                        divider
                                        button
                                        onClick={() => onChange(!(value ?? false))}
                                        disabled={!isInstagramLinked}
                                    >
                                        <ListItemIcon>
                                            <Switch
                                                color="primary"
                                                edge="start"
                                                checked={value ?? false}
                                                disableRipple
                                            />
                                        </ListItemIcon>
                                        <ListItemIcon
                                            classes={{
                                                root: formsClasses.sharePhotoIcon,
                                            }}
                                        >
                                            <InstagramIcon className={formsClasses.instagramIcon} />
                                        </ListItemIcon>
                                        {showText && (
                                            <ListItemText
                                                primary={<Trans i18nKey="actions.shareInstagram" />}
                                            />
                                        )}
                                    </ListItem>
                                )}
                            </Field>
                        </Grid>
                    )}

                    {showGoogle && (
                        <Grid item {...gridSize}>
                            <Field name={'photos[' + id + '].isGoogleShared'}>
                                {({ input: { value, onChange } }) => (
                                    <ListItem
                                        disableGutters
                                        divider={showTwitter}
                                        button
                                        onClick={() => onChange(!(value ?? false))}
                                        disabled={!isGoogleLinked}
                                    >
                                        <ListItemIcon>
                                            <Switch
                                                color="primary"
                                                edge="start"
                                                checked={value ?? false}
                                                disableRipple
                                            />
                                        </ListItemIcon>
                                        <ListItemIcon
                                            classes={{
                                                root: formsClasses.sharePhotoIcon,
                                            }}
                                        >
                                            <GoogleIcon />
                                        </ListItemIcon>
                                        {showText && (
                                            <ListItemText primary={<Trans i18nKey="actions.shareGoogle" />} />
                                        )}
                                    </ListItem>
                                )}
                            </Field>
                        </Grid>
                    )}

                    {showTwitter && (
                        <Grid item {...gridSize}>
                            <Field name={'photos[' + id + '].isTwitterShared'}>
                                {({ input: { value, onChange, name } }) => (
                                    <ListItem
                                        disableGutters
                                        button
                                        onClick={() => onChange(!(value ?? false))}
                                        disabled={!isTwitterLinked}
                                        divider
                                    >
                                        <ListItemIcon>
                                            <Switch
                                                color="primary"
                                                edge="start"
                                                checked={value ?? false}
                                                disableRipple
                                            />
                                        </ListItemIcon>
                                        <ListItemIcon
                                            classes={{
                                                root: formsClasses.sharePhotoIcon,
                                            }}
                                        >
                                            <TwitterIcon className={formsClasses.twitterIcon} />
                                        </ListItemIcon>
                                        {showText && (
                                            <ListItemText
                                                primary={<Trans i18nKey="actions.shareTwitter" />}
                                            />
                                        )}
                                    </ListItem>
                                )}
                            </Field>
                        </Grid>
                    )}
                </Grid>
            </List>
        </FormControl>
    )
}

export default ShareImage
