import { clearCache as suggproClearCache } from '@sugg-gestion/suggpro-graph-module'
import {
    useCheckEmail,
    useForgottenPassword,
    useSignInByEmail,
    useSignUp,
} from '@sugg-gestion/ubidreams-react-suggpro'
import { EmailFormState } from 'components/forms/auth/emailForm'
import { ForgottenPasswordCodeFormState } from 'components/forms/auth/forgottenPasswordCodeForm'
import { SignInFormState } from 'components/forms/auth/signInForm'
import { SignUpFormState } from 'components/forms/auth/signUpForm'
import ForgottenPasswordDialog from 'components/views/auth/dialogs/forgottenPasswordDialog'
import HomeView from 'components/views/auth/homeView'
import SignInView from 'components/views/auth/signInView'
import SignUpView from 'components/views/auth/signUpView'
import { PageType, useApiErrors } from 'core/services/suggPro/useApiErrors'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

enum Step {
    SIGN_IN,
    SIGN_UP,
}

const Home: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const { displayError } = useApiErrors()
    const { checkEmail, inProgress: checkEmailInProgress } = useCheckEmail()
    const { signUp, inProgress: signUpInProgress } = useSignUp()
    const { signInByEmail, inProgress: signInInProgress } = useSignInByEmail()
    const {
        sendForgottenPasswordCode,
        updateForgotPassword,
        sendForgottenPasswordInProgress,
        updateForgotPasswordInProgress,
    } = useForgottenPassword()

    const [step, setStep] = useState<Step>()
    const [email, setEmail] = useState<string>('')
    const [openForgottenPasswordDialog, setOpenForgottenPasswordDialog] = useState<boolean>(false)

    const handleCheckEmail = (values: EmailFormState) => {
        return checkEmail(values)
            .then((notExist) => {
                setEmail(values.email)
                if (notExist) {
                    setStep(Step.SIGN_UP)
                } else {
                    setStep(Step.SIGN_IN)
                }
            })
            .catch((error) => displayError(error))
    }

    const handleSignIn = (values: SignInFormState) => {
        return signInByEmail(values)
            .then(() => {
                suggproClearCache()
            })
            .then(() => {
                enqueueSnackbar(t('views.signIn.success'), {
                    variant: 'success',
                })
            })
            .catch((error) => displayError(error, PageType.SIGN_IN))
    }

    const handleSignUp = (values: SignUpFormState) => {
        return signUp(values)
            .then(() => {
                enqueueSnackbar(t('views.signUp.success'), {
                    variant: 'success',
                })
            })
            .catch((error) => displayError(error))
    }

    const handleCancel = () => {
        setStep(undefined)
    }

    const handleForgotPassword = (email: string) => {
        sendForgottenPasswordCode({
            email: email.trim(),
        })
            .then(() => {
                setEmail(email)
                setOpenForgottenPasswordDialog(true)
            })
            .catch((error) => displayError(error))
    }

    const handleForgotPasswordCode = (values: ForgottenPasswordCodeFormState) => {
        updateForgotPassword({
            email: email.trim(),
            code: values.code,
            newPassword: values.password,
        })
            .then(() => {
                enqueueSnackbar(t('views.forgottenPasswordCode.success'), {
                    variant: 'success',
                })
                setOpenForgottenPasswordDialog(false)
            })
            .catch((error) => displayError(error, PageType.FORGOTTEN_PASSWORD_CODE))
    }

    switch (step) {
        case Step.SIGN_IN:
            return (
                <>
                    <SignInView
                        onForgotPassword={handleForgotPassword}
                        forgottenPasswordInProgress={sendForgottenPasswordInProgress}
                        onSubmit={handleSignIn}
                        onCancel={handleCancel}
                        inProgress={signInInProgress}
                        initialValues={{ email, password: '' }}
                    />

                    <ForgottenPasswordDialog
                        open={openForgottenPasswordDialog}
                        email={email}
                        onClose={() => setOpenForgottenPasswordDialog(false)}
                        inProgress={updateForgotPasswordInProgress}
                        onSubmit={handleForgotPasswordCode}
                        initialValues={{
                            code: '',
                            password: '',
                            passwordConfirmation: '',
                        }}
                    />
                </>
            )
        case Step.SIGN_UP:
            return (
                <SignUpView
                    onSubmit={handleSignUp}
                    onCancel={handleCancel}
                    inProgress={signUpInProgress}
                    initialValues={{
                        email,
                        firstName: '',
                        lastName: '',
                        password: '',
                        passwordConfirmation: '',
                        phoneNumber: '',
                        country: 'FR',
                    }}
                />
            )
    }
    return (
        <HomeView onSubmit={handleCheckEmail} inProgress={checkEmailInProgress} initialValues={{ email }} />
    )
}

export default Home
