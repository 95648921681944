import { takeLatest } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import actions from 'core/store/actions'
import {
    connectAndShareSlateOnFacebook,
    reauthenticateFacebook,
    selectFacebookPageData,
    shareSlateOnFacebookResolver,
} from './sagas/facebook'
import {
    connectAndShareSlateOnGoogle,
    reauthenticateGoogle,
    selectGooglePageData,
    shareSlateOnGoogleResolver,
} from './sagas/google'

function* shareSagas() {
    // Facebook
    yield takeLatest(getType(actions.shareSlateOnFacebookResolver), shareSlateOnFacebookResolver)
    yield takeLatest(getType(actions.shareSlateOnFacebook), connectAndShareSlateOnFacebook)
    yield takeLatest(getType(actions.selectFacebookPageData), selectFacebookPageData)

    yield takeLatest(getType(actions.reauthenticateFacebook), reauthenticateFacebook)

    // Google
    yield takeLatest(getType(actions.shareSlateOnGoogleResolver), shareSlateOnGoogleResolver)
    yield takeLatest(getType(actions.shareSlateOnGoogle), connectAndShareSlateOnGoogle)
    yield takeLatest(getType(actions.selectGooglePageData), selectGooglePageData)

    yield takeLatest(getType(actions.reauthenticateGoogle), reauthenticateGoogle)
}

export default shareSagas
