import React, { useState } from 'react'
import {
    Button,
    CardActions,
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from '@material-ui/core'
import SubmitButton from 'components/common/submitButton'
import DoneIcon from '@material-ui/icons/Done'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Trans } from 'react-i18next'
import { Field } from 'react-final-form'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import WarningIcon from '@material-ui/icons/Warning'
import EditIcon from '@material-ui/icons/Edit'
import _ from 'lodash'
import { Alert } from '@material-ui/lab'
import clsx from 'clsx'
import InstagramIcon from '@material-ui/icons/Instagram'
import FacebookIcon from '@material-ui/icons/Facebook'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: '1.3rem',
        },
        helper: {
            fontSize: '0.9rem',
            fontStyle: 'italic',
        },
        actionsContainer: {
            marginTop: 16,
        },
        actionContainer: {
            width: 'calc(100% - 64px)',
        },
        actionContainerWithoutIcon: {
            flexGrow: 1,
        },
        action: {
            height: 48,
        },
        iconButton: {
            backgroundColor: theme.palette.border.contrastText,
            padding: theme.spacing(0.5),
            minWidth: 0,
            height: 48,
            width: 48,
        },
        icon: {
            fontSize: '2.5rem',
        },
        iconSuccess: {
            color: theme.palette.success.main,
        },
        categoryRow: {
            display: 'block',
            marginBottom: theme.spacing(),
            marginTop: theme.spacing(0.5),
            fontStyle: 'italic',
        },
        linkedPage: {
            overflowWrap: 'break-word',
            borderColor: theme.palette.border.main,
            backgroundColor: theme.palette.border.contrastText,
            borderWidth: 2,
            borderStyle: 'solid',
            borderRadius: 6,
            padding: theme.spacing(),
            width: '100%',
            fontSize: '0.9rem',
            minHeight: 48,
            display: 'inline-flex',
            alignItems: 'center',
        },
        linkedPageContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
        linkedPageActions: {
            paddingRight: 0,
        },
        linkedPageNotActive: {
            width: '100%',
            marginTop: theme.spacing(),
        },
        pageTitle: {
            'display': 'flex',
            'alignItems': 'center',
            '& > svg': {
                marginLeft: theme.spacing(),
            },
        },
        facebook: {
            color: theme.socialColors.facebook,
        },
        instagram: {
            color: theme.socialColors.instagram,
        },
        dialogTitle: {
            color: theme.palette.primary.main,
        },
        underline: {
            textDecoration: 'underline',
        },
        cancel: {
            '& > .MuiTypography-root': {
                textAlign: 'center',
            },
        },
        withAction: {
            paddingRight: 228,
        },
    }),
)

interface Props {
    type: 'facebook' | 'google'
    className?: string
    retrievePages: (setOpen: (open: boolean) => void) => void
    retrievePagesInProgress: boolean
    pages: Array<Page>
    onChange?: () => void
    hideWarning: boolean
    withInstagram?: boolean
}

export interface Page {
    id: string
    name: string
    category: string
    address: string
    url?: string
    withInstagram?: boolean
}

interface ChooseDialogProps {
    open: boolean
    onClose: () => void
    pages: Array<Page>
    onChange: (event: any) => void
    type: 'facebook' | 'google'
    onRetrievePage?: () => void
    loading: boolean
    withInstagram: boolean
    currentPage?: Page
}

const ChooseDialog: React.FC<ChooseDialogProps> = ({
    open,
    onClose,
    pages,
    onChange,
    type,
    onRetrievePage,
    loading,
    withInstagram,
    currentPage,
}) => {
    const classes = useStyles()
    const handleChoosePage = (pageId?: string) => {
        onChange(pageId)
        onClose()
    }
    return (
        <Dialog open={open} onClose={onClose} scroll="body">
            <DialogTitle className={classes.dialogTitle}>
                <Trans i18nKey={'form.' + type + 'Choice'} />
            </DialogTitle>
            <List>
                {pages.map((page, index) => (
                    <React.Fragment key={index}>
                        <Divider component="li" />
                        <ListItem
                            button
                            onClick={() => handleChoosePage(page.id)}
                            selected={currentPage?.id === page.id}
                            classes={{
                                secondaryAction: classes.withAction,
                            }}
                        >
                            <ListItemText
                                primary={
                                    <>
                                        {page.name}{' '}
                                        {type === 'facebook' && withInstagram && page.withInstagram ? (
                                            <>
                                                <FacebookIcon className={classes.facebook} />
                                                <InstagramIcon className={classes.instagram} />
                                            </>
                                        ) : type === 'facebook' ? (
                                            <FacebookIcon className={classes.facebook} />
                                        ) : null}
                                    </>
                                }
                                primaryTypographyProps={{
                                    color: 'textSecondary',
                                }}
                                secondary={
                                    <>
                                        <Typography
                                            className={classes.categoryRow}
                                            component="span"
                                            variant="body2"
                                            color="textPrimary"
                                        >
                                            {page.category}
                                        </Typography>
                                        <Typography component="span" variant="body2" color="textPrimary">
                                            {page.address}
                                        </Typography>
                                    </>
                                }
                                classes={{
                                    primary: classes.pageTitle,
                                }}
                            />
                            <ListItemSecondaryAction>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleChoosePage(page.id)}
                                >
                                    <Trans i18nKey="actions.choosePage" />
                                </Button>
                                {page.url && (
                                    <IconButton component={Link} edge="end" href={page.url} target="_blank">
                                        <OpenInNewIcon color="secondary" />
                                    </IconButton>
                                )}
                            </ListItemSecondaryAction>
                        </ListItem>
                    </React.Fragment>
                ))}
                <Divider component="li" />
                <ListItem button onClick={() => handleChoosePage(undefined)}>
                    <ListItemText
                        classes={{
                            root: classes.cancel,
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleChoosePage(undefined)}
                        >
                            <Trans i18nKey="actions.choosePageCancel" />
                        </Button>
                    </ListItemText>
                </ListItem>
                {onRetrievePage && <Divider component="li" />}
            </List>
            {onRetrievePage && (
                <DialogActions>
                    <SubmitButton
                        variant="outlined"
                        size="large"
                        className={classes.action}
                        onClick={onRetrievePage}
                        loading={loading}
                    >
                        <Trans i18nKey="actions.switchAccount" />
                    </SubmitButton>
                </DialogActions>
            )}
        </Dialog>
    )
}

const SocialNetworkField: React.FC<Props> = ({
    type,
    className,
    retrievePages,
    retrievePagesInProgress,
    pages,
    onChange: onChangeValue,
    hideWarning,
    withInstagram = false,
}) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)

    const getPage = (pageId: string) => {
        return pages.find((page) => page.id === pageId)
    }
    const choosePage = () => {
        setOpen(true)
    }
    return (
        <Field name={type + 'PageId'} type="select">
            {({ input: { onChange, value }, meta }) => {
                const hasError =
                    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
                const handleRetrievePage = () => {
                    retrievePages(setOpen)
                }
                const currentPage = getPage(value as string)
                const hideIcon = _.isEmpty(value) && hideWarning
                return (
                    <FormControl margin="normal" error={hasError} className={className} fullWidth>
                        <Typography
                            color="textSecondary"
                            variant="h1"
                            component="p"
                            className={classes.title}
                        >
                            <Trans
                                i18nKey={
                                    'form.' +
                                    (withInstagram && type === 'facebook' ? 'facebookAndInstagram' : type) +
                                    'Page'
                                }
                            />
                        </Typography>
                        <Typography className={classes.helper}>
                            <Trans
                                i18nKey={
                                    'form.' +
                                    (withInstagram && type === 'facebook' ? 'facebookAndInstagram' : type) +
                                    'Helper'
                                }
                                components={[<span className={classes.underline} />]}
                            />
                        </Typography>
                        <Grid container spacing={2} className={classes.actionsContainer}>
                            <Grid
                                item
                                className={clsx({
                                    [classes.actionContainer]: !hideIcon,
                                    [classes.actionContainerWithoutIcon]: hideIcon,
                                })}
                            >
                                {_.isEmpty(value) && (
                                    <SubmitButton
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        fullWidth
                                        className={classes.action}
                                        onClick={handleRetrievePage}
                                        loading={retrievePagesInProgress}
                                    >
                                        <Trans i18nKey={'actions.' + type + 'Page'} />
                                    </SubmitButton>
                                )}
                                {!_.isEmpty(value) && (
                                    <div className={classes.linkedPageContainer}>
                                        <Typography className={classes.linkedPage}>
                                            <Trans
                                                i18nKey="form.linkedPage"
                                                values={{ page: currentPage?.name }}
                                            />
                                        </Typography>
                                        {!currentPage?.url && (
                                            <Alert severity="warning" className={classes.linkedPageNotActive}>
                                                <Trans i18nKey="form.inactivePage" />
                                            </Alert>
                                        )}
                                        {type === 'facebook' &&
                                            withInstagram &&
                                            currentPage?.withInstagram === false && (
                                                <Alert
                                                    severity="warning"
                                                    className={classes.linkedPageNotActive}
                                                    action={
                                                        <SubmitButton
                                                            variant="outlined"
                                                            color="primary"
                                                            onClick={handleRetrievePage}
                                                            loading={retrievePagesInProgress}
                                                        >
                                                            <Trans i18nKey="actions.linkAgain" />
                                                        </SubmitButton>
                                                    }
                                                >
                                                    <Trans i18nKey="form.pageWithoutInstagram" />
                                                </Alert>
                                            )}
                                        <CardActions className={classes.linkedPageActions}>
                                            {currentPage?.url && (
                                                <Button
                                                    href={currentPage?.url}
                                                    variant="outlined"
                                                    target="_blank"
                                                    startIcon={<OpenInNewIcon />}
                                                >
                                                    <Trans i18nKey="actions.visitPage" />
                                                </Button>
                                            )}
                                            <Button
                                                variant="outlined"
                                                startIcon={<EditIcon />}
                                                onClick={choosePage}
                                            >
                                                <Trans i18nKey="actions.change" />
                                            </Button>
                                        </CardActions>
                                    </div>
                                )}
                            </Grid>
                            {!hideIcon && (
                                <Grid item>
                                    <Button variant="contained" disabled className={classes.iconButton}>
                                        {_.isEmpty(value) ? (
                                            <WarningIcon color="primary" className={classes.icon} />
                                        ) : (
                                            <DoneIcon className={clsx(classes.icon, classes.iconSuccess)} />
                                        )}
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                        <ChooseDialog
                            open={open}
                            onClose={() => setOpen(false)}
                            currentPage={currentPage}
                            pages={pages}
                            onRetrievePage={!_.isEmpty(value) ? handleRetrievePage : undefined}
                            loading={retrievePagesInProgress}
                            onChange={(event) => {
                                onChange(event)
                                if (onChangeValue) {
                                    onChangeValue()
                                }
                            }}
                            type={type}
                            withInstagram={withInstagram}
                        />
                    </FormControl>
                )
            }}
        </Field>
    )
}

export default SocialNetworkField
