import { defineAbility } from '@casl/ability'
import {
    actions as suggProActions,
    FacebookPagesResponse,
    GooglePagesResponse,
    User,
} from '@sugg-gestion/ubidreams-react-suggpro'
import moment from 'moment'
import { SnackbarKey } from 'notistack'
import { Reducer } from 'redux'
import { getType } from 'typesafe-actions'
import actions from '../actions'
import { AlertContent, AppState, FacebookUser, GoogleUser, SnackbarNotification } from './types'

const defaultAbility = defineAbility(() => {})

const initialState: AppState = {
    locale: 'en-US',
    timezone: 'Europe/Paris',
    notifications: [],
    ability: defaultAbility,
    facebookInitialized: false,
    googleInitialized: false,
    hubSpotFormInitialized: false,
    statisticsStartDate: moment().hour(0).minute(0).second(0).millisecond(0).add(-2, 'weeks').toDate(),
    statisticsEndDate: moment().hour(0).minute(0).second(0).millisecond(0).toDate(),
    lostInternetConnexion: false,
}

const reducer: Reducer<AppState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(suggProActions.setUser): {
            const user: User = action.payload
            const ability = defineAbility((can) => {
                can('manage', 'user')
                if (user.registerLevel.name === 'DONE') {
                    can('manage', 'slate')
                    can('manage', 'establishment')
                    can('manage', 'menu')
                    can('manage', 'reminderNotebook')
                    can('manage', 'recruitment')
                    can('manage', 'statistics')
                    can('manage', 'events')
                } else {
                    const establishments = user.establishments
                    establishments.forEach((establishment) => {
                        if (establishment.status) {
                            switch (establishment.status.name) {
                                case 'DONE':
                                    can('manage', 'slate')
                                    can('manage', 'establishment')
                                    can('manage', 'menu')
                                    can('manage', 'reminderNotebook')
                                    can('manage', 'recruitment')
                                    can('manage', 'statistics')
                                    can('manage', 'events')
                                    break
                            }
                        }
                    })
                }
                user.permissions.forEach((permission) => {
                    can(permission)
                })
            })
            return {
                ...state,
                ability,
            }
        }
        case getType(actions.setLocale): {
            const locale = action.payload
            return {
                ...state,
                locale,
            }
        }
        case getType(actions.setTimezone): {
            const timezone = action.payload
            return {
                ...state,
                timezone,
            }
        }
        case getType(actions.setGoogleAuth): {
            const googleAuth: gapi.auth2.GoogleAuth = action.payload
            return {
                ...state,
                googleAuth,
            }
        }
        case getType(actions.setGoogleUser): {
            const googleUser: GoogleUser = action.payload
            return {
                ...state,
                googleUser,
            }
        }
        case getType(actions.setGooglePages): {
            const googlePages: GooglePagesResponse = action.payload
            return {
                ...state,
                googlePages,
            }
        }
        case getType(actions.setFacebookUser): {
            const facebookUser: FacebookUser = action.payload
            return {
                ...state,
                facebookUser,
            }
        }
        case getType(actions.setFacebookPages): {
            const facebookPages: FacebookPagesResponse | undefined = action.payload
            return {
                ...state,
                facebookPages,
            }
        }
        case getType(actions.enqueueSnackbar): {
            const notification: SnackbarNotification = action.payload
            const key = notification.options && notification.options.key
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        // @ts-ignore
                        key: key || new Date().getTime() + Math.random(),
                        ...notification,
                    },
                ],
            }
        }
        case getType(actions.closeSnackbar): {
            const key: SnackbarKey = action.payload
            const dismissAll = key !== undefined
            return {
                ...state,
                notifications: state.notifications.map((notification) =>
                    dismissAll || notification.key === key
                        ? { ...notification, dismissed: true }
                        : { ...notification },
                ),
            }
        }
        case getType(actions.removeSnackbar): {
            const key: SnackbarKey = action.payload
            return {
                ...state,
                notifications: state.notifications.filter((notification) => notification.key !== key),
            }
        }
        case getType(actions.alert): {
            const alert: AlertContent | undefined = action.payload
            return {
                ...state,
                alert,
            }
        }
        case getType(actions.setFacebookInitialized): {
            const facebookInitialized = action.payload
            return {
                ...state,
                facebookInitialized,
            }
        }
        case getType(actions.setGoogleInitialized): {
            const googleInitialized = action.payload
            return {
                ...state,
                googleInitialized,
            }
        }
        case getType(actions.setHubSpotFormInitialized): {
            const hubSpotFormInitialized = action.payload
            return {
                ...state,
                hubSpotFormInitialized,
            }
        }
        case getType(actions.setStatisticsStartDate): {
            const statisticsStartDate = action.payload
            return {
                ...state,
                statisticsStartDate,
            }
        }
        case getType(actions.setStatisticsEndDate): {
            const statisticsEndDate = action.payload
            return {
                ...state,
                statisticsEndDate,
            }
        }
        case getType(actions.setLostInternetConnexion): {
            const lostInternetConnexion = action.payload
            return {
                ...state,
                lostInternetConnexion,
            }
        }
        // case getType(actions.setGraphApiClient): {
        //     const graphApiClient = action.payload
        //     return {
        //         ...state,
        //         graphApiClient,
        //     }
        // }
        default:
            return state
    }
}

export { reducer as appReducer }
