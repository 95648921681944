import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
    createStyles({
        card: {
            // 'borderColor': '#2696E0',
            // 'borderWidth': 4,
            // 'borderStyle': 'solid',
            'overflow': 'initial',
            'position': 'relative',
            '$annual &': {
                overflow: 'hidden',
                borderStyle: 'none',
                marginBottom: -29,
            },
            '& .MuiCardContent-root': {
                padding: theme.spacing(4, 1),
            },
        },
        essential: {
            backgroundColor: '#DCF3FE',
        },
        booster: {
            backgroundColor: '#A3E7FF',
        },
        boosterFht: {
            backgroundColor: '#FFEFCC',
        },
        boosterFhtTitle: {
            color: theme.palette.primary.main,
        },
        boosterFhtText: {
            'color': theme.palette.primary.main,
            'fontWeight': theme.fontsWeight.bold,
            'textAlign': 'right',
            '&.MuiTypography-root': {
                fontSize: '1.1rem',
            },
        },
        boosterSpeText: {
            'paddingLeft': theme.spacing(0.5),
            'fontWeight': theme.fontsWeight.bold,
            'textAlign': 'center',
            '&.MuiTypography-root': {
                fontSize: '1rem',
            },
        },
        annual: {},
        oldPlan: {},
        annualTitle: {
            textTransform: 'uppercase',
            fontSize: '1.3rem',
            textAlign: 'center',
            fontWeight: theme.fontsWeight.bold,
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(5),
        },
        titleContainer: {},
        percent: {
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            fontWeight: theme.fontsWeight.semiBold,
            fontSize: '3rem',
            transform: 'rotate(45deg) translate(55px, -90px)',
            padding: theme.spacing(6, 10, 0, 10),
        },
        title: {
            fontWeight: theme.fontsWeight.semiBold,
            textAlign: 'center',
            fontSize: '1.6rem',
            padding: theme.spacing(0, 1),
            verticalAlign: 'middle',
            display: 'inline-flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        titleFixedHeight: {
            display: 'flex',
            height: 180,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        subtitle: {
            'textAlign': 'center',
            'lineHeight': 'initial',
            '& .MuiTypography-root': {
                color: theme.palette.text.primary,
                fontSize: '0.8rem',
            },
        },
        text: {
            'position': 'absolute',
            'top': theme.spacing(0.5),
            'right': theme.spacing(),
            '& .MuiTypography-root': {
                fontSize: '0.9rem',
                paddingLeft: theme.spacing(0.5),
                textAlign: 'center',
            },
        },
        price: {
            textAlign: 'center',
            fontSize: '0.8rem',
            textTransform: 'none',
            height: 128,
        },
        priceIncrease: {
            fontSize: '3rem',
        },
        priceBarred: {
            // fontSize: '2rem',
            textDecoration: 'line-through',
        },
        priceTax: {
            textAlign: 'center',
        },
        priceAnnualDescription: {
            paddingTop: theme.spacing(2),
            fontSize: '0.86rem',
            fontWeight: theme.fontsWeight.semiBold,
        },
        priceAnnualDescriptionStrong: {
            fontSize: '0.9rem',
            fontWeight: theme.fontsWeight.bold,
        },
        duration: {
            fontWeight: 'normal',
            textTransform: 'none',
        },
        action: {
            'marginTop': theme.spacing(1),
            'marginBottom': theme.spacing(-6),
            'display': 'flex',
            'justifyContent': 'center',
            'alignItems': 'center',
            '& .MuiButton-root': {
                boxShadow:
                    '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
            },
            '& .MuiButton-label': {
                textTransform: 'none',
                fontSize: '1.2rem',
            },
            '& .MuiButton-containedPrimary': {
                backgroundColor: '#2696E0',
            },
            '& .MuiButton-outlinedPrimary': {
                backgroundColor: '#FFF',
            },
            '& .MuiButton-outlinedPrimary:hover': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            },
        },
        advantage: {
            '& .MuiTypography-body1': {
                textAlign: 'left',
                fontSize: '0.87rem',
            },
        },
        animation: {
            'height': 140,
            'padding': theme.spacing(0, 2),
            '$oldPlan &': {
                paddingRight: theme.spacing(8),
            },
        },
        image: {
            'height': 140,
            'padding': theme.spacing(0, 2),
            '$oldPlan &': {
                paddingRight: theme.spacing(8),
            },
            'display': 'block',
            'margin': 'auto',
        },
        success: {
            color: theme.palette.success.main,
        },
        primary: {
            color: theme.palette.primary.main,
        },
        check: {
            minWidth: 42,
        },
        greyIcon: {
            filter: 'grayscale(100%) contrast(100%)',
        },
    }),
)
