import React from 'react'
import { Field, FormSpy } from 'react-final-form'
import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
} from '@material-ui/core'
import TextField from './textField'
import { findIndex } from 'lodash'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'

interface Props {
    name: string
    label?: string | React.ReactNode
    placeholder?: string
    values: Array<RadioValue>
    includeOther?: boolean
    column?: 1 | 2
    required?: boolean
}

export interface RadioValue {
    label: React.ReactNode
    value: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            flexGrow: 1,
        },
        checkbox: {
            'width': '100%',
            'border': '1px solid #EBE6EF',
            'borderRadius': theme.spacing(0.5),
            'padding': theme.spacing(0.5),
            'margin': 0,
            '& .MuiFormControlLabel-label': {
                padding: theme.spacing(0, 0, 0, 2),
                fontWeight: theme.fontsWeight.semiBold,
                flexGrow: 1,
            },
        },
        checkboxSelected: {
            backgroundColor: '#F0F0F0',
        },
        post: {
            marginBottom: theme.spacing(3),
        },

        item: {
            display: 'flex',
            flexDirection: 'column',
        },
        hidden: {
            visibility: 'hidden',
        },
    }),
)

const RadioGroupField: React.FC<Props> = ({
    name,
    label,
    values,
    includeOther = false,
    placeholder,
    column = 1,
    required = false,
}) => {
    const classes = useStyles()
    let itemSize: 12 | 6 = 12
    if (column === 2) {
        itemSize = 6
    }
    return (
        <FormSpy subscription={{ errors: true, touched: true }}>
            {(props) => {
                const hasError =
                    props.errors !== undefined &&
                    props.errors[name] &&
                    props.touched !== undefined &&
                    props.touched[name]
                return (
                    <FormControl
                        component="fieldset"
                        fullWidth
                        margin="normal"
                        required={required}
                        error={hasError}
                    >
                        <div className={classes.post}>
                            <FormLabel component="legend">{label}</FormLabel>
                        </div>
                        {values.length > 0 ? (
                            <RadioGroup>
                                <Grid container spacing={2}>
                                    {values.map((radioValue, index) => (
                                        <Grid item xs={itemSize} className={classes.item} key={index}>
                                            <FormSpy subscription={{ values: true }}>
                                                {(props) => (
                                                    <FormControlLabel
                                                        className={clsx(classes.checkbox, {
                                                            [classes.checkboxSelected]:
                                                                props.values[name] === radioValue.value,
                                                        })}
                                                        control={
                                                            <Field
                                                                name={name}
                                                                type="radio"
                                                                value={radioValue.value}
                                                            >
                                                                {({ input: { name, onChange, checked } }) => (
                                                                    <Radio
                                                                        name={name}
                                                                        value={radioValue.value}
                                                                        color="primary"
                                                                        checked={checked}
                                                                        onChange={onChange}
                                                                        className={
                                                                            !checked
                                                                                ? classes.hidden
                                                                                : undefined
                                                                        }
                                                                    />
                                                                )}
                                                            </Field>
                                                        }
                                                        label={radioValue.label}
                                                        labelPlacement={'start'}
                                                    />
                                                )}
                                            </FormSpy>
                                        </Grid>
                                    ))}
                                    {includeOther && (
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                classes={{
                                                    label: classes.label,
                                                }}
                                                control={
                                                    <FormSpy subscription={{ values: true }}>
                                                        {(props) => (
                                                            <Field name={name} type="radio">
                                                                {({ input: { name, onChange } }) => (
                                                                    <Radio
                                                                        name={name}
                                                                        value={props.values[name]}
                                                                        color="primary"
                                                                        checked={
                                                                            findIndex(values, {
                                                                                value: props.values[name],
                                                                            }) === -1
                                                                        }
                                                                        onChange={() =>
                                                                            onChange(
                                                                                props.values[name + 'Other'],
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                            </Field>
                                                        )}
                                                    </FormSpy>
                                                }
                                                label={
                                                    <Field name={name} type="radio">
                                                        {({ input: { name, onChange } }) => (
                                                            <TextField
                                                                name={name + 'Other'}
                                                                label={undefined}
                                                                onChange={onChange}
                                                                placeholder={placeholder}
                                                            />
                                                        )}
                                                    </Field>
                                                }
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                {hasError && (
                                    <FormHelperText error={hasError}>
                                        {props.errors && props.errors[name]}
                                    </FormHelperText>
                                )}
                            </RadioGroup>
                        ) : (
                            <TextField name={name} label={placeholder} />
                        )}
                    </FormControl>
                )
            }}
        </FormSpy>
    )
}

export default RadioGroupField
