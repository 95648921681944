import React from 'react'
import { CardContent, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import FlatCard from '../../../common/flatCard'
import { Trans, useTranslation } from 'react-i18next'
import {
    Area,
    AreaChart,
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import moment from 'moment'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import FacebookSquareIcon from 'components/icons/facebookSquareIcon'
import InstagramSquareIcon from 'components/icons/instagramSquareIcon'
import GmbIcon from 'components/icons/gmbIcon'
import TwitterSquareIcon from 'components/icons/twitterSquareIcon'
import { SocialMedia, useStatisticsData } from 'core/services/statistics/useStatisticsData'
import { NewsProperty, MenuBoardProperty, Statistics } from '@sugg-gestion/ubidreams-react-suggpro'
import { useStyles } from '../styles.css'

interface Props {
    statistics: Array<Statistics<MenuBoardProperty | NewsProperty>>
    statisticType: 'news' | 'menu'
    type: 'views' | 'likes' | 'comments' | 'shares'
    per: 'perDay' | 'perPost'
    socialMedias: Array<SocialMedia>
    startDate: Date
    endDate: Date
    setSocialMedias: (socialMedias: Array<SocialMedia>) => void
    isFacebookLinked: boolean
    isInstagramLinked: boolean
    isGoogleLinked: boolean
    isTwitterLinked: boolean
}

const StatisticView: React.FC<Props> = ({
    statistics,
    type,
    per,
    socialMedias,
    startDate,
    endDate,
    setSocialMedias,
    isFacebookLinked,
    isInstagramLinked,
    isGoogleLinked,
    isTwitterLinked,
    statisticType,
}) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const classes = useStyles()

    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

    const {
        getStrokeColor,
        getFillColor,
        total,
        totalPieDatas,
        lastMenuBoardTotalPieDatas,
        lastMenuBoardTotal,
        convertData,
    } = useStatisticsData(statistics, type, per, socialMedias)

    const getArea = (socialMedia: SocialMedia) => {
        let area: React.ReactNode = (
            <Area
                key={socialMedia}
                type="monotone"
                dataKey={socialMedia}
                stackId="1"
                stroke={getStrokeColor(socialMedia)}
                fill={getFillColor(socialMedia)}
                name={t('form.' + socialMedia)}
            />
        )
        if (socialMedias.indexOf(socialMedia) === -1) {
            area = null
        }
        return area
    }
    const getBar = (socialMedia: SocialMedia) => {
        let bar: React.ReactNode = (
            <Bar
                key={socialMedia}
                type="monotone"
                dataKey={socialMedia}
                stroke={getStrokeColor(socialMedia)}
                fill={getFillColor(socialMedia)}
                name={t('form.' + socialMedia)}
            />
        )
        if (socialMedias.indexOf(socialMedia) === -1) {
            bar = null
        }
        return bar
    }

    const getDisplay = (socialMedia: SocialMedia) => {
        switch (per) {
            case 'perDay':
                return getArea(socialMedia)
            case 'perPost':
                return getBar(socialMedia)
        }
    }

    const chartArea = () => {
        const facebookDisplay = getDisplay(SocialMedia.FACEBOOK)
        const instagramDisplay = getDisplay(SocialMedia.INSTAGRAM)
        const googlemybusinessDisplay = getDisplay(SocialMedia.GOOGLE_MY_BUSINESS)
        const twitterDisplay = getDisplay(SocialMedia.TWITTER)
        switch (type) {
            case 'views':
                return [facebookDisplay, instagramDisplay, googlemybusinessDisplay, twitterDisplay]
            case 'likes':
                return [facebookDisplay, instagramDisplay, twitterDisplay]
            case 'comments':
                return [facebookDisplay, instagramDisplay, twitterDisplay]
            case 'shares':
                return [facebookDisplay, twitterDisplay]
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FlatCard>
                            <CardContent>
                                <Typography className={classes.totalTitle}>
                                    <Trans i18nKey="views.statistics.total" values={{ startDate, endDate }} />
                                </Typography>
                            </CardContent>
                        </FlatCard>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FlatCard>
                                    <CardContent>
                                        <Typography className={classes.totalLabel}>
                                            <Trans
                                                i18nKey="views.statistics.totalNumber"
                                                values={{
                                                    item: t('views.statistics.' + type).toLowerCase(),
                                                }}
                                            />
                                        </Typography>
                                        <Typography className={classes.totalValue}>
                                            {(total[SocialMedia.FACEBOOK] ?? 0) +
                                                (total[SocialMedia.INSTAGRAM] ?? 0) +
                                                (total[SocialMedia.GOOGLE_MY_BUSINESS] ?? 0) +
                                                (total[SocialMedia.TWITTER] ?? 0)}
                                        </Typography>
                                        <Typography className={classes.totalHelper}>
                                            <Trans i18nKey="views.statistics.duringPeriod" />
                                        </Typography>
                                    </CardContent>
                                </FlatCard>
                            </Grid>
                            <Grid item xs={12}>
                                <FlatCard>
                                    <CardContent>
                                        <Typography className={classes.totalLabel}>
                                            <Trans
                                                i18nKey="views.statistics.totalNumber"
                                                values={{
                                                    item: t('views.statistics.' + statisticType + '.title'),
                                                }}
                                            />
                                        </Typography>
                                        <Typography className={classes.totalValue}>
                                            {total.numberOfMenuBoard}
                                        </Typography>
                                        <Typography className={classes.totalHelper}>
                                            <Trans i18nKey="views.statistics.duringPeriod" />
                                        </Typography>
                                    </CardContent>
                                </FlatCard>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FlatCard className={classes.pieChartContainer}>
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie
                                        dataKey="value"
                                        isAnimationActive
                                        data={totalPieDatas}
                                        cx="50%"
                                        cy="50%"
                                    >
                                        {totalPieDatas.map((entries) => (
                                            <Cell
                                                key={entries.socialMedia}
                                                fill={getStrokeColor(entries.socialMedia)}
                                            />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </FlatCard>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
                <Grid container spacing={2} className={classes.alignHeight}>
                    <Grid item xs={12}>
                        <FlatCard>
                            <CardContent>
                                <Typography className={classes.totalTitle}>
                                    <Trans i18nKey={'views.statistics.' + statisticType + '.last'} />
                                </Typography>
                            </CardContent>
                        </FlatCard>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FlatCard>
                                    <CardContent>
                                        <Typography className={classes.totalLabel}>
                                            <Trans
                                                i18nKey={'views.statistics.' + statisticType + '.lastTotal'}
                                                values={{
                                                    item: t('views.statistics.' + type),
                                                }}
                                            />
                                        </Typography>
                                        <Typography className={classes.totalValue}>
                                            {(lastMenuBoardTotal[SocialMedia.FACEBOOK] ?? 0) +
                                                (lastMenuBoardTotal[SocialMedia.INSTAGRAM] ?? 0) +
                                                (lastMenuBoardTotal[SocialMedia.GOOGLE_MY_BUSINESS] ?? 0) +
                                                (lastMenuBoardTotal[SocialMedia.TWITTER] ?? 0)}
                                        </Typography>
                                        <Typography className={classes.totalHelper}>
                                            <Trans i18nKey="views.statistics.duringPeriod" />
                                        </Typography>
                                    </CardContent>
                                </FlatCard>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FlatCard className={classes.pieChartContainer}>
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie
                                        dataKey="value"
                                        isAnimationActive
                                        data={lastMenuBoardTotalPieDatas}
                                        cx="50%"
                                        cy="50%"
                                        // outerRadius={80}
                                        // fill={theme.palette.primary.main}
                                        // label={renderCustomizedLabel}
                                        // labelLine={false}
                                    >
                                        {lastMenuBoardTotalPieDatas.map((entries) => (
                                            <Cell
                                                key={entries.socialMedia}
                                                fill={getStrokeColor(entries.socialMedia)}
                                            />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </FlatCard>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.graphParent}>
                    <FlatCard className={classes.graphContainer}>
                        <CardContent className={classes.pieChartContainer}>
                            <Typography className={classes.graphTitle}>
                                <Trans
                                    i18nKey="views.statistics.graphTitle"
                                    values={{ startDate, endDate }}
                                />
                            </Typography>
                            <ResponsiveContainer>
                                {per === 'perDay' ? (
                                    <AreaChart
                                        data={convertData(statistics)}
                                        margin={{
                                            left: isMobile ? -36 : 0,
                                            // bottom: isMobile ? -48 : 0,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="unixDate"
                                            scale="time"
                                            type="number"
                                            domain={['dataMin', () => moment(endDate).valueOf()]}
                                            tickFormatter={(tick) => moment(tick).format('DD/MM')}
                                        />
                                        <YAxis />
                                        <Tooltip
                                            labelFormatter={(label, _) => moment(label).format('DD/MM')}
                                        />
                                        <Legend
                                            wrapperStyle={{
                                                left: isMobile ? -12 : 0,
                                            }}
                                        />
                                        {chartArea()}
                                    </AreaChart>
                                ) : (
                                    <BarChart
                                        data={convertData(statistics)}
                                        margin={{
                                            left: isMobile ? -36 : 0,
                                            // bottom: isMobile ? -48 : 0,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="unixDate"
                                            tickFormatter={(tick) =>
                                                t('views.statistics.postName', {
                                                    date: moment(tick).toDate(),
                                                })
                                            }
                                        />
                                        <YAxis />
                                        <Tooltip
                                            labelFormatter={(label, _) =>
                                                t('views.statistics.postName', {
                                                    date: moment(label).toDate(),
                                                })
                                            }
                                        />
                                        <Legend
                                            wrapperStyle={{
                                                left: isMobile ? -12 : 0,
                                            }}
                                        />
                                        {chartArea()}
                                    </BarChart>
                                )}
                            </ResponsiveContainer>
                        </CardContent>
                    </FlatCard>
                    <div className={classes.socialContainer}>
                        <ToggleButtonGroup
                            orientation={isMobile ? 'horizontal' : 'vertical'}
                            value={socialMedias}
                            onChange={(_, value) => setSocialMedias(value)}
                        >
                            <ToggleButton
                                className={classes.iconButton}
                                value={SocialMedia.FACEBOOK}
                                disabled={!isFacebookLinked}
                            >
                                <FacebookSquareIcon className={classes.icon} />
                            </ToggleButton>
                            <ToggleButton
                                className={classes.iconButton}
                                value={SocialMedia.INSTAGRAM}
                                disabled={!isInstagramLinked || type === 'shares'}
                            >
                                <InstagramSquareIcon className={classes.icon} />
                            </ToggleButton>
                            <ToggleButton
                                className={classes.iconButton}
                                value={SocialMedia.GOOGLE_MY_BUSINESS}
                                disabled={
                                    !isGoogleLinked ||
                                    type === 'likes' ||
                                    type === 'shares' ||
                                    type === 'comments'
                                }
                            >
                                <GmbIcon className={classes.icon} />
                            </ToggleButton>
                            <ToggleButton
                                className={classes.iconButton}
                                value={SocialMedia.TWITTER}
                                disabled={!isTwitterLinked}
                            >
                                <TwitterSquareIcon className={classes.icon} />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default StatisticView
