import React from 'react'
import { Link, ListItem, ListItemIcon, Switch } from '@material-ui/core'
import SmartphoneIcon from '@material-ui/icons/Smartphone'
import ListItemText from '@material-ui/core/ListItemText'
import logoSugg1144 from 'assets/logo/sugg1144.png'
import LanguageIcon from '@material-ui/icons/Language'
import { useFormsStyles } from 'components/forms/forms.css'
import { GroupFacebookModel } from '@sugg-gestion/ubidreams-react-suggpro'
import { Trans, useTranslation } from 'react-i18next'

interface Props {
    menuLink: string
    establishmentsGroups?: Array<GroupFacebookModel>
}

const ExtraSharing: React.FC<Props> = ({ menuLink, establishmentsGroups }) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()
    return (
        <>
            <ListItem divider>
                <ListItemIcon>
                    <Switch color="primary" edge="start" checked={true} disableRipple />
                </ListItemIcon>
                <ListItemIcon
                    classes={{
                        root: formsClasses.shareIcon,
                    }}
                >
                    <SmartphoneIcon />
                </ListItemIcon>
                <ListItemText>
                    <Link href="https://www.sugg1144.com/installer-maintenant/" target="_blank">
                        <Trans i18nKey="views.slate.share.app" />
                    </Link>
                </ListItemText>
            </ListItem>
            {establishmentsGroups?.map((group) => (
                <ListItem divider key={group.facebookGroupId}>
                    <ListItemIcon>
                        <Switch color="primary" edge="start" checked={true} disableRipple />
                    </ListItemIcon>
                    <ListItemIcon
                        classes={{
                            root: formsClasses.shareIcon,
                        }}
                    >
                        <img
                            src={logoSugg1144}
                            alt={t('views.slate.share.group', { name: group.facebookGroup.name })}
                        />
                    </ListItemIcon>
                    <ListItemText>
                        <Link href={'https://www.facebook.com/' + group.facebookGroupId} target="_blank">
                            <Trans
                                i18nKey="views.slate.share.group"
                                values={{ name: group.facebookGroup.name }}
                            />
                        </Link>
                    </ListItemText>
                </ListItem>
            ))}
            <ListItem>
                <ListItemIcon>
                    <Switch color="primary" edge="start" checked={true} disableRipple />
                </ListItemIcon>
                <ListItemIcon
                    classes={{
                        root: formsClasses.shareIcon,
                    }}
                >
                    <LanguageIcon />
                </ListItemIcon>
                <ListItemText>
                    <Link href={menuLink} target="_blank">
                        <Trans i18nKey="views.slate.share.website" />
                    </Link>
                </ListItemText>
            </ListItem>
        </>
    )
}

export default ExtraSharing
