import { HTML5toTouch } from 'rdndmb-html5-to-touch'
import React, { ComponentType } from 'react'
import { DndProvider } from 'react-dnd-multi-backend'

const withDnD = <T,>(ComposedComponent: ComponentType<T>) => {
    const WithDnD: React.FC<T> = (props) => {
        return (
            <DndProvider options={HTML5toTouch}>
                <ComposedComponent {...props} />
            </DndProvider>
        )
    }
    return WithDnD
}

export default withDnD
