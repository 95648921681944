import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            width: '100%',
        },
        loadingView: {
            minHeight: 300,
            position: 'relative',
        },
        completed: {
            '& .MuiLinearProgress-bar': {
                backgroundColor: theme.palette.success.main,
            },
        },
        failed: {
            '& .MuiLinearProgress-bar': {
                backgroundColor: theme.palette.error.main,
            },
        },
        done: {
            color: theme.palette.success.main,
        },
        error: {
            color: theme.palette.error.main,
        },
        alert: {
            marginBottom: theme.spacing(1),
        },
        row: {
            marginBottom: theme.spacing(2),
        },
    }),
)
