import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            alignItems: 'center',
            justifyContent: 'space-evenly',
        },
        icon: {
            width: theme.spacing(16),
            height: theme.spacing(16),
            [theme.breakpoints.down('xs')]: {
                width: theme.spacing(12),
                height: theme.spacing(12),
            },
        },
        title: {
            fontSize: '4rem',
            textAlign: 'center',
            [theme.breakpoints.down('xs')]: {
                fontSize: '3rem',
            },
        },
        subtitle: {
            fontSize: '2rem',
            textAlign: 'center',
            fontWeight: theme.fontsWeight.light,
            [theme.breakpoints.down('xs')]: {
                fontSize: '1.4rem',
            },
        },
        button: {
            marginTop: theme.spacing(2),
        },
    }),
)
