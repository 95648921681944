import React from 'react'
import { useFormsStyles } from '../forms.css'
import { Form } from 'react-final-form'
import { SubmitButtonProps } from 'components/common/submitButton'
import { Trans } from 'react-i18next'
import { Grid } from '@material-ui/core'
import SocialNetworkField, { Page } from '../components/socialNetworkField'
import TwitterField from '../components/twitterField'
import { TwitterInfoResponse } from '@sugg-gestion/ubidreams-react-suggpro'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: FacebookGoogleFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: FacebookGoogleFormState
    googlePages: Array<Page>
    retrieveGooglePages: (setOpen: (open: boolean) => void) => void
    retrieveGooglePagesInProgress: boolean
    facebookPages: Array<Page>
    retrieveFacebookPages: (setOpen: (open: boolean) => void, withInstagram: boolean) => void
    retrieveFacebookPagesInProgress: boolean
    fetchTwitterData: () => Promise<TwitterInfoResponse>
    fetchTwitterDataInProgress: boolean
    unlinkTwitter: () => void
    hideWarning: boolean
    withInstagram: boolean
    withTwitter: boolean
}

export interface FacebookGoogleFormState {
    googleSync: Array<boolean>
    facebookSync: Array<boolean>
    googlePageId?: string
    facebookPageId?: string
    twitterAccount?: TwitterInfoResponse
}

const FacebookGoogleForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
    googlePages,
    retrieveGooglePages,
    retrieveGooglePagesInProgress,
    facebookPages,
    retrieveFacebookPages,
    retrieveFacebookPagesInProgress,
    fetchTwitterData,
    fetchTwitterDataInProgress,
    hideWarning,
    withInstagram,
    withTwitter,
    unlinkTwitter,
}) => {
    const formsClasses = useFormsStyles()

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <div className={formsClasses.gridSpacingContainer8}>
                            <Grid container spacing={8} className={formsClasses.removeTopBottomPadding}>
                                <Grid item xs={12} md={6}>
                                    <SocialNetworkField
                                        type="google"
                                        retrievePages={retrieveGooglePages}
                                        retrievePagesInProgress={retrieveGooglePagesInProgress}
                                        pages={googlePages}
                                        hideWarning={hideWarning}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <SocialNetworkField
                                        type="facebook"
                                        retrievePages={(setOpen) =>
                                            retrieveFacebookPages(setOpen, withInstagram)
                                        }
                                        retrievePagesInProgress={retrieveFacebookPagesInProgress}
                                        pages={facebookPages}
                                        hideWarning={hideWarning}
                                        withInstagram={withInstagram}
                                    />
                                </Grid>
                            </Grid>
                            {withTwitter && (
                                <Grid container spacing={8} className={formsClasses.removeTopBottomPadding}>
                                    <Grid item xs={12} md={6}>
                                        <TwitterField
                                            name="twitterAccount"
                                            hideWarning={hideWarning}
                                            fetchTwitterData={fetchTwitterData}
                                            fetchTwitterDataInProgress={fetchTwitterDataInProgress}
                                            unlinkTwitter={unlinkTwitter}
                                            unlinkInProgress={inProgress}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </div>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                className: formsClasses.submit,
                                children: <Trans i18nKey="actions.next" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}
export default FacebookGoogleForm
