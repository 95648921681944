import { GoogleOfflineCodeExpired, useEstablishmentUpdate } from '@sugg-gestion/ubidreams-react-suggpro'
import GoogleCallbackView from 'components/views/account/googleCallbackView'
import withSignUpLayout, { WithSignUpLayout } from 'containers/hoc/withSignUpLayout'
import {
    GmbAccountSelect,
    GmbState,
    GoogleMyBusinessPageExist,
    MultipleGoogleMyBusinessAccounts,
    useGoogleMyBusinessPages,
} from 'core/services/google/useGoogleMyBusinessPages'
import { useQuery } from 'core/services/router/useQuery'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import { ApplicationState } from 'core/store/reducers'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

interface Props extends WithSignUpLayout {}

const GoogleCallback: React.FC<Props> = ({ establishment }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const history = useHistory()

    const query = useQuery()

    const code = query.get('code')
    const state: GmbState = JSON.parse(query.get('state') ?? '')

    const {
        googleCallback,
        inProgress,
        fetchGoogleMyBusinessPagesAction,
        confirmMultipleGoogleMyBusinessAccountsSelection,
    } = useGoogleMyBusinessPages(establishment)
    const { updateEstablishment, inProgress: updateEstablishmentInProgress } = useEstablishmentUpdate()
    const { displayError } = useApiErrors()

    const { googlePages } = useSelector(({ app }: ApplicationState) => ({ googlePages: app.googlePages }))

    const [hasRun, setHasRun] = useState(false)
    const [gmbAccounts, setGmbAccounts] = useState<GmbAccountSelect | null>(null)
    const [shouldRetry, setShouldRetry] = useState(false)

    const returnToPreviousPage = useCallback(() => {
        switch (state.previousAction) {
            case 'sign-up':
                history.push(
                    '/establishment/:id/add/facebook-google'.replace(':id', establishment.id.toString()),
                )
                return
            case 'edit':
                history.push('/establishment/:id/edit'.replace(':id', establishment.id.toString()))
                return
            case 'sign':
                history.push('/account/sign')
                return
        }
    }, [establishment.id, history, state.previousAction])

    useEffect(() => {
        if (!hasRun) {
            setHasRun(true)
            if (code && state) {
                googleCallback(code, state).catch((error) => {
                    if (error instanceof MultipleGoogleMyBusinessAccounts) {
                        setGmbAccounts({
                            setOpen: () => {},
                            accounts: error.accounts,
                        })
                    } else if (error instanceof GoogleOfflineCodeExpired) {
                        setShouldRetry(true)
                    } else if (error instanceof GoogleMyBusinessPageExist) {
                        returnToPreviousPage()
                    } else {
                        displayError(error)
                    }
                })
            }
        }
    }, [code, displayError, googleCallback, hasRun, query, returnToPreviousPage, state])

    const handleRetrievePage = (retry: boolean) => {
        if (state) {
            fetchGoogleMyBusinessPagesAction(state.previousAction, retry ? state.accountId : undefined).catch(
                (error) => {
                    if (error instanceof MultipleGoogleMyBusinessAccounts) {
                        setGmbAccounts({
                            setOpen: () => {},
                            accounts: error.accounts,
                        })
                    } else {
                        displayError(error)
                    }
                },
            )
        }
    }

    const handleChooseAccount = (id?: string) => {
        return confirmMultipleGoogleMyBusinessAccountsSelection(id)
            .then((googlePages) => {
                if (googlePages.length === 0) {
                    enqueueSnackbar(t('views.googleCallback.noPages.message'), {
                        variant: 'error',
                    })
                }
            })
            .catch((error) => displayError(error))
    }

    const handleChoosePage = (id?: string) => {
        if (id === undefined) {
            returnToPreviousPage()
        } else {
            updateEstablishment({
                id: establishment.id,
                establishment: {
                    googleId: id,
                },
            })
                .then(() => {
                    enqueueSnackbar(t('views.establishment.facebookGoogle.success'), {
                        variant: 'success',
                    })
                    returnToPreviousPage()
                })
                .catch((error) => displayError(error))
        }
    }

    return (
        <GoogleCallbackView
            inProgress={inProgress}
            shouldRetry={shouldRetry}
            handleRetrievePage={() => handleRetrievePage(true)}
            gmbAccounts={gmbAccounts}
            googlePages={googlePages}
            onChooseAccount={handleChooseAccount}
            onChoosePage={handleChoosePage}
            updateEstablishmentInProgress={updateEstablishmentInProgress}
            onCancel={returnToPreviousPage}
            onSwitchAccount={() => handleRetrievePage(false)}
        />
    )
}

export default withSignUpLayout(GoogleCallback, { maxWidth: 'md' })
