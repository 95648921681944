import React from 'react'
import { Pricing } from 'core/services/contentful/ressources/types'
import useStyles from './pricingCard.css'
import clsx from 'clsx'
import { Card, CardContent, Typography } from '@material-ui/core'
import LottieAnimation from 'components/common/lottieAnimation'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useRichContentful } from 'components/helpers/useRichContentful'
import { Trans } from 'react-i18next'
import { BLOCKS } from '@contentful/rich-text-types'
import ListItem from '@material-ui/core/ListItem/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon'
import DoneIcon from '@material-ui/icons/Done'
import { Establishment } from '@sugg-gestion/ubidreams-react-suggpro'
import moment from 'moment'
import SubmitButton from 'components/common/submitButton'
import List from '@material-ui/core/List/List'

interface Props {
    establishment: Establishment
    pricing: Pricing
    onChoosePricing: (pricing: Pricing) => void
    inProgress?: string
}

const PricingCard: React.FC<Props> = ({ pricing, establishment, onChoosePricing, inProgress }) => {
    const internalClasses = useStyles()
    const optionsRichText = useRichContentful()
    const optionsPlainText = useRichContentful('plain')

    const percent = pricing.initialPriceByMonth
        ? Math.trunc((1 - (pricing.priceByMonth ?? 0) / (pricing.initialPriceByMonth ?? 0)) * 100)
        : undefined

    const getTrialPeriod = () => {
        if (establishment.promotionRedeem === null) {
            if (establishment.hasRedeemCode) {
                return undefined
            }
            if (pricing.trialPeriod !== null) {
                return pricing.trialPeriod
            }
            return undefined
        } else {
            const now = moment()
            const promotionRedeemExpireAt = moment(establishment.promotionRedeem)
            if (now < promotionRedeemExpireAt) {
                return promotionRedeemExpireAt.diff(now, 'days') + 1
            } else {
                return undefined
            }
        }
    }

    const nextPromotionRedeem = () => {
        if (establishment.promotionRedeem !== null) {
            const now = moment()
            const promotionRedeemExpireAt = moment(establishment.promotionRedeem)
            if (now < promotionRedeemExpireAt) {
                return promotionRedeemExpireAt.add(1, 'days').toDate()
            } else {
                return undefined
            }
        }
        return undefined
    }
    const promotionRedeem = nextPromotionRedeem()
    const trialPeriod = getTrialPeriod()
    return (
        <Card
            elevation={4}
            classes={{
                root: clsx(internalClasses.card, internalClasses.annual, {
                    [internalClasses.oldPlan]: percent !== undefined,
                }),
            }}
        >
            <CardContent>
                <Card
                    elevation={0}
                    classes={{
                        root: clsx(internalClasses.card),
                    }}
                    style={{
                        backgroundColor: pricing.backgroundColor ?? '#DCF3FE',
                    }}
                >
                    <CardContent>
                        {pricing.lottieAnimation ? (
                            <LottieAnimation
                                options={{
                                    animationData: pricing.lottieAnimation.animationData,
                                    loop: pricing.lottieAnimation.loop,
                                }}
                                className={internalClasses.animation}
                            />
                        ) : pricing.image?.url ? (
                            <img
                                src={pricing.image.url}
                                className={internalClasses.image}
                                alt={pricing.title}
                            />
                        ) : null}
                        <div className={clsx(internalClasses.titleContainer)}>
                            {percent !== undefined && (
                                <Typography className={internalClasses.percent}>
                                    <Trans i18nKey="views.payment.percent" values={{ percent }} />
                                </Typography>
                            )}
                            <div className={clsx(internalClasses.titleFixedHeight)}>
                                <Typography
                                    variant="h2"
                                    color="primary"
                                    className={clsx(internalClasses.title)}
                                >
                                    {pricing.title}
                                </Typography>
                                {pricing.subtitle && (
                                    <Typography className={internalClasses.subtitle}>
                                        {documentToReactComponents(pricing.subtitle.json, optionsPlainText)}
                                    </Typography>
                                )}
                            </div>
                            <Typography className={internalClasses.price}>
                                {pricing.initialPriceByMonth ? (
                                    <Trans
                                        i18nKey="views.payment.priceWithReduction"
                                        values={{
                                            price: pricing.priceByMonth,
                                            oldPrice: pricing.initialPriceByMonth,
                                        }}
                                        components={[
                                            <Typography
                                                component={'span'}
                                                variant="h3"
                                                className={internalClasses.priceIncrease}
                                            />,
                                            <Typography
                                                component={'del'}
                                                variant="h3"
                                                className={clsx(
                                                    internalClasses.priceBarred,
                                                    // internalClasses.priceIncrease,
                                                )}
                                                color="primary"
                                            />,
                                        ]}
                                    />
                                ) : (
                                    <>
                                        <Trans
                                            i18nKey="views.payment.price"
                                            values={{
                                                price: pricing.priceByMonth,
                                            }}
                                            components={[
                                                <Typography
                                                    component={'span'}
                                                    variant="h3"
                                                    className={internalClasses.priceIncrease}
                                                />,
                                            ]}
                                        />
                                        {pricing.type === 'year' && (
                                            <Trans
                                                i18nKey="views.payment.priceTotal"
                                                values={{ price: pricing.priceByMonth * 12 }}
                                            />
                                        )}
                                    </>
                                )}
                            </Typography>
                            <Typography className={internalClasses.priceTax}>
                                {pricing.type === 'year' ? (
                                    <Trans i18nKey="views.payment.paymentAnnual" />
                                ) : (
                                    <Trans i18nKey="views.payment.paymentMonth" />
                                )}
                            </Typography>
                            <div className={internalClasses.advantage}>
                                <List dense disablePadding>
                                    {trialPeriod && (
                                        <>
                                            <ListItem disableGutters>
                                                <ListItemIcon className={internalClasses.check}>
                                                    <DoneIcon className={internalClasses.success} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Trans
                                                            i18nKey="views.payment.advantage.trial"
                                                            values={{
                                                                duration: trialPeriod,
                                                            }}
                                                        />
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem disableGutters>
                                                <ListItemIcon className={internalClasses.check}>
                                                    <DoneIcon className={internalClasses.success} />
                                                </ListItemIcon>
                                                {promotionRedeem ? (
                                                    <ListItemText
                                                        primary={
                                                            <Trans
                                                                i18nKey="views.payment.advantage.subscriptionFreePeriod"
                                                                values={{
                                                                    date: promotionRedeem,
                                                                }}
                                                            />
                                                        }
                                                    />
                                                ) : (
                                                    <ListItemText
                                                        primary={
                                                            <Trans
                                                                i18nKey="views.payment.advantage.subscription"
                                                                values={{
                                                                    duration: trialPeriod,
                                                                }}
                                                            />
                                                        }
                                                    />
                                                )}
                                            </ListItem>
                                        </>
                                    )}
                                </List>
                                {pricing.advantages &&
                                    documentToReactComponents(pricing.advantages.json, {
                                        ...optionsRichText,
                                        renderNode: {
                                            ...optionsRichText?.renderNode,
                                            [BLOCKS.OL_LIST]: (node, children) => (
                                                <List disablePadding>{children}</List>
                                            ),
                                            [BLOCKS.UL_LIST]: (node, children) => (
                                                <List disablePadding>{children}</List>
                                            ),
                                            [BLOCKS.LIST_ITEM]: (node, children) => (
                                                <ListItem disableGutters dense>
                                                    <ListItemIcon className={internalClasses.check}>
                                                        <DoneIcon className={internalClasses.success} />
                                                    </ListItemIcon>
                                                    <ListItemText primary={children} />
                                                </ListItem>
                                            ),
                                        },
                                    })}
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <div className={internalClasses.action}>
                    <SubmitButton
                        variant="outlined"
                        color="primary"
                        onClick={() => onChoosePricing(pricing)}
                        disabled={inProgress !== undefined}
                        loading={inProgress === pricing.priceId}
                    >
                        {trialPeriod ? (
                            <Trans i18nKey="views.payment.chooseFree" values={{ trialPeriod: trialPeriod }} />
                        ) : (
                            <Trans i18nKey="views.payment.choose" />
                        )}
                    </SubmitButton>
                </div>
            </CardContent>
        </Card>
    )
}

export default PricingCard
