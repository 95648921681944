import React from 'react'
import EstablishmentCreateView from './establishmentCreateView'
import SignUpContent from 'components/layouts/components/signUpContent'
import SignUpActions from 'components/layouts/components/signUpActions'
import SubmitButton from 'components/common/submitButton'
import clsx from 'clsx'
import { useStyles } from './styles.css'
import { Typography } from '@material-ui/core'
import { Trans } from 'react-i18next'
import HoursForm, { HoursFormState } from 'components/forms/establishment/hoursForm'

interface Props {
    step: number
    steps: number
    onSubmit: (values: HoursFormState) => void
    inProgress: boolean
    initialValues: HoursFormState
    onPrevious: () => void
    previousInProgress: boolean
}

const EstablishmentHoursView: React.FC<Props> = ({
    step,
    steps,
    onPrevious,
    previousInProgress,
    inProgress,
    ...formData
}) => {
    const classes = useStyles()

    return (
        <HoursForm inProgress={inProgress} {...formData}>
            {(content, actions) => (
                <>
                    <SignUpContent>
                        <EstablishmentCreateView step={step} steps={steps}>
                            <Typography variant="h1" color="textSecondary" className={classes.title}>
                                <Trans i18nKey="views.establishment.hours.title" />
                            </Typography>
                            {content}
                        </EstablishmentCreateView>
                    </SignUpContent>
                    <SignUpActions>
                        <SubmitButton
                            variant="contained"
                            onClick={onPrevious}
                            loading={previousInProgress}
                            disabled={inProgress}
                        >
                            <Trans i18nKey="actions.previous" />
                        </SubmitButton>
                        {actions.map((action, index) => (
                            <SubmitButton
                                key={index}
                                variant="contained"
                                color="primary"
                                {...action}
                                className={clsx(action.className)}
                            />
                        ))}
                    </SignUpActions>
                </>
            )}
        </HoursForm>
    )
}

export default EstablishmentHoursView
