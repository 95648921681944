import React from 'react'
import Step from './components/step'

interface Props {
    step: number
    steps: number
}

const EstablishmentCreateView: React.FC<Props> = ({ children, step, steps }) => {
    return (
        <>
            <Step step={step} steps={steps} />
            {children}
        </>
    )
}

export default EstablishmentCreateView
