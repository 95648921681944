import {
    Establishment,
    GetMessagingConversation,
    useGetConversations,
} from '@sugg-gestion/ubidreams-react-suggpro'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store/reducers'
import { useApiErrors } from '../suggPro/useApiErrors'

export const useFetchConversations = (parameters: Establishment) => {
    const { displayError } = useApiErrors()
    const [inProgressGetConversations, setInProgressGetConversations] = useState(false)
    const [inProgress, setInProgress] = useState(false)
    const { privateMessages, posts, reviews } = useSelector(
        ({ suggpro: { messaging } }: ApplicationState) => ({
            privateMessages: messaging.convPrivateMessages,
            posts: messaging.convPosts,
            reviews: messaging.convReviews,
        }),
    )
    const { getConversations } = useGetConversations()

    const updateConversations = useCallback(
        (param: GetMessagingConversation) => {
            setInProgress(true)
            return getConversations({ ...param })
                .catch((error) => {
                    displayError(error)
                })
                .finally(() => {
                    setInProgress(false)
                })
        },
        [displayError, getConversations],
    )

    useEffect(() => {
        if (!inProgressGetConversations) {
            // Get private_message conversation if the store ins empty
            if (privateMessages === undefined) {
                updateConversations({
                    establishment: parameters.id,
                    typeConversation: 'private_message',
                }).catch()
                setInProgressGetConversations(true)
            }
            if (posts === undefined) {
                updateConversations({
                    establishment: parameters.id,
                    typeConversation: 'post',
                }).catch()
                setInProgressGetConversations(true)
            }
            if (reviews === undefined) {
                updateConversations({
                    establishment: parameters.id,
                    typeConversation: 'review',
                }).catch()
                setInProgressGetConversations(true)
            }
        }
        if (privateMessages && posts && reviews) {
            setInProgressGetConversations(false)
        }
    }, [
        displayError,
        updateConversations,
        inProgress,
        privateMessages,
        posts,
        reviews,
        inProgressGetConversations,
        parameters.id,
    ])

    return { privateMessages, posts, reviews, inProgress: inProgressGetConversations }
}
