import { AnyAbility } from '@casl/ability'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import { Establishment, User, useSignOut } from '@sugg-gestion/ubidreams-react-suggpro'
import SignUpLayout from 'components/layouts/signUpLayout'
import AccountDialog from 'containers/account/accountDialog'
import { useApiErrors } from 'core/services/suggPro/useApiErrors'
import { useSnackbar } from 'notistack'
import React, { ComponentType, PropsWithChildren, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import withAuthentication from './withAuthentication'
import withEstablishment from './withEstablishment'
import { WithGraphApiProps } from './withGraphApi'

export interface WithSignUpLayoutDefault extends WithGraphApiProps {
    restaurantOwner: User
    ability: AnyAbility
}

type RequiredEstablishment = {
    establishment: Establishment
}

type OptionalEstablishment = {
    establishment?: Establishment
}

interface WithRequiredEstablishment extends WithSignUpLayoutDefault, RequiredEstablishment {}

interface WithOptionalEstablishment extends WithSignUpLayoutDefault, OptionalEstablishment {}

export type WithSignUpLayout<T extends boolean = true> = PropsWithChildren<
    T extends true ? WithRequiredEstablishment : WithOptionalEstablishment
>

interface InputProps extends WithGraphApiProps {
    restaurantOwner: User
    establishment?: Establishment
    ability: AnyAbility
}

export interface Options {
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}

const withSignUpLayout = <T extends boolean = true>(
    ComposedComponent: ComponentType<WithSignUpLayout<T>>,
    options?: Options,
    withRequiredEstablishment?: T,
) => {
    const WithSignUpLayout: React.FC<InputProps> = ({
        restaurantOwner,
        establishment,
        ability,
        ...props
    }) => {
        const { t } = useTranslation()
        const { enqueueSnackbar } = useSnackbar()
        const classes = useStyles()

        const { displayError } = useApiErrors()
        const { signOut } = useSignOut()

        const [openAccountDialog, setOpenAccountDialog] = useState(false)

        const handleSignOut = () => {
            signOut()
                .then(() => {
                    enqueueSnackbar(t('views.signOut.success'), {
                        variant: 'success',
                    })
                })
                .catch((error) => displayError(error))
        }

        const handleOpenAccountDialog = () => {
            setOpenAccountDialog(true)
        }
        const handleCloseAccountDialog = () => {
            setOpenAccountDialog(false)
        }

        return (
            <SignUpLayout
                onSignOut={handleSignOut}
                onOpenAccount={handleOpenAccountDialog}
                maxWidth={options?.maxWidth}
            >
                {establishment === undefined &&
                (withRequiredEstablishment === undefined || withRequiredEstablishment) ? (
                    <Alert severity="error" className={classes.alert}>
                        <Trans i18nKey="common.wrongEstablishment" />
                    </Alert>
                ) : (
                    <>
                        {/* @ts-ignore */}
                        <ComposedComponent
                            {...props}
                            establishment={establishment}
                            restaurantOwner={restaurantOwner}
                            ability={ability}
                        />
                    </>
                )}
                <AccountDialog
                    open={openAccountDialog}
                    onClose={handleCloseAccountDialog}
                    restaurantOwner={restaurantOwner}
                />
            </SignUpLayout>
        )
    }
    // @ts-ignore
    return withAuthentication(withEstablishment(WithSignUpLayout))
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        alert: {
            margin: theme.spacing(1, 0),
        },
    }),
)

export default withSignUpLayout
