import React, { ComponentType } from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { History } from 'history'

interface Props {
    history: History<any>
}

const withConnectedRouter = (ComposedComponent: ComponentType<Props>) => {
    const WithConnectedRouter: React.FC<Props> = ({ history, ...props }) => {
        return (
            <ConnectedRouter history={history}>
                <ComposedComponent history={history} {...props} />
            </ConnectedRouter>
        )
    }
    return WithConnectedRouter
}

export default withConnectedRouter
