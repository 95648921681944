import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
    createStyles({
        detail: {
            'display': 'flex',
            'alignItems': 'center',
            '& > p': {
                flexGrow: 1,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            },
            'padding': theme.spacing(1, 2),
            '&.MuiCardContent-root:last-child': {
                paddingBottom: theme.spacing(),
            },
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(1, 1),
            },
        },
        media: {
            height: 0,
            width: '100%',
        },
        title: {
            [theme.breakpoints.down('xs')]: {
                fontSize: '0.9rem',
            },
        },
    }),
)
