import React from 'react'
import { Link, Typography } from '@material-ui/core'
import { useStyles } from './notFound.css'
import { Trans } from 'react-i18next'
import HomeLayout from 'components/layouts/homeLayout'
import HomeContent from 'components/layouts/components/homeContent'

const NotFound: React.FC = () => {
    const classes = useStyles()
    return (
        <HomeLayout>
            <HomeContent className={classes.card}>
                <Typography className={classes.title} variant="h1">
                    <Trans i18nKey="views.notFound.title" />
                </Typography>
                <Typography className={classes.subTitle} variant="h2">
                    <Trans i18nKey="views.notFound.subTitle" />
                </Typography>
                <div className={classes.action}>
                    <Link href="/">
                        <Trans i18nKey="views.notFound.action" />
                    </Link>
                </div>
            </HomeContent>
        </HomeLayout>
    )
}

export default NotFound
