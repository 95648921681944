import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        image: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        title: {
            textAlign: 'center',
        },
        card: {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.border.main,
            borderRadius: 0,
            height: '100%',
        },
    }),
)
