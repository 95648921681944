import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionsContainer: {
            zIndex: 2,
            width: '100%',
            [theme.breakpoints.down('xs')]: {
                'position': 'relative',
                'left': theme.spacing(-2),
                'width': '100vw',
                'backgroundColor': theme.palette.background.default,
                '@media (orientation: portrait)': {
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                },
            },
        },
        actions: {
            paddingTop: theme.spacing(),
            justifyContent: 'flex-end',
            [theme.breakpoints.down('xs')]: {
                'padding': theme.spacing(1, 2),
                '@media (orientation: portrait)': {
                    padding: theme.spacing(2, 2),
                },
            },
        },
    }),
)
