import React from 'react'
import { Establishment, MessagingConversation, MessagingMessage } from '@sugg-gestion/ubidreams-react-suggpro'
import { useStyles } from '../styles.css'
import { Grid } from '@material-ui/core'
import CommentBox from './commentBox'

interface Props {
    establishment: Establishment
    conversation: MessagingConversation
    listComments: MessagingMessage[]
    parentComment?: MessagingMessage
    onDeleteMessage: (messageId: string) => void
    onHideMessage: (messageId: string) => void
    onBlockUser: (messageId: string) => void
}

const CommentThread: React.FC<Props> = ({
    establishment,
    conversation,
    listComments,
    parentComment,
    onDeleteMessage,
    onHideMessage,
    onBlockUser,
}) => {
    const classes = useStyles()

    return (
        <>
            {/* Browse parent comment */}
            {Array.from(listComments)
                .reverse()
                .filter(
                    (message, indexParent) =>
                        !message.parentId ||
                        message.parentId === conversation.threadId ||
                        (parentComment && parentComment.messageId === message.parentId),
                )
                .map((comment, index) => {
                    return (
                        <Grid container className={classes.commentContainerBox} key={index}>
                            <CommentBox
                                establishment={establishment}
                                conversation={conversation}
                                comment={comment}
                                onDeleteMessage={onDeleteMessage}
                                onHideMessage={onHideMessage}
                                onBlockUser={onBlockUser}
                            />
                            <Grid item sm={1} xs={1} />
                            <Grid item sm={11} xs={11}>
                                {/* Browse child comment in recursive */}
                                <CommentThread
                                    establishment={establishment}
                                    conversation={conversation}
                                    onDeleteMessage={onDeleteMessage}
                                    onHideMessage={onHideMessage}
                                    onBlockUser={onBlockUser}
                                    listComments={listComments.filter(
                                        (a, b) => a.parentId === comment.messageId,
                                    )}
                                    parentComment={comment}
                                />
                            </Grid>
                        </Grid>
                    )
                })}
        </>
    )
}

export default CommentThread
