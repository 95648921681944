import React from 'react'
import { ReminderNotebook } from '@sugg-gestion/ubidreams-react-suggpro'
import moment from 'moment'
import {
    createStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    withStyles,
} from '@material-ui/core'
import { Trans, useTranslation } from 'react-i18next'
import { useStyles } from './dataTable.css'

interface Props {
    reminderNotebooks: Array<ReminderNotebook>
}

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    }),
)(TableCell)

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }),
)(TableRow)

const DataTable: React.FC<Props> = ({ reminderNotebooks, children }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const columns = [t('form.createdAt'), t('form.firstName'), t('form.lastName'), t('form.phoneNumber')]

    return (
        <TableContainer component={Paper} elevation={0} className={classes.paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        {columns.map((column, index) => (
                            <StyledTableCell key={index}>{column}</StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reminderNotebooks.map((reminderNotebook, index) => (
                        <StyledTableRow key={index}>
                            <TableCell component="th" scope="row">
                                <Trans
                                    i18nKey="reminder-notebook.date"
                                    values={{
                                        date: moment(reminderNotebook.createdAt).toDate(),
                                    }}
                                />
                            </TableCell>
                            <TableCell>{reminderNotebook.givenName}</TableCell>
                            <TableCell>{reminderNotebook.familyName}</TableCell>
                            <TableCell>{reminderNotebook.phone}</TableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
                {children}
            </Table>
        </TableContainer>
    )
}

export default DataTable
