import Bugsnag from '@bugsnag/js'
import {
    actions,
    APIError,
    FacebookSessionExpired,
    FacebookTokenExpired,
    FacebookWrongPermissions,
    GoogleTokenExpired,
    GoogleTokenRequired,
    TwitterExpired,
    TwitterTemporary,
} from '@sugg-gestion/ubidreams-react-suggpro'
import { ApplicationState } from 'core/store/reducers'
import _ from 'lodash'
import { useSnackbar, VariantType } from 'notistack'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

export enum PageType {
    SIGN_IN,
    FORGOTTEN_PASSWORD_CODE,
    DUNS,
    REDEEM_CODE,
    UPDATE_ACCOUNT,
    EDIT_ESTABLISHMENT,
    MESSAGING,
}

export const useApiErrors = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const { user, lostInternetConnexion } = useSelector(({ suggpro: { auth }, app }: ApplicationState) => ({
        user: auth.user,
        lostInternetConnexion: app.lostInternetConnexion,
    }))

    const showMessage = useCallback(
        (error: APIError, pageType?: PageType) => {
            let message = t('error.unexpected')
            let variant: VariantType = 'error'
            if (
                lostInternetConnexion ||
                error?.status === -1 ||
                error?.axiosError?.message === 'Network Error'
            ) {
                message = t('error.lostInternetConnexion')
                return { message, variant }
            }
            if (user) {
                if (
                    error instanceof FacebookWrongPermissions ||
                    error instanceof FacebookTokenExpired ||
                    error instanceof FacebookSessionExpired
                ) {
                    message = t('error.expired.facebook')
                }
                if (error instanceof GoogleTokenExpired || error instanceof GoogleTokenRequired) {
                    message = t('error.expired.google')
                }
                if (error instanceof TwitterExpired) {
                    message = t('error.expired.twitter')
                } else if (error instanceof TwitterTemporary) {
                    message = t('error.twitter.unexpected')
                }
                if (message !== t('error.unexpected')) {
                    return { message, variant }
                }
            }
            switch (pageType) {
                case PageType.SIGN_IN:
                    if (_.get(error, 'data.non_field_errors[0]', '') === 'User account is disabled.') {
                        message = t('error.signIn.disabled')
                    } else if (
                        _.get(error, 'data.non_field_errors[0]', '') ===
                        'Unable to login with provided credentials.'
                    ) {
                        message = t('error.signIn.wrongPassword')
                    } else if (_.get(error, 'data', '') === 'Connection error') {
                        message = t('error.signIn.invalidFacebook')
                    } else if (_.get(error, 'data', '') === 'User does not exist') {
                        message = t('error.signIn.invalidGoogle')
                    }
                    if (_.get(error, 'type', '') === 'FacebookAccountExist') {
                        message = t('error.signIn.facebookAccountExist')
                    }
                    if (_.get(error, 'type', '') === 'GoogleAccountExist') {
                        message = t('error.signIn.googleAccountExist')
                    }
                    if (_.get(error, 'type', '') === 'Sugg1144AccountExist') {
                        message = t('error.signIn.sugg1144AccountExist')
                    }
                    break
                case PageType.FORGOTTEN_PASSWORD_CODE:
                    if (_.get(error, 'status', 0) === 400) {
                        message = t('error.forgottenPassword.wrongCode')
                    }
                    break
                case PageType.REDEEM_CODE:
                    if (_.get(error, 'type', '') === 'WrongCode') {
                        message = t('error.redeemCode.wrongCode')
                    }
                    if (_.get(error, 'type', '') === 'CodeAlreadyRedeem') {
                        message = t('error.redeemCode.codeAlreadyRedeem')
                    }
                    break
                case PageType.DUNS:
                    if (_.get(error, 'status', 0) === 404) {
                        message = t('error.duns')
                    }
                    break
                case PageType.UPDATE_ACCOUNT:
                    if (_.get(error, 'type', '') === 'AccountExist') {
                        message = t('error.updateAccount.accountExist')
                    }
                    break
                case PageType.EDIT_ESTABLISHMENT:
                    if (_.get(error, 'status', 0) === 400) {
                        message = t('error.specialClosing.dateIntervalOverlap')
                    }
                    break
                case PageType.MESSAGING:
                    if (_.get(error, 'type', 0) === 'MessagingMessageNotSend') {
                        message = t('views.messaging.error.sendMessageFailed')
                        variant = 'warning'
                    }
                    if (_.get(error, 'type', 0) === 'MessagingConversationNotFound') {
                        message = t('views.messaging.error.conversationNotFound')
                        variant = 'info'
                    }
            }
            return { message, variant }
        },
        [lostInternetConnexion, t, user],
    )

    const displayError = useCallback(
        (error: APIError, pageType?: PageType, showSnackbar: boolean = true) => {
            console.error(error)
            console.error(JSON.stringify(error))
            const { message, variant } = showMessage(error, pageType)
            if (user) {
                if (
                    error instanceof FacebookWrongPermissions ||
                    error instanceof FacebookTokenExpired ||
                    error instanceof FacebookSessionExpired
                ) {
                    dispatch(actions.setUser({ ...user, isFacebookSignedExpired: true }))
                }
                if (error instanceof GoogleTokenExpired) {
                    dispatch(actions.setUser({ ...user, isGoogleSignedExpired: true }))
                }
                if (error instanceof TwitterExpired) {
                    dispatch(
                        actions.setEstablishment({ ...user.establishments[0], isTwitterSignedExpired: true }),
                    )
                }
            }
            if (message === t('error.unexpected')) {
                Bugsnag.notify(error, (event) => {
                    event.addMetadata('api-error', {
                        error: error.type,
                        name: error.name,
                        message: error.message,
                        axiosCode: JSON.stringify(error.axiosError?.code),
                        axiosName: JSON.stringify(error.axiosError?.name),
                        axiosMessage: JSON.stringify(error.axiosError?.message),
                        axiosConfig: JSON.stringify(error.axiosError?.config),
                        axiosRequest: JSON.stringify(error.axiosError?.request),
                        axiosResponse: JSON.stringify(error.axiosError?.response),
                    })
                })
            }
            if (showSnackbar) {
                enqueueSnackbar(message, {
                    variant: variant,
                })
            }
        },
        [dispatch, enqueueSnackbar, showMessage, t, user],
    )

    return {
        displayError,
        showMessage,
    }
}
