import { AxiosError, AxiosInstance } from 'axios'
import { Store } from 'redux'
import { setLostInternetConnexion } from 'core/store/app/actions'

export const setInterceptor = (store: Store, axiosInstance: AxiosInstance) => {
    axiosInstance.interceptors.response.use(
        function (response) {
            if (!response?.status) {
                store.dispatch(setLostInternetConnexion(true))
                response.status = -1
            } else {
                store.dispatch(setLostInternetConnexion(false))
            }
            return response
        },
        function (error: AxiosError) {
            if (!error?.response?.status) {
                store.dispatch(setLostInternetConnexion(true))
            } else {
                store.dispatch(setLostInternetConnexion(false))
            }
            return Promise.reject(error)
        },
    )
}
