import React from 'react'
import { useFormsStyles } from '../forms.css'
import { Form } from 'react-final-form'
import { SubmitButtonProps } from 'components/common/submitButton'
import { Trans } from 'react-i18next'
import { SlateCategory } from '@sugg-gestion/ubidreams-react-suggpro'
import CheckboxGroupField, { CheckboxValue } from '../components/checkboxGroupField'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: FoodTypeFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps>) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: FoodTypeFormState
    categories?: Array<SlateCategory>
}

export interface FoodTypeFormState {
    foodType: Array<string>
}

const FoodTypeForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
    categories,
}) => {
    const formsClasses = useFormsStyles()

    const categoriesValues: Array<CheckboxValue<string>> = []
    if (categories) {
        categories.forEach((category) => {
            categoriesValues.push({
                label: category.name,
                value: category.name,
            })
        })
        initialValues.foodType.forEach((foodType) => {
            const foodTypeIndex = categoriesValues.findIndex(
                (categoriesValue) => categoriesValue.value === foodType,
            )
            if (foodTypeIndex === -1) {
                categoriesValues.push({
                    label: foodType,
                    value: foodType,
                })
            }
        })
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <>
                            <CheckboxGroupField name="foodType" values={categoriesValues} xs={6} md={4} />
                        </>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                className: formsClasses.submit,
                                children: <Trans i18nKey="actions.next" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}

export default FoodTypeForm
